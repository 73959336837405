import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import Shimmer from "react-shimmer-effect"
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, useHistory, Redirect } from "react-router-dom"

import apiResponse from '../../../../../services/apiResponse'

import CreateBloc from './Blocs/CreateBloc'


class IndexView extends Component {

    createBloc = new CreateBloc();

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            messageError: "",
            statusMappingIipc: [
                {
                    "value":"1",
                    "label":"1 Tahun"
                },
                {
                    "value":"2",
                    "label":"2 Tahun"
                },
                {
                    "value":"3",
                    "label":"3 Tahun"
                },
                {
                    "value":"4",
                    "label":"4 Tahun"
                },
                {
                    "value":"5",
                    "label":"5 Tahun"
                },
                {
                    "value":"6",
                    "label":"6 Tahun"
                },
                {
                    "value":"7",
                    "label":"7 Tahun"
                },
                {
                    "value":"8",
                    "label":"8 Tahun"
                },
                {
                    "value":"9",
                    "label":"9 Tahun"
                },
                {
                    "value":"10",
                    "label":"10 Tahun"
                }
            ],
            statusMappingIipcSelected: "1",
        }
    }

    submitData = async () => {
        await this.createBloc.fetchCreate(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: values,
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: result.data.status,
                        messageError: result.data.status === false ? result.data.message : ""
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">

                            { this.state.messageError ? <>
                                <div className="alert alert-danger">
                                    <strong>{ this.state.messageError }</strong>
                                </div>
                            </> : <></> }

                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah Data</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />

                                  

                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                       
                                       <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Pilih Tahun IIPC<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <CustomSelect
                                                    name="mapping_iipc_year"
                                                    errorMessage="Pilih Kategori"
                                                    options={this.state.statusMappingIipc}
                                                    defaultValue={this.state.statusMappingIipcSelected}
                                                    placeholder="Pilih Kategori"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(v) => {
                                                        this.setState({
                                                            statusMappingIipcSelected: v.value,
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                       
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <Button color="danger" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/master/mapping-iipc.html">
                                                    <Button color="success"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/master/mapping-iipc.html'/>
                : null }
            </Fragment>
        )
    }

}

export default IndexView