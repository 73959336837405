import {
    apiRequest,
    tplnRegulerApproveSubmit,
    tplnRegulerRejectSubmit,
    tplnRegulerResultEmployee,
    tplnRegulerCheckStatus,
    tplnRegulerResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", tplnRegulerApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", tplnRegulerRejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", tplnRegulerResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tplnRegulerResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tplnRegulerCheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository