import {
    apiRequest,
    masterTunkinCountryCreate,
    masterTunkinCountryCountries
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterTunkinCountryCreate, {
            body: query
        }, true)
    }

    fetchCountryList = async () => {
        return await apiRequest("get", masterTunkinCountryCountries, {}, true)
    }

}

export default CreateRepository