import { apiRequest, sigapSalaryGaji } from "../../../../../../services/adapters/output"

class DownloadRepository {
    fetchGaji = async (query) => {
        return await apiRequest(
            "get",
            sigapSalaryGaji,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
