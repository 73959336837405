import {
    apiRequest,
    masterUangMakanCurrent
} from "../../../../../services/adapters/master"

class IndexViewRepository {

    fetchCurrent = async () => {
        return await apiRequest("get", masterUangMakanCurrent, {}, true)
    }
}

export default IndexViewRepository