import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import IndexViewRepository from '../Repositories/IndexViewRepository'


class IndexViewBloc {

    currentChannel = new Subject();
    repository = new IndexViewRepository()

    constructor() {
        this.currentChannel.next({status: apiResponse.INITIAL})
    }

    fetchCurrent = async () => {
        this.currentChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCurrent().then((result) => {
                this.currentChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.currentChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
}

export default IndexViewBloc