import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import IndexViewRepository from '../Repositories/IndexViewRepository'


class IndexViewBloc {

    yearsChannel = new Subject();
    monthsChannel = new Subject();
    monthsBerubahChannel = new Subject();
    monthsKeluarChannel = new Subject();
    detailChannel = new Subject();
    changeChannel = new Subject();
    markReadChannel = new Subject();
    repository = new IndexViewRepository()
    createChannel = new Subject()
    departureChannel = new Subject();
    arrivalChannel = new Subject();
    returnChannel = new Subject();
    decreeChannel = new Subject();
    decreeAllChannel = new Subject();
    contractChannel = new Subject();
    statementChannel = new Subject();
    familyChannel = new Subject();
    childChannel = new Subject();
    marriedChannel = new Subject();
    bankChannel = new Subject();
    npwpChannel = new Subject();

    constructor() {
        this.yearsChannel.next({status: apiResponse.INITIAL})
        this.monthsChannel.next({status: apiResponse.INITIAL})
        this.monthsBerubahChannel.next({status: apiResponse.INITIAL})
        this.monthsKeluarChannel.next({status: apiResponse.INITIAL})
        this.detailChannel.next({status: apiResponse.INITIAL})
        this.changeChannel.next({status: apiResponse.INITIAL})
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.departureChannel.next({status: apiResponse.INITIAL})
        this.arrivalChannel.next({status: apiResponse.INITIAL})
        this.returnChannel.next({status: apiResponse.INITIAL})
        this.decreeChannel.next({status: apiResponse.INITIAL})
        this.decreeAllChannel.next({status: apiResponse.INITIAL})
        this.contractChannel.next({status: apiResponse.INITIAL})
        this.statementChannel.next({status: apiResponse.INITIAL})
        this.familyChannel.next({status: apiResponse.INITIAL})
        this.childChannel.next({status: apiResponse.INITIAL})
        this.marriedChannel.next({status: apiResponse.INITIAL})
        this.bankChannel.next({status: apiResponse.INITIAL})
        this.npwpChannel.next({status: apiResponse.INITIAL})
    }

    fetchYears = async () => {
        this.yearsChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchYears().then((result) => {
                this.yearsChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.yearsChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    
    fetchMonths = async (query, cancelToken) => {
        this.monthsChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchMonths(query, cancelToken).then((result) => {
                this.monthsChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.monthsChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchMonthsBerubah = async (query, cancelToken) => {
        this.monthsBerubahChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchMonthsBerubah(query, cancelToken).then((result) => {
                this.monthsBerubahChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.monthsBerubahChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchMonthsKeluar = async (query, cancelToken) => {
        this.monthsKeluarChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchMonthsKeluar(query, cancelToken).then((result) => {
                this.monthsKeluarChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.monthsKeluarChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.detailChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    
    fetchChange = async (query) => {
        this.changeChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchChange(query).then((result) => {
                this.changeChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.changeChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDeparture = async (query) => {
        this.departureChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDeparture(query).then((result) => {
                this.departureChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.departureChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchArrival = async (query) => {
        this.arrivalChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchArrival(query).then((result) => {
                this.arrivalChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.arrivalChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchReturn = async (query) => {
        this.returnChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchReturn(query).then((result) => {
                this.returnChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.returnChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchContractDocument = async (query) => {
        this.contractChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDocument(query).then((result) => {
                this.contractChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.contractChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchStatementDocument = async (query) => {
        this.statementChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDocument(query).then((result) => {
                this.statementChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.statementChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchFamilyDocument = async (query) => {
        this.familyChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDocument(query).then((result) => {
                this.familyChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.familyChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchChildCardDocument = async (query) => {
        this.childChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDocument(query).then((result) => {
                this.childChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.childChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchMarriedDocument = async (query) => {
        this.marriedChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDocument(query).then((result) => {
                this.marriedChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.marriedChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchBankDocument = async (query) => {
        this.bankChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDocument(query).then((result) => {
                this.bankChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.bankChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchNPWPDocument = async (query) => {
        this.npwpChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDocument(query).then((result) => {
                this.npwpChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.npwpChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }


    fetchDecree = async (query) => {
        this.decreeChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDecree(query).then((result) => {
                this.decreeChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.decreeChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDecreeAll = async (query) => {
        this.decreeAllChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDecreeAll(query).then((result) => {
                this.decreeAllChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.decreeAllChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    markNotificationAsRead = async (uuid) => {
        this.markReadChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.markAsRead(uuid).then((result) => {
                this.markReadChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.markReadChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.processDokumen(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    


}

export default IndexViewBloc