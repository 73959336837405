import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../../services/adapters/master"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../../services/apiResponse'

import ReactTimeAgo from 'react-time-ago'

class TableActiveComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyReferenceSignerTableActive'
    defaultOrder = 'signer_employee_name'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            detailUuid: null,
            updateUuid: null,
            inactiveUuid: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.inactiveData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }
    
    inactiveData = async () => {
        await this.dataTableBloc.fetchInactive({ uuid: this.state.inactiveUuid })
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.dataTableBloc.inactiveChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        loadingTable: true
                    }, function () {
                        this.setTokenAPI()
                        this.props.handleInactiveSuccess()
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
        this.dataTableBloc.inactiveChannel.unsubscribe()
    }

    columns = [
        {
            key: "signer_employee_name",
            text: "Nama Pegawai",
            className: "address",
            sortable: true,
            cell: record => {
                return (
                    <>
                        <div>{ record.signer_employee_name }</div>
                        <small>{ record.signer_description }</small>
                    </>
                )
            }
        },
        {
            key: "signer_signer_type_initial",
            text: "Jenis",
            className: "address",
            sortable: true,
            width: '80'
        },
        {
            key: "signer_signer_type_name",
            text: "Nama Jenis",
            className: "postcode",
            sortable: true,
            width: '260'
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 120,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisId = record.signer_id
                return (
                    <Fragment>
                        { accessRole('delete') ?
                            <span>
                                <button 
                                    className="btn btn-danger btn-sm" 
                                    id={"delete-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            inactiveUuid: record.signer_uuid,
                                            confirm: true
                                        })
                                    }}
                                    style={{
                                        padding: '2px 5px'
                                    }}>
                                    <i className="uil-trash-alt"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["delete" + thisId]} target={"delete-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["delete" + thisId] : !this.state["delete" + thisId]
                                        })
                                    }}
                                >Nonaktifkan</Tooltip>
                            </span>
                            : null
                        }
                    </Fragment>
                );
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={800}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menghapus data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dihapus"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dihapus"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.detailUuid !== null ?
                    <Redirect to={"/sample.html?detail&uuid=" + this.state.detailUuid}/>
                : null }
                { this.state.updateUuid !== null ?
                    <Redirect to={"/sample.html?update&uuid=" + this.state.updateUuid}/>
                : null }
            </>
        )
    }

}

export default TableActiveComponent