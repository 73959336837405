import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, useState, useRef } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"

import TableActiveComponent from './Components/TableActiveComponent'
import TableInactiveComponent from './Components/TableInactiveComponent'


const IndexView = (props) => {

    const [activeTab, setactiveTab] = useState("1")    
    const activeRef = useRef();
    const inactiveRef = useRef();

    const reloadActiveRef = () => activeRef.current.setTokenAPI()
    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                {
                                    (accessRole('create')) ?
                                        <Link to="/master/penanda-tangan.html?create">
                                            <Button color="primary">Hak Akses Baru</Button>
                                        </Link>
                                    : null
                                }
                                </div>
                                <CardTitle>Pengaturan Hak Akses</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Daftar Hak Akses pengguna aplikasi Sistem Informasi Keuangan
                                </CardSubtitle>
                                <hr />
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block">Aktif</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                            active: activeTab === "2",
                                                })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Tidak Aktif</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <TableActiveComponent ref={activeRef} handleInactiveSuccess={reloadInactiveRef}/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <TableInactiveComponent ref={inactiveRef} handleActiveSuccess={reloadActiveRef}/>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView