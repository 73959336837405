import {
    apiRequest,
    masterSpdProvince,
    masterSpdProvinceActive,
    masterSpdProvinceInActive,
    masterSpdProvinceDetail
} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterSpdProvince, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    
    fetchInactive = async (query) => {
        return await apiRequest("post", masterSpdProvinceInActive, {
            body: query
        }, true)
    }

    fetchActive = async (query) => {
        return await apiRequest("post", masterSpdProvinceActive, {
            body: query
        }, true)
    }

    fetchDetail = async (query, cancelToken) => {
        return await apiRequest("get", masterSpdProvinceDetail, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository