import React, { Component } from "react"
import Shimmer from "react-shimmer-effect"
import {
    Row,
    Col,
    Alert, 
    Form, 
    Button
} from 'reactstrap'

import Swal from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import MinioClient from '../../../../../../services/minioClient'
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'

import IndexViewBloc from '../Blocs/IndexViewBloc'
import apiResponse from '../../../../../../services/apiResponse'

import CustomSelect from "../../../../../../helpers/ui/CustomSelect"

import { apiRequest, cancelToken, processDokumen } from "../../../../../../services/adapters/base"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import LoadingComponent from './LoadingComponent'

import moment from 'moment'

class DocumentDetailComponent extends Component {

    indexViewBloc = new IndexViewBloc()
    minioClient = new MinioClient()

    thisDate = new Date()
    thisMonths = this.thisDate.getMonth() === 0 ? 12 : this.thisDate.getMonth() + 1
    thisMonth = "00".substring(0, "00".length - (this.thisMonths).toString().length) + (this.thisMonths).toString()

    monthName = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ]

    constructor(props) {
        super(props)
        const today = new Date();
        const currentYear = today.getFullYear();
        const thisYear = new Date().getFullYear();
        const tahunOptions = Array.from({ length: 7 }, (_, index) => {
            const year = thisYear - 5 + index;
            return {
                value: year.toString(),
                label: year.toString()
            };
        });
        this.state = {

            loadingGenerate: false,
            successGenerate: false,
            failedGenerate: false,

            detailData: {},
            loadingDetail: true,
            showForms: false,
            changeData: [],
            departureData: [],
            arrivalData: [],
            returnData: [],
            decreeData: [],
            contractData: [],
            statementData: [],
            familyData: [],
            childData: [],
            marriedData: [],
            bankData: [],
            npwpData: [],
            loadingChange: true,
            // bulan: { value: this.thisMonth, label: this.monthName[parseInt(this.thisMonth * 1) - 1] },
            optionMonth: [
                { value: '01', label: this.monthName[0] },
                { value: '02', label: this.monthName[1] },
                { value: '03', label: this.monthName[2] },
                { value: '04', label: this.monthName[3] },
                { value: '05', label: this.monthName[4] },
                { value: '06', label: this.monthName[5] },
                { value: '07', label: this.monthName[6] },
                { value: '08', label: this.monthName[7] },
                { value: '09', label: this.monthName[8] },
                { value: '10', label: this.monthName[9] },
                { value: '11', label: this.monthName[10] },
                { value: '12', label: this.monthName[11] },
            ],
            bulanSelected: this.thisMonth.toString(),
            tahunOptions: tahunOptions,
            tahunSelected: thisYear.toString(),
            tahun: currentYear.toString(),
            isChecked: false,
            isDisabled: false,
            isDisabledCheckbox: false,
            showEdit: false,
            isEditing: false,
            optionsTahun: this.generateTahunOptions(currentYear, 5) 
        }
    }

    handleCheckboxChange = (e) => {
        this.setState({ showForms: e.target.checked });
    };

    handleEditClick = () => {
        this.setState({
            isEditing: true,
            isDisabled: false,
        });
    };

    convertUrl = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        changeData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }

    }

    convertUrlDeparture = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        departureData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    convertUrlArrival = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        arrivalData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    convertUrlReturn = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        returnData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    convertUrlDecree = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        decreeData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    convertUrlContract = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        contractData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    convertUrlStatement = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        statementData: newResponse,
                        loadingChange: false
                    })
                }

            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    convertUrlFamily = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        familyData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    convertUrlChild = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        childData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    convertUrlMarried = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        marriedData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    convertUrlBank = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        bankData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    convertUrlNPWP = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        npwpData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }
    }

    componentDidMount() {

        this.indexViewBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingDetail: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingDetail: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        detailData: response,
                        loadingDetail: false
                    })

                    if (response.process_document_is_process === 1) {
                        console.log('tets', response.process_document_year);
                        const formattedMonth = ("00" + response.process_document_month).slice(-2);

                        this.setState({
                            isChecked: true,
                            bulanSelected: formattedMonth,
                            tahunSelected: response.process_document_year.toString(),
                            isDisabled: true,
                            isDisabledCheckbox: true,
                            showEdit: true
                        });
                    } else {
                        this.setState({
                            isChecked: false,
                            isDisabled: false,
                            showEdit: false,
                            isDisabledCheckbox: false
                        });
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true
                    });
                    
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.changeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrl(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.departureChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlDeparture(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.arrivalChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlArrival(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.returnChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlReturn(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.decreeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlDecree(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.contractChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlContract(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.statementChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlStatement(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.familyChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlFamily(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.childChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlChild(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.marriedChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlMarried(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.bankChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlBank(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.npwpChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrlNPWP(response)
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.fetchDetail({ uuid: this.props.uuid })
        this.indexViewBloc.fetchChange({ uuid: this.props.uuid })
        this.indexViewBloc.fetchDeparture({ uuid: this.props.uuid })
        this.indexViewBloc.fetchArrival({ uuid: this.props.uuid })
        this.indexViewBloc.fetchReturn({ uuid: this.props.uuid })
        this.indexViewBloc.fetchDecree({ uuid: this.props.uuid })
        this.indexViewBloc.fetchContractDocument({ uuid: this.props.uuid, document_type: 'kontrak_kerja' })
        this.indexViewBloc.fetchStatementDocument({ uuid: this.props.uuid, document_type: 'pernyataan_kerja' })
        this.indexViewBloc.fetchFamilyDocument({ uuid: this.props.uuid, document_type: 'kartu_keluarga' })
        this.indexViewBloc.fetchChildCardDocument({ uuid: this.props.uuid, document_type: 'akta_anak' })
        this.indexViewBloc.fetchMarriedDocument({ uuid: this.props.uuid, document_type: 'akta_nikah' })
        this.indexViewBloc.fetchBankDocument({ uuid: this.props.uuid, document_type: 'buku_tabungan' })
        this.indexViewBloc.fetchNPWPDocument({ uuid: this.props.uuid, document_type: 'npwp' })
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentWillUnmount() {
        this.indexViewBloc.detailChannel.unsubscribe()
        this.indexViewBloc.changeChannel.unsubscribe()
        this.indexViewBloc.departureChannel.unsubscribe()
        this.indexViewBloc.arrivalChannel.unsubscribe()
        this.indexViewBloc.returnChannel.unsubscribe()
        this.indexViewBloc.decreeChannel.unsubscribe()
        this.indexViewBloc.createChannel.unsubscribe()
        this.indexViewBloc.contractChannel.unsubscribe()
        this.indexViewBloc.statementChannel.unsubscribe()
        this.indexViewBloc.familyChannel.unsubscribe()
        this.indexViewBloc.childChannel.unsubscribe()
        this.indexViewBloc.marriedChannel.unsubscribe()
        this.indexViewBloc.bankChannel.unsubscribe()
        this.indexViewBloc.npwpChannel.unsubscribe()
    }

    generateTahunOptions(currentYear, numYears) {
        const options = [];
        for (let i = 0; i <= numYears; i++) {
            const year = currentYear - i;
            options.push(year.toString());
        }
        return options;
    }

    handleCheckboxChange = () => {
        this.setState((prevState) => ({
            isChecked: !prevState.isChecked
        }));
    }

    handlerOnSubmit = (e, values) => {
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        e.preventDefault()
        this.setState({
            dataForm: {
                financial_recap_uuid: this.state.detailData.financial_recap_uuid,
                financial_recap_simkeu_month: this.state.bulanSelected,
                financial_recap_simkeu_year: this.state.tahunSelected,
                financial_recap_simkeu_create_date:formattedDate,
            },
            confirm: true
        })
    }

    submitData = async () => {
        // await this.createProcess(this.state.dataForm);
        await this.indexViewBloc.fetchCreate(this.state.dataForm)
    }

    // handleButtonClick = () => {
    //     const financialUuid = this.state.detailData.financial_recap_uuid; // Tidak perlu destrukturisasi {}
    //     const { bulan, tahun } = this.state;
    //     const { isChecked } = this.state;
    
    //     if (isChecked) {
    //         this.createProcess(financialUuid, bulan, tahun);
    //     } else {
    //         alert('Silakan centang kotak sebelum melanjutkan.');
    //     }
    // }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true,
                }
                this.props.finishSubmit()
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    handleDownload = (documentPath) => {
        window.open(documentPath, '_blank');
    };
    
    createProcess = (financialUuid, bulanSelected, tahunSelected) => {
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    
        const requestData = {
            body: { 
                financial_recap_uuid: financialUuid,
                financial_recap_simkeu_month: bulanSelected,
                financial_recap_simkeu_year: tahunSelected,
                financial_recap_simkeu_create_date: formattedDate
            }
        };
    
        apiRequest("post", processDokumen, requestData)
        .then(result => {
            // console.log('API Response:', result); // Add this line for debugging
            if (result.status) {
                alert('Data berhasil disimpan.');
            } else {
                alert('Data berhasil disimpan.');
                // console.error('Gagal menandai notifikasi sebagai dibaca:', result.message);
            }
        })
        .catch(error => {
            console.error('Kesalahan menandai notifikasi sebagai dibaca:', error);
        });
    }
    

    render() {
        console.log('contractData:', this.state.contractData);
        console.log('contractData length:', this.state.contractData.length);
        return (
            <>
                <Col>
                    <Row className="mb-12">
                        <Col md="12">
                            {
                                this.state.loadingChange === true ?
                                    <LoadingComponent message="Memeriksa informasi perubahan..." />
                                    :
                                    <>
                                        {
                                            this.state.changeData.length > 0 ?
                                                this.state.changeData.map((item, key) => (
                                                    <Alert color="success" key={key}>
                                                        <table width="100%">
                                                            <tr>
                                                                <td width="30px" style={{ verticalAlign: 'middle' }}>
                                                                    <i className="uil uil-check"></i>
                                                                </td>
                                                                <td style={{ verticalAlign: 'middle' }}>
                                                                    {item.financial_recap_type_name} {item.financial_recap_reason_type_name !== null ? "(" + item.financial_recap_reason_type_name + ")" : ""}
                                                                    {item.financial_recap_start_date !== null && item.financial_recap_start_date !== '' ?
                                                                        <div>
                                                                            <small>
                                                                                {moment(item.financial_recap_start_date).format('LL')} s/d {item.financial_recap_end_date !== null ?
                                                                                    <>
                                                                                        {moment(item.financial_recap_end_date).format('LL')}
                                                                                    </>
                                                                                    :
                                                                                    <>~</>
                                                                                }
                                                                            </small>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </td>
                                                                <td className="text-end" style={{ verticalAlign: 'middle' }}>
                                                                    {item.documents_path !== null ?
                                                                        <a href={item.documents_path} rel="noreferrer" target="_blank">
                                                                            Download
                                                                        </a>
                                                                        :
                                                                        <small>Belum ada dokumen</small>
                                                                    }

                                                                    {item.financial_recap_source_url !== null ?
                                                                        <> | <a href={"https://simpeg.bkpm.go.id" + item.financial_recap_source_url} rel="noreferrer" target="_blank">
                                                                            Lihat
                                                                        </a>
                                                                        </>
                                                                        : <></>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </Alert>
                                                ))
                                                :
                                                <Alert color="secondary">
                                                    <table width="100%">
                                                        <tr>
                                                            <td width="30px" style={{ verticalAlign: 'top' }}>
                                                                <i className="uil uil-check"></i>
                                                            </td>
                                                            <td style={{ verticalAlign: 'top' }}>
                                                                Tidak ada perubahan
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Alert>
                                        }
                                    </>
                            }
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Tanggal Dokumen
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.financial_recap_create_date !== null ? moment(this.state.detailData.financial_recap_create_date).format('LL') : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 140, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nomor Dokumen
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.employee_transfer_detail_decree_number !== null ? this.state.detailData.employee_transfer_detail_decree_number : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 180, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Uraian Dokumen
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.financial_recap_type_name !== null ? this.state.detailData.financial_recap_type_name : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 180, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Dokumen dari
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.employee_fullname !== null ? this.state.detailData.employee_fullname : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 320, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Tanggal Mulai Berlaku Dokumen
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.financial_recap_start_date !== null ? moment(this.state.detailData.financial_recap_start_date).format('LL') : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 20, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>

                    {
                        // Check if the condition is met for adding a custom row
                        this.state.detailData.financial_recap_type_name === 'Surat Keputusan PPPK' &&
                        !this.state.loadingDetail && (
                            <div>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen Kontrak Kerja
                                    </label>
                                    <Col md="9">
                                        {this.state.contractData.length > 0 ? (
                                            this.state.contractData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen Pernyataan Melaksanakan Tugas
                                    </label>
                                    <Col md="9">

                                        {this.state.statementData.length > 0 ? (
                                            this.state.statementData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen Kartu Keluarga
                                    </label>
                                    <Col md="9">
                                        {this.state.familyData.length > 0 ? (
                                            this.state.familyData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen Akta Lahir Anak
                                    </label>
                                    <Col md="9">
                                        {this.state.childData.length > 0 ? (
                                            this.state.childData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen Akta Nikah
                                    </label>
                                    <Col md="9">
                                        {this.state.marriedData.length > 0 ? (
                                            this.state.marriedData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen Buku Tabungan
                                    </label>
                                    <Col md="9">
                                        {this.state.bankData.length > 0 ? (
                                            this.state.bankData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen Kartu NPWP
                                    </label>
                                    <Col md="9">
                                        {this.state.npwpData.length > 0 ? (
                                            this.state.npwpData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>
                            </div>
                        )
                    }

                    {
                        // Check if the condition is met for adding a custom row
                        this.state.detailData.financial_recap_reason_type_name === 'Ditugaskan Di Luar Negeri/Luar BKPM' &&
                        !this.state.loadingDetail && (
                            <div>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Negara Penempatan
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>
                                                    {this.state.detailData.region_name !== null ?
                                                        `${this.state.detailData.region_name}`
                                                        : '-'}
                                                </>
                                                : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Tanggal Berangkat ke Negara Penugasan
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>
                                                    {this.state.detailData.employee_transfer_detail_departure_date !== null ? moment(this.state.detailData.employee_transfer_detail_departure_date).format('LL') : '-'}
                                                </>
                                                : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen Keberangkatan
                                    </label>
                                    <Col md="9">
                                        {this.state.departureData.length > 0 ? (
                                            this.state.departureData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Tanggal Tiba di Negara Penempatan
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>
                                                    {this.state.detailData.employee_transfer_detail_arrival_date !== null ? moment(this.state.detailData.employee_transfer_detail_arrival_date).format('LL') : '-'}
                                                </>
                                                : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen Waktu Tiba
                                    </label>
                                    <Col md="9">
                                        {this.state.arrivalData.length > 0 ? (
                                            this.state.arrivalData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Tanggal Pulang ke Indonesia 
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>
                                                    {this.state.detailData.employee_transfer_detail_return_date !== null ? moment(this.state.detailData.employee_transfer_detail_return_date).format('LL') : '-'}
                                                </>
                                                : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen Kepulangan
                                    </label>
                                    <Col md="9">
                                        {this.state.returnData.length > 0 ? (
                                            this.state.returnData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Dokumen SK Kemenlu
                                    </label>
                                    <Col md="9">
                                        {this.state.decreeData.length > 0 ? (
                                            this.state.decreeData.map((item, key) => (
                                                <div key={key}>
                                                    {item.documents_path !== null ? (
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => this.handleDownload(item.documents_path)}
                                                        >
                                                            Download
                                                        </button>
                                                    ) : (
                                                        <small>Belum ada dokumen</small>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: 'top' }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top' }}>Tidak ada perubahan</td>
                                                    </tr>
                                                </table>
                                            )}
                                    </Col>
                                </Row>
                            </div>
                        )
                    }


                    <hr />
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nama / NIP
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>
                                        {this.state.detailData.employee_fullname !== null ? 
                                            `${this.state.detailData.employee_fullname} (${this.state.detailData.employee_nip})`
                                            : '-'}
                                    </>
                                    : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <label className="col-md-3">
                            Status Kawin
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.employee_marital_status_name !== null ? this.state.detailData.employee_marital_status_name : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 100, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Kode Golongan / Masa Kerja Golongan
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>
                                        {this.state.detailData.work_unit_name !== null ? 
                                            `${this.state.detailData.work_unit_name} (${this.state.detailData.work_unit_echelon_name})`
                                            : '-'}
                                    </>
                                    : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Kedudukan / Jabatan
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                    <>{this.state.detailData.employee_status_position_name !== null ? this.state.detailData.employee_status_position_name : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 180, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>

                    {
                        // Check if the condition is met for adding a custom row
                        this.state.detailData.financial_recap_type_name === 'Surat Keputusan PPPK' &&
                        !this.state.loadingDetail && (
                            <div>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        NIK
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>
                                                    {this.state.detailData.employee_identity_number !== null ? 
                                                        `${this.state.detailData.employee_identity_number}`
                                                        : '-'}
                                                </>
                                                : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Nomor KK
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>{this.state.detailData.employee_family_card_number !== null ? this.state.detailData.employee_family_card_number : '-'}</>
                                                : <Shimmer><div className="shimmer" style={{ width: 100, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Tempat Lahir
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>
                                                    {this.state.detailData.employee_place_of_birth_city_name !== null ? 
                                                        `${this.state.detailData.employee_place_of_birth_city_name}`
                                                        : '-'}
                                                </>
                                                : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                       Tanggal Lahir
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>{this.state.detailData.employee_date_of_birth !== null ?  moment(this.state.detailData.employee_date_of_birth).format('LL') : '-'}</>
                                                : <Shimmer><div className="shimmer" style={{ width: 180, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Jenis Kelamin
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>
                                                    {this.state.detailData.employee_gender_name !== null ? 
                                                        `${this.state.detailData.employee_gender_name}`
                                                        : '-'}
                                                </>
                                                : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Nomor Rekening
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>{this.state.detailData.employee_bank_account_number !== null ? this.state.detailData.employee_bank_account_number : '-'}</>
                                                : <Shimmer><div className="shimmer" style={{ width: 100, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        NPWP
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>
                                                    {this.state.detailData.employee_npwp_number !== null ? 
                                                        `${this.state.detailData.employee_npwp_number}`
                                                        : '-'}
                                                </>
                                                : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                       Alamat 
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>{this.state.detailData.employee_identity_address !== null ? this.state.detailData.employee_identity_address : '-'}</>
                                                : <Shimmer><div className="shimmer" style={{ width: 180, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Kode Pos
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>
                                                    {this.state.detailData.employee_identity_postal_code !== null ? 
                                                        `${this.state.detailData.employee_identity_postal_code}`
                                                        : '-'}
                                                </>
                                                : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Agama
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>{this.state.detailData.employee_religion_name !== null ? this.state.detailData.employee_religion_name : '-'}</>
                                                : <Shimmer><div className="shimmer" style={{ width: 100, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                        Pendidikan Terakhir
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>
                                                    {this.state.detailData.employee_last_education_uuid !== null ? 
                                                        `${this.state.detailData.employee_last_education_uuid}`
                                                        : '-'}
                                                </>
                                                : <Shimmer><div className="shimmer" style={{ width: 240, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                       Nomor HP 
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>{this.state.detailData.employee_mobile_phone_number !== null ? this.state.detailData.employee_mobile_phone_number : '-'}</>
                                                : <Shimmer><div className="shimmer" style={{ width: 180, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label className="col-md-3">
                                       Email 
                                    </label>
                                    <Col md="9">
                                        {
                                            !this.state.loadingDetail ?
                                                <>{this.state.detailData.employee_email !== null ? this.state.detailData.employee_email : '-'}</>
                                                : <Shimmer><div className="shimmer" style={{ width: 180, height: 15 }}></div></Shimmer>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        )
                    }

                    <hr />
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Tanggal Rekam Dokumen SIMKEU
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                <>{this.state.detailData.process_document_create_date !== null ? moment(this.state.detailData.process_document_create_date).format('LL') : '-'}</>
                                : <Shimmer><div className="shimmer" style={{ width: 100, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nama User Perekam SIMKEU
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingDetail ?
                                <>{this.state.detailData.process_document_employee_name !== null ? this.state.detailData.process_document_employee_name : '-'}</>
                                : <Shimmer><div className="shimmer" style={{ width: 100, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <hr />
                    <AvForm
                        className="needs-validation"
                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                    >
                        <Row className="mb-3">
                            <label className="col-md-3">Proses</label>
                            <Col md="6">
                                <input
                                    type="checkbox"
                                    checked={this.state.isChecked}
                                    onChange={this.handleCheckboxChange}
                                    style={{ marginRight: '10px' }}
                                    disabled={this.state.isDisabledCheckbox}
                                />

                                {!this.state.isEditing && this.state.showEdit && (
                                    <button
                                        type="button"
                                        className="btn btn-warning p-1"
                                        onClick={this.handleEditClick}
                                    >
                                        <i className="uil-edit-alt"></i> Edit
                                    </button>
                                )}

                                {this.state.isEditing && (
                                    <label className="col-md-6 text-danger">Silakan perbarui data.</label>
                                )}
                            </Col>
                        </Row>
                        {this.state.isChecked && (
                            <Row className="mb-3">
                                <label className="col-md-3">Pilih Bulan Induk</label>
                                <Col md="6">
                                    <CustomSelect
                                        name="bulan"
                                        errorMessage="Pilih Bulan"
                                        options={this.state.optionMonth}
                                        defaultValue={this.state.bulanSelected}
                                        placeholder="Pilih Bulan"
                                        validate={{ required: { value: true } }}
                                        onChange={(v) => {
                                            this.setState({
                                                bulanSelected: v.value,
                                            })
                                        }}
                                        isDisabled={this.state.isDisabled}
                                    />
                                </Col>
                            </Row>
                        )}
                        {this.state.isChecked && (
                            <Row className="mb-3">
                                <label className="col-md-3">Pilih Tahun Induk</label>
                                <Col md="6">
                                    <CustomSelect
                                        name="tahun"
                                        errorMessage="Pilih Tahun"
                                        options={this.state.tahunOptions}
                                        defaultValue={this.state.tahunSelected}
                                        placeholder="Pilih Tahun"
                                        validate={{ required: { value: true } }}
                                        onChange={(v) => {
                                            this.setState({
                                                tahunSelected: v.value,
                                            })
                                        }}
                                        isDisabled={this.state.isDisabled}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col md="12">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button
                                        onClick={this.handleButtonClick}
                                        className="btn btn-primary"
                                        disabled={!this.state.isChecked}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                    
                </Col>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />

                <ConfirmAlert
                    loadingTitle="Meminta File..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="File berhasil diterima"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal diterima"

                    showLoading={this.state.loadingGenerate}
                    showSuccess={this.state.successGenerate}
                    showFailed={this.state.failedGenerate}

                    response={this.confirmResponse}
                />
                
            </>
        )
    }
}
export default DocumentDetailComponent