import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect"
import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Modal,
    Alert,
    Spinner,
} from "reactstrap"

import { ConfirmAlert } from "../../../../../helpers/ui/alert"
import { generateFormData } from "../../../../../helpers/ui/FileInput"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect, withRouter } from "react-router-dom"
import apiResponse from "../../../../../services/apiResponse"
import EmployeeListComponent from "./Components/EmployeeListComponent"
import CreateBloc from "./Blocs/CreateBloc"
import CustomSelect from "../../../../../helpers/ui/CustomSelect"

import LoadingComponent from "./Components/LoadingComponent"
import { timesSeries } from "async"

class CreateView extends Component {
    createBloc = new CreateBloc()

    source
    constructor(props) {
        super(props)
        this.state = {
            tunsusStatus: null,
            tunsusReason: "",
            tunsusMonth: 0,
            tunsusStatusName: "",
            uuid: null,
            dataForm: {},
            loadingCheckAll: false,
            loadingCheckPopup: true,
            loadingCheckPopupStatus: false,
            showPopUp: false,
            loadingCheckLevel: true,
            loadingCheckPtkp: true,
            loadingCheckOtherMonth: true,

            loadingCheckLevelStatus: false,
            loadingCheckPtkpStatus: false,
            loadingCheckOtherMonthStatus: false,

            loadingSignerLeft: true,
            loadingSignerRight: true,
        }
        this.createBloc.fetchCheckStatus({ uuid: this.props.uuid })
    }

    allPAss = () => {
        if (this.state.loadingCheckLevel === false && this.state.loadingCheckPtkp === false) {
            if (this.state.tunsusMonth === 12) {
                if (this.state.loadingCheckOtherMonth === false) {
                    this.setState({
                        loadingCheckPopup: false,
                    })
                }
            } else {
                this.setState({
                    loadingCheckPopup: false,
                })
            }
        } else {
            this.setState({
                loadingCheckPopup: true,
            })
        }

        if (this.state.loadingCheckLevelStatus === true && this.state.loadingCheckPtkpStatus === true) {
            if (this.state.tunsusMonth === 12) {
                if (this.state.loadingCheckOtherMonthStatus === true) {
                    this.setState({
                        loadingCheckPopupStatus: true,
                    })
                }
            } else {
                this.setState({
                    loadingCheckPopupStatus: true,
                })
            }
        } else {
            this.setState({
                loadingCheckPopupStatus: false,
            })
        }

        if (
            this.state.loadingCheckLevelStatus === true &&
            this.state.loadingCheckPtkpStatus === true &&
            this.state.loadingSignerLeft === false &&
            this.state.loadingSignerRight === false
        ) {
            if (this.state.tunsusMonth === 12) {
                if (this.state.loadingCheckOtherMonthStatus === true) {
                    this.setState({
                        loadingCheckAll: true,
                    })
                }
            } else {
                this.setState({
                    loadingCheckAll: true,
                })
            }
        } else {
            this.setState({
                loadingCheckAll: false,
            })
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm)
        await this.createBloc.fetchCreate(formData)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
            },
            confirm: true,
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.createBloc.checkStatusChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    if (response.status === 1 || response.status === 3) {
                        this.setState({
                            tunsusStatus: response.status,
                            tunsusReason: response.reason,
                            tunsusMonth: response.month,
                        })
                        this.createBloc.fetchCheckLevel({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckPtkp({ uuid: this.props.uuid })
                        this.createBloc.fetchGetSignerLeft({ uuid: "8e76798e-b27f-4276-a375-60a001d3c225" })
                        this.createBloc.fetchGetSignerRight({ uuid: "2f087de1-7714-441c-9255-61279bdd87f2" })
                        if (response.month === 12) {
                            this.createBloc.fetchCheckOtherMonth({ uuid: this.props.uuid })
                        }
                    } else {
                        this.setState({
                            tunsusStatusName: "Proses tidak dapat dilakukan",
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })

        this.createBloc.getSignerLeft.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingSignerLeft: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingSignerLeft: false,
                        signerLeft: response,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })

        this.createBloc.getSignerRight.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingSignerRight: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingSignerRight: false,
                        signerRight: response,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })

        this.createBloc.checkLevelChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckLevel: false,
                            loadingCheckLevelStatus: true,
                            loadingCheckLevelText: "Data Level Khusus tersedia",
                        })
                    } else {
                        this.setState({
                            loadingCheckLevel: false,
                            loadingCheckLevelStatus: false,
                            loadingCheckLevelText: response.message,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkPtkpChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckPtkp: false,
                            loadingCheckPtkpStatus: true,
                            loadingCheckPtkpText: "Data PTKP tersedia",
                        })
                    } else {
                        this.setState({
                            loadingCheckPtkp: false,
                            loadingCheckPtkpStatus: false,
                            loadingCheckPtkpText: response.message,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
        this.createBloc.checkStatusChannel?.unsubscribe()
        this.createBloc.checkPtkpChannel?.unsubscribe()
        this.createBloc.getSignerLeft?.unsubscribe()
        this.createBloc.getSignerRight?.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Proses Tunjangan Khusus Rapel</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Pengecekan data Tunjangan Khusus Rapel perpegawain dan pemrosesan data Tunjangan
                                        Khusus
                                    </CardSubtitle>
                                    <hr />
                                    {this.state.tunsusStatus ? (
                                        <>
                                            {this.state.tunsusStatus === 3 ? (
                                                <>
                                                    <Row className="mb-3">
                                                        <Col md="12">
                                                            <Alert color="warning">
                                                                <b>Data sebelumnya ditolak:</b>{" "}
                                                                {this.state.tunsusReason}
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null}
                                            <AvForm
                                                className="needs-validation"
                                                onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                            >
                                                <AvField name="uuid" type="hidden" id="uuid" value={this.props.uuid} />
                                                <Row className="mb-3">
                                                    {/* <Col md="12">
                                                        {this.state.loadingCheckPopup === true ? (
                                                            <LoadingComponent message="Memeriksa Data Master SIMKEU..." />
                                                        ) : (
                                                            <Alert
                                                                color={
                                                                    this.state.loadingCheckPopupStatus
                                                                        ? "success"
                                                                        : "danger"
                                                                }
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showPopUp: true,
                                                                    })
                                                                }}
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    "uil " +
                                                                                    (this.state.loadingCheckPopupStatus
                                                                                        ? "uil-check"
                                                                                        : "uil-times")
                                                                                }
                                                                            ></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.loadingCheckPopupStatus
                                                                                ? "Data Master SIMKEU tersedia"
                                                                                : "Data Master SIMKEU belum sesuai"}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )}
                                                    </Col> */}
                                                    <Col md="12">
                                                        {this.state.loadingCheckPtkp === true ? (
                                                            <LoadingComponent message="Memeriksa Data Referensi PTKP..." />
                                                        ) : (
                                                            <Alert
                                                                color={
                                                                    this.state.loadingCheckPtkpStatus
                                                                        ? "success"
                                                                        : "danger"
                                                                }
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    "uil " +
                                                                                    (this.state.loadingCheckPtkpStatus
                                                                                        ? "uil-check"
                                                                                        : "uil-times")
                                                                                }
                                                                            ></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.loadingCheckPtkpText}
                                                                        </td>
                                                                        {!this.state.loadingCheckPtkpStatus ? (
                                                                            <td
                                                                                className="text-end"
                                                                                style={{ verticalAlign: "top" }}
                                                                            >
                                                                                <Link to="/master/referensi-ptkp.html">
                                                                                    Master PTKP
                                                                                </Link>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )}
                                                        {this.state.loadingCheckLevel === true ? (
                                                            <LoadingComponent message="Memeriksa Data Referensi Grade..." />
                                                        ) : (
                                                            <Alert
                                                                color={
                                                                    this.state.loadingCheckLevelStatus
                                                                        ? "success"
                                                                        : "danger"
                                                                }
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    "uil " +
                                                                                    (this.state.loadingCheckLevelStatus
                                                                                        ? "uil-check"
                                                                                        : "uil-times")
                                                                                }
                                                                            ></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.loadingCheckLevelText}
                                                                        </td>
                                                                        {!this.state.loadingCheckLevelStatus ? (
                                                                            <td
                                                                                className="text-end"
                                                                                style={{ verticalAlign: "top" }}
                                                                            >
                                                                                <Link to="/master/tunsus.html">
                                                                                    Master Grade
                                                                                </Link>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )}
                                                        {this.state.tunsusMonth === 12 ? (
                                                            <>
                                                                {this.state.loadingCheckOtherMonth === true ? (
                                                                    <LoadingComponent message="Memeriksa Data Tunsus bulan sebelumnya..." />
                                                                ) : (
                                                                    <Alert
                                                                        color={
                                                                            this.state.loadingCheckOtherMonthStatus
                                                                                ? "success"
                                                                                : "danger"
                                                                        }
                                                                    >
                                                                        <table width="100%">
                                                                            <tr>
                                                                                <td
                                                                                    width="30px"
                                                                                    style={{ verticalAlign: "top" }}
                                                                                >
                                                                                    <i
                                                                                        className={
                                                                                            "uil " +
                                                                                            (this.state
                                                                                                .loadingCheckOtherMonthStatus
                                                                                                ? "uil-check"
                                                                                                : "uil-times")
                                                                                        }
                                                                                    ></i>
                                                                                </td>
                                                                                <td style={{ verticalAlign: "top" }}>
                                                                                    {
                                                                                        this.state
                                                                                            .loadingCheckOtherMonthText
                                                                                    }
                                                                                    <br />
                                                                                    {this.state.loadingCheckOtherMonthDetail.map(
                                                                                        (item, key) => {
                                                                                            return (
                                                                                                <li>
                                                                                                    <small>
                                                                                                        {item}
                                                                                                    </small>
                                                                                                </li>
                                                                                            )
                                                                                        }
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </Alert>
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md="12">
                                                        <EmployeeListComponent uuid={this.props.uuid} />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Label htmlFor="fieldNama" className="col-md-3 col-form-label">
                                                        Bendahara Pengeluaran
                                                    </Label>
                                                    <Col md="5">
                                                        {this.state.loadingSignerLeft ? (
                                                            <>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "100%", height: 38 }}
                                                                    ></div>
                                                                </Shimmer>{" "}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <CustomSelect
                                                                    name="signer_left"
                                                                    errorMessage="Pilih Penandatangan"
                                                                    options={this.state.signerLeft}
                                                                    defaultValue={null}
                                                                    placeholder="Pilih Penandatangan"
                                                                    optionLabel="signer_employee_name"
                                                                    optionValue="signer_uuid"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="fieldNama" className="col-md-3 col-form-label">
                                                        Pejabat Pembuat Komitmen
                                                    </Label>
                                                    <Col md="5">
                                                        {this.state.loadingSignerRight ? (
                                                            <>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "100%", height: 38 }}
                                                                    ></div>
                                                                </Shimmer>{" "}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <CustomSelect
                                                                    name="signer_right"
                                                                    errorMessage="Pilih Penandatangan"
                                                                    options={this.state.signerRight}
                                                                    defaultValue={null}
                                                                    placeholder="Pilih Penandatangan"
                                                                    optionLabel="signer_employee_name"
                                                                    optionValue="signer_uuid"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <div className="form-check form-switch mb-3">
                                                            <AvCheckboxGroup
                                                                name="checkboxExample"
                                                                required
                                                                errorMessage="Agreement harus di centang"
                                                            >
                                                                <AvCheckbox
                                                                    label="Saya yakin dan bertanggung jawab atas data ini"
                                                                    value="1"
                                                                />
                                                            </AvCheckboxGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <Link to="/payroll/tunsus-rapel.html">
                                                            <Button color="danger" type="button">
                                                                <i className="uil-arrow-left"></i> Kembali
                                                            </Button>
                                                        </Link>{" "}
                                                        <Button
                                                            color="success"
                                                            type="submit"
                                                            disabled={!this.state.loadingCheckAll}
                                                        >
                                                            <i className="uil-arrow-up-right"></i> Proses Data Tunjangan
                                                            Khusus
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </>
                                    ) : (
                                        <>
                                            {this.state.tunsusStatusName === "" ? (
                                                <LoadingComponent message="Memeriksa status Tunjangan Khusus..." />
                                            ) : (
                                                <>
                                                    <Row>
                                                        <Col md="12">
                                                            <Alert color="danger">
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i className="uil uil-times"></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.tunsusStatusName}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            <hr style={{ marginTop: 0 }} />
                                            <Row>
                                                <Col md="10">
                                                    <Link to="/payroll/tunsus-rapel.html">
                                                        <Button color="danger" type="button">
                                                            <i className="uil-arrow-left"></i> Kembali
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={this.state.showPopUp}
                    toggle={() => {
                        this.setState({
                            showPopUp: !this.state.showPopUp,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Data Master SIMKEU
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showPopUp: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="mb-3">
                            <Col md="12">
                                {this.state.loadingCheckPtkp === true ? (
                                    <LoadingComponent message="Memeriksa Data Referensi PTKP..." />
                                ) : (
                                    <Alert color={this.state.loadingCheckPtkpStatus ? "success" : "danger"}>
                                        <table width="100%">
                                            <tr>
                                                <td width="30px" style={{ verticalAlign: "top" }}>
                                                    <i
                                                        className={
                                                            "uil " +
                                                            (this.state.loadingCheckPtkpStatus
                                                                ? "uil-check"
                                                                : "uil-times")
                                                        }
                                                    ></i>
                                                </td>
                                                <td style={{ verticalAlign: "top" }}>
                                                    {this.state.loadingCheckPtkpText}
                                                </td>
                                                {!this.state.loadingCheckPtkpStatus ? (
                                                    <td className="text-end" style={{ verticalAlign: "top" }}>
                                                        <Link to="/master/referensi-ptkp.html">Master PTKP</Link>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </table>
                                    </Alert>
                                )}
                                {this.state.loadingCheckLevel === true ? (
                                    <LoadingComponent message="Memeriksa Data Referensi Level Khusus..." />
                                ) : (
                                    <Alert color={this.state.loadingCheckLevelStatus ? "success" : "danger"}>
                                        <table width="100%">
                                            <tr>
                                                <td width="30px" style={{ verticalAlign: "top" }}>
                                                    <i
                                                        className={
                                                            "uil " +
                                                            (this.state.loadingCheckLevelStatus
                                                                ? "uil-check"
                                                                : "uil-times")
                                                        }
                                                    ></i>
                                                </td>
                                                <td style={{ verticalAlign: "top" }}>
                                                    {this.state.loadingCheckLevelText}
                                                </td>
                                                {!this.state.loadingCheckLevelStatus ? (
                                                    <td className="text-end" style={{ verticalAlign: "top" }}>
                                                        <Link to="/master/tunsus.html">Master Level Khusus</Link>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </table>
                                    </Alert>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"
                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"
                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan mereset data ini?"
                    loadingTitle="Mereset data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil direset"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal direset"
                    showConfirm={this.state.resetConfirm}
                    showLoading={this.state.resetLoading}
                    showSuccess={this.state.resetSuccess}
                    showFailed={this.state.resetFailed}
                    response={this.confirmResetResponse}
                />
                {this.state.redirect ? <Redirect to="/payroll/tunsus-rapel.html" /> : null}
            </Fragment>
        )
    }
}

export default CreateView
