import {
    apiRequest,
    tplnRapelProcessSubmit,
    tplnRapelProcessEmployees,
    tplnRapelProcessEmployeeDetail,
    tplnRapelGetSigner,
    tplnRapelCheckStatus,
    tplnRapelCheckKurs,
    tplnRapelCheckGpp,
    tplnRapelCheckPtkp,
    tplnRapelCheckPkp,
    tplnRapelCheckCountry,
    tplnRapelCheckRank,
    tplnRapelCheckEmployeeNew,
    tplnRapelCheckEmployeeChange
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", tplnRapelProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", tplnRapelProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tplnRapelProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", tplnRapelGetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tplnRapelCheckStatus, {
            params: query
        }, true)
    }
    
    fetchCheckKurs = async (query) => {
        return await apiRequest("get", tplnRapelCheckKurs, {
            params: query
        }, true)
    }

    fetchCheckGpp = async (query) => {
        return await apiRequest("get", tplnRapelCheckGpp, {
            params: query
        }, true)
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest("get", tplnRapelCheckPtkp, {
            params: query
        }, true)
    }
    
    fetchCheckPkp = async (query) => {
        return await apiRequest("get", tplnRapelCheckPkp, {
            params: query
        }, true)
    }

    fetchCheckCountry = async (query) => {
        return await apiRequest("get", tplnRapelCheckCountry, {
            params: query
        }, true)
    }

    fetchCheckRank = async (query) => {
        return await apiRequest("get", tplnRapelCheckRank, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", tplnRapelCheckEmployeeNew, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", tplnRapelCheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository