import React from "react"
import { Alert, Spinner } from "reactstrap"
import Shimmer from "react-shimmer-effect"

const LoadingComponent = (props) => {
    return (
        <Shimmer>
            <Alert color="secondary" style={{ width: '100%', marginBottom: 10 }}>
                <table width="100%">
                    <tr>
                        <td width="30px" style={{verticalAlign: 'top'}}>
                            <Spinner type="grow" color="secondary" size="sm" style={{ width: 13, height: 13 }} />
                        </td>
                        <td style={{verticalAlign: 'top'}}>
                            { props.message }
                        </td>
                    </tr>
                </table>
            </Alert>
        </Shimmer>
    )
}

export default LoadingComponent