import { apiRequest, ptspRegulerVerify, ptspRegulerCompare } from "../../../../../../services/adapters/output"

class DownloadRepository {
    fetchDownloadVerify = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerVerify,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadCompare = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerCompare,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
