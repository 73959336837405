import {
    apiRequest,
    tplnT14Detail,
    tplnT14Ssp
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", tplnT14Detail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tplnT14Ssp, {
            params: query
        }, true)
    }
}

export default DownloadRepository