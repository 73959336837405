import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../../services/apiResponse'

import ReactTimeAgo from 'react-time-ago'

class TableListComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyMasterTaxPtkpList'
    defaultOrder = 'tax_ptkp_code'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        {
            key: "tax_ptkp_code",
            text: "Kode",
            align: 'center',
            width: '160',
            sortable: true,
        },
        {
            key: "tax_ptkp_id",
            text: "Keterangan",
            align: 'left',
            cell: record => {
                return ( '-' )
            }
        },
        {
            key: "tax_ptkp_value",
            text: "Nilai (Rp)",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            { record.tax_ptkp_value.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                        </div>
                    </>
                )
            }
        }
        /* {
            key: "region_create_date",
            text: "Tanggal",
            className: "rating",
            align: 'center',
            sortable: true,
            width: '160',
            cell: record => {
                const thisId = record.region_id
                if(record.region_create_date !== null) {
                    return (
                        <center>
                            <ReactTimeAgo id={"create-" + thisId} date={record.region_create_date} locale="id" timeStyle="round-minute" />
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["create" + thisId]} target={"create-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["create" + thisId] : !this.state["create" + thisId]
                                    })
                                }}
                            >{record.region_create_date}</Tooltip>
                        </center>
                    )
                } else {
                    return (
                        <center>
                            Tidak diketahui
                        </center>
                    )
                }
            }
        } */
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
            </>
        )
    }

}

export default TableListComponent