import { apiWrapper, apiCancel } from "../wrapper"

export const initial = "output"
const baseUrl = process.env.REACT_APP_SERVICE_OUTPUT_URL
const appCode = process.env.REACT_APP_SERVICE_OUTPUT_APPCODE
const outputType = "json"

export const sigapSalaryGaji = "integration/sigap/gaji/"

export const tplnRegulerDetail = "tpln/reguler/detail/"
export const tplnRegulerSsp = "tpln/reguler/ssp/"

export const tplnT13Detail = "tpln/t13/detail/"
export const tplnT13Ssp = "tpln/t13/ssp/"

export const tplnT14Detail = "tpln/t14/detail/"
export const tplnT14Ssp = "tpln/t14/ssp/"

export const tplnSusulanDetail = "tpln/susulan/detail/"
export const tplnSusulanSsp = "tpln/susulan/ssp/"

export const tplnRapelDetailBefore = "tpln/rapel/detailbefore/"
export const tplnRapelDetailAfter = "tpln/rapel/detailafter/"
export const tplnRapelDetailRapel = "tpln/rapel/detailrapel/"
export const tplnRapelDetailRefund = "tpln/rapel/detailrefund/"
export const tplnRapelSsp = "tpln/rapel/ssp/"

export const tunkinRegulerDetail = "tunkin/reguler/detail/"
export const tunkinRegulerSsp = "tunkin/reguler/ssp/"
export const tunkinRegulerBank = "tunkin/reguler/bank/"
export const tunkinRegulerNrs = "tunkin/reguler/nrs/"
export const tunkinRegulerVerify = "tunkin/reguler/verify/"
export const tunkinRegulerCompare = "tunkin/reguler/compare/"

export const tunkinT13Detail = "tunkin/t13/detail/"
export const tunkinT13Ssp = "tunkin/t13/ssp/"
export const tunkinT13Bank = "tunkin/t13/bank/"

export const tunkinT14Detail = "tunkin/t14/detail/"
export const tunkinT14Ssp = "tunkin/t14/ssp/"
export const tunkinT14Bank = "tunkin/t14/bank/"

export const tunkinSusulanDetail = "tunkin/susulan/detail/"
export const tunkinSusulanSsp = "tunkin/susulan/ssp/"

export const tunkinRapelDetailBefore = "tunkin/rapel/detailbefore/"
export const tunkinRapelDetailAfter = "tunkin/rapel/detailafter/"
export const tunkinRapelDetailRapel = "tunkin/rapel/detailrapel/"
export const tunkinRapelDetailRefund = "tunkin/rapel/detailrefund/"
export const tunkinRapelSsp = "tunkin/rapel/ssp/"

export const tunsusRegulerDetail = "tunsus/reguler/detail/"
export const tunsusRegulerSsp = "tunsus/reguler/ssp/"
export const tunsusRegulerBank = "tunsus/reguler/bank/"
export const tunsusRegulerNrs = "tunsus/reguler/nrs/"
export const tunsusRegulerRecap = "tunsus/reguler/recap/"
export const tunsusRegulerVerify = "tunsus/reguler/verify/"
export const tunsusRegulerCompare = "tunsus/reguler/compare/"

export const tunsusRapelDetailBefore = "tunsus/rapel/detailbefore/"
export const tunsusRapelDetailAfter = "tunsus/rapel/detailafter/"
export const tunsusRapelDetailRapel = "tunsus/rapel/detailrapel/"
export const tunsusRapelSsp = "tunsus/rapel/ssp/"

export const uangMakanRegularDetail = "uangmakan/regular/detail/"
export const uangMakanRegularSsp = "uangmakan/regular/ssp/"

export const uangMakanRapelDetailBefore = "uangmakan/rapel/detailbefore/"
export const uangMakanRapelDetailAfter = "uangmakan/rapel/detailafter/"
export const uangMakanRapelDetailRapel = "uangmakan/rapel/detailrapel/"
export const uangMakanRapelSsp = "uangmakan/rapel/ssp/"

export const overtimeDetail = "overtime/detail/"
export const overtimeSsp = "overtime/ssp/"

export const ptspRegulerDetail = "ptsp/reguler/detail/"
export const ptspRegulerDetailAll = "ptsp/reguler/detail_all/"
export const ptspRegulerSspAttach = "ptsp/reguler/sspattach/"
export const ptspRegulerSsp = "ptsp/reguler/ssp/"

export const ptspRegulerVerify = "ptsp/reguler/verify/"
export const ptspRegulerCompare = "ptsp/reguler/compare/"

export const ptspRegulerExport = "ptsp/reguler/export/"

export const ptspRapelDetailBefore = "ptsp/rapel/detailbefore/"
export const ptspRapelDetailAfter = "ptsp/rapel/detailafter/"
export const ptspRapelDetailRapel = "ptsp/rapel/detailrapel/"
export const ptspRapelSsp = "ptsp/rapel/ssp/"

export const payrollSkpSign = "skp/signSkp/"
export const payrollPayrollSign = "skp/signPayroll/"

export const sptRegular = "spt/regularDownload/"
export const sptFinal = "spt/finalDownload/"

export const officialTravelSpd = "officialtravel/spd/"
export const officialTravelSpds = "officialtravel/spds/"
export const officialTravelSt = "officialtravel/st/"
export const officialTravelDetail = "officialtravel/detail/"
export const officialTravelNominative = "officialtravel/nominative/"
export const officialTravelRiil = "officialtravel/riil/"
export const officialTravelCost = "officialtravel/cost/"
export const officialTravelSptb = "officialtravel/sptb/"
export const officialTravelMonitoring = "officialtravel/monitoring/"

export const officialTravelRapelSpd = "officialtravelrapel/spd/"
export const officialTravelRapelSpds = "officialtravelrapel/spds/"
export const officialTravelRapelSt = "officialtravelrapel/st/"
export const officialTravelRapelDetail = "officialtravelrapel/detail/"
export const officialTravelRapelNominative = "officialtravelrapel/nominative/"
export const officialTravelRapelRiil = "officialtravelrapel/riil/"
export const officialTravelRapelCost = "officialtravelrapel/cost/"
export const officialTravelRapelSptb = "officialtravelrapel/sptb/"
export const officialTravelRapelMonitoring = "officialtravelrapel/monitoring/"

export const dashboardReport = "dashboard/report/"
export const dashboardIcon = "dashboard/icon/"
export const dashboardGpp = "dashboard/gpp/"
export const dashboardSigap = "dashboard/sigap/"
export const dashboardDaftarPekerjaan = "dashboard/daftarpekerjaan/"
export const dashboardPengajuan = "dashboard/pengajuan/"
export const dashboardFilter = "dashboard/filteroption/"
export const dashboardGetLayanan = "dashboard/get_layanan/"
export const dashboardGetSubLayanan = "dashboard/get_sub_layanan/"
export const dashboardGetInformasi = "dashboard/get_informasi/"
export const dashboardGetNotifPersetujuan = "dashboard/get_notif_persetujuan/"
export const dashboardGetPerhitungan = "dashboard/get_perhitungan/"
export const dashboardGetMonitoringOP = "dashboard/get_monitoring_op/"
export const dashboardGetPerhitunganOP = "dashboard/get_perhitungan_op/"
export const dashboardGetLayananOP = "dashboard/get_layanan_op/"
export const informasiGetPengumuman = "information/get_pengumuman/"
export const informasiGetPeraturan = "information/get_peraturan/"
export const informasiGetFaq = "information/get_faq/"

export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (
    method,
    route,
    data = { params: null, body: null, cancelToken: null },
    needToken = true
) => {
    if (needToken) {
        const thisTime = Math.floor(Date.now() / 1000)
        const expire = localStorage.getItem(initial + "accessExpired")
        if (expire < thisTime) {
            await apiWrapper(baseUrl, initial, appCode, outputType, "post", "auth/getAccessToken/", data)
                .then((result) => {
                    localStorage.setItem(initial + "accessToken", result.response.access_token)
                    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
                })
                .catch((result) => {
                    return Promise.reject("Tidak dapat mengambil accessToken")
                })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}
