import React, { Fragment, Component } from "react"
import base64 from "base-64"
import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, Label, Modal, Alert } from "reactstrap"
import Select from "react-select"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Shimmer from "react-shimmer-effect"
import { ConfirmAlert } from "../../../../../helpers/ui/alert"
import CustomSelect from "../../../../../helpers/ui/CustomSelect"
import { FileInput, generateFormData } from "../../../../../helpers/ui/FileInput"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect } from "react-router-dom"
import CreateBloc from "./Blocs/CreateBloc"
import apiResponse from "../../../../../services/apiResponse"

import OfficialTravelDetailComponent from "./Components/OfficialTravelDetailComponent"

import moment from "moment"

class CreateView extends Component {
    createBloc = new CreateBloc()
    source

    constructor(props) {
        super(props)
        this.state = {
            loadingUnprocessList: false,
            unprocessList: [],

            processUuid: null,

            detailUuid: null,
            showDetail: false,

            loadingParticipantList: false,
            participantList: [],

            submissionCheck: [0, 0, 0, 0],

            selectedType: null,
            optionType: [
                { value: 1, label: "Dalam Negeri" },
                { value: 2, label: "Luar Negeri" },
            ],
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, {
            field: "document",
            name: "Trash/Unknown",
        })
        await this.createBloc.fetchCreateInitial(formData)
    }

    handleOnChangeType = (selectedType) => {
        if (this.state.selectedType === null || this.state.selectedType.value !== selectedType.value) {
            this.setState(
                {
                    selectedType: selectedType,
                    loadingUnprocessList: true,
                    loadingParticipantList: false,
                    participantList: [],
                    submissionCheck: [0, 0, 0, 0],
                },
                () => {
                    this.createBloc.fetchUnprocessList({
                        type: this.state.selectedType.value,
                    })
                }
            )
        }
    }
    handleChangeUnprocessList = (value) => {
        this.setState({
            submissionCheck: [value.official_travel_process_type1_official_travel_other_uuid === null ? 1 : 0, value.official_travel_process_type2_official_travel_other_uuid === null ? 1 : 0, value.official_travel_process_type3_official_travel_other_uuid === null ? 1 : 0, value.official_travel_process_type4_official_travel_other_uuid === null ? 1 : 0],
            loadingParticipantList: true,
        })
        this.createBloc.fetchUnprocessParticipantList({
            uuid: value.official_travel_process_uuid,
        })
    }

    componentDidMount() {
        this.createBloc.createInitialChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    var response = result.data.response
                    this.setState({
                        processUuid: response,
                        loading: false,
                        success: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.createBloc.unprocessListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({})
                    break
                case apiResponse.COMPLETED:
                    this.setState({
                        loadingUnprocessList: false,
                        unprocessList: result.data.response.length > 0 ? result.data.response : [],
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loadingUnprocessList: false,
                    })
                    break
                default:
                    break
            }
        })

        this.createBloc.unprocessParticipantListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingParticipantList: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    var response = result.data.response
                    this.setState({
                        loadingParticipantList: false,
                        participantList: response,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })
    }

    numberWithPoint(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
            },
            confirm: true,
        })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Tambah Proses Pengajuan Rapel Perjalanan Dinas</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm className="needs-validation" onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}>
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Tujuan<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="3">
                                                <Select
                                                    value={this.state.selectedType}
                                                    onChange={(selected) => {
                                                        this.handleOnChangeType(selected)
                                                    }}
                                                    options={this.state.optionType}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Nomor SPD<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6" style={{ height: 38 }}>
                                                {this.state.loadingUnprocessList ? (
                                                    <>
                                                        <Shimmer>
                                                            <div
                                                                className="shimmer"
                                                                style={{
                                                                    width: "100%",
                                                                    height: 38,
                                                                }}
                                                            ></div>
                                                        </Shimmer>
                                                    </>
                                                ) : (
                                                    <>
                                                        {this.state.unprocessList.length > 0 ? (
                                                            <CustomSelect
                                                                //isMulti={true}
                                                                name="processUuid"
                                                                isDisabled={this.state.loadingParticipantList}
                                                                isLoading={this.state.loadingParticipantList}
                                                                errorMessage="Pilih Surat Tugas"
                                                                options={this.state.unprocessList}
                                                                //defaultValue={this.state.routeUuid}
                                                                noOptionsMessage={() => "Tidak ada Pilihan"}
                                                                placeholder="Pilih Surat Tugas"
                                                                optionLabel="official_travel_process_number"
                                                                optionValue="official_travel_process_uuid"
                                                                onChange={this.handleChangeUnprocessList}
                                                            />
                                                        ) : (
                                                            <>
                                                                {!this.state.selectedType ? (
                                                                    <Select value={null} placeholder="Pilih Surat Tugas" isDisabled={true} options={{}} classNamePrefix="select2-selection" />
                                                                ) : (
                                                                    <Alert color="warning" className="text-center" style={{ padding: 7, marginBottom: 6 }}>
                                                                        Belum ada surat tugas {this.state.selectedType.label} yang dapat diproses, silahkan <Link to="/official-travel/register.html?create">daftarkan</Link> terlebih dahulu
                                                                    </Alert>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="description" className="col-md-2 col-form-label">
                                                Keterangan
                                            </Label>
                                            <Col md="6">
                                                <AvField name="description" placeholder="Keterangan..." type="textarea" errorMessage="Masukkan Keterangan" className="form-control" id="fielDescription" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="description" className="col-md-2 col-form-label">
                                                Data Pengajuan
                                            </Label>
                                            <Col md="6">
                                                <div className="form-check mb-3 mt-2">
                                                    <div className="d-flex flex">
                                                        <div className={"bg-soft-" + (this.state.submissionCheck[0] === 1 ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>Uang Harian</div>
                                                        <div className={"bg-soft-" + (this.state.submissionCheck[1] === 1 ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>{this.state.selectedType !== null && this.state.selectedType.value === 2 ? "Perjalanan" : "Penginapan"}</div>
                                                        <div className={"bg-soft-" + (this.state.submissionCheck[2] === 1 ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>Transportasi</div>
                                                        {this.state.selectedType !== null && this.state.selectedType.value === 2 ? <></> : <div className={"bg-soft-" + (this.state.submissionCheck[3] === 1 ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>Representatif</div>}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Partisipan
                                            </Label>
                                            <Col md="10">
                                                <Table className="table table-striped table-bordered table-hover">
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="20%" data-priority="1">
                                                                Nomor ST
                                                            </Th>
                                                            <Th width="*" data-priority="1">
                                                                Nama
                                                            </Th>
                                                            <Th width="160" className="text-end">
                                                                Uang Harian
                                                            </Th>
                                                            <Th width="160" className="text-end">
                                                                {this.state.selectedType !== null && this.state.selectedType.value === 1 ? "Penginapan" : "Perjalanan"}
                                                            </Th>
                                                            <Th width="160" className="text-end">
                                                                Transportasi
                                                            </Th>
                                                            <Th width="160" className="text-end" style={{ display: this.state.selectedType !== null && this.state.selectedType.value === 1 ? "" : "none" }}>
                                                                Representatif
                                                            </Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <>
                                                            {this.state.loadingParticipantList ? (
                                                                <>
                                                                    <tr>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                        <td style={{ display: this.state.selectedType !== null && this.state.selectedType.value === 1 ? "" : "none" }}>
                                                                            <Shimmer className="shimmer">
                                                                                <div style={{ width: "100%", height: 16 }}></div>
                                                                            </Shimmer>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {this.state.participantList.map((item, key) => {
                                                                        return (
                                                                            <tr>
                                                                                <td className="text-center">
                                                                                    <span
                                                                                        className="link-primary"
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    detailUuid: item.official_travel_uuid,
                                                                                                },
                                                                                                () => {
                                                                                                    this.setState({
                                                                                                        showDetail: true,
                                                                                                    })
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {item.official_travel_number}
                                                                                    </span>
                                                                                </td>
                                                                                <td>{item.employee_name}</td>
                                                                                <td className="text-end">Rp. {this.numberWithPoint(item.official_travel_process_emp_value_daily.replace(".00", "").replace(".", ","))},-</td>
                                                                                <td className="text-end">Rp. {this.numberWithPoint(item.official_travel_process_emp_value_hotel.replace(".00", "").replace(".", ","))},-</td>
                                                                                <td className="text-end">Rp. {this.numberWithPoint(item.official_travel_process_emp_value_transport.replace(".00", "").replace(".", ","))},-</td>
                                                                                <td className="text-end" style={{ display: this.state.selectedType !== null && this.state.selectedType.value === 1 ? "" : "none" }}>
                                                                                    Rp. {this.numberWithPoint(item.official_travel_process_emp_value_representative.replace(".00", "").replace(".", ","))},-
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </>
                                                            )}
                                                        </>
                                                    </Tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                {this.state.participantList.length > 0 ? (
                                                    <>
                                                        <Button color="success" type="submit">
                                                            <i className="uil-arrow-up-right"></i> Simpan Data
                                                        </Button>{" "}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <Link to="/official-travel/submission.html">
                                                    <Button color="danger" type="button">
                                                        <i className="uil-arrow-left"></i> Kembali
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Detail Surat Tugas
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <OfficialTravelDetailComponent uuid={this.state.detailUuid} />
                    </div>
                </Modal>
                <ConfirmAlert confirmTitle="Konfirmasi!" confirmInfo="Apakah anda yakin akan menyimpan data ini?" loadingTitle="Mengirim data..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="Data berhasil disimpan" failedTitle="Gagal!" failedInfo="Data gagal disimpan" showConfirm={this.state.confirm} showLoading={this.state.loading} showSuccess={this.state.success} showFailed={this.state.failed} response={this.confirmResponse} />
                {this.state.redirect ? <Redirect to={"/official-travel/finalization.html?process&uuid=" + this.state.processUuid} /> : null}
            </Fragment>
        )
    }
}

export default CreateView
