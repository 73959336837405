import {
    apiRequest,
    sigapFamilyList,
    sigapFamilyDetail
} from "../../../../../../services/adapters/sync"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", sigapFamilyList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    fetchDetailTable = async (query, cancelToken) => {
        return await apiRequest("get", sigapFamilyDetail, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository