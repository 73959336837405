import {
    apiRequest,
    payrollSptYears,
    payrollSptCheckStatusRegular,
    payrollSptCheckStatusFinal,
    payrollSptListRegular,
    payrollSptListFinal,
    payrollSptCreateRegular,
    payrollSptCreateFinal,
    payrollSptUpdate
} from "../../../../../services/adapters/process"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", payrollSptYears, {}, true)
    }
    
    fetchRowTableRegular = async (query, cancelToken) => {
        return await apiRequest("get", payrollSptListRegular, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableFinal = async (query, cancelToken) => {
        return await apiRequest("get", payrollSptListFinal, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchCheckStatusRegular = async (query) => {
        return await apiRequest("get", payrollSptCheckStatusRegular, {
            params: query
        }, true)
    }
    
    fetchCheckStatusFinal = async (query) => {
        return await apiRequest("get", payrollSptCheckStatusFinal, {
            params: query
        }, true)
    }

    fetchCreateRegular = async (query) => {
        return await apiRequest("post", payrollSptCreateRegular, {
            body: query
        }, true)
    }
    
    fetchCreateFinal = async (query) => {
        return await apiRequest("post", payrollSptCreateFinal, {
            body: query
        }, true)
    }
    
    fetchUpdate = async (query) => {
        return await apiRequest("post", payrollSptUpdate, {
            body: query
        }, true)
    }
}

export default IndexViewRepository