import {
    apiRequest,
    masterTaxPtkp2024Detail,
    masterTaxPtkp2024Update
    // masterReferenceSignerUpdate
} from "../../../../../../services/adapters/master"

class UpdateRepository {

    
    fetchDetail = async (query, cancelToken) => {
        return await apiRequest("get", masterTaxPtkp2024Detail, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", masterTaxPtkp2024Update, {
            body: query
        }, true)
    }

}

export default UpdateRepository