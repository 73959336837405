import {
    apiRequest,
    tplnT13ProcessSubmit,
    tplnT13ProcessEmployees,
    tplnT13ProcessEmployeeDetail,
    tplnT13GetSigner,
    tplnT13CheckStatus,
    tplnT13CheckKurs,
    tplnT13CheckGpp,
    tplnT13CheckPtkp,
    tplnT13CheckPkp,
    tplnT13CheckCountry,
    tplnT13CheckRank,
    tplnT13CheckEmployeeNew,
    tplnT13CheckEmployeeChange
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", tplnT13ProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", tplnT13ProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tplnT13ProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", tplnT13GetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tplnT13CheckStatus, {
            params: query
        }, true)
    }
    
    fetchCheckKurs = async (query) => {
        return await apiRequest("get", tplnT13CheckKurs, {
            params: query
        }, true)
    }

    fetchCheckGpp = async (query) => {
        return await apiRequest("get", tplnT13CheckGpp, {
            params: query
        }, true)
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest("get", tplnT13CheckPtkp, {
            params: query
        }, true)
    }
    
    fetchCheckPkp = async (query) => {
        return await apiRequest("get", tplnT13CheckPkp, {
            params: query
        }, true)
    }

    fetchCheckCountry = async (query) => {
        return await apiRequest("get", tplnT13CheckCountry, {
            params: query
        }, true)
    }

    fetchCheckRank = async (query) => {
        return await apiRequest("get", tplnT13CheckRank, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", tplnT13CheckEmployeeNew, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", tplnT13CheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository