import {
    apiRequest,
    tunkinRapelMonths,
    tunkinRapelHistory,
    tunkinRapelPushSPM,
    tunkinRapelUpdateSPM
} from "../../../../../../services/adapters/process"

class IndexViewRepository {

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", tunkinRapelMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", tunkinRapelHistory, {
            params: query
        }, true)
    }

    fetchPushSPM = async (query) => {
        return await apiRequest("post", tunkinRapelPushSPM, {
            body: query
        }, true)
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest("post", tunkinRapelUpdateSPM, {
            body: query
        }, true)
    }
}

export default IndexViewRepository