import {
    apiRequest,
    tplnRapelYears,
    tplnRapelMonths,
    tplnRapelHistory,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", tplnRapelYears, {}, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", tplnRapelMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", tplnRapelHistory, {
            params: query
        }, true)
    }
}

export default IndexViewRepository