import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'

class CreateBloc {

    createChannel = new Subject()
    verifyChannel = new Subject()
    employeeChannel = new Subject()
    employeeDetailChannel = new Subject()
    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.verifyChannel.next({ status: apiResponse.INITIAL })
        this.employeeChannel.next({ status: apiResponse.INITIAL })
        this.employeeDetailChannel.next({ status: apiResponse.INITIAL })
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchVerify = async (query) => {
        this.verifyChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchVerify(query).then((result) => {
                this.verifyChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifyChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchEmployeeDetail = async (query) => {
        this.employeeDetailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployeeDetail(query).then((result) => {
                this.employeeDetailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeDetailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchEmployeeList = async (query, cancelToken) => {
        this.employeeChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchEmployeeList(query, cancelToken).then((result) => {
                this.employeeChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.employeeChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

}

export default CreateBloc