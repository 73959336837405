import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../../services/apiResponse'

import ReactTimeAgo from 'react-time-ago'

class TableListComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyMasterTaxPkpList'
    defaultOrder = 'tax_pkp_range_start'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        {
            key: "tax_pkp_range_start",
            text: "Diatas",
            align: 'center',
            width: '160',
            sortable: true,
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            {
                                record.tax_pkp_range_start.toString() === '-1.00' ?
                                    '0'
                                    :
                                    <>
                                        {record.tax_pkp_range_start.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                    </>
                            }
                        </div>
                    </>
                )
            }
        },
        {
            key: "tax_pkp_range_end",
            text: "Sampai",
            align: 'center',
            width: '160',
            sortable: true,
            cell: record => {
                return (
                    <>
                        <div className="text-start">
                            {
                                record.tax_pkp_range_end.toString() === '9999999999999.00' ?
                                    '~'
                                    :
                                    <>
                                        {record.tax_pkp_range_end.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                    </>
                            }
                        </div>
                    </>
                )
            }
        },
        {
            key: "tax_pkp_percentage",
            text: "Penghitungan PKP",
            align: 'center',
            sortable: true,
            cell: record => {
                var beforeSymbol = record.tax_pkp_add_before >= 0 ? ' + ' : ' - ';
                var beforeValue = record.tax_pkp_add_before >= 0 ? (record.tax_pkp_add_before * 1) : (record.tax_pkp_add_before * -1);

                var afterSymbol = record.tax_pkp_add_after >= 0 ? ' + ' : ' - ';
                var afterValue = record.tax_pkp_add_after >= 0 ? (record.tax_pkp_add_after * 1) : (record.tax_pkp_add_after * -1);

                var outputText = '<span className="bg-soft-primary badge rounded-pill font-size-12">Pendapatan</span>'

                if (beforeValue > 0) {
                    outputText = '(' + outputText + beforeSymbol + '<span className="bg-soft-danger badge rounded-pill font-size-12">' + beforeValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '</span>)'
                }

                outputText = '(' + outputText + ' * <span className="bg-soft-success badge rounded-pill font-size-12">' + record.tax_pkp_percentage.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '</span>)'

                if (afterValue > 0) {
                    outputText = '(' + outputText + afterSymbol + '<span className="bg-soft-secondary badge rounded-pill font-size-12">' + afterValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '</span>)'
                }

                return (
                    <div dangerouslySetInnerHTML={{ __html: outputText.replace(/(<? *script)/gi, 'illegalscript') }} ></div>
                )
            }
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
            </>
        )
    }

}

export default TableListComponent