import {
    apiRequest,
    masterMappingIIPC,
    masterCmsGetInformasi
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterMappingIIPC, {
            body: query
        }, true)
    }

    fetchGetInformasi = async () => {
        return await apiRequest("get", masterCmsGetInformasi, {}, true)
    }
}

export default CreateRepository