import {
    apiRequest,
    uangMakanRapelDetailBefore,
    uangMakanRapelDetailAfter,
    uangMakanRapelDetailRapel,
    uangMakanRapelSsp
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetailBefore = async (query) => {
        return await apiRequest("get", uangMakanRapelDetailBefore, {
            params: query
        }, true)
    }

    fetchDownloadDetailAfter = async (query) => {
        return await apiRequest("get", uangMakanRapelDetailAfter, {
            params: query
        }, true)
    }

    fetchDownloadDetailRapel = async (query) => {
        return await apiRequest("get", uangMakanRapelDetailRapel, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", uangMakanRapelSsp, {
            params: query
        }, true)
    }
}

export default DownloadRepository