import { Subject } from "rxjs"
import apiResponse from "../../../../../../services/apiResponse"
import CreateRepository from "../Repositories/CreateRepository"

class CreateBloc {
    createInitialChannel = new Subject()
    unprocessListChannel = new Subject()
    unprocessParticipantListChannel = new Subject()
    repository = new CreateRepository()

    constructor() {
        this.createInitialChannel.next({ status: apiResponse.INITIAL })
        this.unprocessListChannel.next({ status: apiResponse.INITIAL })
        this.unprocessParticipantListChannel.next({ status: apiResponse.INITIAL })
    }

    fetchCreateInitial = async (query) => {
        this.createInitialChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchCreateInitial(query)
                .then((result) => {
                    this.createInitialChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.createInitialChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchUnprocessList = async (query) => {
        this.unprocessListChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchUnprocessList(query)
                .then((result) => {
                    this.unprocessListChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.unprocessListChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchUnprocessParticipantList = async (query) => {
        this.unprocessParticipantListChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchUnprocessParticipantList(query)
                .then((result) => {
                    this.unprocessParticipantListChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.unprocessParticipantListChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }
}

export default CreateBloc
