import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import {
    Tooltip,
    Modal,
} from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../services/apiResponse'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import DetailComponent from './DetailComponent'

import ReactTimeAgo from 'react-time-ago'

class TableActiveComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historySettingAccess'
    defaultOrder = 'access_name'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            showDetail: false,
            accessId: null,
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })


        this.dataTableBloc.inactiveChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        loadingTable: true
                    }, function () {
                        this.setTokenAPI()
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.inactiveData()
                break;
            case 'success':
                forState = {
                    success: false,
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    inactiveData = async () => {
        await this.dataTableBloc.fetchInactive({ id: this.state.accessId })
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
        this.dataTableBloc.inactiveChannel.unsubscribe()
    }

    columns = [
        {
            key: "access_code",
            text: "Kode",
            align: 'center',
            sortable: true,
            width: '140'
        },
        {
            key: "access_name",
            text: "Nama Akses",
            sortable: true,
        },
        {
            key: "access_member",
            text: "Pengguna",
            align: 'center',
            sortable: true,
            width: '140',
            cell: record => {
                const thisData = record.access_member === 9999 ? '~' : record.access_member;
                return (
                    <Fragment>{thisData}</Fragment>
                );
            },
        },
        {
            key: "action",
            text: "Action",
            width: 120,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisId = record.access_uuid
                return (
                    <Fragment>
                        <span>
                            <button
                                className="btn btn-success btn-sm"
                                id={"detail-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        accessId: record.access_id,
                                        showDetail: true
                                    });
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-align-left"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["detail" + thisId] : !this.state["detail" + thisId]
                                    })
                                }}
                            >Detail</Tooltip>
                        </span>
                        {accessRole('delete') ?
                            <span>
                                <button
                                    className="btn btn-danger btn-sm"
                                    id={"delete-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            accessId: record.access_id,
                                            confirm: true
                                        })
                                    }}
                                    style={{
                                        padding: '2px 5px'
                                    }}>
                                    <i className="uil-trash-alt"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["delete" + thisId]} target={"delete-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["delete" + thisId]: !this.state["delete" + thisId]
                                        })
                                    }}
                                >Nonaktifkan</Tooltip>
                            </span>
                            :
                            <button
                                className="btn btn-soft-secondary btn-sm" disabled={true}
                                style={{
                                    padding: '2px 5px'
                                }}>
                                <i className="uil-trash-alt"></i>
                            </button>
                        }
                    </Fragment>
                );
            }
        }
    ]
    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menghapus data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dihapus"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dihapus"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />

                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelx"
                        >
                            Detail 
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <DetailComponent id={this.state.accessId} />
                    </div>
                </Modal>
            </>
        )
    }

}

export default TableActiveComponent