import {
    apiRequest,
    ptspRegulerYears,
    ptspRegulerMonths,
    ptspRegulerHistory,
} from "../../../../../services/adapters/process"

class IndexViewRepository {
    fetchYears = async () => {
        return await apiRequest("get", ptspRegulerYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            ptspRegulerMonths,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchHistory = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerHistory,
            {
                params: query,
            },
            true
        )
    }
}

export default IndexViewRepository
