import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import IndexViewRepository from '../Repositories/IndexViewRepository'


class IndexViewBloc {

    regulerMonthChannel = new Subject();
    createChannel = new Subject();
    monthsChannel = new Subject();
    historyChannel = new Subject();
    spmChannel = new Subject()
    spmUpdateChannel = new Subject()
    repository = new IndexViewRepository()

    constructor() {
        this.regulerMonthChannel.next({status: apiResponse.INITIAL})
        this.createChannel.next({status: apiResponse.INITIAL})
        this.monthsChannel.next({status: apiResponse.INITIAL})
        this.historyChannel.next({status: apiResponse.INITIAL})
        this.spmChannel.next({status: apiResponse.INITIAL})
        this.spmUpdateChannel.next({status: apiResponse.INITIAL})
    }

    fetchRegulerMonts = async (query) => {
        this.regulerMonthChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRegulerMonts(query).then((result) => {
                this.regulerMonthChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.regulerMonthChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchCreate = async (query) => {
        this.createChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.createChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    
    fetchMonths = async (query, cancelToken) => {
        this.monthsChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchMonths(query, cancelToken).then((result) => {
                this.monthsChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.monthsChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchHistory = async (query) => {
        this.historyChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchHistory(query).then((result) => {
                this.historyChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.historyChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchPushSPM = async (query) => {
        this.spmChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchPushSPM(query)
                .then((result) => {
                    this.spmChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.spmChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchUpdateSPM = async (query) => {
        this.spmUpdateChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchUpdateSPM(query)
                .then((result) => {
                    this.spmUpdateChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.spmUpdateChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

}

export default IndexViewBloc