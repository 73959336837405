import {
    apiRequest,
    ptspRegulerProcessSubmit,
    ptspRegulerProcessEmployees,
    ptspRegulerProcessVerify,
    ptspRegulerProcessEmployeeDetail,
} from "../../../../../services/adapters/process"

class CreateRepository {
    fetchCreate = async (query) => {
        return await apiRequest(
            "post",
            ptspRegulerProcessSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchVerify = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerProcessVerify,
            {
                params: query,
            },
            true
        )
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerProcessEmployeeDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            ptspRegulerProcessEmployees,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }
}

export default CreateRepository
