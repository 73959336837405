import React, { Fragment, useState, useRef } from "react"
import DataTableComponent from './Components/DataTableComponent'
import classnames from "classnames"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from "reactstrap"


const IndexView = (props) => {

    const active_tab = localStorage.getItem('active-tab-umum')

    const [activeTab, setactiveTab] = useState(active_tab ? active_tab : "1")
    const activeRef = useRef();
    const inactiveRef = useRef();

    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()
    const reloadActiveRef = () => inactiveRef.current.setTokenAPI()

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
        localStorage.setItem("active-tab-umum", tab)
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Notifikasi</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Daftar notifikasi aplikasi, data yang bertanda bold merupakan data notifikasi yang belum pernah dibuka.
                                </CardSubtitle>
                                <hr />
                                <DataTableComponent />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView