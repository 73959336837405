import {
    apiRequest,
    masterMappingIIPCDetail,
    masterMappingIIPCEdit,
    masterGetMappingIIPC
} from "../../../../../../services/adapters/master"

class UpdateRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", masterMappingIIPCDetail, {
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", masterMappingIIPCEdit, {
            body: query
        }, true)
    }

    fetchGetLayanan = async () => {
        return await apiRequest("get", masterGetMappingIIPC, {}, true)
    }
}

export default UpdateRepository