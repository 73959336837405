import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect"
import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Modal,
    Alert,
    Spinner,
} from "reactstrap"

import { ConfirmAlert } from "../../../../../helpers/ui/alert"
import { generateFormData } from "../../../../../helpers/ui/FileInput"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect, withRouter } from "react-router-dom"
import apiResponse from "../../../../../services/apiResponse"
import EmployeeListComponent from "./Components/EmployeeListComponent"
import CreateBloc from "./Blocs/CreateBloc"
import CustomSelect from "../../../../../helpers/ui/CustomSelect"

import LoadingComponent from "./Components/LoadingComponent"
import { timesSeries } from "async"

class CreateView extends Component {
    createBloc = new CreateBloc()

    source
    constructor(props) {
        super(props)
        this.state = {
            tunkinStatus: null,
            tunkinReason: "",
            tunkinMonth: 0,
            tunkinStatusName: "",
            uuid: null,
            dataForm: {},
            loadingCheckAll: false,
            loadingCheckPopup: true,
            loadingCheckPopupStatus: false,
            showPopUp: false,
            loadingCheckPresence: true,
            loadingCheckLevel: true,
            loadingCheckGpp: true,
            loadingCheckPtkp: true,
            loadingCheckPkp: true,
            loadingCheckOtherMonth: true,
            loadingCheckEmployeeNew: true,
            loadingCheckEmployeeChange: true,

            loadingCheckPresenceStatus: false,
            loadingCheckLevelStatus: false,
            loadingCheckPtkpStatus: false,
            loadingCheckPkpStatus: false,
            loadingCheckGppStatus: false,
            loadingCheckOtherMonthStatus: false,

            loadingSignerLeft: true,
            loadingSignerRight: true,
        }
        this.createBloc.fetchCheckStatus({ uuid: this.props.uuid })
    }

    allPAss = () => {
        if (
            this.state.loadingCheckPresence === false &&
            this.state.loadingCheckLevel === false &&
            this.state.loadingCheckPtkp === false &&
            this.state.loadingCheckPkp === false
        ) {
            if (this.state.tunkinMonth === 12) {
                if (this.state.loadingCheckOtherMonth === false) {
                    this.setState({
                        loadingCheckPopup: false,
                    })
                }
            } else {
                this.setState({
                    loadingCheckPopup: false,
                })
            }
        } else {
            this.setState({
                loadingCheckPopup: true,
            })
        }

        if (
            this.state.loadingCheckPresenceStatus === true &&
            this.state.loadingCheckLevelStatus === true &&
            this.state.loadingCheckPtkpStatus === true &&
            this.state.loadingCheckPkpStatus === true
        ) {
            if (this.state.tunkinMonth === 12) {
                if (this.state.loadingCheckOtherMonthStatus === true) {
                    this.setState({
                        loadingCheckPopupStatus: true,
                    })
                }
            } else {
                this.setState({
                    loadingCheckPopupStatus: true,
                })
            }
        } else {
            this.setState({
                loadingCheckPopupStatus: false,
            })
        }

        if (
            this.state.loadingCheckPresenceStatus === true &&
            this.state.loadingCheckLevelStatus === true &&
            this.state.loadingCheckPtkpStatus === true &&
            this.state.loadingCheckPkpStatus === true &&
            this.state.loadingCheckGppStatus === true &&
            this.state.loadingSignerLeft === false &&
            this.state.loadingSignerRight === false
        ) {
            if (this.state.tunkinMonth === 12) {
                if (this.state.loadingCheckOtherMonthStatus === true) {
                    this.setState({
                        loadingCheckAll: true,
                    })
                }
            } else {
                this.setState({
                    loadingCheckAll: true,
                })
            }
        } else {
            this.setState({
                loadingCheckAll: false,
            })
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm)
        await this.createBloc.fetchCreate(formData)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
            },
            confirm: true,
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    submitReset = async () => {
        var formData = new FormData()
        formData.append("uuid", this.props.uuid)
        await this.createBloc.fetchReset(formData)
    }

    handlerResetSubmit = () => {
        this.setState({
            resetConfirm: true,
        })
    }

    confirmResetResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    resetConfirm: false,
                }
                break
            case "confirm":
                forState = {
                    resetLoading: true,
                    resetConfirm: false,
                }
                this.submitReset()
                break
            case "success":
                forState = {
                    resetSuccess: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    resetFailed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.createBloc.checkStatusChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    if (response.status === 1 || response.status === 3) {
                        this.setState({
                            tunkinStatus: response.status,
                            tunkinReason: response.reason,
                            tunkinMonth: response.month,
                        })
                        this.createBloc.fetchCheckGpp({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckPresence({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckLevel({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckPtkp({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckPkp({ uuid: this.props.uuid })
                        if (response.month === 12) {
                            this.createBloc.fetchCheckOtherMonth({ uuid: this.props.uuid })
                        }
                        //this.createBloc.fetchCheckEmployeeNew({uuid: this.props.uuid})
                        //this.createBloc.fetchCheckEmployeeChange({uuid: this.props.uuid})
                        this.createBloc.fetchGetSignerLeft({ uuid: "8e76798e-b27f-4276-a375-60a001d3c225" })
                        this.createBloc.fetchGetSignerRight({ uuid: "2f087de1-7714-441c-9255-61279bdd87f2" })
                    } else {
                        this.setState({
                            tunkinStatusName: "Proses tidak dapat dilakukan",
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })

        this.createBloc.getSignerLeft.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingSignerLeft: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingSignerLeft: false,
                        signerLeft: response,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })

        this.createBloc.getSignerRight.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingSignerRight: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingSignerRight: false,
                        signerRight: response,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })

        this.createBloc.checkPresenceChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckPresence: false,
                            loadingCheckPresenceStatus: true,
                            loadingCheckPresenceText: "Data Absensi tersedia",
                            loadingCheckPresenceDetail: [],
                        })
                    } else {
                        this.setState({
                            loadingCheckPresence: false,
                            loadingCheckPresenceStatus: false,
                            loadingCheckPresenceText: response.message,
                            loadingCheckPresenceDetail: response.response,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkLevelChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckLevel: false,
                            loadingCheckLevelStatus: true,
                            loadingCheckLevelText: "Data Grade tersedia",
                        })
                    } else {
                        this.setState({
                            loadingCheckLevel: false,
                            loadingCheckLevelStatus: false,
                            loadingCheckLevelText: response.message,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkGppChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckGpp: false,
                            loadingCheckGppStatus: true,
                            loadingCheckGppText: "Data GPP tersedia",
                            loadingCheckGppDetail: [],
                        })
                    } else {
                        this.setState({
                            loadingCheckGpp: false,
                            loadingCheckGppStatus: false,
                            loadingCheckGppText: response.message,
                            loadingCheckGppDetail: response.response,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkPtkpChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckPtkp: false,
                            loadingCheckPtkpStatus: true,
                            loadingCheckPtkpText: "Data PTKP tersedia",
                        })
                    } else {
                        this.setState({
                            loadingCheckPtkp: false,
                            loadingCheckPtkpStatus: false,
                            loadingCheckPtkpText: response.message,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkPkpChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckPkp: false,
                            loadingCheckPkpStatus: true,
                            loadingCheckPkpText: "Data PKP tersedia",
                        })
                    } else {
                        this.setState({
                            loadingCheckPkp: false,
                            loadingCheckPkpStatus: false,
                            loadingCheckPkpText: response.message,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkOtherMonthChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckOtherMonth: false,
                            loadingCheckOtherMonthStatus: true,
                            loadingCheckOtherMonthText: "Data tunkin dan atau Tunkin bulan sebelumnya sudah diproses",
                            loadingCheckOtherMonthDetail: [],
                        })
                    } else {
                        this.setState({
                            loadingCheckOtherMonth: false,
                            loadingCheckOtherMonthStatus: false,
                            loadingCheckOtherMonthText: response.message,
                            loadingCheckOtherMonthDetail: response.response,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkEmployeeNewChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckEmployeeNew: false,
                            loadingCheckEmployeeNewStatus: true,
                            loadingCheckEmployeeNewText: "Tidak ada pegawai baru",
                            loadingCheckEmployeeNewDetail: [],
                        })
                    } else {
                        this.setState({
                            loadingCheckEmployeeNew: false,
                            loadingCheckEmployeeNewStatus: false,
                            loadingCheckEmployeeNewText: response.message,
                            loadingCheckEmployeeNewDetail: response.response,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })
        this.createBloc.checkEmployeeChangeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckEmployeeChange: false,
                            loadingCheckEmployeeChangeStatus: true,
                            loadingCheckEmployeeChangeText: "Tidak ada perubahan data pegawai",
                            loadingCheckEmployeeChangeDetail: [],
                        })
                    } else {
                        this.setState({
                            loadingCheckEmployeeChange: false,
                            loadingCheckEmployeeChangeStatus: false,
                            loadingCheckEmployeeChangeText: response.message,
                            loadingCheckEmployeeChangeDetail: response.response,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })

        this.createBloc.resetChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        resetLoading: false,
                        resetSuccess: true,
                        redirect: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        resetLoading: false,
                        resetFailed: true,
                    })
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
        this.createBloc.checkStatusChannel?.unsubscribe()
        this.createBloc.checkPresenceChannel?.unsubscribe()
        this.createBloc.checkGppChannel?.unsubscribe()
        this.createBloc.checkPtkpChannel?.unsubscribe()
        this.createBloc.checkEmployeeNewChannel?.unsubscribe()
        this.createBloc.checkEmployeeChangeChannel?.unsubscribe()
        this.createBloc.getSignerLeft?.unsubscribe()
        this.createBloc.getSignerRight?.unsubscribe()
        this.createBloc.resetChannel?.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <div className="float-end">
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                this.handlerResetSubmit()
                                            }}
                                        >
                                            <i className="uil-refresh"></i> Reset Data Tunkin
                                        </Button>
                                    </div>
                                    <CardTitle>Proses Tunjangan Kinerja Reguler</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Pengecekan data Tunjangan Kinerja Reguler perpegawain dan pemrosesan data
                                        Tunjangan Kinerja
                                    </CardSubtitle>
                                    <hr />
                                    {this.state.tunkinStatus ? (
                                        <>
                                            {this.state.tunkinStatus === 3 ? (
                                                <>
                                                    <Row className="mb-3">
                                                        <Col md="12">
                                                            <Alert color="warning">
                                                                <b>Data sebelumnya ditolak:</b>{" "}
                                                                {this.state.tunkinReason}
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null}
                                            <AvForm
                                                className="needs-validation"
                                                onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                            >
                                                <AvField name="uuid" type="hidden" id="uuid" value={this.props.uuid} />
                                                <Row className="mb-3">
                                                    <Col md="6">
                                                        {this.state.loadingCheckPopup === true ? (
                                                            <LoadingComponent message="Memeriksa Data Master SIMKEU..." />
                                                        ) : (
                                                            <Alert
                                                                color={
                                                                    this.state.loadingCheckPopupStatus
                                                                        ? "success"
                                                                        : "danger"
                                                                }
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showPopUp: true,
                                                                    })
                                                                }}
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    "uil " +
                                                                                    (this.state.loadingCheckPopupStatus
                                                                                        ? "uil-check"
                                                                                        : "uil-times")
                                                                                }
                                                                            ></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.loadingCheckPopupStatus
                                                                                ? "Data Master SIMKEU tersedia"
                                                                                : "Data Master SIMKEU belum sesuai"}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )}
                                                    </Col>
                                                    <Col md="6">
                                                        {/* {
                                                            this.state.loadingCheckPtkp === true ?
                                                                <LoadingComponent message="Memeriksa Data Referensi PTKP..." />
                                                            :
                                                                <Alert color={ this.state.loadingCheckPtkpStatus ? "success" : "danger" }>
                                                                    <table width="100%">
                                                                        <tr>
                                                                            <td width="30px" style={{verticalAlign: 'top'}}>
                                                                                <i className={ "uil " + (this.state.loadingCheckPtkpStatus ? "uil-check" : "uil-times") }></i>
                                                                            </td>
                                                                            <td style={{verticalAlign: 'top'}}>
                                                                                { this.state.loadingCheckPtkpText }
                                                                            </td>
                                                                            { !this.state.loadingCheckPtkpStatus ?
                                                                                <td className="text-end" style={{verticalAlign: 'top'}}>
                                                                                    <Link to="/master/referensi-ptkp.html">
                                                                                        Master PTKP
                                                                                    </Link>
                                                                                </td> : null
                                                                            }
                                                                        </tr>
                                                                    </table>
                                                                </Alert>
                                                        }
                                                        {
                                                            this.state.loadingCheckPkp === true ?
                                                                <LoadingComponent message="Memeriksa Data Referensi PKP..." />
                                                            :
                                                                <Alert color={ this.state.loadingCheckPkpStatus ? "success" : "danger" }>
                                                                    <table width="100%">
                                                                        <tr>
                                                                            <td width="30px" style={{verticalAlign: 'top'}}>
                                                                                <i className={ "uil " + (this.state.loadingCheckPkpStatus ? "uil-check" : "uil-times") }></i>
                                                                            </td>
                                                                            <td style={{verticalAlign: 'top'}}>
                                                                                { this.state.loadingCheckPkpText }
                                                                            </td>
                                                                            { !this.state.loadingCheckPkpStatus ?
                                                                                <td className="text-end" style={{verticalAlign: 'top'}}>
                                                                                    <Link to="/master/pajak-ptkp.html">
                                                                                        Master PTKP
                                                                                    </Link>
                                                                                </td> : null
                                                                            }
                                                                        </tr>
                                                                    </table>
                                                                </Alert>
                                                        }
                                                        {
                                                            this.state.loadingCheckLevel === true ?
                                                                <LoadingComponent message="Memeriksa Data Referensi Grade..." />
                                                            :
                                                                <Alert color={ this.state.loadingCheckLevelStatus ? "success" : "danger" }>
                                                                    <table width="100%">
                                                                        <tr>
                                                                            <td width="30px" style={{verticalAlign: 'top'}}>
                                                                                <i className={ "uil " + (this.state.loadingCheckLevelStatus ? "uil-check" : "uil-times") }></i>
                                                                            </td>
                                                                            <td style={{verticalAlign: 'top'}}>
                                                                                { this.state.loadingCheckLevelText }
                                                                            </td>
                                                                            { !this.state.loadingCheckLevelStatus ?
                                                                                <td className="text-end" style={{verticalAlign: 'top'}}>
                                                                                    <Link to="/master/tunkin.html">
                                                                                        Master Grade
                                                                                    </Link>
                                                                                </td> : null
                                                                            }
                                                                        </tr>
                                                                    </table>
                                                                </Alert>
                                                        } */}
                                                        {this.state.loadingCheckGpp === true ? (
                                                            <LoadingComponent message="Memeriksa Data Gaji Pokok dari GPP..." />
                                                        ) : (
                                                            <Alert
                                                                color={
                                                                    this.state.loadingCheckGppStatus
                                                                        ? "success"
                                                                        : "danger"
                                                                }
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    "uil " +
                                                                                    (this.state.loadingCheckGppStatus
                                                                                        ? "uil-check"
                                                                                        : "uil-times")
                                                                                }
                                                                            ></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.loadingCheckGppText}
                                                                            <br />
                                                                            {this.state.loadingCheckGppDetail.map(
                                                                                (item, key) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <small>{item}</small>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            )}
                                                                        </td>
                                                                        {!this.state.loadingCheckGppStatus ? (
                                                                            <td
                                                                                className="text-end"
                                                                                style={{ verticalAlign: "top" }}
                                                                            >
                                                                                <Link to="/integration/sigap-gaji.html">
                                                                                    Data GPP
                                                                                </Link>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        )}
                                                    </Col>
                                                    <Col md="12">
                                                        {this.state.tunkinMonth === 12 ? (
                                                            <>
                                                                {this.state.loadingCheckOtherMonth === true ? (
                                                                    <LoadingComponent message="Memeriksa Data tunkin dan atau Tunkin bulan sebelumnya..." />
                                                                ) : (
                                                                    <Alert
                                                                        color={
                                                                            this.state.loadingCheckOtherMonthStatus
                                                                                ? "success"
                                                                                : "danger"
                                                                        }
                                                                    >
                                                                        <table width="100%">
                                                                            <tr>
                                                                                <td
                                                                                    width="30px"
                                                                                    style={{ verticalAlign: "top" }}
                                                                                >
                                                                                    <i
                                                                                        className={
                                                                                            "uil " +
                                                                                            (this.state
                                                                                                .loadingCheckOtherMonthStatus
                                                                                                ? "uil-check"
                                                                                                : "uil-times")
                                                                                        }
                                                                                    ></i>
                                                                                </td>
                                                                                <td style={{ verticalAlign: "top" }}>
                                                                                    {
                                                                                        this.state
                                                                                            .loadingCheckOtherMonthText
                                                                                    }
                                                                                    <br />
                                                                                    {this.state.loadingCheckOtherMonthDetail.map(
                                                                                        (item, key) => {
                                                                                            return (
                                                                                                <li>
                                                                                                    <small>
                                                                                                        {item}
                                                                                                    </small>
                                                                                                </li>
                                                                                            )
                                                                                        }
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </Alert>
                                                                )}
                                                            </>
                                                        ) : null}
                                                        {/* {
                                                            this.state.loadingCheckPresence === true ?
                                                                <LoadingComponent message="Memeriksa Data Absensi Pegawai..." />
                                                            :
                                                                <Alert color={ this.state.loadingCheckPresenceStatus ? "success" : "danger" }>
                                                                    <table width="100%">
                                                                        <tr>
                                                                            <td width="30px" style={{verticalAlign: 'top'}}>
                                                                                <i className={ "uil " + (this.state.loadingCheckPresenceStatus ? "uil-check" : "uil-times") }></i>
                                                                            </td>
                                                                            <td style={{verticalAlign: 'top'}}>
                                                                                { this.state.loadingCheckPresenceText }<br/>
                                                                                {
                                                                                    this.state.loadingCheckPresenceDetail.map((item, key) => {
                                                                                        return (
                                                                                            <li>
                                                                                                <small>{ item }</small>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </td>
                                                                            { !this.state.loadingCheckPresenceStatus ?
                                                                                <td className="text-end" style={{verticalAlign: 'top'}}>
                                                                                    <Link to="/integration/simpeg-presention.html">
                                                                                        Data Absensi
                                                                                    </Link>
                                                                                </td> : null
                                                                            }
                                                                        </tr>
                                                                    </table>
                                                                </Alert>
                                                        } */}
                                                        <hr style={{ marginTop: 0 }} />
                                                        {/* {
                                                            this.state.loadingCheckEmployeeNew === true ?
                                                                <LoadingComponent message="Memeriksa Pegawai Baru..." />
                                                            :
                                                                <Alert color={ this.state.loadingCheckEmployeeNewStatus ? "success" : "warning" }>
                                                                    <table width="100%">
                                                                        <tr>
                                                                            <td width="30px" style={{verticalAlign: 'top'}}>
                                                                                <i className={ "uil " + (this.state.loadingCheckEmployeeNewStatus ? "uil-check" : "uil-info-circle") }></i>
                                                                            </td>
                                                                            <td style={{verticalAlign: 'top'}}>
                                                                                { this.state.loadingCheckEmployeeNewText }<br/>
                                                                                {
                                                                                    this.state.loadingCheckEmployeeNewDetail.map((item, key) => {
                                                                                        return (
                                                                                            <li>
                                                                                                <small>{ item }</small>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </td>
                                                                            { !this.state.loadingCheckEmployeeNewStatus ?
                                                                                <td className="text-end" style={{verticalAlign: 'top'}}>
                                                                                    <Link to="/integration/simpeg-kepegawaian.html">
                                                                                        Data Simpeg
                                                                                    </Link>
                                                                                </td> : null
                                                                            }
                                                                        </tr>
                                                                    </table>
                                                                </Alert>
                                                        }
                                                        {
                                                            this.state.loadingCheckEmployeeChange === true ?
                                                                <LoadingComponent message="Memeriksa Perubahan Data Pegawai..." />
                                                            :
                                                                <Alert color={ this.state.loadingCheckEmployeeChangeStatus ? "success" : "warning" }>
                                                                    <table width="100%">
                                                                        <tr>
                                                                            <td width="30px" style={{verticalAlign: 'top'}}>
                                                                                <i className={ "uil " + (this.state.loadingCheckEmployeeChangeStatus ? "uil-check" : "uil-info-circle") }></i>
                                                                            </td>
                                                                            <td style={{verticalAlign: 'top'}}>
                                                                                { this.state.loadingCheckEmployeeChangeText }<br/>
                                                                                {
                                                                                    this.state.loadingCheckEmployeeChangeDetail.map((item, key) => {
                                                                                        return (
                                                                                            <li>
                                                                                                <small>{ item }</small>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </td>
                                                                            { !this.state.loadingCheckEmployeeChangeStatus ?
                                                                                <td className="text-end" style={{verticalAlign: 'top'}}>
                                                                                    <Link to="/integration/simpeg-kepegawaian.html">
                                                                                        Data Simpeg
                                                                                    </Link>
                                                                                </td> : null
                                                                            }
                                                                        </tr>
                                                                    </table>
                                                                </Alert>
                                                        }
                                                        <hr style={{marginTop: 0}} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md="12">
                                                        <EmployeeListComponent uuid={this.props.uuid} />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Label htmlFor="fieldNama" className="col-md-3 col-form-label">
                                                        Bendahara Pengeluaran
                                                    </Label>
                                                    <Col md="5">
                                                        {this.state.loadingSignerLeft ? (
                                                            <>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "100%", height: 38 }}
                                                                    ></div>
                                                                </Shimmer>{" "}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <CustomSelect
                                                                    name="signer_left"
                                                                    errorMessage="Pilih Penandatangan"
                                                                    options={this.state.signerLeft}
                                                                    defaultValue={null}
                                                                    placeholder="Pilih Penandatangan"
                                                                    optionLabel="signer_employee_name"
                                                                    optionValue="signer_uuid"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="fieldNama" className="col-md-3 col-form-label">
                                                        Pejabat Pembuat Komitmen
                                                    </Label>
                                                    <Col md="5">
                                                        {this.state.loadingSignerRight ? (
                                                            <>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "100%", height: 38 }}
                                                                    ></div>
                                                                </Shimmer>{" "}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <CustomSelect
                                                                    name="signer_right"
                                                                    errorMessage="Pilih Penandatangan"
                                                                    options={this.state.signerRight}
                                                                    defaultValue={null}
                                                                    placeholder="Pilih Penandatangan"
                                                                    optionLabel="signer_employee_name"
                                                                    optionValue="signer_uuid"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label
                                                        htmlFor="fieldDescription"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Keterangan
                                                    </Label>
                                                    <Col md="6">
                                                        <AvField
                                                            name="description"
                                                            placeholder="Keterangan"
                                                            errorMessage="Masukkan Keterangan"
                                                            type="textarea"
                                                            className="form-control"
                                                            id="fieldDescription"
                                                            rows="4"
                                                        />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <div className="form-check form-switch mb-3">
                                                            <AvCheckboxGroup
                                                                name="checkboxExample"
                                                                required
                                                                errorMessage="Agreement harus di centang"
                                                            >
                                                                <AvCheckbox
                                                                    label="Saya yakin dan bertanggung jawab atas data ini"
                                                                    value="1"
                                                                />
                                                            </AvCheckboxGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <Link to="/payroll/tunkin-reguler.html">
                                                            <Button color="danger" type="button">
                                                                <i className="uil-arrow-left"></i> Kembali
                                                            </Button>
                                                        </Link>{" "}
                                                        <Button
                                                            color="success"
                                                            type="submit"
                                                            disabled={!this.state.loadingCheckAll}
                                                        >
                                                            <i className="uil-arrow-up-right"></i> Proses Data Tunjangan
                                                            Kinerja
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </>
                                    ) : (
                                        <>
                                            {this.state.tunkinStatusName === "" ? (
                                                <LoadingComponent message="Memeriksa status Tunjangan Kinerja..." />
                                            ) : (
                                                <>
                                                    <Row>
                                                        <Col md="12">
                                                            <Alert color="danger">
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i className="uil uil-times"></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.tunkinStatusName}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            <hr style={{ marginTop: 0 }} />
                                            <Row>
                                                <Col md="10">
                                                    <Link to="/payroll/tunkin-reguler.html">
                                                        <Button color="danger" type="button">
                                                            <i className="uil-arrow-left"></i> Kembali
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={this.state.showPopUp}
                    toggle={() => {
                        this.setState({
                            showPopUp: !this.state.showPopUp,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Data Master SIMKEU
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showPopUp: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="mb-3">
                            <Col md="12">
                                {this.state.loadingCheckPtkp === true ? (
                                    <LoadingComponent message="Memeriksa Data Referensi PTKP..." />
                                ) : (
                                    <Alert color={this.state.loadingCheckPtkpStatus ? "success" : "danger"}>
                                        <table width="100%">
                                            <tr>
                                                <td width="30px" style={{ verticalAlign: "top" }}>
                                                    <i
                                                        className={
                                                            "uil " +
                                                            (this.state.loadingCheckPtkpStatus
                                                                ? "uil-check"
                                                                : "uil-times")
                                                        }
                                                    ></i>
                                                </td>
                                                <td style={{ verticalAlign: "top" }}>
                                                    {this.state.loadingCheckPtkpText}
                                                </td>
                                                {!this.state.loadingCheckPtkpStatus ? (
                                                    <td className="text-end" style={{ verticalAlign: "top" }}>
                                                        <Link to="/master/referensi-ptkp.html">Master PTKP</Link>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </table>
                                    </Alert>
                                )}
                                {this.state.loadingCheckPkp === true ? (
                                    <LoadingComponent message="Memeriksa Data Referensi PKP..." />
                                ) : (
                                    <Alert color={this.state.loadingCheckPkpStatus ? "success" : "danger"}>
                                        <table width="100%">
                                            <tr>
                                                <td width="30px" style={{ verticalAlign: "top" }}>
                                                    <i
                                                        className={
                                                            "uil " +
                                                            (this.state.loadingCheckPkpStatus
                                                                ? "uil-check"
                                                                : "uil-times")
                                                        }
                                                    ></i>
                                                </td>
                                                <td style={{ verticalAlign: "top" }}>
                                                    {this.state.loadingCheckPkpText}
                                                </td>
                                                {!this.state.loadingCheckPkpStatus ? (
                                                    <td className="text-end" style={{ verticalAlign: "top" }}>
                                                        <Link to="/master/pajak-ptkp.html">Master PTKP</Link>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </table>
                                    </Alert>
                                )}
                                {this.state.loadingCheckLevel === true ? (
                                    <LoadingComponent message="Memeriksa Data Referensi Grade..." />
                                ) : (
                                    <Alert color={this.state.loadingCheckLevelStatus ? "success" : "danger"}>
                                        <table width="100%">
                                            <tr>
                                                <td width="30px" style={{ verticalAlign: "top" }}>
                                                    <i
                                                        className={
                                                            "uil " +
                                                            (this.state.loadingCheckLevelStatus
                                                                ? "uil-check"
                                                                : "uil-times")
                                                        }
                                                    ></i>
                                                </td>
                                                <td style={{ verticalAlign: "top" }}>
                                                    {this.state.loadingCheckLevelText}
                                                </td>
                                                {!this.state.loadingCheckLevelStatus ? (
                                                    <td className="text-end" style={{ verticalAlign: "top" }}>
                                                        <Link to="/master/tunkin.html">Master Grade</Link>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </table>
                                    </Alert>
                                )}
                                {this.state.loadingCheckPresence === true ? (
                                    <LoadingComponent message="Memeriksa Data Absensi Pegawai..." />
                                ) : (
                                    <Alert color={this.state.loadingCheckPresenceStatus ? "success" : "danger"}>
                                        <table width="100%">
                                            <tr>
                                                <td width="30px" style={{ verticalAlign: "top" }}>
                                                    <i
                                                        className={
                                                            "uil " +
                                                            (this.state.loadingCheckPresenceStatus
                                                                ? "uil-check"
                                                                : "uil-times")
                                                        }
                                                    ></i>
                                                </td>
                                                <td style={{ verticalAlign: "top" }}>
                                                    {this.state.loadingCheckPresenceText}
                                                    <br />
                                                    {this.state.loadingCheckPresenceDetail.map((item, key) => {
                                                        return (
                                                            <li>
                                                                <small>{item}</small>
                                                            </li>
                                                        )
                                                    })}
                                                </td>
                                                {!this.state.loadingCheckPresenceStatus ? (
                                                    <td className="text-end" style={{ verticalAlign: "top" }}>
                                                        <Link to="/integration/simpeg-presention.html">
                                                            Data Absensi
                                                        </Link>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </table>
                                    </Alert>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"
                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"
                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan mereset data ini?"
                    loadingTitle="Mereset data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil direset"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal direset"
                    showConfirm={this.state.resetConfirm}
                    showLoading={this.state.resetLoading}
                    showSuccess={this.state.resetSuccess}
                    showFailed={this.state.resetFailed}
                    response={this.confirmResetResponse}
                />
                {this.state.redirect ? <Redirect to="/payroll/tunkin-reguler.html" /> : null}
            </Fragment>
        )
    }
}

export default CreateView
