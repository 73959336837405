import {
    apiRequest,
    overtimeEmployeeYears,
    overtimeEmployeeMonths,
} from "../../../../../services/adapters/process"

class EmployeeViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", overtimeEmployeeYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", overtimeEmployeeMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

}

export default EmployeeViewRepository