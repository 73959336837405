import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import IndexRepository from '../Repositories/IndexRepository'

class IndexBloc 
{
    repository = new IndexRepository()
    dashboardReportChannel = new Subject();
    dashboardIconChannel = new Subject();
    dashboardGppChannel = new Subject();
    dashboardSigapChanel = new Subject();
    dashboardDaftarPekerjaanChannel = new Subject();
    dashboardPengajuanChannel = new Subject();
    dashboardFilterChannel = new Subject();

    constructor() 
    {
        this.dashboardReportChannel.next({status: apiResponse.INITIAL})
        this.dashboardIconChannel.next({status: apiResponse.INITIAL})
        this.dashboardGppChannel.next({status: apiResponse.INITIAL})
        this.dashboardSigapChanel.next({status: apiResponse.INITIAL})
        this.dashboardDaftarPekerjaanChannel.next({status: apiResponse.INITIAL})
        this.dashboardPengajuanChannel.next({status: apiResponse.INITIAL})
        this.dashboardFilterChannel.next({status: apiResponse.INITIAL})
    }

    fetchFilter = async (query, cancelToken) => {
        this.dashboardFilterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchFilter(query, cancelToken).then((result) => {
                this.dashboardFilterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardFilterChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboardReport = async (query, cancelToken) => {
        this.dashboardReportChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardReport(query, cancelToken).then((result) => {
                this.dashboardReportChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardReportChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboardIcon = async (query, cancelToken) => {
        this.dashboardIconChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardIcon(query, cancelToken).then((result) => {
                this.dashboardIconChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardIconChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboardGpp = async (query, cancelToken) => {
        this.dashboardGppChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardGpp(query, cancelToken).then((result) => {
                this.dashboardGppChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardGppChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboardSigap = async (query, cancelToken) => {
        this.dashboardSigapChanel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardSigap(query, cancelToken).then((result) => {
                this.dashboardSigapChanel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardSigapChanel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboardDaftarPekerjaan = async (query, cancelToken) => {
        this.dashboardDaftarPekerjaanChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardDaftarPekerjaan(query, cancelToken).then((result) => {
                this.dashboardDaftarPekerjaanChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardDaftarPekerjaanChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboarPengajuan = async (query, cancelToken) => {
        this.dashboardPengajuanChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboarPengajuan(query, cancelToken).then((result) => {
                this.dashboardPengajuanChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardPengajuanChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

}

export default IndexBloc