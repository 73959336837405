import {
    apiRequest,
    tunsusRegulerEmployeeYears,
    tunsusRegulerEmployeeMonths,
} from "../../../../../../services/adapters/process"

class EmployeeViewRepository {
    fetchYears = async () => {
        return await apiRequest("get", tunsusRegulerEmployeeYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunsusRegulerEmployeeMonths,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }
}

export default EmployeeViewRepository
