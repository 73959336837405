import {
    apiRequest,
    tunkinT14ProcessSubmit,
    tunkinT14ProcessEmployees,
    tunkinT14ProcessEmployeeDetail,
    tunkinT14CheckStatus,
    tunkinT14GetSigner,
    tunkinT14CheckPresence,
    tunkinT14CheckLevel,
    tunkinT14CheckGpp,
    tunkinT14CheckPtkp,
    tunkinT14CheckPkp,
    tunkinT14CheckEmployeeNew,
    tunkinT14CheckEmployeeChange
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", tunkinT14ProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", tunkinT14ProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tunkinT14ProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", tunkinT14GetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tunkinT14CheckStatus, {
            params: query
        }, true)
    }
    
    fetchCheckPresence = async (query) => {
        return await apiRequest("get", tunkinT14CheckPresence, {
            params: query
        }, true)
    }
    
    fetchCheckLevel = async (query) => {
        return await apiRequest("get", tunkinT14CheckLevel, {
            params: query
        }, true)
    }

    fetchCheckGpp = async (query) => {
        return await apiRequest("get", tunkinT14CheckGpp, {
            params: query
        }, true)
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest("get", tunkinT14CheckPtkp, {
            params: query
        }, true)
    }
    
    fetchCheckPkp = async (query) => {
        return await apiRequest("get", tunkinT14CheckPkp, {
            params: query
        }, true)
    }

    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", tunkinT14CheckEmployeeNew, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", tunkinT14CheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository