import {
    apiRequest,
    tunkinT13RegulerMonth,
    tunkinT13Create,
    tunkinT13Months,
    tunkinT13History,
    tunkinT13PushSPM,
    tunkinT13UpdateSPM,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {
    fetchRegulerMonts = async (query) => {
        return await apiRequest(
            "get",
            tunkinT13RegulerMonth,
            {
                params: query,
            },
            true
        )
    }

    fetchCreate = async (query) => {
        return await apiRequest(
            "post",
            tunkinT13Create,
            {
                body: query,
            },
            true
        )
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunkinT13Months,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchHistory = async (query) => {
        return await apiRequest(
            "get",
            tunkinT13History,
            {
                params: query,
            },
            true
        )
    }

    fetchPushSPM = async (query) => {
        return await apiRequest(
            "post",
            tunkinT13PushSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest(
            "post",
            tunkinT13UpdateSPM,
            {
                body: query,
            },
            true
        )
    }
}

export default IndexViewRepository
