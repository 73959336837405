import {
    apiRequest,
    tplnT13Detail,
    tplnT13Ssp
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", tplnT13Detail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tplnT13Ssp, {
            params: query
        }, true)
    }
}

export default DownloadRepository