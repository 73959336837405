import {
    apiRequest,
    simpegEmployeeYears,
    simpegEmployeeMonth,
    simpegEmployeeDetail,
    simpegEmployeeChange,
} from "../../../../../../services/adapters/sync"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", simpegEmployeeYears, {}, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", simpegEmployeeMonth, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    
    fetchDetail = async (query) => {
        return await apiRequest("get", simpegEmployeeDetail, {
            params: query
        }, true)
    }
    
    fetchChange = async (query) => {
        return await apiRequest("get", simpegEmployeeChange, {
            params: query
        }, true)
    }
}

export default IndexViewRepository