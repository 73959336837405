import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component, } from "react"
import { Link } from "react-router-dom"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../../services/adapters/base"

import DetailBloc from '../Blocs/DetailBloc'
import apiResponse from '../../../../../../services/apiResponse'


class TableListComponent extends Component {
    detailBloc = new DetailBloc();

    source
    historyTable = 'historyOfficialTravelParticipants_' + this.props.uuid
    defaultOrder = 'official_travel_participant_employee_name'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            isCheckedAll01: false,
            isCheckedRow01: [],
            tooltip: false,
            defaultSize: 100,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            uuid: this.props.uuid
        }
        await this.detailBloc.fetchParticipant(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.detailBloc.participantChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    let isCheckedRow01s = [];
                    for (var i = 0; i < response.total; ++i) {
                        isCheckedRow01s[i] = false
                    }
                    this.setState({
                        isCheckedRow01: isCheckedRow01s,
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

    }

    componentWillUnmount() {
        this.detailBloc.participantChannel.unsubscribe()
    }

    columns = [
        /* {
            key: "official_travel_participant_id",
            text: "Check",
            sortable: false,
            align: 'center',
            width: '40',
            cell: (resord, r) => {
                return (
                    <>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            name="xthisCheck"
                            checked={this.state.isCheckedRow01[r]}
                            onChange={() => {
                                let newValue = !this.state.isCheckedRow01[r]
                                let isCheckedRow01s = [];
                                let allChecked = true;
                                for(var i = 0; i < this.state.totalRow; ++i) {
                                    if(r === i) {
                                        isCheckedRow01s[i] = newValue
                                    } else {
                                        isCheckedRow01s[i] = this.state.isCheckedRow01[i]
                                    }
                                    if(!isCheckedRow01s[i]) {
                                        allChecked = false;
                                    }
                                }
                                this.setState({
                                    isCheckedRow01: isCheckedRow01s,
                                    isCheckedAll01: allChecked
                                })
                            }}
                        ></input>
                    </>
                )
            },
            header: () => {
                return (
                    <>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            name="thisCheck"
                            checked={this.state.isCheckedAll01}
                            onChange={() => {
                                let newValue = !this.state.isCheckedAll01
                                let isCheckedRow01s = [];
                                for(var i = 0; i < this.state.totalRow; ++i) {
                                    isCheckedRow01s[i] = newValue
                                }
                                this.setState({
                                    isCheckedRow01: isCheckedRow01s,
                                    isCheckedAll01: newValue
                                })
                            }}
                        ></input>
                    </>
                )
            }
        }, */
        {
            key: "official_travel_participant_employee_name",
            text: "Nama",
            sortable: true,
            width: '200',
            cell: record => {
                return record.official_travel_participant_employee_fullname
            }
        },
        {
            key: "official_travel_participant_position_name",
            text: "Jabatan",
            sortable: true,
            width: '220',
        },
        {
            key: "official_travel_participant_work_unit_name",
            text: "Unit Kerja",
            sortable: true,
        },
        {
            key: "official_travel_participant_official_travel_process_uuid",
            text: "Proses",
            sortable: false,
            align: 'center',
            width: '120',
            cell: record => {
                var className = (record.official_travel_participant_official_travel_process_uuid !== null ? 'bg-success' : 'bg-secondary') + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            }
        },
    ]


    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={700}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
            </>
        )
    }

}

export default TableListComponent