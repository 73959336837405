import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect"
import Select from "react-select"
import classnames from "classnames"

import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Modal,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"

import PtspBkpmIndexListComponent from "./Components/PtspBkpmIndexListComponent"
import PtspLoIndexListComponent from "./Components/PtspLoIndexListComponent"

import IndexViewBloc from "./Blocs/IndexViewBloc"
import apiResponse from "../../../../../services/apiResponse"
import PtspDownloadAllComponent from "./Components/PtspDownloadAllComponent"

class IndexView extends Component {
    indexViewBloc = new IndexViewBloc()
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear() - 1 : this.thisDate.getFullYear()

    constructor(props) {
        super(props)
        this.state = {
            showPtspHistory: false,
            loadingCurrent: true,
            selectedYear: { label: this.thisYear.toString(), value: this.thisYear },
            activeTab: "1",
        }
    }

    loadCurrent = async () => {
        await this.indexViewBloc.fetchYears()
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }

    componentDidMount() {
        this.indexViewBloc.yearsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        currentRegulation: response,
                        loadingCurrent: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.loadCurrent()
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState({
            selectedYear: itemYear.length > 0 ? itemYear[0] : { label: this.thisYear.toString(), value: this.thisYear },
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear,
                },
            ],
        })
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState(
            {
                selectedYear: selectedYear,
                loadingCurrent: true,
            },
            () => {
                this.setState({
                    loadingCurrent: false,
                })
            }
        )
    }

    componentWillUnmount() {
        this.indexViewBloc.yearsChannel.unsubscribe()
    }

    render() {
        return (
            <>
                <Fragment>
                    <Container fluid>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Honorarium Tetap Reguler</CardTitle>
                                        <CardSubtitle className="mb-3">
                                            Proses Honorarium Tetap berdasarkan tahun dan bulan tertentu sesuai dengan
                                            data integrasi (Simpeg) yang sudah di verifikasi
                                        </CardSubtitle>
                                        <hr />
                                        <Row>
                                            <Col md="12">
                                                <div className="d-flex flex-row bd-highlight float-start">
                                                    <div className="pt-2 pe-2 pb-2 bd-highlight">
                                                        <div style={{ paddingTop: 8 }}>Tahun</div>
                                                    </div>
                                                    <div className="p-2 bd-highlight">
                                                        {this.state.loadingCurrent ? (
                                                            <>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: 116, height: 36 }}
                                                                    ></div>
                                                                </Shimmer>{" "}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div style={{ width: 116 }}>
                                                                    <Select
                                                                        value={this.state.selectedYear}
                                                                        onChange={(selected) => {
                                                                            this.handleOnChangeYear(selected)
                                                                        }}
                                                                        options={this.state.optionYear}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    {!this.state.loadingCurrent ? (
                                                        <div className="p-2 bd-highlight">
                                                            <Button
                                                                color="success"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showPtspDownload: true,
                                                                        // ,
                                                                        // downloadUuid: record.ptsp_uuid
                                                                    })
                                                                }}
                                                            >
                                                                <i className="uil-arrow-to-bottom"></i> Download
                                                            </Button>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <div className="p-2 bd-highlight float-end">
                                                    <div style={{ paddingTop: 8 }}>
                                                        <div
                                                            className="bg-success rounded-circle d-inline-block"
                                                            style={{ height: 10, width: 10 }}
                                                        ></div>{" "}
                                                        Sudah Integrasi
                                                    </div>
                                                </div>
                                                <div className="p-2 bd-highlight float-end">
                                                    <div style={{ paddingTop: 8 }}>
                                                        <div
                                                            className="bg-danger rounded-circle d-inline-block"
                                                            style={{ height: 10, width: 10 }}
                                                        ></div>{" "}
                                                        Belum Integrasi
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col md="12">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: this.state.activeTab === "1",
                                                            })}
                                                            onClick={() => {
                                                                this.toggle("1")
                                                            }}
                                                        >
                                                            <span className="d-block d-sm-none">
                                                                <i className="fas fa-home"></i>
                                                            </span>
                                                            <span className="d-none d-sm-block">BKPM</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: this.state.activeTab === "2",
                                                            })}
                                                            onClick={() => {
                                                                this.toggle("2")
                                                            }}
                                                        >
                                                            <span className="d-block d-sm-none">
                                                                <i className="far fa-envelope"></i>
                                                            </span>
                                                            <span className="d-none d-sm-block">PP/KL</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                                                    <TabPane tabId="1">
                                                        {this.state.loadingCurrent ? (
                                                            <>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "10%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "20%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>

                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "10%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "20%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <PtspBkpmIndexListComponent
                                                                    year={this.state.selectedYear.value}
                                                                />
                                                            </>
                                                        )}
                                                    </TabPane>
                                                    <TabPane tabId="2">
                                                        {this.state.loadingCurrent ? (
                                                            <>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "10%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "20%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>

                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "10%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "20%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <PtspLoIndexListComponent
                                                                    year={this.state.selectedYear.value}
                                                                />
                                                            </>
                                                        )}
                                                    </TabPane>
                                                </TabContent>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Fragment>

                <Modal
                    size="sm"
                    isOpen={this.state.showPtspDownload}
                    toggle={() => {
                        this.setState({
                            showPtspDownload: !this.state.showPtspDownload,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Unduh Berkas PTSP BKPM
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showPtspDownload: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <PtspDownloadAllComponent
                            year={this.state.selectedYear.value}
                            category={this.state.activeTab}
                        />
                    </div>
                </Modal>
            </>
        )
    }
}

export default IndexView
