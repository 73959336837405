import React from "react"
import { connect } from "react-redux"

import {
  changeNotificationCount, changeNotificationList, changeNotificationMenu
} from "../store/actions"

import logo from '../assets/images/logo-sm.png'


import {
    apiRequest,
    notificationCheck,
} from "../services/adapters/base"

let NotificationService = props => {

    checkNotification()
  
    function showNotification(notif) {
    if(checkNotificationPromise()) {
        var options = {
            body: notif.notification_description,
            icon: logo
        };
        var notification = new Notification(notif.notification_title, options);
        notification.onclick = function() {
            window.open(window.location.origin.toString() + notif.notification_link, '_self');
            notification.close()
        };
    }
    }
    function checkNotificationPromise() {
        try {
            Notification.requestPermission().then();
        } catch(e) {
            return false;
        }
        return true;
    }

    async function checkNotification() {

        await apiRequest("get", notificationCheck)
        .then(result => {
            if(result.status) {
                const response = result.response
                if(response.flash.length > 0) {
                    response.flash.map((notif) => showNotification(notif))
                }
                localStorage.setItem('localTime', new Date().getTime())
                localStorage.setItem('serverTime', result.serverTime)
                updateNotification(response.total, response.list, response.menu)
            }
        })
        setTimeout(function() {
            checkNotification()
        }, 60000)
    }

    function updateNotification(total, list, menu) {
        props.changeNotificationCount(total)
        props.changeNotificationList(list)
        props.changeNotificationMenu(menu)
    }
    
    return (
        <></>
    )
}

const mapDispatchToProps = {
  changeNotificationCount: changeNotificationCount,
  changeNotificationList: changeNotificationList,
  changeNotificationMenu: changeNotificationMenu
};

NotificationService = connect(
  null,
  mapDispatchToProps,
)(NotificationService);

export default NotificationService