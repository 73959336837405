import { accessRole, signer } from "../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect";
import Select from "react-select"
import classnames from "classnames"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    ModalFooter,
    Alert
} from "reactstrap"


import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { ConfirmAlert } from '../../../../helpers/ui/alert'

import IndexViewBloc from './Blocs/IndexViewBloc'
import DownloadBloc from './Blocs/DownloadBloc'
import apiResponse from '../../../../services/apiResponse'

import SPTFinalListComponent from './Components/SPTFinalListComponent'

class IndexView extends Component {
    isSigner = signer('PTAX')
    indexViewBloc = new IndexViewBloc()
    downloadBloc = new DownloadBloc()
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear() - 1 : this.thisDate.getFullYear()

    constructor(props) {
        super(props)
        this.state = {
            optionYear: [],
            loadingCurrent: true,
            selectedYear: { label: this.thisYear.toString(), value: this.thisYear },
            activeTab: "2",

            uuidFinal: '',
            loadingStatusFinal: true,
            statusFinal: 0,
            totalFinal: 0,

            processStep: 0,
            alertMessage: '',

            approveShow: false,
            totalModal: 0,
            passphrase: '',
        }
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            passphrase: values.passphrase,
            confirm: true,
            approveShow: false,
        })
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    confirmResponse = async (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                this.setState(forState)
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.setState(forState, async () => {
                    if (this.state.processStep === 0) {
                        await this.indexViewBloc.fetchCreateFinal({ 'year': this.state.selectedYear.value })
                    } else if (this.state.processStep === 1) {
                        await this.indexViewBloc.fetchUpdate({ 'uuid': this.state.uuidFinal, 'status': 3 })
                    } else if (this.state.processStep === 2) {
                        await this.indexViewBloc.fetchUpdate({ 'uuid': this.state.uuidFinal, 'status': 4, 'passphrase': this.state.passphrase })
                    } else {
                        await this.indexViewBloc.fetchCreateFinal({ 'year': this.state.selectedYear.value, 'uuid': this.state.uuidFinal })
                    }
                })
                break;
            case 'success':
                forState = {
                    success: false,
                    loading: false
                }
                this.setState({
                    loadingCurrent: true
                }, () => {
                    this.setState({
                        loadingCurrent: false,
                    })
                    this.checkStatusFinal()
                });
                this.setState(forState)
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                this.setState(forState)
                break;
            default:
        }
    }

    loadCurrent = async () => {
        await this.indexViewBloc.fetchYears()
    }

    checkStatusFinal = async () => {
        await this.indexViewBloc.fetchCheckStatusFinal({ 'year': this.state.selectedYear.value })
    }

    componentDidMount() {
        this.indexViewBloc.yearsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        loadingCurrent: false,
                    })
                    this.checkStatusFinal()
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.checkStatusFinalChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    const status = response !== null ? response.spt_status : 0
                    const uuid = response !== null ? response.spt_uuid : ''
                    const total = response !== null ? response.total : 0
                    this.setState({
                        loadingStatusFinal: false,
                        statusFinal: status,
                        totalFinal: total,
                        uuidFinal: uuid
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.createFinalChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.loadCurrent()

        this.downloadBloc.finalChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingDownload: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    //window.location.href = process.env.REACT_APP_SERVICE_OUTPUT_URL + link
                    this.setState({
                        loadingDownload: false,
                        successDownload: true
                    })
                    setTimeout(() => {
                        this.setState({
                            successDownload: false,
                        })
                    }, 1000);
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loadingDownload: false,
                        failedDownload: true
                    })
                    setTimeout(() => {
                        this.setState({
                            failedDownload: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState({
            selectedYear: itemYear.length > 0 ? itemYear[0] : { label: this.thisYear.toString(), value: this.thisYear },
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear
                }
            ]
        })
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState({
            selectedYear: selectedYear,
            loadingCurrent: true
        }, () => {
            this.setState({
                loadingCurrent: false,
                loadingStatusFinal: true,
            })
            this.checkStatusFinal()
        });

    }

    componentWillUnmount() {
        this.indexViewBloc.yearsChannel.unsubscribe()
        this.indexViewBloc.checkStatusFinalChannel.unsubscribe()

        this.indexViewBloc.createFinalChannel.unsubscribe()
        this.indexViewBloc.updateChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Surat Pemberitahuan Tahunan</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Data SPT masing-masing pegawai berdasarkan tahun tertentu
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <div className="d-flex flex-row bd-highlight float-start">
                                                <div className="pt-2 pe-2 pb-2 bd-highlight">
                                                    <div style={{ paddingTop: 8 }}>Tahun</div>
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    {
                                                        this.state.loadingCurrent ?
                                                            <><Shimmer><div className="shimmer" style={{ width: 116, height: 38 }}></div></Shimmer>{" "}</>
                                                            :
                                                            <>
                                                                <div style={{ width: 116 }}>
                                                                    <Select
                                                                        value={this.state.selectedYear}
                                                                        onChange={(selected) => {
                                                                            this.handleOnChangeYear(selected)
                                                                        }}
                                                                        options={this.state.optionYear}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                {
                                                    !this.state.loadingCurrent ?
                                                        <div className="p-2 bd-highlight">
                                                            <Button
                                                                color="warning"
                                                                onClick={() => {
                                                                    this.handleOnChangeYear(this.state.selectedYear)
                                                                }}
                                                            >
                                                                <i className="uil-refresh"></i>
                                                            </Button>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                <div className="p-2 bd-highlight">
                                                    {this.state.loadingCurrent ?
                                                        <></>
                                                        :
                                                        <>
                                                            {this.state.loadingStatusFinal ?
                                                                <><Shimmer><div className="shimmer" style={{ width: 200, height: 38 }}></div></Shimmer>{" "}</>
                                                                :
                                                                <>
                                                                    {this.state.statusFinal > 0 ?
                                                                        <>
                                                                            {this.state.statusFinal === 1 || this.state.statusFinal === 2 ?

                                                                                <>
                                                                                    {accessRole('update') ?
                                                                                        <>
                                                                                            {this.state.statusFinal === 1 ?
                                                                                                <Button
                                                                                                    color="secondary"
                                                                                                    active={false}
                                                                                                    style={{ cursor: 'default' }}
                                                                                                >
                                                                                                    <i className="uil-arrow-up-right"></i> Kirim ke Penandatangan
                                                                                                </Button>
                                                                                                :
                                                                                                <Button
                                                                                                    color="primary"
                                                                                                    onClick={() => {
                                                                                                        this.setState({
                                                                                                            confirm: true,
                                                                                                            processStep: 1,
                                                                                                            alertMessage: 'Apakah anda yakin akan mengajukan penandatanganan SPT Final untuk tahun ' + this.state.selectedYear.value + '?'
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="uil-arrow-up-right"></i> Kirim ke Penandatangan
                                                                                                </Button>
                                                                                            }
                                                                                            {" "}
                                                                                            <Button
                                                                                                color="danger"
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        confirm: true,
                                                                                                        processStep: 9,
                                                                                                        alertMessage: 'Apakah anda yakin akan membuat ulang SPT Final untuk tahun ' + this.state.selectedYear.value + '?'
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                <i className="uil-redo"></i> Buat Ulang SPT Final
                                                                                            </Button>
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                    {this.state.statusFinal === 2 && accessRole('print') ?
                                                                                        <>
                                                                                            {" "}
                                                                                            <Button
                                                                                                color="success"
                                                                                                onClick={() => {
                                                                                                    /* this.setState({
                                                                                                        confirm: true,
                                                                                                        processStep: 9,
                                                                                                        alertMessage: 'Apakah anda yakin akan membuat ulang SPT Final untuk tahun ' + this.state.selectedYear.value + '?'
                                                                                                    }); */
                                                                                                    //alert(this.state.uuidFinal);
                                                                                                    this.downloadBloc.fetchDownloadFinal({ uuid: this.state.uuidFinal })
                                                                                                }}
                                                                                            >
                                                                                                <i className="uil-arrow-to-bottom"></i> Download
                                                                                            </Button>
                                                                                        </>
                                                                                        : <></>}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {this.state.statusFinal === 3 && this.isSigner === 1 && accessRole('approve') ?
                                                                                        <Button
                                                                                            color="success"
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    approveShow: true,
                                                                                                    processStep: 2,
                                                                                                    totalModal: this.state.totalFinal,
                                                                                                    alertMessage: 'Apakah anda yakin akan menandatangani SPT Final untuk tahun ' + this.state.selectedYear.value + '?'
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <i className="uil-check"></i> Tandatangani SPT Final
                                                                                        </Button>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                </>
                                                                            }
                                                                            {this.state.statusFinal === 4 ?
                                                                                <>
                                                                                    {accessRole('print') ?
                                                                                    <Button
                                                                                        color="success"
                                                                                        onClick={() => {
                                                                                            this.downloadBloc.fetchDownloadFinal({ uuid: this.state.uuidFinal })
                                                                                        }}
                                                                                    >
                                                                                        <i className="uil-arrow-to-bottom"></i> Download
                                                                                    </Button>
                                                                                    : <></>}
                                                                                </>
                                                                            : <></>}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {accessRole('create') ?
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            confirm: true,
                                                                                            processStep: 0,
                                                                                            alertMessage: 'Apakah anda yakin akan membuat SPT Final untuk tahun ' + this.state.selectedYear.value + '?'
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <i className="uil-folder-medical"></i> Buat SPT Final
                                                                                </Button>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{ paddingTop: 8 }}>
                                                    <div className="bg-success rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Sudah TTE
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{ paddingTop: 8 }}>
                                                    <div className="bg-danger rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Belum TTE
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{ paddingTop: 8 }}>
                                                    <div className="bg-secondary rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Belum Generate
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={12}>
                                            <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                                                <TabPane tabId="2">
                                                    {
                                                        this.state.statusFinal === 2 && accessRole('update') ?
                                                            <>
                                                                <Alert color="warning">
                                                                    Sebelum dikirim kepenandatangan, pastikan semua data SPT Final untuk semua pegawai sudah sesuai
                                                                </Alert>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        !this.state.loadingStatusFinal && this.state.statusFinal < 1 && accessRole('create') ?
                                                            <>
                                                                <Alert color="warning">
                                                                    Pastikan data PTSP untuk tahun {this.state.selectedYear.value} sudah disesuaikan
                                                                </Alert>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        this.state.loadingCurrent ?
                                                            <>
                                                                <Shimmer><div className="shimmer" style={{ width: '10%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '20%', height: 36 }}></div></Shimmer>

                                                                <Shimmer><div className="shimmer" style={{ width: '10%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '20%', height: 22 }}></div></Shimmer>
                                                            </>
                                                            :
                                                            <>
                                                                <SPTFinalListComponent year={this.state.selectedYear.value} />
                                                            </>
                                                    }
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    isOpen={this.state.approveShow}
                    toggle={() => this.setState({ approveShow: !this.state.approveShow })}
                >
                    <ModalHeader
                        toggle={() => this.setState({ approveShow: !this.state.approveShow })}
                    >
                        Penandatanganan
                    </ModalHeader>
                    <AvForm
                        className="needs-validation"
                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                    >
                        <ModalBody>
                            <Fragment>
                                <Row className="mb-3">
                                    <Label
                                        className="col-md-5 col-form-label"
                                    >
                                        Tahun
                                    </Label>
                                    <Label
                                        className="col-md-7 col-form-label"
                                    >
                                        {this.state.selectedYear.value}
                                    </Label>
                                </Row>
                                <Row className="mb-3">
                                    <Label
                                        className="col-md-5 col-form-label"
                                    >
                                        Total Pegawai
                                    </Label>
                                    <Label
                                        className="col-md-7 col-form-label"
                                    >
                                        {this.state.totalModal}
                                    </Label>
                                </Row>
                                <Row className="mb-3">
                                    <Label
                                        className="col-md-5 col-form-label"
                                    >
                                        Passphrase
                                    </Label>
                                    <Label
                                        className="col-md-7 col-form-label"
                                    >
                                        <AvInput
                                            name="passphrase"
                                            type="password"
                                            validate={{ required: { value: true } }}
                                        />
                                    </Label>
                                </Row>
                                <hr />
                                <Row className="mb-3">
                                    <Col md="10">
                                        <div className="form-check form-switch mb-3">
                                            <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                            </AvCheckboxGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </Fragment>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="success"
                                type="submit"
                            >
                                Tandatangani
                            </Button>
                            {' '}
                            <Button
                                color="danger"
                                onClick={() => this.setState({ approveShow: !this.state.approveShow })}
                            >
                                Tutup
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </Modal>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.alertMessage}

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                <ConfirmAlert

                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"

                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"

                    showLoading={this.state.loadingDownload}
                    showSuccess={this.state.successDownload}
                    showFailed={this.state.failedDownload}
                    response={this.confirmResponse}
                />
            </Fragment>
        )
    }
}

export default IndexView