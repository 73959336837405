import {
    apiRequest,
    payrollPayrollReady,
    payrollPayrollCreate,
    payrollPayrollEmployee
} from "../../../../../../services/adapters/process"

class CreateRepository {
    fetchReady = async (query) => {
        return await apiRequest("get", payrollPayrollReady, {params: query}, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", payrollPayrollCreate, {
            body: query
        }, true)
    }

    fetchEmployee = async (query) => {
        return await apiRequest("get", payrollPayrollEmployee, {
            params: query
        }, true)
    }
}

export default CreateRepository