import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../../services/apiResponse'

import moment from 'moment'

class TableListComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyMasterReferenceKurs'
    defaultOrder = 'kurs_date_start'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        {
            key: "kurs_active",
            text: "",
            className: "rating",
            align: 'center',
            width: 20,
            cell: record => {
                return (
                    <>
                        <div className="d-inline-block">
                            {
                                record.kurs_active === "1" ? <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div> : null
                            }
                        </div>
                    </>
                )
            }
        },
        {
            key: "kurs_currency_code",
            text: "Kode",
            className: "address",
            align: 'center',
            sortable: true,
            width: '100'
        },
        {
            key: "kurs_currency_name",
            text: "Mata Uang",
            className: "rating",
            sortable: true,
        },
        {
            key: "kurs_date_start",
            text: "Awal",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-center">
                            { moment(record.kurs_date_start).format('LL') }
                        </div>
                    </>
                )
            }
        },
        {
            key: "kurs_date_end",
            text: "Akhir",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-center">
                            { record.kurs_date_end === null ? '-' : moment(record.kurs_date_end).format('LL') }
                        </div>
                    </>
                )
            }
        },
        {
            key: "kurs_currency_value",
            text: "Nilai (Rp)",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            { record.kurs_currency_value.replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                        </div>
                    </>
                )
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 120,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisId = record.sample_id
                return (
                    <Fragment>
                        { accessRole('update') ? 
                            <span>
                                <button
                                    className="btn btn-warning btn-sm"
                                    id={"update-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            updateUuid: record.sample_uuid
                                        })
                                    }}
                                    style={{
                                        marginRight: '2px',
                                        padding: '2px 5px'
                                    }}>
                                        <i className="uil-pen"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["update" + thisId]} target={"update-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["update" + thisId] : !this.state["update" + thisId]
                                        })
                                    }}
                                >Perbaharui</Tooltip>
                            </span>
                            : 
                            <button
                                className="btn btn-soft-secondary btn-sm" disabled={true}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-pen"></i>
                            </button>
                        }
                        { accessRole('delete') ?
                            <span>
                                <button 
                                    className="btn btn-danger btn-sm" 
                                    id={"delete-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            inactiveUuid: record.sample_uuid,
                                            confirm: true
                                        })
                                    }}
                                    style={{
                                        padding: '2px 5px'
                                    }}>
                                    <i className="uil-trash-alt"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["delete" + thisId]} target={"delete-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["delete" + thisId] : !this.state["delete" + thisId]
                                        })
                                    }}
                                >Nonaktifkan</Tooltip>
                            </span>
                            :
                            <button
                                className="btn btn-soft-secondary btn-sm" disabled={true}
                                style={{
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-trash-alt"></i>
                            </button>
                        }
                    </Fragment>
                );
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
            </>
        )
    }

}

export default TableListComponent