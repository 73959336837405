import {
    apiRequest,
    masterReferenceSignerDetail,
    masterReferenceSignerUpdate
} from "../../../../../../services/adapters/master"

class UpdateRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", masterReferenceSignerDetail, {
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", masterReferenceSignerUpdate, {
            body: query
        }, true)
    }
}

export default UpdateRepository