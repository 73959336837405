import {
    apiRequest,
    masterReferenceMappingNipCreate,
    masterReferenceMappingNipEmployee
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterReferenceMappingNipCreate, {
            body: query
        }, true)
    }

    fetchEmployeeList = async () => {
        return await apiRequest("get", masterReferenceMappingNipEmployee, {}, true)
    }

}

export default CreateRepository