import React, { useState, Component, Fragment } from "react"
import SimpleBar from "simplebar-react"
import Shimmer from "react-shimmer-effect"
import moment from 'moment'
import { Row, Col } from 'reactstrap'

import ApproveBloc from '../Blocs/ApproveBloc'
import apiResponse from '../../../../../services/apiResponse'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

class DetailEmployeeComponent extends Component {

    approveBloc = new ApproveBloc();

    constructor(props) {
        super(props)
        this.state = {
            loadingData: true,
        }
    }

    componentDidMount() {
        this.approveBloc.employeeDetailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingData: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        detailData: response,
                    }, () => {
                        this.setState({
                            loadingData: false
                        })
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.approveBloc.fetchEmployeeDetail({ uuid: this.props.uuid })
    }

    componentWillUnmount() {
        this.approveBloc.employeeDetailChannel.unsubscribe()
    }

    render() {
        return (
            <>
                <Col>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            NIP
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>{this.state.detailData.overtime_employee_nip !== "" ? this.state.detailData.overtime_employee_nip : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 140, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nama
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>{this.state.detailData.overtime_employee_name !== "" ? this.state.detailData.overtime_employee_name : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 200, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Unit Kerja
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>
                                        {
                                            this.state.detailData.overtime_employee_work_unit_name !== "" ?
                                                <>
                                                    {this.state.detailData.overtime_employee_work_unit_name}
                                                </>
                                                : '-'
                                        }
                                    </>
                                    : <Shimmer><div className="shimmer" style={{ width: '80%', height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Jabatan
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>
                                        {
                                            this.state.detailData.overtime_employee_position_name !== "" ?
                                                <>
                                                    {this.state.detailData.overtime_employee_position_name}
                                                </>
                                                : '-'
                                        }
                                    </>
                                    : <Shimmer><div className="shimmer" style={{ width: '50%', height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Golongan Ruang
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>
                                        {
                                            this.state.detailData.overtime_employee_rank_name !== "" ?
                                                <>
                                                    {this.state.detailData.overtime_employee_rank_name}
                                                </>
                                                : '-'
                                        }
                                    </>
                                    : <Shimmer><div className="shimmer" style={{ width: 200, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Status Pernikahan
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>{this.state.detailData.overtime_employee_family_wife > 0 ? 'Menikah' : 'Tidak Menikah'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 200, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Jumlah Tanggungan
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>{this.state.detailData.overtime_employee_family_child > 0 ? this.state.detailData.overtime_employee_family_child + ' anak' : 'Tidak Ada'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 100, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nilai Lembur
                        </label>
                        <Col md="12">
                            <Table size="sm"
                                className="table table-striped table-bordered table-hover"
                            >
                                <thead>
                                    <tr>
                                        <th width="*" className="text-center" rowspan="2" style={{ verticalAlign: 'middle' }}>Tanggal</th>
                                        <th className="text-center" colspan="2" style={{ verticalAlign: 'middle' }}>Jumlah Jam</th>
                                        <th width="140px" className="text-center" rowspan="2" style={{ verticalAlign: 'middle' }}>Jam Makan Lembur</th>
                                        <th width="140px" className="text-center" rowspan="2" style={{ verticalAlign: 'middle' }}>Jumlah</th>
                                    </tr>
                                    <tr>
                                        <th width="100px" className="text-center">Hari Kerja</th>
                                        <th width="100px" className="text-center">Hari Libur</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.loadingData ?
                                            <>
                                                {
                                                    Object.entries(this.state.detailData.overtime_employee_result).map((item, key) => {
                                                        console.log(item);
                                                        return (
                                                            <tr key={key}>
                                                                <td className="text-center">
                                                                    {moment(item[1].overtime_day_date).format('LL')}
                                                                </td>
                                                                <td className="text-center">{item[1].overtime_weekday_total}</td>
                                                                <td className="text-center">{item[1].overtime_weekend_total}</td>
                                                                <td className="text-center">{item[1].overtime_formula_hour_meal}</td>

                                                                <td className="text-end">{((item[1].overtime_formula_overtime_total.replace('.00', '') * 1) + (item[1].overtime_formula_meal_total.replace('.00', '') * 1)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                            : <tr>
                                                <td colspan="7"><Shimmer><div className="shimmer" style={{ marginTop: 5, width: '100%', height: 15 }}></div></Shimmer></td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}

export default DetailEmployeeComponent