import React, { useState, Component, Fragment } from "react"
import SimpleBar from "simplebar-react"
import Shimmer from "react-shimmer-effect"
import moment from "moment"
import { Row, Col } from "reactstrap"

import ApproveBloc from "../Blocs/ApproveBloc"
import apiResponse from "../../../../../../services/apiResponse"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

class DetailEmployeeComponent extends Component {
    approveBloc = new ApproveBloc()

    constructor(props) {
        super(props)
        this.state = {
            loadingData: true,
        }
    }

    componentDidMount() {
        this.approveBloc.employeeDetailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingData: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState(
                        {
                            detailData: response,
                        },
                        () => {
                            this.setState({
                                loadingData: false,
                            })
                        }
                    )
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.approveBloc.fetchEmployeeDetail({ uuid: this.props.uuid })
    }

    componentWillUnmount() {
        this.approveBloc.employeeDetailChannel.unsubscribe()
    }

    render() {
        return (
            <>
                <Col>
                    <Row className="mb-3">
                        <label className="col-md-3">NIP</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_nip !== ""
                                        ? this.state.detailData.current.ptsp_employee_nip
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 140, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Nama</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_name !== ""
                                        ? this.state.detailData.current.ptsp_employee_name
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 200, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Unit Kerja</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_work_unit_name !== "" ? (
                                        <>
                                            {this.state.detailData.current.ptsp_employee_work_unit_uuid !==
                                                this.state.detailData.last.ptsp_employee_work_unit_uuid &&
                                            this.state.detailData.last.ptsp_employee_work_unit_uuid !== null ? (
                                                <>
                                                    <label className="bg-soft-success badge rounded-pill font-size-12">
                                                        {this.state.detailData.current.ptsp_employee_work_unit_name}
                                                    </label>
                                                    <br />
                                                    <label className="bg-soft-secondary badge rounded-pill font-size-12">
                                                        {this.state.detailData.last.ptsp_employee_work_unit_name}
                                                    </label>
                                                </>
                                            ) : (
                                                <>{this.state.detailData.current.ptsp_employee_work_unit_name}</>
                                            )}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "80%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Jabatan</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_position_name !== "" ? (
                                        <>
                                            {this.state.detailData.current.ptsp_employee_position_uuid !==
                                                this.state.detailData.last.ptsp_employee_position_uuid &&
                                            this.state.detailData.last.ptsp_employee_position_uuid !== null ? (
                                                <>
                                                    <label className="bg-soft-success badge rounded-pill font-size-12">
                                                        {this.state.detailData.current.ptsp_employee_position_name}
                                                    </label>
                                                    <br />
                                                    <label className="bg-soft-secondary badge rounded-pill font-size-12">
                                                        {this.state.detailData.last.ptsp_employee_position_name}
                                                    </label>
                                                </>
                                            ) : (
                                                <>{this.state.detailData.current.ptsp_employee_position_name}</>
                                            )}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "50%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Golongan Ruang</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_rank_name !== "" ? (
                                        <>
                                            {this.state.detailData.current.ptsp_employee_rank_uuid !==
                                                this.state.detailData.last.ptsp_employee_rank_uuid &&
                                            this.state.detailData.last.ptsp_employee_rank_uuid !== null ? (
                                                <>
                                                    <label className="bg-soft-success badge rounded-pill font-size-12">
                                                        {this.state.detailData.current.ptsp_employee_rank_name}
                                                    </label>
                                                    <br />
                                                    <label className="bg-soft-secondary badge rounded-pill font-size-12">
                                                        {this.state.detailData.last.ptsp_employee_rank_name}
                                                    </label>
                                                </>
                                            ) : (
                                                <>{this.state.detailData.current.ptsp_employee_rank_name}</>
                                            )}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 200, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Status Pernikahan</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_family_wife > 0
                                        ? "Menikah"
                                        : "Tidak Menikah"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 200, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Jumlah Tanggungan</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_family_child > 0
                                        ? this.state.detailData.current.ptsp_employee_family_child + " anak"
                                        : "Tidak Ada"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 100, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                        <label className="col-md-3">Nilai Tunjangan</label>
                        <Col md="12">
                            {!this.state.loadingData ? (
                                <Table className="table table-striped table-bordered table-hover">
                                    <Thead>
                                        <Tr>
                                            <Th width="*" data-priority="1">
                                                Nama
                                            </Th>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <Th width="160px" data-priority="3" className="text-center">
                                                    Sebelumnya
                                                </Th>
                                            ) : null}
                                            <Th width="160px" data-priority="3" className="text-center">
                                                Jumlah
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <tr>
                                            <td className="fw-bold">Nilai Honorarium</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="fw-bold text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_honorarium !== null
                                                            ? this.state.detailData.last.ptsp_employee_result.ptsp_result_value_honorarium
                                                                  .replace(".00", "")
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="fw-bold text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_honorarium !== null
                                                        ? this.state.detailData.current.ptsp_employee_result.ptsp_result_value_honorarium
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Nilai Tunkin</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="fw-bold text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_tunkin !== null
                                                            ? this.state.detailData.last.ptsp_employee_result.ptsp_result_value_tunkin
                                                                  .replace(".00", "")
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="fw-bold text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_tunkin !== null
                                                        ? this.state.detailData.current.ptsp_employee_result.ptsp_result_value_tunkin
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Total Tunjangan</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="fw-bold text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_total !== null
                                                            ? this.state.detailData.last.ptsp_employee_result.ptsp_result_value_total
                                                                  .replace(".00", "")
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="fw-bold text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_total !== null
                                                        ? this.state.detailData.current.ptsp_employee_result.ptsp_result_value_total
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-4">Tidak Masuk</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_not_present !== null
                                                            ? this.state.detailData.last.ptsp_employee_result.ptsp_result_value_not_present
                                                                  .replace(".00", "")
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_not_present !== null
                                                        ? this.state.detailData.current.ptsp_employee_result.ptsp_result_value_not_present
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-4">Telat Masuk</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_late !== null
                                                            ? this.state.detailData.last.ptsp_employee_result.ptsp_result_value_late
                                                                  .replace(".00", "")
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_late !== null
                                                        ? this.state.detailData.current.ptsp_employee_result.ptsp_result_value_late
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-4">Pulang Cepat</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_early !== null
                                                            ? this.state.detailData.last.ptsp_employee_result.ptsp_result_value_early
                                                                  .replace(".00", "")
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_early !== null
                                                        ? this.state.detailData.current.ptsp_employee_result.ptsp_result_value_early
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-4">Satu Kali Absen</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_single_finger !== null
                                                            ? this.state.detailData.last.ptsp_employee_result.ptsp_result_value_single_finger
                                                                  .replace(".00", "")
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_single_finger !== null
                                                        ? this.state.detailData.current.ptsp_employee_result.ptsp_result_value_single_finger
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Total Potongan Absensi</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="fw-bold text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_not_present !== null
                                                            ? (
                                                                  this.state.detailData.last.ptsp_employee_result
                                                                      .ptsp_result_value_not_present *
                                                                      1 +
                                                                  this.state.detailData.last.ptsp_employee_result
                                                                      .ptsp_result_value_single_finger *
                                                                      1 +
                                                                  this.state.detailData.last.ptsp_employee_result
                                                                      .ptsp_result_value_early *
                                                                      1 +
                                                                  this.state.detailData.last.ptsp_employee_result
                                                                      .ptsp_result_value_late *
                                                                      1
                                                              )
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="fw-bold text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_not_present !== null
                                                        ? (
                                                              this.state.detailData.current.ptsp_employee_result
                                                                  .ptsp_result_value_not_present *
                                                                  1 +
                                                              this.state.detailData.current.ptsp_employee_result
                                                                  .ptsp_result_value_single_finger *
                                                                  1 +
                                                              this.state.detailData.current.ptsp_employee_result
                                                                  .ptsp_result_value_early *
                                                                  1 +
                                                              this.state.detailData.current.ptsp_employee_result
                                                                  .ptsp_result_value_late *
                                                                  1
                                                          )
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-4">Tunjangan Khusus Pajak</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_tax !== null
                                                            ? this.state.detailData.last.ptsp_employee_result.ptsp_result_value_tax
                                                                  .replace(".00", "")
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_tax !== null
                                                        ? this.state.detailData.current.ptsp_employee_result.ptsp_result_value_tax
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-bold">Penghasilan Kotor</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="fw-bold text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_total !== null
                                                            ? (
                                                                  this.state.detailData.last.ptsp_employee_result
                                                                      .ptsp_result_value_total *
                                                                      1 +
                                                                  this.state.detailData.last.ptsp_employee_result
                                                                      .ptsp_result_value_tax *
                                                                      1
                                                              )
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="fw-bold text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_total !== null
                                                        ? (
                                                              this.state.detailData.current.ptsp_employee_result
                                                                  .ptsp_result_value_total *
                                                                  1 +
                                                              this.state.detailData.current.ptsp_employee_result
                                                                  .ptsp_result_value_tax *
                                                                  1
                                                          )
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="ps-4">Pajak Penghasilan</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_tax !== null
                                                            ? this.state.detailData.last.ptsp_employee_result.ptsp_result_value_tax
                                                                  .replace(".00", "")
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_tax !== null
                                                        ? this.state.detailData.current.ptsp_employee_result.ptsp_result_value_tax
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h5 fw-bold">Jumlah Bersih</td>
                                            {this.state.detailData.last.ptsp_employee_uuid !== null ? (
                                                <td className="h5 fw-bold text-end">
                                                    {this.state.detailData.last.ptsp_employee_result !== null
                                                        ? this.state.detailData.last.ptsp_employee_result
                                                              .ptsp_result_value_total !== null
                                                            ? this.state.detailData.last.ptsp_employee_result.ptsp_result_value_total
                                                                  .replace(".00", "")
                                                                  .toString()
                                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                            : "-"
                                                        : "-"}
                                                </td>
                                            ) : null}
                                            <td className="h5 fw-bold text-end">
                                                {this.state.detailData.current.ptsp_employee_result !== null
                                                    ? this.state.detailData.current.ptsp_employee_result
                                                          .ptsp_result_value_total !== null
                                                        ? this.state.detailData.current.ptsp_employee_result.ptsp_result_value_total
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"
                                                    : "-"}
                                            </td>
                                        </tr>
                                    </Tbody>
                                </Table>
                            ) : (
                                <>
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "60%", height: 36 }}></div>
                                    </Shimmer>
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "20%", height: 36 }}></div>
                                    </Shimmer>
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "20%", height: 36 }}></div>
                                    </Shimmer>

                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "60%", height: 22 }}></div>
                                    </Shimmer>
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "20%", height: 22 }}></div>
                                    </Shimmer>
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "20%", height: 22 }}></div>
                                    </Shimmer>
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}

export default DetailEmployeeComponent
