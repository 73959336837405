import {
    apiRequest,
    masterTunkinCountryList,
    masterTunkinCountryDetail
} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterTunkinCountryList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDetail = async (query, cancelToken) => {
        return await apiRequest("get", masterTunkinCountryDetail, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository