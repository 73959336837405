import { apiRequest, officialTravelRapelSt, officialTravelRapelDetail, officialTravelRapelNominative, officialTravelRapelRiil, officialTravelRapelCost, officialTravelRapelSptb } from "../../../../../../services/adapters/output"

class DownloadRepository {
    fetchSt = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelSt,
            {
                params: query,
            },
            true
        )
    }

    fetchDetail = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchNominative = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelNominative,
            {
                params: query,
            },
            true
        )
    }

    fetchRiil = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelRiil,
            {
                params: query,
            },
            true
        )
    }

    fetchCost = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelCost,
            {
                params: query,
            },
            true
        )
    }

    fetchSptb = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelSptb,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
