import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"

import { Tooltip, Modal } from "reactstrap"

import MKIDatatable from "../../../../../../modules/mki-datatable/lib"

import { cancelToken } from "../../../../../../services/adapters/base"

import IndexViewBloc from "../Blocs/IndexViewBloc"
import apiResponse from "../../../../../../services/apiResponse"

import ManualEmployeeListComponent from "./ManualEmployeeListComponent"

class ManualListComponent extends Component {
    indexViewBloc = new IndexViewBloc()

    source
    historyTable = "historyManualListComponent"
    defaultOrder = "gpp_year"
    defaultSize = 10
    defaultSort = "asc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            showManualEmployee: false,
            manualYear: null,
            manualMonthName: null,
            manualUnique: null,
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
        }
        await this.indexViewBloc.fetchRowTableManual(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {}

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.rowDataManualChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.rowDataManualChannel.unsubscribe()
    }

    columns = [
        {
            key: "gpp_month_name",
            text: "Bulan",
            width: "200",
            align: "right",
            sortable: true,
        },
        {
            key: "gpp_year",
            text: "Tahun",
            width: "100",
            align: "center",
            sortable: true,
        },
        {
            key: "gpp_total_row",
            text: "Total Pegawai",
            sortable: true,
            align: "center",
            cell: (record) => {
                return <>{record.gpp_total_row.toString() === "0" ? "Tidak ada" : <>{record.gpp_total_row}</>}</>
            },
        },
        {
            key: "action",
            text: "Aksi",
            width: "80",
            align: "center",
            cell: (record) => {
                const thisId = record.gpp_month
                return (
                    <>
                        <button
                            className="btn btn-success btn-sm"
                            id={"detail-" + thisId}
                            onClick={() => {
                                this.setState({
                                    manualYear: record.gpp_year,
                                    manualMonthName: record.gpp_month_name,
                                    manualUnique: btoa(
                                        JSON.stringify({
                                            year: record.gpp_year,
                                            month: record.gpp_month,
                                            total: record.gpp_total_row,
                                        })
                                    ),
                                    showManualEmployee: true,
                                })
                            }}
                            style={{
                                padding: "2px 5px",
                            }}
                        >
                            <i className="uil-align-left"></i>
                        </button>
                        <Tooltip
                            style={{
                                marginBottom: 2,
                            }}
                            placement="top"
                            isOpen={this.state["detail" + thisId]}
                            target={"detail-" + thisId}
                            toggle={() => {
                                this.setState({
                                    ["detail" + thisId]: !this.state["detail" + thisId],
                                })
                            }}
                        >
                            Detail
                        </Tooltip>
                    </>
                )
            },
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={700}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="xl"
                    isOpen={this.state.showManualEmployee}
                    toggle={() => {
                        this.setState({
                            showManualEmployee: !this.state.showManualEmployee,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Gaji Manual {this.state.manualMonthName}&nbsp;
                            {this.state.manualYear}
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showManualEmployee: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.showManualEmployee ? (
                            <ManualEmployeeListComponent uuid={this.state.manualUnique} />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </>
        )
    }
}

export default ManualListComponent
