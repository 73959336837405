import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    Alert,
    Table,
    Input
} from "reactstrap"
import { withRouter } from "react-router";
import apiResponse from '../../../../../../services/apiResponse'
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'
import { generateFormData } from '../../../../../../helpers/ui/FileInput'
import Select from "react-select"
import moment from 'moment'

import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import OmspanBloc from '../Blocs/OmspanBloc'


class FormCreateComponent extends Component {

    omspanBloc = new OmspanBloc();

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            loadingYear: true,
            optionYear: [
                {
                    label: "Tahun",
                    options: []
                }
            ]
        }
    }

    loadYear = async () => {
        await this.omspanBloc.fetchYearAvailable()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.createToken()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    createToken = async () => {
        const formData = generateFormData(this.state.dataForm, {})
        await this.omspanBloc.fetchCreate(formData)
    }

    componentDidMount() {
        this.omspanBloc.yearAvailableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        loadingYear: false,
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.loadYear()
        this.omspanBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        this.setState({
                            loading: false,
                            success: true,
                        });
                        setTimeout(() => {
                            this.props.successCreate()
                        }, 1000)
                    } else {
                        this.setState({
                            loading: false,
                            failed: true,
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState({
            selectedYear: itemYear.length > 0 ? itemYear[0] : {},
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear
                }
            ]
        }, () => {
            console.log(this.state.optionYear[0].options)
        });
    }

    componentWillUnmount() {
        this.omspanBloc.yearAvailableChannel.unsubscribe()
        this.omspanBloc.createChannel.unsubscribe()
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState({
            selectedYear: selectedYear,
            loadingEmployee: true
        }, () => {
            document.querySelector('#fieldOmspanToken').value = ''
        });
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
                year: this.state.selectedYear.value
            },
            confirm: true
        }, () => {
            console.log(this.state.dataForm)
        });
    }

    render() {
        return (
            <Fragment>
                <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                >
                    <div className="modal-body" style={{ paddingBottom: 0 }}>
                        {
                            !this.state.loadingYear && this.state.optionYear[0].options.length === 0 ?
                                <Alert color="danger">
                                    Tidak dapat menambahkan token baru
                                </Alert>
                                :
                                <>
                                    <Row className="mb-3">
                                        <label
                                            className="col-md-3 col-form-label"
                                        >
                                            Tahun <span className="text-danger">*</span>
                                        </label>
                                        <Col md="9">
                                            <div style={{ width: 116 }}>
                                                {
                                                    this.state.loadingYear ?
                                                        <><Shimmer><div className="shimmer" style={{ width: 116, height: 36 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <>
                                                            <div style={{ width: 116 }}>
                                                                <Select
                                                                    value={this.state.selectedYear}
                                                                    onChange={(selected) => {
                                                                        this.handleOnChangeYear(selected)
                                                                    }}
                                                                    options={this.state.optionYear}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <label
                                            className="col-md-3 col-form-label"
                                        >
                                            {
                                                this.state.loadingYear ?
                                                    <><Shimmer><div className="shimmer" style={{ width: 116, height: 19.59 }}></div></Shimmer>{" "}</>
                                                    :
                                                    <>
                                                        Token Omspan <span className="text-danger">*</span>
                                                    </>
                                            }
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.loadingYear ?
                                                    <><Shimmer><div className="shimmer" style={{ width: '100%', height: 103.41 }}></div></Shimmer>{" "}</>
                                                    :
                                                    <>
                                                        <AvField
                                                            name="token"
                                                            placeholder="Token Omspan"
                                                            errorMessage="Masukkan Token Omspan"
                                                            type="textarea"
                                                            className="form-control"
                                                            id="fieldOmspanToken"
                                                            rows="4"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </>
                                            }

                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="refundAgreement" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                        }
                    </div>
                    <div className="modal-footer">
                        {
                            !this.state.loadingYear && this.state.optionYear[0].options.length === 0 ?
                                <></>
                                :
                                <button
                                    disabled={this.state.loadingYear}
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light"
                                >
                                    Simpan
                                </button>
                        }
                        <button
                            type="button"
                            onClick={() => {
                                this.props.cancelCreate()
                            }}
                            className="btn btn-danger waves-effect"
                        >
                            Tutup
                        </button>
                    </div>
                </AvForm>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan mengirim bukti data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
            </Fragment >
        )
    }
}

export default withRouter(FormCreateComponent);