import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import UpdateRepository from '../Repositories/UpdateRepository'


class UpdateBloc {

    checkTunkinChannel = new Subject();
    approveChannel = new Subject();
    signerChannel = new Subject();
    detailChannel = new Subject();
    repository = new UpdateRepository()

    constructor() {
        this.checkTunkinChannel.next({status: apiResponse.INITIAL})
        this.approveChannel.next({status: apiResponse.INITIAL})
        this.signerChannel.next({status: apiResponse.INITIAL})
        this.detailChannel.next({status: apiResponse.INITIAL})
    }

    fetchCheckTunkin = async (query) => {
        this.checkTunkinChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCheckTunkin(query).then((result) => {
                this.checkTunkinChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.checkTunkinChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchApprove = async (query) => {
        this.approveChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchApprove(query).then((result) => {
                this.approveChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.approveChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchGetSigner = async (query) => {
        this.signerChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchGetSigner(query).then((result) => {
                this.signerChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.signerChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.detailChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}       
    }

}

export default UpdateBloc