import React, { Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect";

import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button, Label
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { Link, Redirect } from "react-router-dom"
import apiResponse from '../../../../../services/apiResponse'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import CreateBloc from './Blocs/CreateBloc'

const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ];
class SkpCreateView extends Component {

    createBloc = new CreateBloc();
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear()-1 : this.thisDate.getFullYear()
    thisMonth = this.thisDate.getMonth() + 1

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            year: null,
            month: null,
            years: [],
            months: [],
            loadingYear: true,
            loadingMonth: false,
            jumlah: 3,
            count: 3,
            accessId: localStorage.getItem('currentRoleId'),
            loadingEmployee: true,
            employees: []
        }
    }

    submitData = async () => {
        //console.log(this.state.dataForm)
        await this.createBloc.fetchCreate(this.state.dataForm)
    }

    loadPayrollReadyYear = async (uuid) => {
        await this.createBloc.fetchReadyYear({uuid: uuid})
    }

    loadPayrollReadyMonth = async (year) => {
        //alert(year)
        await this.createBloc.fetchReadyMonth({year: year, uuid: this.state.selectedEmployee})
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()

        let year = this.state.year 
        let month = this.state.month
        let jumlah = this.state.jumlah
        var _count = 0;

        for (let i = 0; i < jumlah; i++) {
            if ((month - i) <= 0) {
                if((year - 1) >= 2021) {
                    ++_count;
                }
            } else {
                ++_count;
            }
        }
        this.setState({
            dataForm: {
              ...values,
              jumlah: _count
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    listPengajuan = () => {
        let year = this.state.year 
        let month = this.state.month
        let jumlah = this.state.jumlah

        let list = []
        if(year !== null && month !== null) {
        //var _count = 0;

            for (let i = 0; i < jumlah; i++) {
                if ((month - i) <= 0) {
                    if((year - 1) >= 2021) {
                        list.push({ year: year - 1, month: 12 + (month - i) })
                        //++_count;
                    }
                } else {
                    list.push({ year: year, month: month - i })
                    //++_count;
                }
            }
        }
        /* this.setState({
            count: _count
        }) */
        return list
    }

    /* yearList = () => {
        //var d = new Date(new Date().setMonth(new Date().getMonth()-1))
        var d = new Date()
        var n = d.getFullYear()
        var option = []
        for(let i = n; i > 2020; i--) {
            option.push({label: i, value: i})
        }
        this.setState({
            years: option
        })
    }*/

    /* monthList = (year) => {
        //var d = new Date(new Date().setMonth(new Date().getMonth()-1))
        var d = new Date()
        var n = d.getFullYear();
        var m = d.getMonth();
        var option = []
        if(year === n) {
            for(let i = m; i >= 0; i--) {
                option.push({label: months[i], value: (i+1) })
            }
        } else {
            for(let i = months.length - 1; i >= 0; i--) {
                option.push({label: months[i], value: (i+1) })
            }
        }
        setTimeout(() => {
            this.setState({
                months: option,
                loadingMonth: false
            })
        }, 100)
    } */

    componentDidMount() {
        //this.yearList()
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.readyYearChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingYear: false,
                        years: response
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.createBloc.readyMonthChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingMonth: false,
                        months: response
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.createBloc.employeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingEmployee: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingEmployee: false,
                        employees: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        
                    });
                    break
                default:
                    break
            }
        })

        if(this.state.accessId === '2') {
            this.createBloc.fetchEmployee()
            this.setState({
                loadingYear: false
            })
        } else {
            this.loadPayrollReadyYear(null)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.year !== this.state.year) {
            this.listPengajuan()
        }
        if (prevState.month !== this.state.month) {
            this.listPengajuan()
        }
        if (prevState.jumlah !== this.state.jumlah) {
            this.listPengajuan()
        }
    }

    componentWillUnmount() {
        this.createBloc.employeeChannel?.unsubscribe()
        this.createBloc.readyYearChannel.unsubscribe()
        this.createBloc.readyMonthChannel.unsubscribe()
        this.createBloc.createChannel?.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah Pengajuan Surat Informasi Gaji Pegawai</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        {
                                            this.state.accessId === '2' ? 
                                                <Row className="mb-3">
                                                    <Label
                                                        htmlFor="fieldNama"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Nama Pegawai<span className="text-danger">*</span>
                                                    </Label>
                                                    <Col md="6">
                                                        {
                                                            this.state.loadingEmployee?
                                                                <><Shimmer><div className="shimmer" style={{width: '100%', height: 38}}></div></Shimmer>{" "}</>
                                                            :
                                                                <>
                                                                    <CustomSelect 
                                                                        name="uuid"
                                                                        errorMessage="Pilih Pegawai"
                                                                        options={this.state.employees} 
                                                                        placeholder="Pilih Pegawai"
                                                                        optionLabel="employee_name"
                                                                        optionValue="employee_uuid"
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={(value) => {
                                                                            this.setState({
                                                                                year: null,
                                                                                years: [],
                                                                                loadingYear: true,
                                                                                month: null,
                                                                                months: [],
                                                                                loadingMonth: true,
                                                                                selectedEmployee: value.employee_nip
                                                                            })
                                                                            setTimeout(() => {
                                                                                this.setState({
                                                                                    loadingMonth: false
                                                                                })
                                                                            }, 1)

                                                                            this.loadPayrollReadyYear(value.employee_nip)
                                                                        }}
                                                                    />
                                                                </>
                                                            }
                                                    </Col>
                                                </Row>
                                            : <></>
                                        }
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Tahun<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                {
                                                    this.state.loadingYear ?
                                                        <Shimmer><div className="shimmer" style={{width: '100%', height: 33}}></div></Shimmer>
                                                    :
                                                        <CustomSelect 
                                                            name="year"
                                                            errorMessage="Pilih Tahun"
                                                            options={this.state.years} 
                                                            placeholder="Pilih Tahun"
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            validate={{ required: { value: true } }}
                                                            onChange={(value) => {
                                                                this.setState({
                                                                    year: value.value,
                                                                    month: null,
                                                                    months: [],
                                                                    loadingMonth: true
                                                                })
                                                                this.loadPayrollReadyMonth(value.value)
                                                            }}
                                                        />
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Bulan<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                {
                                                    this.state.loadingMonth ?
                                                        <Shimmer><div className="shimmer" style={{width: '100%', height: 33}}></div></Shimmer>
                                                    :
                                                        <CustomSelect 
                                                            name="month"
                                                            errorMessage="Pilih Bulan"
                                                            options={this.state.months} 
                                                            placeholder="Pilih Bulan"
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            validate={{ required: { value: true } }}
                                                            onChange={opt => this.setState({ month: opt.value })}
                                                        />
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Jumlah<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <CustomSelect 
                                                    name="jumlah"
                                                    options={[{value: 1, label: '1 Bulan'}, {value: 3, label: '3 Bulan'}, {value: 6, label: '6 Bulan'}, {value: 12, label: '12 Bulan'}]}
                                                    defaultValue={this.state.jumlah}
                                                    onChange={opt => this.setState({ jumlah: opt.value })}
                                                />
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Pengajuan Sigap<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6" className="mt-2">
                                                <small><i>Data yang dapat diajukan minimal bulan Januari 2021</i></small>
                                                {
                                                    this.listPengajuan()?.length > 0 ?
                                                        <Table
                                                    className="table table-striped table-bordered table-hover"
                                                >
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="50px" data-priority="1">No</Th>
                                                            <Th width="*" data-priority="3">Bulan</Th>
                                                            <Th width="*" data-priority="1">Tahun</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        { 
                                                            this.listPengajuan()?.map((val, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{i+1}</td>
                                                                        <td>{months[val.month - 1]}</td>
                                                                        <td>{val.year}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </Tbody>
                                                </Table>
                                                    :
                                                        <></>
                                                }
                                                
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row className="mb-3">
                                            <Col md="1"></Col>
                                            <Col md="6">
                                                <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/payroll/skp.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>


                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>


                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/payroll/skp.html'/>
                : null }

            </Fragment>
        )
    }
}

export default SkpCreateView