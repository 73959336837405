import {
    apiRequest,
    masterCmsInformasi,
    masterCmsGetInformasi
} from "../../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterCmsInformasi, {
            body: query
        }, true)
    }

    fetchGetInformasi = async () => {
        return await apiRequest("get", masterCmsGetInformasi, {}, true)
    }
}

export default CreateRepository