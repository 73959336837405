import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import UpdateRepository from '../Repositories/UpdateRepository'


class UpdateBloc {

    detailChannel = new Subject();
    updateChannel = new Subject();
    listNrsChannel = new Subject();
    repository = new UpdateRepository()

    constructor() {
        this.updateChannel.next({status: apiResponse.INITIAL})
        this.listNrsChannel.next({status: apiResponse.INITIAL})
        this.detailChannel.next({status: apiResponse.INITIAL})
    }

    fetchNrs = async (query) => {
        this.listNrsChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchNrs(query).then((result) => {
                this.listNrsChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.listNrsChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        await this.repository.fetchDetail(query).then((result) => {
            this.detailChannel.next({status: apiResponse.COMPLETED, data: result})
        }).catch((error) => {
            this.detailChannel.next({status: apiResponse.ERROR, data: error})
        })
    }

    fetchUpdate = async (query) => {
        this.updateChannel.next({status: apiResponse.LOADING})
        await this.repository.fetchUpdate(query).then((result) => {
            this.updateChannel.next({status: apiResponse.COMPLETED, data: result})
        }).catch((error) => {
            this.updateChannel.next({status: apiResponse.ERROR, data: error})
        })
    }
}

export default UpdateBloc