import {
    apiRequest,
    masterPtspTunkinCreate,
    masterPtspTunkinRankList,
    masterPtspTunkinMasterGrade
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterPtspTunkinCreate, {
            body: query
        }, true)
    }

    fetchRankList = async () => {
        return await apiRequest("get", masterPtspTunkinRankList, {}, true)
    }

    fetchGrade = async () => {
        return await apiRequest("get", masterPtspTunkinMasterGrade, {}, true)
    }
}

export default CreateRepository