import {
    apiRequest,
    officialTravelSubmissionProcessDetail,
    officialTravelSubmissionProcessParticipant,
    officialTravelSubmissionProcessUpdate,
    officialTravelSubmissionSource,
    officialTravelSubmissionProcessDeleteParticipant
} from "../../../../../../services/adapters/process"

class ProcessRepository {

    fetchDeleteParticipant = async (query) => {
        return await apiRequest("post", officialTravelSubmissionProcessDeleteParticipant, {
            body: query
        }, true)
    }


    fetchUpdate = async (query) => {
        return await apiRequest("post", officialTravelSubmissionProcessUpdate, {
            body: query
        }, true)
    }

    fetchDetail = async (query) => {
        return await apiRequest("get", officialTravelSubmissionProcessDetail, {
            params: query
        }, true)
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelSubmissionProcessParticipant, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchSource = async (query) => {
        return await apiRequest("get", officialTravelSubmissionSource, {
            params: query
        }, true)
    }
}

export default ProcessRepository
