import React, { Fragment, useState } from "react"
import PropTypes from 'prop-types'
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import TimeAgo from 'react-timeago'

import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

import idStrings from 'react-timeago/lib/language-strings/id'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

// Import DataTableBloc
import DataTableBloc from '../../../pages/Home/Notification/Index/Blocs/DataTableBloc.jsx'

const NotificationDropdown = props => {

  const formatter = buildFormatter(idStrings)
  const history = useHistory()
  const dataTableBloc = new DataTableBloc()

  const notifCount = parseInt(props.notificationCount)
  const notifList = props.notificationList
  const [menu, setMenu] = useState(false)

  const getNotificationLink = (notif) => {
    const baseUrl = notif.notification_type === 'PSLIP' 
      ? "/payroll/slip.html?payrollupdate&uuid=" 
      : "/payroll/sigap.html?payrollupdate&uuid=";
    return baseUrl + notif.notification_data_uuid;
  };

  const handleNotificationClick = (notif) => {
    // Update notification status
    dataTableBloc.fetchFlagRead({ notification_id: notif.notification_id })

    // Close the dropdown
    setMenu(false)

    // Redirect to the notification link
    history.push(getNotificationLink(notif))
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell font-size-18 text-muted"></i>
          { notifCount > 0 ? <span className="badge bg-danger rounded-pill">{notifCount}</span> : null }
        </DropdownToggle>

        { notifCount > 0 ? <>
          <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 font-size-16">Notifikasi</h6>
                </Col>
              </Row>
            </div>

            <SimpleBar style={{ maxHeight: "230px" }}>
              { notifList.length > 0 ? notifList.map((notif) => {
                return (
                  <Fragment key={notif.notification_id}>
                    <div
                      onClick={() => handleNotificationClick(notif)}
                      className="text-reset notification-item"
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="d-flex align-items-start">
                        <div className="me-3">
                          <i className="uil-bell font-size-18 text-muted"></i>
                        </div>
                        <div className="flex-1">
                          <h6 className={`mt-0 mb-1 ${notif.notification_read === 0 ? "fw-bold" : ""}`}>
                            { notif.notification_title }
                          </h6>
                          <div className="font-size-12 text-muted">
                            <p className={`mb-1 ${notif.notification_read === 0 ? "fw-bold" : ""}`}>
                              { notif.notification_description }
                            </p>
                            <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                              <TimeAgo date={ notif.notification_date } formatter={formatter} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )
              }) : <></> }
            </SimpleBar>
            <div className="p-2 border-top d-grid">
              <Link
                className="btn btn-sm btn-link font-size-14 text-center"
                to="/home/notification.html"
              ><i className="uil-arrow-circle-right me-1"></i>
                Lihat Semua Notifikasi
              </Link>
            </div>
          </DropdownMenu>
        </> : null }
      </Dropdown>
    </>
  )
}

NotificationDropdown.propTypes = {
  notificationCount: PropTypes.number,
  notificationList: PropTypes.any
}

const mapStatetoProps = state => {
  return {
    ...state.Layout,
    ...state.Notification
  }
}

export default withRouter(
  connect(mapStatetoProps, {})(NotificationDropdown)
)