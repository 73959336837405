import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import ApproveRepository from '../Repositories/ApproveRepository'

class ApproveBloc {

    verifyUpdateChannel = new Subject()
    verifyDetailChannel = new Subject()
    verifyParticipantChannel = new Subject()
    verifySourceChannel = new Subject()
    repository = new ApproveRepository()

    constructor() {
        this.verifyUpdateChannel.next({ status: apiResponse.INITIAL })
        this.verifyDetailChannel.next({ status: apiResponse.INITIAL })
        this.verifyParticipantChannel.next({ status: apiResponse.INITIAL })
        this.verifySourceChannel.next({ status: apiResponse.INITIAL })
    }

    fetchApprove = async (query) => {
        this.verifyUpdateChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchApprove(query).then((result) => {
                this.verifyUpdateChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifyUpdateChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchDetail = async (query) => {
        this.verifyDetailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.verifyDetailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifyDetailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchParticipant = async (query) => {
        this.verifyParticipantChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchParticipant(query).then((result) => {
                this.verifyParticipantChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifyParticipantChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchSource = async (query) => {
        this.verifySourceChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchSource(query).then((result) => {
                this.verifySourceChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifySourceChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

}

export default ApproveBloc