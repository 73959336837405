import {
    apiRequest,
    krkoList,
    krkoEmployeeList
} from "../../../../../services/adapters/process"

class IndexViewRepository {

    fetchRowTableKrko = async (query, cancelToken) => {
        return await apiRequest("get", krkoList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableEmployee = async (query, cancelToken) => {
        return await apiRequest("get", krkoEmployeeList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default IndexViewRepository