import { accessRole } from "../../../../helpers/access_role"
import ErrorAccess from '../../../../components/Error/Content/Access'

import IndexView from "./Index/View"
import OmspanView from "./Omspan/View"

const RouteView = (props) => {

  const queryParams = new URLSearchParams(props.location.search);
  const _create = queryParams.get('create')
  const _detail = queryParams.get('detail')
  const _update = queryParams.get('update')
  const _delete = queryParams.get('delete')
  const _approve = queryParams.get('approve')
  const _print = queryParams.get('print')

  const omspan = queryParams.get('omspan')
  const omspanCreate = queryParams.get('omspan-create')

  if (omspan != null) {
    return accessRole('create') ? <OmspanView /> : <ErrorAccess />
  }
  return accessRole('read') ? <IndexView /> : <ErrorAccess />
}
export default RouteView