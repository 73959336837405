import { apiRequest, officialTravelRapelVerificationProcessDetail, officialTravelRapelVerificationProcessParticipant, officialTravelRapelVerificationProcessApprove, officialTravelRapelVerificationSource } from "../../../../../../services/adapters/process"

class ApproveRepository {
    fetchApprove = async (query) => {
        return await apiRequest(
            "post",
            officialTravelRapelVerificationProcessApprove,
            {
                body: query,
            },
            true
        )
    }

    fetchDetail = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelVerificationProcessDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            officialTravelRapelVerificationProcessParticipant,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchSource = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelVerificationSource,
            {
                params: query,
            },
            true
        )
    }
}

export default ApproveRepository
