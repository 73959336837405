import React, { Component, Fragment } from "react"
import * as XLSX from 'xlsx';
import Dropzone from "react-dropzone"
import loadingIcon from "../../../../../../assets/images/loading.gif"
import {
    UncontrolledAlert,
    Button,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table"
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'
import { decode, encode } from 'base-64'

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../../services/apiResponse'

class UploadComponent extends Component {
    dataTableBloc = new DataTableBloc();

    excelConfig = {
        headerRow: 0,
        startRow: 1,
        perUpload: 4,
        getColumn: [0, 1, 2, 3, 4, 5],
        columnRequired: [0, 1, 2, 3, 4, 5],
        headerData: [
            'Nama Pegawai',
            'Kode Bank',
            'Nama Bank',
            'Cabang Bank',
            'Nomor Rekening',
            'Nama Rekening',
        ],
        headerTableAttr: [
            ['text-left', '*'],
            ['text-center', '120px'],
            ['text-center', '120px'],
            ['text-center', '120px'],
            ['text-center', '120px'],
            ['text-center', '120px'],
        ],
        tableMaxHeight: 400,
        tableMinWidth: 600,
    }

    constructor(props) {
        super(props)
        this.state = {
            errorText: null,
            uploadText: "Tunggu beberapa saat",
            fileDataUploaded: 0,
            fileDataTotal: 0,
            fileDataGroupItem: [],
            fileDataUploadStep: 0,

            page: 'form',
            selectedFiles: [],
        }
    }

    handleAcceptedFiles = (files) => {
        this.setState({
            page: 'loading',
        })
        var f = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary', cellDates: true, cellNF: false, cellText: false });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'yyyy-mm-dd' })
            this.validatingData(dataParse)
        }
        reader.readAsBinaryString(f)
    }

    validatingData = (data) => {
        if (data.length > 0) {

            var _data = []
            var _i = 0;
            for (var n = 0; n < data.length; ++n) {
                var valid = true
                if (this.excelConfig.columnRequired.length > 0) {
                    for (var r = 0; r < this.excelConfig.columnRequired.length; ++r) {
                        if (data[n][this.excelConfig.columnRequired[r]] === '') {
                            valid = false
                        }
                    }
                }
                var allNull = 0
                for (var a = 0; a < data[n].length; ++a) {
                    if (data[n][a].trim() === '') {
                        ++allNull
                    }
                }
                if (valid && allNull < data[n].length) {
                    _data[_i] = data[n]
                    ++_i;
                }
            }
            data = _data

            var header = data[this.excelConfig.headerRow];
            var validHeader = true;
            if (header.length >= 6) {
                for (var h = 0; h < this.excelConfig.headerData.length; ++h) {
                    var headerKey = this.excelConfig.getColumn.length === 0 ? h : this.excelConfig.getColumn[h]
                    if (this.excelConfig.headerData[h] !== header[headerKey]) {
                        validHeader = false
                    }
                }
                if (validHeader) {
                    var dataGroupItem = []
                    var dataTotal = data.length - 1
                    var dataUploadStep = Math.ceil(dataTotal / this.excelConfig.perUpload)
                    for (var s = 0; s < dataUploadStep; ++s) {
                        var dataItems = []
                        var start = (this.excelConfig.perUpload * s) + this.excelConfig.startRow
                        var end = (start + this.excelConfig.perUpload) - 1;
                        end = end < dataTotal ? end : dataTotal;
                        for (var i = start; i <= end; ++i) {
                            var items = [];
                            for (var c = 0; c < this.excelConfig.getColumn.length; ++c) {
                                items.push(data[i][this.excelConfig.getColumn[c]])
                            }
                            dataItems.push(items)
                        }
                        dataGroupItem.push(dataItems)
                    }
                    this.setState({
                        page: 'table',
                        fileDataTotal: dataTotal,
                        fileDataGroupItem: dataGroupItem,
                        fileDataUploadStep: dataUploadStep,
                    })
                    this.props.handleSetModalSize('lg')
                    /* setTimeout(() => {
                        this.setState({
                            page: 'table'
                        })
                        this.props.handleSetModalSize('lg')
                    }, 1000); */
                } else {
                    this.setState({
                        page: 'form',
                        errorText: 'Header tidak sesuai'
                    })
                }
            } else {
                this.setState({
                    page: 'form',
                    errorText: 'File tidak sesuai'
                })
            }
        } else {
            this.setState({
                page: 'form',
                errorText: 'File tidak diizinkan'
            })
        }
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false,
                    uploadText: "Mengunggah... (" + (this.state.fileDataUploaded + 1) + "/" + this.state.fileDataUploadStep + ") tahap",
                }
                this.doUpload()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    doStartUpload = () => {
        this.setState({
            confirm: true
        })
    }

    doUpload = () => {
        var _data = encode(JSON.stringify(this.state.fileDataGroupItem[this.state.fileDataUploaded]))
        this.dataTableBloc.fetchUpload({ data: _data })
    }

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    componentDidMount() {
        this.dataTableBloc.uploadChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        fileDataUploaded: this.state.fileDataUploaded + 1,
                    }, function () {
                        if (this.state.fileDataUploaded === this.state.fileDataUploadStep) {
                            this.setState({
                                loading: false,
                                success: true,
                            });
                            setTimeout(() => {
                                this.props.handleCloseModal(true)
                            }, 2000)
                        } else {
                            this.setState({
                                uploadText: "Mengunggah... (" + (this.state.fileDataUploaded + 1) + "/" + this.state.fileDataUploadStep + ") tahap",
                            });
                            this.doUpload()
                        }
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                    });
                    break
                default:
                    break
            }
        })
    }

    render() {
        return (
            <>
                {
                    this.state.page === 'form' ?
                        <>
                            {this.state.errorText ?
                                <UncontrolledAlert color="danger" className="alert-dismissible fade show">
                                    {this.state.errorText}
                                </UncontrolledAlert>
                                : null
                            }
                            <Dropzone
                                maxFiles={1}
                                onDrop={acceptedFiles => {
                                    this.handleAcceptedFiles(acceptedFiles)
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                        <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                            <div className="mb-3">
                                                <i className="display-4 text-muted uil uil-cloud-upload" />
                                            </div>
                                            <h4>Drop files here or click to upload.</h4>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            <a href={process.env.REACT_APP_SERVICE_MASTER_URL + "/template/MappingBankAccount.xlsx"} target="_blank" rel="noreferrer">
                                Unduh template...
                            </a>
                        </>
                        :
                        <>
                            {
                                this.state.page === 'loading' ?
                                    <>
                                        <div
                                            style={{
                                                height: 230,
                                                textAlign: 'center',
                                                paddingTop: 60
                                            }}
                                        >
                                            <img src={loadingIcon} alt="" style={{ width: 60 }} />
                                        </div>
                                        Memeriksa file...
                                    </>
                                    :
                                    <>
                                        <div
                                            className="col-12"
                                            style={{
                                                maxHeight: this.excelConfig.tableMaxHeight,
                                                overflowY: 'auto',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    minWidth: this.excelConfig.tableMinWidth,
                                                    overflowX: 'auto'
                                                }}
                                            >
                                                <Table
                                                    className="table table-striped table-bordered table-hover"
                                                >
                                                    <Thead>
                                                        <Tr>
                                                            {
                                                                this.excelConfig.headerData?.map((header, key) => {
                                                                    var _class = (typeof this.excelConfig.headerTableAttr[key][0] !== 'undefined') ? this.excelConfig.headerTableAttr[key][0] : ''
                                                                    var width = (typeof this.excelConfig.headerTableAttr[key][1] !== 'undefined') ? this.excelConfig.headerTableAttr[key][1] : '*'
                                                                    return <Th key={"th" + key} width={width} className={_class}>{header}</Th>
                                                                })
                                                            }
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            this.state.fileDataGroupItem?.map((items) => {
                                                                const tr = []
                                                                items?.map((item, key) => {

                                                                    const td = []
                                                                    this.excelConfig.headerData?.map((header, key) => {
                                                                        var _class = (typeof this.excelConfig.headerTableAttr[key][0] !== 'undefined') ? this.excelConfig.headerTableAttr[key][0] : ''
                                                                        td.push(<td className={_class}>{item[key]}</td>)
                                                                    })
                                                                    tr.push(<tr key={"td" + key}>{td}</tr>)
                                                                })
                                                                return <>{tr}</>
                                                            })
                                                        }
                                                    </Tbody>
                                                </Table>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 mt-2"
                                        >
                                            <div className="float-end">
                                                <Button
                                                    color="danger"
                                                    type="button"
                                                    onClick={() => {
                                                        this.props.handleCloseModal()
                                                    }}
                                                >
                                                    <i className="uil-times"></i> Batal
                                                </Button>
                                                {' '}
                                                <Button
                                                    color="success"
                                                    type="button"
                                                    onClick={this.doStartUpload}
                                                >
                                                    <i className="uil-arrow-up-right"></i> Upload Data
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                            }
                        </>
                }
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo={this.state.uploadText}

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
            </>
        )
    }

}

export default UploadComponent