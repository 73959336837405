import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, useState, useRef } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"

import TableActiveComponentA from './Components/TableActiveComponentA'
import TableActiveComponentB from './Components/TableActiveComponentB'
import TableActiveComponentC from './Components/TableActiveComponentC'


const IndexView = (props) => {

    const active_tab = localStorage.getItem('active-tab-umum')

    const [activeTab, setactiveTab] = useState(active_tab ? active_tab : "1")
    const activeRef = useRef();
    const inactiveRef = useRef();

    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()
    const reloadActiveRef = () => inactiveRef.current.setTokenAPI()

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
        localStorage.setItem("active-tab-umum", tab)
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                    {
                                        (accessRole('create')) ?
                                            <Link to="/master/pajak-ptkp2024.html?create">
                                                <Button color="primary"><i className="uil-folder-medical"></i> Tambah Data Baru</Button>
                                            </Link>
                                        : null
                                    }
                                </div>
                                <CardTitle>Mapping PTKP 2024</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Informasi data referensi PTKP 2024.
                                </CardSubtitle>
                                <hr />
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Grup A</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Grup B</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "3",
                                            })}
                                            onClick={() => {
                                                toggle("3")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Grup C</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <TableActiveComponentA />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <TableActiveComponentB />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <TableActiveComponentC />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView