import React, { useState, Fragment, Component } from 'react'
import classnames from "classnames"
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"
import { Link } from "react-router-dom"
import { withRouter } from "react-router";
import { Preview } from '../../../../../../helpers/ui/FileInput'

import apiResponse from '../../../../../../services/apiResponse'

import moment from 'moment'

import IndexViewBloc from '../Blocs/IndexViewBloc'
import MonitoringSourceComponent from "./MonitoringSourceComponent"

class MinitoringDetailComponent extends Component {

    indexViewBloc = new IndexViewBloc();

    constructor(props) {
        super(props)
        this.state = {
            loadingDetail: true,
            listDetail: [],
            paramDetail: null,
            activeTab: "0"
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    componentDidMount() {
        this.indexViewBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            activeTab: "1",
                            loadingDetail: false,
                            listDetail: response.list
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.setState({
            paramDetail: this.props.paramDetail
        }, function () {
            this.indexViewBloc.fetchDetail(this.props.paramDetail)
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.detailChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Nav tabs>
                                {
                                    this.state.loadingDetail ?
                                        <>
                                            {/* <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    ...
                                                </span>
                                            </NavLink>
                                        </NavItem> */}
                                            <span className="p-2 d-none d-sm-block">
                                                <Shimmer className="shimmer"><div style={{ width: 140, height: 18 }}></div></Shimmer>
                                            </span>

                                        </>
                                        :
                                        <>
                                            {
                                                Object.entries(this.state.listDetail).map((item, key) => {
                                                    return <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: this.state.activeTab === (key + 1).toString(),
                                                            })}
                                                            onClick={() => {
                                                                this.toggle((key + 1).toString())
                                                            }}
                                                        >
                                                            <span className="d-none d-sm-block">{item[1].official_travel_number}</span>
                                                        </NavLink>
                                                    </NavItem>
                                                })
                                            }
                                        </>
                                }
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>

                            {
                                this.state.loadingDetail ?
                                    <div className="p-3 text-muted">
                                        <MonitoringSourceComponent />
                                    </div>
                                    :
                                    <>
                                        <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                                            {
                                                Object.entries(this.state.listDetail).map((item, key) => {
                                                    return <TabPane tabId={(key + 1).toString()}>
                                                        <MonitoringSourceComponent detail={item[1]} />
                                                    </TabPane>
                                                })
                                            }
                                        </TabContent>
                                    </>
                            }

                            {/* <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                                {
                                    this.state.loadingDetail ?
                                        <TabPane tabId="0">
                                            <MonitoringSourceComponent />
                                        </TabPane>
                                        :
                                        <>
                                            {
                                                Object.entries(this.state.listDetail).map((item, key) => {
                                                    return <TabPane tabId={(key + 1).toString()}>
                                                        <MonitoringSourceComponent detail={item[1]} />
                                                    </TabPane>
                                                })
                                            }
                                        </>
                                }
                            </TabContent> */}
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

//export default MinitoringDetailComponent
export default withRouter(MinitoringDetailComponent);
