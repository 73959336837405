import {
    apiRequest,
    masterPtspTunkinMinistry,
    masterPtspTunkinCurrent
} from "../../../../../../services/adapters/master"

class IndexRepository {

    fetchMinistry = async (query, cancelToken) => {
        return await apiRequest("get", masterPtspTunkinMinistry, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchCurrent = async (query, cancelToken) => {
        return await apiRequest("get", masterPtspTunkinCurrent, {
            cancelToken: cancelToken,
            params: query
        }, true)       
    }

}

export default IndexRepository