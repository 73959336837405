import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import IndexRepository from '../Repositories/IndexRepository'

class IndexBloc 
{
    repository = new IndexRepository()
    dashboardReportChannel = new Subject();
    dashboardMonitoringOPChannel = new Subject();
    dashboardPerhitunganOPChannel = new Subject();
    dashboardLayananOPChannel = new Subject();

    constructor() 
    {
        this.dashboardReportChannel.next({status: apiResponse.INITIAL})
        this.dashboardMonitoringOPChannel.next({status: apiResponse.INITIAL})
        this.dashboardPerhitunganOPChannel.next({status: apiResponse.INITIAL})
        this.dashboardLayananOPChannel.next({status: apiResponse.INITIAL})
    }

    fetchDashboardReport = async (query, cancelToken) => {
        this.dashboardReportChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardReport(query, cancelToken).then((result) => {
                this.dashboardReportChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardReportChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
    }

    fetchGetMonitoringOP = async () => {
        this.dashboardMonitoringOPChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetMonitoringOP().then((result) => {
                this.dashboardMonitoringOPChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.dashboardMonitoringOPChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'MonitoringOP Unsubscribed') 
        }
    }

    fetchGetPerhitunganOP = async () => {
        this.dashboardPerhitunganOPChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetPerhitunganOP().then((result) => {
                this.dashboardPerhitunganOPChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.dashboardPerhitunganOPChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'PerhitunganOP Unsubscribed') 
        }
    }

    fetchGetLayananOP = async () => {
        this.dashboardLayananOPChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetLayananOP().then((result) => {
                this.dashboardLayananOPChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.dashboardLayananOPChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'LayananOP Unsubscribed') 
        }
    }

}

export default IndexBloc