import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import VerifyRepository from '../Repositories/VerifyRepository'

class VerifyBloc {

    verifyChannel = new Subject()
    compareChannel = new Subject()
    resetRequestChannel = new Subject()
    repository = new VerifyRepository()

    constructor() {
        this.verifyChannel.next({ status: apiResponse.INITIAL })
        this.compareChannel.next({ status: apiResponse.INITIAL })
        this.resetRequestChannel.next({ status: apiResponse.INITIAL })
        
    }

    fetchVerify = async (query) => {
        this.verifyChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchVerify(query).then((result) => {
                this.verifyChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifyChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    
    fetchCompare = async (query) => {
        this.compareChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCompare(query).then((result) => {
                this.compareChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.compareChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchResetRequest = async (query) => {
        this.resetRequestChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchResetRequest(query).then((result) => {
                this.resetRequestChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.resetRequestChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

}

export default VerifyBloc