import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component } from "react"

import MKIDatatable from "../../../../../../modules/mki-datatable/lib"

import { cancelToken } from "../../../../../../services/adapters/base"

import IndexViewBloc from "../Blocs/IndexViewBloc"
import apiResponse from "../../../../../../services/apiResponse"

class ManualEmployeeListComponent extends Component {
    indexViewBloc = new IndexViewBloc()

    source
    historyTable = "historyManualEmployeeListComponent" + this.props.uuid
    defaultOrder = "gpp_employee_name"
    defaultSize = 10
    defaultSort = "asc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            showManualHistory: false,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            uuid: this.props.uuid,
        }
        await this.indexViewBloc.fetchRowTableEmployee(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {}

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.rowDataEmployeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.rowDataEmployeeChannel.unsubscribe()
    }

    columns = [
        {
            key: "nip",
            text: "NIP",
            width: "260",
            align: "center",
            sortable: true,
        },
        {
            key: "gpp_employee_name",
            text: "Nama Pegawai",
            align: "left",
            sortable: true,
        },
        {
            key: "kdjns",
            text: "Kode Jenis",
            width: "180",
            align: "center",
            sortable: true,
        },
        {
            key: "gapok",
            text: "Gaji Bersih",
            width: "180",
            sortable: true,
            align: "center",
            cell: (record) => {
                return (
                    <>
                        <div className="text-end">
                            {(
                                (parseInt(record.gapok) ?? 0) +
                                (parseInt(record.tistri) ?? 0) +
                                (parseInt(record.tanak) ?? 0) +
                                (parseInt(record.tumum) ?? 0) +
                                (parseInt(record.tstruktur) ?? 0) +
                                (parseInt(record.tfungsi) ?? 0) +
                                (parseInt(record.bulat) ?? 0) +
                                (parseInt(record.tberas) ?? 0) +
                                (parseInt(record.tpajak) ?? 0) +
                                (parseInt(record.tpencil) ?? 0) +
                                (parseInt(record.tlain) ?? 0) -
                                ((parseInt(record.iwp) ?? 0) +
                                    (parseInt(record.sewarmh) ?? 0) +
                                    (parseInt(record.pberas) ?? 0) +
                                    (parseInt(record.potlain) ?? 0) +
                                    (parseInt(record.taperum) ?? 0) +
                                    (parseInt(record.bpjs) ?? 0) +
                                    (parseInt(record.bpjs2) ?? 0) +
                                    (parseInt(record.pph) ?? 0))
                            )
                                .toString()
                                .replace(".00", "")
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </div>
                    </>
                )
            },
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={700}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
            </>
        )
    }
}

export default ManualEmployeeListComponent
