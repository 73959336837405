import {
    apiRequest,
    ptspRegulerApproveSubmit,
    ptspRegulerRejectSubmit,
    ptspRegulerResultEmployee,
    ptspRegulerCheckStatus,
    ptspRegulerResultEmployeeDetail,
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {
    fetchApprove = async (query) => {
        return await apiRequest(
            "post",
            ptspRegulerApproveSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchReject = async (query) => {
        return await apiRequest(
            "post",
            ptspRegulerRejectSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            ptspRegulerResultEmployee,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerResultEmployeeDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerCheckStatus,
            {
                params: query,
            },
            true
        )
    }
}

export default ApproveViewRepository
