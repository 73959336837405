import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect";
import Select from "react-select"
import classnames from "classnames"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
import CustomSelect from '../../../../helpers/ui/CustomSelect'

import IndexViewBloc from './Blocs/IndexViewBloc'
import apiResponse from '../../../../services/apiResponse'

import SKPIndexListComponent from './Components/SKPIndexListComponent'
import PayrollIndexListComponent from './Components/PayrollIndexListComponent'

class IndexView extends Component {

    indexViewBloc = new IndexViewBloc()
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear()-1 : this.thisDate.getFullYear()
    thisMonths = this.thisDate.getMonth() === 0 ? 12 : this.thisDate.getMonth() + 1
    thisMonth = "00".substring(0, "00".length - (this.thisMonths).toString().length) + (this.thisMonths).toString()
    monthName = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ]

    constructor(props) {
        super(props)
        const thisYear = new Date().getFullYear();
        const tahunOptions = Array.from({ length: 7 }, (_, index) => {
            const year = thisYear - 5 + index;
            return {
                value: year.toString(),
                label: year.toString()
            };
        });
        this.state = {
            showTunkinHistory: false,
            loadingCurrent: true,
            selectedYear: {label: this.thisYear.toString(), value: this.thisYear},
            selectedMonth: { value: this.thisMonth, label: this.monthName[parseInt(this.thisMonth * 1) - 1] },
            optionMonth: [
                { value: '01', label: this.monthName[0] },
                { value: '02', label: this.monthName[1] },
                { value: '03', label: this.monthName[2] },
                { value: '04', label: this.monthName[3] },
                { value: '05', label: this.monthName[4] },
                { value: '06', label: this.monthName[5] },
                { value: '07', label: this.monthName[6] },
                { value: '08', label: this.monthName[7] },
                { value: '09', label: this.monthName[8] },
                { value: '10', label: this.monthName[9] },
                { value: '11', label: this.monthName[10] },
                { value: '12', label: this.monthName[11] },
            ],
            activeTab: "1",
            tahunOptions: tahunOptions,
            tahunSelected: this.thisYear.toString(),
            bulanSelected: this.thisMonth.toString()
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab : tab
            })
        }
    }

    loadCurrent = async () => {
        await this.indexViewBloc.fetchYears()
    }

    componentDidMount() {
        this.indexViewBloc.yearsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        currentRegulation: response,
                        loadingCurrent: false,
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.loadCurrent()
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState({
            selectedYear: itemYear.length > 0 ? itemYear[0] : {label: this.thisYear.toString(), value: this.thisYear},
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear
                }
            ]
        })
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState({
            selectedYear: selectedYear,
            loadingCurrent: true
        }, () => {
            this.setState({
                loadingCurrent: false
            })
        });
    }

    componentWillUnmount() {
        this.indexViewBloc.yearsChannel.unsubscribe()
    }

    handleShowClick = () => {
        this.setState({
            loadingCurrent: true,
        }, () => {
            const selectedYear = this.state.tahunSelected;
            const selectedMonth = this.state.bulanSelected;

            this.setState({
                selectedYear: { label: selectedYear, value: selectedYear },
                selectedMonth: { 
                    value: selectedMonth, 
                    label: this.monthName[parseInt(selectedMonth) - 1] 
                },
            }, () => {
                this.setState({ loadingCurrent: false });
            });
        });
    }

    handlerOnSubmit = (event, values) => {
        const { tahunSelected, bulanSelected } = this.state;
        console.log({ tahun: tahunSelected, bulan: bulanSelected });
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="float-end">
                                    {
                                        (accessRole('create')) ?
                                            <>
                                                { this.state.activeTab === "1" ?
                                                <Link to={`/payroll/sigap.html?skpcreate`}>
                                                    <Button color="primary"><i className="uil-folder-medical"></i> Tambah Pengajuan Sigap</Button>
                                                </Link>
                                                :
                                                <Link to={`/payroll/sigap.html?payrollcreate`}>
                                                    <Button color="primary"><i className="uil-folder-medical"></i> Tambah Pengajuan Slip Gaji</Button>
                                                </Link> }
                                            </>
                                        : null
                                    }
                                    </div>
                                    <CardTitle>Surat Informasi Gaji Pegawai (SIGAP)</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Dokumen resmi informasi penghasilan pegawai yang memuat Gaji Induk, Tunjangan Kinerja, Uang Makan dan Tunjangan Lainnyaaa.
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                        <>
                                            <AvForm
                                                className="needs-validation p-2 mt-2 mb-5"
                                                onValidSubmit={this.handlerOnSubmit} // Ensure this is correctly referenced
                                            >
                                                <Row className="mb-3">
                                                    <Col md="2">
                                                        <CustomSelect
                                                            name="tahun"
                                                            errorMessage="Pilih Tahun"
                                                            options={this.state.tahunOptions}
                                                            defaultValue={this.state.tahunSelected}
                                                            placeholder="Pilih Tahun"
                                                            validate={{ required: { value: true } }}
                                                            onChange={(v) => {
                                                                this.setState({
                                                                    tahunSelected: v.value,
                                                                })
                                                            }}
                                                        />
                                                    </Col>
                            
                                                    <Col md="2">
                                                        <CustomSelect
                                                            name="bulan"
                                                            errorMessage="Pilih Bulan"
                                                            options={this.state.optionMonth}
                                                            defaultValue={this.state.bulanSelected}
                                                            placeholder="Pilih Bulan"
                                                            validate={{ required: { value: true } }}
                                                            onChange={(v) => {
                                                                this.setState({
                                                                    bulanSelected: v.value,
                                                                })
                                                            }}
                                                        />
                                                    </Col>
                            
                                                    <Col md="3" className="d-flex align-items-end">
                                                        <Button color="primary" type="submit" onClick={this.handleShowClick}>
                                                            <i className="uil-search-alt"></i> Tampilkan
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </AvForm>

                                        </>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    {/* <Row>
                                        <Col md="12">
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: this.state.activeTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            this.toggle("1")
                                                        }}
                                                    >
                                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                        <span className="d-none d-sm-block">Sigap</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: this.state.activeTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            this.toggle("2")
                                                        }}
                                                    >
                                                        <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                                        <span className="d-none d-sm-block">Slip Gaji</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col sm={12}>
                                            <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                                                <TabPane tabId="1">
                                                    {
                                                        this.state.loadingCurrent?
                                                            <>
                                                                <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '35%', height: 36}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '35%', height: 36}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '20%', height: 36}}></div></Shimmer>

                                                                <Shimmer><div className="shimmer" style={{width: '10%', height: 22}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '35%', height: 22}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '35%', height: 22}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '20%', height: 22}}></div></Shimmer>
                                                            </>
                                                        :
                                                            <>
                                                                <SKPIndexListComponent year={ this.state.selectedYear.value } month={this.state.selectedMonth.value}/>
                                                            </>
                                                    }
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    {
                                                        this.state.loadingCurrent?
                                                            <>
                                                                <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '35%', height: 36}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '35%', height: 36}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '20%', height: 36}}></div></Shimmer>

                                                                <Shimmer><div className="shimmer" style={{width: '10%', height: 22}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '35%', height: 22}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '35%', height: 22}}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{width: '20%', height: 22}}></div></Shimmer>
                                                            </>
                                                        :
                                                            <>
                                                                <PayrollIndexListComponent year={ this.state.selectedYear.value } month={this.state.selectedMonth.value}/>
                                                            </>
                                                    }
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView