import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import VerifyRepository from '../Repositories/VerifyRepository'

class VerifyBloc {

    verifyVerifyChannel = new Subject()
    verifyRejectChannel = new Subject()
    verifyDetailChannel = new Subject()
    verifyParticipantChannel = new Subject()
    verifySourceChannel = new Subject()
    repository = new VerifyRepository()

    constructor() {
        this.verifyVerifyChannel.next({ status: apiResponse.INITIAL })
        this.verifyRejectChannel.next({ status: apiResponse.INITIAL })
        this.verifyDetailChannel.next({ status: apiResponse.INITIAL })
        this.verifyParticipantChannel.next({ status: apiResponse.INITIAL })
        this.verifySourceChannel.next({ status: apiResponse.INITIAL })
    }

    fetchVerify = async (query) => {
        this.verifyVerifyChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchVerify(query).then((result) => {
                this.verifyVerifyChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifyVerifyChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }
    fetchReject = async (query) => {
        this.verifyRejectChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchReject(query).then((result) => {
                this.verifyRejectChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifyRejectChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchDetail = async (query) => {
        this.verifyDetailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.verifyDetailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifyDetailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchParticipant = async (query) => {
        this.verifyParticipantChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchParticipant(query).then((result) => {
                this.verifyParticipantChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifyParticipantChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchSource = async (query) => {
        this.verifySourceChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchSource(query).then((result) => {
                this.verifySourceChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.verifySourceChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

}

export default VerifyBloc