import React, { Component } from "react"
import Shimmer from "react-shimmer-effect"
import { Row, Col, Alert } from "reactstrap"
import { Link } from "react-router-dom"
import MinioClient from "../../../../../../services/minioClient"

import IndexViewBloc from "../Blocs/IndexViewBloc"
import apiResponse from "../../../../../../services/apiResponse"

import LoadingComponent from "./LoadingComponent"

import moment from "moment"

class EmployeeDetailComponent extends Component {
    indexViewBloc = new IndexViewBloc()
    minioClient = new MinioClient()

    constructor(props) {
        super(props)
        this.state = {
            detailData: {},
            loadingDetail: true,
            changeData: [],
            loadingChange: true,
        }
    }

    convertUrl = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === totalRow - 1) {
                    this.setState({
                        changeData: newResponse,
                        loadingChange: false,
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false,
            })
        }
    }

    componentDidMount() {
        this.indexViewBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingDetail: true,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingDetail: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        detailData: response,
                        loadingDetail: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.changeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrl(response)
                    /* this.setState({
                        changeData: response,
                        loadingChange: false
                    }) */
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.indexViewBloc.fetchDetail({ uuid: this.props.uuid, year: this.props.year, month: this.props.month })
        this.indexViewBloc.fetchChange({ uuid: this.props.uuid, year: this.props.year, month: this.props.month })
    }

    componentWillUnmount() {
        this.indexViewBloc.detailChannel.unsubscribe()
        this.indexViewBloc.changeChannel.unsubscribe()
    }

    render() {
        return (
            <>
                <Col>
                    <Row className="mb-12">
                        <Col md="12">
                            {this.state.loadingChange === true ? (
                                <LoadingComponent message="Memeriksa informasi perubahan..." />
                            ) : (
                                <>
                                    {this.state.changeData.length > 0 ? (
                                        this.state.changeData.map((item, key) => (
                                            <Alert color="success" key={key}>
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: "middle" }}>
                                                            <i className="uil uil-check"></i>
                                                        </td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            {item.financial_recap_type_name}{" "}
                                                            {item.financial_recap_reason_type_name !== null
                                                                ? "(" + item.financial_recap_reason_type_name + ")"
                                                                : ""}
                                                            {item.financial_recap_start_date !== null &&
                                                            item.financial_recap_start_date !== "" ? (
                                                                <div>
                                                                    <small>
                                                                        {moment(item.financial_recap_start_date).format(
                                                                            "LL"
                                                                        )}{" "}
                                                                        s/d{" "}
                                                                        {item.financial_recap_end_date !== null ? (
                                                                            <>
                                                                                {moment(
                                                                                    item.financial_recap_end_date
                                                                                ).format("LL")}
                                                                            </>
                                                                        ) : (
                                                                            <>~</>
                                                                        )}
                                                                    </small>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </td>
                                                        <td className="text-end" style={{ verticalAlign: "middle" }}>
                                                            {item.documents_path !== null ? (
                                                                <a
                                                                    href={item.documents_path}
                                                                    rel="noreferrer"
                                                                    target="_blank"
                                                                >
                                                                    Download
                                                                </a>
                                                            ) : (
                                                                <small>Belum ada dokumen</small>
                                                            )}

                                                            {item.financial_recap_source_url !== null ? (
                                                                <>
                                                                    {" "}
                                                                    |{" "}
                                                                    <a
                                                                        href={
                                                                            "https://simpeg.bkpm.go.id" +
                                                                            item.financial_recap_source_url
                                                                        }
                                                                        rel="noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        Lihat
                                                                    </a>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </Alert>
                                        ))
                                    ) : (
                                        <Alert color="secondary">
                                            <table width="100%">
                                                <tr>
                                                    <td width="30px" style={{ verticalAlign: "top" }}>
                                                        <i className="uil uil-check"></i>
                                                    </td>
                                                    <td style={{ verticalAlign: "top" }}>Tidak ada perubahan</td>
                                                </tr>
                                            </table>
                                        </Alert>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                        <label className="col-md-3">Nama</label>
                        <Col md="9">
                            {!this.state.loadingDetail ? (
                                <>
                                    {this.state.detailData.employee_fullname !== null
                                        ? this.state.detailData.employee_fullname
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 240, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">NIP</label>
                        <Col md="9">
                            {!this.state.loadingDetail ? (
                                <>
                                    {this.state.detailData.employee_nip !== null
                                        ? this.state.detailData.employee_nip
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 140, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Jabatan</label>
                        <Col md="9">
                            {!this.state.loadingDetail ? (
                                <>
                                    {this.state.detailData.employee_position_name !== null
                                        ? this.state.detailData.employee_position_name
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 180, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                        <label className="col-md-3">Nomor Surat Persetujuan Cuti Besar</label>
                        <Col md="9">
                            {
                                // !this.state.loadingDetail ?
                                //     <>{this.state.detailData.employee_identity_number !== null ? this.state.detailData.employee_identity_number : '-'}</>
                                //     : <Shimmer><div className="shimmer" style={{ width: 140, height: 15 }}></div></Shimmer>
                                !this.state.loadingDetail ? (
                                    "-"
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: 240, height: 15 }}></div>
                                    </Shimmer>
                                )
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Tanggal Pengajuan Cuti Besar</label>
                        <Col md="9">
                            {
                                // !this.state.loadingDetail ?
                                //     <>{this.state.detailData.employee_email_internal !== null ? this.state.detailData.employee_email_internal : '-'}</>
                                //     : <Shimmer><div className="shimmer" style={{ width: 180, height: 15 }}></div></Shimmer>
                                !this.state.loadingDetail ? (
                                    "-"
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: 240, height: 15 }}></div>
                                    </Shimmer>
                                )
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Tanggal Mulai Cuti Besar</label>
                        <Col md="9">
                            {
                                // !this.state.loadingDetail ?
                                //     <>{this.state.detailData.employee_phone_number !== null ? this.state.detailData.employee_phone_number : '-'}</>
                                //     : <Shimmer><div className="shimmer" style={{ width: 120, height: 15 }}></div></Shimmer>
                                !this.state.loadingDetail ? (
                                    "-"
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: 240, height: 15 }}></div>
                                    </Shimmer>
                                )
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Tanggal Selesai Cuti Besar</label>
                        <Col md="9">
                            {
                                // !this.state.loadingDetail ?
                                //     <>{this.state.detailData.employee_marital_status_name !== null ? this.state.detailData.employee_marital_status_name : '-'}</>
                                //     : <Shimmer><div className="shimmer" style={{ width: 100, height: 15 }}></div></Shimmer>
                                !this.state.loadingDetail ? (
                                    "-"
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: 240, height: 15 }}></div>
                                    </Shimmer>
                                )
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Histori Approval Cuti Besar</label>
                        <Col md="9">
                            {
                                // !this.state.loadingDetail ?
                                //     <>{this.state.detailData.employee_child !== null ? this.state.detailData.employee_child : '-'}</>
                                //     : <Shimmer><div className="shimmer" style={{ width: 20, height: 15 }}></div></Shimmer>
                                !this.state.loadingDetail ? (
                                    "-"
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: 240, height: 15 }}></div>
                                    </Shimmer>
                                )
                            }
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}
export default EmployeeDetailComponent
