import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { Component } from "react"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../services/adapters/base"

import IndexViewBloc from '../Blocs/IndexViewBloc'
import apiResponse from '../../../../../services/apiResponse'

class TunjabEmployeeListComponent extends Component {

    indexViewBloc = new IndexViewBloc();

    source
    historyTable = 'historyTunjabEmployeeListComponent'
    defaultOrder = 'tunjab_employee_employee_name'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            showTunjabHistory: false,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            uuid: this.props.uuid
        }
        await this.indexViewBloc.fetchRowTableEmployee(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => { }

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.rowDataEmployeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.rowDataEmployeeChannel.unsubscribe()
    }

    columns = [
        {
            key: "tunjab_employee_employee_nip",
            text: "NIP",
            width: '260',
            align: 'center',
            sortable: true
        },
        {
            key: "tunjab_employee_employee_name",
            text: "Nama Pegawai",
            align: 'left',
            sortable: true
        },
        {
            key: "tunjab_employee_value",
            text: "Nilai",
            width: '180',
            sortable: true,
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            {record.tunjab_employee_value.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </div>
                    </>
                )
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={700}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />

            </>
        )
    }
}

export default TunjabEmployeeListComponent