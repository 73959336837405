import React, { useState, Component, Fragment } from "react"
import SimpleBar from "simplebar-react"
import Shimmer from "react-shimmer-effect"
import moment from 'moment'
import { Row, Col, Table } from 'reactstrap'

import CreateBloc from '../Blocs/CreateBloc'
import apiResponse from '../../../../../services/apiResponse'

//import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import MinioClient from '../../../../../services/minioClient'

class DetailEmployeeComponent extends Component {

    createBloc = new CreateBloc();
    minioClient = new MinioClient()

    constructor(props) {
        super(props)
        this.state = {
            loadingData: true,
        }
    }

    componentDidMount() {
        this.createBloc.employeeDetailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingData: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        detailData: response,
                    }, () => {
                        this.setState({
                            loadingData: false
                        })
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchEmployeeDetail({ uuid: this.props.uuid })
    }

    componentWillUnmount() {
        this.createBloc.employeeDetailChannel.unsubscribe()
    }

    downloadST = (path) => {
        this.setState({
            minioLoading: true
        })
        this.minioClient.get(path).then((url) => {
            this.setState({
                minioLoading: false,
                minioSuccess: true
            })
            setTimeout(() => {
                this.setState({
                    minioSuccess: false,
                })
            }, 1000);
            window.open(url, '_blank');
        })
    }

    render() {
        return (
            <>
                <Col>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            NIP
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>{this.state.detailData.current.overtime_employee_nip !== "" ? this.state.detailData.current.overtime_employee_nip : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 140, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nama
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>{this.state.detailData.current.overtime_employee_name !== "" ? this.state.detailData.current.overtime_employee_name : '-'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 200, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Unit Kerja
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>
                                        {
                                            this.state.detailData.current.overtime_employee_work_unit_name !== "" ?
                                                <>
                                                    {
                                                        this.state.detailData.current.overtime_employee_work_unit_uuid !== this.state.detailData.last.overtime_employee_work_unit_uuid && this.state.detailData.last.overtime_employee_work_unit_uuid !== null ?
                                                            <>
                                                                <label className="bg-soft-success badge rounded-pill font-size-12">{this.state.detailData.current.overtime_employee_work_unit_name}</label>
                                                                <br /><label className="bg-soft-secondary badge rounded-pill font-size-12">{this.state.detailData.last.overtime_employee_work_unit_name}</label>
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.detailData.current.overtime_employee_work_unit_name}
                                                            </>

                                                    }
                                                </>
                                                : '-'
                                        }
                                    </>
                                    : <Shimmer><div className="shimmer" style={{ width: '80%', height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Jabatan
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>
                                        {
                                            this.state.detailData.current.overtime_employee_position_name !== "" ?
                                                <>
                                                    {
                                                        this.state.detailData.current.overtime_employee_position_uuid !== this.state.detailData.last.overtime_employee_position_uuid && this.state.detailData.last.overtime_employee_position_uuid !== null ?
                                                            <>
                                                                <label className="bg-soft-success badge rounded-pill font-size-12">{this.state.detailData.current.overtime_employee_position_name}</label>
                                                                <br /><label className="bg-soft-secondary badge rounded-pill font-size-12">{this.state.detailData.last.overtime_employee_position_name}</label>
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.detailData.current.overtime_employee_position_name}
                                                            </>
                                                    }
                                                </>
                                                : '-'
                                        }
                                    </>
                                    : <Shimmer><div className="shimmer" style={{ width: '50%', height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Golongan Ruang
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>
                                        {
                                            this.state.detailData.current.overtime_employee_rank_name !== "" ?
                                                <>
                                                    {
                                                        this.state.detailData.current.overtime_employee_rank_uuid !== this.state.detailData.last.overtime_employee_rank_uuid && this.state.detailData.last.overtime_employee_rank_uuid !== null ?
                                                            <>
                                                                <label className="bg-soft-success badge rounded-pill font-size-12">{this.state.detailData.current.overtime_employee_rank_name}</label>
                                                                <br /><label className="bg-soft-secondary badge rounded-pill font-size-12">{this.state.detailData.last.overtime_employee_rank_name}</label>
                                                            </>
                                                            :
                                                            <>
                                                                {this.state.detailData.current.overtime_employee_rank_name}
                                                            </>
                                                    }
                                                </>
                                                : '-'
                                        }
                                    </>
                                    : <Shimmer><div className="shimmer" style={{ width: 200, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Status Pernikahan
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>{this.state.detailData.current.overtime_employee_family_wife > 0 ? 'Menikah' : 'Tidak Menikah'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 200, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Jumlah Tanggungan
                        </label>
                        <Col md="9">
                            {
                                !this.state.loadingData ?
                                    <>{this.state.detailData.current.overtime_employee_family_child > 0 ? this.state.detailData.current.overtime_employee_family_child + ' anak' : 'Tidak Ada'}</>
                                    : <Shimmer><div className="shimmer" style={{ width: 100, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-0">
                        <label className="col-md-3">
                            Data Lembur
                        </label>
                        <Col md="9">
                            <small><i>
                                <div className="bd-highlight float-end">
                                    <div>
                                        <div className="bg-success rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Hari Libur/Akhir Pekan
                                    </div>
                                </div>
                                <div className="bd-highlight float-end"><div>&nbsp;&nbsp;&nbsp;&nbsp;</div></div>
                                <div className="bd-highlight float-end">
                                    <div>
                                        <div className="bg-danger rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Hari Kerja
                                    </div>
                                </div>
                            </i></small>
                        </Col>
                    </Row>
                    <Row className="mb-0">
                        <Col md="12">
                            <Table size="sm"
                                className="table table-striped table-bordered table-hover"
                            >
                                <thead>
                                    <tr>
                                        <th width="170px" className="text-center" rowspan="2" style={{ verticalAlign: 'middle' }}>Tanggal</th>
                                        <th width="*" className="text-center" rowspan="2" style={{ verticalAlign: 'middle' }}>Surat Tugas</th>
                                        <th className="text-center" colspan="2">Lembur</th>
                                        <th className="text-center" colspan="2">Absen</th>
                                        <th width="30px" rowspan="2"></th>
                                    </tr>
                                    <tr>
                                        <th width="100px" className="text-center">Mulai</th>
                                        <th width="100px" className="text-center">Selesai</th>
                                        <th width="100px" className="text-center">Masuk</th>
                                        <th width="100px" className="text-center">Pulang</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.loadingData ?
                                            <>
                                                {
                                                    Object.entries(this.state.detailData.overtimes).map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className="text-center">
                                                                    {moment(item[1].overtime_day_date).format('LL')}
                                                                </td>
                                                                <td className="text-center">
                                                                    <button
                                                                        className="btn btn-secondary btn-sm"
                                                                        onClick={() => {
                                                                            this.downloadST(item[1].overtime_day_document_path)
                                                                        }}
                                                                        style={{
                                                                            padding: '2px 5px'
                                                                        }}
                                                                    >
                                                                        {item[1].overtime_day_st_number}
                                                                    </button>
                                                                </td>
                                                                <td className="text-center">{item[1].overtime_day_hour_start}</td>
                                                                <td className="text-center">{item[1].overtime_day_hour_end}</td>
                                                                <td className="text-center">{item[1].overtime_day_presence_start === null ? '-' : item[1].overtime_day_presence_start}</td>
                                                                <td className="text-center">{item[1].overtime_day_presence_end === null ? '-' : item[1].overtime_day_presence_end}</td>
                                                                <td className="text-center">{item[1].overtime_day_type === 1 ? <div className="bg-success rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> : <div className="bg-danger rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div>}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                            : <tr>
                                                <td colspan="7"><Shimmer><div className="shimmer" style={{ marginTop: 5, width: '100%', height: 15 }}></div></Shimmer></td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}

export default DetailEmployeeComponent