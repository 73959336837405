import { accessRole } from "../../../helpers/access_role"
import ErrorAccess from '../../../components/Error/Content/Access'

import IndexView from "./Index/View"
import EmployeeView from "./Employee/View"

const RouteView = (props) => {

  const queryParams = new URLSearchParams(props.location.search);
  const _create = queryParams.get('create')
  const _detail = queryParams.get('detail')
  const _update = queryParams.get('update')
  const _delete = queryParams.get('detail')
  const _approve = queryParams.get('approve')
  const _print = queryParams.get('print')

  if(accessRole('id') === '1') {
    return accessRole('read') ? <EmployeeView /> : <ErrorAccess />
  } else {
    return accessRole('read') ? <IndexView /> : <ErrorAccess />
  }
}
export default RouteView