import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Link, Redirect } from "react-router-dom"
import moment from 'moment'
import { 
    Tooltip, 
    Modal,
    Row, Col,
    Label,
    Button 
} from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'

import { AvField, AvForm } from "availity-reactstrap-validation"
import CustomSelect from '../../../../../../helpers/ui/CustomSelect'
import { cancelToken } from "../../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/IndexBloc'
import SimpegIndexViewBloc from '../../../Kepegawaian/Index/Blocs/IndexViewBloc'
import EmployeeDetailComponentCB from '../../../Kepegawaian/Index/Components/EmployeeDetailComponentCB'
import apiResponse from '../../../../../../services/apiResponse'

import ReactTimeAgo from 'react-time-ago'

class DataTableCutiBesarComponent extends Component {
    
    dataTableBloc = new DataTableBloc();
    simpegIndexViewBloc = new SimpegIndexViewBloc();

    source
    historyTable = 'historyMonitoringDataTableCutiBesar'
    defaultOrder = 'status_description'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            year: 1900,
            month: "01",
            financialUuid: "",
            showDetail: false,
            tooltip: false,
            gotoLink: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: false,
            jenisOptions: [
                {
                    "value":"1",
                    "label":"Akan Selesai"
                },
                {
                    "value":"5",
                    "label":"Selesai"
                },
                {
                    "value":"2",
                    "label":"Belum Mulai"
                },
                {
                    "value":"3",
                    "label":"Sedang Berjalan"
                }
            ],
            rentangOptions: [
                {
                    "value":"7",
                    "label":"7 Hari Terakhir"
                },
                {
                    "value":"30",
                    "label":"30 Hari Terakhir"
                },
                {
                    "value":"0",
                    "label":"Semua"
                }
            ],
            rentangSelected: "7",
            jenisSelected: "all",
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            jenis: this.state.jenisSelected,
            rentang: this.state.rentangSelected,
            status: 1
        }
        await this.dataTableBloc.fetchCutiBesar(query, this.source.token)
    }

    handlerOnSubmit = (e, values) => {
        this.setTokenAPI()
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        // this.setTokenAPI()

        this.dataTableBloc.fetchCutiBesarChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.dataTableBloc.fetchCutiBesarChannel?.unsubscribe()
    }

    columns = [
        {
            key: "employee_nip",
            text: "NIP Pegawai",
            sortable: true
        },
        {
            key: "employee_name",
            text: "Nama Pegawai",
            sortable: true
        },
        {
            key: "financial_recap_start_date",
            text: "Tanggal Mulai Cuti",
            sortable: true,
            cell: record => {
                return <>{record.financial_recap_start_date ? moment(record.financial_recap_start_date).format('LL') : "-"}</>
            }
        },
        {
            key: "financial_recap_end_date",
            text: "Tanggal Selesai Cuti",
            sortable: true,
            cell: record => {
                return <>{record.financial_recap_end_date ? moment(record.financial_recap_end_date).format('LL') : "-"}</>
            }
        },
        {
            key: "financial_recap_days",
            text: "Lama Hari Cuti",
            sortable: true
        },
        {
            key: "financial_recap_date_status_description",
            text: "Status",
            sortable: true,
            width: '140',
            align: 'center',
            cell: record => {
                if (record.financial_recap_date_status_description === "Belum Mulai") {
                    return (
                        <button
                            className="btn btn-soft-secondary btn-sm"
                            style={{ padding: '2px 5px', borderRadius: 16 }}
                        >
                            Belum Mulai
                        </button>
                    );
                } 
                else if (record.financial_recap_date_status_description === "Selesai") {
                    return (
                        <button
                            className="btn btn-soft-success btn-sm"
                            style={{ padding: '2px 5px', borderRadius: 16 }}
                        >
                            Selesai
                        </button>
                    );
                } else if (record.financial_recap_date_status_description === "Akan Selesai") {
                    return (
                        <button
                            className="btn btn-soft-danger btn-sm"
                            onClick={() => {}}
                            style={{ padding: '2px 5px', borderRadius: 16 }}
                        >
                            Akan Selesai
                        </button>
                    );
                } else if (record.financial_recap_date_status_description === "Sedang Berjalan") {
                    return (
                        <button
                            className="btn btn-soft-primary btn-sm"
                            onClick={() => {}}
                            style={{ padding: '2px 5px', borderRadius: 16 }}
                        >
                            Sedang
                        </button>
                    );
                }
            }
        },
        {
            key: "financial_recap_id",
            text: "Aksi",
            sortable: false,
            cell: record => {
                const thisId = record.financial_recap_id
                return (
                    <>
                        <span>
                            <button
                                className="btn btn-success btn-sm"
                                id={"detail-" + thisId}
                                onClick={() => {
                                    let report_year = record.report_year
                                    let report_month = record.report_month
                                    let report_month_params = parseInt(report_month) < 10 ? "0"+report_month : report_month
                                    this.setState({
                                        financialUuid: record.financial_recap_uuid,
                                        year: report_year,
                                        month: report_month_params,
                                        showDetail: true
                                    })
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-search"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["detail" + thisId] : !this.state["detail" + thisId]
                                    })
                                }}
                            >Detail</Tooltip>
                        </span>
                    </>
                )
            }
        }
    ]

    render() {
        return (
            <>
                <AvForm
                    className="needs-validation p-2 mt-2 mb-5"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                >
                    <Row className="mb-3">
                        <Col md="2">
                            <CustomSelect 
                                name="jenis_pengajuan"
                                errorMessage="Pilih Status"
                                options={this.state.jenisOptions} 
                                defaultValue={'all'}
                                placeholder="Pilih Status"
                                validate={{ required: { value: true } }}
                                onChange={(v) => {
                                    this.setState({
                                        jenisSelected: v.value,
                                    })
                                }}
                            />
                        </Col>
                        <Col md="2">
                            <CustomSelect 
                                name="usia_data"
                                errorMessage="Pilih Usia Data"
                                options={this.state.rentangOptions} 
                                defaultValue={this.state.rentangSelected}
                                placeholder="Pilih Usia Data"
                                validate={{ required: { value: true } }}
                                onChange={(v) => {
                                    this.setState({
                                        rentangSelected: v.value,
                                    })
                                }}
                            />
                        </Col>
                        <Col md="3" className="d-flex align-items-end">
                            <Button color="primary" type="submit">
                                <i className="uil-search-alt"></i> Tampilkan
                            </Button>
                        </Col>
                    </Row>
                </AvForm>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                { this.state.gotoLink !== null ?
                    <Redirect to={this.state.gotoLink}/>
                : null }

                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Informasi Pegawai
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <EmployeeDetailComponentCB uuid={this.state.financialUuid}/>
                    </div>
                </Modal>
            </>
        )
    }
}

export default DataTableCutiBesarComponent