import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    Alert,
    Table,
    Input
} from "reactstrap"
import { withRouter } from "react-router";
import apiResponse from '../../../../../../services/apiResponse'
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../../../helpers/ui/FileInput'
import moment from 'moment'

import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import CreateBloc from '../Blocs/CreateBloc'
import { noConflict } from 'lodash';


class OfficialTravelRefundComponent extends Component {

    createBloc = new CreateBloc()

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},

            conflictUuid: null,
            conflictUse: '',
            processUuid: null,
            confirmText: '',
            conflictData: {},

            showProof: false
        }
    }

    conflictSolver = async () => {
        const formData = generateFormData(this.state.dataForm, { field: 'document', name: 'Keuangan/PerjalananDinas/Refund/Refund' })
        await this.createBloc.fetchConflictSolver_2(formData)
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.conflictSolver()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.conflictSolver_2Channel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        this.setState({
                            loading: false,
                            success: true,
                        });
                        this.props.onSolved()
                    } else {
                        this.setState({
                            loading: false,
                            failed: true,
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.setState({
            conflictUuid: this.props.conflictUuid,
            conflictUse: this.props.conflictUse,
            processUuid: this.props.processUuid,
            confirmText: this.props.confirmText,
            conflictData: this.props.conflictData
        })
    }

    componentWillUnmount() {
        this.createBloc.conflictSolver_2Channel.unsubscribe()
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
                conflict: this.state.conflictUuid,
                use: this.state.conflictUse,
                process: this.state.processUuid
            },
            confirm: true
        })
    }

    handleKeyPress = (event) => {
        var last = event.target.value
        if (last.length >= 10) { event.preventDefault(); }
        if (event.which !== 44) {
            if (event.which !== 8 && isNaN(String.fromCharCode(event.which))) {
                event.preventDefault();
            }
        }
    }

    handleKeyUp = (event, id) => {
        const _this = document.querySelector('#' + id)
        var value = event.target.value
        value = value.charAt(0) === '0' ? value.substring(1) : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    }

    render() {
        return (
            <Fragment>
                <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                >
                    <div className="modal-body" style={{ paddingBottom: 0 }}>

                        <Row className="mb-3">
                            <Col sm={12}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td width="120px">Nomor ST</td>
                                        <td width="10px">:</td>
                                        <td width="*">{this.state.conflictData.number}</td>
                                    </tr>
                                    <tr>
                                        <td>Tanggal</td>
                                        <td>:</td>
                                        <td>{this.state.conflictData.date_start} <i className="uil-arrow-right"></i> {this.state.conflictData.date_end}</td>
                                    </tr>
                                    <tr>
                                        <td>Tempat Dinas</td>
                                        <td>:</td>
                                        <td>{this.state.conflictData.place}</td>
                                    </tr>
                                    <tr>
                                        <td>Tujuan</td>
                                        <td>:</td>
                                        <td>{this.state.conflictData.destination}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>:</td>
                                        <td>
                                            {this.state.conflictData.status === null ?
                                                <div className="bd-highlight float-start">
                                                    <div style={{ paddingTop: 2 }}>
                                                        <div className="bg-success rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Belum diproses
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {this.state.conflictData.status === 7 ?
                                                        <div className="bd-highlight float-start">
                                                            <div style={{ paddingTop: 2 }}>
                                                                <div className="bg-danger rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Sudah dibayar
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="bd-highlight float-start">
                                                            <div style={{ paddingTop: 2 }}>
                                                                <div className="bg-warning rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Sedang diproses
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="mb-3">
                            <label className="p-2 col-md-5">
                                Nilai pengembalian <span className="text-danger">*</span>
                            </label>
                            <Col sm={7}>
                                <AvField
                                    name="refund_value"
                                    autocomplete="off"
                                    placeholder="0"
                                    errorMessage="Masukkan Nilai Pengembalian"
                                    type="text"
                                    className="form-control text-end"
                                    id="refundValue"
                                    onKeyPress={(e) => { this.handleKeyPress(e) }}
                                    onKeyUp={(e) => { this.handleKeyUp(e, 'refundValue') }}
                                    validate={{ required: { value: true } }}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="5"></Col>
                            <Col md="7">
                                <div className="form-check form-switch mb-3" style={{ paddingLeft: 14 }}>
                                    <AvCheckboxGroup name="checkboxProof" errorMessage="Agreement harus di centang">
                                        <AvCheckbox label="Bukti pengembalian" value={this.state.showProof ? 1 : 0}
                                            onChange={(val) => {
                                                this.setState({
                                                    showProof: !this.state.showProof
                                                })
                                            }}
                                        />
                                    </AvCheckboxGroup>

                                </div>
                            </Col>
                        </Row>
                        {
                            this.state.showProof ?
                                <Row className="mb-3">
                                    <label
                                        className="col-md-5 col-form-label"
                                    >
                                        File Bukti <span className="text-danger">*</span>
                                    </label>
                                    <Col md="6">
                                        <FileInput
                                            width={12}
                                            name="document"
                                            accept="application/pdf"
                                            validate={{ required: { value: true } }}
                                        />
                                    </Col>
                                </Row>
                                :
                                <></>
                        }
                        <hr />
                        <Row>
                            <Col md="12">
                                <div className="form-check form-switch mb-3">
                                    <AvCheckboxGroup name="refundAgreement" required errorMessage="Agreement harus di centang">
                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                    </AvCheckboxGroup>
                                </div>
                            </Col>
                        </Row>

                    </div>
                    <div className="modal-footer">
                        <button
                            type="submit"
                            /* onClick={() => {
                                this.props.changeValue(this.state.formData)
                            }} */
                            className="btn btn-primary waves-effect waves-light"
                        >
                            Lakukan pengembalian
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                this.props.cancelRefund()
                            }}
                            className="btn btn-danger waves-effect"
                        >
                            Tutup
                        </button>
                    </div>
                </AvForm>
                {/* </Container> */}

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.confirmText}

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
            </Fragment >
        )
    }
}

export default withRouter(OfficialTravelRefundComponent);
