import {
    apiRequest,
    ptspRapelDetailBefore,
    ptspRapelDetailAfter,
    ptspRapelDetailRapel,
    ptspRapelSsp,
} from "../../../../../../services/adapters/output"

class DownloadRepository {
    fetchDownloadDetailBefore = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelDetailBefore,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadDetailAfter = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelDetailAfter,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadDetailRapel = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelDetailRapel,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelSsp,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
