import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import IndexViewRepository from '../Repositories/IndexViewRepository'


class IndexViewBloc {

    rowRefundListProcessChannel = new Subject();
    rowRefundListDoneChannel = new Subject();
    rowRefundListCancelChannel = new Subject();
    rowRefundCancelChannel = new Subject();
    rowRefundUploadChannel = new Subject();
    rowRefundDetailChannel = new Subject();

    repository = new IndexViewRepository()

    constructor() {
        this.rowRefundListProcessChannel.next({ status: apiResponse.INITIAL })
        this.rowRefundListDoneChannel.next({ status: apiResponse.INITIAL })
        this.rowRefundListCancelChannel.next({ status: apiResponse.INITIAL })
        this.rowRefundCancelChannel.next({ status: apiResponse.INITIAL })
        this.rowRefundUploadChannel.next({ status: apiResponse.INITIAL })
        this.rowRefundDetailChannel.next({ status: apiResponse.INITIAL })
    }

    fetchRefundUpload = async (query) => {
        this.rowRefundUploadChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRefundUpload(query).then((result) => {
                this.rowRefundUploadChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowRefundUploadChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchRefundCancel = async (query) => {
        this.rowRefundCancelChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRefundCancel(query).then((result) => {
                this.rowRefundCancelChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowRefundCancelChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchRefundDetail = async (query) => {
        this.rowRefundDetailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRefundDetail(query).then((result) => {
                this.rowRefundDetailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowRefundDetailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchRowRefundListProcess = async (query, cancelToken) => {
        this.rowRefundListProcessChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRowRefundList({ ...query, status: 1 }, cancelToken).then((result) => {
                this.rowRefundListProcessChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowRefundListProcessChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchRowRefundListDone = async (query, cancelToken) => {
        this.rowRefundListDoneChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRowRefundList({ ...query, status: 2 }, cancelToken).then((result) => {
                this.rowRefundListDoneChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowRefundListDoneChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchRowRefundListCancel = async (query, cancelToken) => {
        this.rowRefundListCancelChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRowRefundList({ ...query, status: 0 }, cancelToken).then((result) => {
                this.rowRefundListCancelChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowRefundListCancelChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }
}

export default IndexViewBloc