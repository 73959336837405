import {
    apiRequest,
    dashboardReport,
    dashboardIcon,
    dashboardGpp,
    dashboardSigap,
    dashboardDaftarPekerjaan,
    dashboardPengajuan,
    dashboardFilter,
    informasiGetPengumuman,
    informasiGetPeraturan,
    informasiGetFaq,
    dashboardGetNotifPersetujuan,
    dashboardGetPerhitungan
} from "../../../../../services/adapters/output"

class IndexRepository {

    fetchFilter = async (query, cancelToken) => {
        return await apiRequest("get", dashboardFilter, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDashboardReport = async (query) => {
        return await apiRequest("post", dashboardReport, {
            body: query
        }, true)
    }

    fetchDashboardIcon = async (query) => {
        return await apiRequest("post", dashboardIcon, {
            body: query
        }, true)
    }

    fetchDashboardGpp = async (query) => {
        return await apiRequest("post", dashboardGpp, {
            body: query
        }, true)
    }

    fetchDashboardSigap = async (query) => {
        return await apiRequest("post", dashboardSigap, {
            body: query
        }, true)
    }

    fetchDashboardDaftarPekerjaan = async (query) => {
        return await apiRequest("post", dashboardDaftarPekerjaan, {
            body: query
        }, true)
    }

    fetchDashboarPengajuan = async (query) => {
        return await apiRequest("post", dashboardPengajuan, {
            body: query
        }, true)
    }

    fetchGetPengumuman = async () => {
        return await apiRequest("get", informasiGetPengumuman, {}, true)
    }

    fetchGetPeraturan = async () => {
        return await apiRequest("get", informasiGetPeraturan, {}, true)
    }
    
    fetchGetFaq = async () => {
        return await apiRequest("get", informasiGetFaq, {}, true)
    }

    fetchGetNotifPersetujuan = async () => {
        return await apiRequest("get", dashboardGetNotifPersetujuan, {}, true)
    }

    fetchGetPerhitungan = async () => {
        return await apiRequest("get", dashboardGetPerhitungan, {}, true)
    }

}

export default IndexRepository