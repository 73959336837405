import {
    apiRequest,
    masterTunjanganKhususRegulationCreate,
    masterTunjanganKhususMasterGrade
} from "../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterTunjanganKhususRegulationCreate, {
            body: query
        }, true)
    }

    fetchGradeList = async () => {
        return await apiRequest("get", masterTunjanganKhususMasterGrade, {}, true)
    }


}

export default CreateRepository