import {
    apiRequest,
    masterSpdCountryDetail,
    masterSpdCountryCountries,
    masterSpdCountryupdate,
    masterSpdCountryprovinceCheck
    // masterReferenceSignerUpdate
} from "../../../../../../services/adapters/master"

class UpdateRepository {

    
    fetchDetail = async (query, cancelToken) => {
        return await apiRequest("get", masterSpdCountryDetail, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", masterSpdCountryupdate, {
            body: query
        }, true)
    }

    fetchCountryList = async () => {
        return await apiRequest("get", masterSpdCountryCountries, {}, true)
    }

    fetchCheck = async (query) => {
        return await apiRequest("post",masterSpdCountryprovinceCheck, {
            body: query
        }, true)
    }
}

export default UpdateRepository