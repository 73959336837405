import { apiRequest, uangMakanRegularVerifyList, uangMakanRegulerCompareList, tunkinRegulerResetRequest } from "../../../../../../services/adapters/process"

class VerifyRepository {
    fetchVerify = async (query) => {
        return await apiRequest(
            "get",
            uangMakanRegularVerifyList,
            {
                params: query,
            },
            true
        )
    }

    fetchCompare = async (query) => {
        return await apiRequest(
            "get",
            uangMakanRegulerCompareList,
            {
                params: query,
            },
            true
        )
    }

    fetchResetRequest = async (query) => {
        return await apiRequest(
            "post",
            tunkinRegulerResetRequest,
            {
                body: query,
            },
            true
        )
    }
}

export default VerifyRepository
