import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'


class CreateBloc {

    searchChannel = new Subject()
    participantChannel = new Subject();
    createChannel = new Subject()

    repository = new CreateRepository()

    constructor() {
        this.searchChannel.next({ status: apiResponse.INITIAL })
        this.participantChannel.next({ status: apiResponse.INITIAL })
        this.createChannel.next({ status: apiResponse.INITIAL })
    }

    fetchSearch = async (query) => {
        this.searchChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchSearch(query).then((result) => {
                this.searchChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.searchChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchParticipant = async (query) => {
        this.participantChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchParticipant(query).then((result) => {
                this.participantChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.participantChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }
}

export default CreateBloc