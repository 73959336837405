import {
    apiRequest,
    dashboardReport,
    dashboardGetMonitoringOP,
    dashboardGetPerhitunganOP,
    dashboardGetLayananOP
} from "../../../../../services/adapters/output"

class IndexRepository {

    fetchDashboardReport = async (query) => {
        return await apiRequest("post", dashboardReport, {
            body: query
        }, true)
    }

    fetchGetMonitoringOP = async () => {
        return await apiRequest("get", dashboardGetMonitoringOP, {}, true)
    }
    
    fetchGetPerhitunganOP = async () => {
        return await apiRequest("get", dashboardGetPerhitunganOP, {}, true)
    }

    fetchGetLayananOP = async () => {
        return await apiRequest("get", dashboardGetLayananOP, {}, true)
    }

}

export default IndexRepository