import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import DataTableRepository from '../Repositories/DataTableRepository'


class DataTableBloc {

    rowtableChannel = new Subject();
    detailChannel = new Subject();
    inactiveChannel = new Subject();
    activeChannel = new Subject();
    repository = new DataTableRepository()

    constructor() {
        this.rowtableChannel.next({status: apiResponse.INITIAL})
        this.detailChannel.next({status: apiResponse.INITIAL})
        this.inactiveChannel.next({status: apiResponse.INITIAL})
        this.activeChannel.next({status: apiResponse.INITIAL})
    }

    fetchRowTable = async (query, cancelToken) => {
        this.rowtableChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTable(query, cancelToken).then((result) => {
                this.rowtableChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.detailChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchInactive = async (query) => {
        this.inactiveChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchInactive(query).then((result) => {
                this.inactiveChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.inactiveChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchActive = async (query) => {
        this.activeChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchActive(query).then((result) => {
                this.activeChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.activeChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
}

export default DataTableBloc