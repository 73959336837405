import { apiRequest, officialTravelRapelVerificationList } from "../../../../../../services/adapters/process"

class DataTableRepository {
    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            officialTravelRapelVerificationList,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }
}

export default DataTableRepository
