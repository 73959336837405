import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import {
    Tooltip,
    Modal
} from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../services/adapters/base"

import IndexViewBloc from '../Blocs/IndexViewBloc'
import apiResponse from '../../../../../services/apiResponse'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import MinioClient from '../../../../../services/minioClient'


class SPTRegulerListComponent extends Component {
    minioClient = new MinioClient()

    indexViewBloc = new IndexViewBloc();

    source
    historyTable = 'historySPTRegulerListComponent'
    defaultOrder = 'employee_name'
    defaultSize = 20
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            showSptData: false,
            modalrecord: null,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            year: this.props.year
        }
        await this.indexViewBloc.fetchRowTableRegular(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => { }

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.rowDataRegularChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.rowDataRegularChannel.unsubscribe()
    }

    listPengajuan = (year, month, jumlah) => {
        let list = []
        for (let i = 0; i < jumlah; i++) {
            if ((month - i) <= 0) {
                list.push({ year: year - 1, month: 12 + (month - i) })
            } else {
                list.push({ year: year, month: month - i })
            }
        }
        return list
    }

    columns = [
        {
            key: "employee_nip",
            text: "NIP",
            sortable: true,
            width: '140',
            align: 'center'
        },
        {
            key: "employee_name",
            text: "Nama Pegawai",
            width: '220',
            sortable: true
        },
        {
            key: "employee_work_unit_name",
            text: "Unit Kerja",
            sortable: true,
        },
        {
            key: "spt_detail_status",
            text: "Status",
            width: '100',
            sortable: true,
            align: 'center',
            cell: record => {
                return (
                    <div className={(record.spt_detail_status === 3 ? "bg-success" : (record.spt_detail_status === 2 ? "bg-danger" : "bg-secondary")) + " rounded-circle d-inline-block"} style={{ height: 10, width: 10 }}></div>
                )
            }
        },
        {
            key: "action",
            text: "Aksi",
            width: '90',
            align: 'center',
            cell: record => {
                const thisId = record.employee_nip
                var dataPDF = record.spt_detail_status === 2 ? true : false
                var dataTTE = record.spt_detail_status === 3 ? true : false
                var dataReady = dataPDF || dataTTE || record.spt_detail_status === 1 ? true : false
                return (
                    <>
                        {dataReady ?
                            <>
                                <button
                                    className="btn btn-primary btn-sm"
                                    id={"detail-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            showSptData: true,
                                            modalrecord: record,
                                        });
                                    }}
                                    style={{
                                        padding: '2px 5px',
                                        marginRight: '2px',
                                    }}
                                >
                                    <i className="uil-align-left"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["detail" + thisId]: !this.state["detail" + thisId]
                                        })
                                    }}
                                >Lihat Data</Tooltip>
                            </>
                            :
                            <button
                                className="btn btn-soft-secondary btn-sm" disabled={true}
                                style={{
                                    padding: '2px 5px',
                                    marginRight: '2px',
                                }}>
                                <i className="uil-align-left"></i>
                            </button>
                        }
                        {dataTTE ?
                            <>
                                <button
                                    className="btn btn-danger btn-sm"
                                    id={"print-" + thisId}
                                    onClick={() => {
                                        this.downloadSigned(record.spt_detail_file_path)
                                    }}
                                    style={{
                                        padding: '2px 5px'
                                    }}
                                >
                                    <i className="uil-arrow-to-bottom"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["print" + thisId]} target={"print-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["print" + thisId]: !this.state["print" + thisId]
                                        })
                                    }}
                                >Download</Tooltip>
                            </>
                            :
                            <button
                                className="btn btn-soft-secondary btn-sm" disabled={true}
                                style={{
                                    padding: '2px 5px'
                                }}>
                                <i className="uil-arrow-to-bottom"></i>
                            </button>
                        }
                    </>
                )
            }
        }
    ]

    downloadSigned = (path) => {
        this.setState({
            loading: true
        })
        this.minioClient.get(path).then((url) => {
            this.setState({
                loading: false,
                success: true
            })
            setTimeout(() => {
                this.setState({
                    success: false,
                })
            }, 1000);
            window.open(url, '_blank');
        })
    }

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1000}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="lg"
                    isOpen={this.state.showSptData}
                    toggle={() => {
                        this.setState({
                            showSptData: !this.state.showSptData
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Rincian Penghasilan dan Penghitungan PPh Pasal 21
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showSptData: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.modalrecord !== null ?
                            <Table
                                className="table table-striped table-bordered table-hover"
                            >
                                <Thead>
                                    <Tr>
                                        <Th width="*" data-priority="1" colspan="3">Uraian</Th>
                                        <Th width="160px" data-priority="3" className="text-center">Jumlah</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <tr>
                                        <td className="fw-bold" colspan="4">Penghasilan Bruto</td>
                                    </tr>
                                    <tr>
                                        <td width="50px" className="text-end">1</td>
                                        <td className="" colspan="2">Gaji Pokok/Pensiun</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row1 !== null ? this.state.modalrecord.spt_detail_row1.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">2</td>
                                        <td className="" colspan="2">Tunjangan Istri</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row2 !== null ? this.state.modalrecord.spt_detail_row2.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">3</td>
                                        <td className="" colspan="2">Tunjangan Anak</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row3 !== null ? this.state.modalrecord.spt_detail_row3.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">4</td>
                                        <td className="" colspan="2">Jumlah Gaji dan Tunjangan Keluarga (1 s/d 3)</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row4 !== null ? this.state.modalrecord.spt_detail_row4.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">5</td>
                                        <td className="" colspan="2">Tunjangan Perbaikan Penghasilan</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row5 !== null ? this.state.modalrecord.spt_detail_row5.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">6</td>
                                        <td className="" colspan="2">Tunjangan Struktural/Fungsional</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row6 !== null ? this.state.modalrecord.spt_detail_row6.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">7</td>
                                        <td className="" colspan="2">Tunjangan Beras</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row7 !== null ? this.state.modalrecord.spt_detail_row7.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">8</td>
                                        <td className="" colspan="2">Tunjangan Khusus</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row8 !== null ? this.state.modalrecord.spt_detail_row8.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">9</td>
                                        <td className="" colspan="2">Tunjangan Lain-lain</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row9 !== null ? this.state.modalrecord.spt_detail_row9.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">10</td>
                                        <td className="" colspan="2">Penghasilan Tetap dan Teratur Lainnya yang Pembayarannya Terpisah dari Pembayaran Gaji</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row10 !== null ? this.state.modalrecord.spt_detail_row10.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">11</td>
                                        <td className="" colspan="2">Jumlah Penghasilan Bruto (4 s/d 10)</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row11 !== null ? this.state.modalrecord.spt_detail_row11.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold" colspan="4">Pengurangan</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">12</td>
                                        <td className="" colspan="2">Biaya Jabatan/Biaya Pensiun</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row12 !== null ? this.state.modalrecord.spt_detail_row12.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">13</td>
                                        <td className="" colspan="2">Iuran Pensiun atau Iuran THT/JHT</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row13 !== null ? this.state.modalrecord.spt_detail_row13.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">14</td>
                                        <td className="" colspan="2">Jumlah Pengurangan (12 s/d 13)</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row14 !== null ? this.state.modalrecord.spt_detail_row14.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold" colspan="4">Penghitungan PPh Pasal 21</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">15</td>
                                        <td className="" colspan="2">Jumlah Penghasilan Neto (11 - 14)</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row15 !== null ? this.state.modalrecord.spt_detail_row15.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">16</td>
                                        <td className="" colspan="2">Penghasil Neto Masa Pajak Sebelumnya</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row16 !== null ? this.state.modalrecord.spt_detail_row16.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">17</td>
                                        <td className="" colspan="2">Jumlah Penghasilan Neto untuk Penghitungan PPh Pasal 21 (Setahun/Disetahunkan)</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row17 !== null ? this.state.modalrecord.spt_detail_row17.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">18</td>
                                        <td className="" colspan="2">Penghasil Tidak Kena Pajak (PTKP)</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row18 !== null ? this.state.modalrecord.spt_detail_row18.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">19</td>
                                        <td className="" colspan="2">Penghasilan Kena Pajak Setahun/Disetahunkan (17-18)</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row19 !== null ? this.state.modalrecord.spt_detail_row19.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">20</td>
                                        <td className="" colspan="2">PPh Pasal 21 Atas Penghasilan Kena Pajak Setahun/Disetahunkan</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row20 !== null ? this.state.modalrecord.spt_detail_row20.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">21</td>
                                        <td className="" colspan="2">PPh Pasal 21 yang telah Dipotong Masa Pajak Sebelumnya</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row21 !== null ? this.state.modalrecord.spt_detail_row21.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">22</td>
                                        <td className="" colspan="2">PPh Pasal 21 Terutang</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row22 !== null ? this.state.modalrecord.spt_detail_row22.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">23</td>
                                        <td className="" colspan="2">PPh Pasal 21 yang telah Dipotong dan Dilunasi</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row23 !== null ? this.state.modalrecord.spt_detail_row23.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end"></td>
                                        <td width="50px" className="text-end">23A</td>
                                        <td className="">Atas Gaji dan Tunjangan</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row23a !== null ? this.state.modalrecord.spt_detail_row23a.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end"></td>
                                        <td width="50px" className="text-end">23B</td>
                                        <td className="">Atas Penghasilan Tetap dan teratur Lainnya yang Pembayarannya Terpisah dari Pembayaran Gaji</td>
                                        <td className="text-end">{this.state.modalrecord.spt_detail_row23b !== null ? this.state.modalrecord.spt_detail_row23b.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
                                    </tr>
                                </Tbody>
                            </Table> : <></>
                        }
                    </div>
                </Modal>
                <ConfirmAlert

                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"

                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"

                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />

                <ConfirmAlert

                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"

                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"

                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
            </>
        )
    }
}

export default SPTRegulerListComponent