import {
    apiRequest,
    overtimeYears,
    overtimeMonths,
    overtimeHistory,
    overtimePushSPM,
    overtimeUpdateSPM,
} from "../../../../../services/adapters/process"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", overtimeYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", overtimeMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", overtimeHistory, {
            params: query
        }, true)
    }

    fetchPushSPM = async (query) => {
        return await apiRequest(
            "post",
            overtimePushSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest(
            "post",
            overtimeUpdateSPM,
            {
                body: query,
            },
            true
        )
    }

}

export default IndexViewRepository