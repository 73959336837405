import React, { Fragment, Component } from "react"
import { Link, Redirect } from "react-router-dom"
import apiResponse from '../../../../../services/apiResponse'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import {
    Container,
    Row, Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Alert,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import UpdateBloc from './Blocs/UpdateBloc'
import LoadingComponent from './Components/LoadingComponent'

const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ];

class SkpUpdateView extends Component {

    updateBloc = new UpdateBloc()
    source
    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            detail: null,
            gpp: null,
            loadingDetail: true,
            checkTunkin: [],
            loadingCheckTunkin: true,
            checkTunkinStatus: '',
        }
    }

    submitData = async () => {
        await this.updateBloc.fetchApprove(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                uuid: this.props.uuid,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.updateBloc.checkTunkinChannel.subscribe((result) => {
            switch(result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response

                    this.setState({ 
                        checkTunkin: response.result,
                        checkTunkinStatus: response.pass,
                        loadingCheckTunkin: false
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        checkTunkinStatus: null,
                        loadingCheckTunkin: false
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingDetail: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingDetail: false,
                        detail: result.data.response.paycheck,
                        gpp: result.data.response.gpp
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.approveChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.fetchCheckTunkin({ uuid: this.props.uuid })
        this.updateBloc.fetchDetail({uuid: this.props.uuid})
    }

    componentWillUnmount() {
        this.updateBloc.checkTunkinChannel?.unsubscribe()
        this.updateBloc.detailChannel?.unsubscribe()
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Proses Pengajuan Slip Gaji</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Proses Slip Gaji berdasarkan tahun dan bulan tertentu sesuai dengan data yang sudah di verifikasi
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        { 
                                            this.state.loadingCheckTunkin === true ?
                                                <></>
                                            :
                                            <Alert color={this.state.checkTunkinStatus ? "success" : "danger"}>
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{ verticalAlign: "top"}}>
                                                            <i className={"uil " + (this.state.checkTunkinStatus ? "uil-check" : "uil-times")}></i>
                                                        </td>
                                                        <td style={{ verticalAlign: "top" }}>
                                                            { this.state.checkTunkinStatus ? "Proses dapat dilanjutkan" : "Proses tidak dapat dilanjutkan, Menemukan data yang tidak terdapat tunkin" }<br/>
                                                            { !this.state.checkTunkinStatus &&
                                                                <>
                                                                    {this.state.checkTunkin?.map((val, i) => {
                                                                        if (val.tunkin_uuid == null) {
                                                                            return (
                                                                                <li>
                                                                                    <small>{ months[val.month - 1] }{" "}{val.year}</small>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    })}
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                </table>
                                            </Alert>
                                        }       
                                        { this.state.loadingDetail === true ?
                                            <Fragment>
                                                <LoadingComponent message="Memeriksa Data Tunkin..." />
                                            </Fragment>     
                                            : 
                                            <>
                                                <Fragment>
                                                    <Row className="mb-3">
                                                        <Label
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Nama Pegawai
                                                        </Label>
                                                        <Label
                                                            className="col-md-8 col-form-label"
                                                        >
                                                            { this.state.detail?.paycheck_employee_name }
                                                        </Label>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Label
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Bulan
                                                        </Label>
                                                        <Label
                                                            className="col-md-8 col-form-label"
                                                        >
                                                            { months[this.state.detail?.paycheck_month - 1] }
                                                        </Label>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Label
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Tahun
                                                        </Label>
                                                        <Label
                                                            className="col-md-8 col-form-label"
                                                        >
                                                            { this.state.detail?.paycheck_year }
                                                        </Label>
                                                    </Row>
                                                </Fragment>
                                                <hr/>
                                                <Fragment>
                                                    <Row className="mb-0">
                                                        <Col md="6">
                                                            <Row className="mb-2">
                                                                <Label
                                                                    className="col-md-4 col-form-label"
                                                                >
                                                                    Penghasilan
                                                                </Label>
                                                                <Label
                                                                    className="ms-3 col-md-5 col-form-label text-end"
                                                                >
                                                                    { ((this.state.gpp?.gapok * 1) + (this.state.gpp?.tistri * 1) + (this.state.gpp?.tanak * 1) + (this.state.gpp?.tumum * 1) + (this.state.gpp?.ttambumum * 1) + (this.state.gpp?.tpapua * 1) + (this.state.gpp?.tpencil * 1) + (this.state.gpp?.tstruktur * 1) + (this.state.gpp?.tfungsi * 1) + (this.state.gpp?.tlain * 1) + (this.state.gpp?.bulat * 1) + (this.state.gpp?.tberas * 1) + (this.state.gpp?.tpajak * 1)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Label>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Gaji Pokok
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.gapok.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tunjangan Istri/Suami
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tistri.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tunjangan Anak
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tanak.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tunjangan Umum
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tumum.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tambahan Tunjangan Umum
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.ttambumum.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tunjangan Papua
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tpapua.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tunjangan Wilayah Terpencil
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tpencil.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tunjangan Jabatan Struktural
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tstruktur.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tunjangan Jabatan Fungsional
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tfungsi.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Lain-lain
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tlain.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Pembulatan
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.bulat.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tunjangan Beras
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tberas.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tunjangan Khusus Pajak
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tpajak.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md="6">
                                                            <Row className="mb-2">
                                                                <Label
                                                                    className="col-md-4 col-form-label"
                                                                >
                                                                    Potongan
                                                                </Label>
                                                                <Label
                                                                    className="ms-3 col-md-5 col-form-label text-end"
                                                                >
                                                                    { ((this.state.gpp?.pberas * 1) + (this.state.gpp?.iwp * 1) + (this.state.gpp?.bpjs * 1) + (this.state.gpp?.bpjs2 * 1) + (this.state.gpp?.pph * 1) + (this.state.gpp?.sewarmh * 1) + (this.state.gpp?.tunggakan * 1) + (this.state.gpp?.utanglebih * 1) + (this.state.gpp?.potlain * 1) + (this.state.gpp?.taperum * 1)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Label>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Potongan Beras
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.pberas.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Iuran Wajib Pajak
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.iwp.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    BPJS
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.bpjs.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    BPJS Lain
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.bpjs2.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Pajak Penghasilan
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.pph.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Sewa Rumah
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.sewarmh.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Tunggakan
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.tunggakan.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Utang Lebih
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.utanglebih.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Potongan Lain
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.potlain.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-0">
                                                                <div
                                                                    className="ms-3 col-md-4 col-form-label"
                                                                >
                                                                    Taperum
                                                                </div>
                                                                <Col md="5" className="text-end">
                                                                    { this.state.gpp?.taperum.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                            </>
                                        }

                                        <hr/>
                                        <Row className="mb-3">
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="10">
                                                <Link to="/payroll/sigap.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                <Button color="success" type="submit" disabled={!this.state.checkTunkinStatus}>
                                                    <i className="uil-arrow-up-right"></i> Proses Data Slip Gaji
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/payroll/sigap.html'/>
                : null }
 
            </Fragment>
        )
    }
}

export default SkpUpdateView