import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    Alert,
    Table,
    Input
} from "reactstrap"
import { withRouter } from "react-router";
import apiResponse from '../../../../../services/apiResponse'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../../helpers/ui/FileInput'
import moment from 'moment'

import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import IndexViewBloc from '../Blocs/IndexViewBloc'


class RefundUploadComponent extends Component {

    indexViewBloc = new IndexViewBloc()

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},

            refundUuid: null,
        }
    }

    conflictSolver = async () => {
        const formData = generateFormData(this.state.dataForm, { field: 'document', name: 'Keuangan/PerjalananDinas/Refund/Refund' })
        await this.indexViewBloc.fetchRefundUpload(formData)
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.conflictSolver()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.indexViewBloc.rowRefundUploadChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        this.setState({
                            loading: false,
                            success: true,
                        });
                        this.props.cancelUploadSolved()
                    } else {
                        this.setState({
                            loading: false,
                            failed: true,
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.setState({
            refundUuid: this.props.refundUuid
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.rowRefundUploadChannel.unsubscribe()
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
                refund: this.state.refundUuid
            },
            confirm: true
        })
    }

    handleKeyPress = (event) => {
        var last = event.target.value
        if (last.length >= 10) { event.preventDefault(); }
        if (event.which !== 44) {
            if (event.which !== 8 && isNaN(String.fromCharCode(event.which))) {
                event.preventDefault();
            }
        }
    }

    handleKeyUp = (event, id) => {
        const _this = document.querySelector('#' + id)
        var value = event.target.value
        value = value.charAt(0) === '0' ? value.substring(1) : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    }

    render() {
        return (
            <Fragment>
                <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                >
                    <div className="modal-body" style={{ paddingBottom: 0 }}>

                        <Row className="mb-3">
                            <label
                                className="col-md-5 col-form-label"
                            >
                                File Bukti <span className="text-danger">*</span>
                            </label>
                            <Col md="6">
                                <FileInput
                                    width={12}
                                    name="document"
                                    accept="application/pdf"
                                    validate={{ required: { value: true } }}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md="12">
                                <div className="form-check form-switch mb-3">
                                    <AvCheckboxGroup name="refundAgreement" required errorMessage="Agreement harus di centang">
                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                    </AvCheckboxGroup>
                                </div>
                            </Col>
                        </Row>

                    </div>
                    <div className="modal-footer">
                        <button
                            type="submit"
                            /* onClick={() => {
                                this.props.changeValue(this.state.formData)
                            }} */
                            className="btn btn-primary waves-effect waves-light"
                        >
                            Lakukan pengembalian
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                this.props.cancelUpload()
                            }}
                            className="btn btn-danger waves-effect"
                        >
                            Tutup
                        </button>
                    </div>
                </AvForm>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan mengirim bukti data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
            </Fragment >
        )
    }
}

export default withRouter(RefundUploadComponent);