import {
    apiRequest,
    overtimeDetail,
    overtimeSsp
} from "../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", overtimeDetail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", overtimeSsp, {
            params: query
        }, true)
    }
}

export default DownloadRepository