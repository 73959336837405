import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'

class CreateBloc {

    createChannel = new Subject()
    employeeChannel = new Subject()
    employeeDetailChannel = new Subject()
    getSignerLeft = new Subject()
    getSignerRight = new Subject()
    checkStatusChannel = new Subject()
    checkPresenceChannel = new Subject()
    checkLevelChannel = new Subject()
    checkGppChannel = new Subject()
    checkPtkpChannel = new Subject()
    checkPkpChannel = new Subject()
    checkEmployeeNewChannel = new Subject()
    checkEmployeeChangeChannel = new Subject()
    
    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.employeeChannel.next({ status: apiResponse.INITIAL })
        this.employeeDetailChannel.next({ status: apiResponse.INITIAL })
        this.getSignerLeft.next({ status: apiResponse.INITIAL })
        this.getSignerRight.next({ status: apiResponse.INITIAL })
        this.checkStatusChannel.next({ status: apiResponse.INITIAL })
        this.checkPresenceChannel.next({ status: apiResponse.INITIAL })
        this.checkLevelChannel.next({ status: apiResponse.INITIAL })
        this.checkGppChannel.next({ status: apiResponse.INITIAL })
        this.checkPtkpChannel.next({ status: apiResponse.INITIAL })
        this.checkPkpChannel.next({ status: apiResponse.INITIAL })
        this.checkEmployeeNewChannel.next({ status: apiResponse.INITIAL })
        this.checkEmployeeChangeChannel.next({ status: apiResponse.INITIAL })
        
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchEmployeeList = async (query, cancelToken) => {
        this.employeeChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchEmployeeList(query, cancelToken).then((result) => {
                this.employeeChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.employeeChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchEmployeeDetail = async (query) => {
        this.employeeDetailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployeeDetail(query).then((result) => {
                this.employeeDetailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeDetailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchGetSignerLeft = async (query) => {
        this.getSignerLeft.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetSigner(query).then((result) => {
                this.getSignerLeft.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getSignerLeft.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    fetchGetSignerRight = async (query) => {
        this.getSignerRight.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetSigner(query).then((result) => {
                this.getSignerRight.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getSignerRight.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    
    fetchCheckStatus = async (query) => {
        this.checkStatusChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckStatus(query).then((result) => {
                this.checkStatusChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkStatusChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    
    fetchCheckPresence = async (query) => {
        this.checkPresenceChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckPresence(query).then((result) => {
                this.checkPresenceChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkPresenceChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    
    fetchCheckLevel = async (query) => {
        this.checkLevelChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckLevel(query).then((result) => {
                this.checkLevelChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkLevelChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchCheckGpp = async (query) => {
        this.checkGppChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckGpp(query).then((result) => {
                this.checkGppChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkGppChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchCheckPtkp = async (query) => {
        this.checkPtkpChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckPtkp(query).then((result) => {
                this.checkPtkpChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkPtkpChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchCheckPkp = async (query) => {
        this.checkPkpChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckPkp(query).then((result) => {
                this.checkPkpChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkPkpChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchCheckEmployeeNew = async (query) => {
        this.checkEmployeeNewChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckEmployeeNew(query).then((result) => {
                this.checkEmployeeNewChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkEmployeeNewChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    
    fetchCheckEmployeeChange = async (query) => {
        this.checkEmployeeChangeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckEmployeeChange(query).then((result) => {
                this.checkEmployeeChangeChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkEmployeeChangeChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

}

export default CreateBloc