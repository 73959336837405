import {
    apiRequest,
    ptspRapelProcessSubmit,
    ptspRapelProcessEmployees,
    ptspRapelProcessEmployeeDetail,
    ptspRapelCheckStatus,
    ptspRapelGetSigner,
    ptspRapelCheckPresence,
    ptspRapelCheckTunkin,
    ptspRapelCheckTaxFinal,
    ptspRapelCheckEmployeeNew,
    ptspRapelCheckEmployeeChange,
} from "../../../../../../services/adapters/process"

class CreateRepository {
    fetchCreate = async (query) => {
        return await apiRequest(
            "post",
            ptspRapelProcessSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            ptspRapelProcessEmployees,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelProcessEmployeeDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchGetSigner = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelGetSigner,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelCheckStatus,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckPresence = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelCheckPresence,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckTunkin = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelCheckTunkin,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckTaxFinal = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelCheckTaxFinal,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelCheckEmployeeNew,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelCheckEmployeeChange,
            {
                params: query,
            },
            true
        )
    }
}

export default CreateRepository
