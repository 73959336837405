import {
    apiRequest,
    ptspRapelApproveSubmit,
    ptspRapelRejectSubmit,
    ptspRapelResultEmployee,
    ptspRapelCheckStatus,
    ptspRapelResultEmployeeDetail,
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {
    fetchApprove = async (query) => {
        return await apiRequest(
            "post",
            ptspRapelApproveSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchReject = async (query) => {
        return await apiRequest(
            "post",
            ptspRapelRejectSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            ptspRapelResultEmployee,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelResultEmployeeDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelCheckStatus,
            {
                params: query,
            },
            true
        )
    }
}

export default ApproveViewRepository
