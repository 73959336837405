import {
    apiRequest,
    masterSpdCountry,
    masterSpdCountryActive,
    masterSpdCountryInactive,
    masterSpdCountryDetail
} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get",  masterSpdCountry, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchInactive = async (query) => {
        return await apiRequest("post", masterSpdCountryInactive, {
            body: query
        }, true)
    }

    fetchActive = async (query) => {
        return await apiRequest("post", masterSpdCountryActive, {
            body: query
        }, true)
    }

    fetchDetail = async (query, cancelToken) => {
        return await apiRequest("get", masterSpdCountryDetail, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository