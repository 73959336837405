import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import IndexViewRepository from '../Repositories/IndexViewRepository'


class IndexViewBloc {

    yearsChannel = new Subject();
    monthsChannel = new Subject();
    repository = new IndexViewRepository()

    constructor() {
        this.yearsChannel.next({status: apiResponse.INITIAL})
        this.monthsChannel.next({status: apiResponse.INITIAL})
    }

    fetchYears = async () => {
        this.yearsChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchYears().then((result) => {
                this.yearsChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.yearsChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    
    fetchMonths = async (query, cancelToken) => {
        this.monthsChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchMonths(query, cancelToken).then((result) => {
                this.monthsChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.monthsChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

}

export default IndexViewBloc