import { apiRequest, officialTravelRapelSubmissionProcessDetail, officialTravelRapelSubmissionProcessParticipant, officialTravelRapelSubmissionProcessUpdate, officialTravelRapelSubmissionSource } from "../../../../../../services/adapters/process"

class ProcessRepository {
    fetchUpdate = async (query) => {
        return await apiRequest(
            "post",
            officialTravelRapelSubmissionProcessUpdate,
            {
                body: query,
            },
            true
        )
    }

    fetchDetail = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelSubmissionProcessDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            officialTravelRapelSubmissionProcessParticipant,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchSource = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelSubmissionSource,
            {
                params: query,
            },
            true
        )
    }
}

export default ProcessRepository
