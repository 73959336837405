import {
    apiRequest,
    masterTaxPtkp2024A,
    masterTaxPtkp2024B,
    masterTaxPtkp2024C,
    masterTaxPtkp2024Detail,
    masterTaxPtkp2024Create,
    masterTaxPtkp2024Update

} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTableA = async (query, cancelToken) => {
        return await apiRequest("get",  masterTaxPtkp2024A, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableB = async (query, cancelToken) => {
        return await apiRequest("get",  masterTaxPtkp2024B, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableC = async (query, cancelToken) => {
        return await apiRequest("get",  masterTaxPtkp2024C, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", masterTaxPtkp2024Create, {
            body: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", masterTaxPtkp2024Update, {
            body: query
        }, true)
    }

    fetchDetail = async (query, cancelToken) => {
        return await apiRequest("get", masterTaxPtkp2024Detail, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository