import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Modal
} from "reactstrap"
import Shimmer from "react-shimmer-effect";
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import { FileInput, generateFormData } from '../../../../../helpers/ui/FileInput'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect } from "react-router-dom"
import apiResponse from '../../../../../services/apiResponse'
import CreateBloc from './Blocs/CreateBloc'


class CreateView extends Component {

    createBloc = new CreateBloc()
    source
    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            loadingCountry: true
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, {field: 'document', name: 'Keuangan/SK/ADTLN'})
        await this.createBloc.fetchCreate(formData)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
              ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    _suffixTotal = "posisi jabatan"
    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.countryChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingCountry: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response.result
                    this.setState({
                        loadingCountry: false,
                        countries: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.fetchCountryList()
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
        this.createBloc.countryChannel?.unsubscribe()
    }

    handleKeyPress = (event) => {
        var last = event.target.value
        if(last.length >= 10) { event.preventDefault(); }
        if(event.which !== 44) {
            if(event.which !== 8 && isNaN(String.fromCharCode(event.which))){
                event.preventDefault();
            }
        }
    }
    handleKeyUp = (event, id) => {
        const _this = document.querySelector('#' + id)
        var value = event.target.value
        value = value.charAt(0) === '0' ? value.substring(1) : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Tambah Nilai TPLN Negara</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr/>
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-2 col-form-label"
                                            >
                                                Negara<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                {
                                                    this.state.loadingCountry?
                                                        <><Shimmer><div className="shimmer" style={{width: '100%', height: 38}}></div></Shimmer>{" "}</>
                                                    :
                                                        <>
                                                            <CustomSelect 
                                                                name="region_uuid"
                                                                errorMessage="Pilih Negara"
                                                                options={this.state.countries} 
                                                                placeholder="Pilih Negara"
                                                                optionLabel="region_name"
                                                                optionValue="region_uuid"
                                                                validate={{ required: { value: true } }}
                                                            />
                                                        </>
                                                    }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nomor<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="tpln_country_number"
                                                    placeholder="Nomor Surat Keputusan"
                                                    errorMessage="Masukkan Nomor Surat Keputusan"
                                                    type="text"
                                                    className="form-control"
                                                    id="fieldNumber"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldTitle"
                                                className="col-md-2 col-form-label"
                                            >
                                                Judul<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                <AvField
                                                    name="tpln_country_name"
                                                    placeholder="Judul Surat Keputusan"
                                                    errorMessage="Masukkan Judul Surat Keputusan"
                                                    type="text"
                                                    className="form-control"
                                                    id="fieldTitle"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldAbout"
                                                className="col-md-2 col-form-label"
                                            >
                                                Perihal <span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                <AvField
                                                    name="tpln_country_about"
                                                    placeholder="Nama Perihal"
                                                    errorMessage="Masukkan Perihal"
                                                    type="text"
                                                    className="form-control"
                                                    id="fieldAbout"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldDateCreate"
                                                className="col-md-2 col-form-label"
                                            >
                                                Tanggal Penetapan <span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <AvField
                                                    name="tpln_country_date"
                                                    placeholder="Tanggal Penetapan"
                                                    errorMessage="Masukkan Tanggal Penetapan"
                                                    type="date"
                                                    className="form-control"
                                                    id="fieldDateCreate"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldDescription"
                                                className="col-md-2 col-form-label"
                                            >
                                                Keterangan
                                            </Label>
                                            <Col md="6">
                                                <AvField
                                                    name="tpln_country_description"
                                                    placeholder="Keterangan"
                                                    errorMessage="Masukkan Keterangan"
                                                    type="textarea"
                                                    className="form-control"
                                                    id="fieldDescription"
                                                    rows="4"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldDateActive"
                                                className="col-md-2 col-form-label"
                                            >
                                                Tanggal Mulai Berlaku <span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <AvField
                                                    name="tpln_country_start_date"
                                                    placeholder="Tanggal Mulai Berlaku"
                                                    errorMessage="Masukkan Tanggal Mulai Berlaku"
                                                    type="date"
                                                    className="form-control"
                                                    id="fieldDateActive"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                File Surat Keputusan <span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                <FileInput 
                                                    name="document"
                                                    accept="application/pdf"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nilai<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <AvField
                                                    name="tpln_country_value"
                                                    placeholder="0"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Nilai"
                                                    className="form-control text-end"
                                                    id="fieldValue"
                                                    validate={{ required: { value: true } }}
                                                    onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue") }}
                                                    onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue") }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/master/negara.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/master/negara.html'/>
                : null }
            </Fragment>
        )
    }

}

export default CreateView