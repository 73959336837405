import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'


class CreateBloc {

    createChannel = new Subject()
    yearAvailableChannel = new Subject();
    allEmployeeChannel = new Subject();

    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.yearAvailableChannel.next({ status: apiResponse.INITIAL })
        this.allEmployeeChannel.next({ status: apiResponse.INITIAL })
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchYearAvailable = async () => {
        this.yearAvailableChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchYearAvailable().then((result) => {
                this.yearAvailableChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.yearAvailableChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchDataAllEmployeeList = async (query) => {
        this.allEmployeeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDataAllEmployeeList(query).then((result) => {
                this.allEmployeeChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.allEmployeeChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }
}

export default CreateBloc