import {
    apiRequest,
    ptspRegulerVerifyList,
    ptspRegulerCompareList,
    ptspRegulerResetRequest,
} from "../../../../../../services/adapters/process"

class VerifyRepository {
    fetchVerify = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerVerifyList,
            {
                params: query,
            },
            true
        )
    }

    fetchCompare = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerCompareList,
            {
                params: query,
            },
            true
        )
    }

    fetchResetRequest = async (query) => {
        return await apiRequest(
            "post",
            ptspRegulerResetRequest,
            {
                body: query,
            },
            true
        )
    }
}

export default VerifyRepository
