import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import DownloadRepository from '../Repositories/DownloadRepository'


class DownloadBloc {

    detailChannel = new Subject();
    sspChannel = new Subject();
    txtChannel = new Subject();
    repository = new DownloadRepository()

    constructor() {
        this.detailChannel.next({ status: apiResponse.INITIAL })
        this.sspChannel.next({ status: apiResponse.INITIAL })
        this.txtChannel.next({ status: apiResponse.INITIAL })
    }

    fetchDownloadDetail = async (query) => {
        this.detailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDownloadDetail(query).then((result) => {
                this.detailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.detailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchDownloadSsp = async (query) => {
        this.sspChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDownloadSsp(query).then((result) => {
                this.sspChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.sspChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }
    fetchDownloadTxt = async (query) => {
        this.txtChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDownloadTxt(query).then((result) => {
                this.txtChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.txtChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }
}

export default DownloadBloc