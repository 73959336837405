import {
    apiRequest,
    masterReferenceSignerType,
    masterReferenceSignerEmployee,
    masterReferenceSignerCreate
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchType = async (query) => {
        return await apiRequest("get", masterReferenceSignerType, {
            params: query
        }, true)
    }

    fetchEmployee = async (query) => {
        return await apiRequest("get", masterReferenceSignerEmployee, {
            params: query
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", masterReferenceSignerCreate, {
            body: query
        }, true)
    }
}

export default CreateRepository