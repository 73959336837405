import { accessRole } from "../../../../../helpers/access_role"
import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Modal,
} from "reactstrap"

import TableListComponent from './Components/TableListComponent'
import FormCreateComponent from './Components/FormCreateComponent'


//const OmspanView = (props) => {
class OmspanView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showOmspanCreate: false,
            reloadTable: false
        }
    }

    closeFormCancelCreate = () => {
        this.setState({
            showOmspanCreate: false
        })
    }

    closeFormSuccessCreate = () => {
        this.setState({
            showOmspanCreate: false,
        })
        this.reloadTable()
    }

    reloadTable = () => {
        this.setState({
            reloadTable: true
        }, () => {
            this.setState({
                reloadTable: false
            })
        })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="float-end">
                                        {
                                            (accessRole('create')) ?
                                                <Button color="primary"
                                                    onClick={() => {
                                                        this.setState({
                                                            showOmspanCreate: true
                                                        })
                                                    }}
                                                ><i className="uil-folder-medical"></i> Tambah Data Baru</Button>
                                                : null
                                        }
                                    </div>
                                    <CardTitle>Token OmSpan</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Informasi data token omspan.
                                    </CardSubtitle>
                                    <hr />
                                    {!this.state.reloadTable ?
                                        <TableListComponent /> : <></>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={this.state.showOmspanCreate}
                    toggle={() => {
                        this.setState({
                            showOmspanCreate: this.state.showOmspanCreate
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="omspanCreateModal"
                        >
                            Tambah Token OmSpan
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showOmspanCreate: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <FormCreateComponent cancelCreate={this.closeFormCancelCreate} successCreate={this.closeFormSuccessCreate} />
                    </div>
                </Modal>
            </Fragment>
        )
    }
}

export default OmspanView