import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip, Modal } from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../../services/apiResponse'

import moment from 'moment'

import TableDetailComponent from './TableDetailComponent'

class TableListComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyMasterTunkinCountry'
    defaultOrder = 'tpln_country_region_name'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            showCountryDetail: false,
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        {
            key: "tpln_country_active",
            text: "",
            className: "rating",
            align: 'center',
            width: 20,
            cell: record => {
                return (
                    <>
                        <div className="d-inline-block">
                            {
                                record.tpln_country_active === "1" ? <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div> : null
                            }
                        </div>
                    </>
                )
            }
        },
        {
            key: "tpln_country_region_code",
            text: "Kode",
            className: "address",
            align: 'center',
            sortable: true,
            width: '100'
        },
        {
            key: "tpln_country_region_name",
            text: "Negara",
            className: "rating",
            sortable: true,
        },
        {
            key: "tpln_country_date_start",
            text: "Awal",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-center">
                            { moment(record.tpln_country_date_start).format('LL') }
                        </div>
                    </>
                )
            }
        },
        {
            key: "tpln_country_date_end",
            text: "Akhir",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-center">
                            { record.tpln_country_date_end === null ? '-' : moment(record.tpln_country_date_end).format('LL') }
                        </div>
                    </>
                )
            }
        },
        {
            key: "tpln_country_currency_value",
            text: "Nilai ($)",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            { record.tpln_country_value.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                        </div>
                    </>
                )
            }
        },
        {
            key: "tpln_country_uuid",
            text: "Aksi",
            className: "",
            width: '80',
            align: 'center',
            cell: record => {
                const thisId = record.tpln_country_uuid
                return (
                    <Fragment>
                        <span>
                            <button 
                                className="btn btn-success btn-sm" 
                                id={"view-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        detailUuid: record.tpln_country_uuid,
                                        showCountryDetail: true
                                    })
                                }}
                                style={{
                                    padding: '2px 5px'
                                }}>
                                <i className="uil-align-left"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["view" + thisId]} target={"view-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["view" + thisId] : !this.state["view" + thisId]
                                    })
                                }}
                            >Lihat SK</Tooltip>
                        </span>
                    </Fragment>
                );
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={700}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />

                <Modal
                    size="lg"
                    isOpen={this.state.showCountryDetail}
                    toggle={() => {
                        this.setState({
                            showCountryDetail: this.state.showCountryDetail
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Daftar Surat Keputusan
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showCountryDetail: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TableDetailComponent uuid={this.state.detailUuid} />
                    </div>
                </Modal>
            </>
        )
    }

}

export default TableListComponent