import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment } from "react"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button
} from "reactstrap"


const IndexView = (props) => {

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                {
                                    (accessRole('create')) ?
                                        <Link to="/sample.html?create">
                                            <Button color="primary">New Sample</Button>
                                        </Link>
                                    : null
                                }
                                </div>
                                <CardTitle>Index Sample</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Keterangan menu-menu. Lorem ipsum dolor sit amet...
                                </CardSubtitle>
                                <hr />
                                Hai
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView