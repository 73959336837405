import {
    apiRequest,
    tplnRegulerProcessSubmit,
    tplnRegulerProcessEmployees,
    tplnRegulerProcessEmployeeDetail,
    //tplnRegulerProcessVerify,
    tplnRegulerGetSigner,
    tplnRegulerCheckStatus,
    tplnRegulerCheckKurs,
    tplnRegulerCheckGpp,
    tplnRegulerCheckPtkp,
    tplnRegulerCheckPkp,
    tplnRegulerCheckCountry,
    tplnRegulerCheckRank,
    tplnRegulerCheckOtherMonth,
    tplnRegulerCheckEmployeeNew,
    tplnRegulerCheckEmployeeChange,
    tplnRegulerUpdate
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", tplnRegulerProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", tplnRegulerProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tplnRegulerProcessEmployeeDetail, {
            params: query
        }, true)
    }

    /* fetchVerify = async (query) => {
        return await apiRequest("get", tplnRegulerProcessVerify, {
            params: query
        }, true)
    } */

    fetchGetSigner = async (query) => {
        return await apiRequest("get", tplnRegulerGetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tplnRegulerCheckStatus, {
            params: query
        }, true)
    }
    
    fetchCheckKurs = async (query) => {
        return await apiRequest("get", tplnRegulerCheckKurs, {
            params: query
        }, true)
    }

    fetchCheckGpp = async (query) => {
        return await apiRequest("get", tplnRegulerCheckGpp, {
            params: query
        }, true)
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest("get", tplnRegulerCheckPtkp, {
            params: query
        }, true)
    }
    
    fetchCheckPkp = async (query) => {
        return await apiRequest("get", tplnRegulerCheckPkp, {
            params: query
        }, true)
    }

    fetchCheckCountry = async (query) => {
        return await apiRequest("get", tplnRegulerCheckCountry, {
            params: query
        }, true)
    }

    fetchCheckRank = async (query) => {
        return await apiRequest("get", tplnRegulerCheckRank, {
            params: query
        }, true)
    }
    
    fetchCheckOtherMonth = async (query) => {
        return await apiRequest("get", tplnRegulerCheckOtherMonth, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", tplnRegulerCheckEmployeeNew, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", tplnRegulerCheckEmployeeChange, {
            params: query
        }, true)
    }


    fetchUpdate = async (query) => {
        return await apiRequest("post", tplnRegulerUpdate, {
            body: query
        }, true)
    }

}

export default CreateRepository