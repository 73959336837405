import {
    apiRequest,
    sptRegular,
    sptFinal
} from "../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadRegular = async (query) => {
        return await apiRequest("get", sptRegular, {
            params: query
        }, true)
    }
    fetchDownloadFinal = async (query) => {
        return await apiRequest("get", sptFinal, {
            params: query
        }, true)
    }
}

export default DownloadRepository