import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Link, Redirect } from "react-router-dom"

import { Modal } from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import DataTableGradeComponent from './DataTableGradeComponent'
import apiResponse from '../../../../../../services/apiResponse'

class DataTableValueComponent extends Component {
    dataTableBloc = new DataTableBloc();
    source
    historyTable = 'historyMasterTunkinPtspValue'
    defaultOrder = 'ministry_tunkin_value_allowance_grade'
    defaultSize = 10
    defaultSort = 'asc'
    _suffixTotal = "posisi jabatan"
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.historyTable = this.historyTable + this.props.uuid
        this.state = {
            tooltip: false,
            detailUuid: null,
            updateUuid: null,
            inactiveUuid: null,
            positionType: null,
            positionGrade: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            uuid: this.props.uuid
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.inactiveData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    inactiveData = async () => {
        await this.dataTableBloc.fetchInactive({ uuid: this.state.inactiveUuid })
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel?.unsubscribe()
    }

    columns = [
        {
            key: "ministry_tunkin_value_allowance_grade",
            text: "Grade",
            className: "",
            sortable: true,
            width: '90',
            align: 'center',
            cell: record => {
                return (
                    <>
                        {record.ministry_tunkin_value_allowance_grade}
                    </>
                )
            }
        },
        {
            key: "ministry_tunkin_value_total_structural",
            text: "Struktural",
            sortable: true,
            align: 'center',
            cell: record => {
                return (
                    <Fragment>
                        {record.ministry_tunkin_value_total_structural > 0 ?
                            <div
                                className="text-primary"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.setState({
                                        positionType: 1,
                                        positionGrade: record.ministry_tunkin_value_allowance_grade,
                                        showTotalList: true
                                    })
                                }}
                            >
                                {record.ministry_tunkin_value_total_structural} {this._suffixTotal}
                            </div>
                            :
                            <>-</>
                        }
                    </Fragment>
                )
            }
        },
        {
            key: "ministry_tunkin_value_total_functional",
            text: "Fungsional",
            sortable: true,
            align: 'center',
            cell: record => {
                return (
                    <Fragment>
                        {record.ministry_tunkin_value_total_functional > 0 ?
                            <div
                                className="text-primary"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.setState({
                                        positionType: 2,
                                        positionGrade: record.ministry_tunkin_value_allowance_grade,
                                        showTotalList: true
                                    })
                                }}
                            >
                                {record.ministry_tunkin_value_total_functional} {this._suffixTotal}
                            </div>
                            :
                            <>-</>
                        }
                    </Fragment>
                )
            }
        },
        {
            key: "ministry_tunkin_value_value",
            text: "Tunjangan",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            {record.ministry_tunkin_value_value.replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </div>
                    </>
                )
            }
        }/* ,
        {
            key: "kurs_value",
            text: "Nilai (Rp)",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            { Number(record.kurs_value).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                        </div>
                    </>
                )
            }
        } */
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menghapus data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dihapus"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dihapus"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                <Modal
                    size="lg"
                    isOpen={this.state.showTotalList}
                    toggle={() => {
                        this.setState({
                            showTotalList: this.state.showTotalList
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Jabatan {this.state.positionType === 1 ? "Struktural" : "Fungsional"} Grade {this.state.positionGrade}
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showTotalList: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <DataTableGradeComponent type={this.state.positionType} grade={this.state.positionGrade} />
                    </div>
                </Modal>


                {this.state.detailUuid !== null ?
                    <Redirect to={"/master/ptsp-tunkin.html?detail&uuid=" + this.state.detailUuid} />
                    : null}
                {this.state.updateUuid !== null ?
                    <Redirect to={"/master/ptsp-tunkin.html?update&uuid=" + this.state.updateUuid} />
                    : null}
            </>
        )
    }
}

export default DataTableValueComponent