import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import IndexViewRepository from '../Repositories/IndexViewRepository'


class IndexViewBloc {

    yearsChannel = new Subject();
    checkStatusRegularChannel = new Subject();
    checkStatusFinalChannel = new Subject();
    rowDataRegularChannel = new Subject();
    rowDataFinalChannel = new Subject();

    createRegularChannel = new Subject();
    createFinalChannel = new Subject();

    updateChannel = new Subject();

    repository = new IndexViewRepository()

    constructor() {
        this.yearsChannel.next({status: apiResponse.INITIAL})
        this.checkStatusRegularChannel.next({status: apiResponse.INITIAL})
        this.checkStatusFinalChannel.next({status: apiResponse.INITIAL})
        this.rowDataRegularChannel.next({status: apiResponse.INITIAL})
        this.rowDataFinalChannel.next({status: apiResponse.INITIAL})

        this.createRegularChannel.next({status: apiResponse.INITIAL})
        this.createFinalChannel.next({status: apiResponse.INITIAL})

        this.updateChannel.next({status: apiResponse.INITIAL})
    }

    fetchYears = async () => {
        this.yearsChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchYears().then((result) => {
                this.yearsChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.yearsChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    
    fetchRowTableRegular = async (query, cancelToken) => {
        this.rowDataRegularChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTableRegular(query, cancelToken).then((result) => {
                this.rowDataRegularChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowDataRegularChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchRowTableFinal = async (query, cancelToken) => {
        this.rowDataFinalChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTableFinal(query, cancelToken).then((result) => {
                this.rowDataFinalChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowDataFinalChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchCheckStatusRegular = async (query) => {
        this.checkStatusRegularChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCheckStatusRegular(query).then((result) => {
                this.checkStatusRegularChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.checkStatusRegularChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchCheckStatusFinal = async (query) => {
        this.checkStatusFinalChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCheckStatusFinal(query).then((result) => {
                this.checkStatusFinalChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.checkStatusFinalChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchCreateRegular = async (query) => {
        this.createRegularChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCreateRegular(query).then((result) => {
                this.createRegularChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.createRegularChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchCreateFinal = async (query) => {
        this.createFinalChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCreateFinal(query).then((result) => {
                this.createFinalChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.createFinalChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchUpdate = async (query) => {
        this.updateChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchUpdate(query).then((result) => {
                this.updateChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.updateChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    
}

export default IndexViewBloc