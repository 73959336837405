import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment } from "react"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle
} from "reactstrap"

import TableListComponent from './Components/TableListComponent'


const IndexView = (props) => {

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Pajak Final</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Informasi data referensi penghitungan pajak final berdasarkan golongan pegawai.
                                </CardSubtitle>
                                <hr />
                                <TableListComponent />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView