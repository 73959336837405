import {
    apiRequest,
    payrollSkpYears,
    payrollSkpList,
    payrollPayrollList,
    payrollSkpHistory,
    payrollSkpCreate,
    payrollSkpDetail, 
    payrollPayrollDetail
} from "../../../../../services/adapters/process"


import {
    apiRequest as apiOutputRequest,
    payrollSkpSign,
    payrollPayrollSign,
} from "../../../../../services/adapters/output"


class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", payrollSkpYears, {}, true)
    }
    
    fetchRowTableSkp = async (query, cancelToken) => {
        return await apiRequest("get", payrollSkpList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTablePayroll = async (query, cancelToken) => {
        return await apiRequest("get", payrollPayrollList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", payrollSkpHistory, {
            params: query
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", payrollSkpCreate, {
            body: query
        }, true)
    }

    fetchDetailSkp = async (query) => {
        return await apiRequest("get", payrollSkpDetail, {
            params: query
        }, true)
    }
    
    fetchDetailPayroll = async (query) => {
        return await apiRequest("get", payrollPayrollDetail, {
            params: query
        }, true)
    }

    fetchSignSkp = async (query) => {
        return await apiOutputRequest("post", payrollSkpSign, {
            body: query
        }, true)
    }

    fetchSignPayroll = async (query) => {
        return await apiOutputRequest("post", payrollPayrollSign, {
            body: query
        }, true)
    }
}

export default IndexViewRepository