import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component, useState, useRef } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect";
import Select from "react-select"

import {
    Container,
    Row, Col,
    Tooltip, 
    Modal,
    Label,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
import CustomSelect from '../../../../../helpers/ui/CustomSelect'

import DocumentIndexListComponent from './Components/DocumentIndexListComponent'
import DocumentIndexListComponentBerubah from './Components/DocumentIndexListComponentBerubah'
import DocumentIndexListComponentKeluar from './Components/DocumentIndexListComponentKeluar'

import IndexViewBloc from './Blocs/IndexViewBloc'
import apiResponse from '../../../../../services/apiResponse'


class IndexView extends Component {

    indexViewBloc = new IndexViewBloc()
    thisDate = new Date()
    // thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear() - 1 : this.thisDate.getFullYear()
    thisMonths = this.thisDate.getMonth() === 0 ? 12 : this.thisDate.getMonth() + 1
    thisMonth = "00".substring(0, "00".length - (this.thisMonths).toString().length) + (this.thisMonths).toString()

    monthName = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ]

    constructor(props) {
        super(props);
        const thisYear = new Date().getFullYear();
        const tahunOptions = Array.from({ length: 7 }, (_, index) => {
            const year = thisYear - 5 + index;
            return {
                value: year.toString(),
                label: year.toString()
            };
        });

        this.state = {
            showTunkinHistory: false,
            loadingCurrent: true,
            activeTab : "1",
            selectedMonth: { value: this.thisMonth, label: this.monthName[parseInt(this.thisMonth * 1) - 1] },
            optionMonth: [
                { value: '01', label: this.monthName[0] },
                { value: '02', label: this.monthName[1] },
                { value: '03', label: this.monthName[2] },
                { value: '04', label: this.monthName[3] },
                { value: '05', label: this.monthName[4] },
                { value: '06', label: this.monthName[5] },
                { value: '07', label: this.monthName[6] },
                { value: '08', label: this.monthName[7] },
                { value: '09', label: this.monthName[8] },
                { value: '10', label: this.monthName[9] },
                { value: '11', label: this.monthName[10] },
                { value: '12', label: this.monthName[11] },
            ],
            rentangOptions: [
                {
                    "value":"7",
                    "label":"7 Hari Terakhir"
                },
                {
                    "value":"30",
                    "label":"30 Hari Terakhir"
                },
                {
                    "value":"0",
                    "label":"Semua"
                }
            ],
            rentangSelected: "7",
            tahunOptions: tahunOptions,
            tahunSelected: thisYear.toString()
        };

    }

    loadCurrent = async () => {
        await this.indexViewBloc.fetchYears()
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab : tab
            })
        }
    }


    componentDidMount() {
        this.indexViewBloc.yearsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        currentRegulation: response,
                        loadingCurrent: false,
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.loadCurrent()
    }

    onChangeYear = (selectedYear) => {
        const isCurrentYear = selectedYear.value === new Date().getFullYear().toString();
    
        const rentangOptions = isCurrentYear
            ? [
                { value: "7", label: "7 Hari Terakhir" },
                { value: "30", label: "30 Hari Terakhir" },
                { value: "0", label: "Semua" }
              ]
            : [
                { value: "0", label: "Semua" }
              ];
    
        this.setState({
            selectedYear,
            rentangOptions,
            rentangSelected: "0", // Set to "Semua" by default
        });
    }

    setOptionYear = (list) => {
        const itemYear = [];
        list.map((year) => {
            if (year) { // Memeriksa apakah year ada dan bukan null atau undefined
                itemYear.push({ label: year.toString(), value: year });
            }
            return null;
        });
        this.setState({
            selectedYear: itemYear.length > 0 ? itemYear[0] : { label: this.thisYear.toString(), value: this.thisYear },
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear
                }
            ],
            loadingCurrent: false, // Menetapkan loadingCurrent ke false karena sudah mendapatkan opsi tahun
        });
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState({
            selectedYear: selectedYear,
            loadingCurrent: true
        }, () => {
            this.setState({
                loadingCurrent: false
            })
        });
    }

    handleOnChangeMonth = (selectedMonth) => {
        this.setState({
            selectedMonth: selectedMonth,
            loadingCurrent: true
        }, () => {
            this.setState({
                loadingCurrent: false
            })
        });
    }

    componentWillUnmount() {
        this.indexViewBloc.yearsChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Data Dokumen</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Dokumen diurutkan secara default berdasarkan tanggal rekam dokumen di SIMPEG yang terbaru.
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: this.state.activeTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            this.toggle("1")
                                                        }}
                                                    >
                                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                        <span className="d-none d-sm-block">Pegawai Masuk</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: this.state.activeTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            this.toggle("2")
                                                        }}
                                                    >
                                                        <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                                        <span className="d-none d-sm-block">Pegawai Berubah</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: this.state.activeTab === "3",
                                                        })}
                                                        onClick={() => {
                                                            this.toggle("3")
                                                        }}
                                                    >
                                                        <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                                        <span className="d-none d-sm-block">Pegawai Keluar</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>

                                            <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                                                <TabPane tabId="1">
                                                    {
                                                        this.state.loadingCurrent?
                                                            <>
                                                                <Shimmer><div className="shimmer" style={{ width: '10%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '20%', height: 36 }}></div></Shimmer>

                                                                <Shimmer><div className="shimmer" style={{ width: '10%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '20%', height: 22 }}></div></Shimmer>
                                                            </>
                                                            :
                                                            <>
                                                                <DocumentIndexListComponent />
                                                    </>
                                                    }
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    {
                                                        this.state.loadingCurrent?
                                                        <>
                                                            <Shimmer><div className="shimmer" style={{ width: '10%', height: 36 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '20%', height: 36 }}></div></Shimmer>

                                                            <Shimmer><div className="shimmer" style={{ width: '10%', height: 22 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '20%', height: 22 }}></div></Shimmer>
                                                        </>
                                                        :
                                                        <>
                                                            <DocumentIndexListComponentBerubah />
                                                        </>
                                                    }
                                                </TabPane>
                                                <TabPane tabId="3">
                                                    {
                                                        this.state.loadingCurrent?
                                                        <>
                                                            <Shimmer><div className="shimmer" style={{ width: '10%', height: 36 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '20%', height: 36 }}></div></Shimmer>

                                                            <Shimmer><div className="shimmer" style={{ width: '10%', height: 22 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                            <Shimmer><div className="shimmer" style={{ width: '20%', height: 22 }}></div></Shimmer>
                                                        </>
                                                        :
                                                        <>
                                                            <DocumentIndexListComponentKeluar />
                                                        </>
                                                    }
                                                </TabPane>
                                            </TabContent>
                                            
                                        </Col>
                                    </Row>
                                    {/* <hr />
                                    <Row>
                                        <Col sm={12}>
                                            {
                                                this.state.loadingCurrent ?
                                                    <>
                                                        <Shimmer><div className="shimmer" style={{ width: '10%', height: 36 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '20%', height: 36 }}></div></Shimmer>

                                                        <Shimmer><div className="shimmer" style={{ width: '10%', height: 22 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '20%', height: 22 }}></div></Shimmer>
                                                    </>
                                                    :
                                                    <>
                                                        <DocumentIndexListComponent year={this.state.selectedYear.value} month={this.state.selectedMonth.value} />
                                                    </>
                                            }
                                        </Col>
                                    </Row> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView