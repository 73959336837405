import {
    apiRequest,
    tunkinT13ApproveSubmit,
    tunkinT13RejectSubmit,
    tunkinT13ResultEmployee,
    tunkinT13CheckStatus,
    tunkinT13ResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", tunkinT13ApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", tunkinT13RejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", tunkinT13ResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tunkinT13ResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tunkinT13CheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository