import {
    apiRequest,
    masterOvertimeRegulationCreate,
    masterOvertimeMasterRank
} from "../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterOvertimeRegulationCreate, {
            body: query
        }, true)
    }

    fetchRankList = async () => {
        return await apiRequest("get", masterOvertimeMasterRank, {}, true)
    }

}

export default CreateRepository