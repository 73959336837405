import React, { Fragment, Component, useState } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    Button
} from "reactstrap"
import { Preview } from '../../../../../../helpers/ui/FileInput'

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../../services/apiResponse'
import moment from 'moment'

class TableDetailComponent extends Component {

    dataTableBloc = new DataTableBloc()

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.dataTableBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.dataTableBloc.fetchDetail({ uuid: this.props.uuid })
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Negara
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.official_travel_oversea_region_name !== "" ? this.state.detail.official_travel_oversea_region_name : '-'}</>
                                            : <Shimmer><div className="shimmer" style={{ width: '20%', height: 15 }}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Golongan A
                                </label>
                                <Col md="auto">$</Col>
                                <Col md="2" className="text-end">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.official_travel_oversea_daily_class_a !== "" ? this.state.detail.official_travel_oversea_daily_class_a.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-'}</>
                                            : <Shimmer><div className="shimmer" style={{ width: '20%', height: 15 }}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Golongan B
                                </label>
                                <Col md="auto">$</Col>
                                <Col md="2" className="text-end">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.official_travel_oversea_daily_class_b !== "" ? this.state.detail.official_travel_oversea_daily_class_b.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-'}</>
                                            : <Shimmer><div className="shimmer" style={{ width: '60%', height: 15 }}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Golongan C
                                </label>
                                <Col md="auto">$</Col>
                                <Col md="2" className="text-end">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.official_travel_oversea_daily_class_c !== "" ? this.state.detail.official_travel_oversea_daily_class_c.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-'}</>
                                            : <Shimmer><div className="shimmer" style={{ width: '20%', height: 15 }}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Golongan D
                                </label>
                                <Col md="auto">$</Col>
                                <Col md="2" className="text-end">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.official_travel_oversea_daily_class_d !== "" ? this.state.detail.official_travel_oversea_daily_class_d.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-'}</>
                                            :
                                            <Shimmer><div className="shimmer" style={{ width: '80%', height: 15 }}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default TableDetailComponent;