import {
    apiRequest,
    tplnT13RegulerMonth,
    tplnT13Create,
    tplnT13Months,
    tplnT13History,
    tplnT13PushSPM,
    tplnT13UpdateSPM,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {

    fetchRegulerMonts = async (query) => {
        return await apiRequest("get", tplnT13RegulerMonth, {
            params: query
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", tplnT13Create, {
            body: query
        }, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", tplnT13Months, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", tplnT13History, {
            params: query
        }, true)
    }

    fetchPushSPM = async (query) => {
        return await apiRequest(
            "post",
            tplnT13PushSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest(
            "post",
            tplnT13UpdateSPM,
            {
                body: query,
            },
            true
        )
    }
}

export default IndexViewRepository