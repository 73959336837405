import {
    apiRequest,
    masterTplnKursCreate,
    masterTplnKursCurrencies
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterTplnKursCreate, {
            body: query
        }, true)
    }

    fetchCurrencyList = async () => {
        return await apiRequest("get", masterTplnKursCurrencies, {}, true)
    }

}

export default CreateRepository