/* import {
    apiRequest,
    tunkinT13Detail,
    tunkinT13Ssp
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", tunkinT13Detail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tunkinT13Ssp, {
            params: query
        }, true)
    }
}

export default DownloadRepository */

import {
    apiRequest,
    tunkinT13Detail,
    tunkinT13Ssp,
    tunkinT13Bank
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", tunkinT13Detail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tunkinT13Ssp, {
            params: query
        }, true)
    }

    fetchDownloadBank = async (query) => {
        return await apiRequest("get", tunkinT13Bank, {
            params: query
        }, true)
    }
}

export default DownloadRepository