import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link, Redirect } from "react-router-dom"
import Select from "react-select"
import Shimmer from "react-shimmer-effect"

import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, Label } from "reactstrap"
import * as XLSX from "xlsx"
import Dropzone from "react-dropzone"
import { ConfirmAlert } from "../../../../../helpers/ui/alert"
import { FileInput, generateFormData } from "../../../../../helpers/ui/FileInput"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import CreateBloc from "./Blocs/CreateBloc"
import apiResponse from "../../../../../services/apiResponse"

class CreateView extends Component {
    createBloc = new CreateBloc()
    thisDate = new Date()
    thisYear = this.thisDate.getFullYear()
    thisMonth = this.thisDate.getMonth() + 1
    monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ]

    constructor(props) {
        super(props)
        this.state = {
            selectedYear: null,
            selectedMonth: null,
            fileData: [],
            status: 0, //0.Initial, 1.Loading file, 2.Data
            statusData: 0, //0.Empty, 1.Data OK
        }
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })
        this.setOptionYear(Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i))
        this.setOptionMonth(Array.from({ length: 12 }, (_, i) => 12 - i))
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState({
            selectedYear: { label: this.thisYear.toString(), value: this.thisYear },
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear,
                },
            ],
        })
    }

    setOptionMonth = (list) => {
        const itemMonth = []
        list.map((month) => {
            itemMonth.push({ label: this.monthNames[month - 1], value: month })
            return null
        })
        this.setState({
            selectedMonth: { label: this.monthNames[this.thisMonth - 1], value: this.thisMonth },
            optionMonth: [
                {
                    label: "Tahun",
                    options: itemMonth,
                },
            ],
        })
    }
    handleOnChangeYear = (selectedYear) => {
        this.setState(
            {
                selectedYear: selectedYear,
            },
            () => {
                //this.resetAllForm()
            }
        )
    }

    handleOnChangeMonth = (selectedMonth) => {
        this.setState(
            {
                selectedMonth: selectedMonth,
            },
            () => {
                //this.resetAllForm()
            }
        )
    }

    componentWillUnmount() {
        this.createBloc.createChannel.unsubscribe()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    handlerOnSubmit = (e, values) => {
        var fileDataExist = this.state.fileData
        if (fileDataExist.length > 0) {
            this.setState({
                dataForm: {
                    year: this.state.selectedYear.value,
                    month: this.state.selectedMonth.value,
                    data: this.state.fileData,
                },
                confirm: true,
            })
        }
    }

    submitData = async () => {
        //const formData = generateFormData(this.state.dataForm, { field: "document", name: "Trash/Unknown" })
        await this.createBloc.fetchCreate(this.state.dataForm)
    }

    handleOnSelectFile = (file) => {
        var f = file[0]
        const reader = new FileReader()
        reader.onload = (evt) => {
            const bstr = evt.target.result
            const wb = XLSX.read(bstr, { type: "binary" })
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            const data = XLSX.utils.sheet_to_json(ws, { header: 2 })

            var statusData = 0
            var _fileDatas = []
            if (data.length > 0) {
                statusData = 1
                for (var i = 0; i < data.length; ++i) {
                    _fileDatas.push(JSON.parse(JSON.stringify(data[i], (key, value) => (value === "0" ? null : value))))
                }
            }
            this.setState({
                status: 2,
                fileData: _fileDatas,
                statusData: statusData, //0.Empty, 1.Data OK
            })
        }
        reader.readAsBinaryString(f)

        this.setState({
            status: 1,
            fileName: file[0].name,
        })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah Tunjangan PTSP</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib
                                        diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Pilih Tahun<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                <div style={{ width: 116 }}>
                                                    <Select
                                                        value={this.state.selectedYear}
                                                        onChange={(selected) => {
                                                            this.handleOnChangeYear(selected)
                                                        }}
                                                        options={this.state.optionYear}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Pilih Bulan<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                <div style={{ width: 130 }}>
                                                    <Select
                                                        value={this.state.selectedMonth}
                                                        onChange={(selected) => {
                                                            this.handleOnChangeMonth(selected)
                                                        }}
                                                        options={this.state.optionMonth}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Unggah File<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="10">
                                                <div className="bd-highlight float-start">
                                                    <Dropzone
                                                        onDrop={(acceptedFiles) =>
                                                            this.handleOnSelectFile(acceptedFiles)
                                                        }
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div {...getRootProps()}>
                                                                    <input
                                                                        {...getInputProps()}
                                                                        multiple={false}
                                                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                    />
                                                                    <span
                                                                        className={
                                                                            "btn " +
                                                                            (this.state.fileName
                                                                                ? "btn-danger"
                                                                                : "btn-secondary") +
                                                                            " btn-sm"
                                                                        }
                                                                        style={{
                                                                            padding: "4px 7px",
                                                                        }}
                                                                    >
                                                                        <i className="uil-file"></i>
                                                                    </span>{" "}
                                                                    {this.state.fileName !== ""
                                                                        ? this.state.fileName
                                                                        : "Pilih File Excel"}
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                                <div className="bd-highlight float-end">
                                                    <span
                                                        className={"btn btn-link btn-sm"}
                                                        onClick={() => {
                                                            window.open(
                                                                process.env.REACT_APP_SERVICE_SYNC_URL +
                                                                    "/template/GajiManual.xlsx",
                                                                "_blank"
                                                            )
                                                        }}
                                                        style={{ padding: "2px 0px" }}
                                                    >
                                                        Download Template
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label className="col-md-2 col-form-label">
                                                Data Gaji <span className="text-danger">*</span>
                                            </Label>
                                            <Col md="10">
                                                <Table className="table table-striped table-bordered table-hover">
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="50px" data-priority="1" className="text-end">
                                                                No.
                                                            </Th>
                                                            <Th width="260px" data-priority="1">
                                                                Nip
                                                            </Th>
                                                            <Th width="*" data-priority="1">
                                                                Nama Pegawai
                                                            </Th>
                                                            <Th width="180px" data-priority="1" className="text-end">
                                                                Gaji Bersih
                                                            </Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.status === 0 ? (
                                                            <tr>
                                                                <Td colspan="5" className="text-center">
                                                                    Silahkan pilih file terlebih dahulu
                                                                </Td>
                                                            </tr>
                                                        ) : (
                                                            <>
                                                                {this.state.status === 1 ? (
                                                                    <tr>
                                                                        <Td
                                                                            style={{ paddingTop: 14, paddingBottom: 0 }}
                                                                        >
                                                                            <Shimmer>
                                                                                <div
                                                                                    className="shimmer"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: 15,
                                                                                    }}
                                                                                ></div>
                                                                            </Shimmer>
                                                                        </Td>
                                                                        <Td
                                                                            style={{ paddingTop: 14, paddingBottom: 0 }}
                                                                        >
                                                                            <Shimmer>
                                                                                <div
                                                                                    className="shimmer"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: 15,
                                                                                    }}
                                                                                ></div>
                                                                            </Shimmer>
                                                                        </Td>
                                                                        <Td
                                                                            style={{ paddingTop: 14, paddingBottom: 0 }}
                                                                        >
                                                                            <Shimmer>
                                                                                <div
                                                                                    className="shimmer"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: 15,
                                                                                    }}
                                                                                ></div>
                                                                            </Shimmer>
                                                                        </Td>
                                                                        <Td
                                                                            style={{ paddingTop: 14, paddingBottom: 0 }}
                                                                        >
                                                                            <Shimmer>
                                                                                <div
                                                                                    className="shimmer"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: 15,
                                                                                    }}
                                                                                ></div>
                                                                            </Shimmer>
                                                                        </Td>
                                                                    </tr>
                                                                ) : (
                                                                    <>
                                                                        {this.state.statusData === 0 ? (
                                                                            <tr>
                                                                                <Td colspan="5" className="text-center">
                                                                                    Data pegawai PTSP tidak ditemukan
                                                                                </Td>
                                                                            </tr>
                                                                        ) : (
                                                                            <>
                                                                                {Object.entries(
                                                                                    this.state.fileData
                                                                                ).map((item, key) => {
                                                                                    var color = "#495057"
                                                                                    var weight = "normal"
                                                                                    return (
                                                                                        <tr key={key}>
                                                                                            <td
                                                                                                style={{
                                                                                                    color: color,
                                                                                                    fontWeight: weight,
                                                                                                }}
                                                                                                className="text-end"
                                                                                            >
                                                                                                {key + 1}
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    color: color,
                                                                                                    fontWeight: weight,
                                                                                                }}
                                                                                            >
                                                                                                {item[1].nip}
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    color: color,
                                                                                                    fontWeight: weight,
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    item[1]
                                                                                                        .gpp_employee_name
                                                                                                }
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    color: color,
                                                                                                    fontWeight: weight,
                                                                                                }}
                                                                                                className="text-end"
                                                                                            >
                                                                                                {(
                                                                                                    (parseInt(
                                                                                                        item[1].gapok
                                                                                                    ) || 0) +
                                                                                                    (parseInt(
                                                                                                        item[1].tistri
                                                                                                    ) || 0) +
                                                                                                    (parseInt(
                                                                                                        item[1].tanak
                                                                                                    ) || 0) +
                                                                                                    (parseInt(
                                                                                                        item[1].tumum
                                                                                                    ) || 0) +
                                                                                                    (parseInt(
                                                                                                        item[1]
                                                                                                            .tstruktur
                                                                                                    ) || 0) +
                                                                                                    (parseInt(
                                                                                                        item[1].tfungsi
                                                                                                    ) || 0) +
                                                                                                    (parseInt(
                                                                                                        item[1].bulat
                                                                                                    ) || 0) +
                                                                                                    (parseInt(
                                                                                                        item[1].tberas
                                                                                                    ) || 0) +
                                                                                                    (parseInt(
                                                                                                        item[1].tpajak
                                                                                                    ) || 0) +
                                                                                                    (parseInt(
                                                                                                        item[1].tpencil
                                                                                                    ) || 0) +
                                                                                                    (parseInt(
                                                                                                        item[1].tlain
                                                                                                    ) || 0) -
                                                                                                    ((parseInt(
                                                                                                        item[1].iwp
                                                                                                    ) || 0) +
                                                                                                        (parseInt(
                                                                                                            item[1]
                                                                                                                .sewarmh
                                                                                                        ) || 0) +
                                                                                                        (parseInt(
                                                                                                            item[1]
                                                                                                                .pberas
                                                                                                        ) || 0) +
                                                                                                        (parseInt(
                                                                                                            item[1]
                                                                                                                .potlain
                                                                                                        ) || 0) +
                                                                                                        (parseInt(
                                                                                                            item[1]
                                                                                                                .taperum
                                                                                                        ) || 0) +
                                                                                                        (parseInt(
                                                                                                            item[1].bpjs
                                                                                                        ) || 0) +
                                                                                                        (parseInt(
                                                                                                            item[1]
                                                                                                                .bpjs2
                                                                                                        ) || 0) +
                                                                                                        (parseInt(
                                                                                                            item[1].pph
                                                                                                        ) || 0))
                                                                                                )
                                                                                                    .toString()
                                                                                                    .toString()
                                                                                                    .replace(
                                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                                        "."
                                                                                                    )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </Tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup
                                                        name="checkboxExample"
                                                        required
                                                        errorMessage="Agreement harus di centang"
                                                    >
                                                        <AvCheckbox
                                                            label="Saya yakin dan bertanggung jawab atas data ini"
                                                            value="1"
                                                        />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <Button
                                                    color="success"
                                                    type="submit"
                                                    disabled={this.state.statusData !== 1}
                                                >
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>{" "}
                                                <Link to="/integration/sigap-manual.html">
                                                    <Button color="danger" type="button">
                                                        <i className="uil-arrow-left"></i> Kembali
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"
                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"
                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
                {this.state.redirect ? <Redirect to={"/integration/sigap-manual.html"} /> : null}
            </Fragment>
        )
    }
}

export default CreateView
