import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import DataTableRepository from '../Repositories/DataTableRepository'


class DataTableBloc {

    rowtableChannel = new Subject();
    repository = new DataTableRepository()

    constructor() {
        this.rowtableChannel.next({ status: apiResponse.INITIAL })
    }

    fetchRowTable = async (query, cancelToken) => {
        this.rowtableChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRowTable(query, cancelToken).then((result) => {
                this.rowtableChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowtableChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

}

export default DataTableBloc