import {
    apiRequest,
    tunsusRapelNrss,
    tunsusRapelMonths,
    tunsusRapelHistory,
    tunsusRapelPushSPM,
    tunsusRapelUpdateSPM,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {
    fetchNrss = async () => {
        return await apiRequest("get", tunsusRapelNrss, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunsusRapelMonths,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchHistory = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelHistory,
            {
                params: query,
            },
            true
        )
    }

    fetchPushSPM = async (query) => {
        return await apiRequest(
            "post",
            tunsusRapelPushSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest(
            "post",
            tunsusRapelUpdateSPM,
            {
                body: query,
            },
            true
        )
    }
}

export default IndexViewRepository
