import {
    apiRequest,
    tunsusRegulerYears,
    tunsusRegulerNrss,
    tunsusRegulerMonths,
    tunsusRegulerHistory,
    tunsusRegulerPushSPM,
    tunsusRegulerUpdateSPM,
    tunsusRegulerResetApprove,
    tunsusRegulerResetReject,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {
    fetchYears = async () => {
        return await apiRequest("get", tunsusRegulerYears, {}, true)
    }

    fetchNrss = async () => {
        return await apiRequest("get", tunsusRegulerNrss, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunsusRegulerMonths,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchHistory = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerHistory,
            {
                params: query,
            },
            true
        )
    }

    fetchPushSPM = async (query) => {
        return await apiRequest(
            "post",
            tunsusRegulerPushSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest(
            "post",
            tunsusRegulerUpdateSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchResetApprove = async (query) => {
        return await apiRequest(
            "post",
            tunsusRegulerResetApprove,
            {
                body: query,
            },
            true
        )
    }

    fetchResetReject = async (query) => {
        return await apiRequest(
            "post",
            tunsusRegulerResetReject,
            {
                body: query,
            },
            true
        )
    }
}

export default IndexViewRepository
