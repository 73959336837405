import React, { Fragment, Component } from "react"
import { Link, Redirect } from "react-router-dom"
import apiResponse from '../../../../../services/apiResponse'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import {
    Container,
    Row, Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Alert,
    Modal,
    ModalHeader, 
    ModalBody,
    ModalFooter,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import UpdateBloc from './Blocs/UpdateBloc'
import LoadingComponent from './Components/LoadingComponent'
import {
    apiRequest,
    notificationCheck,
    notificationRead,
} from "../../../../../services/adapters/base"


const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ];

class SkpUpdateView extends Component {

    updateBloc = new UpdateBloc()
    source
    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            showDetail: null,
            dataDetail: {
                tunkin: 0,
                uangmakan: 0,
                tunkin13: 0,
                gaji13: 0,
                gpp: null,
            },
            detail: null,
            loadingDetail: true,
            checkTunkin: [],
            loadingCheckTunkin: true,
            checkTunkinStatus: '',
            selectedMonthNumber: null,
            selectedMonthName: null,
            selectedYear: null,
        }
    }

    submitData = async () => {
        await this.updateBloc.fetchApprove(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                uuid: this.props.uuid,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    handleButtonClick = () => {
        const notificationUuid = this.state.detail?.paycheck_uuid;  
        this.markNotificationAsRead(notificationUuid);
    }
    
    markNotificationAsRead = (notificationUuid) => {
        const requestData = {
            body: { notification_data_uuid: notificationUuid }
        };
        
        apiRequest("post", notificationRead, requestData)
        .then(result => {
            if(result.status) {
                // window.location.reload();
                // Handle success, maybe update state or redux store
            } else {
                console.error('Failed to mark notification as read:', result.message);
            }
        })
        .catch(error => {
            console.error('Error marking notification as read:', error);
        });
    }

    componentDidMount() {

        console.log(this.props.uuid);
        
        this.updateBloc.checkTunkinChannel.subscribe((result) => {
            switch(result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response

                    this.setState({ 
                        checkTunkin: response.result,
                        checkTunkinStatus: response.pass,
                        loadingCheckTunkin: false
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        checkTunkinStatus: null,
                        loadingCheckTunkin: false
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingDetail: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingDetail: false,
                        detail: result.data.response.paycheck,
                        gpp: result.data.response.gpp
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.approveChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.fetchCheckTunkin({ uuid: this.props.uuid })
        this.updateBloc.fetchDetail({uuid: this.props.uuid})
    }

    componentWillUnmount() {
        this.updateBloc.checkTunkinChannel?.unsubscribe()
        this.updateBloc.detailChannel?.unsubscribe()
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Proses Pengajuan Slip Gaji</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Proses Slip Gaji berdasarkan tahun dan bulan tertentu sesuai dengan data yang sudah di verifikasi
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        { this.state.loadingDetail === true ?
                                            <Fragment>
                                                {/* <LoadingComponent message="Memeriksa Data Tunkin..." /> */}
                                            </Fragment>     
                                            : 
                                            <>
                                                <Fragment>
                                                    <Row className="mb-3">
                                                        <Label
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Nama Pegawai
                                                        </Label>
                                                        <Label
                                                            className="col-md-8 col-form-label"
                                                        >
                                                            { this.state.detail?.paycheck_employee_name }
                                                        </Label>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Label
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Bulan
                                                        </Label>
                                                        <Label
                                                            className="col-md-8 col-form-label"
                                                        >
                                                            { months[this.state.detail?.paycheck_month - 1] }
                                                        </Label>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Label
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Tahun
                                                        </Label>
                                                        <Label
                                                            className="col-md-8 col-form-label"
                                                        >
                                                            { this.state.detail?.paycheck_year }
                                                        </Label>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Label
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Jumlah
                                                        </Label>
                                                        <Label
                                                            className="col-md-8 col-form-label"
                                                        >
                                                            { this.state.detail?.paycheck_total }
                                                        </Label>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Label
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Pengajuan Slip Gaji
                                                        </Label>
                                                        <Col md="8">
                                                            <Table
                                                                className="table table-striped table-bordered table-hover"
                                                            >
                                                                <Thead>
                                                                    <Tr>
                                                                        <Th width="50px" data-priority="1">No</Th>
                                                                        <Th width="*" data-priority="3">Bulan</Th>
                                                                        <Th width="*" data-priority="1">Tahun</Th>
                                                                        <Th width="*" data-priority="1">GPP</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    { 
                                                                        this.state.checkTunkin?.map((val, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>{i+1}</td>
                                                                                    <td>{months[val.month - 1]}</td>
                                                                                    <td>{val.year}</td>
                                                                                    <td>
                                                                                        {
                                                                                            val.available ?
                                                                                                <div
                                                                                                    class="link-primary"
                                                                                                    style={{
                                                                                                        cursor: 'pointer',
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        this.setState({
                                                                                                            showDetail: true,
                                                                                                            selectedMonthNumber: val.month,
                                                                                                            selectedMonthName: months[val.month - 1],
                                                                                                            selectedYear: val.year,
                                                                                                            dataDetail: val.data
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    Tersedia
                                                                                                </div>
                                                                                            : "Tidak Tersedia"
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </Tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                            </>
                                        }

                                        {this.state.detail?.paycheck_status === 1 && (
                                            <>
                                                <hr/>
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <div className="form-check form-switch mb-3">
                                                            <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                                <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                            </AvCheckboxGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <Link to="/payroll/slip.html">
                                                            <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                        </Link>
                                                        <Button 
                                                            color="success" 
                                                            type="submit" 
                                                            onClick={this.handleButtonClick}
                                                        >
                                                            <i className="uil-arrow-up-right"></i> Proses Data Slip Gaji
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail
                        })
                    }}
                >
                    <ModalHeader 
                        toggle={() => {
                            this.setState({
                                showDetail: !this.state.showDetail
                            })
                        }}
                    >
                        Detail Slip Gaji bulan { this.state.selectedMonthName } { this.state.selectedYear }
                    </ModalHeader>
                    <ModalBody>
                        { this.state.showDetail ?
                        <table>
                            <tr>
                                <td width="5%" style={{fontWeight: 'bold'}}>I.</td>
                                <td style={{fontWeight: 'bold'}} colspan="6">GAJI</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td width="5%">I.A.</td>
                                <td colspan="4">PENGHASILAN</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td width="35%">Gaji Pokok</td>
                                <td width="2%">:</td>
                                <td width="3%">Rp</td>
                                <td className="text-end" width="20%">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.gapok.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td width="10%"></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Anak</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.tanak.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Suami/Istri</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.tistri.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Umum</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.tumum.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Jabatan</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? ((this.state.dataDetail.gpp.tstruktur*1)+(this.state.dataDetail.gpp.tfungsi*1)).toString().replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Beras</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.tberas.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Pph</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.tpajak.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Pembulatan</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.bulat.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="2"></td>
                                <td>JUMLAH PENGHASILAN</td>
                                <td className="text-end" colspan="4">Rp</td>
                                <td className="text-end" width="20%">{ this.state.dataDetail.gpp !== null ? ((this.state.dataDetail.gpp?.gapok !== null ? (this.state.dataDetail.gpp.gapok*1) : 0)+(this.state.dataDetail.gpp?.tanak !== null ? (this.state.dataDetail.gpp.tanak*1) : 0)+(this.state.dataDetail.gpp?.tistri !== null ? (this.state.dataDetail.gpp.tistri*1) : 0)+(this.state.dataDetail.gpp?.tumum !== null ? (this.state.dataDetail.gpp.tumum*1) : 0)+(this.state.dataDetail.gpp?.tstruktur !== null ? (this.state.dataDetail.gpp.tstruktur*1) : 0)+(this.state.dataDetail.gpp?.tfungsi !== null ? (this.state.dataDetail.gpp.tfungsi*1) : 0)+(this.state.dataDetail.gpp?.tberas !== null ? (this.state.dataDetail.gpp.tberas*1) : 0)+(this.state.dataDetail.gpp?.tpajak !== null ? (this.state.dataDetail.gpp.tpajak*1) : 0)+(this.state.dataDetail.gpp?.bulat !== null ? (this.state.dataDetail.gpp.bulat*1) : 0) * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "0" }</td>
                            </tr>
                            <tr>
                                <td colspan="7">
                                    <div style={{height: '8px'}}></div>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>I.B.</td>
                                <td colspan="4">POTONGAN</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Iuran Wajib Pegawai</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.iwp.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Iuran BPJS</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.bpjs.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Iuran BPJS 2</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.bpjs2.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Iuran Perumahan</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.taperum.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Sewa Rumah</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.sewarmh.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Potongan PPh 21</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.pph.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Potongan Lain</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? this.state.dataDetail.gpp.potlain.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0' }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="2"></td>
                                <td>JUMLAH POTONGAN</td>
                                <td className="text-end" colspan="4">Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? ((this.state.dataDetail.gpp?.iwp !== null ? (this.state.dataDetail.gpp.iwp*1) : 0)+(this.state.dataDetail.gpp?.bpjs !== null ? (this.state.dataDetail.gpp.bpjs*1) : 0)+(this.state.dataDetail.gpp?.bpjs2 !== null ? (this.state.dataDetail.gpp.bpjs2*1) : 0)+(this.state.dataDetail.gpp?.taperum !== null ? (this.state.dataDetail.gpp.taperum*1) : 0)+(this.state.dataDetail.gpp?.sewarmh !== null ? (this.state.dataDetail.gpp.sewarmh*1) : 0)+(this.state.dataDetail.gpp?.pph !== null ? (this.state.dataDetail.gpp.pph*1) : 0)+(this.state.dataDetail.gpp?.potlain !== null ? (this.state.dataDetail.gpp.potlain*1) : 0) * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "0"}</td>
                            </tr>
                            <tr>
                                <td colspan="7">
                                    <div style={{height: '6px'}}></div>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colspan="2">GAJI BERSIH</td>
                                <td className="text-end" colspan="4">Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gpp !== null ? (
            (
                [
                    this.state.dataDetail.gpp?.gapok,
                    this.state.dataDetail.gpp?.tanak,
                    this.state.dataDetail.gpp?.tistri,
                    this.state.dataDetail.gpp?.tumum,
                    this.state.dataDetail.gpp?.tstruktur,
                    this.state.dataDetail.gpp?.tfungsi,
                    this.state.dataDetail.gpp?.tberas,
                    this.state.dataDetail.gpp?.tpajak,
                    this.state.dataDetail.gpp?.bulat
                ].reduce((total, item) => total + (item !== null ? item*1 : 0), 0)
                -
                [
                    this.state.dataDetail.gpp?.iwp,
                    this.state.dataDetail.gpp?.bpjs,
                    this.state.dataDetail.gpp?.bpjs2,
                    this.state.dataDetail.gpp?.taperum,
                    this.state.dataDetail.gpp?.sewarmh,
                    this.state.dataDetail.gpp?.pph,
                    this.state.dataDetail.gpp?.potlain
                ].reduce((total, item) => total + (item !== null ? item*1 : 0), 0)
            )
            .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        ) : "0" }</td>
                            </tr>
                            <tr>
                                <td colspan="7">
                                    <div style={{height: '8px'}}></div>
                                </td>
                            </tr>
                            {/* <tr>
                                <td style={{fontWeight: 'bold'}}>II.</td>
                                <td style={{fontWeight: 'bold'}} colspan="6">PENGHASILAN LAIN-LAIN</td>
                            </tr> */}
                            {/* <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunjangan Kinerja</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.tunkin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</td>
                                <td></td>
                            </tr> */}
                            {/* <tr>
                                <td></td>
                                <td>-</td>
                                <td>Uang Makan</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.uangmakan.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-</td>
                                <td>Gaji Ke-13/THR</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.gaji13.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</td>
                                <td></td>
                            </tr> */}
                            {/* <tr>
                                <td></td>
                                <td>-</td>
                                <td>Tunkin Ke-13/THR</td>
                                <td>:</td>
                                <td>Rp</td>
                                <td className="text-end">{ this.state.dataDetail.tunkin13.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</td>
                                <td></td>
                            </tr> */}
                            {/* <tr>
                                <td></td>
                                <td colspan="2">JUMLAH PENGHASILAN LAIN-LAIN</td>
                                <td className="text-end" colspan="4">Rp</td>
                                <td className="text-end">{ (this.state.dataDetail.tunkin+this.state.dataDetail.uangmakan+this.state.dataDetail.gaji13+this.state.dataDetail.tunkin13).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</td>
                            </tr> */}
                            <tr>
                                <td colspan="7">
                                    <div style={{height: '6px'}}></div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style={{fontWeight: 'bold'}}>TOTAL PENGHASILAN</td>
                                <td className="text-end" colspan="4" style={{fontWeight: 'bold'}}>Rp</td>
                                <td className="text-end" style={{fontWeight: 'bold'}}>
                                    { this.state.dataDetail.gpp !== null ? (
                                        (
                                            [
                                                this.state.dataDetail.gpp?.gapok,
                                                this.state.dataDetail.gpp?.tanak,
                                                this.state.dataDetail.gpp?.tistri,
                                                this.state.dataDetail.gpp?.tumum,
                                                this.state.dataDetail.gpp?.tstruktur,
                                                this.state.dataDetail.gpp?.tfungsi,
                                                this.state.dataDetail.gpp?.tberas,
                                                this.state.dataDetail.gpp?.tpajak,
                                                this.state.dataDetail.gpp?.bulat
                                            ].reduce((total, item) => total + (item !== null ? item*1 : 0), 0)
                                            -
                                            [
                                                this.state.dataDetail.gpp?.iwp,
                                                this.state.dataDetail.gpp?.bpjs,
                                                this.state.dataDetail.gpp?.bpjs2,
                                                this.state.dataDetail.gpp?.taperum,
                                                this.state.dataDetail.gpp?.sewarmh,
                                                this.state.dataDetail.gpp?.pph,
                                                this.state.dataDetail.gpp?.potlain
                                            ].reduce((total, item) => total + (item !== null ? item*1 : 0), 0)
                                        )
                                        .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    ) : "0" }
                                </td>
                            </tr>
                        </table>
                        : <></>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            color="danger"
                            onClick={() => {
                                this.setState({
                                    showDetail: false
                                })
                            }}
                        >
                            Tutup
                        </Button>
                    </ModalFooter>
                </Modal> 

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/payroll/slip.html'/>
                : null }
 
            </Fragment>
        )
    }
}

export default SkpUpdateView