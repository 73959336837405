import Shimmer from "react-shimmer-effect"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Media,
    Collapse
} from "reactstrap"

import faqimg from "../../../assets/images/faqs-img.png"
import logoLight from "../../../assets/images/logo-full.png"

class IndexView extends Component {

    
    constructor(props) {
        super(props)
        this.state = {
            isOpenCollapse1: true,
            isOpenCollapse2: false,
            isOpenCollapse3: false,
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12" className="mt-2">
                            <Card>
                                <CardBody>
                                    <Row className="justify-content-center">
                                        <Col lg={12}>
                                            <div className="text-center mb-5 mt-5">
                                                <img src={logoLight} alt="" height="120" />
                                            </div>
                                            <div className="text-center">
                                                <h5>Sistem Informasi Keuangan</h5>
                                                <p className="text-muted">Selamat datang diaplikasi keuangan BKPM</p>

                                                <Link to="/home/profile.html">
                                                    <Button color="primary">Masuk Aplikasi</Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col sm={4} className="mx-auto">
                                            <div>
                                                <img src={faqimg} alt="" className="img-fluid mx-auto d-block" />
                                            </div>
                                        </Col>
                                        <Col xl={8}>

                                            <div id="faqs-accordion" className="custom-accordion mt-5 mt-xl-0">
                                                <Card className="border shadow-none">
                                                    <Link to="#" className="text-dark" onClick={() => {
                                                        this.setState({
                                                            isOpenCollapse1: !this.state.isOpenCollapse1
                                                        })
                                                    }}>
                                                        <div className="bg-light p-3">
                                                            <div className="float-end pt-2">
                                                                <i className="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="me-3">
                                                                    <div className="avatar-xs">
                                                                        <div className="avatar-title rounded-circle font-size-22">
                                                                            <i className="uil uil-question-circle"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-1 overflow-hidden">
                                                                    <h5 className="font-size-16 mb-1">Petunjuk Penggunaan</h5>
                                                                    <p className="text-muted text-truncate mb-0">Petunjuk lengkap penggunaan Aplikasi Sistem Keuangan</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>

                                                    <Collapse isOpen={this.state.isOpenCollapse1} id="faqs-gen-ques-collapse">
                                                        <div className="p-4">
                                                            <Row>
                                                                <Col md="6">
                                                                    <Media className="d-flex mt-4">
                                                                        <div className="avatar-xs me-3">
                                                                            <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                                <i className="uil uil-question-circle"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-1">
                                                                            <h5 className="font-size-16 mt-1">What is Lorem Ipsum ?</h5>
                                                                            <p className="text-muted">If several languages coalesce, the grammar of the resulting language is more simple</p>
                                                                        </div>
                                                                    </Media>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Media className="d-flex mt-4">
                                                                        <div className="avatar-xs me-3">
                                                                            <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                                <i className="uil uil-question-circle"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-1">
                                                                            <h5 className="font-size-16 mt-1">Where does it come from ?</h5>
                                                                            <p className="text-muted">Everyone realizes why a new common language would be desirable one could refuse to pay expensive translators.</p>
                                                                        </div>
                                                                    </Media>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Media className="d-flex mt-4">
                                                                        <div className="avatar-xs me-3">
                                                                            <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                                <i className="uil uil-question-circle"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-1">
                                                                            <h5 className="font-size-16 mt-1">Why do we use it ?</h5>
                                                                            <p className="text-muted">Their separate existence is a myth. For science, music, sport, etc, Europe uses the same vocabulary. </p>
                                                                        </div>
                                                                    </Media>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Collapse>
                                                </Card>

                                                <Card className="border shadow-none">
                                                    <Link to="#" className="text-dark" onClick={() => {
                                                        this.setState({
                                                            isOpenCollapse2: !this.state.isOpenCollapse2
                                                        })
                                                    }}>
                                                        <div className="bg-light p-3">
                                                            <div className="float-end pt-2">
                                                                <i className="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="me-3">
                                                                    <div className="avatar-xs">
                                                                        <div className="avatar-title rounded-circle font-size-22">
                                                                            <i className="uil uil-question-circle"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-1 overflow-hidden">
                                                                    <h5 className="font-size-16 mb-1">Privacy Policy</h5>
                                                                    <p className="text-muted text-truncate mb-0">Privacy Policy Questions</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Collapse isOpen={this.state.isOpenCollapse2}>
                                                        <div className="p-4">
                                                            <Row>
                                                                <Col md="6">
                                                                    <Media className="d-flex mt-4">
                                                                        <div className="avatar-xs me-3">
                                                                            <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                                <i className="uil uil-question-circle"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-1">
                                                                            <h5 className="font-size-16 mt-1">What is Lorem Ipsum ?</h5>
                                                                            <p className="text-muted">If several languages coalesce, the grammar of the resulting language is more simple</p>
                                                                        </div>
                                                                    </Media>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Media className="d-flex mt-4">
                                                                        <div className="avatar-xs me-3">
                                                                            <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                                <i className="uil uil-question-circle"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-1">
                                                                            <h5 className="font-size-16 mt-1">Where does it come from ?</h5>
                                                                            <p className="text-muted">Everyone realizes why a new common language would be desirable one could refuse to pay expensive translators.</p>
                                                                        </div>
                                                                    </Media>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Media className="d-flex mt-4">
                                                                        <div className="avatar-xs me-3">
                                                                            <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                                <i className="uil uil-question-circle"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-1">
                                                                            <h5 className="font-size-16 mt-1">Why do we use it ?</h5>
                                                                            <p className="text-muted">Their separate existence is a myth. For science, music, sport, etc, Europe uses the same vocabulary. </p>
                                                                        </div>
                                                                    </Media>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Collapse>
                                                </Card>

                                                <Card className="border shadow-none">
                                                    <Link to="#" className="text-dark" onClick={() => {
                                                        this.setState({
                                                            isOpenCollapse3: !this.state.isOpenCollapse3
                                                        })
                                                    }}>
                                                        <div className="bg-light p-3">
                                                            <div className="float-end pt-2">
                                                                <i className="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="me-3">
                                                                    <div className="avatar-xs">
                                                                        <div className="avatar-title rounded-circle font-size-22">
                                                                            <i className="uil uil-question-circle"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-1 overflow-hidden">
                                                                    <h5 className="font-size-16 mb-1">Pricing & Plans</h5>
                                                                    <p className="text-muted text-truncate mb-0">Pricing & Plans Questions</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Collapse isOpen={this.state.isOpenCollapse3}>
                                                        <div className="p-4">
                                                            <Row>
                                                                <Col md="6">
                                                                    <Media className="d-flex mt-4">
                                                                        <div className="avatar-xs me-3">
                                                                            <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                                <i className="uil uil-question-circle"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-1">
                                                                            <h5 className="font-size-16 mt-1">What is Lorem Ipsum ?</h5>
                                                                            <p className="text-muted">If several languages coalesce, the grammar of the resulting language is more simple</p>
                                                                        </div>
                                                                    </Media>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Media className="d-flex mt-4">
                                                                        <div className="avatar-xs me-3">
                                                                            <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                                <i className="uil uil-question-circle"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-1">
                                                                            <h5 className="font-size-16 mt-1">Where does it come from ?</h5>
                                                                            <p className="text-muted">Everyone realizes why a new common language would be desirable one could refuse to pay expensive translators.</p>
                                                                        </div>
                                                                    </Media>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Media className="d-flex mt-4">
                                                                        <div className="avatar-xs me-3">
                                                                            <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                                <i className="uil uil-question-circle"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-1">
                                                                            <h5 className="font-size-16 mt-1">Why do we use it ?</h5>
                                                                            <p className="text-muted">Their separate existence is a myth. For science, music, sport, etc, Europe uses the same vocabulary. </p>
                                                                        </div>
                                                                    </Media>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Collapse>
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView