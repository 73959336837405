import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import DownloadRepository from '../Repositories/DownloadRepository'


class DownloadBloc {

    detailChannel = new Subject();
    sspChannel = new Subject();
    bankChannel = new Subject();
    nrsChannel = new Subject();
    repository = new DownloadRepository()

    constructor() {
        this.detailChannel.next({status: apiResponse.INITIAL})
        this.sspChannel.next({status: apiResponse.INITIAL})
        this.bankChannel.next({status: apiResponse.INITIAL})
        this.nrsChannel.next({status: apiResponse.INITIAL})
    }

    fetchDownloadDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDownloadDetail(query).then((result) => {
                this.detailChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDownloadSsp = async (query) => {
        this.sspChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDownloadSsp(query).then((result) => {
                this.sspChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.sspChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    fetchDownloadBank = async (query) => {
        this.bankChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDownloadBank(query).then((result) => {
                this.bankChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.bankChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    
    fetchDownloadNrs = async (query) => {
        this.nrsChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDownloadNrs(query).then((result) => {
                this.nrsChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.nrsChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
}

export default DownloadBloc