import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import IndexRepository from '../Repositories/IndexRepository'

class IndexBloc {
    currentChannel = new Subject();
    repository = new IndexRepository()

    constructor() {
        this.currentChannel.next({status: apiResponse.INITIAL})
    }

    fetchCurrent = async (query, cancelToken) => {
        this.currentChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCurrent(query, cancelToken).then((result) => {
                this.currentChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.currentChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }


}

export default IndexBloc