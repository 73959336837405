import {
    apiRequest,
    tunsusRapelDetailBefore,
    tunsusRapelDetailAfter,
    tunsusRapelDetailRapel,
    tunsusRapelSsp,
} from "../../../../../../services/adapters/output"

class DownloadRepository {
    fetchDownloadDetailBefore = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelDetailBefore,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadDetailAfter = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelDetailAfter,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadDetailRapel = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelDetailRapel,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelSsp,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
