import {
    apiRequest,
    tunjabCreate,
    tunjabYearAvailable,
    tunjabAllEmployeeList
} from "../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", tunjabCreate, {
            body: query
        }, true)
    }

    fetchYearAvailable = async () => {
        return await apiRequest("get", tunjabYearAvailable, {}, true)
    }

    fetchDataAllEmployeeList = async (query) => {
        return await apiRequest("get", tunjabAllEmployeeList, { params: query }, true)
    }
}

export default CreateRepository