import {
    apiRequest,
    masterReferenceNrsList,
    masterReferenceNrsActivated,
    masterMappingIIPCList,
    masterCmsListPeraturan,
    masterCmsListfaq,
    masterMappingIIPCDelete
} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    
    fetchInactive = async (query, cancelToken) => {
        return await apiRequest("get", masterMappingIIPCDelete, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }


    fetchActive = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsActivated, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableMappingIipc = async (query, cancelToken) => {
        return await apiRequest("get", masterMappingIIPCList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTablePeraturan = async (query, cancelToken) => {
        return await apiRequest("get", masterCmsListPeraturan, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableFaq = async (query, cancelToken) => {
        return await apiRequest("get", masterCmsListfaq, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository