import {
    apiRequest,
    masterPtspHonorCreate,
    masterPtspHonorMasterGrade,
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterPtspHonorCreate, {
            body: query
        }, true)
    }

    fetchGradeList = async () => {
        return await apiRequest("get", masterPtspHonorMasterGrade, {}, true)
    }

}

export default CreateRepository