import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect";
import Select from "react-select"
import classnames from "classnames"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Modal,
    Nav, NavItem, NavLink,
    TabContent, TabPane
} from "reactstrap"

import PtspBkpmIndexListComponent from './Components/PtspBkpmIndexListComponent'
import PtspKLIndexListComponent from './Components/PtspKLIndexListComponent'

import IndexViewBloc from './Blocs/IndexViewBloc'
import apiResponse from '../../../../services/apiResponse'

class IndexView extends Component {

    indexViewBloc = new IndexViewBloc()
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear() - 1 : this.thisDate.getFullYear()

    constructor(props) {
        super(props)
        this.state = {
            showTunkinHistory: false,
            loadingCurrent: true,
            selectedYear: { label: this.thisYear.toString(), value: this.thisYear },
            activeTab: "1",
        }

    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    loadCurrent = async () => {
        await this.indexViewBloc.fetchYears()
    }

    componentDidMount() {
        this.indexViewBloc.yearsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        currentRegulation: response,
                        loadingCurrent: false,
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.loadCurrent()
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState({
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear
                }
            ]
        })
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState({
            selectedYear: selectedYear,
            loadingCurrent: true
        }, () => {
            this.setState({
                loadingCurrent: false
            })
        });
    }

    componentWillUnmount() {
        this.indexViewBloc.yearsChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Honor Tetap</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Proses Honor Tetap berdasarkan tahun dan bulan tertentu sesuai dengan data integrasi (Simpeg) yang sudah di verifikasi
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <div className="d-flex flex-row bd-highlight float-start">
                                                <div className="pt-2 pe-2 pb-2 bd-highlight">
                                                    <div style={{ paddingTop: 8 }}>Tahun</div>
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    {
                                                        this.state.loadingCurrent ?
                                                            <><Shimmer><div className="shimmer" style={{ width: 116, height: 36 }}></div></Shimmer>{" "}</>
                                                            :
                                                            <>
                                                                <div style={{ width: 116 }}>
                                                                    <Select
                                                                        value={this.state.selectedYear}
                                                                        onChange={(selected) => {
                                                                            this.handleOnChangeYear(selected)
                                                                        }}
                                                                        options={this.state.optionYear}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{ paddingTop: 8 }}>
                                                    <div className="bg-success rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Sudah Diverifikasi
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{ paddingTop: 8 }}>
                                                    <div className="bg-warning rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Proses Verifikasi
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{ paddingTop: 8 }}>
                                                    <div className="bg-danger rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Belum Menyampaikan
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: this.state.activeTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            this.toggle("1")
                                                        }}
                                                    >
                                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                        <span className="d-none d-sm-block">BKPM</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: this.state.activeTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            this.toggle("2")
                                                        }}
                                                    >
                                                        <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                                        <span className="d-none d-sm-block">PP KL</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12}>

                                            <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                                                <TabPane tabId="1">
                                                    {
                                                        this.state.loadingCurrent ?
                                                            <>
                                                                <Shimmer><div className="shimmer" style={{ width: '10%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '20%', height: 36 }}></div></Shimmer>

                                                                <Shimmer><div className="shimmer" style={{ width: '10%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '20%', height: 22 }}></div></Shimmer>
                                                            </>
                                                            :
                                                            <>
                                                                <PtspBkpmIndexListComponent year={this.state.selectedYear.value} />
                                                            </>
                                                    }
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    {
                                                        this.state.loadingCurrent ?
                                                            <>
                                                                <Shimmer><div className="shimmer" style={{ width: '10%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '20%', height: 36 }}></div></Shimmer>

                                                                <Shimmer><div className="shimmer" style={{ width: '10%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                                <Shimmer><div className="shimmer" style={{ width: '20%', height: 22 }}></div></Shimmer>
                                                            </>
                                                            :
                                                            <>
                                                                <PtspKLIndexListComponent year={this.state.selectedYear.value} />
                                                            </>
                                                    }
                                                </TabPane>
                                            </TabContent>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView