import {
    apiRequest,
    tunkinT14ApproveSubmit,
    tunkinT14RejectSubmit,
    tunkinT14ResultEmployee,
    tunkinT14CheckStatus,
    tunkinT14ResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", tunkinT14ApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", tunkinT14RejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", tunkinT14ResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tunkinT14ResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tunkinT14CheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository