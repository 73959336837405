import {
    apiRequest,
    tunkinSusulanDetail,
    tunkinSusulanSsp
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", tunkinSusulanDetail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tunkinSusulanSsp, {
            params: query
        }, true)
    }
}

export default DownloadRepository