import { apiRequest, sigapManualList, sigapManualEmployeeList } from "../../../../../../services/adapters/sync"

class IndexViewRepository {
    fetchRowTableManual = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            sigapManualList,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchRowTableEmployee = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            sigapManualEmployeeList,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }
}

export default IndexViewRepository
