import {
    apiRequest,
    tunkinRegulerApproveSubmit,
    tunkinRegulerRejectSubmit,
    tunkinRegulerResultEmployee,
    tunkinRegulerCheckStatus,
    tunkinRegulerResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", tunkinRegulerApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", tunkinRegulerRejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", tunkinRegulerResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tunkinRegulerResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tunkinRegulerCheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository