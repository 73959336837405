import { sumBy } from 'lodash'
import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'

class CreateBloc {

    createChannel = new Subject()
    countryChannel = new Subject()
    checkChannnel  = new Subject()
    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.countryChannel.next({ status: apiResponse.INITIAL })
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {}
    }

    fetchCountryList = async () => {
        this.countryChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCountryList().then((result) => {
                this.countryChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.countryChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {}
    }

    fetchCheck = async (query) => {
        this.checkChannnel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheck(query).then((result) => {
                this.checkChannnel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkChannnel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {}
    }


}

export default CreateBloc