import {
    apiRequest,
    uangMakanRapelEmployeeYears,
    uangMakanRapelEmployeeMonths,
} from "../../../../../../services/adapters/process"

class EmployeeViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", uangMakanRapelEmployeeYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", uangMakanRapelEmployeeMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

}

export default EmployeeViewRepository