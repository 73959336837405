import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
} from "reactstrap"


import TunjabListComponent from './Components/TunjabListComponent'

class IndexView extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="float-end">
                                        {
                                            (accessRole('create')) ?
                                                <Link to="/payroll/tunjab.html?create">
                                                    <Button color="primary"><i className="uil-folder-medical"></i> Tambah Data Baru</Button>
                                                </Link>
                                                : null
                                        }
                                    </div>
                                    <CardTitle>Tunjangan Staff Khusus</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Tunjangan Jabatan Staff Khusus masing-masing pegawai berdasarkan tahun tertentu
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <TunjabListComponent year={2021} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </Fragment>
        )
    }
}

export default IndexView