import {
    apiRequest,
    masterPtspHonorGrade, 
    masterPtspHonorValue,
} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterPtspHonorValue, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowGrade = async (query, cancelToken) => {
        return await apiRequest("get", masterPtspHonorGrade, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

}

export default DataTableRepository