import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import DownloadRepository from '../Repositories/DownloadRepository'


class DownloadBloc {

    stChannel = new Subject();
    detailChannel = new Subject();
    nominativeChannel = new Subject();
    riilChannel = new Subject();
    costChannel = new Subject();
    sptbChannel = new Subject();
    repository = new DownloadRepository()

    constructor() {
        this.stChannel.next({ status: apiResponse.INITIAL })
        this.detailChannel.next({ status: apiResponse.INITIAL })
        this.nominativeChannel.next({ status: apiResponse.INITIAL })
        this.riilChannel.next({ status: apiResponse.INITIAL })
        this.costChannel.next({ status: apiResponse.INITIAL })
        this.sptbChannel.next({ status: apiResponse.INITIAL })
    }

    fetchSt = async (query) => {
        this.stChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchSt(query).then((result) => {
                this.stChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.stChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.detailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.detailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchNominative = async (query) => {
        this.nominativeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchNominative(query).then((result) => {
                this.nominativeChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.nominativeChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchRiil = async (query) => {
        this.riilChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRiil(query).then((result) => {
                this.riilChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.riilChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchCost = async (query) => {
        this.costChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCost(query).then((result) => {
                this.costChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.costChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchSptb = async (query) => {
        this.sptbChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchSptb(query).then((result) => {
                this.sptbChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.sptbChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

}

export default DownloadBloc