import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Modal
} from "reactstrap"

import TableListComponent from './Components/TableListComponent'
import UploadComponent from './Components/UploadComponent'


class IndexView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showTable: true,
            showUpload: false,
            modalSize: 'sm'
        }
    }

    setModalSize = (size) => {
        this.setState({
            modalSize: size
        })
    }

    closeModal = (reload = false) => {
        this.setState({
            showUpload: false
        })
        setTimeout(() => {
            this.setState({
                modalSize: 'sm'
            })
        }, 500);
        if(reload) {
            this.setState({
                showTable: false
            }, function() {
                setTimeout(() => {
                    this.setState({
                        showTable: true
                    })
                }, 100)
            })
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="float-end">
                                        {
                                            (accessRole('create')) ?
                                                <>
                                                    <Button
                                                        color="warning"
                                                        onClick={() => {
                                                            this.setState({
                                                                showUpload: true,
                                                            });
                                                        }}
                                                    >
                                                        <i className="uil-upload"></i>
                                                    </Button>
                                                    {" "}
                                                    <Link to="/master/mapping-nip.html?create">
                                                        <Button color="primary"><i className="uil-folder-medical"></i> Tambah Data Baru</Button>
                                                    </Link>
                                                </>
                                            : null
                                        }
                                    </div>
                                    <CardTitle>Referensi Mapping NIP</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Informasi data perbedaan NIP antara data NIP di GPP dan NIP di Sistem Kepegawaian.
                                    </CardSubtitle>
                                    <hr />
                                    {
                                        this.state.showTable ? <TableListComponent /> : null
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size={this.state.modalSize}
                    isOpen={this.state.showUpload}
                    /* toggle={() => {
                        this.setState({
                            showUpload: !this.state.showUpload,
                        })
                        setTimeout(() => {
                            this.setState({
                                modalSize: 'sm'
                            })
                        }, 500);
                    }} */
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Upload Mapping NIP
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showUpload: false,
                                })
                                setTimeout(() => {
                                    this.setState({
                                        modalSize: 'sm'
                                    })
                                }, 500);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <UploadComponent handleSetModalSize={this.setModalSize} handleCloseModal={this.closeModal} />
                    </div>
                </Modal>
                
            </Fragment>
        )
    }
}

export default IndexView