import {
    apiRequest,
    masterPtspHonorCurrent
} from "../../../../../../services/adapters/master"

class IndexRepository {

    fetchCurrent = async (query, cancelToken) => {
        return await apiRequest("get", masterPtspHonorCurrent, {
            cancelToken: cancelToken,
            params: query
        }, true)       
    }

}

export default IndexRepository