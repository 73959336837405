import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, useRef, useState } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button } from "reactstrap"

import OTTableListComponent from "./Components/OTTableListComponent"

const IndexView = (props) => {
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                    {accessRole("create") ? (
                                        <Link to="/official-travel/finalization.html?create">
                                            <Button color="primary">
                                                <i className="uil-folder-medical"></i> Ajukan Rapel Perjalanan Dinas
                                            </Button>
                                        </Link>
                                    ) : null}
                                </div>
                                <CardTitle>Pengajuan Rapel Perjalanan Dinas</CardTitle>
                                <CardSubtitle className="mb-3">Informasi data Pengajuan Rapel Perjalanan Dinas dari Surat Tugas yang sudah diajukan.</CardSubtitle>
                                <hr />
                                <OTTableListComponent />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView
