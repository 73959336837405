import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'


class CreateBloc {

    employeeChannel = new Subject();
    typeChannel = new Subject();
    createChannel = new Subject();
    repository = new CreateRepository()

    constructor() {
        this.employeeChannel.next({status: apiResponse.INITIAL})
        this.typeChannel.next({status: apiResponse.INITIAL})
        this.createChannel.next({status: apiResponse.INITIAL})
    }

    fetchType = async (query) => {
        this.typeChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchType(query).then((result) => {
                this.typeChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.typeChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchCreate = async (query) => {
        this.createChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.createChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchEmployee = async (query) => {
        this.employeeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployee(query).then((result) => {
                this.employeeChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
}

export default CreateBloc