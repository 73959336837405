import {
    apiRequest,
    tplnT13ApproveSubmit,
    tplnT13RejectSubmit,
    tplnT13ResultEmployee,
    tplnT13CheckStatus,
    tplnT13ResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", tplnT13ApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", tplnT13RejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", tplnT13ResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tplnT13ResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tplnT13CheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository