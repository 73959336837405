import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Link, Redirect } from "react-router-dom"

import { Modal } from "reactstrap"

import MKIDatatable from "../../../../../../modules/mki-datatable/lib"
import { ConfirmAlert } from "../../../../../../helpers/ui/alert"

import { cancelToken } from "../../../../../../services/adapters/base"

import IndexViewBloc from "../Blocs/IndexViewBloc"
import apiResponse from "../../../../../../services/apiResponse"
import UangMakanHistoryComponent from "./UangMakanHistoryComponent"
import UangMakanDownloadComponent from "./UangMakanDownloadComponent"

class UangMakanIndexListComponent extends Component {
    indexViewBloc = new IndexViewBloc()

    source
    historyTable = "historyPayrollUangMakanRapelIndexListComponent"
    defaultOrder = "uangmakan_month"
    defaultSize = 20
    defaultSort = "desc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            showUangMakanHistory: false,
            uangmakanHistoryTitle: null,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            type: this.props.type,
        }
        await this.indexViewBloc.fetchMonths(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {}

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.monthsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.monthsChannel.unsubscribe()
    }

    columns = [
        {
            key: "uangmakan_id",
            text: "ID",
            className: "",
            align: "center",
            width: "100",
            sortable: true,
            cell: (record) => {
                return <>{record.uangmakan_id.toString().padStart(6, "0")}</>
            },
        },
        {
            key: "uangmakan_status",
            text: "Proses",
            className: "",
            sortable: true,
            width: "140",
            align: "center",
            cell: (record) => {
                return (
                    <>
                        {record.uangmakan_status === 2 || record.uangmakan_status === 4 ? (
                            <button
                                className="btn btn-soft-primary btn-sm"
                                onClick={() => {}}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                done
                            </button>
                        ) : (
                            <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                done
                            </button>
                        )}{" "}
                        {record.uangmakan_status === 4 ? (
                            <button
                                className="btn btn-soft-success btn-sm"
                                onClick={() => {}}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                approved
                            </button>
                        ) : (
                            <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                approved
                            </button>
                        )}
                    </>
                )
            },
        },
        {
            key: "uangmakan_year",
            text: "Tahun",
            className: "",
            align: "center",
            width: "120",
            sortable: true,
        },
        {
            key: "uangmakan_month",
            text: "Bulan",
            className: "",
            sortable: true,
            cell: (record) => {
                return <>{record.uangmakan_month_name}</>
            },
        },
        {
            key: "uangmakan_employee_total",
            text: "Total Pegawai",
            className: "",
            align: "center",
            width: "140",
            sortable: true,
        },
        {
            key: "uangmakan_status_employee",
            text: "Simpeg",
            className: "",
            sortable: true,
            width: "100",
            align: "center",
            cell: (record) => {
                var className =
                    (record.uangmakan_status_employee === 1 ? "bg-success" : "bg-danger") +
                    " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "uangmakan_status_presence",
            text: "Absensi",
            className: "",
            sortable: true,
            width: "100",
            align: "center",
            cell: (record) => {
                var className =
                    (record.uangmakan_status_presence === 1 ? "bg-success" : "bg-danger") +
                    " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "uangmakan_uuid",
            text: "Aksi",
            className: "",
            width: "140",
            align: "center",
            cell: (record) => {
                var dataReady =
                    record.uangmakan_status_employee === 1 && record.uangmakan_status_presence === 1
                        ? record.uangmakan_status === 1 || record.uangmakan_status === 3
                            ? true
                            : false
                        : false
                var dataGenerate = record.uangmakan_status === 2 ? true : false
                var dataReject = record.uangmakan_status === 3 ? true : false
                var dataDone = record.uangmakan_status === 4 ? true : false
                return (
                    <>
                        <span>
                            {accessRole("create") ? (
                                <>
                                    {dataReady ? (
                                        <Link
                                            className={"btn btn-" + (dataReject ? "danger" : "primary") + " btn-sm"}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                            to={"/payroll/uang-makan-rapel.html?create&uuid=" + record.uangmakan_uuid}
                                        >
                                            <i className="uil-cog"></i>
                                        </Link>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-cog"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            <button
                                className="btn btn-warning btn-sm"
                                onClick={() => {
                                    this.setState({
                                        showUangMakanHistory: true,
                                        historyUuid: record.uangmakan_uuid,
                                        uangmakanHistoryTitle:
                                            record.uangmakan_month_name + " " + record.uangmakan_year,
                                    })
                                }}
                                style={{
                                    marginRight: "2px",
                                    padding: "2px 5px",
                                }}
                            >
                                <i className="uil-clock-eight"></i>
                            </button>
                            {accessRole("approve") ? (
                                <>
                                    {dataGenerate ? (
                                        <Link
                                            className={"btn btn-success btn-sm"}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                            to={"/payroll/uang-makan-rapel.html?approve&uuid=" + record.uangmakan_uuid}
                                        >
                                            <i className="uil-check"></i>
                                        </Link>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-check"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            {accessRole("print") ? (
                                <>
                                    {dataDone ? (
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => {
                                                this.setState({
                                                    showUangmakanDownload: true,
                                                    downloadUuid: record.uangmakan_uuid,
                                                })
                                            }}
                                            style={{
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <>
                                                <i className="uil-arrow-to-bottom"></i>
                                                {/* <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="text-reset" id="dropdownMenuButton5" caret>
                                                        <i className="uil-arrow-to-bottom"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem
                                                            onClick={() => {
                                                                this.downloadBloc.fetchDownloadDetail({uuid: record.uangmakan_uuid})
                                                            }}
                                                        >
                                                            Detail Isi
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                this.downloadBloc.fetchDownloadSsp({uuid: record.uangmakan_uuid})
                                                            }}
                                                        >
                                                            SSP
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown> */}
                                            </>
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-arrow-to-bottom"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                        </span>
                    </>
                )
            },
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1000}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="md"
                    isOpen={this.state.showUangMakanHistory}
                    toggle={() => {
                        this.setState({
                            showUangMakanHistory: !this.state.showUangMakanHistory,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Riwayat Proses Uang Makan Rapel {this.state.uangmakanHistoryTitle}
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showUangMakanHistory: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <UangMakanHistoryComponent uuid={this.state.historyUuid} />
                    </div>
                </Modal>
                <Modal
                    size="sm"
                    isOpen={this.state.showUangmakanDownload}
                    toggle={() => {
                        this.setState({
                            showUangmakanDownload: !this.state.showUangmakanDownload,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Unduh Berkas Uang Makan
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showUangmakanDownload: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <UangMakanDownloadComponent uuid={this.state.downloadUuid} />
                    </div>
                </Modal>
                <ConfirmAlert
                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"
                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
            </>
        )
    }
}

export default UangMakanIndexListComponent
