import {
    apiRequest,
    payrollSkpCheckData,
    payrollSkpGetSigner,
    payrollSkpDetail,
    payrollSkpProcess
} from "../../../../../../services/adapters/process"

class UpdateRepository {
    fetchCheckTunkin = async (query) => {
        return await apiRequest("get", payrollSkpCheckData, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", payrollSkpGetSigner, {
            params: query
        }, true)
    }

    fetchApprove = async (query) => {
        return await apiRequest("post", payrollSkpProcess, {
            body: query
        }, true)
    }

    fetchDetail = async (query) => {
        return await apiRequest("get", payrollSkpDetail, {
            params: query
        }, true)
    }
}

export default UpdateRepository