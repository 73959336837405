import {
    apiRequest,
    masterTunkinTplnRegulationList,
    masterTunkinTplnRegulationDetail
} from "../../../../../../services/adapters/master"

class RegulationRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterTunkinTplnRegulationList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    
    fetchDetail = async (query) => {
        return await apiRequest("get", masterTunkinTplnRegulationDetail, {
            params: query
        }, true)
    }
}

export default RegulationRepository