import {
    apiRequest,
    masterTaxPtkp2024Create
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterTaxPtkp2024Create, {
            body: query
        }, true)
    }

}

export default CreateRepository