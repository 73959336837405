import {
    apiRequest,
    officialTravelPpkEmployee,
    officialTravelPpkCreate
} from "../../../../../services/adapters/process"

class CreateRepository {

    fetchEmployeeList = async () => {
        return await apiRequest("get", officialTravelPpkEmployee, {}, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", officialTravelPpkCreate, {
            body: query
        }, true)
    }
}

export default CreateRepository