import {
    apiRequest,
    tunkinRegulerVerifyList,
    tunkinRegulerCompareList,
    tunkinRegulerResetRequest
} from "../../../../../../services/adapters/process"

class VerifyRepository {

    fetchVerify = async (query) => {
        return await apiRequest("get", tunkinRegulerVerifyList, {
            params: query
        }, true)
    }
    
    fetchCompare = async (query) => {
        return await apiRequest("get", tunkinRegulerCompareList, {
            params: query
        }, true)
    }

    fetchResetRequest = async (query) => {
        return await apiRequest("post", tunkinRegulerResetRequest, {
            body: query
        }, true)
    }

}

export default VerifyRepository