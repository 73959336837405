import { Subject } from "rxjs"
import apiResponse from "../../../../../../services/apiResponse"
import ApproveViewRepository from "../Repositories/ApproveViewRepository"

class ApproveBloc {
    approveChannel = new Subject()
    rejectChannel = new Subject()
    listResultChannel = new Subject()
    checkStatusChannel = new Subject()
    employeeDetailChannel = new Subject()
    repository = new ApproveViewRepository()

    constructor() {
        this.approveChannel.next({ status: apiResponse.INITIAL })
        this.rejectChannel.next({ status: apiResponse.INITIAL })
        this.listResultChannel.next({ status: apiResponse.INITIAL })
        this.employeeDetailChannel.next({ status: apiResponse.INITIAL })
        this.checkStatusChannel.next({ status: apiResponse.INITIAL })
    }

    fetchApprove = async (query) => {
        this.approveChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchApprove(query)
                .then((result) => {
                    this.approveChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.approveChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {
            console.log("rxjs", "Channel Unsubscribed")
        }
    }

    fetchReject = async (query) => {
        this.rejectChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchReject(query)
                .then((result) => {
                    this.rejectChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.rejectChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {
            console.log("rxjs", "Channel Unsubscribed")
        }
    }

    fetchResultList = async (query, cancelToken) => {
        this.listResultChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchResultList(query, cancelToken)
                .then((result) => {
                    this.listResultChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.listResultChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchEmployeeDetail = async (query) => {
        this.employeeDetailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchEmployeeDetail(query)
                .then((result) => {
                    this.employeeDetailChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.employeeDetailChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {
            console.log("rxjs", "Channel Unsubscribed")
        }
    }

    fetchCheckStatus = async (query) => {
        this.checkStatusChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchCheckStatus(query)
                .then((result) => {
                    this.checkStatusChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.checkStatusChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {
            console.log("rxjs", "Channel Unsubscribed")
        }
    }
}

export default ApproveBloc
