import { accessRole } from "../../../../helpers/access_role"
import ErrorAccess from "../../../../components/Error/Content/Access"

import IndexView from "./Index/View"
import CreateView from "./Create/View"
import ApproveView from "./Approve/View"
import EmployeeView from "./Employee/View"
import VerifyView from "./Verify/View"

const RouteView = (props) => {
    const queryParams = new URLSearchParams(props.location.search)
    const _create = queryParams.get("create")
    const _detail = queryParams.get("detail")
    const _update = queryParams.get("update")
    const _delete = queryParams.get("detail")
    const _approve = queryParams.get("approve")
    const _print = queryParams.get("print")
    const _verify = queryParams.get("verify")

    if (_create != null) {
        const uuid = queryParams.get("uuid")
        return accessRole("create") ? <CreateView uuid={uuid} /> : <ErrorAccess />
    } else if (_detail != null) {
        return null
    } else if (_update != null) {
        return null
    } else if (_delete != null) {
        return null
    } else if (_approve != null) {
        const uuid = queryParams.get("uuid")
        return accessRole("approve") ? <ApproveView uuid={uuid} /> : <ErrorAccess />
    } else if (_print != null) {
        return null
    } else if (_verify != null) {
        const uuid = queryParams.get("uuid")
        return accessRole("create") ? <VerifyView uuid={uuid} /> : <ErrorAccess />
    }

    if (accessRole("id") === "1") {
        return accessRole("read") ? <EmployeeView /> : <ErrorAccess />
    } else {
        return accessRole("read") ? <IndexView /> : <ErrorAccess />
    }
}
export default RouteView
