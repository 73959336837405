import {
    apiRequest as apiRequestOutput,
    uangMakanRegularDetail,
    uangMakanRegularSsp
} from "../../../../../../services/adapters/output"

import {
    apiRequest as apiRequestSimpeg,
    txtUangmakan
} from "../../../../../../services/adapters/simpegsimkeu"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequestOutput("get", uangMakanRegularDetail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequestOutput("get", uangMakanRegularSsp, {
            params: query
        }, true)
    }

    fetchDownloadTxt = async (query) => {
        return await apiRequestSimpeg("get", txtUangmakan, {
            params: query
        }, true)
    }
}

export default DownloadRepository