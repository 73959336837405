import {
    apiRequest,
    overtimeProcessSubmit,
    overtimeProcessEmployees,
    overtimeProcessEmployeeDetail,
    overtimeCheckStatus,
    overtimeGetSigner,
    overtimeCheckPresence,
    overtimeCheckRank,
    overtimeCheckTaxFinal,
    overtimeCheckEmployeeNew,
    overtimeCheckEmployeeChange
} from "../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", overtimeProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", overtimeProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", overtimeProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", overtimeGetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", overtimeCheckStatus, {
            params: query
        }, true)
    }

    fetchCheckPresence = async (query) => {
        return await apiRequest("get", overtimeCheckPresence, {
            params: query
        }, true)
    }

    fetchCheckRank = async (query) => {
        return await apiRequest("get", overtimeCheckRank, {
            params: query
        }, true)
    }

    fetchCheckTaxFinal = async (query) => {
        return await apiRequest("get", overtimeCheckTaxFinal, {
            params: query
        }, true)
    }

    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", overtimeCheckEmployeeNew, {
            params: query
        }, true)
    }

    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", overtimeCheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository