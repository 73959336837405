import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link, Redirect } from "react-router-dom"
import Select from "react-select"
import Shimmer from "react-shimmer-effect"

import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, Label } from "reactstrap"
import * as XLSX from "xlsx"
import Dropzone from "react-dropzone"
import { ConfirmAlert } from "../../../../helpers/ui/alert"
import { FileInput, generateFormData } from "../../../../helpers/ui/FileInput"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import CreateBloc from "./Blocs/CreateBloc"
import apiResponse from "../../../../services/apiResponse"

class CreateView extends Component {
    createBloc = new CreateBloc()
    thisDate = new Date()
    thisYear = this.thisDate.getFullYear() - 1

    constructor(props) {
        super(props)
        this.state = {
            loadingYear: true,
            loadingEmployee: true,
            selectedYear: null,
            allNIP: [],
            fileName: "",
            fileData: [],
            status: 0, //0.Initial, 1.Loading file, 2.Data
            statusData: 0, //0.Empty, 1.Data OK, 2.Data Problem
        }
    }

    loadYear = async () => {
        await this.createBloc.fetchYearAvailable()
    }

    componentDidMount() {
        this.createBloc.yearAvailableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        loadingYear: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.loadYear()

        this.createBloc.allEmployeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    var nips = []
                    if (response.length > 0) {
                        for (var i = 0; i < response.length; ++i) {
                            nips[response[i].employee_nip] = response[i].employee_uuid
                        }
                    }
                    this.setState({
                        loadingEmployee: false,
                        allNIP: nips,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })

        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState(
            {
                selectedYear:
                    itemYear.length > 0 ? itemYear[0] : { label: this.thisYear.toString(), value: this.thisYear },
                optionYear: [
                    {
                        label: "Tahun",
                        options: itemYear,
                    },
                ],
            },
            () => {
                this.resetAllForm()
            }
        )
    }
    handleOnChangeYear = (selectedYear) => {
        this.setState(
            {
                selectedYear: selectedYear,
                loadingEmployee: true,
            },
            () => {
                this.resetAllForm()
            }
        )
    }

    componentWillUnmount() {
        this.createBloc.yearAvailableChannel.unsubscribe()
        this.createBloc.allEmployeeChannel.unsubscribe()
        this.createBloc.createChannel.unsubscribe()
    }

    resetAllForm = () => {
        this.setState({
            status: 0,
            statusData: 0,
            allNIP: [],
            fileName: "",
            fileData: [],
        })
        this.loadEmployee()
    }

    loadEmployee = async () => {
        await this.createBloc.fetchDataAllEmployeeList({ year: this.state.selectedYear.value })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    handlerOnSubmit = (e, values) => {
        var fileDataExist = this.state.fileData
        if (fileDataExist.length > 0) {
            var _uuid = []
            var _bruto = []
            var _tax = []
            for (var i = 0; i < fileDataExist.length; ++i) {
                _uuid[i] = fileDataExist[i].uuid
                _bruto[i] = fileDataExist[i].bruto
                _tax[i] = fileDataExist[i].tax
            }
            this.setState({
                dataForm: {
                    year: this.state.selectedYear.value,
                    uuid: _uuid,
                    bruto: _bruto,
                    tax: _tax,
                },
                confirm: true,
            })
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, { field: "document", name: "Trash/Unknown" })
        await this.createBloc.fetchCreate(formData)
    }

    handleOnSelectFile = (file) => {
        var f = file[0]
        const reader = new FileReader()
        reader.onload = (evt) => {
            const bstr = evt.target.result
            const wb = XLSX.read(bstr, { type: "binary" })
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            const data = XLSX.utils.sheet_to_json(ws, { header: 2 })

            var statusData = 0
            var _fileDatas = []
            if (data.length > 0) {
                var _fileData = {}
                statusData = 1
                for (var i = 0; i < data.length; ++i) {
                    var uuid = this.state.allNIP[data[i].NIP]
                    _fileData = {
                        uuid: uuid,
                        nip: data[i].NIP,
                        name: data[i].Nama,
                        bruto: data[i].Bruto,
                        tax: data[i].Pajak,
                        problem: uuid == null,
                    }
                    if (uuid === null) {
                        statusData = 2
                    }
                    _fileDatas.push(_fileData)
                }
            }
            this.setState({
                status: 2,
                fileData: _fileDatas,
                statusData: statusData, //0.Empty, 1.Data OK, 2.Data Problem
            })
        }
        reader.readAsBinaryString(f)

        this.setState({
            status: 1,
            fileName: file[0].name,
        })
    }

    deleteRow = (key) => {
        var fileDataExist = this.state.fileData
        var statusData = 0
        var _fileDatas = []
        if (fileDataExist.length > 0) {
            var _fileData = {}
            statusData = 1
            for (var i = 0; i < fileDataExist.length; ++i) {
                if (i !== key) {
                    _fileData = fileDataExist[i]
                    if (fileDataExist[i].uuid === null) {
                        statusData = 2
                    }
                    _fileDatas.push(_fileData)
                }
            }
        }
        this.setState({
            fileData: _fileDatas,
            statusData: statusData,
        })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah Tunjangan PTSP</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib
                                        diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Pilih Tahun<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                {this.state.loadingYear ? (
                                                    <>
                                                        <Shimmer>
                                                            <div
                                                                className="shimmer"
                                                                style={{ width: 116, height: 36 }}
                                                            ></div>
                                                        </Shimmer>{" "}
                                                    </>
                                                ) : (
                                                    <>
                                                        <div style={{ width: 116 }}>
                                                            <Select
                                                                value={this.state.selectedYear}
                                                                onChange={(selected) => {
                                                                    this.handleOnChangeYear(selected)
                                                                }}
                                                                options={this.state.optionYear}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="fieldNama" className="col-md-2 col-form-label">
                                                Unggah File<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="10">
                                                {this.state.loadingEmployee ? (
                                                    <>
                                                        <Shimmer>
                                                            <div
                                                                className="shimmer"
                                                                style={{ width: 316, height: 36 }}
                                                            ></div>
                                                        </Shimmer>{" "}
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="bd-highlight float-start">
                                                            <Dropzone
                                                                onDrop={(acceptedFiles) =>
                                                                    this.handleOnSelectFile(acceptedFiles)
                                                                }
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div {...getRootProps()}>
                                                                            <input
                                                                                {...getInputProps()}
                                                                                multiple={false}
                                                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                            />
                                                                            <span
                                                                                className={
                                                                                    "btn " +
                                                                                    (this.state.fileName
                                                                                        ? "btn-danger"
                                                                                        : "btn-secondary") +
                                                                                    " btn-sm"
                                                                                }
                                                                                style={{
                                                                                    padding: "4px 7px",
                                                                                }}
                                                                            >
                                                                                <i className="uil-file"></i>
                                                                            </span>{" "}
                                                                            {this.state.fileName !== ""
                                                                                ? this.state.fileName
                                                                                : "Pilih File Excel"}
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                        <div className="bd-highlight float-end">
                                                            <span
                                                                className={"btn btn-link btn-sm"}
                                                                onClick={() => {
                                                                    window.open(
                                                                        process.env.REACT_APP_SERVICE_PROCESS_URL +
                                                                            "/templates/Template-PTSP-Manual.xlsx",
                                                                        "_blank"
                                                                    )
                                                                }}
                                                                style={{ padding: "2px 0px" }}
                                                            >
                                                                Download Template
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label className="col-md-2 col-form-label">
                                                Data Pegawai <span className="text-danger">*</span>
                                            </Label>
                                            <Col md="10">
                                                <Table className="table table-striped table-bordered table-hover">
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="40px" data-priority="1" className="text-end">
                                                                No.
                                                            </Th>
                                                            <Th width="*" data-priority="1">
                                                                Nama Pegawai
                                                            </Th>
                                                            <Th width="180px" data-priority="1" className="text-end">
                                                                Bruto
                                                            </Th>
                                                            <Th width="180px" data-priority="1" className="text-end">
                                                                Pajak
                                                            </Th>
                                                            <Th width="90px" data-priority="1" className="text-center">
                                                                Aksi
                                                            </Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.status === 0 ? (
                                                            <tr>
                                                                <Td colspan="5" className="text-center">
                                                                    Silahkan pilih file terlebih dahulu
                                                                </Td>
                                                            </tr>
                                                        ) : (
                                                            <>
                                                                {this.state.status === 1 ? (
                                                                    <tr>
                                                                        <Td
                                                                            style={{ paddingTop: 14, paddingBottom: 0 }}
                                                                        >
                                                                            <Shimmer>
                                                                                <div
                                                                                    className="shimmer"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: 15,
                                                                                    }}
                                                                                ></div>
                                                                            </Shimmer>
                                                                        </Td>
                                                                        <Td
                                                                            style={{ paddingTop: 14, paddingBottom: 0 }}
                                                                        >
                                                                            <Shimmer>
                                                                                <div
                                                                                    className="shimmer"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: 15,
                                                                                    }}
                                                                                ></div>
                                                                            </Shimmer>
                                                                        </Td>
                                                                        <Td
                                                                            style={{ paddingTop: 14, paddingBottom: 0 }}
                                                                        >
                                                                            <Shimmer>
                                                                                <div
                                                                                    className="shimmer"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: 15,
                                                                                    }}
                                                                                ></div>
                                                                            </Shimmer>
                                                                        </Td>
                                                                        <Td
                                                                            style={{ paddingTop: 14, paddingBottom: 0 }}
                                                                        >
                                                                            <Shimmer>
                                                                                <div
                                                                                    className="shimmer"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: 15,
                                                                                    }}
                                                                                ></div>
                                                                            </Shimmer>
                                                                        </Td>
                                                                        <Td className="text-center">
                                                                            <Shimmer>
                                                                                <div
                                                                                    className="shimmer"
                                                                                    style={{ width: 22, height: 22 }}
                                                                                ></div>
                                                                            </Shimmer>
                                                                        </Td>
                                                                    </tr>
                                                                ) : (
                                                                    <>
                                                                        {this.state.statusData === 0 ? (
                                                                            <tr>
                                                                                <Td colspan="5" className="text-center">
                                                                                    Data pegawai PTSP tidak ditemukan
                                                                                </Td>
                                                                            </tr>
                                                                        ) : (
                                                                            <>
                                                                                {Object.entries(
                                                                                    this.state.fileData
                                                                                ).map((item, key) => {
                                                                                    var color = item[1].problem
                                                                                        ? "#dd0000"
                                                                                        : "#495057"
                                                                                    var weight = item[1].problem
                                                                                        ? "bold"
                                                                                        : "normal"
                                                                                    return (
                                                                                        <tr key={key}>
                                                                                            <td
                                                                                                style={{
                                                                                                    color: color,
                                                                                                    fontWeight: weight,
                                                                                                }}
                                                                                                className="text-end"
                                                                                            >
                                                                                                {key + 1}
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    color: color,
                                                                                                    fontWeight: weight,
                                                                                                }}
                                                                                            >
                                                                                                {item[1].name}
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    color: color,
                                                                                                    fontWeight: weight,
                                                                                                }}
                                                                                                className="text-end"
                                                                                            >
                                                                                                {item[1].bruto
                                                                                                    .toString()
                                                                                                    .replace(
                                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                                        "."
                                                                                                    )}
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    color: color,
                                                                                                    fontWeight: weight,
                                                                                                }}
                                                                                                className="text-end"
                                                                                            >
                                                                                                {item[1].tax
                                                                                                    .toString()
                                                                                                    .replace(
                                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                                        "."
                                                                                                    )}
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    color: color,
                                                                                                    fontWeight: weight,
                                                                                                }}
                                                                                                className="text-center"
                                                                                            >
                                                                                                {item[1].problem ? (
                                                                                                    <span
                                                                                                        className="btn btn-danger btn-sm"
                                                                                                        style={{
                                                                                                            padding:
                                                                                                                "2px 5px",
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            this.deleteRow(
                                                                                                                key
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="uil-trash-alt"></i>
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </Tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup
                                                        name="checkboxExample"
                                                        required
                                                        errorMessage="Agreement harus di centang"
                                                    >
                                                        <AvCheckbox
                                                            label="Saya yakin dan bertanggung jawab atas data ini"
                                                            value="1"
                                                        />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <Button
                                                    color="success"
                                                    type="submit"
                                                    disabled={this.state.statusData !== 1}
                                                >
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>{" "}
                                                <Link to="/payroll/krko.html">
                                                    <Button color="danger" type="button">
                                                        <i className="uil-arrow-left"></i> Kembali
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"
                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"
                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
                {this.state.redirect ? <Redirect to={"/payroll/krko.html"} /> : null}
            </Fragment>
        )
    }
}

export default CreateView
