import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link, Redirect } from "react-router-dom"
import Select from "react-select"
import Shimmer from "react-shimmer-effect";

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Label,
} from "reactstrap"
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import CreateBloc from './Blocs/CreateBloc'
import apiResponse from '../../../../services/apiResponse'

class CreateView extends Component {

    createBloc = new CreateBloc();
    thisDate = new Date()
    thisYear = this.thisDate.getFullYear() - 1

    constructor(props) {
        super(props)
        this.state = {
            loadingYear: true,
            loadingEmployee: true,
            selectedYear: null,
            selectedEmployee: null,
            dataList: [],
            dataValue: []
        }
    }

    loadYear = async () => {
        await this.createBloc.fetchYearAvailable()
    }

    loadEmployee = async () => {
        await this.createBloc.fetchDataAllEmployeeList({ year: this.state.selectedYear.value })
    }

    componentDidMount() {
        this.createBloc.yearAvailableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        loadingYear: false,
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.loadYear()

        this.createBloc.allEmployeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionEmployee(response)
                    this.setState({
                        loadingEmployee: false,
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState({
            selectedYear: itemYear.length > 0 ? itemYear[0] : { label: this.thisYear.toString(), value: this.thisYear },
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear
                }
            ]
        }, () => {
            this.resetAllForm()
        });
    }

    setOptionEmployee = (list) => {
        const itemEmployee = []
        list.map((employee) => {
            itemEmployee.push(employee)
            return null
        })
        this.setState({
            selectedEmployee: null,
            optionEmployee: itemEmployee
            /* [
                {
                    label: "Staff Khusus",
                    options: itemEmployee
                }
            ] */
        }, () => {
            this.addNewRow()
        });
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState({
            selectedYear: selectedYear,
            loadingEmployee: true
        }, () => {
            this.resetAllForm()
        });
    }
    handleOnChangeEmployee = (key, selected) => {
        var _dataValue = this.state.dataValue
        _dataValue[key] = { employee: selected, value: _dataValue[key].value }

        this.setState({
            dataValue: _dataValue,
        });
    }

    componentWillUnmount() {
        this.createBloc.yearAvailableChannel.unsubscribe()
        this.createBloc.allEmployeeChannel.unsubscribe()
        this.createBloc.createChannel.unsubscribe()
    }

    resetAllForm = () => {
        this.setState({
            dataList: [],
            dataValue: []
        })
        this.loadEmployee()
    }

    addNewRow = () => {
        var _dataList = this.state.dataList
        var _dataValue = this.state.dataValue
        var allowAdd = true;
        _dataValue.forEach((item) => {
            if (item.employee === null || item.value === '' || item.value === 0) {
                allowAdd = false;
            }
        })
        if (allowAdd) {
            _dataList.push(this.state.optionEmployee)
            _dataValue.push({ employee: null, value: 0 })
            this.setState({
                dataList: _dataList,
                dataValue: _dataValue
            })
        }
    }

    deleteRow(key) {
        var _dataList = this.state.dataList
        var _dataValue = this.state.dataValue
        delete _dataList[key]
        delete _dataValue[key]
        var __dataList = [];
        _dataList.forEach(element => {
            __dataList.push(element)
        });
        var __dataValue = [];
        _dataValue.forEach(element => {
            __dataValue.push(element)
        });
        this.setState({
            dataList: __dataList,
            dataValue: __dataValue
        }, () => {
            if (this.state.dataList.length === 0) {
                this.addNewRow()
            }
        })
    }

    handleKeyPress = (event, key) => {
        var last = event.target.value
        if (last.length >= 10) { event.preventDefault(); }
        if (event.which !== 44) {
            if (event.which !== 8 && isNaN(String.fromCharCode(event.which))) {
                event.preventDefault();
            }
        }
    }
    handleKeyUp = (event, key) => {
        const _this = document.querySelector('#tunjab_item_' + key)
        var value = event.target.value
        value = value.charAt(0) === '0' ? value.substring(1) : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")

        var _dataValue = this.state.dataValue
        _dataValue[key] = { employee: _dataValue[key].employee, value: _this.value }
        this.setState({
            dataValue: _dataValue
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    handlerOnSubmit = (e, values) => {
        var _dataValue = this.state.dataValue
        var _employee = []
        var _value = []
        _dataValue.forEach((item) => {
            _employee.push(item.employee.value)
            _value.push(item.value)
        })
        this.setState({
            dataForm: {
                year: this.state.selectedYear.value,
                employee: _employee,
                value: _value
            },
            confirm: true
        })
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, { field: 'document', name: 'Trash/Unknown' })
        await this.createBloc.fetchCreate(formData)
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah Tunjangan Staff Khusus</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-2 col-form-label"
                                            >
                                                Pilih Tahun<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                {
                                                    this.state.loadingYear ?
                                                        <><Shimmer><div className="shimmer" style={{ width: 116, height: 36 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <>
                                                            <div style={{ width: 116 }}>
                                                                <Select
                                                                    value={this.state.selectedYear}
                                                                    onChange={(selected) => {
                                                                        this.handleOnChangeYear(selected)
                                                                    }}
                                                                    options={this.state.optionYear}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Data Pegawai <span className="text-danger">*</span>
                                            </Label>
                                            <Col md="10">
                                                <Table
                                                    className="table table-striped table-bordered table-hover"
                                                >
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="*" data-priority="1">Nama Pegawai</Th>
                                                            <Th width="180px" data-priority="1">Nilai</Th>
                                                            <Th width="90px" data-priority="1" class="text-center">Aksi</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            this.state.loadingYear ?
                                                                <tr>
                                                                    <Td>
                                                                        <div style={{ maxWidth: 420 }}>
                                                                            <Select
                                                                                value={{ label: 'Pilih Pegawai', value: '' }}
                                                                                isDisabled={true}
                                                                                options={[]}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Td>
                                                                    <Td>
                                                                        <AvInput
                                                                            name="nothing"
                                                                            placeholder="0"
                                                                            align="right"
                                                                            disabled={true}
                                                                            type="text"
                                                                            className="form-control text-end"
                                                                        />
                                                                    </Td>
                                                                    <Td className="text-center">
                                                                        <div
                                                                            className="btn btn-danger"
                                                                            disabled={true}
                                                                            onClick={() => {
                                                                                //this.downloadSigned(record.spt_detail_file_path)
                                                                            }}
                                                                        >
                                                                            <i className="uil-trash-alt"></i>
                                                                        </div>
                                                                    </Td>
                                                                </tr>
                                                                : <>
                                                                    {
                                                                        this.state.loadingEmployee ?
                                                                            <tr>
                                                                                <Td>
                                                                                    <div style={{ maxWidth: 420 }}>
                                                                                        <Shimmer><div className="shimmer" style={{ width: '100%', height: 38 }}></div></Shimmer>
                                                                                    </div>
                                                                                </Td>
                                                                                <Td>
                                                                                    <Shimmer><div className="shimmer" style={{ width: '100%', height: 38 }}></div></Shimmer>
                                                                                </Td>
                                                                                <Td className="text-center">
                                                                                    <Shimmer><div className="shimmer" style={{ width: 38, height: 38 }}></div></Shimmer>
                                                                                </Td>
                                                                            </tr>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    Object.entries(this.state.dataList).map((item, key) => {
                                                                                        return (
                                                                                            <tr key={key}>
                                                                                                <td>
                                                                                                    <div style={{ maxWidth: 420 }}>
                                                                                                        <CustomSelect
                                                                                                            name={"tunjab_employee_" + key}
                                                                                                            value={this.state.dataValue[key].employee}
                                                                                                            errorMessage="Pilih Pegawai"
                                                                                                            options={item[1]}
                                                                                                            placeholder="Pilih Pegawai"
                                                                                                            optionLabel="label"
                                                                                                            optionValue="value"
                                                                                                            validate={{ required: { value: true } }}
                                                                                                            onChange={(selected) => {
                                                                                                                this.handleOnChangeEmployee(key, selected)
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <AvInput
                                                                                                        name={"tunjab_item_" + key}
                                                                                                        placeholder="0"
                                                                                                        align="right"
                                                                                                        type="text"
                                                                                                        value={this.state.dataValue[key].value}
                                                                                                        className="form-control text-end"
                                                                                                        id={"tunjab_item_" + key}
                                                                                                        validate={{ required: { value: true } }}
                                                                                                        onKeyPress={(e) => { this.handleKeyPress(e, key) }}
                                                                                                        onKeyUp={(e) => { this.handleKeyUp(e, key) }}
                                                                                                    />
                                                                                                </td>
                                                                                                <Td className="text-center">
                                                                                                    <div
                                                                                                        className="btn btn-danger"
                                                                                                        disabled={this.state.loadingEmployee}
                                                                                                        onClick={() => {
                                                                                                            this.deleteRow(key)
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="uil-trash-alt"></i>
                                                                                                    </div>
                                                                                                </Td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                    }
                                                                </>
                                                        }
                                                    </Tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colspan="2"></td>
                                                            <Td className="text-center">
                                                                <Button
                                                                    type="button"
                                                                    className="btn btn-success"
                                                                    disabled={this.state.loadingEmployee}
                                                                    onClick={() => {
                                                                        this.addNewRow()
                                                                    }}
                                                                >
                                                                    <i className="uil-plus"></i>
                                                                </Button>
                                                            </Td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/payroll/tunjab.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                {this.state.redirect ?
                    <Redirect to={'/payroll/tunjab.html'} />
                    : null}
            </Fragment>
        )
    }
}

export default CreateView