import {
    apiRequest,
    officialTravelSubmissionList
} from "../../../../../../services/adapters/process"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelSubmissionList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository