import {
    apiRequest,
    uangMakanRegularYears,
    uangMakanRegularMonths,
    uangMakanRegularHistory,
    uangmakanRegulerPushSPM,
    uangmakanRegulerUpdateSPM,
    uangmakanRegulerResetApprove,
    uangmakanRegulerResetReject,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", uangMakanRegularYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", uangMakanRegularMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", uangMakanRegularHistory, {
            params: query
        }, true)
    }

    fetchPushSPM = async (query) => {
        return await apiRequest("post", uangmakanRegulerPushSPM, {
            body: query
        }, true)
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest("post", uangmakanRegulerUpdateSPM, {
            body: query
        }, true)
    }

    fetchResetApprove = async (query) => {
        return await apiRequest("post", uangmakanRegulerResetApprove, {
            body: query
        }, true)
    }

    fetchResetReject = async (query) => {
        return await apiRequest("post", uangmakanRegulerResetReject, {
            body: query
        }, true)
    }
}

export default IndexViewRepository