import {
    apiRequest,
    sigapSalaryUpload,
} from "../../../../../../services/adapters/sync"

class CreateViewRepository {

    fetchUpload = async (query) => {
        return await apiRequest("post", sigapSalaryUpload, {
            body: query
        }, true)
    }
}

export default CreateViewRepository