import {
    apiRequest,
    officialTravelMonitoring
} from "../../../../../../services/adapters/output"

class DownloadRepository {


    fetchMonitoring = async (query) => {
        return await apiRequest("get", officialTravelMonitoring, {
            params: query
        }, true)
    }
}

export default DownloadRepository