import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";
import Select from "react-select"
import apiResponse from '../../../../../services/apiResponse'

import UpdateBloc from './Blocs/UpdateBloc'

var reformatCode = function (regions) {
    return regions.map(function (region) {
        var newObj = {};
        newObj["label"] = region.nrs_code;
        newObj["value"] = region.nrs_code;
        return newObj;
    });
};


class IndexView extends Component {

    updateBloc = new UpdateBloc();

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            messageError: "",
            nrs_option: [],
            nrs_code: ''
        }
    }

    onChangeNrsCode = (type) => {
        this.setState({
            nrs_code: type.value,
        });
    }

    submitData = async () => {
        await this.updateBloc.fetchUpdate(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.nrs_employee_nrs_uuid,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            dataForm: response,
                            nrs_code: response.nrs_employee_nrs_code
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.updateBloc.listNrsChannel.subscribe((result) => {
            if (result.data) {
                if (result.data.response) {
                    let rows = result.data.response
                    this.setState({
                        nrs_option: reformatCode(rows)
                    })
                }
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)
        this.updateBloc.fetchDetail({ uuid: queryParams.get('uuid') })
        this.updateBloc.fetchNrs()
    }

    componentWillUnmount() {
        this.updateBloc.updateChannel?.unsubscribe()
        this.updateBloc.listNrsChannel?.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            
                            { this.state.messageError ? <>
                                <div className="alert alert-danger">
                                    <strong>{ this.state.messageError }</strong>
                                </div>
                            </> : <></> }

                            <Card>
                                <CardBody>
                                    <CardTitle>Edit Mapping NRS</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >

                                      
                                        
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode NRS<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <Select
                                                    value={this.state.nrs_option.filter(option =>
                                                        option.value === this.state.nrs_code)}
                                                    onChange={this.onChangeNrsCode}
                                                    options={this.state.nrs_option}
                                                    classNamePrefix=""
                                                    placeholder="Pilih Kode NRS"
                                                />
                                                <AvField
                                                    name="nrs_employee_nrs_code"
                                                    id="nrs_employee_nrs_code"
                                                    type="hidden"
                                                    className="form-control"
                                                    value={this.state.nrs_code}
                                                />
                                            </Col>
                                        </Row>
                                       

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode KPPN<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_kppn_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode KPPN"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_kppn_code"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.dataForm.nrs_employee_nrs_kppn_code}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Tipe Supplier
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_supplier_type_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Tipe Supplier"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_supplier_type_code"
                                                    validate={{ required: { value: false } }}
                                                    value={this.state.dataForm.nrs_employee_nrs_supplier_type_code}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode Pos
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_postal_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode Pos"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_postal_code"
                                                    validate={{ required: { value: false } }}
                                                    value={this.state.dataForm.nrs_employee_nrs_postal_code}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                NPWP Supplier
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_supplier_npwp"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan NPWP Supplier"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_supplier_npwp"
                                                    validate={{ required: { value: false } }}
                                                    value={this.state.dataForm.nrs_employee_nrs_supplier_npwp}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama Supplier
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_supplier_name"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Nama Supplier"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_supplier_name"
                                                    validate={{ required: { value: false } }}
                                                    value={this.state.dataForm.nrs_employee_nrs_supplier_name}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Bank Supplier
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_supplier_bank"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Bank Supplier"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_supplier_bank"
                                                    validate={{ required: { value: false } }}
                                                    value={this.state.dataForm.nrs_employee_nrs_supplier_bank}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode NAB
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_nab_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode NAB"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_nab_code"
                                                    validate={{ required: { value: false } }}
                                                    value={this.state.dataForm.nrs_employee_nrs_nab_code}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode Valas
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_currency_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode Valas"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_currency_code"
                                                    validate={{ required: { value: false } }}
                                                    value={this.state.dataForm.nrs_employee_nrs_currency_code}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode Swift/IBAN
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_swift_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode Swift/IBAN"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_swift_code"
                                                    validate={{ required: { value: false } }}
                                                    value={this.state.dataForm.nrs_employee_nrs_swift_code}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                NIP Penerima<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_recipient_nip"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan NIP Penerima"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_recipient_nip"
                                                    validate={{ required: { value: true } }}
                                                    readOnly={true}
                                                    value={this.state.dataForm.nrs_employee_nrs_recipient_nip}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama Penerima<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_recipient_name"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Nama Penerima"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_recipient_name"
                                                    validate={{ required: { value: true } }}
                                                    readOnly={true}
                                                    value={this.state.dataForm.nrs_employee_nrs_recipient_name}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                NPWP Penerima<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_recipient_npwp"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan NPWP Penerima"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_recipient_npwp"
                                                    validate={{ required: { value: true } }}
                                                    readOnly={true}
                                                    value={this.state.dataForm.nrs_employee_nrs_recipient_npwp}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                No Rekening Penerima<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_account_number"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan No Rekening Penerima"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_account_number"
                                                    validate={{ required: { value: true } }}
                                                    readOnly={true}
                                                    value={this.state.dataForm.nrs_employee_nrs_account_number}
                                                />
                                            </Col>
                                        </Row>               
                                      
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <Link to="/master/mapping-nrs.html">
                                                    <Button color="danger"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                {this.state.redirect ?
                    <Redirect to='/master/mapping-nrs.html' />
                    : null}
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);