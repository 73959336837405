import { apiWrapper, apiCancel } from "../wrapper"

export const initial = "sync"
const baseUrl = process.env.REACT_APP_SERVICE_SYNC_URL
const appCode = process.env.REACT_APP_SERVICE_SYNC_APPCODE
const outputType = "json"

export const simpegEmployeeYears = "simpeg/employee/years/"
export const simpegEmployeeMonth = "simpeg/employee/months/"
export const simpegEmployeeDetail = "simpeg/employee/detail/"
export const simpegEmployeeChange = "simpeg/employee/change/"

export const simpegPresenceYears = "simpeg/presence/years/"
export const simpegPresenceMonth = "simpeg/presence/months/"

export const simpegDocumentYears = "simpeg/dokumen/years/"
export const simpegDocumentMonth = "simpeg/dokumen/months/"
export const simpegDocumentMonthBerubah = "simpeg/dokumen/months_perubahan/"
export const simpegDocumentMonthKeluar = "simpeg/dokumen/months_keluar/"
export const simpegDocumentDetail = "simpeg/dokumen/detail/"
export const simpegDocumentChange = "simpeg/dokumen/change/"
export const simpegDocumentDeparture = "simpeg/dokumen/departure_document/"
export const simpegDocumentArrival = "simpeg/dokumen/arrival_document/"
export const simpegDocumentReturn = "simpeg/dokumen/return_document/"
export const simpegDocumentDecree = "simpeg/dokumen/decree_document/"
export const simpegDocument = "simpeg/dokumen/getDocument/"
export const simpegDocumentDecreeAll = "simpeg/dokumen/decree_document_all/"
export const simpegReadFlag = "simpeg/dokumen/markAsRead/"
export const simpegDocumentProcess = "simpeg/dokumen/processDokumen/"

export const sigapSalaryYears = "gpp/salary/years/"
export const sigapSalaryMonth = "gpp/salary/months/"
export const sigapSalaryDetail = "gpp/salary/detail/"
export const sigapSalaryUpload = "gpp/salary/upload/"
export const sigapSalaryRefList = "gpp/salary/reflist/"
export const sigapSalaryRefDetail = "gpp/salary/refdetail/"
export const sigapFamilyList = "gpp/family/list/"
export const sigapFamilyDetail = "gpp/family/detail/"
export const sigapManualList = "gpp/manual/lists/"
export const sigapManualEmployeeList = "gpp/manual/listEmployee/"
export const sigapManualCreate = "gpp/manual/create/"

export const simapanTrackingYears = "simapan/tracking/years/"
export const simapanTrackingMonth = "simapan/tracking/months/"

export const simapanTokenLists = "simapan/tracking/tokenlist/"
export const simapanTokenYearAvailable = "simapan/tracking/yearAvailable/"
export const simapanTokenCreate = "simapan/tracking/create/"
export const simapanTokenDelete = "simapan/tracking/inactive/"

export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (
    method,
    route,
    data = { params: null, body: null, cancelToken: null },
    needToken = true
) => {
    if (needToken) {
        const thisTime = Math.floor(Date.now() / 1000)
        const expire = localStorage.getItem(initial + "accessExpired")
        if (expire < thisTime) {
            await apiWrapper(baseUrl, initial, appCode, outputType, "post", "auth/getAccessToken/", data)
                .then((result) => {
                    localStorage.setItem(initial + "accessToken", result.response.access_token)
                    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
                })
                .catch((result) => {
                    return Promise.reject("Tidak dapat mengambil accessToken")
                })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}
