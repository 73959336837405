import React, { Fragment, useState, useRef } from "react"
import classnames from "classnames"
import DataTableIIPCComponent from './Components/DataTableIIPCComponent'
import DataTableCutiBesarComponent from './Components/DataTableCutiBesarComponent'
import DataTableTugasBelajarComponent from './Components/DataTableTugasBelajarComponent'
import DataTableCLTNComponent from './Components/DataTableCLTNComponent'

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from "reactstrap"


const IndexView = (props) => {

    const active_tab = localStorage.getItem('active-tab-umum')

    const [activeTab, setactiveTab] = useState(active_tab ? active_tab : "1")
    const activeRef = useRef();
    const inactiveRef = useRef();

    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()
    const reloadActiveRef = () => inactiveRef.current.setTokenAPI()

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
        localStorage.setItem("active-tab-umum", tab)
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Monitoring</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Daftar Monitoring Simpeg
                                </CardSubtitle>
                                <hr />
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">IIPC</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Cuti Besar (CB)</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "3",
                                            })}
                                            onClick={() => {
                                                toggle("3")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Tugas Belajar (TB)</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "4",
                                            })}
                                            onClick={() => {
                                                toggle("4")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Cuti di Luar Tanggungan Negara (CLTN) </span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <DataTableIIPCComponent />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <DataTableCutiBesarComponent />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <DataTableTugasBelajarComponent />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <DataTableCLTNComponent />
                                    </TabPane>
                                    <TabPane tabId="5">
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView