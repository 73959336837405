import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"

import { Tooltip, Modal, Row, Col, Label, Button } from "reactstrap"

import MKIDatatable from "../../../../../../modules/mki-datatable/lib"
import { AvForm, AvInput, AvField, AvGroup, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { ConfirmAlert } from "../../../../../../helpers/ui/alert"
import { FileInput, generateFormData } from "../../../../../../helpers/ui/FileInput"
import { cancelToken } from "../../../../../../services/adapters/base"

import IndexViewBloc from "../Blocs/IndexViewBloc"
import apiResponse from "../../../../../../services/apiResponse"
import TunsusHistoryComponent from "./TunsusHistoryComponent"
import TunsusDownloadComponent from "./TunsusDownloadComponent"

class TunsusIndexListComponent extends Component {
    indexViewBloc = new IndexViewBloc()

    source
    historyTable = "historyPayrollTunsusRegularIndexListComponent"
    defaultOrder = "tunsus_month"
    defaultSize = 20
    defaultSort = "desc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            showApproveReset: false,

            showTunsusHistory: false,
            tunsusHistoryTitle: null,
            tunsusHistorySubTitle: null,
            showTunsusSPM: false,
            tunsusUuid: null,
            tunsusSPMNumber: null,
            tunsusSPMStatus: 0,
            tunsusSPMRows: [],
            stateTunsusSPMEdit: false,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            year: this.props.year,
        }
        await this.indexViewBloc.fetchMonths(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitSPM()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    submitSPM = async () => {
        const formData = generateFormData(this.state.dataForm)
        await this.indexViewBloc.fetchPushSPM(formData)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        var newSpm = ""
        Object.keys(values.spm).map(function (key) {
            newSpm += (newSpm !== "" ? "," : "") + values.spm[key]
        })
        values.spm = newSpm
        values.spmShow = values.spmShow[0] !== undefined ? 1 : 0
        this.setState({
            dataForm: {
                ...values,
            },
            confirm: true,
        })
    }

    spmAddRow = () => {
        var allowAdd = true
        let newTunsusSPMRows = this.state.tunsusSPMRows
        newTunsusSPMRows.map((id, index) => {
            if (newTunsusSPMRows[index][1] === "") {
                allowAdd = false
            }
        })
        if (allowAdd) {
            var id = this.spmId(5)
            this.setState({
                tunsusSPMRows: [...this.state.tunsusSPMRows, [id, ""]],
            })
        }
    }

    spmRemoveRow = (i) => {
        let newTunsusSPMRows = this.state.tunsusSPMRows
        newTunsusSPMRows.splice(i, 1)
        this.setState({ tunsusSPMRows: newTunsusSPMRows }, () => {
            if (this.state.tunsusSPMRows.length === 0) {
                this.spmAddRow()
            }
        })
    }

    spmHandleChange = (i, e) => {
        let newTunsusSPMRows = this.state.tunsusSPMRows
        newTunsusSPMRows[i] = [newTunsusSPMRows[i][0], e.target.value]
        this.setState({ tunsusSPMRows: newTunsusSPMRows })
    }

    spmId = (length) => {
        var result = ""
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
        var charactersLength = characters.length
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
    }

    submitDoneSPM = async () => {
        var formData = new FormData()
        formData.append("uuid", this.state.tunsusUuid)
        //formData.append('spmShow', 1)
        await this.indexViewBloc.fetchUpdateSPM(formData)
    }

    confirmDoneResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    doneConfirm: false,
                }
                break
            case "confirm":
                forState = {
                    doneLoading: true,
                    doneConfirm: false,
                }
                this.submitDoneSPM()
                break
            case "success":
                forState = {
                    doneSuccess: false,
                }
                break
            case "failed":
                forState = {
                    doneFailed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    handlerDoneSubmit = () => {
        this.setState({
            doneConfirm: true,
        })
    }

    submitResetApprove = async () => {
        var formData = new FormData()
        formData.append("uuid", this.state.tunsusUuid)
        await this.indexViewBloc.fetchResetApprove(formData)
    }

    confirmResetApproveResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    resetApproveConfirm: false,
                }
                break
            case "confirm":
                forState = {
                    resetApproveLoading: true,
                    resetApproveConfirm: false,
                }
                this.submitResetApprove()
                break
            case "success":
                forState = {
                    resetApproveSuccess: false,
                }
                break
            case "failed":
                forState = {
                    resetApproveFailed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    handlerResetApprove = () => {
        this.setState({
            resetApproveConfirm: true,
        })
    }

    submitResetReject = async () => {
        var formData = new FormData()
        formData.append("uuid", this.state.tunsusUuid)
        formData.append("type", "1")
        await this.indexViewBloc.fetchResetReject(formData)
    }

    confirmResetRejectResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    resetRejectConfirm: false,
                }
                break
            case "confirm":
                forState = {
                    resetRejectLoading: true,
                    resetRejectConfirm: false,
                }
                this.submitResetReject()
                break
            case "success":
                forState = {
                    resetRejectSuccess: false,
                }
                break
            case "failed":
                forState = {
                    resetRejectFailed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    handlerResetReject = () => {
        this.setState({
            resetRejectConfirm: true,
        })
    }

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.monthsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.spmChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        showTunsusSPM: false,
                    })
                    this.setTokenAPI()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.spmUpdateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        doneLoading: false,
                        doneSuccess: true,
                        showTunsusSPM: false,
                    })
                    this.setTokenAPI()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        doneFailed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.resetApproveChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        resetApproveLoading: false,
                        resetApproveSuccess: true,
                    })
                    this.setTokenAPI()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        resetApproveFailed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.resetRejectChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        resetRejectLoading: false,
                        resetRejectSuccess: true,
                    })
                    this.setTokenAPI()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        resetRejectFailed: true,
                    })
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.monthsChannel.unsubscribe()
        this.indexViewBloc.spmChannel.unsubscribe()
        this.indexViewBloc.spmUpdateChannel.unsubscribe()
        this.indexViewBloc.resetApproveChannel.unsubscribe()
        this.indexViewBloc.resetRejectChannel.unsubscribe()
    }

    columns = [
        {
            key: "tunsus_status",
            text: "Proses",
            className: "",
            sortable: true,
            width: "220",
            align: "center",
            cell: (record) => {
                return (
                    <>
                        {record.tunsus_status === 5 ? (
                            <button
                                className="btn btn-soft-danger btn-sm"
                                onClick={() => {}}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                Permintaan Reset
                            </button>
                        ) : (
                            <>
                                {record.tunsus_status === 2 || record.tunsus_status === 4 ? (
                                    <button
                                        className="btn btn-soft-primary btn-sm"
                                        onClick={() => {}}
                                        style={{
                                            padding: "2px 5px",
                                            borderRadius: 16,
                                        }}
                                    >
                                        done
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-soft-secondary btn-sm"
                                        disabled={true}
                                        style={{
                                            padding: "2px 5px",
                                            borderRadius: 16,
                                        }}
                                    >
                                        done
                                    </button>
                                )}{" "}
                                {record.tunsus_status === 3 ? (
                                    <button
                                        className="btn btn-soft-danger btn-sm"
                                        onClick={() => {}}
                                        style={{
                                            padding: "2px 5px",
                                            borderRadius: 16,
                                        }}
                                    >
                                        review
                                    </button>
                                ) : (
                                    <>
                                        {record.tunsus_status === 4 ? (
                                            <button
                                                className="btn btn-soft-success btn-sm"
                                                onClick={() => {}}
                                                style={{
                                                    padding: "2px 5px",
                                                    borderRadius: 16,
                                                }}
                                            >
                                                approved
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-soft-secondary btn-sm"
                                                disabled={true}
                                                style={{
                                                    padding: "2px 5px",
                                                    borderRadius: 16,
                                                }}
                                            >
                                                approval
                                            </button>
                                        )}
                                    </>
                                )}{" "}
                                {record.tunsus_spm_number !== null ? (
                                    <>
                                        {record.tunsus_spm_status === 2 ? (
                                            <button
                                                className="btn btn-success btn-sm"
                                                disabled={true}
                                                onClick={() => {}}
                                                style={{
                                                    padding: "2px 5px",
                                                    borderRadius: 16,
                                                }}
                                            >
                                                SPM
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-soft-success btn-sm"
                                                disabled={true}
                                                onClick={() => {}}
                                                style={{
                                                    padding: "2px 5px",
                                                    borderRadius: 16,
                                                }}
                                            >
                                                SPM
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <button
                                        className="btn btn-soft-secondary btn-sm"
                                        disabled={true}
                                        style={{
                                            padding: "2px 5px",
                                            borderRadius: 16,
                                        }}
                                    >
                                        SPM
                                    </button>
                                )}
                            </>
                        )}
                    </>
                )
            },
        },
        {
            key: "tunsus_month",
            text: "Bulan",
            className: "",
            sortable: true,
            cell: (record) => {
                const thisId = record.tunsus_id
                return (
                    <>
                        {record.tunsus_month_name}
                        {/*  &nbsp; <span style={{padding: '2px 6px', background: '#eeeeee', fontSize: 9, borderRadius: 4}}>{record.tunsus_date_name}</span> */}
                    </>
                )
            },
        },
        {
            key: "tunsus_date_name",
            text: "Periode",
            className: "",
            align: "center",
            width: "240",
            sortable: true,
        },
        {
            key: "tunsus_employee_total",
            text: "Total Pegawai",
            className: "",
            align: "center",
            width: "140",
            sortable: true,
        },
        {
            key: "tunsus_status_gpp",
            text: "GPP",
            className: "",
            sortable: true,
            width: "100",
            align: "center",
            cell: (record) => {
                var className =
                    (record.tunsus_status_gpp === 1 ? "bg-success" : "bg-danger") + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "tunsus_status_tunkin",
            text: "Tunkin",
            className: "",
            sortable: true,
            width: "100",
            align: "center",
            cell: (record) => {
                var className =
                    (record.tunsus_status_tunkin === 1 ? "bg-success" : "bg-danger") + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "tunsus_status_employee",
            text: "Simpeg",
            className: "",
            sortable: true,
            width: "100",
            align: "center",
            cell: (record) => {
                var className =
                    (record.tunsus_status_employee === 1 ? "bg-success" : "bg-danger") +
                    " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "tunsus_status_presence",
            text: "Tunsus",
            className: "",
            sortable: true,
            width: "100",
            align: "center",
            cell: (record) => {
                var className =
                    (record.tunsus_status_presence === 1 ? "bg-success" : "bg-danger") +
                    " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "tunsus_uuid",
            text: "Aksi",
            className: "",
            width: "200",
            align: "center",
            cell: (record) => {
                var dataReady =
                    record.tunsus_status_gpp === 1 &&
                    record.tunsus_status_employee === 1 &&
                    record.tunsus_status_presence === 1
                        ? record.tunsus_status === 1 || record.tunsus_status === 3
                            ? true
                            : false
                        : false
                var dataGenerate = record.tunsus_status === 2 ? true : false
                var dataDone = record.tunsus_status === 4 ? true : false
                var requestReset = record.tunsus_status === 5 ? true : false
                const thisId = record.tunsus_id
                return (
                    <>
                        <span>
                            {accessRole("create") ? (
                                <>
                                    {dataReady ? (
                                        <>
                                            <Link
                                                className="btn btn-primary btn-sm"
                                                id={"create-" + thisId}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                                to={"/payroll/tunsus-reguler.html?create&uuid=" + record.tunsus_uuid}
                                            >
                                                <i className="uil-cog"></i>
                                            </Link>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["create" + thisId]}
                                                target={"create-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["create" + thisId]: !this.state["create" + thisId],
                                                    })
                                                }}
                                            >
                                                Proses Tunsus
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-cog"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            <button
                                className="btn btn-warning btn-sm"
                                id={"history-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        showTunsusHistory: true,
                                        historyUuid: record.tunsus_uuid,
                                        tunsusHistoryTitle: record.tunsus_month_name + " " + this.props.year,
                                        tunsusHistorySubTitle:
                                            record.tunsus_date_name +
                                            " (" +
                                            (record.tunsus_percentage * 1).toFixed(2) * 1 +
                                            "% dari nilai tunjangan)",
                                    })
                                }}
                                style={{
                                    marginRight: "2px",
                                    padding: "2px 5px",
                                }}
                            >
                                <i className="uil-clock-eight"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2,
                                }}
                                placement="top"
                                isOpen={this.state["history" + thisId]}
                                target={"history-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["history" + thisId]: !this.state["history" + thisId],
                                    })
                                }}
                            >
                                Riwayat Proses
                            </Tooltip>
                            {accessRole("approve") ? (
                                <>
                                    {dataGenerate ? (
                                        <>
                                            <Link
                                                className={"btn btn-success btn-sm"}
                                                id={"approve-" + thisId}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                                to={"/payroll/tunsus-reguler.html?approve&uuid=" + record.tunsus_uuid}
                                            >
                                                <i className="uil-check"></i>
                                            </Link>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["approve" + thisId]}
                                                target={"approve-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["approve" + thisId]: !this.state["approve" + thisId],
                                                    })
                                                }}
                                            >
                                                Setujui Tunsus
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-check"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            {accessRole("print") ? (
                                <>
                                    {dataDone ? (
                                        <>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                id={"print-" + thisId}
                                                onClick={() => {
                                                    this.setState({
                                                        showTunsusDownload: true,
                                                        downloadUuid: record.tunsus_uuid,
                                                    })
                                                }}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                            >
                                                <i className="uil-arrow-to-bottom"></i>
                                            </button>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["print" + thisId]}
                                                target={"print-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["print" + thisId]: !this.state["print" + thisId],
                                                    })
                                                }}
                                            >
                                                Download
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-arrow-to-bottom"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            {accessRole("update") ? (
                                <>
                                    {dataDone ? (
                                        <>
                                            <button
                                                className="btn btn-success btn-sm"
                                                id={"spm-" + thisId}
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            tunsusSPMRows: [],
                                                            showTunsusSPM: true,
                                                            stateTunsusSPMEdit: false,
                                                            tunsusSPMNumber: record.tunsus_spm_number,
                                                            tunsusSPMStatus: record.tunsus_spm_status,
                                                            tunsusUuid: record.tunsus_uuid,
                                                        },
                                                        () => {
                                                            this.spmAddRow()
                                                        }
                                                    )
                                                }}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                            >
                                                <i className="uil-link"></i>
                                            </button>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["spm" + thisId]}
                                                target={"spm-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["spm" + thisId]: !this.state["spm" + thisId],
                                                    })
                                                }}
                                            >
                                                SPM
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-link"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            {accessRole("approve") && (
                                <>
                                    {requestReset ? (
                                        <>
                                            <button
                                                className="btn btn-primary btn-sm"
                                                id={"update-" + thisId}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        tunsusUuid: record.tunsus_uuid,
                                                        showApproveReset: true,
                                                    })
                                                }}
                                            >
                                                <i className="uil-file-check-alt"></i>
                                            </button>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["update" + thisId]}
                                                target={"update-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["update" + thisId]: !this.state["update" + thisId],
                                                    })
                                                }}
                                            >
                                                Persetujuan Reset
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-primary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-file-check-alt"></i>
                                        </button>
                                    )}
                                </>
                            )}
                            {accessRole("create") && (
                                <>
                                    {dataDone && record.tunsus_spm_status !== 2 ? (
                                        <>
                                            <Link
                                                className="btn btn-primary btn-sm"
                                                id={"update-" + thisId}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                                to={"/payroll/tunsus-reguler.html?verify&uuid=" + record.tunsus_uuid}
                                            >
                                                <i className="uil-file-check-alt"></i>
                                            </Link>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["update" + thisId]}
                                                target={"update-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["update" + thisId]: !this.state["update" + thisId],
                                                    })
                                                }}
                                            >
                                                Validasi
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-primary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-file-check-alt"></i>
                                        </button>
                                    )}
                                </>
                            )}
                        </span>
                    </>
                )
            },
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1400}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="md"
                    isOpen={this.state.showTunsusHistory}
                    toggle={() => {
                        this.setState({
                            showTunsusHistory: !this.state.showTunsusHistory,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            <>
                                Riwayat Proses Tunsus {this.state.tunsusHistoryTitle}
                                {this.state.tunsusHistorySubTitle !== null ? (
                                    <div style={{ fontWeight: "normal", fontSize: 11 }}>
                                        Periode: {this.state.tunsusHistorySubTitle}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showTunsusHistory: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TunsusHistoryComponent uuid={this.state.historyUuid} />
                    </div>
                </Modal>
                <Modal
                    size="sm"
                    isOpen={this.state.showTunsusDownload}
                    toggle={() => {
                        this.setState({
                            showTunsusDownload: !this.state.showTunsusDownload,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Unduh Berkas Tunsus Reguler
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showTunsusDownload: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TunsusDownloadComponent uuid={this.state.downloadUuid} nrsList={this.props.nrsList} />
                    </div>
                </Modal>
                <Modal
                    size="sm"
                    isOpen={this.state.showTunsusSPM}
                    toggle={() => {
                        this.setState({
                            showTunsusSPM: !this.state.showTunsusSPM,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Status SPM Tunsus
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showTunsusSPM: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {this.state.tunsusSPMNumber === null ? (
                        <>
                            <AvForm
                                className="needs-validation"
                                onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                            >
                                <div className="modal-body">
                                    <Row>
                                        <Label className="col-md-12 col-form-label">
                                            Nomor SPM
                                            <AvField name="uuid" value={this.state.tunsusUuid} type="hidden" />
                                        </Label>
                                        <div className="col-md-12">
                                            <table width="100%">
                                                {this.state.tunsusSPMRows.map((id, index) => (
                                                    <tr>
                                                        <td width="*" className="m-2">
                                                            <AvInput
                                                                name={"spm[" + id[0] + "]"}
                                                                placeholder={"SPM " + (index + 1)}
                                                                type="text"
                                                                value={id[1]}
                                                                className="form-control"
                                                                id="fieldTitle"
                                                                validate={{ required: { value: true } }}
                                                                onChange={(e) => this.spmHandleChange(index, e)}
                                                            />
                                                        </td>
                                                        <td width="38px">
                                                            <span
                                                                class="input-group-text btn btn-danger waves-effect"
                                                                aria-hidden="true"
                                                                onClick={() => this.spmRemoveRow(index)}
                                                            >
                                                                <i className="uil-times"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td width="*"></td>
                                                    <td width="38px">
                                                        <span
                                                            class="input-group-text btn btn-success waves-effect"
                                                            aria-hidden="true"
                                                            onClick={() => this.spmAddRow()}
                                                        >
                                                            <i className="uil-plus"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <div
                                                className="form-check form-switch form-switch-small"
                                                style={{ paddingLeft: 12 }}
                                            >
                                                <AvCheckboxGroup name="spmShow">
                                                    <AvCheckbox label="Tampilkan Tunsus di pegawai" value="1" />
                                                </AvCheckboxGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            this.setState({
                                                showTunsusSPM: false,
                                            })
                                        }}
                                        className="btn btn-danger waves-effect"
                                        data-dismiss="modal"
                                    >
                                        Batal
                                    </button>
                                    <button type="submit" className="btn btn-primary waves-effect waves-light">
                                        Simpan
                                    </button>
                                </div>
                            </AvForm>
                        </>
                    ) : (
                        <>
                            {this.state.stateTunsusSPMEdit ? (
                                <>
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <div className="modal-body">
                                            <Row>
                                                <Label className="col-md-12 col-form-label">
                                                    Nomor SPM
                                                    <AvField name="uuid" value={this.state.tunsusUuid} type="hidden" />
                                                </Label>
                                                <div className="col-md-12">
                                                    <table width="100%">
                                                        {this.state.tunsusSPMRows.map((id, index) => (
                                                            <tr>
                                                                <td width="*" className="m-2">
                                                                    <AvInput
                                                                        name={"spm[" + id[0] + "]"}
                                                                        placeholder={"SPM " + (index + 1)}
                                                                        type="text"
                                                                        value={id[1]}
                                                                        className="form-control"
                                                                        id="fieldTitle"
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={(e) => this.spmHandleChange(index, e)}
                                                                    />
                                                                </td>
                                                                <td width="38px">
                                                                    <span
                                                                        class="input-group-text btn btn-danger waves-effect"
                                                                        aria-hidden="true"
                                                                        onClick={() => this.spmRemoveRow(index)}
                                                                    >
                                                                        <i className="uil-times"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td width="*"></td>
                                                            <td width="38px">
                                                                <span
                                                                    class="input-group-text btn btn-success waves-effect"
                                                                    aria-hidden="true"
                                                                    onClick={() => this.spmAddRow()}
                                                                >
                                                                    <i className="uil-plus"></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col md="12">
                                                    <div
                                                        className="form-check form-switch form-switch-small"
                                                        style={{ paddingLeft: 12 }}
                                                    >
                                                        <AvCheckboxGroup name="spmShow">
                                                            <AvCheckbox label="Tampilkan Gaji di pegawai" value="1" />
                                                        </AvCheckboxGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    this.setState({
                                                        showTunsusSPM: false,
                                                    })
                                                }}
                                                className="btn btn-danger waves-effect"
                                                data-dismiss="modal"
                                            >
                                                Batal
                                            </button>
                                            <button type="submit" className="btn btn-primary waves-effect waves-light">
                                                Simpan
                                            </button>
                                        </div>
                                    </AvForm>
                                </>
                            ) : (
                                <>
                                    <div className="modal-body">
                                        <Row className="mb-3">
                                            <label className="col-md-12">Nomor SPM</label>
                                            <Col md="12">
                                                <SimpleBar className="mb-0 ps-5">
                                                    {this.state.tunsusSPMNumber.split(",").map((id, index) => (
                                                        <li
                                                            className="feed-item"
                                                            key={index}
                                                            style={{ paddingBottom: 4 }}
                                                        >
                                                            xx{id}
                                                        </li>
                                                    ))}
                                                </SimpleBar>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <label className="col-md-5">Status</label>
                                            <Col md="7">
                                                {this.state.tunsusSPMStatus === 1 ? (
                                                    <>
                                                        <span>Proses</span>
                                                        <div className="float-end">
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    this.handlerDoneSubmit()
                                                                }}
                                                                className="btn btn-sm btn-success waves-effect"
                                                                data-dismiss="modal"
                                                            >
                                                                Selesai
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    "Selesai"
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                let newTunsusSPMRows = []
                                                this.state.tunsusSPMNumber
                                                    .split(",")
                                                    .map((id, index) => (newTunsusSPMRows[index] = [this.spmId(5), id]))
                                                this.setState({
                                                    tunsusSPMRows: newTunsusSPMRows,
                                                    stateTunsusSPMEdit: true,
                                                })
                                            }}
                                            className="btn btn-warning waves-effect"
                                            data-dismiss="modal"
                                        >
                                            Perbaharui
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                this.setState({
                                                    showTunsusSPM: false,
                                                })
                                            }}
                                            className="btn btn-danger waves-effect"
                                            data-dismiss="modal"
                                        >
                                            Tutup
                                        </button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Modal>
                <Modal
                    size="sm"
                    isOpen={this.state.showApproveReset}
                    toggle={() => {
                        this.setState({
                            showApproveReset: !this.state.showApproveReset,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Reset data Tunjangan Khusus
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showApproveReset: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center">
                        <Col md="12">Persetujuan reset data Tunjangan Khusus</Col>
                    </div>
                    <div className="modal-footer">
                        {/* <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showApproveReset: false,
                                })
                            }}
                            className="btn btn-danger waves-effect"
                            data-dismiss="modal"
                        >
                            Tutup
                        </button> */}
                        <Button
                            color="success"
                            onClick={() => {
                                this.setState({
                                    showApproveReset: false,
                                })
                                this.handlerResetApprove()
                            }}
                        >
                            <i className="uil-check"></i> Setujui
                        </Button>{" "}
                        <Button
                            color="danger"
                            onClick={() => {
                                this.setState({
                                    showApproveReset: false,
                                })
                                this.handlerResetReject()
                            }}
                        >
                            <i className="uil-times"></i> Tolak
                        </Button>
                    </div>
                </Modal>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"
                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"
                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"
                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"
                    showConfirm={this.state.doneConfirm}
                    showLoading={this.state.doneLoading}
                    showSuccess={this.state.doneSuccess}
                    showFailed={this.state.doneFailed}
                    response={this.confirmDoneResponse}
                />

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyetujui reset data ini?"
                    loadingTitle="Mereset data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil disetujui"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal disetujui"
                    showConfirm={this.state.resetApproveConfirm}
                    showLoading={this.state.resetApproveLoading}
                    showSuccess={this.state.resetApproveSuccess}
                    showFailed={this.state.resetApproveFailed}
                    response={this.confirmResetApproveResponse}
                />

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menolak reset data ini?"
                    loadingTitle="Mereset data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil ditolak"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal ditolak"
                    showConfirm={this.state.resetRejectConfirm}
                    showLoading={this.state.resetRejectLoading}
                    showSuccess={this.state.resetRejectSuccess}
                    showFailed={this.state.resetRejectFailed}
                    response={this.confirmResetRejectResponse}
                />
            </>
        )
    }
}

export default TunsusIndexListComponent
