import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import { Tooltip, Modal } from "reactstrap"

import MKIDatatable from "../../../../../../modules/mki-datatable/lib"

import { cancelToken } from "../../../../../../services/adapters/base"

import EmployeeViewBloc from "../Blocs/EmployeeViewBloc"
import apiResponse from "../../../../../../services/apiResponse"

class TunsusEmployeeListComponent extends Component {
    indexViewBloc = new EmployeeViewBloc()

    source
    historyTable = "historyPayrollTunsusRegularEmployeeListComponent"
    defaultOrder = "tunsus_month"
    defaultSize = 20
    defaultSort = "desc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            showTunsusData: false,
            modalrecord: null,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            year: this.props.year,
        }
        await this.indexViewBloc.fetchMonths(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {}

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.monthsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.monthsChannel.unsubscribe()
    }

    columns = [
        {
            key: "tunsus_spm_status",
            text: "Proses",
            className: "",
            sortable: true,
            width: "100",
            align: "center",
            cell: (record) => {
                return (
                    <>
                        {record.tunsus_spm_status === 2 ? (
                            <div
                                className="bg-success rounded-circle d-inline-block"
                                style={{ height: 10, width: 10 }}
                            ></div>
                        ) : (
                            <div
                                className="bg-secondary rounded-circle d-inline-block"
                                style={{ height: 10, width: 10 }}
                            ></div>
                        )}
                    </>
                )
            },
        },
        {
            key: "tunsus_month",
            text: "Bulan",
            className: "",
            sortable: true,
            cell: (record) => {
                var done = record.tunsus_spm_status === 2 ? true : false
                return <>{done ? <b> {record.tunsus_month_name} </b> : record.tunsus_month_name}</>
            },
        },
        {
            key: "tunsus_result_value_presence",
            text: "Presensi",
            className: "",
            align: "center",
            width: "140",
            sortable: true,
            cell: (record) => {
                var done = record.tunsus_spm_status === 2 ? true : false
                return (
                    <div className="text-end">
                        {done
                            ? (record.tunsus_result_value_presence * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            : "-"}
                    </div>
                )
            },
        },
        {
            key: "tunsus_result_value_skp",
            text: "Sasaran Kinerja",
            className: "",
            sortable: true,
            width: "240",
            align: "center",
            cell: (record) => {
                var done = record.tunsus_spm_status === 2 ? true : false
                return (
                    <div className="text-end">
                        {done
                            ? (record.tunsus_result_value_skp * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            : "-"}
                    </div>
                )
            },
        },
        {
            key: "tunsus_result_value_leader",
            text: "Ekspektasi Pimpinan",
            className: "",
            sortable: true,
            width: "240",
            align: "center",
            cell: (record) => {
                var done = record.tunsus_spm_status === 2 ? true : false
                return (
                    <div className="text-end">
                        {done
                            ? (record.tunsus_result_value_leader * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            : "-"}
                    </div>
                )
            },
        },
        {
            key: "tunsus_result_value_tax_tunsus",
            text: "Pajak",
            className: "",
            sortable: true,
            width: "140",
            align: "center",
            cell: (record) => {
                var done = record.tunsus_spm_status === 2 ? true : false
                return (
                    <div className="text-end">
                        {done
                            ? (record.tunsus_result_value_tax_tunsus * 1)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            : "-"}
                    </div>
                )
            },
        },
        {
            key: "tunsus_result_value_total",
            text: "Bersih",
            className: "",
            sortable: true,
            width: "140",
            align: "center",
            cell: (record) => {
                var done = record.tunsus_spm_status === 2 ? true : false
                return (
                    <div className="text-end">
                        <b>
                            {done
                                ? (record.tunsus_result_value_total * 1)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                : "-"}
                        </b>
                    </div>
                )
            },
        },
        /* {
            key: "tunsus_uuid",
            text: "Aksi",
            className: "",
            width: "70",
            align: "center",
            cell: (record) => {
                var done = record.tunsus_spm_status === 2 ? true : false
                const thisId = record.tunsus_result_id
                return (
                    <>
                        {done ? (
                            <>
                                <button
                                    className="btn btn-success btn-sm"
                                    id={"detail-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            showTunsusData: true,
                                            modalrecord: record,
                                        })
                                    }}
                                    style={{
                                        marginRight: "2px",
                                        padding: "2px 5px",
                                    }}
                                >
                                    <i className="uil-align-left"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2,
                                    }}
                                    placement="top"
                                    isOpen={this.state["detail" + thisId]}
                                    target={"detail-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["detail" + thisId]: !this.state["detail" + thisId],
                                        })
                                    }}
                                >
                                    Lihat Tunjangan
                                </Tooltip>
                            </>
                        ) : (
                            <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    marginRight: "2px",
                                    padding: "2px 5px",
                                }}
                            >
                                <i className="uil-align-left"></i>
                            </button>
                        )}
                    </>
                )
            },
        }, */
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1000}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
                {/* <Modal
                    size="md"
                    isOpen={this.state.showTunsusData}
                    toggle={() => {
                        this.setState({
                            showTunsusData: !this.state.showTunsusData,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Rincian Tunjangan Khusus Rapel
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showTunsusData: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.modalrecord !== null ? (
                            <Table className="table table-striped table-bordered table-hover">
                                <Thead>
                                    <Tr>
                                        <Th width="*" data-priority="1">
                                            Nama
                                        </Th>
                                        <Th width="160px" data-priority="3" className="text-center">
                                            Jumlah
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <tr>
                                        <td className="fw-bold">Total Tunjangan</td>
                                        <td className="fw-bold text-end">
                                            {this.state.modalrecord.tunsus_result_value_primary !== null
                                                ? this.state.modalrecord.tunsus_result_value_primary
                                                      .replace(".00", "")
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4">Tidak Masuk</td>
                                        <td className="text-end">
                                            {this.state.modalrecord.tunsus_result_value_not_present !== null
                                                ? this.state.modalrecord.tunsus_result_value_not_present
                                                      .replace(".00", "")
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4">Telat Masuk</td>
                                        <td className="text-end">
                                            {this.state.modalrecord.tunsus_result_value_late !== null
                                                ? this.state.modalrecord.tunsus_result_value_late
                                                      .replace(".00", "")
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4">Pulang Cepat</td>
                                        <td className="text-end">
                                            {this.state.modalrecord.tunsus_result_value_early !== null
                                                ? this.state.modalrecord.tunsus_result_value_early
                                                      .replace(".00", "")
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4">Satu Kali Absen</td>
                                        <td className="text-end">
                                            {this.state.modalrecord.tunsus_result_value_single_finger !== null
                                                ? this.state.modalrecord.tunsus_result_value_single_finger
                                                      .replace(".00", "")
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Total Potongan Absensi</td>
                                        <td className="fw-bold text-end">
                                            {this.state.modalrecord.tunsus_result_value_not_present !== null
                                                ? (
                                                      this.state.modalrecord.tunsus_result_value_not_present * 1 +
                                                      this.state.modalrecord.tunsus_result_value_single_finger * 1 +
                                                      this.state.modalrecord.tunsus_result_value_early * 1 +
                                                      this.state.modalrecord.tunsus_result_value_late * 1
                                                  )
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4">Hukuman Disiplin</td>
                                        <td className="text-end">
                                            {this.state.modalrecord.tunsus_result_value_discipline !== null
                                                ? this.state.modalrecord.tunsus_result_value_discipline
                                                      .replace(".00", "")
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">
                                            <a
                                                href="https://simpeg.bkpm.go.id/kepegawaian/rekap_absensi.html"
                                                target="_blank"
                                            >
                                                Total Potongan
                                            </a>
                                        </td>
                                        <td className="fw-bold text-end">
                                            {this.state.modalrecord.tunsus_result_value_not_present !== null
                                                ? (
                                                      this.state.modalrecord.tunsus_result_value_not_present * 1 +
                                                      this.state.modalrecord.tunsus_result_value_single_finger * 1 +
                                                      this.state.modalrecord.tunsus_result_value_early * 1 +
                                                      this.state.modalrecord.tunsus_result_value_late * 1 +
                                                      this.state.modalrecord.tunsus_result_value_discipline * 1
                                                  )
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <>
                                        {this.state.modalrecord.tunsus_result_value_other !== null ? (
                                            <tr>
                                                <td className="fw-bold">Tunjangan PLT</td>
                                                <td className="fw-bold text-end">
                                                    {this.state.modalrecord.tunsus_result_value_other !== null
                                                        ? this.state.modalrecord.tunsus_result_value_other
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "0"}
                                                </td>
                                            </tr>
                                        ) : null}
                                    </>
                                    <tr>
                                        <td className="ps-4">Tunjangan Khusus Pajak</td>
                                        <td className="text-end">
                                            {this.state.modalrecord.tunsus_result_value_tax !== null
                                                ? this.state.modalrecord.tunsus_result_value_tax
                                                      .replace(".00", "")
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Penghasilan Kotor</td>
                                        <td className="fw-bold text-end">
                                            {this.state.modalrecord.tunsus_result_value_total !== null
                                                ? (
                                                      this.state.modalrecord.tunsus_result_value_total * 1 +
                                                      this.state.modalrecord.tunsus_result_value_tax * 1
                                                  )
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-4">Pajak Penghasilan</td>
                                        <td className="text-end">
                                            {this.state.modalrecord.tunsus_result_value_tax !== null
                                                ? this.state.modalrecord.tunsus_result_value_tax
                                                      .replace(".00", "")
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h5 fw-bold">Jumlah Bersih</td>
                                        <td className="h5 fw-bold text-end">
                                            {this.state.modalrecord.tunsus_result_value_total !== null
                                                ? this.state.modalrecord.tunsus_result_value_total
                                                      .replace(".00", "")
                                                      .toString()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                : "0"}
                                        </td>
                                    </tr>
                                </Tbody>
                            </Table>
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal> */}
            </>
        )
    }
}

export default TunsusEmployeeListComponent
