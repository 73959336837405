import {
    apiRequest,
    tunkinT14RegulerMonth,
    tunkinT14Create,
    tunkinT14Months,
    tunkinT14History,
    tunkinT14PushSPM,
    tunkinT14UpdateSPM,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {
    fetchRegulerMonts = async (query) => {
        return await apiRequest(
            "get",
            tunkinT14RegulerMonth,
            {
                params: query,
            },
            true
        )
    }

    fetchCreate = async (query) => {
        return await apiRequest(
            "post",
            tunkinT14Create,
            {
                body: query,
            },
            true
        )
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunkinT14Months,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchHistory = async (query) => {
        return await apiRequest(
            "get",
            tunkinT14History,
            {
                params: query,
            },
            true
        )
    }

    fetchPushSPM = async (query) => {
        return await apiRequest(
            "post",
            tunkinT14PushSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest(
            "post",
            tunkinT14UpdateSPM,
            {
                body: query,
            },
            true
        )
    }
}

export default IndexViewRepository
