import {
    apiRequest,
    overtimeApproveSubmit,
    overtimeRejectSubmit,
    overtimeResultEmployee,
    overtimeCheckStatus,
    overtimeResultEmployeeDetail
} from "../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", overtimeApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", overtimeRejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", overtimeResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", overtimeResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", overtimeCheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository