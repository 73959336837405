import { accessRole } from "../../../../helpers/access_role"
import classnames from "classnames"
import React, { Fragment, useRef, useState } from "react"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"


import RefundProcessListComponent from './Components/RefundProcessListComponent'
import RefundDoneListComponent from './Components/RefundDoneListComponent'
import RefundCancelListComponent from './Components/RefundCancelListComponent'

const IndexView = (props) => {

    const [activeTab, setactiveTab] = useState(sessionStorage.getItem('refundTabs') ? sessionStorage.getItem('refundTabs') : "1")
    const processRef = useRef();
    const doneRef = useRef();
    const cancelRef = useRef();

    const reloadProcessRef = () => processRef.current.setTokenAPI()
    const reloadDoneRef = () => doneRef.current.setTokenAPI()
    const reloadCancelRef = () => cancelRef.current.setTokenAPI()


    function toggle(tab) {
        sessionStorage.setItem('refundTabs', tab)
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Pengembalian Uang Harian</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Informasi pengembalian uang makan yang sudah dibayarkan
                                </CardSubtitle>
                                <hr />
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block" style={{ color: (activeTab === "1" ? '#5b73e8' : '#7b8190') }}>Proses</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block" style={{ color: (activeTab === "2" ? '#5b73e8' : '#7b8190') }}>Selesai</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "3",
                                            })}
                                            onClick={() => {
                                                toggle("3")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block" style={{ color: (activeTab === "3" ? '#5b73e8' : '#7b8190') }}>Batal</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <RefundProcessListComponent ref={processRef} handleDoneSuccess={reloadDoneRef} handleCancelSuccess={reloadCancelRef} />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <RefundDoneListComponent ref={doneRef} handleCancelSuccess={reloadCancelRef} handleProcessSuccess={reloadProcessRef} />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <RefundCancelListComponent ref={cancelRef} handleProcessSuccess={reloadProcessRef} handleDoneSuccess={reloadDoneRef} />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView