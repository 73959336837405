import {
    apiRequest,
    krkoCreate,
    krkoYearAvailable,
    krkoAllEmployeeList
} from "../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", krkoCreate, {
            body: query
        }, true)
    }

    fetchYearAvailable = async () => {
        return await apiRequest("get", krkoYearAvailable, {}, true)
    }

    fetchDataAllEmployeeList = async (query) => {
        return await apiRequest("get", krkoAllEmployeeList, { params: query }, true)
    }
}

export default CreateRepository