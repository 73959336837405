import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import IndexViewRepository from '../Repositories/IndexViewRepository'


class IndexViewBloc {

    yearsChannel = new Subject();
    rowSkpDataChannel = new Subject();
    rowPayrollDataChannel = new Subject();
    historyChannel = new Subject();
    createChannel = new Subject();
    detailSkpChannel = new Subject();
    detailPayrollChannel = new Subject();
    signPayrollChannel = new Subject();
    signSkpChannel = new Subject();
    statusChannel = new Subject();
    repository = new IndexViewRepository()

    constructor() {
        this.yearsChannel.next({status: apiResponse.INITIAL})
        this.rowSkpDataChannel.next({status: apiResponse.INITIAL})
        this.rowPayrollDataChannel.next({status: apiResponse.INITIAL})
        this.historyChannel.next({status: apiResponse.INITIAL})
        this.createChannel.next({status: apiResponse.INITIAL})
        this.detailSkpChannel.next({status: apiResponse.INITIAL})
        this.detailPayrollChannel.next({status: apiResponse.INITIAL})
        this.signPayrollChannel.next({status: apiResponse.INITIAL})
        this.signSkpChannel.next({status: apiResponse.INITIAL})
        this.statusChannel.next({status: apiResponse.INITIAL})
    }

    fetchYears = async () => {
        this.yearsChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchYears().then((result) => {
                this.yearsChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.yearsChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    
    fetchRowTableSkp = async (query, cancelToken) => {
        this.rowSkpDataChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTableSkp(query, cancelToken).then((result) => {
                this.rowSkpDataChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowSkpDataChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchRowTablePayroll = async (query, cancelToken) => {
        this.rowPayrollDataChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTablePayroll(query, cancelToken).then((result) => {
                this.rowPayrollDataChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowPayrollDataChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchHistory = async (query) => {
        this.historyChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchHistory(query).then((result) => {
                this.historyChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.historyChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchCreate = async (query) => {
        this.createChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.createChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    
    fetchDetailSkp = async (query) => {
        this.detailSkpChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetailSkp(query).then((result) => {
                this.detailSkpChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailSkpChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}       
    }

    fetchDetailPayroll = async (query) => {
        this.detailPayrollChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetailPayroll(query).then((result) => {
                this.detailPayrollChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailPayrollChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}       
    }
    
    fetchSignPayroll = async (query) => {
        this.signPayrollChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchSignPayroll(query).then((result) => {
                this.signPayrollChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.signPayrollChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}       
    }

    fetchSignSkp = async (query) => {
        this.signSkpChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchSignSkp(query).then((result) => {
                this.signSkpChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.signSkpChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}       
    }

}

export default IndexViewBloc