import {
    apiRequest,
    masterSpdCountryCreate,
    masterSpdCountryCountries,
    masterSpdCountryprovinceCheck
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterSpdCountryCreate, {
            body: query
        }, true)
    }

    fetchCountryList = async () => {
        return await apiRequest("get", masterSpdCountryCountries, {}, true)
    }
    fetchCheck = async (query) => {
        return await apiRequest("post",masterSpdCountryprovinceCheck, {
            body: query
        }, true)
    }

}

export default CreateRepository