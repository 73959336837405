import { accessRole, ppk } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"

import MKIDatatable from "../../../../../../modules/mki-datatable/lib"

import { cancelToken } from "../../../../../../services/adapters/base"

import DataTableBloc from "../Blocs/DataTableBloc"
import apiResponse from "../../../../../../services/apiResponse"

import OTDownloadComponent from "./OTDownloadComponent"

import { Tooltip, Label, Modal } from "reactstrap"

import moment from "moment"

class OTTableListComponent extends Component {
    dataTableBloc = new DataTableBloc()

    source
    historyTable = "historyOfficialTravelSubmission"
    defaultOrder = "official_travel_process_create_date"
    defaultSize = 10
    defaultSort = "desc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        {
            key: "official_travel_process_number",
            text: "Nomor",
            sortable: true,
            width: "220",
        },
        {
            key: "official_travel_process_total",
            text: "Pegawai",
            sortable: true,
            width: "140",
            cell: (record) => {
                return <>{record.official_travel_process_total} Pegawai</>
            },
        },
        {
            key: "official_travel_process_type_name",
            text: "Tujuan",
            sortable: true,
            width: "140",
        },
        {
            key: "official_travel_process_create_date",
            text: "Tanggal",
            sortable: true,
            width: "220",
            cell: (record) => {
                return moment(record.official_travel_process_create_date).format("LL")
            },
        },
        /* {
            key: "official_travel_process_collective",
            text: "Kolektif",
            sortable: true,
            width: "140",
            align: "center",
            cell: (record) => {
                return record.official_travel_process_collective === 1 ? <div className="bg-success rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> : <>{"-"}</>
            },
        }, */
        {
            key: "official_travel_process_description",
            text: "Keterangan",
            sortable: true,
        },
        {
            key: "official_travel_process_status_ppk_text",
            text: "Status",
            sortable: true,
            width: "220",
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: "120",
            sortable: false,
            align: "center",
            cell: (record) => {
                const thisId = record.official_travel_process_id
                var allowUpdate = (accessRole("create") || accessRole("update")) && (record.official_travel_process_status === 1 || record.official_travel_process_status === 3)
                var allowApprove = (accessRole("create") || accessRole("update")) && record.official_travel_process_status === 2 && ppk() === 1
                //var allowPrint = (accessRole('print')) && record.official_travel_process_status === 7
                var allowPrint = accessRole("print") && record.official_travel_process_allow_download === 1
                return (
                    <>
                        <span>
                            {allowUpdate ? (
                                <>
                                    <Link
                                        className="btn btn-primary btn-sm"
                                        id={"update-" + thisId}
                                        style={{
                                            marginRight: "2px",
                                            padding: "2px 5px",
                                        }}
                                        to={"/official-travel/submission.html?process&uuid=" + record.official_travel_process_uuid}
                                    >
                                        <i className="uil-pen"></i>
                                    </Link>
                                    <Tooltip
                                        style={{
                                            marginBottom: 2,
                                        }}
                                        placement="top"
                                        isOpen={this.state["update" + thisId]}
                                        target={"update-" + thisId}
                                        toggle={() => {
                                            this.setState({
                                                ["update" + thisId]: !this.state["update" + thisId],
                                            })
                                        }}
                                    >
                                        Proses Nilai
                                    </Tooltip>
                                </>
                            ) : (
                                <button
                                    className="btn btn-soft-secondary btn-sm"
                                    disabled={true}
                                    style={{
                                        marginRight: "2px",
                                        padding: "2px 5px",
                                    }}
                                >
                                    <i className="uil-pen"></i>
                                </button>
                            )}
                            {allowApprove ? (
                                <>
                                    <Link
                                        className="btn btn-success btn-sm"
                                        id={"approve-" + thisId}
                                        style={{
                                            marginRight: "2px",
                                            padding: "2px 5px",
                                        }}
                                        to={"/official-travel/submission.html?process&uuid=" + record.official_travel_process_uuid}
                                    >
                                        <i className="uil-check"></i>
                                    </Link>
                                    <Tooltip
                                        style={{
                                            marginBottom: 2,
                                        }}
                                        placement="top"
                                        isOpen={this.state["approve" + thisId]}
                                        target={"approve-" + thisId}
                                        toggle={() => {
                                            this.setState({
                                                ["approve" + thisId]: !this.state["approve" + thisId],
                                            })
                                        }}
                                    >
                                        Setujui Pengajuan
                                    </Tooltip>
                                </>
                            ) : (
                                <button
                                    className="btn btn-soft-secondary btn-sm"
                                    disabled={true}
                                    style={{
                                        marginRight: "2px",
                                        padding: "2px 5px",
                                    }}
                                >
                                    <i className="uil-check"></i>
                                </button>
                            )}
                            {allowPrint ? (
                                <>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        id={"print-" + thisId}
                                        onClick={() => {
                                            this.setState({
                                                showOfficialTravelDownload: true,
                                                downloadUuid: record.official_travel_process_uuid,
                                            })
                                        }}
                                        style={{
                                            marginRight: "2px",
                                            padding: "2px 5px",
                                        }}
                                    >
                                        <i className="uil-arrow-to-bottom"></i>
                                    </button>
                                    <Tooltip
                                        style={{
                                            marginBottom: 2,
                                        }}
                                        placement="top"
                                        isOpen={this.state["print" + thisId]}
                                        target={"print-" + thisId}
                                        toggle={() => {
                                            this.setState({
                                                ["print" + thisId]: !this.state["print" + thisId],
                                            })
                                        }}
                                    >
                                        Download
                                    </Tooltip>
                                </>
                            ) : (
                                <button
                                    className="btn btn-soft-secondary btn-sm"
                                    disabled={true}
                                    style={{
                                        padding: "2px 5px",
                                    }}
                                >
                                    <i className="uil-arrow-to-bottom"></i>
                                </button>
                            )}
                        </span>
                    </>
                )
            },
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1200}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="sm"
                    isOpen={this.state.showOfficialTravelDownload}
                    toggle={() => {
                        this.setState({
                            showOfficialTravelDownload: !this.state.showOfficialTravelDownload,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Unduh Berkas
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showOfficialTravelDownload: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <OTDownloadComponent uuid={this.state.downloadUuid} />
                    </div>
                </Modal>
            </>
        )
    }
}

export default OTTableListComponent
