import React, { Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    Button
} from "reactstrap"
import { Preview } from '../../../../../helpers/ui/FileInput'

import RegulationBloc from '../Blocs/RegulationBloc'
import apiResponse from '../../../../../services/apiResponse'
import moment from 'moment'

class RegulationDetailComponent extends Component {

    regulationBloc = new RegulationBloc()

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.regulationBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.regulationBloc.fetchDetail({uuid: this.props.uuid })
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Nomor
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.regulation_number !== "" ? this.state.detail.regulation_number : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Judul
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.regulation_title !== "" ? this.state.detail.regulation_title : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{width: '60%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Perihal
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.regulation_description !== "" ? this.state.detail.regulation_description : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{width: '40%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Tanggal Penetapan
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.regulation_date !== "" ? moment(this.state.detail.regulation_date).format('LL') : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Keterangan
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.regulation_other !== "" ? this.state.detail.regulation_other : '-'}</>
                                        :
                                            <Shimmer><div className="shimmer" style={{width: '80%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Tanggal Mulai Berlaku
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.regulation_date_start !== "" ? moment(this.state.detail.regulation_date_start).format('LL') : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Tanggal Berakhir
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.regulation_date_end !== null ? moment(this.state.detail.regulation_date_end).format('LL') : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    File Surat Keputusan
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detail ?
                                            <Preview
                                                col={6}
                                                value={[{
                                                    documents_name      : this.state.detail.regulation_letter_name,
                                                    documents_path      : this.state.detail.regulation_letter_path,
                                                    documents_mime_type     : this.state.detail.regulation_letter_mime,
                                                    documents_file_size     : this.state.detail.regulation_letter_size,
                                                    documents_source        : 'minio',
                                                }]}
                                            />
                                        : <Shimmer><div className="shimmer" style={{width: 80, height: 100}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md="3"></Col>
                                <Col md="9">
                                    <Button color="success" onClick={() => this.props.handleToList()}><i className="uil-arrow-left"></i> Kembali</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default RegulationDetailComponent;