import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button
} from "reactstrap"
import { Link } from "react-router-dom"
import { withRouter } from "react-router";
import { Preview } from '../../../../../../helpers/ui/FileInput'

import apiResponse from '../../../../../../services/apiResponse'

import ProcessBloc from '../Blocs/ProcessBloc'
import moment from 'moment'

class OfficialTravelSourceComponent extends Component {

    processBloc = new ProcessBloc()

    constructor(props) {
        super(props)
        this.state = {
            uuid: null
        }
    }

    componentDidMount() {
        this.processBloc.processSourceChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.detail
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.setState({
            uuid: this.props.uuid
        }, function () {
            this.processBloc.fetchSource({ uuid: this.props.uuid })
        })
    }

    componentWillUnmount() {
        this.processBloc.processSourceChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Nomor
                        </label>
                        <Col md="9">
                            {
                                this.state.detail ?
                                    <>{this.state.detail.official_travel_number !== "" ? this.state.detail.official_travel_number : '-'}</>
                                    : <Shimmer className="shimmer"><div style={{ width: 100, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Tanggal Mulai
                        </label>
                        <Col md="9">
                            {
                                this.state.detail ?
                                    <>{this.state.detail.official_travel_start_date !== "" ? moment(this.state.detail.official_travel_start_date).format('LL') : '-'}</>
                                    : <Shimmer className="shimmer"><div style={{ width: 140, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Tanggal Selesai
                        </label>
                        <Col md="9">
                            {
                                this.state.detail ?
                                    <>{this.state.detail.official_travel_end_date !== "" ? moment(this.state.detail.official_travel_end_date).format('LL') : '-'}</>
                                    : <Shimmer className="shimmer"><div style={{ width: 140, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Tempat Dinas
                        </label>
                        <Col md="9">
                            {
                                this.state.detail ?
                                    <>
                                        {this.state.detail.official_travel_oversea === 1 ? ('Dalam Negeri' + (this.state.detail.official_travel_domicile === 1 ? ' (Dalam Kota)' : ' (Luar Kota)')) : 'Luar Negeri'}
                                    </>
                                    : <Shimmer className="shimmer"><div style={{ width: 120, height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    {
                        this.state.detail ?
                            <>
                                {this.state.detail.official_travel_oversea === 1 ?
                                    <>
                                        <Row className="mb-3">
                                            <label className="col-md-3">
                                                Provinsi
                                            </label>
                                            <Col md="9">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.official_travel_province !== "" ? this.state.detail.official_travel_province : '-'}</>
                                                        : <Shimmer className="shimmer"><div style={{ width: 100, height: 15 }}></div></Shimmer>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label className="col-md-3">
                                                Kota
                                            </label>
                                            <Col md="9">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.official_travel_city !== "" ? this.state.detail.official_travel_city : '-'}</>
                                                        : <Shimmer className="shimmer"><div style={{ width: 140, height: 15 }}></div></Shimmer>
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row className="mb-3">
                                            <label className="col-md-3">
                                                Negara
                                            </label>
                                            <Col md="9">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.official_travel_country !== "" ? this.state.detail.official_travel_country : '-'}</>
                                                        : <Shimmer className="shimmer"><div style={{ width: 100, height: 15 }}></div></Shimmer>
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </>
                            : <></>
                    }
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Tujuan
                        </label>
                        <Col md="9">
                            {
                                this.state.detail ?
                                    <>{this.state.detail.official_travel_destination !== "" ? this.state.detail.official_travel_destination : '-'}</>
                                    :
                                    <Shimmer className="shimmer"><div style={{ width: '80%', height: 15 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            Keterangan
                        </label>
                        <Col md="9">
                            {
                                this.state.detail ?
                                    <>{this.state.detail.official_travel_description !== "" ? this.state.detail.official_travel_description : '-'}</>
                                    :
                                    <>
                                        <Shimmer className="shimmer"><div style={{ width: '100%', height: 15 }}></div></Shimmer>
                                        <Shimmer className="shimmer"><div style={{ width: '20%', height: 15 }}></div></Shimmer>
                                    </>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">
                            File Surat Keputusan
                        </label>
                        <Col md="9">
                            {
                                this.state.detail ?
                                    <Preview
                                        col={5}
                                        value={[{
                                            documents_name: this.state.detail.official_travel_letter_name,
                                            documents_path: this.state.detail.official_travel_letter_path,
                                            documents_mime_type: this.state.detail.official_travel_letter_mime,
                                            documents_file_size: this.state.detail.official_travel_letter_size,
                                            documents_source: 'minio',
                                        }]}
                                    />
                                    : <Shimmer><div className="shimmer" style={{ width: 120, height: 220 }}></div></Shimmer>
                            }
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

//export default OfficialTravelSourceComponent
export default withRouter(OfficialTravelSourceComponent);
