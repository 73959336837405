import {
    apiRequest,
    uangMakanRegularEmployeeYears,
    uangMakanRegularEmployeeMonths,
} from "../../../../../../services/adapters/process"

class EmployeeViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", uangMakanRegularEmployeeYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", uangMakanRegularEmployeeMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

}

export default EmployeeViewRepository