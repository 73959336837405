import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Link, Redirect } from "react-router-dom"
import moment from 'moment'
import {
    Row, Col,
    Label,
    Button,
    Tooltip
} from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { AvField, AvForm } from "availity-reactstrap-validation"
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import { cancelToken } from "../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc.jsx'
import apiResponse from '../../../../../services/apiResponse'

import ReactTimeAgo from 'react-time-ago'

class DataTableComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyNotificationDataTable'
    defaultOrder = 'notification_date'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            gotoLink: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            jenisOptions: [
                {
                    "value":"all",
                    "label":"Semua"
                },
                {
                    "value":"PSIGAP",
                    "label":"SIGAP"
                },
                {
                    "value":"PSLIP",
                    "label":"Slip Gaji"
                }
            ],
            jenisSelected: "all",
            date_start: moment().startOf('year').format('YYYY-MM-DD'),
            date_end: moment().endOf('year').format('YYYY-MM-DD'),
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            jenis: this.state.jenisSelected,
            date_start: this.state.date_start,
            date_end: this.state.date_end
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    
    handlerOnSubmit = (e, values) => {
        this.setTokenAPI()
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED: {
                    const response = result.data.response;
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    });
                    break;
                }
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.dataTableBloc.flagReadChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:

                    break
                case apiResponse.COMPLETED:
                    const response = result.data;
                    if(response.status) {
                        this.setState({
                            loadingTable: true,
                            gotoLink: this.state.notificationSelectedLink
                        }, () => {
                            // Reload data to reflect the updated notification_read status
                            this.loadData();
                        });
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        });

        // Remove the duplicate subscription to flagReadChannel
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
        this.dataTableBloc.flagReadChannel.unsubscribe()
    }

    handleChecked = (e) => {
        this.dataTableBloc.fetchChecklist({ notification_id: e.target.value, status: e.target.checked ? 1 : 0 })
        e.target.disabled  = true
    }

    columns = [
        {
            key: "notification_title",
            text: "Judul",
            sortable: true,
            width: '20%',
            cell: record => {
                return <span className={record.notification_read === 0 ? "fw-bold" : " " }>{record.notification_title}</span>
            }
        },
        {
            key: "notification_description",
            text: "Keterangan",
            sortable: true,
            cell: record => {
                const getNotificationLink = () => {
                    const baseUrl = record.notification_type === 'PSLIP' 
                        ? "/payroll/slip.html?payrollupdate&uuid=" 
                        : "/payroll/sigap.html?payrollupdate&uuid=";
                    return baseUrl + record.notification_data_uuid;
                };

                return (
                    <span
                        className="link-primary"
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            this.dataTableBloc.fetchFlagRead({ notification_id: record.notification_id });
                            this.setState({
                                gotoLink: getNotificationLink(),
                                notificationSelectedLink: getNotificationLink()
                            });
                        }}
                    >
                        <span className={(record.notification_read === 0) ? "fw-bold" : ""}>{record.notification_description}</span>
                    </span>
                )
            }
        },
        {
            key: "tanggal_pengajuan",
            text: "Tanggal Pengajuan",
            sortable: true,
            cell: record => {
                return moment(record.notification_date).format('LL')
            }
        },
        {
            key: "notification_date",
            text: "Waktu Pemberitahuan",
            align: 'center',
            sortable: true,
            width: '160',
            cell: record => {
                const thisId = record.sample_id
                return (
                    <center>
                        <ReactTimeAgo id={"create-" + thisId} date={record.notification_date} locale="id" timeStyle="round-minute" />
                        <Tooltip
                            style={{
                                marginBottom: 2
                            }}
                            placement="top" isOpen={this.state["create" + thisId]} target={"create-" + thisId}
                            toggle={() => {
                                this.setState({
                                    ["create" + thisId] : !this.state["create" + thisId]
                                })
                            }}
                        >{record.notification_date}</Tooltip>
                    </center>
                )
            }
        }
    ]

    render() {
        return (
            <>

                <AvForm
                    className="needs-validation p-2 mt-2 mb-5"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                >

                    <Row className="mb-3">
                        <Label
                            htmlFor="fieldDateActive"
                            className="col-md-2 col-form-label"
                        >
                            Jenis Pengajuan<span className="text-danger">*</span>
                        </Label>
                        <Col md="2">
                            <CustomSelect 
                                name="jenis_pengajuan"
                                errorMessage="Pilih Jenis Pengajuan"
                                options={this.state.jenisOptions} 
                                defaultValue={'all'}
                                placeholder="Pilih Jenis Pengajuan"
                                validate={{ required: { value: true } }}
                                onChange={(v) => {
                                    this.setState({
                                        jenisSelected: v.value,
                                    })
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Label
                            htmlFor="fieldDateActive"
                            className="col-md-2 col-form-label"
                        >
                            Tanggal Awal<span className="text-danger">*</span>
                        </Label>
                        <Col md="2">
                            <AvField
                                name="date_start"
                                placeholder="Filter Tanggal Awal"
                                errorMessage="Masukkan Tanggal Awal"
                                type="date"
                                className="form-control"
                                id="date_start"
                                validate={{ required: { value: true } }}
                                defaultValue={this.state.date_start}
                                onChange={(v) => {
                                    this.setState({
                                        date_start: v.target.value,
                                    })
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Label
                            htmlFor="fieldDateActive"
                            className="col-md-2 col-form-label"
                        >
                            Tanggal Akhir<span className="text-danger">*</span>
                        </Label>
                        <Col md="2">
                            <AvField
                                name="date_end"
                                placeholder="Filter Tanggal Akhir"
                                errorMessage="Masukkan Tanggal Akhir"
                                type="date"
                                className="form-control"
                                id="date_end"
                                validate={{ required: { value: true } }}
                                defaultValue={this.state.date_end}
                                onChange={(v) => {
                                    this.setState({
                                        date_end: v.target.value,
                                    })
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md="2"></Col>
                        <Col md="10">
                            <Button color="primary" type="submit">
                                <i className="uil-search-alt"></i> Filter Pencarian
                            </Button>
                        </Col>
                    </Row>


                </AvForm>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                { this.state.gotoLink !== null ?
                    <Redirect to={this.state.gotoLink}/>
                : null }
            </>
        )
    }

}

export default DataTableComponent