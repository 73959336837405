import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import DetailRepository from '../Repositories/DetailRepository'


class DetailBloc {

    detailChannel = new Subject();
    participantChannel = new Subject();
    levelOptionChannel = new Subject();
    updateChannel = new Subject();
    updateAllChannel = new Subject();
    repository = new DetailRepository()

    constructor() {
        this.detailChannel.next({ status: apiResponse.INITIAL })
        this.participantChannel.next({ status: apiResponse.INITIAL })
        this.levelOptionChannel.next({ status: apiResponse.INITIAL })
        this.updateChannel.next({ status: apiResponse.INITIAL })
        this.updateAllChannel.next({ status: apiResponse.INITIAL })
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.detailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.detailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchParticipant = async (query) => {
        this.participantChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchParticipant(query).then((result) => {
                this.participantChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.participantChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchLevelOption = async (query) => {
        this.levelOptionChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchLevelOption(query).then((result) => {
                this.levelOptionChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.levelOptionChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchUpdate = async (query) => {
        this.updateChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchUpdate(query).then((result) => {
                this.updateChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.updateChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchUpdateAll = async (query) => {
        this.updateAllChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchUpdateAll(query).then((result) => {
                this.updateAllChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.updateAllChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }
}

export default DetailBloc
