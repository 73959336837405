import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect";
import Select from "react-select"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Modal
} from "reactstrap"

import TplnIndexListComponent from './Components/TplnIndexListComponent'

import IndexViewBloc from './Blocs/IndexViewBloc'
import apiResponse from '../../../../../services/apiResponse'


class IndexView extends Component {

    indexViewBloc = new IndexViewBloc()
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear()-1 : this.thisDate.getFullYear()

    constructor(props) {
        super(props)
        this.state = {
            showTunkinHistory: false,
        }

    }

    loadCurrent = async () => {
        await this.indexViewBloc.fetchYears()
    }

    componentDidMount() {

    }


    componentWillUnmount() {
        
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tunjangan TPLN Susulan</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Proses Tunjangan TPLN Susulan berdasarkan tahun dan bulan tertentu sesuai dengan data integrasi (Simpeg) yang sudah di verifikasi
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{paddingTop: 8}}>
                                                    <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div> Sudah Integrasi
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{paddingTop: 8}}>
                                                    <div className="bg-danger rounded-circle d-inline-block" style={{height: 10, width: 10}}></div> Belum Integrasi
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={12}>
                                            <TplnIndexListComponent />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView