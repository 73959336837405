import React, { Fragment, Component } from "react"
import classnames from "classnames"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"


import SPTRegulerListComponent from './Components/SPTRegulerListComponent'
import SPTFinalListComponent from './Components/SPTFinalListComponent'

class EmployeeView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeTab: "1"
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab : tab
            })
        }
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
        
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Surat Pemberitahuan Tahunan</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Data SPT masing-masing pegawai berdasarkan tahun tertentu
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{paddingTop: 8}}>
                                                    <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div> Sudah TTE
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{paddingTop: 8}}>
                                                    <div className="bg-warning rounded-circle d-inline-block" style={{height: 10, width: 10}}></div> Proses
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: this.state.activeTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            this.toggle("1")
                                                        }}
                                                    >
                                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                        <span className="d-none d-sm-block">1721-A2</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: this.state.activeTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            this.toggle("2")
                                                        }}
                                                    >
                                                        <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                                        <span className="d-none d-sm-block">PPh Final</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                                                <TabPane tabId="1">
                                                    <SPTRegulerListComponent year={ 2021 }/>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <SPTFinalListComponent year={ 2021 }/>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </Fragment>
        )
    }
}

export default EmployeeView