import React, { useState, useEffect } from "react"
import Shimmer from "react-shimmer-effect";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Modal
} from "reactstrap"
import Select from "react-select"
import { authContext } from '../../../services/adalConfig';
import avatarMale from '../../../assets/images/avatar_male.png'
import avatarFemale from '../../../assets/images/avatar_female.png'

import { Link } from "react-router-dom"


const ProfileMenu = props => {

  const userdata = JSON.parse(localStorage.getItem('userdata'))

  const [menu, setMenu] = useState(false)

  const [email, setemail] = useState("")
  const [username, setusername] = useState("")
  const [userphoto, setuserphoto] = useState(localStorage.getItem('employeePhoto'))
  const [useravatar, setuseravatar] = useState(avatarMale)

  const [modalChangeRoleOpen, setModalChangeRoleOpen] = useState(false)
  const [selectedAccessRole, setSelectedAccessRole] = useState({ label: localStorage.getItem('currentRoleName'), value: localStorage.getItem('currentRoleId').toString() + (localStorage.getItem('ppkUuid') !== '' ? ':' + localStorage.getItem('ppkUuid') : '') })
  const listAccessRole = JSON.parse(sessionStorage.getItem('allRole'))
  const itemAccessRole = []

  listAccessRole.map((role) => {
    itemAccessRole.push({ label: role.access_name, value: role.access_id.toString() })
    return null
  })

  const optionAccessRole = [
    {
      label: "Akses Role",
      options: itemAccessRole
    }
  ]
  function handleSelectAccessRole(selectedAccessRole) {
    setSelectedAccessRole(selectedAccessRole)
  }
  function toggleChangeRole(cmd) {
    if (cmd === 'open') {
      setModalChangeRoleOpen(true)
    }
    if (cmd === 'close') {
      setModalChangeRoleOpen(false)
    }
  }

  useEffect(() => {
    if (authContext.getCachedUser()) {
      setusername(userdata.employee_name)
      setemail(userdata.employee_email_internal === null ? userdata.employee_name : userdata.employee_email_internal)
      if (userdata.employee_gender === 'P') {
        setuseravatar(avatarFemale)
      }
    }
  }, [])

  const addDefaultPhoto = (ev) => {
    ev.target.src = useravatar
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {userphoto === "" ?
            <Shimmer>
              <div
                className="rounded-circle header-profile-user"
                style={{
                  verticalAlign: 'middle',
                }}
              />
            </Shimmer>
            :
            <img
              onError={addDefaultPhoto}
              className="rounded-circle header-profile-user"
              style={{ 'verticalAlign': 'top', marginTop: '3px', 'objectFit': 'cover' }}
              src={userphoto}
              alt=""
            />
          }
          {username === "" ?
            <Shimmer>
              <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15" style={{ width: 120, }}>&nbsp;</span>
            </Shimmer>
            :
            <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15" style={{ paddingRight: '10px', textAlign: 'left' }}>
              <div>{username}</div>
              <div style={{ fontWeight: 'normal', textAlign: 'left', fontSize: 12 }}>
                &#8212; {' '}
                {localStorage.getItem('currentRoleName')}
              </div>
            </span>
          }
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15" style={{ 'verticalAlign': 'top', marginTop: '14px' }}></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <div className="dropdown-item" style={{ pointerEvents: 'none' }}>
            <i className="dripicons-scale font-size-18 align-middle me-1 text-muted"></i>
            {localStorage.getItem('currentRoleName')}
          </div>
          <div className="dropdown-divider" /> */}
          <Link to="/home/profile.html" className="dropdown-item">
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {email}
          </Link>
          <a target='_blank' href="https://account.live.com/password/change" className="dropdown-item" rel="noreferrer">
            <i className="uil uil-key-skeleton font-size-18 align-middle text-muted me-1"></i>
            Ganti Password
          </a>
          {listAccessRole.length > 1 ?
            <div onClick={() => { toggleChangeRole('open') }} className="dropdown-item" style={{ cursor: 'pointer' }}>
              <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
              Ganti Hak Akses
            </div>
            : null}
          <div className="dropdown-divider" />
          <Link to="/logout.html" className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            Keluar
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Modal
        size="sm"
        isOpen={modalChangeRoleOpen}
        toggle={() => {

        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Ganti Hak Akses
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleChangeRole('close')
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {
            modalChangeRoleOpen ?
              <Select
                name="change_role__"
                value={selectedAccessRole}
                defaultValue={selectedAccessRole.value.toString()}
                onChange={(selected) => {
                  handleSelectAccessRole(selected)
                }}
                options={optionAccessRole}
                classNamePrefix="select2-selection"
              />
              : <></>
          }
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              toggleChangeRole('close')
            }}
            className="btn btn-danger waves-effect"
            data-dismiss="modal"
          >
            Batal
          </button>
          <button
            type="button"
            onClick={() => {
              var ppkUuid = ''
              var accessData = selectedAccessRole.value.split(':')
              var accessId = accessData[0].toString()
              if (accessData.length > 1) {
                ppkUuid = accessData[1]
              }
              localStorage.setItem('currentRoleId', accessId)
              localStorage.setItem('ppkUuid', ppkUuid)
              localStorage.setItem('currentRoleName', selectedAccessRole.label)
              /* localStorage.setItem('currentRoleName', selectedAccessRole.label)
              localStorage.setItem('currentRoleName', selectedAccessRole.label) */
              toggleChangeRole('close')
              window.location.reload();
            }}
            className="btn btn-primary waves-effect waves-light"
          >
            Ganti
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

/* ProfileMenu.propTypes = {
  notificationCount: PropTypes.number,
  notificationList: PropTypes.any
}
const mapStatetoProps = state => {
  return {
    ...state.Layout,
    ...state.Notification
  }
}
export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
) */
export default ProfileMenu