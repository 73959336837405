import {
    apiRequest,
    tunkinT13ProcessSubmit,
    tunkinT13ProcessEmployees,
    tunkinT13ProcessEmployeeDetail,
    tunkinT13CheckStatus,
    tunkinT13GetSigner,
    tunkinT13CheckPresence,
    tunkinT13CheckLevel,
    tunkinT13CheckGpp,
    tunkinT13CheckPtkp,
    tunkinT13CheckPkp,
    tunkinT13CheckEmployeeNew,
    tunkinT13CheckEmployeeChange
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", tunkinT13ProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", tunkinT13ProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tunkinT13ProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", tunkinT13GetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tunkinT13CheckStatus, {
            params: query
        }, true)
    }
    
    fetchCheckPresence = async (query) => {
        return await apiRequest("get", tunkinT13CheckPresence, {
            params: query
        }, true)
    }
    
    fetchCheckLevel = async (query) => {
        return await apiRequest("get", tunkinT13CheckLevel, {
            params: query
        }, true)
    }

    fetchCheckGpp = async (query) => {
        return await apiRequest("get", tunkinT13CheckGpp, {
            params: query
        }, true)
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest("get", tunkinT13CheckPtkp, {
            params: query
        }, true)
    }
    
    fetchCheckPkp = async (query) => {
        return await apiRequest("get", tunkinT13CheckPkp, {
            params: query
        }, true)
    }

    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", tunkinT13CheckEmployeeNew, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", tunkinT13CheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository