import { Subject } from "rxjs"
import apiResponse from "../../../../../services/apiResponse"
import DownloadRepository from "../Repositories/DownloadRepository"

class DownloadBloc {
    downloadSpdChannel = new Subject()
    downloadSpdsChannel = new Subject()
    repository = new DownloadRepository()

    constructor() {
        this.downloadSpdChannel.next({ status: apiResponse.INITIAL })
        this.downloadSpdsChannel.next({ status: apiResponse.INITIAL })
    }

    fetchDownloadSpd = async (query) => {
        this.downloadSpdChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchDownloadSpd(query)
                .then((result) => {
                    this.downloadSpdChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.downloadSpdChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchDownloadSpds = async (query) => {
        this.downloadSpdsChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchDownloadSpds(query)
                .then((result) => {
                    this.downloadSpdsChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.downloadSpdsChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }
}

export default DownloadBloc
