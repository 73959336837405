import { accessRole } from "../../../../../helpers/access_role"
import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import Shimmer from "react-shimmer-effect"

import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, NavLink, NavItem, Nav, TabContent, TabPane, Alert } from "reactstrap"
import RootView from "./RootView"
class IndexView extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="float-end">
                                        {accessRole("create") ? (
                                            <Link to={"/master/tpln.html?create"}>
                                                <Button color="primary">
                                                    <i className="uil-folder-medical"></i> Tambah Data Baru
                                                </Button>
                                            </Link>
                                        ) : null}
                                    </div>
                                    <CardTitle>Tunjangan Kinerja Negara TPLN</CardTitle>
                                    <CardSubtitle className="mb-3">Informasi nilai tunjangan kinerja TPLN golongan dan periode tertentu.</CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md={12}>
                                            <RootView country={{ uuid: "", name: "Semua Negara" }} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView
