import {
    apiRequest,
    officialTravelMonitoringStDetail,
    officialTravelMonitoringStParticipant
} from "../../../../../../services/adapters/process"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", officialTravelMonitoringStDetail, {
            params: query
        }, true)
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelMonitoringStParticipant, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DetailRepository
