import {
    apiRequest,
    uangMakanRegularProcessSubmit,
    uangMakanRegularProcessEmployees,
    uangMakanRegularProcessEmployeeDetail,
    uangMakanRegularCheckStatus,
    uangMakanRegularGetSigner,
    uangMakanRegularCheckPresence,
    uangMakanRegularCheckRank,
    uangMakanRegularCheckTaxFinal,
    uangMakanRegularCheckEmployeeNew,
    uangMakanRegularCheckEmployeeChange
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", uangMakanRegularProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", uangMakanRegularProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", uangMakanRegularProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", uangMakanRegularGetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", uangMakanRegularCheckStatus, {
            params: query
        }, true)
    }

    fetchCheckPresence = async (query) => {
        return await apiRequest("get", uangMakanRegularCheckPresence, {
            params: query
        }, true)
    }

    fetchCheckRank = async (query) => {
        return await apiRequest("get", uangMakanRegularCheckRank, {
            params: query
        }, true)
    }

    fetchCheckTaxFinal = async (query) => {
        return await apiRequest("get", uangMakanRegularCheckTaxFinal, {
            params: query
        }, true)
    }

    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", uangMakanRegularCheckEmployeeNew, {
            params: query
        }, true)
    }

    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", uangMakanRegularCheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository