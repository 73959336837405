import {
    apiRequest,
    masterTunjanganKhususCurrent
} from "../../../../../services/adapters/master"

class IndexViewRepository {

    fetchCurrent = async () => {
        return await apiRequest("get", masterTunjanganKhususCurrent, {}, true)
    }
}

export default IndexViewRepository