import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import OmspanRepository from '../Repositories/OmspanRepository'


class OmspanBloc {

    rowtableChannel = new Subject();
    yearAvailableChannel = new Subject();
    createChannel = new Subject();
    deleteChannel = new Subject();
    repository = new OmspanRepository()

    constructor() {
        this.rowtableChannel.next({ status: apiResponse.INITIAL })
        this.yearAvailableChannel.next({ status: apiResponse.INITIAL })
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.deleteChannel.next({ status: apiResponse.INITIAL })
    }

    fetchRowTable = async (query, cancelToken) => {
        this.rowtableChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRowTable(query, cancelToken).then((result) => {
                this.rowtableChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowtableChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchYearAvailable = async () => {
        this.yearAvailableChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchYearAvailable().then((result) => {
                this.yearAvailableChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.yearAvailableChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchDelete = async (query) => {
        this.deleteChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDelete(query).then((result) => {
                this.deleteChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.deleteChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }
}

export default OmspanBloc