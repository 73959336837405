import {
    apiRequest,
    officialTravelSubmissionSource
} from "../../../../../../services/adapters/process"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", officialTravelSubmissionSource, {
            params: query
        }, true)
    }

}

export default DetailRepository
