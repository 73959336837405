import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Shimmer from "react-shimmer-effect"
import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import CustomSelect from '../../../../../helpers/ui/CustomSelect'

import apiResponse from '../../../../../services/apiResponse'

import UpdateBloc from './Blocs/UpdateBloc'


class IndexView extends Component {

    updateBloc = new UpdateBloc();

    constructor(props) {
        super(props)
        this.state = {
            loadingCountry: true,
            dataForm: {},
            dataUpdate: {}
        }
    }

    submitData = async () => {
        await this.updateBloc.fetchUpdate(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        console.log(values)
        e.preventDefault()
        this.setState({
            dataForm: values,
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    confirmResponse2 = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm2: false
                }
                break;
            case 'confirm':
                forState = {
                    confirm2: false
                }
                break;
            case 'success':
                forState = {
                    success2: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed2: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        /* this.updateBloc.countryChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingCountry: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response.result
                    this.setState({
                        loadingCountry: true,
                        countries: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.fetchCountryList() */

        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            loadingCountry: false,
                            dataUpdate: {
                                uuid: response.official_travel_oversea_uuid,
                                negara: response.official_travel_oversea_region_uuid,
                                region_name: response.official_travel_oversea_region_name,
                                nilai: response.official_travel_oversea_value_amount,
                                gola: response.official_travel_oversea_daily_class_a.toString().replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
                                golb: response.official_travel_oversea_daily_class_b.toString().replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
                                golc: response.official_travel_oversea_daily_class_c.toString().replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
                                gold: response.official_travel_oversea_daily_class_d.toString().replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),

                            }
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.updateBloc.fetchDetail({ uuid: queryParams.get('uuid') })
    }

    handleKeyPress = (event) => {
        var last = event.target.value
        if (last.length >= 10) { event.preventDefault(); }
        if (event.which !== 44) {
            if (event.which !== 8 && isNaN(String.fromCharCode(event.which))) {
                event.preventDefault();
            }
        }
    }
    handleKeyUp = (event, id) => {
        const _this = document.querySelector('#' + id)
        var value = event.target.value
        value = value.charAt(0) === '0' ? value.substring(1) : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Perjalanan Dinas Negara</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Edit Perjalanan Dinas Negara
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="fielCode"
                                                className="col-md-3 col-form-label"
                                            >
                                                Negara
                                            </label>
                                            <Col md="4">

                                                {
                                                    this.state.loadingCountry ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 38 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <><Input
                                                            disabled={true}
                                                            name="region_name"
                                                            type="text"
                                                            value={this.state.dataUpdate.region_name}
                                                        />
                                                            <AvField
                                                                disabled={true}
                                                                name="uuid"
                                                                type="hidden"
                                                                value={this.state.dataUpdate.uuid}
                                                            />
                                                        </>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="fieldTitle"
                                                className="col-md-3 col-form-label"
                                            >
                                                Golongan A<span className="text-danger">*</span>
                                            </label>
                                            <Col md="2">
                                                {
                                                    this.state.loadingCountry ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 38 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <AvInput
                                                                name="official_travel_oversea_value_amount_1"
                                                                placeholder="0"
                                                                align="right"
                                                                type="text"
                                                                errorMessage="Masukkan Nilai"
                                                                className="form-control text-end"
                                                                id="fieldValue1"
                                                                validate={{ required: { value: true } }}
                                                                onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue1") }}
                                                                onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue1") }}
                                                                value={this.state.dataUpdate.gola}
                                                            />
                                                        </div>
                                                }
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <label
                                                htmlFor="fieldTitle"
                                                className="col-md-3 col-form-label"
                                            >
                                                Golongan B<span className="text-danger">*</span>
                                            </label>
                                            <Col md="2">
                                                {
                                                    this.state.loadingCountry ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 38 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <AvInput
                                                                name="official_travel_oversea_value_amount_2"
                                                                placeholder="0"
                                                                align="right"
                                                                type="text"
                                                                errorMessage="Masukkan Nilai"
                                                                className="form-control text-end"
                                                                id="fieldValue2"
                                                                validate={{ required: { value: true } }}
                                                                onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue2") }}
                                                                onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue2") }}
                                                                value={this.state.dataUpdate.golb}
                                                            />
                                                        </div>
                                                }
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <label
                                                htmlFor="fieldTitle"
                                                className="col-md-3 col-form-label"
                                            >
                                                Golongan C<span className="text-danger">*</span>
                                            </label>
                                            <Col md="2">
                                                {
                                                    this.state.loadingCountry ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 38 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <AvInput
                                                                name="official_travel_oversea_value_amount_3"
                                                                placeholder="0"
                                                                align="right"
                                                                type="text"
                                                                errorMessage="Masukkan Nilai"
                                                                className="form-control text-end"
                                                                id="fieldValue3"
                                                                validate={{ required: { value: true } }}
                                                                onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue3") }}
                                                                onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue3") }}
                                                                value={this.state.dataUpdate.golc}
                                                            />
                                                        </div>
                                                }
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <label
                                                htmlFor="fieldTitle"
                                                className="col-md-3 col-form-label"
                                            >
                                                Golongan D<span className="text-danger">*</span>
                                            </label>
                                            <Col md="2">
                                                {
                                                    this.state.loadingCountry ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 38 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <AvInput
                                                                name="official_travel_oversea_value_amount_4"
                                                                placeholder="0"
                                                                align="right"
                                                                type="text"
                                                                errorMessage="Masukkan Nilai"
                                                                className="form-control text-end"
                                                                id="fieldValue4"
                                                                validate={{ required: { value: true } }}
                                                                onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue4") }}
                                                                onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue4") }}
                                                                value={this.state.dataUpdate.gold}
                                                            />
                                                        </div>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="3"></Col>
                                            <Col md="9">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="3"></Col>
                                            <Col md="9">
                                                <Button color="success" disabled={this.state.status_button} id="btnsubmit" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/master/spd-country.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                <ConfirmAlert
                    failedTitle="Gagal!"
                    failedInfo="Data sudah tersedia"

                    showFailed={this.state.failed2}
                    showConfirm={this.state.confirm2}
                    response={this.confirmResponse2}
                />
                {this.state.redirect ?
                    <Redirect to='/master/spd-country.html' />
                    : null}
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);