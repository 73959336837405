import {
    apiRequest,
    tunkinRegulerProcessSubmit,
    tunkinRegulerProcessEmployees,
    tunkinRegulerProcessEmployeeDetail,
    tunkinRegulerCheckStatus,
    tunkinRegulerGetSigner,
    tunkinRegulerCheckPresence,
    tunkinRegulerCheckLevel,
    tunkinRegulerCheckGpp,
    tunkinRegulerCheckPtkp,
    tunkinRegulerCheckPkp,
    tunkinRegulerCheckOtherMonth,
    tunkinRegulerCheckEmployeeNew,
    tunkinRegulerCheckEmployeeChange,
    tunkinRegulerReset,
} from "../../../../../../services/adapters/process"

class CreateRepository {
    fetchCreate = async (query) => {
        return await apiRequest(
            "post",
            tunkinRegulerProcessSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunkinRegulerProcessEmployees,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerProcessEmployeeDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchGetSigner = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerGetSigner,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerCheckStatus,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckPresence = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerCheckPresence,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckLevel = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerCheckLevel,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckGpp = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerCheckGpp,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerCheckPtkp,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckPkp = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerCheckPkp,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckOtherMonth = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerCheckOtherMonth,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerCheckEmployeeNew,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerCheckEmployeeChange,
            {
                params: query,
            },
            true
        )
    }

    fetchReset = async (query) => {
        return await apiRequest(
            "post",
            tunkinRegulerReset,
            {
                body: query,
            },
            true
        )
    }
}

export default CreateRepository
