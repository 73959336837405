import {
    apiRequest,
    tplnSusulanApproveSubmit,
    tplnSusulanRejectSubmit,
    tplnSusulanResultEmployee,
    tplnSusulanCheckStatus,
    tplnSusulanResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", tplnSusulanApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", tplnSusulanRejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", tplnSusulanResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tplnSusulanResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tplnSusulanCheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository