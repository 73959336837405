import {
    apiRequest,
    tunkinRegulerVerify,
    tunkinRegulerCompare,
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadVerify = async (query) => {
        return await apiRequest("get", tunkinRegulerVerify, {
            params: query
        }, true)
    }
    
    fetchDownloadCompare = async (query) => {
        return await apiRequest("get", tunkinRegulerCompare, {
            params: query
        }, true)
    }
}

export default DownloadRepository