import { accessRole } from "../../../../../../helpers/access_role"
import React, { Fragment, useState, useRef } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"

import TableActiveComponent from './Components/TableActiveComponent'
import TableInactiveComponent from './Components/TableInactiveComponent'
import TableInformasiComponent from './Components/TableInformasiComponent'


const IndexView = (props) => {

    const [activeTab, setactiveTab] = useState("1")    

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                    {accessRole('create') ? (
                                        <Link to={'/master/informasi.html?create'}>
                                            <Button color="success">Tambah Data Baru</Button>
                                        </Link>
                                    ) : null}
                                </div>
                                <CardTitle>Informasi</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Master Informasi
                                </CardSubtitle>
                                <hr />
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block">Pengumuman</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block">Peraturan</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                            active: activeTab === "3",
                                                })}
                                            onClick={() => {
                                                toggle("3")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">FAQ</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <TableActiveComponent />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <TableInactiveComponent />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <TableInformasiComponent />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView