import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, useRef, useState } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button } from "reactstrap"

import TableListComponent from "./Components/TableListComponent"

const IndexView = (props) => {
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Pengajuan Rapel Perjalanan Dinas</CardTitle>
                                <CardSubtitle className="mb-3">Informasi data Pengajuan Rapel Perjalanan Dinas dari Pejabat Pembuat Komitmen.</CardSubtitle>
                                <hr />
                                <TableListComponent />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView
