import {
    apiRequest,
    masterUangMakanRegulationList,
    masterUangMakanRegulationDetail
} from "../../../../../services/adapters/master"

class RegulationRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterUangMakanRegulationList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    
    fetchDetail = async (query) => {
        return await apiRequest("get", masterUangMakanRegulationDetail, {
            params: query
        }, true)
    }
}

export default RegulationRepository