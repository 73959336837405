import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap"
import Shimmer from "react-shimmer-effect"
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Select from "react-select"
import { Link, useHistory, Redirect } from "react-router-dom"
import { config } from "../../../../../helpers/config_global"
import { cancelToken } from "../../../../../services/adapters/base"
import apiResponse from '../../../../../services/apiResponse'
import MKIDatatable from '../../../../../modules/mki-datatable/lib'
import CreateBloc from './Blocs/CreateBloc'

var reformatCode = function (regions) {
    return regions.map(function (region) {
        var newObj = {};
        newObj["label"] = region.nrs_code;
        newObj["value"] = region.nrs_code;
        return newObj;
    });
};

class IndexView extends Component {

    createBloc = new CreateBloc();

    source
    historyTable = 'historyEmployeeNRS'
    defaultSize = 10
    defaultOrder = 'employee_nip'
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {
                nrs_employee_nrs_recipient_nip: '',
                nrs_employee_nrs_recipient_npwp: '',
                nrs_employee_nrs_recipient_name: '',
                nrs_employee_nrs_account_number: '',
            },
            messageError: "",
            nrs_option: [],
            nrs_code: '',
            loadingData: true,
            modalShow: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
            JSON.parse(sessionStorage.getItem(this.historyTable)) :
            {
                filter_value: "",
                page_number: 1,
                page_size: this.defaultSize,
                sort_order: {
                    column: this.defaultOrder,
                    order: this.defaultSort
                }
            }
        }
    }

    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order
        }
        await this.createBloc.fetchEmployee(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    submitData = async () => {
        await this.createBloc.fetchCreate(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: values,
            confirm: true
        })
    }

    onChangeNrsCode = (type) => {
        this.setState({
            nrs_code: type.value,
        });
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {

        this.setTokenAPI()

        this.createBloc.employeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.listNrsChannel.subscribe((result) => {
            if (result.data) {
                if (result.data.response) {
                    let rows = result.data.response
                    this.setState({
                        nrs_option: reformatCode(rows)
                    })
                }
            }
        })

       this.createBloc.fetchNrs()
       this.createBloc.fetchEmployee()
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
        this.createBloc.listNrsChannel?.unsubscribe()
        this.createBloc.employeeChannel?.unsubscribe()
    }

    columns = [
        {
            key: "employee_nip",
            text: "NIP",
            sortable: true,
        },
        {
            key: "employee_name",
            text: "Nama Pegawai",
            sortable: true
        },
        {
            key: "employee_bank_account_number",
            text: "No Rekening",
            sortable: true,
        },
        {
            key: "employee_npwp_number",
            text: "NPWP",
            sortable: true,
        },
        {
            key: "employee_uuid",
            text: "Aksi",
            className: "action",
            width: 100,
            sortable: false,
            align: 'center',
            cell: record => {
                return (
                    <Fragment>
                         <span>
                            <button
                                className="btn btn-primary btn-sm"
                                id={record.employee_uuid}
                                onClick={() => {
                                    this.setState({
                                        dataForm: {
                                            nrs_employee_nrs_recipient_nip: record.employee_nip,
                                            nrs_employee_nrs_recipient_npwp:  record.employee_npwp_number,
                                            nrs_employee_nrs_recipient_name:  record.employee_name,
                                            nrs_employee_nrs_account_number:  record.employee_bank_account_number
                                        },
                                        modalShow: false
                                    })
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                <i class="fas fa-check"></i>
                            </button>
                        </span>
                    </Fragment>
                );
            }
        }
    ]

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">

                            { this.state.messageError ? <>
                                <div className="alert alert-danger">
                                    <strong>{ this.state.messageError }</strong>
                                </div>
                            </> : <></> }

                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah NRS</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />

                                  

                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                       
                                       <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode NRS<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <Select
                                                    value={this.state.nrs_option.filter(option =>
                                                        option.value === this.state.nrs_code)}
                                                    onChange={this.onChangeNrsCode}
                                                    options={this.state.nrs_option}
                                                    classNamePrefix=""
                                                    placeholder="Pilih Kode NRS"
                                                />
                                                <AvField
                                                    name="nrs_employee_nrs_code"
                                                    id="nrs_employee_nrs_code"
                                                    type="hidden"
                                                    className="form-control"
                                                    value={this.state.nrs_code}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode KPPN<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_kppn_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode KPPN"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_kppn_code"
                                                    validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Tipe Supplier
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_supplier_type_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Tipe Supplier"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_supplier_type_code"
                                                    validate={{ required: { value: false } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode Pos
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_postal_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode Pos"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_postal_code"
                                                    validate={{ required: { value: false } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                NPWP Supplier
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_supplier_npwp"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan NPWP Supplier"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_supplier_npwp"
                                                    validate={{ required: { value: false } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama Supplier
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_supplier_name"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Nama Supplier"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_supplier_name"
                                                    validate={{ required: { value: false } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Bank Supplier
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_supplier_bank"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Bank Supplier"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_supplier_bank"
                                                    validate={{ required: { value: false } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode NAB
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_nab_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode NAB"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_nab_code"
                                                    validate={{ required: { value: false } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode Valas
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_currency_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode Valas"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_currency_code"
                                                    validate={{ required: { value: false } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode Swift/IBAN
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_swift_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode Swift/IBAN"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_swift_code"
                                                    validate={{ required: { value: false } }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                NIP Penerima<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_recipient_nip"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan NIP Penerima"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_recipient_nip"
                                                    validate={{ required: { value: true } }}
                                                    readOnly={true}
                                                    value={this.state.dataForm.nrs_employee_nrs_recipient_nip}
                                                />
                                            </Col>
                                            <Col md="2">
                                                <Button
                                                    color="primary"
                                                    className=""
                                                    onClick={e => {
                                                        this.setState({ modalShow: true })
                                                    }}
                                                >
                                                <i className="uil-plus"></i>
                                                </Button>         
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama Penerima<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_recipient_name"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Nama Penerima"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_recipient_name"
                                                    validate={{ required: { value: true } }}
                                                    readOnly={true}
                                                    value={this.state.dataForm.nrs_employee_nrs_recipient_name}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                NPWP Penerima<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_recipient_npwp"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan NPWP Penerima"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_recipient_npwp"
                                                    validate={{ required: { value: true } }}
                                                    readOnly={true}
                                                    value={this.state.dataForm.nrs_employee_nrs_recipient_npwp}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                No Rekening Penerima<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_employee_nrs_account_number"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan No Rekening Penerima"
                                                    className="form-control"
                                                    id="nrs_employee_nrs_account_number"
                                                    validate={{ required: { value: true } }}
                                                    readOnly={true}
                                                    value={this.state.dataForm.nrs_employee_nrs_account_number}
                                                />
                                            </Col>
                                        </Row>
                                       
                                       
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <Button color="danger" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/master/mapping-nrs.html">
                                                    <Button color="success"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    className='modal-xl'
                    isOpen={this.state.modalShow}
                    toggle={() => {
                        this.setState({
                            modalShow: false
                        })
                    }}
                >
                    <ModalHeader
                        toggle={() => {
                            this.setState({
                                modalShow: false
                            })
                        }}
                    >Daftar Pegawai</ModalHeader>
                    <ModalBody>
                        <MKIDatatable
                            className={this.configDatatable.tableClass}
                            config={this.configDatatable}
                            records={this.state.rows}
                            columns={this.columns}
                            dynamic={true}
                            minWidth={1000}
                            total_record={this.state.totalRow}
                            initial={this.state.filter}
                            onChange={(filter) => {
                                this.setState({
                                    loadingTable: true,
                                    filter: filter
                                }, function () {
                                    this.setTokenAPI()
                                });
                            }}
                            loading={this.state.loadingTable}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => {
                                this.setState({
                                    modalShow: false
                                })
                            }}><i className="uil-arrow-left"></i> Tutup
                        </Button>
                    </ModalFooter>
                </Modal>


                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/master/mapping-nrs.html'/>
                : null }
            </Fragment>
        )
    }

}

export default IndexView