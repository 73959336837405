import { apiRequest, officialTravelRapelVerificationDetail, officialTravelRapelVerificationParticipant } from "../../../../../../services/adapters/process"

class DetailRepository {
    fetchDetail = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelVerificationDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            officialTravelRapelVerificationParticipant,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }
}

export default DetailRepository
