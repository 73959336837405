import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../../services/adapters/base"

import RegulationBloc from '../Blocs/RegulationBloc'
import apiResponse from '../../../../../../services/apiResponse'
import RegulationDetailComponent from './RegulationDetailComponent'

import moment from 'moment'

class RegulationlistComponent extends Component {
    regulationBloc = new RegulationBloc();

    source
    historyTable = 'historyMasterTunkinPtspRegulationList'
    defaultOrder = 'regulation_date_start'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            page: 'list',
            tooltip: false,
            detailUuid: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            uuid: this.props.uuid
        }
        await this.regulationBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.inactiveData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }
    
    inactiveData = async () => {
        await this.regulationBloc.fetchInactive({ uuid: this.state.inactiveUuid })
    }

    componentDidMount() {
        this.setTokenAPI()

        this.regulationBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.regulationBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        {
            key: "regulation_number",
            text: "Nomor SK",
            className: "",
            sortable: true,
            width: '*',
            align: 'left',
            cell: record => {
                return (
                    <>
                        <div className="d-inline-block">
                            {
                                record.regulation_active === "0" ? <div className="bg-danger rounded-circle d-inline-block" style={{height: 10, width: 10}}></div> :
                                record.regulation_active === "1" ? <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div> : <div className="bg-primary rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>
                            }
                        </div>
                        {" "} { record.regulation_number }
                    </>
                )
            }
        },
        {
            key: "regulation_date",
            text: "Penetapan",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-center">
                            { moment(record.regulation_date).format('LL') }
                        </div>
                    </>
                )
            }
        },
        {
            key: "regulation_date_start",
            text: "Mulai",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-center">
                            { moment(record.regulation_date_start).format('LL') }
                        </div>
                    </>
                )
            }
        },
        /* {
            key: "regulation_date_end",
            text: "Berakhir",
            className: "",
            sortable: true,
            width: '160',
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-center">
                            { record.regulation_date_end === null ? '-' :
                                moment(record.regulation_date_end).format('LL')
                            }
                        </div>
                    </>
                )
            }
        }, */
        {
            key: "regulation_uuid",
            text: "Aksi",
            className: "",
            sortable: true,
            width: '120',
            align: 'center',
            cell: record => {
                const thisId = record.regulation_id
                return (
                    <Fragment>
                        <span>
                            <button
                                className="btn btn-primary btn-sm"
                                id={"push-" + thisId}
                                onClick={() => {
                                    this.props.changeUUID(record)
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-tag-alt"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["push" + thisId]} target={"push-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["push" + thisId] : !this.state["push" + thisId]
                                    })
                                }}
                            >Lihat Tunjangan</Tooltip>
                        </span>
                        <span>
                            <button 
                                className="btn btn-success btn-sm" 
                                id={"view-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        page: 'detail',
                                        detailUuid: record.regulation_uuid
                                    })
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                <i className="uil-align-left"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["view" + thisId]} target={"view-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["view" + thisId] : !this.state["view" + thisId]
                                    })
                                }}
                            >Lihat SK</Tooltip>
                        </span>
                    </Fragment>
                );
            }
        }
    ]

    handleToList = () => {
        
        this.setState({
            page: 'list'
        });
    }

    render() {
        return (
            <>
                <div style={{display: (this.state.page === 'list' ? 'block': 'none') }}>
                    <MKIDatatable
                        className={this.configDatatable.tableClass}
                        config={this.configDatatable}
                        records={this.state.rows}
                        columns={this.columns}
                        dynamic={true}
                        total_record={this.state.totalRow}
                        initial={this.state.filter}
                        onChange={(filter) => {
                            this.setState({
                                loadingTable: true,
                                filter: filter
                            }, function () {
                                this.setTokenAPI()
                            });
                        }}
                        loading={this.state.loadingTable}
                    />
                </div>
                {
                    this.state.page === 'list' ? null : <RegulationDetailComponent uuid={this.state.detailUuid} handleToList={this.handleToList} />
                }
            </>
        )
    }



}

export default RegulationlistComponent