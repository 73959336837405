import {
    apiRequest,
    simapanTrackingYears,
    simapanTrackingMonth,
} from "../../../../../../services/adapters/sync"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", simapanTrackingYears, {}, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", simapanTrackingMonth, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default IndexViewRepository