import {
    apiRequest,
    payrollSkpReady,
    payrollSkpCreate,
    payrollSkpEmployee
} from "../../../../../../services/adapters/process"

class CreateRepository {
    fetchReady = async (query) => {
        return await apiRequest("get", payrollSkpReady, {params: query}, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", payrollSkpCreate, {
            body: query
        }, true)
    }

    fetchEmployee = async (query) => {
        return await apiRequest("get", payrollSkpEmployee, {
            params: query
        }, true)
    }
}

export default CreateRepository