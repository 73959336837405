import { apiRequest, officialTravelSpd, officialTravelSpds } from "../../../../../services/adapters/output"

class DownloadRepository {
    fetchDownloadSpd = async (query) => {
        return await apiRequest(
            "get",
            officialTravelSpd,
            {
                params: query,
            },
            true
        )
    }
    fetchDownloadSpds = async (query) => {
        return await apiRequest(
            "get",
            officialTravelSpds,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
