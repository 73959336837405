import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Modal,
    Alert,
    Spinner
} from "reactstrap"

import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { generateFormData } from '../../../../../helpers/ui/FileInput'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect, withRouter } from "react-router-dom"
import apiResponse from '../../../../../services/apiResponse'
import EmployeeListComponent from './Components/EmployeeListComponent'
import CreateBloc from './Blocs/CreateBloc'
import CustomSelect from '../../../../../helpers/ui/CustomSelect'

import LoadingComponent from './Components/LoadingComponent'
import { timesSeries } from 'async'



class CreateView extends Component {

    createBloc = new CreateBloc()

    source
    constructor(props) {
        super(props)
        this.state = {
            tplnStatus: null,
            tplnReason: '',
            tplnStatusName: "",
            uuid: null,
            dataForm: {},
            loadingCheckAll: false,
            loadingCheckPresence: true,
            loadingCheckRank: true,
            loadingCheckTaxFinal: true,

            loadingCheckPresenceStatus: false,
            loadingCheckRankStatus: false,
            loadingCheckTaxFinalStatus: false,

            loadingSignerLeft: true,
            loadingSignerRight: true,
        }
        this.createBloc.fetchCheckStatus({ uuid: this.props.uuid })
    }

    allPAss = () => {
        if (
            this.state.loadingCheckPresenceStatus === true &&
            this.state.loadingCheckRankStatus === true &&
            this.state.loadingCheckTaxFinalStatus === true &&
            this.state.loadingSignerLeft === false &&
            this.state.loadingSignerRight === false
        ) {
            this.setState({
                loadingCheckAll: true
            })
        } else {
            this.setState({
                loadingCheckAll: false
            })
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm)
        await this.createBloc.fetchCreate(formData)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.checkStatusChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    if (response.status === 1 || response.status === 3) {
                        this.setState({
                            tplnStatus: response.status,
                            tplnReason: response.reason
                        });
                        this.createBloc.fetchCheckPresence({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckRank({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckTaxFinal({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckEmployeeNew({ uuid: this.props.uuid })
                        this.createBloc.fetchCheckEmployeeChange({ uuid: this.props.uuid })
                        this.createBloc.fetchGetSignerLeft({ uuid: '8e76798e-b27f-4276-a375-60a001d3c225' })
                        this.createBloc.fetchGetSignerRight({ uuid: '2f087de1-7714-441c-9255-61279bdd87f2' })
                    } else {
                        this.setState({
                            tplnStatusName: "Proses tidak dapat dilakukan"
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.getSignerLeft.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingSignerLeft: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingSignerLeft: false,
                        signerLeft: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
            this.allPAss()
        })

        this.createBloc.getSignerRight.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingSignerRight: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingSignerRight: false,
                        signerRight: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
            this.allPAss()
        })

        this.createBloc.checkPresenceChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckPresence: false,
                            loadingCheckPresenceStatus: true,
                            loadingCheckPresenceText: 'Data Absensi tersedia',
                            loadingCheckPresenceDetail: []
                        });
                    } else {
                        this.setState({
                            loadingCheckPresence: false,
                            loadingCheckPresenceStatus: false,
                            loadingCheckPresenceText: response.message,
                            loadingCheckPresenceDetail: response.response
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkRankChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckRank: false,
                            loadingCheckRankStatus: true,
                            loadingCheckRankText: 'Data Golongan tersedia'
                        });
                    } else {
                        this.setState({
                            loadingCheckRank: false,
                            loadingCheckRankStatus: false,
                            loadingCheckRankText: response.message
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
            this.allPAss()
        })
        this.createBloc.checkTaxFinalChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data
                    if (response.status === true) {
                        this.setState({
                            loadingCheckTaxFinal: false,
                            loadingCheckTaxFinalStatus: true,
                            loadingCheckTaxFinalText: 'Data Pajak Final tersedia'
                        });
                    } else {
                        this.setState({
                            loadingCheckTaxFinal: false,
                            loadingCheckTaxFinalStatus: false,
                            loadingCheckTaxFinalText: response.message
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
            this.allPAss()
        })
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
        this.createBloc.checkStatusChannel?.unsubscribe()
        this.createBloc.checkPresenceChannel?.unsubscribe()
        this.createBloc.checkTaxFinalChannel?.unsubscribe()
        this.createBloc.getSignerLeft?.unsubscribe()
        this.createBloc.getSignerRight?.unsubscribe()
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Proses Uang Makan Rapel</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Pengecekan data Uang Makan Rapel perpegawain dan pemrosesan data Uang Makan
                                    </CardSubtitle>
                                    <hr />
                                    {this.state.tplnStatus ?
                                        <>
                                            {this.state.tplnStatus === 3 ?
                                                <>
                                                    <Row className="mb-3">
                                                        <Col md="12">
                                                            <Alert color="warning">
                                                                <b>Data sebelumnya ditolak:</b> {this.state.tplnReason}
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                </>
                                                : null
                                            }
                                            <AvForm
                                                className="needs-validation"
                                                onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                            >
                                                <AvField
                                                    name="uuid"
                                                    type="hidden"
                                                    id="uuid"
                                                    value={this.props.uuid}
                                                />
                                                <Row className="mb-3">
                                                    <Col md="12">
                                                        {
                                                            this.state.loadingCheckTaxFinal === true ?
                                                                <LoadingComponent message="Memeriksa Data Referensi Pajak Final..." />
                                                                :
                                                                <Alert color={this.state.loadingCheckTaxFinalStatus ? "success" : "danger"}>
                                                                    <table width="100%">
                                                                        <tr>
                                                                            <td width="30px" style={{ verticalAlign: 'top' }}>
                                                                                <i className={"uil " + (this.state.loadingCheckTaxFinalStatus ? "uil-check" : "uil-times")}></i>
                                                                            </td>
                                                                            <td style={{ verticalAlign: 'top' }}>
                                                                                {this.state.loadingCheckTaxFinalText}
                                                                            </td>
                                                                            {!this.state.loadingCheckTaxFinalStatus ?
                                                                                <td className="text-end" style={{ verticalAlign: 'top' }}>
                                                                                    <Link to="/master/pajak-ptkp.html">
                                                                                        Master Pajak Final
                                                                                    </Link>
                                                                                </td> : null
                                                                            }
                                                                        </tr>
                                                                    </table>
                                                                </Alert>
                                                        }
                                                        {
                                                            this.state.loadingCheckRank === true ?
                                                                <LoadingComponent message="Memeriksa Data Referensi Golongan..." />
                                                                :
                                                                <Alert color={this.state.loadingCheckRankStatus ? "success" : "danger"}>
                                                                    <table width="100%">
                                                                        <tr>
                                                                            <td width="30px" style={{ verticalAlign: 'top' }}>
                                                                                <i className={"uil " + (this.state.loadingCheckRankStatus ? "uil-check" : "uil-times")}></i>
                                                                            </td>
                                                                            <td style={{ verticalAlign: 'top' }}>
                                                                                {this.state.loadingCheckRankText}
                                                                            </td>
                                                                            {!this.state.loadingCheckRankStatus ?
                                                                                <td className="text-end" style={{ verticalAlign: 'top' }}>
                                                                                    <Link to="/master/tunkin.html">
                                                                                        Master Grade
                                                                                    </Link>
                                                                                </td> : null
                                                                            }
                                                                        </tr>
                                                                    </table>
                                                                </Alert>
                                                        }
                                                        {
                                                            this.state.loadingCheckPresence === true ?
                                                                <LoadingComponent message="Memeriksa Data Absensi Pegawai..." />
                                                                :
                                                                <Alert color={this.state.loadingCheckPresenceStatus ? "success" : "danger"}>
                                                                    <table width="100%">
                                                                        <tr>
                                                                            <td width="30px" style={{ verticalAlign: 'top' }}>
                                                                                <i className={"uil " + (this.state.loadingCheckPresenceStatus ? "uil-check" : "uil-times")}></i>
                                                                            </td>
                                                                            <td style={{ verticalAlign: 'top' }}>
                                                                                {this.state.loadingCheckPresenceText}<br />
                                                                                {
                                                                                    this.state.loadingCheckPresenceDetail.map((item, key) => {
                                                                                        return (
                                                                                            <li>
                                                                                                <small>{item}</small>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </td>
                                                                            {!this.state.loadingCheckPresenceStatus ?
                                                                                <td className="text-end" style={{ verticalAlign: 'top' }}>
                                                                                    <Link to="/integration/simpeg-presention.html">
                                                                                        Data Absensi
                                                                                    </Link>
                                                                                </td> : null
                                                                            }
                                                                        </tr>
                                                                    </table>
                                                                </Alert>
                                                        }
                                                        <hr style={{ marginTop: 0 }} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md="12">
                                                        <EmployeeListComponent uuid={this.props.uuid} />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Label
                                                        htmlFor="fieldNama"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Bendahara Pengeluaran
                                                    </Label>
                                                    <Col md="5">
                                                        {this.state.loadingSignerLeft ?
                                                            <><Shimmer><div className="shimmer" style={{ width: '100%', height: 38 }}></div></Shimmer>{" "}</>
                                                            :
                                                            <>
                                                                <CustomSelect
                                                                    name="signer_left"
                                                                    errorMessage="Pilih Penandatangan"
                                                                    options={this.state.signerLeft}
                                                                    defaultValue={null}
                                                                    placeholder="Pilih Penandatangan"
                                                                    optionLabel="signer_employee_name"
                                                                    optionValue="signer_uuid"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label
                                                        htmlFor="fieldNama"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Pejabat Pembuat Komitmen
                                                    </Label>
                                                    <Col md="5">
                                                        {this.state.loadingSignerRight ?
                                                            <><Shimmer><div className="shimmer" style={{ width: '100%', height: 38 }}></div></Shimmer>{" "}</>
                                                            :
                                                            <>
                                                                <CustomSelect
                                                                    name="signer_right"
                                                                    errorMessage="Pilih Penandatangan"
                                                                    options={this.state.signerRight}
                                                                    defaultValue={null}
                                                                    placeholder="Pilih Penandatangan"
                                                                    optionLabel="signer_employee_name"
                                                                    optionValue="signer_uuid"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>
                                                        }
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <div className="form-check form-switch mb-3">
                                                            <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                                <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                            </AvCheckboxGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <Link to="/payroll/uang-makan-rapel.html">
                                                            <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                        </Link>
                                                        {' '}
                                                        <Button color="success" type="submit" disabled={!this.state.loadingCheckAll}>
                                                            <i className="uil-arrow-up-right"></i> Proses Data Uang Makan
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </>
                                        :
                                        <>
                                            {
                                                this.state.tplnStatusName === "" ?
                                                    <LoadingComponent message="Memeriksa status Uang Makan..." />
                                                    :
                                                    <>
                                                        <Row>
                                                            <Col md="12">
                                                                <Alert color="danger">
                                                                    <table width="100%">
                                                                        <tr>
                                                                            <td width="30px" style={{ verticalAlign: 'top' }}>
                                                                                <i className="uil uil-times"></i>
                                                                            </td>
                                                                            <td style={{ verticalAlign: 'top' }}>
                                                                                {this.state.tplnStatusName}
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </Alert>
                                                            </Col>
                                                        </Row>
                                                    </>
                                            }
                                            <hr style={{ marginTop: 0 }} />
                                            <Row>
                                                <Col md="10">
                                                    <Link to="/payroll/uang-makan-rapel.html">
                                                        <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                {this.state.redirect ?
                    <Redirect to='/payroll/uang-makan-rapel.html' />
                    : null}
            </Fragment>
        )
    }

}

export default CreateView