import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'


class CreateBloc {

    createChannel = new Subject();
    getInformasiChannel = new Subject();
    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({status: apiResponse.INITIAL})
        this.getInformasiChannel.next({status: apiResponse.INITIAL})
    }

    fetchCreate = async (query) => {
        this.createChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.createChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchGetInformasi = async () => {
        this.getInformasiChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetInformasi().then((result) => {
                this.getInformasiChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getInformasiChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Layanan Unsubscribed') 
        }
    }

    
}

export default CreateBloc