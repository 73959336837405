import {
    apiRequest,
    tunsusRapelProcessSubmit,
    tunsusRapelProcessEmployees,
    tunsusRapelProcessEmployeeDetail,
    tunsusRapelCheckStatus,
    tunsusRapelGetSigner,
    tunsusRapelCheckLevel,
    tunsusRapelCheckPtkp,
} from "../../../../../../services/adapters/process"

class CreateRepository {
    fetchCreate = async (query) => {
        return await apiRequest(
            "post",
            tunsusRapelProcessSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunsusRapelProcessEmployees,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelProcessEmployeeDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchGetSigner = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelGetSigner,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelCheckStatus,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckLevel = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelCheckLevel,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelCheckPtkp,
            {
                params: query,
            },
            true
        )
    }
}

export default CreateRepository
