import { accessRole } from "../../../../helpers/access_role"
import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import ErrorComingSoon from '../../../../components/Error/Content/ComengSoon'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import apiResponse from '../../../../services/apiResponse'
import IndexBloc from './Blocs/IndexBloc'
import Shimmer from "react-shimmer-effect"
import moment from 'moment'
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import Slider from "react-slick";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import MinioClient from "../../../../services/minioClient"

import {
    Container,
    Row, Col,
    CardHeader, CardBody, Card, CardTitle, CardSubtitle,
    Button,
    CardFooter,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Input, Collapse
} from "reactstrap"

function formatDate(dateString) {
    const months = [
        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

class IndexView extends Component {
    minioClient = new MinioClient()
    indexBloc = new IndexBloc();

    constructor(props) {
        super(props)
        this.state = {
            loadingInformasi: false,
            informasi: [],
        }
    }

    componentDidMount() {
        this.indexBloc.getPeraturanChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({ loadingInformasi: true });
                    break;
                case apiResponse.COMPLETED:
                    const response = result.data.response;
                    const informasiOptions = response.map(subitem => ({
                        id: subitem.informasi_id,
                        label: subitem.informasi_subname,
                        description: subitem.informasi_description,
                        date: formatDate(subitem.informasi_create_date),

                    }));
                    this.setState({ loadingInformasi: false, informasi: informasiOptions });
                    break;
                case apiResponse.ERROR:
                    this.setState({ loadingInformasi: false });
                    break;
                default:
                    break;
            }
        });
        
        this.indexBloc.fetchGetPeraturan();

        this.loadData()
    }

    componentWillUnmount() {
        this.indexBloc.getPeraturanChannel?.unsubscribe()
    }

    generateFile = async (path) => {
        //alert(path)
        this.setState({
            loadingGenerate: true,
        })
        this.minioClient.get(path).then((url) => {
            this.setState({
                loadingGenerate: false,
                successGenerate: true,
            })
            setTimeout(() => {
                window.open(url, "_blank")
                this.setState({
                    successGenerate: false,
                })
            }, 1000)
        })
    }

    loadData = () => {
        this.indexBloc.fetchDashboardReport()
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        let formPencarian = {
            ...values
        }
        this.setState({ dashboardPengajuanLoading: true })
        this.indexBloc.fetchDashboarPengajuan(formPencarian)
    }

    toggleDetail = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    };

    toggleAmountVisibility = () => {
        this.setState(prevState => ({
            showAmount: !prevState.showAmount
        }));
    }

    formatNumber(number) {
        return new Intl.NumberFormat('id-ID', { minimumFractionDigits: 0 }).format(number);
    }

    SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", left: '10px', zIndex: 2 }} // tambahkan z-index
            onClick={onClick}
          >
            <i className="uil uil-angle-left"></i>
          </div>
        );
    }
    
    SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", right: '10px', zIndex: 2 }} // tambahkan z-index
            onClick={onClick}
          >
            <i className="uil uil-angle-right"></i>
          </div>
        );
    }

    setActiveService = (service) => {
        this.setState({ activeService: service });
    }
    
    render() {
        const settings = {
            dots: false,
            infinite: false,
            width: '200px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const settingsb = {
            dots: false,
            infinite: true,
            width: '250px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const settingsc = {
            dots: false,
            infinite: true,
            width: '250px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const settingsd = {
            dots: false,
            infinite: true,
            width: '280px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const containerStyle = {
            display: 'flex',
            justifyContent: 'center', // opsional, untuk memposisikan card di tengah kontainer
        };

        const fixedCardStyle = {
            width: '240px',
            height: '230px', // contoh, sesuaikan dengan kebutuhan Anda
            overflow: 'auto', // agar konten yang melebihi tinggi card bisa discroll
            marginLeft: '40px',
            borderRadius: '15px'
        };

        const fixedCardStyleInfo = {
            width: '310px',
            height: '250px', // contoh, sesuaikan dengan kebutuhan Anda
            overflow: 'auto' // agar konten yang melebihi tinggi card bisa discroll
        };

        const { informasi } = this.state;

        return (
            <Fragment>
                <Container fluid>
                    <div className="clearfix mb-2">
                        <div>
                            <Card className="p-3" style={{ width: '100%' }}>
                            <div className="mt-4 text-center"> 
                                <h2>Peraturan</h2>
                                {/* <p style={{ marginBottom: '50px'}}>Pengumuman dan Peraturan Terbaru Terkait Keuangan</p> */}
                                {informasi.length > 0 ? (
                                    <div>
                                        <Slider {...settings}>
                                            {informasi.map((informasiItem, index) => (
                                                <div className="d-flex justify-content-center" style={{ marginTop:'10px' }} key={index}>
                                                    <Link to={informasiItem.link}>
                                                        <Card style={fixedCardStyleInfo}>
                                                            <CardHeader>
                                                                <p style={{ textAlign: 'left', fontSize: '10px' }}>{informasiItem.date}</p>
                                                                <h5 style={{ textAlign: 'left' }}>{informasiItem.label}</h5> {/* Judul di tengah */}
                                                            </CardHeader>
                                                            <CardBody>
                                                                <p style={{ textAlign: 'left' }}>{informasiItem.description}</p> {/* Konten teks */}
                                                            </CardBody>
                                                        </Card>
                                                    </Link>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                ) : (
                                    <p>Sedang memuat informasi...</p>
                                )}
       
                            </div>
                            </Card>

                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView