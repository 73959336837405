import {
    apiRequest,
    officialTravelRefundList,
    officialTravelRefundCancel,
    officialTravelRefundUpload,
    officialTravelRefundDetail
} from "../../../../../services/adapters/process"

class IndexViewRepository {

    fetchRowRefundList = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelRefundList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRefundDetail = async (query) => {
        return await apiRequest("get", officialTravelRefundDetail, {
            params: query
        }, true)
    }

    fetchRefundCancel = async (query) => {
        return await apiRequest("post", officialTravelRefundCancel, {
            body: query
        }, true)
    }

    fetchRefundUpload = async (query) => {
        return await apiRequest("post", officialTravelRefundUpload, {
            body: query
        }, true)
    }
}

export default IndexViewRepository