import {
    apiRequest,
    settingRoleList,
    settingRoleInactive,
    settingRoleActive,
    settingRoleDetail
} from "../../../../../services/adapters/base"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", settingRoleList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDetail = async (query) => {
        return await apiRequest("get", settingRoleDetail, {
            params: query
        }, true)
    }

    fetchInactive = async (query) => {
        return await apiRequest("post", settingRoleInactive, {
            body: query
        }, true)
    }
    fetchActive = async (query) => {
        return await apiRequest("post", settingRoleActive, {
            body: query
        }, true)
    }
}

export default DataTableRepository