import {
    apiRequest,
    tunkinRapelDetailBefore,
    tunkinRapelDetailAfter,
    tunkinRapelDetailRapel,
    tunkinRapelDetailRefund,
    tunkinRapelSsp
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetailBefore = async (query) => {
        return await apiRequest("get", tunkinRapelDetailBefore, {
            params: query
        }, true)
    }

    fetchDownloadDetailAfter = async (query) => {
        return await apiRequest("get", tunkinRapelDetailAfter, {
            params: query
        }, true)
    }

    fetchDownloadDetailRapel = async (query) => {
        return await apiRequest("get", tunkinRapelDetailRapel, {
            params: query
        }, true)
    }

    fetchDownloadDetailRefund = async (query) => {
        return await apiRequest("get", tunkinRapelDetailRefund, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tunkinRapelSsp, {
            params: query
        }, true)
    }
}

export default DownloadRepository