import {
    apiRequest,
    activityList,
} from "../../../../../services/adapters/base"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", activityList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository