import { accessRole } from "../../../../helpers/access_role"
import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import ErrorComingSoon from '../../../../components/Error/Content/ComengSoon'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import apiResponse from '../../../../services/apiResponse'
import IndexBloc from './Blocs/IndexBloc'
import Shimmer from "react-shimmer-effect"
import moment from 'moment'
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import Slider from "react-slick";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import MinioClient from "../../../../services/minioClient"

import {
    Container,
    Row, Col,
    CardHeader, CardBody, Card, CardTitle, CardSubtitle,
    Button,
    CardFooter,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Input, Collapse
} from "reactstrap"

function formatDate(dateString) {
    const months = [
        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

class IndexView extends Component {
    minioClient = new MinioClient()
    indexBloc = new IndexBloc();

    constructor(props) {
        super(props)
        this.state = {
            messageError: "",
            modalShow: false,
            dashboardReportLoading: true,
            dashboardReport: [],
            isOpen: false,
            showAmount: false,
            activeService: null,
            loadingCurrent: false,
            loadingSubCurrent: false,
            loadingInformasi: false,
            loadingNotifikasi: false,
            layanan: [],
            subLayanan: [],
            informasi: [],
            layananNotif: null,
            layananBulan: null, 
            layananTahun: null,
            layananLink: null,
            countBulan: null,
            countGpp: null,
            countHonorarium: null,
            countTunkin: null,
            countTunkinLn: null,
            countTunsus: null,
            countUangMakan: null,
            countUangLembur: null,
            countTotal: null,
            countTahun: null
        }
    }

    componentDidMount() {

        this.indexBloc.dashboardReportChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        dashboardReportLoading: false,
                        dashboardReport: result.data.response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        dashboardReportLoading: false
                    });
                    break
                default:
                    break
            }
        })

        this.indexBloc.getLayananChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({ loadingCurrent: true });
                    break;
                case apiResponse.COMPLETED:
                    const response = result.data.response;
                    const layananOptions = response.map(item => ({
                        id: item.dashboard_pegawai_id,
                        label: item.dashboard_pegawai_name
                    }));
                    this.setState({ loadingCurrent: false, layanan: layananOptions });
                    break;
                case apiResponse.ERROR:
                    this.setState({ loadingCurrent: false });
                    break;
                default:
                    break;
            }
        });

        this.indexBloc.getSubLayananChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({ loadingSubCurrent: true });
                    break;
                case apiResponse.COMPLETED:
                    const response = result.data.response;
                    const subLayananOptions = response.map(subitem => ({
                        id: subitem.dashboard_pegawai_parent_id,
                        label: subitem.dashboard_pegawai_subname,
                        description: subitem.dashboard_pegawai_description,
                        link: subitem.dashboard_pegawai_link

                    }));
                    this.setState({ loadingSubCurrent: false, subLayanan: subLayananOptions });
                    break;
                case apiResponse.ERROR:
                    this.setState({ loadingSubCurrent: false });
                    break;
                default:
                    break;
            }
        });

        this.indexBloc.getInformasiChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({ loadingInformasi: true });
                    break;
                case apiResponse.COMPLETED:
                    const response = result.data.response;
                    const informasiOptions = response.map(subitem => ({
                        id: subitem.dashboard_pegawai_id,
                        label: subitem.dashboard_pegawai_subname,
                        description: subitem.dashboard_pegawai_description,
                        date: formatDate(subitem.dashboard_pegawai_create_date),

                    }));
                    this.setState({ loadingInformasi: false, informasi: informasiOptions });
                    break;
                case apiResponse.ERROR:
                    this.setState({ loadingInformasi: false });
                    break;
                default:
                    break;
            }
        });

        this.indexBloc.getNotifPersetujuanChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({ loadingNotifikasi: true });
                    break;
                case apiResponse.COMPLETED:
                    const response = result.data.response;
                    let layanan = '';
                    if(response.paycheck_type == 1){
                        layanan = 'SIGAP';
                    } else if(response.paycheck_type == 2){
                        layanan = 'SLIP GAJI';
                    }

                    const monthNames = [
                        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
                        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
                    ];
                    const layananBulan = monthNames[response.paycheck_month - 1]; // Subtract 1 since array is zero-based
        
                    this.setState({
                        loadingNotifikasi: false,
                        layananNotif: layanan,
                        layananBulan: layananBulan,
                        layananTahun: response.paycheck_year,
                        layananLink: response.paycheck_pdf_file_path
                    });
        
                    break;
                case apiResponse.ERROR:
                    this.setState({ loadingNotifikasi: false });
                    break;
                default:
                    break;
            }
        });

        this.indexBloc.getPerhitunganChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({ loadingNotifikasi: true });
                    break;
                case apiResponse.COMPLETED:
                    const response = result.data.response;

                    console.log('month', response.month);
                    console.log('gpp', response.gpp);
                    console.log('honorarium', response.honorarium);
                    console.log('tunkin', response.tunkin);
                    console.log('tunkin_ln', response.tunkin_ln);
                    console.log('tunsus', response.tunsus);
                    console.log('u_makan', response.uang_makan);
                    console.log('u_lembur', response.uang_lembur);
                    console.log('s_total', response.sum_total);

                    const monthNames = [
                        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
                        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
                    ];
                    const countBulan = monthNames[response.month - 1];
                    const countTahun = response.year;
                    const countGpp = response.gpp;
                    const countHonorarium = response.honorarium;
                    const countTunkin = response.tunkin;
                    const countTunkinLn = response.tunkin_ln;
                    const countTunsus = response.tunsus;
                    const countUangMakan = response.uang_makan;
                    const countUangLembur = response.uang_lembur;
                    const countTotal = response.sum_total;

                    this.setState({
                        loadingNotifikasi: false,
                        countBulan: countBulan,
                        countTahun: countTahun,
                        countGpp: countGpp,
                        countHonorarium: countHonorarium,
                        countTunkin: countTunkin,
                        countTunkinLn: countTunkinLn,
                        countTunsus: countTunsus,
                        countUangMakan: countUangMakan,
                        countUangLembur: countUangLembur,
                        countTotal: countTotal
                    });
        
                    break;
                case apiResponse.ERROR:
                    this.setState({ loadingNotifikasi: false });
                    break;
                default:
                    break;
            }
        });
        
        this.indexBloc.fetchGetLayanan();
        this.indexBloc.fetchSubGetLayanan();
        this.indexBloc.fetchGetInformasi();
        this.indexBloc.fetchGetNotifPersetujuan();
        this.indexBloc.fetchGetPerhitungan();

        this.loadData()
    }

    componentWillUnmount() {
        this.indexBloc.dashboardReportChannel?.unsubscribe()
        this.indexBloc.getLayananChannel?.unsubscribe()
        this.indexBloc.getSubLayananChannel?.unsubscribe()
        this.indexBloc.getInformasiChannel?.unsubscribe()
        this.indexBloc.getNotifPersetujuanChannel?.unsubscribe()
        this.indexBloc.getPerhitunganChannel?.unsubscribe()
    }

    generateFile = async (path) => {
        //alert(path)
        this.setState({
            loadingGenerate: true,
        })
        this.minioClient.get(path).then((url) => {
            this.setState({
                loadingGenerate: false,
                successGenerate: true,
            })
            setTimeout(() => {
                window.open(url, "_blank")
                this.setState({
                    successGenerate: false,
                })
            }, 1000)
        })
    }

    loadData = () => {
        this.indexBloc.fetchDashboardReport()
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        let formPencarian = {
            ...values
        }
        this.setState({ dashboardPengajuanLoading: true })
        this.indexBloc.fetchDashboarPengajuan(formPencarian)
    }

    toggleDetail = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    };

    toggleAmountVisibility = () => {
        this.setState(prevState => ({
            showAmount: !prevState.showAmount
        }));
    }

    formatNumber(number) {
        return new Intl.NumberFormat('id-ID', { minimumFractionDigits: 0 }).format(number);
    }

    SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", left: '10px', zIndex: 2 }} // tambahkan z-index
            onClick={onClick}
          >
            <i className="uil uil-angle-left"></i>
          </div>
        );
    }
    
    SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", right: '10px', zIndex: 2 }} // tambahkan z-index
            onClick={onClick}
          >
            <i className="uil uil-angle-right"></i>
          </div>
        );
    }

    setActiveService = (service) => {
        this.setState({ activeService: service });
    }
    
    render() {
        const settings = {
            dots: false,
            infinite: false,
            width: '200px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const settingsb = {
            dots: false,
            infinite: true,
            width: '250px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const settingsc = {
            dots: false,
            infinite: true,
            width: '250px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const settingsd = {
            dots: false,
            infinite: true,
            width: '280px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const containerStyle = {
            display: 'flex',
            justifyContent: 'center', // opsional, untuk memposisikan card di tengah kontainer
        };

        const fixedCardStyle = {
            width: '240px',
            height: '230px', // contoh, sesuaikan dengan kebutuhan Anda
            overflow: 'auto', // agar konten yang melebihi tinggi card bisa discroll
            marginLeft: '40px',
            borderRadius: '15px'
        };

        const fixedCardStyleInfo = {
            width: '310px',
            height: '250px', // contoh, sesuaikan dengan kebutuhan Anda
            overflow: 'auto' // agar konten yang melebihi tinggi card bisa discroll
        };

        const { layanan, subLayanan, activeService, informasi } = this.state;
        const filteredSubLayanan = subLayanan.filter(subItem => subItem.id === activeService);
        const { layananNotif, layananBulan, layananTahun, layananLink } = this.state;
        const { countBulan, countGpp, countHonorarium, countTunkin, countTunkinLn, countTunsus, countUangMakan, countUangLembur, countTotal, countTahun } = this.state;


        return (
            <Fragment>
                <Container fluid>
                    <div className="clearfix mb-2">
                        <div>
                            {/* Menggantikan notifikasi dengan Card */}
                            {this.state.layananNotif && (
                                <Card className="p-3" style={{ width: '100%' }}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            Permintaan { layananNotif } Bulan { layananBulan } Tahun { layananTahun } telah disetujui oleh Bagian Keuangan.
                                        </div>
                                        <Link
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.generateFile(layananLink);
                                            }}
                                            style={{ borderRadius: '40px', width: '130px' }}
                                            to="#"
                                        >
                                            <i className="uil-download me-2"></i>Unduh
                                        </Link>
                                    </div>
                                </Card>
                            )}

                            <Card className="p-3" style={{ width: '100%' }}>
                                <div className="d-flex justify-content-between" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        <div>
                                            <div><strong>PENGHASILAN</strong></div>
                                            <div>Per {countBulan} {countTahun}</div>
                                        </div>
                                        <div className="mt-auto">
                                            <strong style={{ marginRight: '5px' }}>
                                                Rp {this.state.showAmount ? this.formatNumber(countTotal) : '********'}
                                            </strong>
                                            {
                                                this.state.showAmount ? 
                                                <i className="uil uil-eye-slash" style={{ fontSize: '20px', color: '#5271ff' }} onClick={this.toggleAmountVisibility}></i> :
                                                <i className="uil uil-eye" style={{ fontSize: '20px', color: '#5271ff' }} onClick={this.toggleAmountVisibility}></i>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '10px', flexShrink: 0 }}>
                                        <div 
                                            onClick={this.toggleDetail} 
                                            style={{ 
                                                cursor: 'pointer', 
                                                textAlign: 'center', 
                                                padding: '10px', 
                                                backgroundColor: '#5271ff', 
                                                color: 'white',
                                                whiteSpace: 'nowrap', 
                                                minWidth: '500px',
                                                marginTop: '15px',
                                                borderRadius: '36px'
                                            }}
                                        >
                                            Detail {this.state.isOpen ? <i className="uil uil-angle-up"></i> : <i className="uil uil-angle-down"></i>}
                                            <Collapse isOpen={this.state.isOpen}>
                                                <Card body style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', marginTop: '10px', color: 'white' }}>
                                                    <Table>
                                                        <Tbody>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>Gaji Induk</td>
                                                                <td style={{ textAlign: 'right' }}>Rp</td>
                                                                <td style={{ textAlign: 'right' }}>{this.state.showAmount ? this.formatNumber(countGpp) : '********'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>Tunjangan Kinerja</td>
                                                                <td style={{ textAlign: 'right' }}>Rp</td>
                                                                <td style={{ textAlign: 'right' }}>{this.state.showAmount ? this.formatNumber(countTunkin) : '********'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>Tunjangan Penghidupan Luar Negeri</td>
                                                                <td style={{ textAlign: 'right' }}>Rp</td>
                                                                <td style={{ textAlign: 'right' }}>{this.state.showAmount ? this.formatNumber(countTunkinLn) : '********'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>Tunjangan Khusus</td>
                                                                <td style={{ textAlign: 'right' }}>Rp</td>
                                                                <td style={{ textAlign: 'right' }}>{this.state.showAmount ? this.formatNumber(countTunsus) : '********'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>Uang Makan</td>
                                                                <td style={{ textAlign: 'right' }}>Rp</td>
                                                                <td style={{ textAlign: 'right' }}>{this.state.showAmount ? this.formatNumber(countUangMakan) : '********'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>Uang Lembur</td>
                                                                <td style={{ textAlign: 'right' }}>Rp</td>
                                                                <td style={{ textAlign: 'right' }}>{this.state.showAmount ? this.formatNumber(countUangLembur) : '********'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>Honorarium Tetap</td>
                                                                <td style={{ textAlign: 'right' }}>Rp</td>
                                                                <td style={{ textAlign: 'right' }}>{this.state.showAmount ? this.formatNumber(countHonorarium) : '********'}</td>
                                                            </tr>
                                                        </Tbody>
                                                    </Table>
                                                </Card>
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>
                            </Card>


                            <Card className="p-3" style={{ width: '100%' }}>
                                <div className="mt-4 text-center"> 
                                    <h2>LAYANAN</h2>
                                    <p>Daftar Layanan Bagian Keuangan</p>
                                    <div className="d-flex mb-5 justify-content-center">
                                        {layanan.length > 0 ? (
                                            layanan.map((layananItem, index) => (
                                                <button
                                                    key={index}
                                                    className="btn btn-primary me-3"
                                                    style={{ borderRadius: '40px' }}
                                                    onClick={() => this.setActiveService(layananItem.id)}
                                                >
                                                    {layananItem.label}
                                                </button>
                                            ))
                                        ) : (
                                            <p>Sedang memuat layanan...</p>
                                        )}
                                    </div>
                                    {filteredSubLayanan.length > 0 && (
                                        <div>
                                            <Slider {...settings}>
                                                {filteredSubLayanan.map((subItem, index) => (
                                                    <div className="p-3" style={containerStyle} key={index}>
                                                        <Link to={subItem.link}>
                                                            <Card style={fixedCardStyle}>
                                                                <CardHeader>
                                                                    <h5 style={{ textAlign: 'center' }}>{subItem.label}</h5>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <p style={{ color: '#495057' }}>{subItem.description}</p>
                                                                </CardBody>
                                                            </Card>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    )}
        
                                </div>
                            </Card>

                            <Card className="p-3" style={{ width: '100%' }}>
                            <div className="mt-4 text-center"> 
                                <h2>INFORMASI</h2>
                                <p style={{ marginBottom: '50px'}}>Pengumuman dan Peraturan Terbaru Terkait Keuangan</p>
                                {informasi.length > 0 ? (
                                    <div>
                                        <Slider {...settings}>
                                            {informasi.map((informasiItem, index) => (
                                                <div className="d-flex justify-content-center" style={{ marginTop:'10px' }} key={index}>
                                                    <Link to={informasiItem.link}>
                                                        <Card style={fixedCardStyleInfo}>
                                                            <CardHeader>
                                                                <p style={{ textAlign: 'left', fontSize: '10px' }}>{informasiItem.date}</p>
                                                                <h5 style={{ textAlign: 'left' }}>{informasiItem.label}</h5> {/* Judul di tengah */}
                                                            </CardHeader>
                                                            <CardBody>
                                                                <p style={{ textAlign: 'left' }}>{informasiItem.description}</p> {/* Konten teks */}
                                                            </CardBody>
                                                        </Card>
                                                    </Link>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                ) : (
                                    <p>Sedang memuat informasi...</p>
                                )}
       
                            </div>
                            </Card>

                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView