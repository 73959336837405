import {
    apiRequest,
    tunkinRapelApproveSubmit,
    tunkinRapelRejectSubmit,
    tunkinRapelResultEmployee,
    tunkinRapelCheckStatus,
    tunkinRapelResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", tunkinRapelApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", tunkinRapelRejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", tunkinRapelResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tunkinRapelResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tunkinRapelCheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository