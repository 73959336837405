import {
    apiRequest,
    ptspRegulerDetail,
    ptspRegulerDetailAll,
    ptspRegulerSspAttach,
    ptspRegulerSsp,
} from "../../../../../../services/adapters/output"

class DownloadRepository {
    fetchDownloadDetail = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadDetailAll = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerDetailAll,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadSspAttach = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerSspAttach,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerSsp,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
