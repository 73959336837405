import {
    apiRequest,
    uangMakanRapelYears,
    uangMakanRapelMonths,
    uangMakanRapelHistory,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", uangMakanRapelYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", uangMakanRapelMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", uangMakanRapelHistory, {
            params: query
        }, true)
    }
}

export default IndexViewRepository