import React, { useState, Component, Fragment } from "react"
import SimpleBar from "simplebar-react"
import Shimmer from "react-shimmer-effect"
import moment from 'moment'

import {
    Row, Col,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../services/apiResponse'

class DetailComponent extends Component {

    datatableBloc = new DataTableBloc();

    constructor(props) {
        super(props)
        this.state = {
            loadingData: true,
            menuData: []
        }
    }

    componentDidMount() {
        this.datatableBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingData: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        menuData: response,
                        loadingData: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.datatableBloc.fetchDetail({id: this.props.id})
    }

    componentWillUnmount() {
        this.datatableBloc.detailChannel.unsubscribe()
    }

    render () {
        return (
            <>
                { this.state.loadingData ?
                    <Row>
                        <Col sm={12}>
                            <Shimmer><div className="shimmer" style={{width: '40%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>

                            <Shimmer><div className="shimmer" style={{width: '40%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                        </Col>
                    </Row>
                :
                    <>
                    { this.state.menuData.length > 0 ?
                        <Row>
                            <Col sm={12}>
                                <Table
                                    className="table table-striped table-bordered table-hover"
                                >
                                    <Thead>
                                        <Tr>
                                            <Th width="*" data-priority="1">Menu</Th>
                                            <Th class="text-center" width="80px" data-priority="1">Create</Th>
                                            <Th class="text-center" width="80px" data-priority="1">Read</Th>
                                            <Th class="text-center" width="80px" data-priority="1">Update</Th>
                                            <Th class="text-center" width="80px" data-priority="1">Delete</Th>
                                            <Th class="text-center" width="80px" data-priority="1">Approve</Th>
                                            <Th class="text-center" width="80px" data-priority="1">Print</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            Object.entries(this.state.menuData).map((item, key) => {
                                                return (
                                                    <tr key={key} style={{fontWeight: item[1].level === 0 ? 'bold' : 'normal'}}>
                                                        <td className="text-left" style={{paddingLeft: (item[1].level + 1) * 18}}>{item[1].title}</td>
                                                        <td className="text-center">
                                                            <>
                                                            {
                                                                item[1].create === 1 ?
                                                                    <center>
                                                                        <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>
                                                                    </center>
                                                                : <></>
                                                            }
                                                            </>
                                                        </td>
                                                        <td className="text-center">
                                                            <>
                                                                {
                                                                    item[1].read === 1 ?
                                                                        <center>
                                                                            <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>
                                                                        </center>
                                                                    : <></>
                                                                }
                                                            </>
                                                        </td>
                                                        <td className="text-center">
                                                            <>
                                                                {
                                                                    item[1].update === 1 ?
                                                                        <center>
                                                                            <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>
                                                                        </center>
                                                                    : <></>
                                                                }
                                                            </>    
                                                        </td>
                                                        <td className="text-center">
                                                            <>
                                                                {
                                                                    item[1].delete === 1 ?
                                                                        <center>
                                                                            <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>
                                                                        </center>
                                                                    : <></>
                                                                }
                                                            </>
                                                        </td>
                                                        <td className="text-center">
                                                            <>
                                                                {
                                                                    item[1].approve === 1 ?
                                                                        <center>
                                                                            <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>
                                                                        </center>
                                                                    : <></>
                                                                }
                                                            </>
                                                        </td>
                                                        <td className="text-center">
                                                            <>
                                                                {
                                                                    item[1].print === 1 ?
                                                                        <center>
                                                                            <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>
                                                                        </center>
                                                                    : <></>
                                                                }
                                                            </>    
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </Col>
                        </Row>
                        :
                        <p className="text-muted mb-1 font-size-13">Tidak ada data</p>
                    }
                    </>
                }
            </>
        )
    }
}

export default DetailComponent