import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Modal
} from "reactstrap"
import Shimmer from "react-shimmer-effect";
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import { generateFormData } from '../../../../../helpers/ui/FileInput'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect } from "react-router-dom"
import apiResponse from '../../../../../services/apiResponse'
import CreateBloc from './Blocs/CreateBloc'


class CreateView extends Component {

    createBloc = new CreateBloc()
    source
    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            loadingCurrent: true
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm)
        await this.createBloc.fetchCreate(formData)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
              ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.employeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingCurrent: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingCurrent: false,
                        employees: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        
                    });
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchEmployeeList()
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
        this.createBloc.employeeChannel?.unsubscribe()
    }

    handleKeyPress = (event) => {
        var last = event.target.value
        if(last.length >= 32) { event.preventDefault(); }
        if(event.which !== 44) {
            if(event.which !== 8 && isNaN(String.fromCharCode(event.which))){
                event.preventDefault();
            }
        }
    }
    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Tambah Mapping NIP</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr/>
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama Pegawai<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="6">
                                                {
                                                    this.state.loadingCurrent?
                                                        <><Shimmer><div className="shimmer" style={{width: '100%', height: 36}}></div></Shimmer>{" "}</>
                                                    :
                                                        <>
                                                            <CustomSelect 
                                                                name="employee_uuid"
                                                                errorMessage="Pilih Nama Pegawai"
                                                                options={this.state.employees} 
                                                                placeholder="Pilih Nama Pegawai"
                                                                optionLabel="employee_name"
                                                                optionValue="employee_uuid"
                                                                validate={{ required: { value: true } }}
                                                            />
                                                        </>
                                                    }
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                NIP GPP<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nip_gpp"
                                                    placeholder="Masukkan NIP GPP"
                                                    align="left"
                                                    type="text"
                                                    errorMessage="Masukkan NIP GPP"
                                                    className="form-control"
                                                    id="fieldValue"
                                                    validate={{ required: { value: true } }}
                                                    onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue") }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/master/mapping-nip.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/master/mapping-nip.html'/>
                : null }
            </Fragment>
        )
    }

}

export default CreateView