import {
    apiRequest,
    officialTravelSubmissionCreateInitial,
    officialTravelSubmissionUnprocessList,
    officialTravelSubmissionUnprocessParticipantList,
    officialTravelSubmissionConflictDetail,
    officialTravelSubmissionConflictSolver_0,
    officialTravelSubmissionConflictSolver_1,
    officialTravelSubmissionConflictSolver_2,
    officialTravelSubmissionLevelList,
    officialTravelSubmissionLevelUpdate
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchLevelList = async (query) => {
        return await apiRequest("get", officialTravelSubmissionLevelList, {
            params: query
        }, true)
    }

    fetchLevelUpdate = async (query) => {
        return await apiRequest("post", officialTravelSubmissionLevelUpdate, {
            body: query
        }, true)
    }

    fetchCreateInitial = async (query) => {
        return await apiRequest("post", officialTravelSubmissionCreateInitial, {
            body: query
        }, true)
    }

    fetchUnprocessList = async (query) => {
        return await apiRequest("get", officialTravelSubmissionUnprocessList, {
            params: query
        }, true)
    }

    fetchUnprocessParticipantList = async (query) => {
        return await apiRequest("get", officialTravelSubmissionUnprocessParticipantList, {
            params: query
        }, true)
    }
    fetchConflictDetail = async (query) => {
        return await apiRequest("get", officialTravelSubmissionConflictDetail, {
            params: query
        }, true)
    }

    fetchConflictSolver_0 = async (query) => {
        return await apiRequest("post", officialTravelSubmissionConflictSolver_0, {
            body: query
        }, true)
    }
    fetchConflictSolver_1 = async (query) => {
        return await apiRequest("post", officialTravelSubmissionConflictSolver_1, {
            body: query
        }, true)
    }
    fetchConflictSolver_2 = async (query) => {
        return await apiRequest("post", officialTravelSubmissionConflictSolver_2, {
            body: query
        }, true)
    }
}

export default CreateRepository