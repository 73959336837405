import React, { useState, Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect"
import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button } from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom"
import { withRouter } from "react-router"
import { Preview } from "../../../../../helpers/ui/FileInput"

import apiResponse from "../../../../../services/apiResponse"

import DetailBloc from "./Blocs/DetailBloc"
import TableListComponent from "./Components/TableListComponent"
import moment from "moment"

class IndexView extends Component {
    detailBloc = new DetailBloc()

    constructor(props) {
        super(props)
        this.state = {
            uuid: null,
        }
    }

    componentDidMount() {
        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.detail,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.detailBloc.fetchDetail({ uuid: this.props.uuid })
    }

    componentWillUnmount() {
        this.detailBloc.detailChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Detail Perjalanan Dinas</CardTitle>
                                    <CardSubtitle className="mb-3">Informasi data Perjalanan dinas yang bersumber dari persuaratan dan deverifikasi oleh kepegawaian.</CardSubtitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <label className="col-md-3">Nomor</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_number !== "" ? this.state.detail.official_travel_number : "-"}</>
                                            ) : (
                                                <Shimmer className="shimmer">
                                                    <div style={{ width: 100, height: 15 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">Tanggal Mulai</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_start_date !== "" ? moment(this.state.detail.official_travel_start_date).format("LL") : "-"}</>
                                            ) : (
                                                <Shimmer className="shimmer">
                                                    <div style={{ width: 140, height: 15 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">Tanggal Selesai</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_end_date !== "" ? moment(this.state.detail.official_travel_end_date).format("LL") : "-"}</>
                                            ) : (
                                                <Shimmer className="shimmer">
                                                    <div style={{ width: 140, height: 15 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">Tempat Dinas</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_oversea === 1 ? "Dalam Negeri" + (this.state.detail.official_travel_domicile === 1 ? " (Dalam Kota)" : " (Luar Kota)") : "Luar Negeri"}</>
                                            ) : (
                                                <Shimmer className="shimmer">
                                                    <div style={{ width: 120, height: 15 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    {this.state.detail ? (
                                        <>
                                            {this.state.detail.official_travel_oversea === 1 ? (
                                                <>
                                                    <Row className="mb-3">
                                                        <label className="col-md-3">Provinsi</label>
                                                        <Col md="9">
                                                            {this.state.detail ? (
                                                                <>{this.state.detail.official_travel_province !== "" ? this.state.detail.official_travel_province : "-"}</>
                                                            ) : (
                                                                <Shimmer className="shimmer">
                                                                    <div style={{ width: 100, height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <label className="col-md-3">Kota</label>
                                                        <Col md="9">
                                                            {this.state.detail ? (
                                                                <>{this.state.detail.official_travel_city !== "" ? this.state.detail.official_travel_city : "-"}</>
                                                            ) : (
                                                                <Shimmer className="shimmer">
                                                                    <div style={{ width: 140, height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : (
                                                <>
                                                    <Row className="mb-3">
                                                        <label className="col-md-3">Negara</label>
                                                        <Col md="9">
                                                            {this.state.detail ? (
                                                                <>{this.state.detail.official_travel_country !== "" ? this.state.detail.official_travel_country : "-"}</>
                                                            ) : (
                                                                <Shimmer className="shimmer">
                                                                    <div style={{ width: 100, height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <Row className="mb-3">
                                        <label className="col-md-3">Tujuan</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_destination !== "" ? this.state.detail.official_travel_destination : "-"}</>
                                            ) : (
                                                <Shimmer className="shimmer">
                                                    <div style={{ width: "80%", height: 15 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">Keterangan</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <>{this.state.detail.official_travel_description !== "" ? this.state.detail.official_travel_description : "-"}</>
                                            ) : (
                                                <>
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: "100%", height: 15 }}></div>
                                                    </Shimmer>
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: "20%", height: 15 }}></div>
                                                    </Shimmer>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">File Surat Keputusan</label>
                                        <Col md="9">
                                            {this.state.detail ? (
                                                <Preview
                                                    col={3}
                                                    value={[
                                                        {
                                                            documents_name: this.state.detail.official_travel_letter_name,
                                                            documents_path: this.state.detail.official_travel_letter_path,
                                                            documents_mime_type: this.state.detail.official_travel_letter_mime,
                                                            documents_file_size: this.state.detail.official_travel_letter_size,
                                                            documents_source: "minio",
                                                        },
                                                    ]}
                                                />
                                            ) : (
                                                <Shimmer>
                                                    <div className="shimmer" style={{ width: 80, height: 100 }}></div>
                                                </Shimmer>
                                            )}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-3">
                                        <label className="col-md-12">
                                            <div className="p-2 bd-highlight float-start">
                                                <div style={{ paddingTop: 8 }}>Partisipan</div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{ paddingTop: 8 }}>
                                                    <div className="bg-success rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Sudah Proses
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{ paddingTop: 8 }}>
                                                    <div className="bg-secondary rounded-circle d-inline-block" style={{ height: 10, width: 10 }}></div> Belum Proses
                                                </div>
                                            </div>
                                        </label>
                                    </Row>
                                    <hr />
                                    <Row className="mb-3">
                                        <Col md="12">
                                            {this.state.detail ? (
                                                <>{this.state.detail.total_participant !== "" ? <>{this.state.detail.total_participant > 0 ? <TableListComponent uuid={this.props.uuid} /> : "Tidak ada"}</> : "Tidak ada"}</>
                                            ) : (
                                                <>
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: "100%", height: 15 }}></div>
                                                    </Shimmer>
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: "20%", height: 15 }}></div>
                                                    </Shimmer>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <Link to="/official-travel/finalization.html" className="waves-effect">
                                                <Button color="danger">
                                                    <i className="uil-arrow-left"></i> Kembali
                                                </Button>
                                            </Link>{" "}
                                            <Link to={"/official-travel/finalization.html?create&uuid=" + this.props.uuid} className="waves-effect">
                                                <Button color="primary">
                                                    <i className="uil-cog"></i> Proses Perjalanan Dinas
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(IndexView)
