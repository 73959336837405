import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import UpdateRepository from '../Repositories/UpdateRepository'


class UpdateBloc {

    employeeChannel = new Subject()
    updateChannel = new Subject()

    repository = new UpdateRepository()

    constructor() {
        this.updateChannel.next({ status: apiResponse.INITIAL })
        this.employeeChannel.next({ status: apiResponse.INITIAL })
    }

    fetchEmployeeList = async () => {
        this.employeeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployeeList().then((result) => {
                this.employeeChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchUpdate = async (query) => {
        this.updateChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchUpdate(query).then((result) => {
                this.updateChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.updateChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }
}

export default UpdateBloc