import {
    apiRequest,
    masterTunkinTplnCountries,
    masterTunkinTplnCurrent
} from "../../../../../../services/adapters/master"

class IndexViewRepository {

    fetchRegisteredCountries = async () => {
        return await apiRequest("get", masterTunkinTplnCountries, {}, true)
    }

    fetchCurrent = async (query, cancelToken) => {
        return await apiRequest("get", masterTunkinTplnCurrent, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default IndexViewRepository