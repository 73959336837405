import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"

import {
    Tooltip,
    Modal
} from "reactstrap"
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import MKIDatatable from '../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../services/adapters/base"

import IndexViewBloc from '../Blocs/IndexViewBloc'
import apiResponse from '../../../../../services/apiResponse'

import MinioClient from '../../../../../services/minioClient'

class SPTFinalListComponent extends Component {
    minioClient = new MinioClient()

    indexViewBloc = new IndexViewBloc();

    source
    historyTable = 'historySPTFinalEmployeeComponent'
    defaultOrder = 'employee_name'
    defaultSize = 20
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            showSKPHistory: false,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            year: this.props.year
        }
        await this.indexViewBloc.fetchRowTableFinal(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => { }

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.rowDataFinalChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.rowDataFinalChannel.unsubscribe()
    }

    listPengajuan = (year, month, jumlah) => {
        let list = []
        for (let i = 0; i < jumlah; i++) {
            if ((month - i) <= 0) {
                list.push({ year: year - 1, month: 12 + (month - i) })
            } else {
                list.push({ year: year, month: month - i })
            }
        }
        return list
    }

    columns = [
        {
            key: "spt_year",
            text: "Tahun",
            width: '90',
            align: 'center',
            sortable: true
        },
        {
            key: "employee_nip",
            text: "NIP",
            sortable: true,
            width: '140',
            align: 'center'
        },
        {
            key: "employee_name",
            text: "Nama Pegawai",
            width: '220',
            sortable: true
        },
        {
            key: "employee_work_unit_name",
            text: "Unit Kerja",
            sortable: true,
        },
        {
            key: "spt_detail_status",
            text: "Status",
            width: '100',
            sortable: true,
            align: 'center',
            cell: record => {
                return (
                    <div className={(record.spt_detail_status === 3 ? "bg-success" : "bg-warning") + " rounded-circle d-inline-block"} style={{ height: 10, width: 10 }}></div>
                )
            }
        },
        {
            key: "action",
            text: "Aksi",
            width: '60',
            align: 'center',
            cell: record => {
                const thisId = record.spt_id
                var dataDone = record.spt_detail_status === 3 ? true : false
                return (
                    <>
                        {dataDone ?
                            <>
                                <button
                                    className="btn btn-danger btn-sm"
                                    id={"print-" + thisId}
                                    onClick={() => {
                                        this.downloadSigned(record.spt_detail_file_path)
                                    }}
                                    style={{
                                        padding: '2px 5px'
                                    }}
                                >
                                    <i className="uil-arrow-to-bottom"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["print" + thisId]} target={"print-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["print" + thisId]: !this.state["print" + thisId]
                                        })
                                    }}
                                >Download</Tooltip>
                            </>
                            :
                            <button
                                className="btn btn-soft-secondary btn-sm" disabled={true}
                                style={{
                                    padding: '2px 5px'
                                }}>
                                <i className="uil-arrow-to-bottom"></i>
                            </button>
                        }
                    </>
                )
            }
        }
    ]

    downloadSigned = (path) => {
        this.setState({
            minioLoading: true
        })
        this.minioClient.get(path).then((url) => {
            this.setState({
                minioLoading: false,
                minioSuccess: true
            })
            setTimeout(() => {
                this.setState({
                    minioSuccess: false,
                })
            }, 1000);
            window.open(url, '_blank');
        })
    }

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1000}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <ConfirmAlert

                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"

                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"

                    showLoading={this.state.minioLoading}
                    showSuccess={this.state.minioSuccess}
                    showFailed={this.state.minioFailed}
                />
            </>
        )
    }
}

export default SPTFinalListComponent