import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip, Modal } from "reactstrap"

import MKIDatatable from "../../../../../../modules/mki-datatable/lib"

import { cancelToken } from "../../../../../../services/adapters/base"

import CreateBloc from "../Blocs/CreateBloc"
import apiResponse from "../../../../../../services/apiResponse"

import DetailEmployeeComponent from "./DetailEmployeeComponent"

import moment from "moment"

class EmployeeListComponent extends Component {
    createBloc = new CreateBloc()

    source
    historyTable = "historyPayrollTunsusRegularEmployeeList"
    defaultOrder = "tunsus_employee_name"
    defaultSize = 10
    defaultSort = "asc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.historyTable = this.historyTable + this.props.uuid
        this.state = {
            showDetail: false,
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    loadData = async (server) => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: 2000, //this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            uuid: this.props.uuid,
        }
        if (server) {
            await this.createBloc.fetchEmployeeList(query, this.source.token)
        }
    }

    setTokenAPI = (server = false) => {
        if (server) {
            if (typeof this.source != typeof undefined) {
                this.source.cancel()
            }
            this.source = cancelToken()
        }
        this.loadData(server)
    }

    componentDidMount() {
        this.setTokenAPI(true)

        this.createBloc.employeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.createBloc.employeeChannel.unsubscribe()
    }

    columns = [
        {
            key: "tunsus_employee_nip",
            text: "NIP",
            className: "",
            align: "center",
            sortable: true,
            width: "140",
        },
        {
            key: "tunsus_employee_name",
            text: "Nama Pegawai",
            className: "",
            sortable: true,
        },
        {
            key: "tunsus_employee_position_name",
            text: "Jabatan",
            className: "",
            sortable: true,
            width: "160",
        },
        {
            key: "tunsus_employee_rank_name",
            text: "Golongan",
            className: "",
            sortable: true,
            width: "160",
        },
        {
            key: "tunsus_employee_last",
            text: "Status",
            className: "",
            align: "center",
            sortable: true,
            cell: (record) => {
                var className =
                    (record.tunsus_employee_last === 1
                        ? "bg-soft-danger"
                        : record.tunsus_employee_last === 2
                        ? "bg-soft-warning"
                        : "bg-success") + " badge rounded-pill font-size-12"
                var labelName =
                    record.tunsus_employee_last === 1 ? "Baru" : record.tunsus_employee_last === 2 ? "Perubahan" : ""
                const thisId = record.tunsus_id
                return (
                    <>
                        {record.tunsus_employee_family_child !== 2 ? (
                            <div className={className} style={{ height: 10, width: 10 }}></div>
                        ) : null}
                        <label className={className}>{labelName}</label>{" "}
                    </>
                )
            },
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 70,
            sortable: false,
            align: "center",
            cell: (record) => {
                const thisId = record.tunsus_employee_uuid
                return (
                    <Fragment>
                        <span>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                id={"detail-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        showDetail: true,
                                        detailUuid: record.tunsus_employee_uuid,
                                    })
                                }}
                                style={{
                                    marginRight: "2px",
                                    padding: "2px 5px",
                                }}
                            >
                                <i className="uil-file-search-alt"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2,
                                }}
                                placement="top"
                                isOpen={this.state["detail" + thisId]}
                                target={"detail-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["detail" + thisId]: !this.state["detail" + thisId],
                                    })
                                }}
                            >
                                Lihat Detail
                            </Tooltip>
                        </span>
                    </Fragment>
                )
            },
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    withNumber={false}
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    minWidth={900}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        console.log(filter)
                        this.setState(
                            {
                                //loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                //this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Detail Pegawai
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <DetailEmployeeComponent uuid={this.state.detailUuid} />
                    </div>
                </Modal>
            </>
        )
    }
}

export default EmployeeListComponent
