import {
    apiRequest,
    simpegDocumentYears,
    simpegDocumentMonth,
    simpegDocumentMonthBerubah,
    simpegDocumentMonthKeluar,
    simpegDocumentDetail,
    simpegDocumentChange,
    simpegReadFlag,
    simpegDocumentProcess,
    simpegDocumentDeparture,
    simpegDocumentArrival,
    simpegDocumentReturn,
    simpegDocumentDecree,
    simpegDocumentDecreeAll,
    simpegDocument
    
} from "../../../../../../services/adapters/sync"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", simpegDocumentYears, {}, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", simpegDocumentMonth, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchMonthsBerubah = async (query, cancelToken) => {
        return await apiRequest("get", simpegDocumentMonthBerubah, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchMonthsKeluar = async (query, cancelToken) => {
        return await apiRequest("get", simpegDocumentMonthKeluar, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDetail = async (query) => {
        return await apiRequest("get", simpegDocumentDetail, {
            params: query
        }, true)
    }
    
    fetchChange = async (query) => {
        return await apiRequest("get", simpegDocumentChange, {
            params: query
        }, true)
    }

    fetchDeparture = async (query) => {
        return await apiRequest("get", simpegDocumentDeparture, {
            params: query
        }, true)
    }

    fetchArrival = async (query) => {
        return await apiRequest("get", simpegDocumentArrival, {
            params: query
        }, true)
    }

    fetchReturn = async (query) => {
        return await apiRequest("get", simpegDocumentReturn, {
            params: query
        }, true)
    }

    fetchDecree = async (query) => {
        return await apiRequest("get", simpegDocumentDecree, {
            params: query
        }, true)
    }

    fetchDecreeAll = async (query) => {
        return await apiRequest("get", simpegDocumentDecreeAll, {
            params: query
        }, true)
    }

    markAsRead = async (query) => {
        return await apiRequest("post", simpegReadFlag, {
            body: query 
        }, true)
    }

    processDokumen = async (query) => {
        return await apiRequest("post", simpegDocumentProcess, {
            body: query 
        }, true)
    }

    fetchDocument = async (query) => {
        return await apiRequest("get", simpegDocument, {
            params: query
        }, true)
    }
    

}

export default IndexViewRepository