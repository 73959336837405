import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect";
import Select from "react-select"
import moment from 'moment'

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Modal,
    Label
} from "reactstrap"
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import TunkinIndexListComponent from './Components/TunkinIndexListComponent'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import IndexViewBloc from './Blocs/IndexViewBloc'
import apiResponse from '../../../../../services/apiResponse'


class IndexView extends Component {

    indexViewBloc = new IndexViewBloc()
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear()-1 : this.thisDate.getFullYear()
    thisMonth = "00".substring(0, "00".length - (this.thisDate.getMonth()+1).toString().length) + (this.thisDate.getMonth()+1).toString()

    constructor(props) {
        super(props)
        this.state = {
            showTable: true,
            dataForm: {},
            showCreate: false,
            showTunkinHistory: false,
            loadingMonth: true,
            allowCreate: false,
            months: [],
            monthSelected: null
        }

    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
              ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false,
                    showCreate: false,
                    showTable: false
                }
                this.createData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true,
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    loadRegularMonts = async () => {
        await this.indexViewBloc.fetchRegulerMonts({'year': this.thisYear})
    }

    createData = async () => {
        await this.indexViewBloc.fetchCreate(this.state.dataForm)
    }

    closeModal = (reload = false) => {
        this.setState({
            showCreate: false
        })
        if(reload) {
            alert('reload');
        }
    }

    componentDidMount() {
        this.indexViewBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        allowCreate: false,
                        loading: false,
                        success: true,
                        allowCreate: false,
                        showTable: true
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.regulerMonthChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        allowCreate: response.status,
                        loadingMonth: false,
                        months: response.months
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.loadRegularMonts()
    }


    componentWillUnmount() {
        this.indexViewBloc.regulerMonthChannel.unsubscribe()
    }

    handleKeyPress = (event) => {
        var last = event.target.value
        if(last.length === 2 && parseInt(last.charAt(0)) > 1) { event.preventDefault(); }
        if(event.which !== 44) {
            if(event.which !== 8 && isNaN(String.fromCharCode(event.which))){
                event.preventDefault();
            }
        }
    }
    handleKeyUp = (event, id) => {
        const _this = document.querySelector('#' + id)
        var value = event.target.value
        if(value !== '') {
            value = parseInt(value, 10)
            value = (Number(value) > 100) ? 100 : value
        }
        _this.value = value
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="float-end">
                                        {
                                            (accessRole('create')) ?
                                                <>
                                                    { this.state.loadingMonth ?
                                                        <><Shimmer><div className="shimmer" style={{width: 160, height: 36}}></div></Shimmer></>
                                                    :
                                                        <>
                                                            <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        monthSelected: null,
                                                                        showCreate: true,
                                                                    });
                                                                }}
                                                            >
                                                                <i className="uil-folder-medical"></i> Tambah Data Baru
                                                            </Button>
                                                        </>
                                                    }
                                                </>
                                            : null
                                        }
                                    </div>
                                    <CardTitle>Tunjangan Kinerja Ke-14</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Proses Tunjangan Kinerja Ke-14 berdasarkan tahun dan bulan tertentu sesuai dengan data integrasi (Simpeg) yang sudah di verifikasi
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{paddingTop: 8}}>
                                                    <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div> Sudah Integrasi
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{paddingTop: 8}}>
                                                    <div className="bg-danger rounded-circle d-inline-block" style={{height: 10, width: 10}}></div> Belum Integrasi
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={12}>
                                            { this.state.showTable ? <TunkinIndexListComponent /> :
                                                <>
                                                    <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '35%', height: 36}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '35%', height: 36}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '20%', height: 36}}></div></Shimmer>

                                                    <Shimmer><div className="shimmer" style={{width: '10%', height: 22}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '35%', height: 22}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '35%', height: 22}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '20%', height: 22}}></div></Shimmer>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {
                    (accessRole('create')) ?
                        <>
                            <Modal
                                size="sm"
                                isOpen={this.state.showCreate}
                                toggle={() => {
                                    this.setState({
                                        showCreate: !this.state.showCreate,
                                    })
                                }}
                            >
                                <div className="modal-header">
                                    <h5
                                        className="modal-title mt-0"
                                        id="myLargeModalLabel"
                                    >
                                        Menambah Tunkin Ke-14
                                    </h5>
                                    <button
                                        onClick={() => {
                                            this.setState({
                                                monthSelected: null,
                                                showCreate: false,
                                            })
                                        }}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <AvForm
                                    className="needs-validation"
                                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                >
                                    <div className="modal-body">
                                        <Row className="mb-3">
                                            <b>Bulan Proses</b>
                                        </Row>
                                        <Row className="ms-1">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-4 col-form-label"
                                            >
                                                Tahun
                                            </Label>
                                            <Col md="8">
                                                <AvField
                                                    name="process"
                                                    type="hidden"
                                                    value={this.thisYear + "-" + this.thisMonth}
                                                />
                                                <div className="mt-2">
                                                    {this.thisYear}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="ms-1">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-4 col-form-label"
                                            >
                                                Bulan
                                            </Label>
                                            <Col md="8">
                                                <div className="mt-2">
                                                    {moment().format('MMMM')}
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row className="mb-3">
                                            <b>Bulan Target</b>
                                        </Row>
                                        { this.state.allowCreate ?
                                            <>
                                                { this.state.months.length > 0 ?
                                                    <>
                                                        <Row className="ms-1 mb-2">
                                                            <Label
                                                                htmlFor="fieldNama"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Bulan
                                                            </Label>
                                                            <Col md="8">
                                                                <CustomSelect
                                                                    name="source"
                                                                    errorMessage="Pilih Bulan"
                                                                    options={this.state.months} 
                                                                    placeholder="Pilih Bulan"
                                                                    optionLabel="label"
                                                                    optionValue="value"
                                                                    validate={{ required: { value: true } }}
                                                                    value={this.state.monthSelected}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="ms-1">
                                                            <Label
                                                                htmlFor="fieldNama"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Nilai
                                                            </Label>
                                                            <Col md="4">
                                                                <AvInput
                                                                    name="percentage"
                                                                    placeholder="0"
                                                                    align="right"
                                                                    type="text"
                                                                    className="form-control text-end"
                                                                    id="percentage"
                                                                    validate={{ required: { value: true } }}
                                                                    size="4"
                                                                    value="100"
                                                                    maxlength="3"
                                                                    onKeyPress={(e) => { this.handleKeyPress(e, "percentage") }}
                                                                    onKeyUp={(e) => { this.handleKeyUp(e, "percentage") }}
                                                                />
                                                            </Col>
                                                            <Label
                                                                htmlFor="fieldNama"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                %
                                                            </Label>
                                                        </Row>
                                                    </>
                                                :
                                                    <Row className="ms-1">
                                                        <div className="mt-2">
                                                            Tunkin Reguler belum tersedia
                                                        </div>
                                                    </Row>
                                                }
                                            </>
                                        :
                                            <Row className="ms-1">
                                                <div className="mt-2">
                                                    Tunkin Ke-14 hanya dapat ditambahkan sekali dalam setahun
                                                </div>
                                            </Row>
                                        }
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                this.setState({
                                                    showCreate: !this.state.showCreate,
                                                })
                                            }}
                                            className="btn btn-danger waves-effect"
                                            data-dismiss="modal"
                                        >
                                            Batal
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={ this.state.allowCreate ? false : true }
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            Tambahkan
                                        </button>
                                    </div>
                                </AvForm>
                            </Modal>
                            <ConfirmAlert
                                confirmTitle="Konfirmasi!"
                                confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                                loadingTitle="Mengirim data..."
                                loadingInfo="Tunggu beberapa saat"

                                successTitle="Berhasil!"
                                successInfo="Data berhasil disimpan"

                                failedTitle="Gagal!"
                                failedInfo="Data gagal disimpan"

                                showConfirm={this.state.confirm}
                                showLoading={this.state.loading}
                                showSuccess={this.state.success}
                                showFailed={this.state.failed}

                                response={this.confirmResponse}
                            />
                        </>
                    : null
                }
            </Fragment>
        )
    }
}

export default IndexView