import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import DownloadRepository from '../Repositories/DownloadRepository'


class DownloadBloc {

    regularChannel = new Subject();
    finalChannel = new Subject();
    /* regularExcelChannel = new Subject();
    finalExcelChannel = new Subject(); */
    repository = new DownloadRepository()

    constructor() {
        this.regularChannel.next({ status: apiResponse.INITIAL })
        this.finalChannel.next({ status: apiResponse.INITIAL })
        /* this.regularExcelChannel.next({ status: apiResponse.INITIAL })
        this.finalExcelChannel.next({ status: apiResponse.INITIAL }) */
    }

    fetchDownloadRegular = async (query) => {
        this.regularChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDownloadRegular(query).then((result) => {
                this.regularChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.regularChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchDownloadFinal = async (query) => {
        this.finalChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDownloadFinal(query).then((result) => {
                this.finalChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.finalChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    /* fetchDownloadRegularExcel = async (query) => {
        this.regularExcelChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDownloadRegularExcel(query).then((result) => {
                this.regularExcelChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.regularExcelChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchDownloadFinalExcel = async (query) => {
        this.finalExcelChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDownloadFinalExcel(query).then((result) => {
                this.finalExcelChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.finalExcelChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    } */
}

export default DownloadBloc