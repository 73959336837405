import {
    apiRequest,
    sigapSalaryRefList,
    sigapSalaryRefDetail
} from "../../../../../../services/adapters/sync"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", sigapSalaryRefList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    fetchDetailTable = async (query, cancelToken) => {
        return await apiRequest("get", sigapSalaryRefDetail, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository