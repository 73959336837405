import {
    apiRequest,
    masterOvertimeRegulationList,
    masterOvertimeRegulationDetail
} from "../../../../../services/adapters/master"

class RegulationRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterOvertimeRegulationList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDetail = async (query) => {
        return await apiRequest("get", masterOvertimeRegulationDetail, {
            params: query
        }, true)
    }
}

export default RegulationRepository