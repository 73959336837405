import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'

class CreateBloc {

    createInitialChannel = new Subject()
    unprocessListChannel = new Subject()
    unprocessParticipantListChannel = new Subject()
    conflictHeadChannel = new Subject()
    conflictTailChannel = new Subject()
    conflictSolver_0Channel = new Subject()
    conflictSolver_1Channel = new Subject()
    conflictSolver_2Channel = new Subject()
    levelListChannel = new Subject()
    levelUpdateChannel = new Subject()
    repository = new CreateRepository()

    constructor() {
        this.createInitialChannel.next({ status: apiResponse.INITIAL })
        this.unprocessListChannel.next({ status: apiResponse.INITIAL })
        this.unprocessParticipantListChannel.next({ status: apiResponse.INITIAL })
        this.conflictHeadChannel.next({ status: apiResponse.INITIAL })
        this.conflictTailChannel.next({ status: apiResponse.INITIAL })
        this.conflictSolver_0Channel.next({ status: apiResponse.INITIAL })
        this.conflictSolver_1Channel.next({ status: apiResponse.INITIAL })
        this.conflictSolver_2Channel.next({ status: apiResponse.INITIAL })
        this.levelListChannel.next({ status: apiResponse.INITIAL })
        this.levelUpdateChannel.next({ status: apiResponse.INITIAL })
    }

    fetchLevelList = async (query) => {
        this.levelListChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchLevelList(query).then((result) => {
                this.levelListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.levelListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchLevelUpdate = async (query) => {
        this.levelUpdateChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchLevelUpdate(query).then((result) => {
                this.levelUpdateChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.levelUpdateChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchConflictSolver_0 = async (query) => {
        this.conflictSolver_0Channel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchConflictSolver_0(query).then((result) => {
                this.conflictSolver_0Channel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.conflictSolver_0Channel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchConflictSolver_1 = async (query) => {
        this.conflictSolver_1Channel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchConflictSolver_1(query).then((result) => {
                this.conflictSolver_1Channel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.conflictSolver_1Channel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchConflictSolver_2 = async (query) => {
        this.conflictSolver_2Channel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchConflictSolver_2(query).then((result) => {
                this.conflictSolver_2Channel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.conflictSolver_2Channel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchCreateInitial = async (query) => {
        this.createInitialChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreateInitial(query).then((result) => {
                this.createInitialChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createInitialChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchUnprocessList = async (query) => {
        this.unprocessListChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchUnprocessList(query).then((result) => {
                this.unprocessListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.unprocessListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchUnprocessParticipantList = async (query) => {
        this.unprocessParticipantListChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchUnprocessParticipantList(query).then((result) => {
                this.unprocessParticipantListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.unprocessParticipantListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchConflictHead = async (query) => {
        this.conflictHeadChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchConflictDetail(query).then((result) => {
                this.conflictHeadChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.conflictHeadChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchConflictTail = async (query) => {
        this.conflictTailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchConflictDetail(query).then((result) => {
                this.conflictTailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.conflictTailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

}

export default CreateBloc