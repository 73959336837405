import React, { useState, Fragment, Component } from 'react'
import {
    Row, Col,
    Input
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

class OfficialTravelUpdateComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formData: []
        }
    }

    handleKeyPress = (event) => {
        var last = event.target.value
        if (last.length >= 10) { event.preventDefault(); }
        if (event.which !== 44) {
            if (event.which !== 8 && isNaN(String.fromCharCode(event.which))) {
                event.preventDefault();
            }
        }
    }
    handleKeyUp = (event, id) => {
        const _this = document.querySelector('#' + id)
        var value = event.target.value
        value = value.charAt(0) === '0' ? value.substring(1) : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    }

    updateStateFormTemp = (event, category, type, init) => {
        var _formData = this.state.formData
        var value = event.target.value.replace('.', '').replace('.', '').replace(',', '.')
        if (value.substring(value.length - 1) === ".") {
            value = value.substring(0, value.length - 1);
        }
        value = value === '' ? 0 : value
        var kurs = 1
        if (category === 'daily' && type === 1) {
            kurs = parseInt(_formData.kurs)
        }
        _formData[category]['items'][type][init] = value
        _formData[category]['items'][type][3] = (_formData[category]['items'][type][1] * _formData[category]['items'][type][2] * kurs).toFixed(2)
        if (init === 2) {
            _formData[category]['items'][type][init] = parseInt(value).toFixed(2)
        } else {
            _formData[category]['items'][type][init] = parseInt(value).toFixed(0)
        }
        var total = 0;
        _formData[category]['items'].map((item, key) => {
            total += parseInt(item[3])
        })
        _formData[category]['total'] = total.toFixed(2)
        this.setState({
            formData: _formData
        })
    }

    numberWithPoint(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    componentDidMount() {
        this.setState({
            formData: this.props.formData
        })
    }

    componentWillUnmount() {

    }

    render() {
        return (
            <Fragment>
                <div className="modal-body">
                    <Col md="12">
                        <div className="table-responsive">
                            <Table
                                className="table table-striped table-bordered table-hover"
                                style={{ minWidth: 750 }}
                            >
                                <Thead>
                                    <Tr>
                                        <Th className="text-center" width="245">Uang Harian</Th>
                                        <Th className="text-center" width="170">Penginapan</Th>
                                        <Th className="text-center" width="170">Transportasi</Th>
                                        <Th className="text-center" width="140">Representatif</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <tr>
                                        <td className="text-end">
                                            <Row className="g-2 align-items-center mb-1">
                                                <Col className="col-auto">
                                                    <div style={{ width: 65 }}>Biasa</div>
                                                </Col>
                                                <Col className="col-auto">
                                                    <div
                                                        style={{ width: 40 }}
                                                    >
                                                        {Object.keys(this.state.formData).length > 0 ? this.state.formData['daily']['items'][1][1] : 0} x
                                                    </div>
                                                </Col>
                                                <Col className="col-auto">
                                                    <div
                                                        style={{ width: 100 }}
                                                        className="text-end"
                                                    >
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['daily']['items'][1][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="g-2 align-items-center mb-1">
                                                <Col className="col-auto">
                                                    <div style={{ width: 65 }}>Fullday</div>
                                                </Col>
                                                <Col className="col-auto">
                                                    <div
                                                        style={{ width: 40 }}
                                                    >
                                                        {Object.keys(this.state.formData).length > 0 ? this.state.formData['daily']['items'][2][1] : 0} x
                                                    </div>
                                                </Col>
                                                <Col className="col-auto">
                                                    <div
                                                        style={{ width: 100 }}
                                                        className="text-end"
                                                    >
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['daily']['items'][2][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="g-2 align-items-center mb-1">
                                                <Col className="col-auto">
                                                    <div style={{ width: 65 }}>Fullboard</div>
                                                </Col>
                                                <Col className="col-auto">
                                                    <div
                                                        style={{ width: 40 }}
                                                    >
                                                        {Object.keys(this.state.formData).length > 0 ? this.state.formData['daily']['items'][3][1] : 0} x
                                                    </div>
                                                </Col>
                                                <Col className="col-auto">
                                                    <div
                                                        style={{ width: 100 }}
                                                        className="text-end"
                                                    >
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['daily']['items'][3][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </td>
                                        <td>
                                            <Row className="g-2 align-items-center mb-1">
                                                <Col className="col-auto">
                                                    <div style={{ width: 40 }}>Biasa</div>
                                                </Col>
                                                <Col className="col-auto">
                                                    <div
                                                        style={{ width: 100 }}
                                                        className="text-end"
                                                    >
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['hotel']['items'][1][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="g-2 align-items-center mb-1">
                                                <Col className="col-auto">
                                                    <div style={{ width: 40 }}>Riil</div>
                                                </Col>
                                                <Col className="col-auto">
                                                    <div
                                                        style={{ width: 100 }}
                                                        className="text-end"
                                                    >
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['hotel']['items'][4][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </td>
                                        <td>
                                        <Row className="g-2 align-items-center mb-1">
                                            <Col className="col-12">
                                                    <label className="m-0 card ps-2 mb-1" style={{fontSize: 10, textTransform: 'uppercase', fontWeight: 600, letterSpacing: 4}}>Darat</label>
                                                </Col>
                                            </Row>
                                            <Row className="g-2 align-items-center mb-1">
                                                <div className="col-md-12">
                                                    <div className="float-start ps-2">Biasa</div>
                                                    <div className="float-end">
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['transport']['items'][1][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="float-start ps-2">Riil</div>
                                                    <div className="float-end">
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['transport']['items'][4][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </div>
                                            </Row>
                                            <hr />
                                            <Row className="g-2 align-items-center mb-1">
                                                <Col className="col-12">
                                                    <label className="m-0 card ps-2 mb-1" style={{fontSize: 10, textTransform: 'uppercase', fontWeight: 600, letterSpacing: 4}}>Laut</label>
                                                </Col>
                                            </Row>
                                            <Row className="g-2 align-items-center mb-1">
                                                <div className="col-md-12">
                                                    <div className="float-start ps-2">Biasa</div>
                                                    <div className="float-end">
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['transport']['items'][21][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="float-start ps-2">Riil</div>
                                                    <div className="float-end">
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['transport']['items'][24][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </div>
                                            </Row>
                                            <hr />
                                            <Row className="g-2 align-items-center mb-1">
                                                <Col className="col-12">
                                                    <label className="m-0 card ps-2 mb-1" style={{fontSize: 10, textTransform: 'uppercase', fontWeight: 600, letterSpacing: 4}}>Udara</label>
                                                </Col>
                                            </Row>
                                            <Row className="g-2 align-items-center mb-1">
                                                <div className="col-md-12">
                                                    <div className="float-start ps-2">Biasa</div>
                                                    <div className="float-end">
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['transport']['items'][31][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="float-start ps-2">Riil</div>
                                                    <div className="float-end">
                                                        {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['transport']['items'][34][2].replace('.00', '').replace('.', ',')) : 0}
                                                    </div>
                                                </div>
                                            </Row>
                                        </td>
                                        <td>
                                            <div
                                                style={{ width: 100 }}
                                                className="text-end"
                                            >
                                                {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['representative']['items'][1][2].replace('.00', '').replace('.', ',')) : 0}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-end"><b>Rp. {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['daily']['total'].replace('.00', '').replace('.', ',')) : 0},-</b></td>
                                        <td className="text-end"><b>Rp. {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['hotel']['total'].replace('.00', '').replace('.', ',')) : 0},-</b></td>
                                        <td className="text-end"><b>Rp. {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['transport']['total'].replace('.00', '').replace('.', ',')) : 0},-</b></td>
                                        <td className="text-end"><b>Rp. {Object.keys(this.state.formData).length > 0 ? this.numberWithPoint(this.state.formData['representative']['total'].replace('.00', '').replace('.', ',')) : 0},-</b></td>
                                    </tr>
                                </Tbody>
                            </Table>
                        </div>
                    </Col>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            this.props.closeModal()
                        }}
                        className="btn btn-danger waves-effect"
                    >
                        Tutup
                    </button>
                </div>
            </Fragment>
        )
    }
}

export default OfficialTravelUpdateComponent
