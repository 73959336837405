import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Redirect } from "react-router-dom"

import {
    Tooltip,
    Modal
} from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../services/adapters/base"

import IndexViewBloc from '../Blocs/IndexViewBloc'
import PpkDetailComponent from './PpkDetailComponent'
import apiResponse from '../../../../../services/apiResponse'

class PpkInactiveListComponent extends Component {

    indexViewBloc = new IndexViewBloc();

    source
    historyTable = 'historyPpkInactiveListComponent'
    defaultOrder = 'ppk_name'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            showDetail: false,
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order
        }
        await this.indexViewBloc.fetchRowPpkListInactive(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.activeData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    activeData = async () => {
        await this.indexViewBloc.fetchActive({ uuid: this.state.activeUuid })
    }

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.rowPpkListInactiveChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.activeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        loadingTable: true
                    }, function () {
                        this.setTokenAPI()
                        this.props.handleActiveSuccess()
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.rowPpkListInactiveChannel.unsubscribe()
    }

    columns = [
        {
            key: "ppk_code",
            text: "Kode PPK",
            sortable: true,
            width: '180',
            align: 'center'
        },
        {
            key: "ppk_employee_name",
            text: "Nama Pegawai",
            sortable: true,
            width: '280',
            align: 'left'
        },
        {
            key: "ppk_name",
            text: "Nama PPK",
            sortable: true,
            align: 'left'
        },
        {
            key: "ppk_staff_total",
            text: "Jumlah Anggota",
            width: '200',
            sortable: true,
            align: 'center',
            cell: record => {
                return (
                    <>
                        {
                            record.ppk_staff_total === 0 ? '-' : record.ppk_staff_total
                        }
                    </>
                )
            }
        },
        {
            key: "action",
            text: "Aksi",
            width: '80',
            align: 'center',
            cell: record => {
                const thisId = record.tunjab_id
                return (
                    <>
                        <button
                            className="btn btn-success btn-sm"
                            id={"detail-" + thisId}
                            onClick={() => {
                                this.setState({
                                    ppkUuid: record.ppk_uuid,
                                    showDetail: true
                                })
                            }}
                            style={{
                                marginRight: '2px',
                                padding: '2px 5px'
                            }}
                        >
                            <i className="uil-align-left"></i>
                        </button>
                        <Tooltip
                            style={{
                                marginBottom: 2
                            }}
                            placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                            toggle={() => {
                                this.setState({
                                    ["detail" + thisId]: !this.state["detail" + thisId]
                                })
                            }}
                        >Detail</Tooltip>
                        {accessRole('delete') ?
                            <span>
                                <button 
                                    className="btn btn-primary btn-sm" 
                                    id={"delete-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            activeUuid: record.ppk_uuid,
                                            confirm: true
                                        })
                                    }}
                                    style={{
                                        padding: '2px 5px'
                                    }}>
                                    <i className="uil-check"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["delete" + thisId]} target={"delete-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["delete" + thisId] : !this.state["delete" + thisId]
                                        })
                                    }}
                                >Aktifkan</Tooltip>
                            </span>
                            : 
                            <button
                                className="btn btn-soft-secondary btn-sm" disabled={true}
                                style={{
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-check"></i>
                            </button>
                        }
                        {/* { accessRole('update') ? 
                            <span>
                                <button
                                    className="btn btn-warning btn-sm"
                                    id={"update-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            updateUuid: record.ppk_uuid
                                        })
                                    }}
                                    style={{
                                        marginRight: '2px',
                                        padding: '2px 5px'
                                    }}>
                                        <i className="uil-pen"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["update" + thisId]} target={"update-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["update" + thisId] : !this.state["update" + thisId]
                                        })
                                    }}
                                >Perbaharui</Tooltip>
                            </span>
                            : 
                            <button
                                className="btn btn-soft-secondary btn-sm" disabled={true}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-pen"></i>
                            </button>
                        } */}
                    </>
                )
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={700}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan mengaktifkan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dinaktifkan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dinaktifkan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                <Modal
                    size="xl"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Detail PPK
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.showDetail ?
                            <PpkDetailComponent uuid={this.state.ppkUuid} />
                            : <></>}
                    </div>
                </Modal>
                { this.state.updateUuid && this.state.updateUuid !== null ?
                    <Redirect to={"/official-travel/ppk.html?update&uuid=" + this.state.updateUuid}/>
                : null }
            </>
        )
    }
}

export default PpkInactiveListComponent