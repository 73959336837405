import {
    apiRequest,
    officialTravelVerificationProcessDetail,
    officialTravelVerificationProcessParticipant,
    officialTravelVerificationProcessVerify,
    officialTravelVerificationProcessReject,
    officialTravelVerificationSource
} from "../../../../../../services/adapters/process"

class VerifyRepository {

    fetchVerify = async (query) => {
        return await apiRequest("post", officialTravelVerificationProcessVerify, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", officialTravelVerificationProcessReject, {
            body: query
        }, true)
    }

    fetchDetail = async (query) => {
        return await apiRequest("get", officialTravelVerificationProcessDetail, {
            params: query
        }, true)
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelVerificationProcessParticipant, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchSource = async (query) => {
        return await apiRequest("get", officialTravelVerificationSource, {
            params: query
        }, true)
    }
}

export default VerifyRepository
