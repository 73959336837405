import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"

import moment from 'moment'

import {
    Tooltip,
    Modal
} from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'
import { cancelToken } from "../../../../../../services/adapters/base"
import IndexViewBloc from '../Blocs/IndexViewBloc'
import apiResponse from '../../../../../../services/apiResponse'

import EmployeeDetailComponent from '../Components/EmployeeDetailComponent'

class EmployeeIndexListComponent extends Component {
    indexViewBloc = new IndexViewBloc();

    source
    historyTable = 'historyIntegrationEmployeeIndexListComponent'
    defaultOrder = 'employee_nip'
    defaultSize = 20
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,

            showDetail: false,
            employeeUuid: null,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: this.props.status,
            year: this.props.year,
            month: this.props.month
        }
        await this.indexViewBloc.fetchMonths(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {}

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.monthsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.monthsChannel.unsubscribe()
    }

    columns = [
        {
            key: "employee_nip",
            text: "Nip",
            className: "",
            sortable: true,
            width: '150'
        },
        {
            key: "employee_fullname",
            text: "Nama",
            className: "",
            align: 'left',
            sortable: true,
            width: '250'
        },
        {
            key: "employee_work_unit_name",
            text: "Unit Kerja",
            className: "",
            align: 'left',
            sortable: true
        },
        {
            key: "employee_changes",
            text: "Perubahan",
            className: "",
            align: 'center',
            width: '140',
            sortable: true,
            cell: record => {
                return (
                    record.employee_changes > 0 ?
                    <center>
                        <div className="bg-danger rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>
                    </center>
                    : <>-</>
                )
            }
        },
        {
            key: "employee_uuid",
            text: "Aksi",
            className: "",
            width: '50',
            align: 'center',
            cell: record => {
                const thisId = record.employee_id
                return (
                    <>
                        <span>
                            <button
                                className="btn btn-success btn-sm"
                                id={"detail-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        showDetail: true,
                                        employeeUuid: record.employee_uuid
                                    });
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-align-left"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["detail" + thisId] : !this.state["detail" + thisId]
                                    })
                                }}
                            >Detail</Tooltip>
                        </span>
                    </>
                )
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1020}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />

                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Informasi Pegawai
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <EmployeeDetailComponent uuid={this.state.employeeUuid} year={this.props.year} month={this.props.month} />
                    </div>
                </Modal>
            </>
        )
    }

}

export default EmployeeIndexListComponent