import {
    apiRequest,
    tplnSusulanYears,
    tplnSusulanMonths,
    tplnSusulanHistory,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", tplnSusulanYears, {}, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", tplnSusulanMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", tplnSusulanHistory, {
            params: query
        }, true)
    }
}

export default IndexViewRepository