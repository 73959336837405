import {
    apiRequest,
    masterTunkinClassCurrent
} from "../../../../../../services/adapters/master"

class IndexViewRepository {

    fetchCurrent = async () => {
        return await apiRequest("get", masterTunkinClassCurrent, {}, true)
    }
}

export default IndexViewRepository