import {
    apiRequest,
    tunkinRegulerYears,
    tunkinRegulerNrss,
    tunkinRegulerMonths,
    tunkinRegulerHistory,
    tunkinRegulerPushSPM,
    tunkinRegulerUpdateSPM,
    tunkinRegulerResetApprove,
    tunkinRegulerResetReject,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {
    fetchYears = async () => {
        return await apiRequest("get", tunkinRegulerYears, {}, true)
    }

    fetchNrss = async () => {
        return await apiRequest("get", tunkinRegulerNrss, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunkinRegulerMonths,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchHistory = async (query) => {
        return await apiRequest(
            "get",
            tunkinRegulerHistory,
            {
                params: query,
            },
            true
        )
    }

    fetchPushSPM = async (query) => {
        return await apiRequest(
            "post",
            tunkinRegulerPushSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest(
            "post",
            tunkinRegulerUpdateSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchResetApprove = async (query) => {
        return await apiRequest(
            "post",
            tunkinRegulerResetApprove,
            {
                body: query,
            },
            true
        )
    }

    fetchResetReject = async (query) => {
        return await apiRequest(
            "post",
            tunkinRegulerResetReject,
            {
                body: query,
            },
            true
        )
    }
}

export default IndexViewRepository
