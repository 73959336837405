import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import DataTableRepository from '../Repositories/DataTableRepository'


class DataTableBloc {

    rowtableChannel = new Subject();
    deleteChannel = new Subject();
    uploadChannel = new Subject();
    repository = new DataTableRepository()

    constructor() {
        this.rowtableChannel.next({status: apiResponse.INITIAL})
        this.deleteChannel.next({status: apiResponse.INITIAL})
        this.uploadChannel.next({status: apiResponse.INITIAL})
    }

    fetchRowTable = async (query, cancelToken) => {
        this.rowtableChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTable(query, cancelToken).then((result) => {
                this.rowtableChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDelete = async (query) => {
        this.deleteChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDelete(query).then((result) => {
                this.deleteChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.deleteChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchUpload = async (query) => {
        this.uploadChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchUpload(query).then((result) => {
                this.uploadChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.uploadChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

}

export default DataTableBloc