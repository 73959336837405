import {
    apiRequest,
    officialTravelPpkList,
    officialTravelPpkDetail,
    officialTravelPpkInactive,
    officialTravelPpkActive
} from "../../../../../services/adapters/process"

class IndexViewRepository {

    fetchRowPpkList = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelPpkList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchPpkDetail = async (query) => {
        return await apiRequest("get", officialTravelPpkDetail, {
            params: query
        }, true)
    }

    fetchInactive = async (query) => {
        return await apiRequest("post", officialTravelPpkInactive, {
            body: query
        }, true)
    }

    fetchActive = async (query) => {
        return await apiRequest("post", officialTravelPpkActive, {
            body: query
        }, true)
    }
}

export default IndexViewRepository