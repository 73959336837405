import {
    apiRequest,
    officialTravelRegisterDetail,
    officialTravelRegisterParticipant,
    officialTravelRegisterLevelOption,
    officialTravelRegisterUpdate,
    officialTravelRegisterUpdateAll
} from "../../../../../services/adapters/process"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", officialTravelRegisterDetail, {
            params: query
        }, true)
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelRegisterParticipant, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchLevelOption = async (query) => {
        return await apiRequest("get", officialTravelRegisterLevelOption, {
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", officialTravelRegisterUpdate, {
            body: query
        }, true)
    }

    fetchUpdateAll = async (query) => {
        return await apiRequest("post", officialTravelRegisterUpdateAll, {
            body: query
        }, true)
    }
}

export default DetailRepository
