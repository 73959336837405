import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link, Redirect } from "react-router-dom"
import Select from "react-select"
import Shimmer from "react-shimmer-effect";

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Label,
    Input,
    Alert
} from "reactstrap"
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { Preview } from '../../../../helpers/ui/FileInput'

import CreateBloc from './Blocs/CreateBloc'
import apiResponse from '../../../../services/apiResponse'
import moment from 'moment'

class CreateView extends Component {

    createBloc = new CreateBloc();

    constructor(props) {
        super(props)
        this.state = {
            ppk: localStorage.getItem('ppkUuid'),
            loadingSearch: false,
            loadingCreate: false,
            search: '',
            show: 1, //1.First, 2.Loading, 3.Error
            travelUuid: '',
            travelPpkUuid: '',
            detail: null,
            loadingParticipantList: false,
            participantList: {},
            isCheckedAll: true,
            isCheckedRow: {},
            selectedParticipant: {},

            dataForm: {},
        }
    }

    doSearch() {
        this.setState({
            loadingSearch: true,
            show: 2,
            detail: null,
            travelUuid: '',
            participantList: {},
            isCheckedAll: true,
            isCheckedRow: {}
        })
        this.createBloc.fetchSearch({ st: this.state.search })
    }

    componentDidMount() {
        this.createBloc.searchChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        if (response.detail === null) {
                            this.setState({
                                loadingSearch: false,
                                show: 3,
                                detail: null
                            })
                            setTimeout(() => {
                                this.setState({
                                    search: '',
                                    show: 1
                                })
                            }, 2000)
                        } else {
                            this.setState({
                                search: '',
                                detail: response.detail,
                                travelUuid: response.detail.official_travel_uuid
                            })
                            this.createBloc.fetchParticipant({ uuid: response.detail.official_travel_uuid })
                        }
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.createBloc.participantChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        let _isCheckedRow = {}
                        let _selectedParticipant = {}
                        for (var p = 0; p < response.length; ++p) {
                            if (response[p].official_travel_participant_problem.length === 0) {
                                if (response[p].official_travel_ppk_ppk_uuid === null || (response[p].official_travel_ppk_ppk_uuid === this.state.ppk && response[p].official_travel_participant_official_travel_process_uuid === null)) {
                                    _isCheckedRow[response[p].official_travel_participant_uuid] = true
                                    _selectedParticipant[response[p].official_travel_participant_uuid] = true
                                }
                            }
                        }
                        this.setState({
                            participantList: response,
                            isCheckedRow: _isCheckedRow,
                            selectedParticipant: _selectedParticipant,
                            loadingSearch: false
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        travelPpkUuid: result.data.response,
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.createBloc.searchChannel.unsubscribe()
        this.createBloc.participantChannel.unsubscribe()
        this.createBloc.createChannel.unsubscribe()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        var participants = []
        Object.entries(this.state.selectedParticipant).map((a) => {
            participants.push(a[0])
        })
        this.setState({
            dataForm: {
                uuid: this.state.travelUuid,
                participants: participants
            },
            confirm: true
        })
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, { field: 'document', name: 'Trash/Unknown' })
        await this.createBloc.fetchCreate(formData)
    }

    objectLength = (object) => {
        var length = 0;
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                ++length;
            }
        }
        return length;
    };

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Mendaftarkan Surat Tugas</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Masukkan nomor Surat Tugas, dilanjutkan dengan menekan tombol cari.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <label className="col-md-2 pt-2">
                                                Nomor Surat Tugas
                                            </label>
                                            <Col md="3">
                                                <Input
                                                    type="text"
                                                    name="search"
                                                    id="search"
                                                    autoComplete="off"
                                                    value={this.state.search}
                                                    placeholder="Nomor Surat Tugas"
                                                    onChange={(event) => {
                                                        this.setState({
                                                            search: event.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            {!this.state.loadingSearch ?
                                                <Col md="6">
                                                    <Button
                                                        type="button"
                                                        className="btn btn-warning"
                                                        onClick={() => {
                                                            this.doSearch()
                                                        }}
                                                    >
                                                        <i className="uil-search"></i>
                                                    </Button>
                                                </Col>
                                                :
                                                <></>
                                            }
                                        </Row>
                                        {this.state.show === 1 ?
                                            <>
                                                <hr />
                                                <div className="p-4">
                                                    <Alert color="warning">
                                                        Masukkan nomor Surat Tugas untuk melihat detail dan mendaftarkannya
                                                    </Alert>
                                                </div>
                                                <hr />
                                            </>
                                            :
                                            <>
                                                {this.state.show === 2 ?
                                                    <>
                                                        <hr />
                                                        <Row className="mb-3">
                                                            <label className="col-md-2">
                                                                Nomor
                                                            </label>
                                                            <Col md="10">
                                                                {
                                                                    !this.state.loadingSearch ?
                                                                        <>{this.state.detail.official_travel_number !== "" ? this.state.detail.official_travel_number : '-'}</>
                                                                        : <Shimmer className="shimmer"><div style={{ width: 100, height: 15 }}></div></Shimmer>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label className="col-md-2">
                                                                Tanggal Mulai
                                                            </label>
                                                            <Col md="10">
                                                                {
                                                                    !this.state.loadingSearch ?
                                                                        <>{this.state.detail.official_travel_start_date !== "" ? moment(this.state.detail.official_travel_start_date).format('LL') : '-'}</>
                                                                        : <Shimmer className="shimmer"><div style={{ width: 140, height: 15 }}></div></Shimmer>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label className="col-md-2">
                                                                Tanggal Selesai
                                                            </label>
                                                            <Col md="10">
                                                                {
                                                                    !this.state.loadingSearch ?
                                                                        <>{this.state.detail.official_travel_end_date !== "" ? moment(this.state.detail.official_travel_end_date).format('LL') : '-'}</>
                                                                        : <Shimmer className="shimmer"><div style={{ width: 140, height: 15 }}></div></Shimmer>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label className="col-md-2">
                                                                Tempat Dinas
                                                            </label>
                                                            <Col md="10">
                                                                {
                                                                    !this.state.loadingSearch ?
                                                                        <>
                                                                            {this.state.detail.official_travel_oversea === 1 ? ('Dalam Negeri' + (this.state.detail.official_travel_domicile === 1 ? ' (Dalam Kota)' : ' (Luar Kota)')) : 'Luar Negeri'}
                                                                        </>
                                                                        : <Shimmer className="shimmer"><div style={{ width: 120, height: 15 }}></div></Shimmer>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        {
                                                            !this.state.loadingSearch ?
                                                                <>
                                                                    {this.state.detail.official_travel_oversea === 1 ?
                                                                        <>
                                                                            <Row className="mb-3">
                                                                                <label className="col-md-2">
                                                                                    Provinsi
                                                                                </label>
                                                                                <Col md="10">
                                                                                    {
                                                                                        !this.state.loadingSearch ?
                                                                                            <>{this.state.detail.official_travel_province !== "" ? this.state.detail.official_travel_province : '-'}</>
                                                                                            : <Shimmer className="shimmer"><div style={{ width: 100, height: 15 }}></div></Shimmer>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="mb-3">
                                                                                <label className="col-md-2">
                                                                                    Kota
                                                                                </label>
                                                                                <Col md="10">
                                                                                    {
                                                                                        !this.state.loadingSearch ?
                                                                                            <>{this.state.detail.official_travel_city !== "" ? this.state.detail.official_travel_city : '-'}</>
                                                                                            : <Shimmer className="shimmer"><div style={{ width: 140, height: 15 }}></div></Shimmer>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Row className="mb-3">
                                                                                <label className="col-md-2">
                                                                                    Negara
                                                                                </label>
                                                                                <Col md="10">
                                                                                    {
                                                                                        !this.state.loadingSearch ?
                                                                                            <>{this.state.detail.official_travel_country !== "" ? this.state.detail.official_travel_country : '-'}</>
                                                                                            : <Shimmer className="shimmer"><div style={{ width: 100, height: 15 }}></div></Shimmer>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    }
                                                                </>
                                                                : <></>
                                                        }
                                                        <Row className="mb-3">
                                                            <label className="col-md-2">
                                                                Tujuan
                                                            </label>
                                                            <Col md="10">
                                                                {
                                                                    !this.state.loadingSearch ?
                                                                        <>{this.state.detail.official_travel_destination !== "" ? this.state.detail.official_travel_destination : '-'}</>
                                                                        :
                                                                        <Shimmer className="shimmer"><div style={{ width: '80%', height: 15 }}></div></Shimmer>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label className="col-md-2">
                                                                Keterangan
                                                            </label>
                                                            <Col md="10">
                                                                {
                                                                    !this.state.loadingSearch ?
                                                                        <>{this.state.detail.official_travel_description !== "" ? this.state.detail.official_travel_description : '-'}</>
                                                                        :
                                                                        <>
                                                                            <Shimmer className="shimmer"><div style={{ width: '100%', height: 15 }}></div></Shimmer>
                                                                            <Shimmer className="shimmer"><div style={{ width: '20%', height: 15 }}></div></Shimmer>
                                                                        </>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label className="col-md-2">
                                                                File Surat Keputusan
                                                            </label>
                                                            <Col md="10">
                                                                {
                                                                    !this.state.loadingSearch ?
                                                                        <Preview
                                                                            col={3}
                                                                            value={[{
                                                                                documents_name: this.state.detail.official_travel_letter_name,
                                                                                documents_path: this.state.detail.official_travel_letter_path,
                                                                                documents_mime_type: this.state.detail.official_travel_letter_mime,
                                                                                documents_file_size: this.state.detail.official_travel_letter_size,
                                                                                documents_source: 'minio',
                                                                            }]}
                                                                        />
                                                                        : <Shimmer><div className="shimmer" style={{ width: 150, height: 200 }}></div></Shimmer>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="mb-3">
                                                            <label className="col-md-2">
                                                                Pilih Pegawai
                                                            </label>
                                                            <Col md="10">
                                                                <Col className="mb-3" md="12">
                                                                    <div>
                                                                        <span
                                                                            className="btn btn-danger btn-sm"
                                                                            style={{
                                                                                padding: '0px 0px',
                                                                                height: 14,
                                                                                width: 14,
                                                                                cursor: 'default',
                                                                                marginBottom: 3,
                                                                            }}
                                                                        >
                                                                        </span> Duplikasi Tanggal
                                                                    </div>
                                                                    <div>
                                                                        <span
                                                                            className="btn btn-warning btn-sm"
                                                                            style={{
                                                                                padding: '0px 0px',
                                                                                height: 14,
                                                                                width: 14,
                                                                                cursor: 'default',
                                                                                marginBottom: 3,
                                                                            }}
                                                                        >
                                                                        </span> Sudah didaftarkan dan di proses
                                                                    </div>
                                                                    <div>
                                                                        <span
                                                                            className="btn btn-secondary btn-sm"
                                                                            style={{
                                                                                padding: '0px 0px',
                                                                                height: 14,
                                                                                width: 14,
                                                                                cursor: 'default',
                                                                                marginBottom: 3,
                                                                            }}
                                                                        >
                                                                        </span> Didaftarkan PPK lain
                                                                    </div>
                                                                </Col>
                                                                <Col md="12">
                                                                    <Table
                                                                        className="table table-striped table-bordered table-hover"
                                                                    >
                                                                        <Thead>
                                                                            <Tr>
                                                                                <Th width="50px" data-priority="1" className="text-center">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        value=""
                                                                                        name="thisCheck"
                                                                                        checked={this.state.isCheckedAll}
                                                                                        onChange={() => {
                                                                                            let newValue = !this.state.isCheckedAll
                                                                                            let isCheckedRows = {}
                                                                                            var _selectedParticipant = {}
                                                                                            Object.entries(this.state.isCheckedRow).map((a) => {
                                                                                                isCheckedRows[a[0]] = newValue
                                                                                                if (newValue) {
                                                                                                    _selectedParticipant[a[0]] = true
                                                                                                }
                                                                                            })
                                                                                            this.setState({
                                                                                                isCheckedRow: isCheckedRows,
                                                                                                isCheckedAll: newValue,
                                                                                                selectedParticipant: _selectedParticipant
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </Th>
                                                                                <Th width="*" data-priority="1">Nama</Th>
                                                                                <Th width="30%" data-priority="1" className="text-center">Tanggal</Th>
                                                                                <Th colspan="2" width="20*" data-priority="1">Jabatan</Th>
                                                                            </Tr>
                                                                        </Thead>
                                                                        <Tbody>
                                                                            <>
                                                                                {
                                                                                    Object.entries(this.state.participantList).map((employee, i) => {
                                                                                        var uuid = employee[1].official_travel_participant_uuid
                                                                                        return <tr key={i}>
                                                                                            <td className="text-center" style={{ verticalAlign: 'middle' }}>
                                                                                                {employee[1].official_travel_participant_problem.length === 0 ?
                                                                                                    (employee[1].official_travel_ppk_ppk_uuid === null || (employee[1].official_travel_ppk_ppk_uuid === this.state.ppk && employee[1].official_travel_participant_official_travel_process_uuid === null)) ?
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="checkbox"
                                                                                                            value="1"
                                                                                                            name="thisCheck"
                                                                                                            checked={this.state.isCheckedRow[uuid]}
                                                                                                            onChange={() => {
                                                                                                                let newValue = !this.state.isCheckedRow[uuid]
                                                                                                                var _isCheckedRow = this.state.isCheckedRow
                                                                                                                var _selectedParticipant = this.state.selectedParticipant
                                                                                                                _isCheckedRow[uuid] = newValue
                                                                                                                if (!newValue) {
                                                                                                                    delete _selectedParticipant[uuid]
                                                                                                                } else {
                                                                                                                    _selectedParticipant[uuid] = true
                                                                                                                }

                                                                                                                var allChecked = true
                                                                                                                Object.entries(_isCheckedRow).map((a) => {
                                                                                                                    if (!a[1]) {
                                                                                                                        allChecked = false
                                                                                                                    }
                                                                                                                })
                                                                                                                this.setState({
                                                                                                                    isCheckedRow: _isCheckedRow,
                                                                                                                    selectedParticipant: _selectedParticipant,
                                                                                                                    isCheckedAll: allChecked
                                                                                                                })
                                                                                                            }}
                                                                                                        ></input>
                                                                                                        : <>
                                                                                                            {(employee[1].official_travel_ppk_ppk_uuid === this.state.ppk) ?
                                                                                                                <span
                                                                                                                    className="btn btn-warning btn-sm"
                                                                                                                    style={{
                                                                                                                        padding: '0px 0px',
                                                                                                                        height: 14,
                                                                                                                        width: 14,
                                                                                                                        cursor: 'default',
                                                                                                                        marginBottom: 3,
                                                                                                                    }}
                                                                                                                />
                                                                                                                :
                                                                                                                <span
                                                                                                                    className="btn btn-secondary btn-sm"
                                                                                                                    style={{
                                                                                                                        padding: '0px 0px',
                                                                                                                        height: 14,
                                                                                                                        width: 14,
                                                                                                                        cursor: 'default',
                                                                                                                        marginBottom: 3,
                                                                                                                    }}
                                                                                                                />
                                                                                                            }
                                                                                                        </>
                                                                                                    :
                                                                                                    <span
                                                                                                        className="btn btn-danger btn-sm"
                                                                                                        style={{
                                                                                                            padding: '0px 0px',
                                                                                                            height: 14,
                                                                                                            width: 14,
                                                                                                            cursor: 'default',
                                                                                                            marginBottom: 3,
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                            </td>
                                                                                            <td style={{ verticalAlign: 'middle', color: employee[1].official_travel_participant_problem.length === 0 ? ((employee[1].official_travel_ppk_ppk_uuid === null) ? '#495057' : (employee[1].official_travel_ppk_ppk_uuid === this.state.ppk ? (employee[1].official_travel_participant_official_travel_process_uuid !== null ? '#cc7700' : '#0000aa') : '#aaaaaa')) : '#aa0000' }} className="text-left">
                                                                                                <div>{employee[1].official_travel_participant_employee_name} { employee[1].official_travel_participant_status === 9 && <><label className="bg-soft-danger badge rounded-pill font-size-12">Batal</label></> }</div>
                                                                                            </td>
                                                                                            <td style={{ verticalAlign: 'middle', color: employee[1].official_travel_participant_problem.length === 0 ? ((employee[1].official_travel_ppk_ppk_uuid === null) ? '#495057' : (employee[1].official_travel_ppk_ppk_uuid === this.state.ppk ? (employee[1].official_travel_participant_official_travel_process_uuid !== null ? '#cc7700' : '#0000aa') : '#aaaaaa')) : '#aa0000' }} className="text-center">
                                                                                                <div>{moment(employee[1].official_travel_participant_date_start).format('Do MMM YYYY')} <i className="uil-arrow-right"></i> {moment(employee[1].official_travel_participant_date_end).format('Do MMM YYYY')}</div>
                                                                                            </td>
                                                                                            <td colspan={employee[1].official_travel_participant_problem.length === 0 ? 2 : 1} style={{ verticalAlign: 'middle', color: employee[1].official_travel_participant_problem.length === 0 ? ((employee[1].official_travel_ppk_ppk_uuid === null) ? '#495057' : (employee[1].official_travel_ppk_ppk_uuid === this.state.ppk ? (employee[1].official_travel_participant_official_travel_process_uuid !== null ? '#cc7700' : '#0000aa') : '#aaaaaa')) : '#aa0000' }} className="text-left">
                                                                                                {employee[1].official_travel_participant_position_name}
                                                                                            </td>
                                                                                            {
                                                                                                employee[1].official_travel_participant_problem.length > 0 ?
                                                                                                    <td className="text-center" style={{ width: 210 }}>
                                                                                                        {/* <span
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                            style={{
                                                                                                                padding: '0px 8px'
                                                                                                            }}
                                                                                                            onClick={() => {

                                                                                                            }}
                                                                                                        >Ajukan Penyesuaian Tanggal</span> */}
                                                                                                        {/* <span
                                                                                                            className="btn-soft-warning btn-sm"
                                                                                                            style={{
                                                                                                                padding: '2px 8px',
                                                                                                                cursor: 'default',
                                                                                                                fontSize: '0.7875rem',
                                                                                                                borderRadius: 4,
                                                                                                            }}
                                                                                                        >Sedang disesuaikan</span> */}
                                                                                                    </td>
                                                                                                    : <></>
                                                                                            }
                                                                                        </tr>;
                                                                                    })
                                                                                }
                                                                                {
                                                                                    this.state.loadingSearch ?
                                                                                        <>
                                                                                            <tr>
                                                                                                <td><Shimmer className="shimmer"><div style={{ width: '100%', height: 16 }}></div></Shimmer></td>
                                                                                                <td><Shimmer className="shimmer"><div style={{ width: '100%', height: 16 }}></div></Shimmer></td>
                                                                                                <td><Shimmer className="shimmer"><div style={{ width: '100%', height: 16 }}></div></Shimmer></td>
                                                                                                <td><Shimmer className="shimmer"><div style={{ width: '100%', height: 16 }}></div></Shimmer></td>
                                                                                            </tr>
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                            </>
                                                                        </Tbody>
                                                                    </Table>
                                                                    <AvField
                                                                        name="participant_selected"
                                                                        errorMessage="Pilih minimal 1 partisipan"
                                                                        type="hidden"
                                                                        value={this.objectLength(this.state.selectedParticipant) === 0 ? '' : '1'}
                                                                        className="form-control"
                                                                        id="fieldNumber"
                                                                        validate={{ required: { value: !this.state.loadingSearch ? (this.state.detail.ppk_uuid !== this.state.ppk) : true } }}
                                                                    />
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                    </>
                                                    :
                                                    <>
                                                        <hr />
                                                        <div className="p-4">
                                                            <Alert color="danger">
                                                                Nomor Surat Tugas tidak ditemukan
                                                            </Alert>
                                                        </div>
                                                        <hr />
                                                    </>
                                                }
                                            </>
                                        }
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2">
                                            </Col>
                                            <Col md="10">
                                                {this.state.travelUuid !== '' ?
                                                    <>
                                                        <div className="waves-effect">
                                                            <Button color="success" type="submit">
                                                                <i className="uil-arrow-up-right"></i> Daftarkan Surat Tugas
                                                            </Button>
                                                            {/* <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    confirm: true
                                                                })
                                                            }}
                                                        >
                                                            <i className="uil-cog"></i> Daftarkan Surat Tugas
                                                        </Button> */}
                                                        </div>
                                                        {" "}
                                                    </>
                                                    :
                                                    <></>
                                                }
                                                <Link to="/official-travel/register.html" className="waves-effect">
                                                    <Button color="danger"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo={"Data berhasil " + (this.state.travelPpkUuid === '' ? 'dihapus' : 'disimpan')}

                    failedTitle="Gagal!"
                    failedInfo={"Data gagal disimpan"}

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                {
                    this.state.redirect ?
                        <Redirect to={'/official-travel/register.html' + (this.state.travelPpkUuid === '' ? '' : '?detail&uuid=' + this.state.travelPpkUuid)} />
                        : null
                }
            </Fragment >
        )
    }
}

export default CreateView