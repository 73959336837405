import {
    apiRequest,
    uangMakanRapelApproveSubmit,
    uangMakanRapelRejectSubmit,
    uangMakanRapelResultEmployee,
    uangMakanRapelCheckStatus,
    uangMakanRapelResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", uangMakanRapelApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", uangMakanRapelRejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", uangMakanRapelResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", uangMakanRapelResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", uangMakanRapelCheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository