import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect"
import Select from "react-select"

import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, Modal } from "reactstrap"

import SalaryIndexListComponent from "./Components/SalaryIndexListComponent"

import IndexViewBloc from "./Blocs/IndexViewBloc"
import DownloadBloc from "./Blocs/DownloadBloc"
import apiResponse from "../../../../../services/apiResponse"

import { ConfirmAlert } from "../../../../../helpers/ui/alert"

class IndexView extends Component {
    indexViewBloc = new IndexViewBloc()
    downloadBloc = new DownloadBloc()
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear() - 1 : this.thisDate.getFullYear()
    thisMonths = this.thisDate.getMonth() === 0 ? 12 : this.thisDate.getMonth() + 1
    thisMonth = "00".substring(0, "00".length - this.thisMonths.toString().length) + this.thisMonths.toString()

    monthName = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ]

    constructor(props) {
        super(props)
        this.state = {
            showTunkinHistory: false,
            loadingCurrent: true,
            selectedYear: { label: this.thisYear.toString(), value: this.thisYear },
            selectedMonth: { value: this.thisMonth, label: this.monthName[parseInt(this.thisMonth * 1) - 1] },
            optionMonth: [
                { value: "01", label: this.monthName[0] },
                { value: "02", label: this.monthName[1] },
                { value: "03", label: this.monthName[2] },
                { value: "04", label: this.monthName[3] },
                { value: "05", label: this.monthName[4] },
                { value: "06", label: this.monthName[5] },
                { value: "07", label: this.monthName[6] },
                { value: "08", label: this.monthName[7] },
                { value: "09", label: this.monthName[8] },
                { value: "10", label: this.monthName[9] },
                { value: "11", label: this.monthName[10] },
                { value: "12", label: this.monthName[11] },
            ],
            selectedType: { label: "Gaji Induk", value: "1" },
            optionType: [
                { value: "1", label: "Gaji Induk" },
                { value: "2", label: "Gaji Susulan" },
                { value: "4", label: "Uang Duka Wafat" },
                { value: "6", label: "Uang Duka Tewas" },
                { value: "3", label: "Uang Muka Gaji" },
                { value: "5", label: "Gaji Terusan" },
                { value: "7", label: "Gaji ke-13" },
                { value: "8", label: "Kekurangan Gaji" },
                { value: "9", label: "Tunjangan Hari Raya" },
            ],
        }
    }

    loadCurrent = async () => {
        await this.indexViewBloc.fetchYears()
    }

    componentDidMount() {
        this.indexViewBloc.yearsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        currentRegulation: response,
                        loadingCurrent: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })

        this.downloadBloc.gajiChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })

        this.loadCurrent()
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState({
            selectedYear: itemYear.length > 0 ? itemYear[0] : { label: this.thisYear.toString(), value: this.thisYear },
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear,
                },
            ],
        })
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState(
            {
                selectedYear: selectedYear,
                loadingCurrent: true,
            },
            () => {
                this.setState({
                    loadingCurrent: false,
                })
            }
        )
    }

    handleOnChangeMonth = (selectedMonth) => {
        this.setState(
            {
                selectedMonth: selectedMonth,
                loadingCurrent: true,
            },
            () => {
                this.setState({
                    loadingCurrent: false,
                })
            }
        )
    }

    handleOnChangeType = (selectedType) => {
        this.setState(
            {
                selectedType: selectedType,
                loadingCurrent: true,
            },
            () => {
                this.setState({
                    loadingCurrent: false,
                })
            }
        )
    }

    componentWillUnmount() {
        this.indexViewBloc.yearsChannel.unsubscribe()
        this.downloadBloc.gajiChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="float-end"></div>
                                    <CardTitle>Data Gaji Pokok Pegawai</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Riwayat Data Gaji Pokok Pegawai hasil penarikan data dari Gaji Web.
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <div className="d-flex flex-row bd-highlight float-start">
                                                <div className="pt-2 pe-2 pb-2 bd-highlight">
                                                    <div style={{ paddingTop: 8 }}>Tahun</div>
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    {this.state.loadingCurrent ? (
                                                        <>
                                                            <Shimmer>
                                                                <div
                                                                    className="shimmer"
                                                                    style={{ width: 116, height: 36 }}
                                                                ></div>
                                                            </Shimmer>{" "}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div style={{ width: 116 }}>
                                                                <Select
                                                                    value={this.state.selectedYear}
                                                                    onChange={(selected) => {
                                                                        this.handleOnChangeYear(selected)
                                                                    }}
                                                                    options={this.state.optionYear}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    {this.state.loadingCurrent ? (
                                                        <>
                                                            <Shimmer>
                                                                <div
                                                                    className="shimmer"
                                                                    style={{ width: 160, height: 36 }}
                                                                ></div>
                                                            </Shimmer>{" "}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div style={{ width: 160 }}>
                                                                <Select
                                                                    value={this.state.selectedMonth}
                                                                    onChange={(selected) => {
                                                                        this.handleOnChangeMonth(selected)
                                                                    }}
                                                                    options={this.state.optionMonth}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    {this.state.loadingCurrent ? (
                                                        <>
                                                            <Shimmer>
                                                                <div
                                                                    className="shimmer"
                                                                    style={{ width: 180, height: 36 }}
                                                                ></div>
                                                            </Shimmer>{" "}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div style={{ width: 180 }}>
                                                                <Select
                                                                    value={this.state.selectedType}
                                                                    onChange={(selected) => {
                                                                        this.handleOnChangeType(selected)
                                                                    }}
                                                                    options={this.state.optionType}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    {this.state.loadingCurrent ? (
                                                        <>
                                                            <Shimmer>
                                                                <div
                                                                    className="shimmer"
                                                                    style={{ width: 110, height: 36 }}
                                                                ></div>
                                                            </Shimmer>{" "}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                color="success"
                                                                onClick={() => {
                                                                    this.downloadBloc.fetchGaji({
                                                                        month: this.state.selectedMonth.value,
                                                                        year: this.state.selectedYear.value,
                                                                        type: this.state.selectedType.value,
                                                                    })
                                                                }}
                                                                style={{ paddingBottom: 6, paddingTop: 7 }}
                                                            >
                                                                <i className="uil-arrow-to-bottom"></i> Download
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={12}>
                                            {this.state.loadingCurrent ? (
                                                <>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "10%", height: 36 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "35%", height: 36 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "35%", height: 36 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "20%", height: 36 }}
                                                        ></div>
                                                    </Shimmer>

                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "10%", height: 22 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "35%", height: 22 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "35%", height: 22 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "20%", height: 22 }}
                                                        ></div>
                                                    </Shimmer>
                                                </>
                                            ) : (
                                                <>
                                                    <SalaryIndexListComponent
                                                        year={this.state.selectedYear.value}
                                                        month={this.state.selectedMonth.value}
                                                        type={this.state.selectedType.value}
                                                    />
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"
                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
            </Fragment>
        )
    }
}

export default IndexView
