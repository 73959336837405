import {
    apiRequest,
    tunsusRegulerVerifyList,
    tunsusRegulerCompareList,
    tunsusRegulerResetRequest,
} from "../../../../../../services/adapters/process"

class VerifyRepository {
    fetchVerify = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerVerifyList,
            {
                params: query,
            },
            true
        )
    }

    fetchCompare = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerCompareList,
            {
                params: query,
            },
            true
        )
    }

    fetchResetRequest = async (query) => {
        return await apiRequest(
            "post",
            tunsusRegulerResetRequest,
            {
                body: query,
            },
            true
        )
    }
}

export default VerifyRepository
