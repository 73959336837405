import {
    apiRequest,
    tunkinRapelEmployeeMonths,
} from "../../../../../../services/adapters/process"

class EmployeeViewRepository {

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", tunkinRapelEmployeeMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default EmployeeViewRepository