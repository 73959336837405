import {
    apiRequest,
    tplnSusulanDetail,
    tplnSusulanSsp
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", tplnSusulanDetail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tplnSusulanSsp, {
            params: query
        }, true)
    }
}

export default DownloadRepository