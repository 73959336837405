import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"

import moment from 'moment'

import { 
    Tooltip, 
    Modal,
    Row, Col,
    Label,
    Button 
} from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'

import { AvField, AvForm } from "availity-reactstrap-validation"
import CustomSelect from '../../../../../../helpers/ui/CustomSelect'

import { apiRequest, cancelToken, notificationReadDokumen } from "../../../../../../services/adapters/base"

import IndexViewBloc from '../Blocs/IndexViewBloc'
import apiResponse from '../../../../../../services/apiResponse'

import DocumentDetailComponent from './DocumentDetailComponent'

class DocumentIndexListComponentKeluar extends Component {
    indexViewBloc = new IndexViewBloc();
    thisDate = new Date()
    thisMonths = this.thisDate.getMonth() === 0 ? 12 : this.thisDate.getMonth() + 1
    thisMonth = "00".substring(0, "00".length - (this.thisMonths).toString().length) + (this.thisMonths).toString()
    source
    historyTable = 'historyIntegrationDocumentIndexListComponentKeluar'
    defaultOrder = 'financial_recap_id'
    defaultSize = 20
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    monthName = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ]


    constructor(props) {
        super(props)
        const thisYear = new Date().getFullYear();
        const tahunOptions = Array.from({ length: 7 }, (_, index) => {
            const year = thisYear - 5 + index;
            return {
                value: year.toString(),
                label: year.toString()
            };
        });
        this.state = {
            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: false,
            filter: sessionStorage.getItem(this.historyTable) ?
            JSON.parse(sessionStorage.getItem(this.historyTable)) :
            {
                filter_value: "",
                page_number: 1,
                page_size: this.defaultSize,
                sort_order: {
                    column: this.defaultOrder,
                    order: this.defaultSort
                }
            },
            optionMonth: [
                { value: '01', label: this.monthName[0] },
                { value: '02', label: this.monthName[1] },
                { value: '03', label: this.monthName[2] },
                { value: '04', label: this.monthName[3] },
                { value: '05', label: this.monthName[4] },
                { value: '06', label: this.monthName[5] },
                { value: '07', label: this.monthName[6] },
                { value: '08', label: this.monthName[7] },
                { value: '09', label: this.monthName[8] },
                { value: '10', label: this.monthName[9] },
                { value: '11', label: this.monthName[10] },
                { value: '12', label: this.monthName[11] },
            ],
            rentangOptions: [
                {
                    "value":"7",
                    "label":"7 Hari Terakhir"
                },
                {
                    "value":"30",
                    "label":"30 Hari Terakhir"
                },
                {
                    "value":"0",
                    "label":"Semua"
                }
            ],
            statusDokumen: [
                {
                    "value":"0",
                    "label":"Belum Diproses"
                },
                {
                    "value":"1",
                    "label":"Sudah Diproses"
                }
            ],
            rentangSelected: "7",
            statusDokumenSelected: "0",
            tahunOptions: tahunOptions,
            tahunSelected: thisYear.toString(),
            bulanSelected: this.thisMonth.toString()
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            year: this.state.tahunSelected,
            rentang: this.state.rentangSelected,
            month: this.state.bulanSelected,
            dokumen: this.state.statusDokumenSelected
        }
        await this.indexViewBloc.fetchMonthsKeluar(query, this.source.token)
    }

    onChangeYear = (selectedYear) => {
        const isCurrentYear = selectedYear.value === new Date().getFullYear().toString();
    
        const rentangOptions = isCurrentYear
            ? [
                { value: "7", label: "7 Hari Terakhir" },
                { value: "30", label: "30 Hari Terakhir" },
                { value: "0", label: "Semua" }
              ]
            : [
                { value: "0", label: "Semua" }
              ];
    
        this.setState({
            selectedYear,
            rentangOptions,
            rentangSelected: "0",
        });
    }

    handlerOnSubmit = (e, values) => {
        this.setTokenAPI()
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {}

    componentDidMount() {
        // this.setTokenAPI()

        this.updateColumns();

        this.indexViewBloc.monthsKeluarChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.monthsKeluarChannel.unsubscribe()
    }

    handleButtonClick = (record) => {  // accept the record here
        const financialUuid = record.financial_recap_uuid; 
        this.markNotificationAsRead(financialUuid);

        this.setState({
            showDetail: true,
            employeeUuid: financialUuid   // use the financialUuid directly
        });
    }
    
    
    markNotificationAsRead = (financialUuid) => {
        const requestData = {
            body: { financial_recap_uuid: financialUuid }
        };
    
        apiRequest("post", notificationReadDokumen, requestData)
        .then(result => {
            if(result.status) {
               
            } else {
                console.error('Failed to mark notification as read:', result.message);
            }
        })
        .catch(error => {
            console.error('Error marking notification as read:', error);
        });
    }

    refreshDatatable = () => {
        this.setTokenAPI();
    }
    
    // columns = [
    //     {
    //         key: "employee_transfer_detail_decree_number",
    //         text: "Nomor Dokumen",
    //         className: "",
    //         sortable: true,
    //         width: '250',
    //         cell: record => {
    //             const decreeNumber = record.employee_transfer_detail_decree_number;

    //             const displayValue = decreeNumber !== null && decreeNumber !== null ? decreeNumber : "-";
    
    //             return (
    //                 <span className={record.process_document_notification_read === null ? "fw-bold" : ""}>
    //                     {displayValue}
    //                 </span>
    //             );
    //         }
    //     },
    //     {
    //         key: "financial_recap_type_name",
    //         text: "Uraian",
    //         className: "",
    //         align: 'left',
    //         sortable: true,
    //         width: '250',
    //         cell: record => {
    //             return <span className={record.process_document_notification_read === null ? "fw-bold" : " " }>{record.financial_recap_type_name}</span>
    //         }
    //     },
    //     {
    //         key: "employee_fullname",
    //         text: "Nama Pegawai",
    //         className: "",
    //         align: 'left',
    //         sortable: true,
    //         width: '250',
    //         cell: record => {
    //             return <span className={record.process_document_notification_read === null ? "fw-bold" : " " }>{record.employee_fullname}</span>
    //         }
    //     },
    //     {
    //         key: "process_document_month",
    //         text: "Bulan Induk",
    //         className: "",
    //         align: 'center',
    //         sortable: true,
    //         width: '75',
    //         cell: record => {
    //             const monthIndex = parseInt(record.process_document_month) - 1;
    //             const monthName = this.monthName[monthIndex];
    //             return <span className={record.process_document_notification_read === null ? "fw-bold" : ""}>{monthName}</span>
    //         }
    //     },
    //     {
    //         key: "process_document_year",
    //         text: "Tahun Induk",
    //         className: "",
    //         align: 'center',
    //         sortable: true,
    //         width: '75',
    //         cell: record => {
    //             return <span className={record.process_document_notification_read === null ? "fw-bold" : " " }>{record.process_document_year}</span>
    //         }
    //     },
    //     {
    //         key: "is_process",
    //         text: "Proses",
    //         className: "",
    //         sortable: true,
    //         width: '100',
    //         align: 'center',
    //         cell: record => {
    //             var className = (record.process_document_is_process === 1 ? 'bg-success' : 'bg-danger') + " rounded-circle d-inline-block"
    //             return (
    //                 <>
    //                     <div className={className} style={{ height: 10, width: 10 }}></div>
    //                 </>
    //             )
    //         }
    //     },
    //     {
    //         key: "financial_recap_uuid",
    //         text: "Aksi",
    //         className: "",
    //         width: '50',
    //         align: 'center',
    //         cell: record => {
    //             const thisId = record.financial_recap_id
    //             return (
    //                 <>
    //                     <span>
    //                         <button
    //                             className="btn btn-success btn-sm"
    //                             id={"detail-" + thisId}
    //                             onClick={() => this.handleButtonClick(record)}  // pass the record here
    //                             style={{
    //                                 marginRight: '2px',
    //                                 padding: '2px 5px'
    //                             }}>
    //                             <i className="uil-align-left"></i>
    //                         </button>


    //                         <Tooltip
    //                             style={{
    //                                 marginBottom: 2
    //                             }}
    //                             placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
    //                             toggle={() => {
    //                                 this.setState({
    //                                     ["detail" + thisId] : !this.state["detail" + thisId]
    //                                 })
    //                             }}
    //                         >Detail</Tooltip>
    //                     </span>
    //                 </>
    //             )
    //         }
    //     }
    // ]

    updateColumns() {
        const { statusDokumenSelected } = this.state;
    
        const bulanText = statusDokumenSelected === "0" ? "Bulan" : "Bulan Induk";
        const tahunText = statusDokumenSelected === "0" ? "Tahun" : "Tahun Induk";
    
        this.columns = [
            {
                key: "employee_transfer_detail_decree_number",
                text: "Nomor Dokumen",
                className: "",
                sortable: true,
                width: '250',
                cell: record => {
                    const decreeNumber = record.employee_transfer_detail_decree_number;
    
                    const displayValue = decreeNumber !== null && decreeNumber !== null ? decreeNumber : "-";
    
                    return (
                        <span className={record.process_document_notification_read === null ? "fw-bold" : ""}>
                            {displayValue}
                        </span>
                    );
                }
            },
            {
                key: "financial_recap_type_name",
                text: "Uraian",
                className: "",
                align: 'left',
                sortable: true,
                width: '250',
                cell: record => {
                    return <span className={record.process_document_notification_read === null ? "fw-bold" : " " }>{record.financial_recap_type_name}</span>
                }
            },
            {
                key: "employee_fullname",
                text: "Nama Pegawai",
                className: "",
                align: 'left',
                sortable: true,
                width: '250',
                cell: record => {
                    return <span className={record.process_document_notification_read === null ? "fw-bold" : " " }>{record.employee_fullname}</span>
                }
            },
            {
                key: "process_document_month",
                text: bulanText,
                className: "",
                align: 'center',
                sortable: true,
                width: '75',
                cell: record => {
                    const monthIndex = parseInt(statusDokumenSelected === "0" ? record.financial_recap_month : record.process_document_month) - 1;
                    const monthName = this.monthName[monthIndex];
                    return <span className={record.process_document_notification_read === null ? "fw-bold" : ""}>{monthName}</span>
                }
            },
            {
                key: "process_document_year",
                text: tahunText,
                className: "",
                align: 'center',
                sortable: true,
                width: '75',
                cell: record => {
                    const year = statusDokumenSelected === "0" ? record.financial_recap_year : record.process_document_year;
                    return <span className={record.process_document_notification_read === null ? "fw-bold" : ""}>{year}</span>
                }
            },
            {
                key: "is_process",
                text: "Proses",
                className: "",
                sortable: true,
                width: '100',
                align: 'center',
                cell: record => {
                    var className = (record.process_document_is_process === 1 ? 'bg-success' : 'bg-danger') + " rounded-circle d-inline-block"
                    return (
                        <>
                            <div className={className} style={{ height: 10, width: 10 }}></div>
                        </>
                    )
                }
            },
            {
                key: "financial_recap_uuid",
                text: "Aksi",
                className: "",
                width: '50',
                align: 'center',
                cell: record => {
                    const thisId = record.financial_recap_id
                    return (
                        <>
                            <span>
                                <button
                                    className="btn btn-success btn-sm"
                                    id={"detail-" + thisId}
                                    onClick={() => this.handleButtonClick(record)}  // pass the record here
                                    style={{
                                        marginRight: '2px',
                                        padding: '2px 5px'
                                    }}>
                                    <i className="uil-align-left"></i>
                                </button>
    
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["detail" + thisId] : !this.state["detail" + thisId]
                                        })
                                    }}
                                >Detail</Tooltip>
                            </span>
                        </>
                    )
                }
            }
        ];
    }

    finishSubmit = () => {
        this.setState({showDetail: false})
        this.loadData();
    }

    render() {
        return (
            <>
                <AvForm
                    className="needs-validation p-2 mt-2 mb-5"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                >
                    <Row className="mb-3">
                        <Col md="2">
                            <CustomSelect
                                name="status_dokumen"
                                errorMessage="Pilih Status Dokumen"
                                options={this.state.statusDokumen}
                                defaultValue={this.state.statusDokumenSelected}
                                placeholder="Pilih Status Dokumen"
                                validate={{ required: { value: true } }}
                                onChange={(v) => {
                                    this.setState({
                                        statusDokumenSelected: v.value,
                                    })
                                }}
                            />
                        </Col>

                        <Col md="2">
                            <CustomSelect
                                name="tahun"
                                errorMessage="Pilih Tahun"
                                options={this.state.tahunOptions}
                                defaultValue={this.state.tahunSelected}
                                placeholder="Pilih Tahun"
                                validate={{ required: { value: true } }}
                                onChange={(v) => {
                                    this.setState({
                                        tahunSelected: v.value,
                                    })
                                }}
                            />
                        </Col>

                        <Col md="2">
                            <CustomSelect
                                name="bulan"
                                errorMessage="Pilih Bulan"
                                options={this.state.optionMonth}
                                defaultValue={this.state.bulanSelected}
                                placeholder="Pilih Bulan"
                                validate={{ required: { value: true } }}
                                onChange={(v) => {
                                    this.setState({
                                        bulanSelected: v.value,
                                    })
                                }}
                            />
                        </Col>

                        <Col md="2">
                            <CustomSelect
                                name="usia_data"
                                errorMessage="Pilih Usia Data"
                                options={this.state.rentangOptions}
                                defaultValue={this.state.rentangSelected}
                                placeholder="Pilih Usia Data"
                                validate={{ required: { value: true } }}
                                onChange={(v) => {
                                    this.setState({
                                        rentangSelected: v.value,
                                    })
                                }}
                            />
                        </Col>

                        <Col md="3" className="d-flex align-items-end">
                            <Button color="primary" type="submit" onClick={this.handleShowClick}>
                                <i className="uil-search-alt"></i> Tampilkan
                            </Button>
                        </Col>
                    </Row>
                </AvForm>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1020}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />

                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail
                        }, () => {
                            if (!this.state.showDetail) { // If the modal is closed
                                this.refreshDatatable();
                            }
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">Informasi Dokumen</h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false
                                }, () => {
                                    this.refreshDatatable();
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <DocumentDetailComponent finishSubmit={this.finishSubmit} uuid={this.state.employeeUuid} />
                    </div>
                </Modal>


            </>
        )
    }

}

export default DocumentIndexListComponentKeluar