import {
    apiRequest,
    tunjabList,
    tunjabEmployeeList
} from "../../../../../services/adapters/process"

class IndexViewRepository {

    fetchRowTableTunjab = async (query, cancelToken) => {
        return await apiRequest("get", tunjabList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableEmployee = async (query, cancelToken) => {
        return await apiRequest("get", tunjabEmployeeList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default IndexViewRepository