import {
    apiRequest,
    masterReferenceNrsList,
    masterReferenceNrsActivated
} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchInactive = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsActivated, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchActive = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsActivated, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository