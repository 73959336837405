import {
    apiRequest,
    officialTravelRegisterSearch,
    officialTravelRegisterSearchParticipant,
    officialTravelRegisterCreate
} from "../../../../../services/adapters/process"

class CreateRepository {

    fetchSearch = async (query) => {
        return await apiRequest("get", officialTravelRegisterSearch, {
            params: query
        }, true)
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelRegisterSearchParticipant, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", officialTravelRegisterCreate, {
            body: query
        }, true)
    }
}

export default CreateRepository