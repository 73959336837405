import { apiRequest, tplnRapelDetailBefore, tplnRapelDetailAfter, tplnRapelDetailRapel, tplnRapelDetailRefund, tplnRapelSsp } from "../../../../../../services/adapters/output"

class DownloadRepository {
    fetchDownloadDetailBefore = async (query) => {
        return await apiRequest(
            "get",
            tplnRapelDetailBefore,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadDetailAfter = async (query) => {
        return await apiRequest(
            "get",
            tplnRapelDetailAfter,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadDetailRapel = async (query) => {
        return await apiRequest(
            "get",
            tplnRapelDetailRapel,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadDetailRefund = async (query) => {
        return await apiRequest(
            "get",
            tplnRapelDetailRefund,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest(
            "get",
            tplnRapelSsp,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
