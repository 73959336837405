import { apiRequest, officialTravelRapelSubmissionCreateInitial, officialTravelRapelSubmissionUnprocessList, officialTravelRapleUnprocessParticipantList } from "../../../../../../services/adapters/process"

class CreateRepository {
    fetchCreateInitial = async (query) => {
        return await apiRequest(
            "post",
            officialTravelRapelSubmissionCreateInitial,
            {
                body: query,
            },
            true
        )
    }

    fetchUnprocessList = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelSubmissionUnprocessList,
            {
                params: query,
            },
            true
        )
    }

    fetchUnprocessParticipantList = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapleUnprocessParticipantList,
            {
                params: query,
            },
            true
        )
    }
}

export default CreateRepository
