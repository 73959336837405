import {
    apiRequest,
    tunsusRapelEmployeeYears,
    tunsusRapelEmployeeMonths,
} from "../../../../../../services/adapters/process"

class EmployeeViewRepository {
    fetchYears = async () => {
        return await apiRequest("get", tunsusRapelEmployeeYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunsusRapelEmployeeMonths,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }
}

export default EmployeeViewRepository
