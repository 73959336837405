/* import {
    apiRequest,
    tunkinT14Detail,
    tunkinT14Ssp
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", tunkinT14Detail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tunkinT14Ssp, {
            params: query
        }, true)
    }
}

export default DownloadRepository */

import {
    apiRequest,
    tunkinT14Detail,
    tunkinT14Ssp,
    tunkinT14Bank
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", tunkinT14Detail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tunkinT14Ssp, {
            params: query
        }, true)
    }

    fetchDownloadBank = async (query) => {
        return await apiRequest("get", tunkinT14Bank, {
            params: query
        }, true)
    }
}

export default DownloadRepository