import {
    apiRequest,
    masterReferenceNrsCreate
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterReferenceNrsCreate, {
            body: query
        }, true)
    }
}

export default CreateRepository