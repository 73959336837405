import {
    apiRequest,
    officialTravelVerificationDetail,
    officialTravelVerificationParticipant
} from "../../../../../../services/adapters/process"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", officialTravelVerificationDetail, {
            params: query
        }, true)
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelVerificationParticipant, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DetailRepository
