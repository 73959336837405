import {
    apiRequest,
    tunkinRapelProcessSubmit,
    tunkinRapelProcessEmployees,
    tunkinRapelProcessEmployeeDetail,
    tunkinRapelCheckStatus,
    tunkinRapelGetSigner,
    tunkinRapelCheckPresence,
    tunkinRapelCheckLevel,
    tunkinRapelCheckGpp,
    tunkinRapelCheckPtkp,
    tunkinRapelCheckPkp,
    tunkinRapelCheckEmployeeNew,
    tunkinRapelCheckEmployeeChange
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", tunkinRapelProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", tunkinRapelProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tunkinRapelProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", tunkinRapelGetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tunkinRapelCheckStatus, {
            params: query
        }, true)
    }
    
    fetchCheckPresence = async (query) => {
        return await apiRequest("get", tunkinRapelCheckPresence, {
            params: query
        }, true)
    }
    
    fetchCheckLevel = async (query) => {
        return await apiRequest("get", tunkinRapelCheckLevel, {
            params: query
        }, true)
    }

    fetchCheckGpp = async (query) => {
        return await apiRequest("get", tunkinRapelCheckGpp, {
            params: query
        }, true)
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest("get", tunkinRapelCheckPtkp, {
            params: query
        }, true)
    }
    
    fetchCheckPkp = async (query) => {
        return await apiRequest("get", tunkinRapelCheckPkp, {
            params: query
        }, true)
    }

    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", tunkinRapelCheckEmployeeNew, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", tunkinRapelCheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository