import {
    apiRequest,
    masterPtspHonorRegulationList,
    masterPtspHonorRegulationDetail
} from "../../../../../../services/adapters/master"

class RegulationRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterPtspHonorRegulationList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    
    fetchDetail = async (query) => {
        return await apiRequest("get", masterPtspHonorRegulationDetail, {
            params: query
        }, true)
    }
}

export default RegulationRepository