import {
    apiRequest,
    officialTravelMonitoringStList
} from "../../../../../../services/adapters/process"

class IndexViewRepository {

    fetchRowList = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelMonitoringStList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default IndexViewRepository