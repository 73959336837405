import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../../services/apiResponse'

import UpdateBloc from './Blocs/UpdateBloc'


class IndexView extends Component {

    updateBloc = new UpdateBloc();

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            messageError: "",
            statusMappingIipc: [
                {
                    "value":"1",
                    "label":"1 Tahun"
                },
                {
                    "value":"2",
                    "label":"2 Tahun"
                },
                {
                    "value":"3",
                    "label":"3 Tahun"
                },
                {
                    "value":"4",
                    "label":"4 Tahun"
                },
                {
                    "value":"5",
                    "label":"5 Tahun"
                },
                {
                    "value":"6",
                    "label":"6 Tahun"
                },
                {
                    "value":"7",
                    "label":"7 Tahun"
                },
                {
                    "value":"8",
                    "label":"8 Tahun"
                },
                {
                    "value":"9",
                    "label":"9 Tahun"
                },
                {
                    "value":"10",
                    "label":"10 Tahun"
                }
            ],
            statusMappingIipcSelected: "1",
            layananSelected: null,
            statusOptions: [
                { value: 1, label: "Aktif" },
                { value: 2, label: "Tidak Aktif" },
            ],
            statusSelected: 1,
        }

        this.handleChange = this.handleChange.bind(this);
    }

    submitData = async () => {
        await this.updateBloc.fetchUpdate(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.mapping_year_iipc_uuid,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState(prevState => ({
            dataForm: {
                ...prevState.dataForm,
                [name]: value
            }
        }));
    }

    componentDidMount() {
        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: result.data.status,
                        messageError: result.data.status === false ? result.data.message : ""
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        console.log('mencari', response
                        );
                        this.setState({
                            dataForm: response,
                            statusMappingIipcSelected: response.mapping_year_iipc_year,
                            statusSelected: response.mapping_year_iipc_status
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)
        this.updateBloc.fetchDetail({ uuid: queryParams.get('uuid') })
    }

    componentWillUnmount() {
        this.updateBloc.updateChannel?.unsubscribe()
        this.updateBloc.detailChannel?.unsubscribe()
    }


    render() {
        const { statusMappingIipc, statusMappingIipcSelected, statusOptions, statusSelected, dataForm } = this.state;
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            
                            { this.state.messageError ? <>
                                <div className="alert alert-danger">
                                    <strong>{ this.state.messageError }</strong>
                                </div>
                            </> : <></> }

                            <Card>
                                <CardBody>
                                    <CardTitle>Edit Data</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >

                                      
                                    <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Tahun<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <CustomSelect
                                                    name="mapping_iipc_year"
                                                    errorMessage="Pilih Kategori"
                                                    options={this.state.statusMappingIipc}
                                                    defaultValue={this.state.statusMappingIipcSelected}
                                                    placeholder="Pilih Kategori"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(v) => {
                                                        this.setState({
                                                            statusMappingIipcSelected: v.value,
                                                        });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                      
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="mapping_iipc_status"
                                                className="col-md-2 col-form-label"
                                            >
                                                Status<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <CustomSelect
                                                    name="mapping_iipc_status"
                                                    errorMessage="Pilih Status"
                                                    options={this.state.statusOptions}
                                                    defaultValue={this.state.statusSelected}
                                                    placeholder="Pilih Status"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(v) => {
                                                        this.setState({
                                                            statusSelected: parseInt(v.value),
                                                        });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                      
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <Link to="/master/mapping-iipc.html">
                                                    <Button color="danger"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                {this.state.redirect ?
                    <Redirect to='/master/mapping-iipc.html' />
                    : null}
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);