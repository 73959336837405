import {
    apiRequest,
    sigapSalaryYears,
    sigapSalaryMonth,
    sigapSalaryDetail,
} from "../../../../../../services/adapters/sync"

class IndexViewRepository {
    fetchYears = async () => {
        return await apiRequest("get", sigapSalaryYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            sigapSalaryMonth,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchDetail = async (query) => {
        return await apiRequest(
            "get",
            sigapSalaryDetail,
            {
                params: query,
            },
            true
        )
    }
}

export default IndexViewRepository
