import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'


class CreateBloc {

    createChannel = new Subject();
    listNrsChannel = new Subject();
    employeeChannel = new Subject();
    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({status: apiResponse.INITIAL})
        this.listNrsChannel.next({status: apiResponse.INITIAL})
        this.employeeChannel.next({status: apiResponse.INITIAL})
    }
    
    fetchEmployee = async (query) => {
        this.employeeChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchEmployee(query).then((result) => {
                this.employeeChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.employeeChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchNrs = async (query) => {
        this.listNrsChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchNrs(query).then((result) => {
                this.listNrsChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.listNrsChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchCreate = async (query) => {
        this.createChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.createChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    
}

export default CreateBloc