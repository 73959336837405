import {
    apiRequest,
    ptspRegulerProcessSubmit,
    ptspRegulerProcessEmployees,
    ptspRegulerProcessEmployeeDetail,
    ptspRegulerCheckStatus,
    ptspRegulerGetSigner,
    ptspRegulerCheckPresence,
    ptspRegulerCheckTunkin,
    ptspRegulerCheckTaxFinal,
    ptspRegulerCheckEmployeeNew,
    ptspRegulerCheckEmployeeChange,
} from "../../../../../../services/adapters/process"

class CreateRepository {
    fetchCreate = async (query) => {
        return await apiRequest(
            "post",
            ptspRegulerProcessSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            ptspRegulerProcessEmployees,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerProcessEmployeeDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchGetSigner = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerGetSigner,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerCheckStatus,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckPresence = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerCheckPresence,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckTunkin = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerCheckTunkin,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckTaxFinal = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerCheckTaxFinal,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerCheckEmployeeNew,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerCheckEmployeeChange,
            {
                params: query,
            },
            true
        )
    }
}

export default CreateRepository
