import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import CreateViewRepository from '../Repositories/CreateViewRepository'


class CreateViewBloc {

    uploadChannel = new Subject();
    repository = new CreateViewRepository()

    constructor() {
        this.uploadChannel.next({status: apiResponse.INITIAL})
    }

    fetchUpload = async (query) => {
        this.uploadChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchUpload(query).then((result) => {
                this.uploadChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.uploadChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    
}

export default CreateViewBloc