import {
    apiRequest,
    masterReferenceMappingNipList,
    masterReferenceMappingNipDelete,
    masterReferenceMappingNipUpload,
} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceMappingNipList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDelete = async (query) => {
        return await apiRequest("post", masterReferenceMappingNipDelete, {
            body: query
        }, true)
    }

    fetchUpload = async (query) => {
        return await apiRequest("post", masterReferenceMappingNipUpload, {
            body: query
        }, true)
    }
}

export default DataTableRepository