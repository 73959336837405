import {
    apiRequest,
    uangMakanRegularApproveSubmit,
    uangMakanRegularRejectSubmit,
    uangMakanRegularResultEmployee,
    uangMakanRegularCheckStatus,
    uangMakanRegularResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", uangMakanRegularApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", uangMakanRegularRejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", uangMakanRegularResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", uangMakanRegularResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", uangMakanRegularCheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository