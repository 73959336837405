import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import DataTableRepository from '../Repositories/DataTableRepository'


class DataTableBloc {

    rowtableChannelA = new Subject();
    rowtableChannelB = new Subject();
    rowtableChannelC = new Subject();
    createChannel = new Subject();
    updateChannel = new Subject();
    repository = new DataTableRepository()
    detailChannel = new Subject();

    constructor() {
        this.rowtableChannelA.next({status: apiResponse.INITIAL})
        this.rowtableChannelB.next({status: apiResponse.INITIAL})
        this.rowtableChannelC.next({status: apiResponse.INITIAL})
        this.createChannel.next({status: apiResponse.INITIAL})
        this.updateChannel.next({status: apiResponse.INITIAL})
        this.detailChannel.next({status: apiResponse.INITIAL})
    }

    fetchRowTableA = async (query, cancelToken) => {
        this.rowtableChannelA.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTableA(query, cancelToken).then((result) => {
                this.rowtableChannelA.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannelA.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchRowTableB = async (query, cancelToken) => {
        this.rowtableChannelB.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTableB(query, cancelToken).then((result) => {
                this.rowtableChannelB.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannelB.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchRowTableC = async (query, cancelToken) => {
        this.rowtableChannelC.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTableC(query, cancelToken).then((result) => {
                this.rowtableChannelC.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannelC.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchCreate = async (query) => {
        this.createChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.createChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchUpdate = async (query) => {
        this.updateChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchUpdate(query).then((result) => {
                this.updateChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.updateChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    
    fetchDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.detailChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
}

export default DataTableBloc