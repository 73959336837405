import {
    apiRequest,
    officialTravelMonitoringEmployeeEmployee,
    officialTravelMonitoringEmployeeUnit,
    officialTravelMonitoringEmployeeList,
    officialTravelMonitoringEmployeeDetail
} from "../../../../../../services/adapters/process"

class IndexViewRepository {

    fetchEmployeeList = async (query) => {
        return await apiRequest("get", officialTravelMonitoringEmployeeEmployee, {
            params: query
        }, true)
    }

    fetchUnitList = async () => {
        return await apiRequest("get", officialTravelMonitoringEmployeeUnit, {}, true)
    }

    fetchMonitoringList = async (query) => {
        return await apiRequest("get", officialTravelMonitoringEmployeeList, {
            params: query
        }, true)
    }

    fetchDetail = async (query) => {
        return await apiRequest("get", officialTravelMonitoringEmployeeDetail, {
            params: query
        }, true)
    }
}

export default IndexViewRepository