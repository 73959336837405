import React, { Component } from "react"

import { Row, Col } from "reactstrap"

import { ConfirmAlert } from "../../../../../../helpers/ui/alert"
import apiResponse from "../../../../../../services/apiResponse"
import DownloadBloc from "../Blocs/DownloadBloc"

class TunsusDownloadComponent extends Component {
    downloadBloc = new DownloadBloc()

    constructor(props) {
        super(props)
        this.state = {
            uuid: this.props.uuid,
        }
    }

    componentDidMount() {
        this.downloadBloc.detailBeforeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    //window.location.href = process.env.REACT_APP_SERVICE_OUTPUT_URL + link
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })

        this.downloadBloc.detailAfterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    //window.location.href = process.env.REACT_APP_SERVICE_OUTPUT_URL + link
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })

        this.downloadBloc.detailRapelChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    //window.location.href = process.env.REACT_APP_SERVICE_OUTPUT_URL + link
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })

        this.downloadBloc.sspChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    //window.location.href = process.env.REACT_APP_SERVICE_OUTPUT_URL + link
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(function () {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.downloadBloc.detailBeforeChannel.unsubscribe()
        this.downloadBloc.detailAfterChannel.unsubscribe()
        this.downloadBloc.detailRapelChannel.unsubscribe()
        this.downloadBloc.sspChannel.unsubscribe()
    }

    render() {
        return (
            <>
                <Row>
                    <Col xs="12" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadDetailBefore({ uuid: this.state.uuid })
                            }}
                        >
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Detail Isi Sebelum
                        </button>
                    </Col>
                    <Col xs="12" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadDetailAfter({ uuid: this.state.uuid })
                            }}
                        >
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Detail Isi Sesudah
                        </button>
                    </Col>
                    <Col xs="12" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadDetailRapel({ uuid: this.state.uuid })
                            }}
                        >
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Detail Isi Rapel
                        </button>
                    </Col>
                    {/* <Col xs="12" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadDetailRapel({ uuid: this.state.uuid, csv: true })
                            }}
                        >
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Detail Isi Rapel (CSV)
                        </button>
                    </Col> */}
                    <Col xs="12" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadSsp({ uuid: this.state.uuid })
                            }}
                        >
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Surat Setoran Pajak
                        </button>
                    </Col>
                </Row>

                <ConfirmAlert
                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"
                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
            </>
        )
    }
}

export default TunsusDownloadComponent
