import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import IndexRepository from '../Repositories/IndexRepository'

class IndexBloc 
{
    repository = new IndexRepository()
    dashboardReportChannel = new Subject();
    dashboardIconChannel = new Subject();
    dashboardGppChannel = new Subject();
    dashboardSigapChanel = new Subject();
    dashboardDaftarPekerjaanChannel = new Subject();
    dashboardPengajuanChannel = new Subject();
    dashboardFilterChannel = new Subject();

    getLayananChannel = new Subject();
    getSubLayananChannel = new Subject();
    getInformasiChannel = new Subject();
    getNotifPersetujuanChannel = new Subject();
    getPerhitunganChannel = new Subject();

    constructor() 
    {
        this.dashboardReportChannel.next({status: apiResponse.INITIAL})
        this.dashboardIconChannel.next({status: apiResponse.INITIAL})
        this.dashboardGppChannel.next({status: apiResponse.INITIAL})
        this.dashboardSigapChanel.next({status: apiResponse.INITIAL})
        this.dashboardDaftarPekerjaanChannel.next({status: apiResponse.INITIAL})
        this.dashboardPengajuanChannel.next({status: apiResponse.INITIAL})
        this.dashboardFilterChannel.next({status: apiResponse.INITIAL})
        this.getLayananChannel.next({status: apiResponse.INITIAL})
        this.getSubLayananChannel.next({status: apiResponse.INITIAL})
        this.getInformasiChannel.next({status: apiResponse.INITIAL})
        this.getNotifPersetujuanChannel.next({status: apiResponse.INITIAL})
        this.getPerhitunganChannel.next({status: apiResponse.INITIAL})
    }

    fetchFilter = async (query, cancelToken) => {
        this.dashboardFilterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchFilter(query, cancelToken).then((result) => {
                this.dashboardFilterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardFilterChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboardReport = async (query, cancelToken) => {
        this.dashboardReportChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardReport(query, cancelToken).then((result) => {
                this.dashboardReportChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardReportChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboardIcon = async (query, cancelToken) => {
        this.dashboardIconChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardIcon(query, cancelToken).then((result) => {
                this.dashboardIconChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardIconChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboardGpp = async (query, cancelToken) => {
        this.dashboardGppChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardGpp(query, cancelToken).then((result) => {
                this.dashboardGppChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardGppChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboardSigap = async (query, cancelToken) => {
        this.dashboardSigapChanel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardSigap(query, cancelToken).then((result) => {
                this.dashboardSigapChanel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardSigapChanel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboardDaftarPekerjaan = async (query, cancelToken) => {
        this.dashboardDaftarPekerjaanChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboardDaftarPekerjaan(query, cancelToken).then((result) => {
                this.dashboardDaftarPekerjaanChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardDaftarPekerjaanChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchDashboarPengajuan = async (query, cancelToken) => {
        this.dashboardPengajuanChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDashboarPengajuan(query, cancelToken).then((result) => {
                this.dashboardPengajuanChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.dashboardPengajuanChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchGetLayanan = async () => {
        this.getLayananChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetLayanan().then((result) => {
                this.getLayananChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getLayananChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Layanan Unsubscribed') 
        }
    }

    fetchSubGetLayanan = async () => {
        this.getSubLayananChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchSubGetLayanan().then((result) => {
                this.getSubLayananChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getSubLayananChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Layanan Unsubscribed') 
        }
    }

    fetchGetInformasi = async () => {
        this.getInformasiChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetInformasi().then((result) => {
                this.getInformasiChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getInformasiChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Informasi Unsubscribed') 
        }
    }

    fetchGetNotifPersetujuan = async () => {
        this.getNotifPersetujuanChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetNotifPersetujuan().then((result) => {
                this.getNotifPersetujuanChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getNotifPersetujuanChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Informasi Unsubscribed') 
        }
    }

    fetchGetPerhitungan = async () => {
        this.getPerhitunganChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetPerhitungan().then((result) => {
                this.getPerhitunganChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getPerhitunganChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Informasi Unsubscribed') 
        }
    }

}

export default IndexBloc