import {
    apiRequest,
    tplnRapelApproveSubmit,
    tplnRapelRejectSubmit,
    tplnRapelResultEmployee,
    tplnRapelCheckStatus,
    tplnRapelResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", tplnRapelApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", tplnRapelRejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", tplnRapelResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tplnRapelResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tplnRapelCheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository