import React, { Fragment, Component } from "react"
import { accessRole, ppk } from "../../../../../helpers/access_role"
import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, Label, Modal, Input } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Shimmer from "react-shimmer-effect"
import { ConfirmAlert } from "../../../../../helpers/ui/alert"
import { FileInput, generateFormData } from "../../../../../helpers/ui/FileInput"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect } from "react-router-dom"
import VerifyBloc from "./Blocs/VerifyBloc"
import apiResponse from "../../../../../services/apiResponse"
import OfficialTravelUpdateComponent from "./Components/OfficialTravelUpdateComponent"

import OfficialTravelSourceComponent from "./Components/OfficialTravelSourceComponent"

import MinioClient from "../../../../../services/minioClient"

import moment from "moment"

class VerifyView extends Component {
    verifyBloc = new VerifyBloc()
    minioClient = new MinioClient()

    constructor(props) {
        super(props)
        this.state = {
            detailUuid: null,
            showDetail: false,
            showModal: false,
            showReject: false,
            uuid: this.props.uuid,
            loadingInitial: true,
            processDetail: {},
            loadingEmployee: true,
            processEmployees: [],

            action: "0",
            dataForm: {},
            formFileName: [],
            formValue: [],
            formFiles: [],
            forMinio: [],
            formTemp: [],

            currentEdit: null,
            currentName: "",
            copyFrom: null,
        }
    }

    submitData = async () => {
        console.log(this.state.dataForm)
        const formData = generateFormData(this.state.dataForm)
        if (this.state.submitType === "accept") {
            await this.verifyBloc.fetchVerify(formData)
        } else if (this.state.submitType === "reject") {
            await this.verifyBloc.fetchReject(formData)
        }
    }

    handlerOnReject = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
            },
            submitType: "reject",
            confirm: true,
        })
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
            },
            submitType: "accept",
            confirm: true,
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        if (this.props.uuid) {
            this.verifyBloc.fetchDetail({ uuid: this.props.uuid })
        }
        this.verifyBloc.verifyDetailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingInitial: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    this.setState({
                        loadingInitial: false,
                        processDetail: result.data.response,
                        uuid: result.data.response.official_travel_rapel_uuid,
                    })
                    this.getEmployeeData()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loadingInitial: false,
                    })
                    break
                default:
                    break
            }
        })
        this.verifyBloc.verifyParticipantChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingEmployee: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    var _formFileName = []
                    var _formValue = []
                    var _forMinio = []
                    for (var e = 0; e < result.data.response.length; ++e) {
                        var response = result.data.response[e]
                        _forMinio[e] = { field: "document_" + response.official_travel_rapel_emp_uuid, name: "Keuangan/PerjalananDinas/Lampiran" }

                        if (response.official_travel_rapel_emp_file_url !== null) {
                            var maxName = response.official_travel_rapel_emp_file_url.length
                            _formFileName[response.official_travel_rapel_emp_uuid] = maxName < 28 ? response.official_travel_rapel_emp_file_url : response.official_travel_rapel_emp_file_url.substring(0, 28) + "..."
                        }
                        /* if (response.official_travel_rapel_emp_file_name !== null) {
                            var maxName = response.official_travel_rapel_emp_file_name.length
                            _formFileName[response.official_travel_rapel_emp_uuid] = maxName < 17 ? response.official_travel_rapel_emp_file_name : response.official_travel_rapel_emp_file_name.substring(0, 15) + '...'
                        } */
                        _formValue[response.official_travel_rapel_emp_uuid] = []
                        _formValue[response.official_travel_rapel_emp_uuid]["kurs"] = response.official_travel_rapel_emp_kurs_value
                        _formValue[response.official_travel_rapel_emp_uuid]["oversea"] = response.official_travel_oversea
                        _formValue[response.official_travel_rapel_emp_uuid]["daily_max_day"] = response.official_travel_rapel_emp_daily_max_day
                        _formValue[response.official_travel_rapel_emp_uuid]["daily_max_value_1"] = response.official_travel_rapel_emp_daily_max_value_1
                        _formValue[response.official_travel_rapel_emp_uuid]["daily_max_value_2"] = response.official_travel_rapel_emp_daily_max_value_2
                        _formValue[response.official_travel_rapel_emp_uuid]["daily_max_value_3"] = response.official_travel_rapel_emp_daily_max_value_3
                        _formValue[response.official_travel_rapel_emp_uuid]["daily"] = []
                        _formValue[response.official_travel_rapel_emp_uuid]["hotel"] = []
                        _formValue[response.official_travel_rapel_emp_uuid]["transport"] = []
                        _formValue[response.official_travel_rapel_emp_uuid]["representative"] = []
                        _formValue[response.official_travel_rapel_emp_uuid]["other"] = []

                        var _daily = []
                        var _hotel = []
                        var _transport = []
                        var _representative = []
                        var _other = []
                        for (var d = 0; d < response.daily.length; ++d) {
                            _daily[response.daily[d].official_travel_rapel_value_type] = [response.daily[d].official_travel_rapel_value_uuid, response.daily[d].official_travel_rapel_value_quantity.toString(), response.daily[d].official_travel_rapel_value_amount, response.daily[d].official_travel_rapel_value_total]
                        }
                        for (var d = 0; d < response.hotel.length; ++d) {
                            _hotel[response.hotel[d].official_travel_rapel_value_type] = [response.hotel[d].official_travel_rapel_value_uuid, response.hotel[d].official_travel_rapel_value_quantity.toString(), response.hotel[d].official_travel_rapel_value_amount, response.hotel[d].official_travel_rapel_value_total]
                        }
                        for (var d = 0; d < response.transport.length; ++d) {
                            _transport[response.transport[d].official_travel_rapel_value_type] = [response.transport[d].official_travel_rapel_value_uuid, response.transport[d].official_travel_rapel_value_quantity.toString(), response.transport[d].official_travel_rapel_value_amount, response.transport[d].official_travel_rapel_value_total]
                        }
                        for (var d = 0; d < response.representative.length; ++d) {
                            _representative[response.representative[d].official_travel_rapel_value_type] = [response.representative[d].official_travel_rapel_value_uuid, response.representative[d].official_travel_rapel_value_quantity.toString(), response.representative[d].official_travel_rapel_value_amount, response.representative[d].official_travel_rapel_value_total]
                        }
                        for (var d = 0; d < response.other.length; ++d) {
                            _other[response.other[d].official_travel_rapel_value_type] = [response.other[d].official_travel_rapel_value_uuid, response.other[d].official_travel_rapel_value_quantity.toString(), response.other[d].official_travel_rapel_value_amount, response.other[d].official_travel_rapel_value_total]
                        }

                        _formValue[response.official_travel_rapel_emp_uuid]["daily"]["items"] = _daily
                        _formValue[response.official_travel_rapel_emp_uuid]["daily"]["total"] = response.official_travel_rapel_emp_value_daily
                        _formValue[response.official_travel_rapel_emp_uuid]["hotel"]["items"] = _hotel
                        _formValue[response.official_travel_rapel_emp_uuid]["hotel"]["total"] = response.official_travel_rapel_emp_value_hotel
                        _formValue[response.official_travel_rapel_emp_uuid]["transport"]["items"] = _transport
                        _formValue[response.official_travel_rapel_emp_uuid]["transport"]["total"] = response.official_travel_rapel_emp_value_transport
                        _formValue[response.official_travel_rapel_emp_uuid]["representative"]["items"] = _representative
                        _formValue[response.official_travel_rapel_emp_uuid]["representative"]["total"] = response.official_travel_rapel_emp_value_representative
                        _formValue[response.official_travel_rapel_emp_uuid]["other"]["items"] = _other
                        _formValue[response.official_travel_rapel_emp_uuid]["other"]["total"] = response.official_travel_rapel_emp_value_other
                    }

                    _forMinio[_forMinio.length] = { field: "process_file", name: "Keuangan/PerjalananDinas/Lampiran" }

                    if (this.state.processDetail.official_travel_rapel_file_url !== null) {
                        _formFileName["process_file"] = this.state.processDetail.official_travel_rapel_file_url
                    }
                    /* if (this.state.processDetail.official_travel_rapel_file_name !== null) {
                        _formFileName['process_file'] = this.state.processDetail.official_travel_rapel_file_name
                    } */
                    this.setState({
                        formValue: _formValue,
                        formFileName: _formFileName,
                        loadingEmployee: false,
                        processEmployees: result.data.response,
                        forMinio: _forMinio,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loadingEmployee: false,
                    })
                    break
                default:
                    break
            }
        })
        this.verifyBloc.verifyVerifyChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    var response = result.data.response
                    if (result.data.status) {
                        this.setState({
                            processUuid: response,
                            loading: false,
                            success: true,
                        })
                    } else {
                        this.setState({
                            loading: false,
                            failed: true,
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.verifyBloc.verifyRejectChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })
    }

    getEmployeeData() {
        this.verifyBloc.fetchParticipant({ uuid: this.props.uuid })
    }

    componentWillUnmount() {
        this.verifyBloc.verifyVerifyChannel?.unsubscribe()
        this.verifyBloc.verifyRejectChannel?.unsubscribe()
        this.verifyBloc.verifyDetailChannel?.unsubscribe()
        this.verifyBloc.verifyParticipantChannel?.unsubscribe()
    }

    handlerCloseModal = () => {
        this.setState({
            showModal: false,
        })
    }

    handlerChangeValue = (newValue) => {
        var _formValue = this.state.formValue
        _formValue[this.state.currentEdit] = newValue
        this.setState({
            formValue: _formValue,
            showModal: false,
        })
    }

    numberWithPoint(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    downloadFile = (path) => {
        this.setState({
            minioLoading: true,
        })
        this.minioClient.get(path).then((url) => {
            this.setState({
                minioLoading: false,
                minioSuccess: true,
            })
            setTimeout(() => {
                this.setState({
                    minioSuccess: false,
                })
            }, 1000)
            window.open(url, "_blank")
        })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Tambah Proses Pengajuan Rapel Perjalanan Dinas</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm className="needs-validation" onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}>
                                        <Row className="mb-3">
                                            <label className="col-md-2 mt-1">Nomor Pengajuan</label>
                                            <Col md="10" className="mt-1">
                                                {this.state.loadingInitial ? (
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: 200, height: 15 }}></div>
                                                    </Shimmer>
                                                ) : (
                                                    this.state.processDetail.official_travel_rapel_number
                                                )}
                                                <AvField type="hidden" name="process_uuid" value={this.state.uuid} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label className="col-md-2 mt-1">Tujuan</label>
                                            <Col md="10" className="mt-1">
                                                {this.state.loadingInitial ? (
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: 200, height: 15 }}></div>
                                                    </Shimmer>
                                                ) : (
                                                    this.state.processDetail.official_travel_rapel_type_name
                                                )}
                                                <AvField type="hidden" name="process_uuid" value={this.state.uuid} />
                                                <AvField type="hidden" name="process_action" value={this.state.action} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label className="col-md-2 mt-1">Data Pengajuan</label>
                                            <Col md="10" className="mt-1">
                                                {this.state.loadingInitial ? (
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: 200, height: 15 }}></div>
                                                    </Shimmer>
                                                ) : (
                                                    <div className="d-flex flex">
                                                        <div className={"bg-soft-" + (this.state.processDetail.official_travel_rapel_type1_official_travel_other_uuid === "self" ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>Uang Harian</div>
                                                        <div className={"bg-soft-" + (this.state.processDetail.official_travel_rapel_type2_official_travel_other_uuid === "self" ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>{this.state.processDetail.official_travel_rapel_type === 2 ? "Perjalanan" : "Penginapan"}</div>
                                                        <div className={"bg-soft-" + (this.state.processDetail.official_travel_rapel_type3_official_travel_other_uuid === "self" ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>Transportasi</div>
                                                        {this.state.processDetail.official_travel_rapel_type === 2 ? <></> : <div className={"bg-soft-" + (this.state.processDetail.official_travel_rapel_type4_official_travel_other_uuid === "self" ? "success" : "secondary opacity-25") + " badge rounded-pill font-size-14 me-2 px-2"}>Representatif</div>}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label className="col-md-2 mt-1">File Lampiran</label>
                                            <Col md="10" className="mt-1">
                                                {this.state.loadingEmployee ? (
                                                    <Shimmer className="shimmer">
                                                        <div style={{ width: 240, height: 22 }}></div>
                                                    </Shimmer>
                                                ) : (
                                                    <>
                                                        {this.state.formFileName["process_file"] !== null ? (
                                                            <a href={this.state.formFileName["process_file"]} rel="noreferrer" target="_blank">
                                                                {this.state.formFileName["process_file"]}
                                                            </a>
                                                        ) : (
                                                            <>-</>
                                                        )}
                                                        {/* {this.state.processDetail.official_travel_rapel_file_path !== null ?
                                                            <span className={"btn btn-link btn-sm"} onClick={() => { this.downloadFile(this.state.processDetail.official_travel_rapel_file_path) }} style={{ padding: '2px 0px' }}>{this.state.processDetail.official_travel_rapel_file_name}</span>
                                                            :
                                                            <span>-</span>
                                                        } */}
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mb-3">
                                            <Col md="12">
                                                <div className="table-responsive">
                                                    <Table className="table table-bordered table-striped table-hover" style={{ minWidth: 1000 }}>
                                                        {!this.state.loadingInitial ? (
                                                            <Thead>
                                                                <Tr>
                                                                    <Th width="180" className="text-center">
                                                                        Nomor ST
                                                                    </Th>
                                                                    {/* <Th width="40"></Th> */}
                                                                    <Th width="*">Nama Pegawai</Th>
                                                                    <Th width="160" className="text-end">
                                                                        Uang Harian
                                                                    </Th>
                                                                    <Th width="160" className="text-end">
                                                                        {this.state.processDetail.official_travel_rapel_type === 1 ? "Penginapan" : "Perjalanan"}
                                                                    </Th>
                                                                    <Th width="160" className="text-end">
                                                                        Transportasi
                                                                    </Th>
                                                                    <Th width="160" className="text-end" style={{ display: this.state.processDetail.official_travel_rapel_type === 1 ? "" : "none" }}>
                                                                        Representatif
                                                                    </Th>
                                                                    <Th width="160" className="text-end">
                                                                        Total
                                                                    </Th>
                                                                    {/* <Th width="220">Lampiran</Th> */}
                                                                    <Th width="120" className="text-center">
                                                                        Aksi
                                                                    </Th>
                                                                </Tr>
                                                            </Thead>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <Tbody>
                                                            {this.state.loadingEmployee === true ? (
                                                                <tr>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }}>
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: "100%", height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    {/* <td style={{ paddingTop: 14, paddingBottom: 0 }}>
                                                                        <Shimmer className="shimmer"><div style={{ width: '100%', height: 15 }}></div></Shimmer>
                                                                    </td> */}
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }}>
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: "80%", height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }} className="text-end">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 120, height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }} className="text-end">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 120, height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }} className="text-end">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 120, height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0, display: this.state.processDetail.official_travel_rapel_type === 1 ? "" : "none" }} className="text-end">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 120, height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    <td style={{ paddingTop: 14, paddingBottom: 0 }} className="text-end">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 120, height: 15 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                    {/* <td className="text-center">
                                                                        <Shimmer className="shimmer"><span style={{ width: '100%', height: 22 }}></span></Shimmer>
                                                                    </td> */}
                                                                    <td className="text-center">
                                                                        <Shimmer className="shimmer">
                                                                            <div style={{ width: 22, height: 22 }}></div>
                                                                        </Shimmer>
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                <>
                                                                    {this.state.processEmployees?.map((item, key) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td className="text-center" style={{ paddingTop: 12, paddingBottom: 12 }}>
                                                                                    <span
                                                                                        className="link-primary"
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    detailUuid: item.official_travel_uuid,
                                                                                                },
                                                                                                function () {
                                                                                                    this.setState({
                                                                                                        showDetail: true,
                                                                                                    })
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        {item.official_travel_number}
                                                                                    </span>
                                                                                </td>
                                                                                {/* <td style={{ paddingTop: 12, paddingBottom: 12 }}>
                                                                                        <div
                                                                                            className="btn btn-warning btn-sm"
                                                                                            disabled={true}
                                                                                            style={{
                                                                                                padding: item.official_travel_oversea === 2 ? '1px 5.5px' : '1px 3px',
                                                                                                borderRadius: 20,
                                                                                                fontSize: 10,
                                                                                                cursor: 'default',
                                                                                            }}
                                                                                        >
                                                                                            {item.official_travel_oversea === 2 ? '$' : 'Rp'}
                                                                                        </div>
                                                                                    </td> */}
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12 }}>{item.employee_name}</td>
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12 }} className="text-end">
                                                                                    Rp. {this.numberWithPoint(this.state.formValue[item.official_travel_rapel_emp_uuid]["daily"]["total"].replace(".00", "").replace(".", ","))},-
                                                                                </td>
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12 }} className="text-end">
                                                                                    Rp. {this.numberWithPoint(this.state.formValue[item.official_travel_rapel_emp_uuid]["hotel"]["total"].replace(".00", "").replace(".", ","))},-
                                                                                </td>
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12 }} className="text-end">
                                                                                    Rp. {this.numberWithPoint(this.state.formValue[item.official_travel_rapel_emp_uuid]["transport"]["total"].replace(".00", "").replace(".", ","))},-
                                                                                </td>
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12, display: this.state.processDetail.official_travel_rapel_type === 1 ? "" : "none" }} className="text-end">
                                                                                    Rp. {this.numberWithPoint(this.state.formValue[item.official_travel_rapel_emp_uuid]["representative"]["total"].replace(".00", "").replace(".", ","))},-
                                                                                </td>
                                                                                <td style={{ paddingTop: 12, paddingBottom: 12 }} className="text-end">
                                                                                    Rp. {this.numberWithPoint((parseInt(this.state.formValue[item.official_travel_rapel_emp_uuid]["daily"]["total"]) + parseInt(this.state.formValue[item.official_travel_rapel_emp_uuid]["hotel"]["total"]) + parseInt(this.state.formValue[item.official_travel_rapel_emp_uuid]["transport"]["total"]) + parseInt(this.state.formValue[item.official_travel_rapel_emp_uuid]["representative"]["total"])).toString().replace(".00", "").replace(".", ","))},-
                                                                                </td>
                                                                                {/* <td> */}
                                                                                {/* {(this.state.formFileName[item.official_travel_rapel_emp_uuid] !== null) ?
                                                                                            <a href={item.official_travel_rapel_emp_file_url} rel="noreferrer" target="_blank">{this.state.formFileName[item.official_travel_rapel_emp_uuid]}</a>
                                                                                            : <>-</>
                                                                                        } */}
                                                                                {/* {(item.official_travel_rapel_emp_file_path !== null) ?
                                                                                            <span className={"btn btn-link btn-sm"} onClick={() => { this.downloadFile(item.official_travel_rapel_emp_file_path) }} style={{ padding: '2px 5px' }}>{item.official_travel_rapel_emp_file_name}</span>
                                                                                            : <>-</>
                                                                                        } */}

                                                                                {/* </td> */}
                                                                                <td className="text-center">
                                                                                    <span
                                                                                        className="btn btn-primary btn-sm"
                                                                                        style={{
                                                                                            padding: "2px 5px",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            var _formTemp = this.state.formValue
                                                                                            this.setState({
                                                                                                showModal: true,
                                                                                                currentEdit: item.official_travel_rapel_emp_uuid,
                                                                                                currentName: item.employee_name,
                                                                                                formTemp: _formTemp[item.official_travel_rapel_emp_uuid],
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <i className="uil-file-search-alt"></i>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </>
                                                            )}
                                                        </Tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <Button color="success" type="submit" disabled={this.state.loadingEmployee}>
                                                    <i className="uil-arrow-up-right"></i> Verifikasi Pengajuan
                                                </Button>{" "}
                                                <Button
                                                    color="warning"
                                                    type="button"
                                                    onClick={() => {
                                                        this.setState({
                                                            showReject: true,
                                                        })
                                                    }}
                                                >
                                                    <i className="uil-times"></i> Tolak Pengajuan
                                                </Button>{" "}
                                                <Link to="/official-travel/finalization.html">
                                                    <Button color="danger" type="button">
                                                        <i className="uil-arrow-left"></i> Kembali
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Detail Surat Tugas
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <OfficialTravelSourceComponent uuid={this.state.detailUuid} />
                    </div>
                </Modal>

                <Modal
                    size="lg"
                    isOpen={this.state.showModal}
                    toggle={() => {
                        this.setState({
                            showModal: this.state.showModal,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Detil Nilai Pengajuan untuk {this.state.currentName}
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showModal: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <OfficialTravelUpdateComponent formData={this.state.formTemp} closeModal={this.handlerCloseModal} changeValue={this.handlerChangeValue} />
                </Modal>

                <Modal
                    size="sm"
                    isOpen={this.state.showReject}
                    toggle={() => {
                        this.setState({
                            showReject: !this.state.showReject,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Alasan Penolakan
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showReject: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <AvForm className="needs-validation" onValidSubmit={(e, values) => this.handlerOnReject(e, values)}>
                        <AvField name="process_uuid" type="hidden" id="process_uuid" value={this.props.uuid} />
                        <AvField name="process_back" type="hidden" id="process_back" value="3" />
                        <div className="modal-body">
                            <Col md="12">
                                <AvField name="reason" placeholder="Alasan Penolakan" errorMessage="Masukkan Alasan Penolakan" type="textarea" className="form-control" id="fieldReason" rows="4" validate={{ required: { value: true } }} />
                            </Col>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    this.setState({
                                        showReject: false,
                                    })
                                }}
                                className="btn btn-danger waves-effect"
                                data-dismiss="modal"
                            >
                                Batal
                            </button>
                            <button
                                type="submit"
                                onClick={() => {
                                    //submit
                                }}
                                className="btn btn-primary waves-effect waves-light"
                            >
                                Kirim Alasan Menolak
                            </button>
                        </div>
                    </AvForm>
                </Modal>

                <ConfirmAlert confirmTitle="Konfirmasi!" confirmInfo="Apakah anda yakin akan mengirim data ini?" loadingTitle="Mengirim data..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="Data berhasil disimpan" failedTitle="Gagal!" failedInfo="Data gagal disimpan" showConfirm={this.state.confirm} showLoading={this.state.loading} showSuccess={this.state.success} showFailed={this.state.failed} response={this.confirmResponse} />

                <ConfirmAlert loadingTitle="Membuat file..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="File akan segera terunduh otomatis" failedTitle="Gagal!" failedInfo="File gagal dibuat, silahkan coba lagi" showLoading={this.state.minioLoading} showSuccess={this.state.minioSuccess} showFailed={this.state.minioFailed} />
                {this.state.redirect ? (
                    <>
                        <Redirect to="/official-travel/finalization.html" />
                    </>
                ) : (
                    <></>
                )}
            </Fragment>
        )
    }
}

export default VerifyView
