import {
    apiRequest,
    tplnRegulerEmployeeYears,
    tplnRegulerEmployeeMonths,
} from "../../../../../../services/adapters/process"

class EmployeeViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", tplnRegulerEmployeeYears, {}, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", tplnRegulerEmployeeMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default EmployeeViewRepository