import { Subject } from "rxjs"
import apiResponse from "../../../../../../services/apiResponse"
import CreateRepository from "../Repositories/CreateRepository"

class CreateBloc {
    createChannel = new Subject()

    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchCreate(query)
                .then((result) => {
                    this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.createChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {
            console.log("rxjs", "Channel Unsubscribed")
        }
    }
}

export default CreateBloc
