import {
    apiRequest,
    masterSpdProvinceDetail,
    masterSpdProvinceUpdate,
    masterSpdProvinceprovince,
    masterSpdProvinceprovinceCheck
} from "../../../../../../services/adapters/master"

class UpdateRepository {

    
    fetchDetail = async (query, cancelToken) => {
        return await apiRequest("get", masterSpdProvinceDetail, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", masterSpdProvinceUpdate, {
            body: query
        }, true)
    }

    fetchCountryList = async () => {
        return await apiRequest("get", masterSpdProvinceprovince, {}, true)
    }

    fetchCheck = async (query) => {
        return await apiRequest("post", masterSpdProvinceprovinceCheck, {
            body: query
        }, true)
    }
}

export default UpdateRepository