import { accessRole, signer } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { ConfirmAlert } from "../../../../../helpers/ui/alert"

import MinioClient from "../../../../../services/minioClient"

import moment from "moment"

import { Tooltip, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Row, Col } from "reactstrap"

import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import MKIDatatable from "../../../../../modules/mki-datatable/lib"

import { cancelToken } from "../../../../../services/adapters/base"

import IndexViewBloc from "../Blocs/IndexViewBloc"
import apiResponse from "../../../../../services/apiResponse"
import LoadingComponent from "./LoadingComponent"

const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]

class PayrollIndexListComponent extends Component {
    isSigner = signer("PSLIP")
    indexViewBloc = new IndexViewBloc()
    minioClient = new MinioClient()

    source
    historyTable = "historyPayrollPayrollIndexListComponent"
    defaultOrder = "paycheck_create_date"
    defaultSize = 10
    defaultSort = "desc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            showSKPHistory: false,

            loadingGenerate: false,
            successGenerate: false,
            failedGenerate: false,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            loadingDetail: false,
            detail: null,
            approveUuid: null,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    toggleModal = () => this.setState({ approveUuid: null })

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                uuid: this.state.approveUuid,
                ...values,
            },
            confirm: true,
        })
    }

    submitData = async () => {
        await this.indexViewBloc.fetchSignPayroll(this.state.dataForm)
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    generateFile = async (path) => {
        //alert(path)
        this.setState({
            loadingGenerate: true,
        })
        this.minioClient.get(path).then((url) => {
            this.setState({
                loadingGenerate: false,
                successGenerate: true,
            })
            setTimeout(() => {
                window.open(url, "_blank")
                this.setState({
                    successGenerate: false,
                })
            }, 1000)
        })
    }

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            year: this.props.year,
        }
        await this.indexViewBloc.fetchRowTablePayroll(query, this.source.token)
    }

    loadDetail = async () => {
        await this.indexViewBloc.fetchDetailPayroll({ uuid: this.state.approveUuid })
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.rowPayrollDataChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.detailPayrollChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingDetail: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingDetail: false,
                        detail: result.data.response.paycheck,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.signPayrollChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        approveUuid: null,
                    })
                    this.loadData()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.rowPayrollDataChannel.unsubscribe()
        this.indexViewBloc.detailPayrollChannel?.unsubscribe()
        this.indexViewBloc.signPayrollChannel?.unsubscribe()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.approveUuid !== this.state.approveUuid) {
            if (this.state.approveUuid !== null) {
                this.loadDetail()
            }
        }
    }

    listPengajuan = (year, month, jumlah) => {
        let list = []
        for (let i = 0; i < jumlah; i++) {
            if (month - i <= 0) {
                list.push({ year: year - 1, month: 12 + (month - i) })
            } else {
                list.push({ year: year, month: month - i })
            }
        }
        return list
    }

    columns = [
        {
            key: "paycheck_status",
            text: "Proses",
            sortable: true,
            width: "140",
            align: "center",
            cell: (record) => {
                return (
                    <>
                        {record.paycheck_status === 2 || record.paycheck_status === 3 ? (
                            <button className="btn btn-soft-primary btn-sm" style={{ padding: "2px 5px", borderRadius: 16 }}>
                                done
                            </button>
                        ) : (
                            <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                done
                            </button>
                        )}{" "}
                        {record.paycheck_status === 1 ? (
                            <button
                                className="btn btn-soft-danger btn-sm"
                                onClick={() => {}}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                Request
                            </button>
                        ) : (
                            <>
                                {record.paycheck_status === 3 ? (
                                    <button
                                        className="btn btn-soft-success btn-sm"
                                        onClick={() => {}}
                                        style={{
                                            padding: "2px 5px",
                                            borderRadius: 16,
                                        }}
                                    >
                                        approved
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-soft-secondary btn-sm"
                                        disabled={true}
                                        style={{
                                            padding: "2px 5px",
                                            borderRadius: 16,
                                        }}
                                    >
                                        approval
                                    </button>
                                )}
                            </>
                        )}
                    </>
                )
            },
        },
        {
            key: "paycheck_create_date",
            text: "Tanggal Pengajuan",
            align: "center",
            width: "200",
            sortable: true,
            cell: (record) => {
                return moment(record.paycheck_create_date).format("LL")
            },
        },
        {
            key: "paycheck_employee_name",
            text: "Pegawai",
            sortable: true,
        },
        {
            key: "paycheck_total",
            text: "Pengajuan Slip Pegawai",
            sortable: true,
            cell: (record) => {
                let list = this.listPengajuan(record.paycheck_year, record.paycheck_month, record.paycheck_total)
                return (
                    <>
                        {list?.map((val, i) => {
                            return (
                                <>
                                    {months[val.month - 1]} {val.year}
                                    {i + 1 != list?.length && ", "}
                                </>
                            )
                        })}
                    </>
                )
            },
        },
        {
            key: "paycheck_approve_name",
            text: "Diproses Oleh",
            sortable: true,
        },
        {
            key: "action",
            text: "Aksi",
            width: "140",
            align: "center",
            cell: (record) => {
                const dataReady = record.paycheck_status === 1 ? true : false
                const dataApprove = record.paycheck_status === 2 ? true : false
                const dataSigned = record.paycheck_status === 3 ? true : false
                const thisId = record.paycheck_id
                return (
                    <>
                        <span>
                            {accessRole("update") ? (
                                <>
                                    {dataReady ? (
                                        <>
                                            <Link
                                                className="btn btn-primary btn-sm"
                                                id={"proses-" + thisId}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                                to={"/payroll/skp.html?payrollupdate&uuid=" + record.paycheck_uuid}
                                            >
                                                <i className="uil-cog"></i>
                                            </Link>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["proses" + thisId]}
                                                target={"proses-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["proses" + thisId]: !this.state["proses" + thisId],
                                                    })
                                                }}
                                            >
                                                Proses Slip Gaji
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-cog"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            {accessRole("approve") ? (
                                <>
                                    {dataApprove && this.isSigner === 1 ? (
                                        <>
                                            <button
                                                className={"btn btn-success btn-sm"}
                                                id={"approve-" + thisId}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                                onClick={() => this.setState({ approveUuid: record.paycheck_uuid })}
                                            >
                                                <i className="uil-check"></i>
                                            </button>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["approve" + thisId]}
                                                target={"approve-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["approve" + thisId]: !this.state["approve" + thisId],
                                                    })
                                                }}
                                            >
                                                Setujui Slip Gaji
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-check"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            {accessRole("print") ? (
                                <>
                                    {dataSigned ? (
                                        <>
                                            {/* <a
                                                className={"btn btn-danger btn-sm"}
                                                id={"print-" + thisId}
                                                style={{
                                                    marginRight: '2px',
                                                    padding: '2px 5px'
                                                }}
                                                target="_blank"
                                                href={record.paycheck_pdf_file_path}
                                                rel="noreferrer"
                                            >
                                                    <i className="uil-arrow-to-bottom"></i>
                                            </a> */}
                                            <button
                                                className={"btn btn-danger btn-sm"}
                                                id={"print-" + thisId}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                                onClick={() => {
                                                    this.generateFile(record.paycheck_pdf_file_path)
                                                }}
                                            >
                                                <i className="uil-arrow-to-bottom"></i>
                                            </button>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["print" + thisId]}
                                                target={"print-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["print" + thisId]: !this.state["print" + thisId],
                                                    })
                                                }}
                                            >
                                                Download
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-arrow-to-bottom"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                        </span>
                    </>
                )
            },
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1000}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal size="lg" isOpen={this.state.approveUuid !== null ? true : false} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>Detail Permohonan Slip Gaji</ModalHeader>
                    <AvForm className="needs-validation" onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}>
                        <ModalBody>
                            {this.state.loadingDetail === true ? (
                                <Fragment>
                                    <LoadingComponent message="Memeriksa Data Gpp..." />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Row className="mb-3">
                                        <Label className="col-md-2 col-form-label">Nama Pegawai</Label>
                                        <Label className="col-md-8 col-form-label">{this.state.detail?.paycheck_employee_name}</Label>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label className="col-md-2 col-form-label">Bulan</Label>
                                        <Label className="col-md-8 col-form-label">{months[this.state.detail?.paycheck_month - 1]}</Label>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label className="col-md-2 col-form-label">Tahun</Label>
                                        <Label className="col-md-8 col-form-label">{this.state.detail?.paycheck_year}</Label>
                                    </Row>
                                    {/* <Row className="mb-3">
                                        <Label
                                            className="col-md-2 mt-1 col-form-label"
                                        >
                                            Passphrase
                                        </Label>
                                        <Label
                                            className="col-md-8 col-form-label"
                                        >
                                            <AvField 
                                                name="passphrase"
                                                type="password"
                                                validate={{ required: { value: true } }}
                                            />
                                        </Label>
                                    </Row> */}
                                    <hr />
                                    <Row className="">
                                        <Col md="10">
                                            <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="submit">
                                Tandatangani
                            </Button>{" "}
                            <Button color="danger" onClick={() => this.toggleModal()}>
                                Batal
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </Modal>

                <ConfirmAlert confirmTitle="Konfirmasi!" confirmInfo="Apakah anda yakin akan menyimpan data ini?" loadingTitle="Mengirim data..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="Data berhasil disimpan" failedTitle="Gagal!" failedInfo="Data gagal disimpan" showConfirm={this.state.confirm} showLoading={this.state.loading} showSuccess={this.state.success} showFailed={this.state.failed} response={this.confirmResponse} />

                <ConfirmAlert loadingTitle="Meminta File..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="File berhasil diterima" failedTitle="Gagal!" failedInfo="Data gagal diterima" showLoading={this.state.loadingGenerate} showSuccess={this.state.successGenerate} showFailed={this.state.failedGenerate} response={this.confirmResponse} />
            </>
        )
    }
}

export default PayrollIndexListComponent
