import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { cloneElement, Component, Fragment } from "react"
import { Redirect } from "react-router-dom"
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'

import { Tooltip, Modal } from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../../services/apiResponse'

import ReactTimeAgo from 'react-time-ago'
import TableDetailComponent from './TableDetailComponent'
import TableUpdateComponent from "./TableUpdateComponent"

class TableActiveComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyMasterReferenceCountryActive'
    defaultOrder = 'ptkp_data_value'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            detailUuid: null,
            updateUuid: null,
            inactiveUuid: null,
            showCountryDetail: false,
            showCountryUpdate: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            //status: 1
        }
        await this.dataTableBloc.fetchRowTableB(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannelB.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        // this.dataTableBloc.inactiveChannel.subscribe((result) => {
        //     switch (result.status) {
        //         case apiResponse.COMPLETED:
        //             this.setState({
        //                 loading: false,
        //                 success: true,
        //                 loadingTable: true
        //             }, function () {
        //                 this.setTokenAPI()
        //                 this.props.handleInactiveSuccess()
        //             });
        //             break
        //         case apiResponse.ERROR:
        //             this.setState({
        //                 loading: false,
        //                 failed: true,
        //             });
        //             break
        //         default:
        //             break
        //     }
        // })
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannelB.unsubscribe()
        // this.dataTableBloc.inactiveChannel.unsubscribe()
    }


    columns = [
        {
            key: "ptkp_data_start",
            text: "Diatas",
            align: 'center',
            sortable: true,
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            {record.ptkp_data_start.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </div>
                    </>
                )
            }
        },
        {
            key: "ptkp_data_end",
            text: "Sampai",
            align: 'center',
            sortable: true,
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            {record.ptkp_data_end.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </div>
                    </>
                )
            }
        },
        {
            key: "ptkp_data_value",
            text: "Tarif Efektif Rata",
            align: 'center',
            sortable: true,
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            {record.ptkp_data_value.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}%
                        </div>
                    </>
                )
            }
        },        
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 120,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisId = record.ptkp_data_uuid
                return (
                    <Fragment>
                        <span>
                            <button
                                className="btn btn-success btn-sm"
                                id={"detail-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        detailUuid: record.ptkp_data_uuid,
                                        showCountryDetail: true
                                    })
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                <i className="uil-align-left"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["detail" + thisId]: !this.state["detail" + thisId]
                                    })
                                }}
                            >Detil</Tooltip>
                        </span>
                        {accessRole('update') ?
                            <span>
                                <button
                                    className="btn btn-warning btn-sm"
                                    id={"update-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            updateUuid: record.ptkp_data_uuid
                                        })
                                    }}
                                    style={{
                                        marginRight: '2px',
                                        padding: '2px 5px'
                                    }}>
                                    <i className="uil-pen"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["update" + thisId]} target={"update-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["update" + thisId]: !this.state["update" + thisId]
                                        })
                                    }}
                                >Perbaharui</Tooltip>
                            </span>
                            : null
                        }
                    </Fragment>
                );
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />

                <Modal
                    size="lg"
                    isOpen={this.state.showCountryDetail}
                    toggle={() => {
                        this.setState({
                            showCountryDetail: !this.state.showCountryDetail
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Detail Perjalanan Dinas Negara
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showCountryDetail: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TableDetailComponent uuid={this.state.detailUuid} />
                    </div>
                </Modal>
                {this.state.updateUuid !== null ?
                    <Redirect to={"/master/pajak-ptkp2024.html?update&uuid=" + this.state.updateUuid} />
                    : null}

            </>
        )
    }

}

export default TableActiveComponent