import classnames from "classnames"
import React, { Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect"

import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import { ConfirmAlert } from "../../../../../helpers/ui/alert"

import { Link, Redirect, withRouter } from "react-router-dom"
import apiResponse from "../../../../../services/apiResponse"

import VerifyBloc from "./Blocs/VerifyBloc"

import SimpegComponent from "./Components/SimpegComponent"
import CompareComponent from "./Components/CompareComponent"

class VerifyView extends Component {
    verifyBloc = new VerifyBloc()

    constructor(props) {
        super(props)
        this.state = {
            uuid: this.props.uuid,
            activeTab: "1",
        }
    }

    submitReset = async () => {
        let formData = new FormData()
        formData.append("uuid", this.state.uuid)
        await this.verifyBloc.fetchResetRequest(formData)
    }

    handlerResetSubmit = () => {
        this.setState({
            resetConfirm: true,
        })
    }

    confirmResetResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    resetConfirm: false,
                }
                break
            case "confirm":
                forState = {
                    resetLoading: true,
                    resetConfirm: false,
                }
                this.submitReset()
                break
            case "success":
                forState = {
                    resetSuccess: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    resetFailed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.verifyBloc.resetRequestChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        resetLoading: false,
                        resetSuccess: true,
                        redirect: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        resetLoading: false,
                        resetFailed: true,
                    })
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.verifyBloc.resetRequestChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Validasi Honorarium</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Pengecekan data Honorarium perpegawain dan pemrosesan data Honorarium
                                    </CardSubtitle>
                                    <hr />
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: this.state.activeTab === "1",
                                                })}
                                                onClick={() => {
                                                    this.setState({
                                                        activeTab: "1",
                                                    })
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block">Simpeg</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: this.state.activeTab === "2",
                                                })}
                                                onClick={() => {
                                                    this.setState({
                                                        activeTab: "2",
                                                    })
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block">Simkeu</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                                        <TabPane tabId="1">
                                            <SimpegComponent uuid={this.props.uuid} />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <CompareComponent uuid={this.props.uuid} />
                                        </TabPane>
                                    </TabContent>
                                    <hr style={{ marginTop: 0 }} />
                                    <Row className="mb-3">
                                        <Col md="10">
                                            <Link to="/payroll/ptsp-reguler.html">
                                                <Button color="danger" type="button">
                                                    <i className="uil-arrow-left"></i> Kembali
                                                </Button>
                                            </Link>{" "}
                                            {/* <Button
                                                color="primary"
                                                onClick={() => {
                                                    this.handlerResetSubmit()
                                                }}
                                            >
                                                <i className="uil-refresh"></i> Ajukan Reset Data Honor
                                            </Button> */}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan mengajukan reset data ini?"
                    loadingTitle="Mereset data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil direset"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal direset"
                    showConfirm={this.state.resetConfirm}
                    showLoading={this.state.resetLoading}
                    showSuccess={this.state.resetSuccess}
                    showFailed={this.state.resetFailed}
                    response={this.confirmResetResponse}
                />
                {this.state.redirect ? <Redirect to="/payroll/ptsp-reguler.html" /> : null}
            </Fragment>
        )
    }
}

export default VerifyView
