import {
    apiRequest,
    tunsusRapelApproveSubmit,
    tunsusRapelRejectSubmit,
    tunsusRapelResultEmployee,
    tunsusRapelCheckStatus,
    tunsusRapelResultEmployeeDetail,
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {
    fetchApprove = async (query) => {
        return await apiRequest(
            "post",
            tunsusRapelApproveSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchReject = async (query) => {
        return await apiRequest(
            "post",
            tunsusRapelRejectSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunsusRapelResultEmployee,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelResultEmployeeDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest(
            "get",
            tunsusRapelCheckStatus,
            {
                params: query,
            },
            true
        )
    }
}

export default ApproveViewRepository
