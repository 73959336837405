import {
    apiRequest,
    uangMakanRapelProcessSubmit,
    uangMakanRapelProcessEmployees,
    uangMakanRapelProcessEmployeeDetail,
    uangMakanRapelCheckStatus,
    uangMakanRapelGetSigner,
    uangMakanRapelCheckPresence,
    uangMakanRapelCheckRank,
    uangMakanRapelCheckTaxFinal,
    uangMakanRapelCheckEmployeeNew,
    uangMakanRapelCheckEmployeeChange
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", uangMakanRapelProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", uangMakanRapelProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", uangMakanRapelProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", uangMakanRapelGetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", uangMakanRapelCheckStatus, {
            params: query
        }, true)
    }

    fetchCheckPresence = async (query) => {
        return await apiRequest("get", uangMakanRapelCheckPresence, {
            params: query
        }, true)
    }

    fetchCheckRank = async (query) => {
        return await apiRequest("get", uangMakanRapelCheckRank, {
            params: query
        }, true)
    }

    fetchCheckTaxFinal = async (query) => {
        return await apiRequest("get", uangMakanRapelCheckTaxFinal, {
            params: query
        }, true)
    }

    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", uangMakanRapelCheckEmployeeNew, {
            params: query
        }, true)
    }

    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", uangMakanRapelCheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository