import React, { Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect"

import { Button } from "reactstrap"
import { Table, Tbody } from "react-super-responsive-table"

import { ConfirmAlert } from "../../../../../../helpers/ui/alert"

import apiResponse from "../../../../../../services/apiResponse"

import VerifyBloc from "../Blocs/VerifyBloc"
import DownloadBloc from "../Blocs/DownloadBloc"

class CompareComponent extends Component {
    verifyBloc = new VerifyBloc()
    downloadBloc = new DownloadBloc()

    constructor(props) {
        super(props)
        this.state = {
            uuid: this.props.uuid,
            year: 0,
            month: 0,
            data: [],
            loading: true,
        }
    }

    componentDidMount() {
        this.downloadBloc.downloadCompareChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        downloadLoading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    this.setState({
                        downloadLoading: false,
                        downloadSuccess: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            downloadSuccess: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        downloadLoading: false,
                        downloadFailed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            downloadFailed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })

        this.verifyBloc.compareChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loading: false,
                        data: response.result !== undefined ? response.result : [],
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })

        this.verifyBloc.fetchCompare({ uuid: this.state.uuid })
    }

    componentWillUnmount() {
        this.verifyBloc.compareChannel.unsubscribe()
        this.downloadBloc.downloadCompareChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <div className="clearfix pb-2">
                    {/* <div className="float-end">
                        <Button
                            color="success"
                            onClick={() => {
                                this.downloadBloc.fetchDownloadCompare({ uuid: this.state.uuid })
                            }}
                        >
                            <i className="uil-file-download-alt"></i> Download Excel
                        </Button>
                    </div> */}
                    <h6 className="pt-2 text-muted">Perbandingan hasil pemrosesan Honor dengan data sebelumnya</h6>
                </div>
                <div style={{ overflowX: "auto" }}>
                    <Table
                        className="table table-striped table-bordered table-hover table-md"
                        style={{ minWidth: 1200 }}
                    >
                        <thead>
                            <tr>
                                <th
                                    width="*"
                                    colspan="3"
                                    data-priority="1"
                                    className="text-center"
                                    style={{ backgroundColor: "#CCDBFF" }}
                                >
                                    Validasi Data Honor
                                </th>
                                <th
                                    width="*"
                                    colspan="3"
                                    data-priority="1"
                                    className="text-center"
                                    style={{ backgroundColor: "#CEFFEA" }}
                                >
                                    Sekarang
                                </th>
                                <th
                                    width="*"
                                    colspan="3"
                                    data-priority="1"
                                    className="text-center"
                                    style={{ backgroundColor: "#FFE8C6" }}
                                >
                                    Bulan Sebelumnya
                                </th>
                            </tr>
                            <tr>
                                <th
                                    style={{ borderBottom: "2px solid #cccccc", verticalAlign: "middle" }}
                                    width="*"
                                    data-priority="1"
                                    className="text-end"
                                >
                                    No
                                </th>
                                <th
                                    className="text-center"
                                    style={{ borderBottom: "2px solid #cccccc", verticalAlign: "middle" }}
                                    width="*"
                                    data-priority="1"
                                >
                                    NIP
                                </th>
                                <th
                                    style={{ borderBottom: "2px solid #cccccc", verticalAlign: "middle" }}
                                    width="*"
                                    data-priority="1"
                                >
                                    Nama
                                </th>

                                <th
                                    style={{ borderBottom: "2px solid #cccccc", verticalAlign: "middle" }}
                                    width="*"
                                    data-priority="1"
                                    className="text-center"
                                >
                                    Grade
                                </th>
                                <th
                                    className="text-center"
                                    style={{ borderBottom: "2px solid #cccccc", verticalAlign: "middle" }}
                                    width="*"
                                    data-priority="1"
                                >
                                    Nilai Honor
                                    <br />
                                    Dasar
                                </th>
                                <th
                                    style={{ borderBottom: "2px solid #cccccc", verticalAlign: "middle" }}
                                    width="*"
                                    data-priority="1"
                                    className="text-center"
                                >
                                    Plt/Plh
                                </th>

                                <th
                                    style={{ borderBottom: "2px solid #cccccc", verticalAlign: "middle" }}
                                    width="*"
                                    data-priority="1"
                                    className="text-center"
                                >
                                    Grade
                                </th>
                                <th
                                    className="text-center"
                                    style={{ borderBottom: "2px solid #cccccc", verticalAlign: "middle" }}
                                    width="*"
                                    data-priority="1"
                                >
                                    Nilai Honor
                                    <br />
                                    Dasar
                                </th>
                                <th
                                    style={{ borderBottom: "2px solid #cccccc", verticalAlign: "middle" }}
                                    width="*"
                                    data-priority="1"
                                    className="text-center"
                                >
                                    Plt/Plh
                                </th>
                            </tr>
                        </thead>
                        <Tbody>
                            {this.state.loading ? (
                                <tr>
                                    <td></td>
                                    <td>
                                        <Shimmer>
                                            <div className="shimmer" style={{ width: "100%", height: 13 }}></div>
                                        </Shimmer>
                                    </td>
                                    <td>
                                        <Shimmer>
                                            <div className="shimmer" style={{ width: "100%", height: 13 }}></div>
                                        </Shimmer>
                                    </td>
                                    <td>
                                        <Shimmer>
                                            <div className="shimmer" style={{ width: "100%", height: 13 }}></div>
                                        </Shimmer>
                                    </td>
                                    <td>
                                        <Shimmer>
                                            <div className="shimmer" style={{ width: "100%", height: 13 }}></div>
                                        </Shimmer>
                                    </td>
                                    <td>
                                        <Shimmer>
                                            <div className="shimmer" style={{ width: "100%", height: 13 }}></div>
                                        </Shimmer>
                                    </td>
                                    <td>
                                        <Shimmer>
                                            <div className="shimmer" style={{ width: "100%", height: 13 }}></div>
                                        </Shimmer>
                                    </td>
                                    <td>
                                        <Shimmer>
                                            <div className="shimmer" style={{ width: "100%", height: 13 }}></div>
                                        </Shimmer>
                                    </td>
                                    <td>
                                        <Shimmer>
                                            <div className="shimmer" style={{ width: "100%", height: 13 }}></div>
                                        </Shimmer>
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    {this.state.data.map((data, index) => {
                                        const empty =
                                            (data.current_discipline === null &&
                                                data.current_discipline_value === null &&
                                                data.current_grade === null &&
                                                data.current_plt === null &&
                                                data.current_plt_value === null &&
                                                data.current_presence === null &&
                                                data.current_presence_value === null &&
                                                data.current_value === null) ||
                                            (data.previous_discipline === null &&
                                                data.previous_discipline_value === null &&
                                                data.previous_grade === null &&
                                                data.previous_plt === null &&
                                                data.previous_plt_value === null &&
                                                data.previous_presence === null &&
                                                data.previous_presence_value === null &&
                                                data.previous_value === null)
                                        return (
                                            <tr key={index}>
                                                <td className="text-end" style={{ color: empty ? "#ff0000" : "" }}>
                                                    {index + 1}
                                                </td>
                                                <td className="text-center" style={{ color: empty ? "#ff0000" : "" }}>
                                                    {data.current_nip}
                                                </td>
                                                <td style={{ color: empty ? "#ff0000" : "" }}>
                                                    {data.current_fullname}
                                                </td>

                                                <td
                                                    className="text-center"
                                                    style={{
                                                        color:
                                                            data.difference_grade === 1
                                                                ? "#ff0000"
                                                                : empty
                                                                ? "#ff0000"
                                                                : "",
                                                    }}
                                                >
                                                    {data.current_grade !== null ? data.current_grade : "-"}
                                                </td>
                                                <td
                                                    className="text-center"
                                                    style={{
                                                        color:
                                                            data.difference_value === 1
                                                                ? "#ff0000"
                                                                : empty
                                                                ? "#ff0000"
                                                                : "",
                                                    }}
                                                >
                                                    {data.current_grade !== null
                                                        ? data.current_value
                                                              .toString()
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"}
                                                </td>
                                                <td
                                                    className="text-center"
                                                    style={{
                                                        color:
                                                            data.difference_plt === 1
                                                                ? "#ff0000"
                                                                : empty
                                                                ? "#ff0000"
                                                                : "",
                                                    }}
                                                >
                                                    {data.current_plt === 1 ? "Ya" : "-"}
                                                </td>

                                                <td
                                                    className="text-center"
                                                    style={{
                                                        color:
                                                            data.difference_grade === 1
                                                                ? "#ff0000"
                                                                : empty
                                                                ? "#ff0000"
                                                                : "",
                                                    }}
                                                >
                                                    {data.previous_grade !== null ? data.previous_grade : "-"}
                                                </td>
                                                <td
                                                    className="text-center"
                                                    style={{
                                                        color:
                                                            data.difference_value === 1
                                                                ? "#ff0000"
                                                                : empty
                                                                ? "#ff0000"
                                                                : "",
                                                    }}
                                                >
                                                    {data.previous_grade !== null
                                                        ? data.previous_value
                                                              .toString()
                                                              .replace(".00", "")
                                                              .toString()
                                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                        : "-"}
                                                </td>
                                                <td
                                                    className="text-center"
                                                    style={{
                                                        color:
                                                            data.difference_plt === 1
                                                                ? "#ff0000"
                                                                : empty
                                                                ? "#ff0000"
                                                                : "",
                                                    }}
                                                >
                                                    {data.previous_plt === 1 ? "Ya" : "-"}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </>
                            )}
                        </Tbody>
                    </Table>
                </div>
                <ConfirmAlert
                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"
                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"
                    showLoading={this.state.downloadLoading}
                    showSuccess={this.state.downloadSuccess}
                    showFailed={this.state.downloadFailed}
                    response={this.confirmDownloadResponse}
                />
            </Fragment>
        )
    }
}

export default CompareComponent
