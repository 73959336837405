import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { Container } from "reactstrap"

const Breadcrumb = props => {


  const uriPath = window.location.pathname
  const accessPath = JSON.parse(sessionStorage.getItem('accessPath'))
  const accessBreadcrumb = JSON.parse(sessionStorage.getItem('accessBreadcrumb'))
  const dataBreadcrumb = accessBreadcrumb[Object.keys(accessPath).find(key => accessPath[key] === uriPath)]

  return (
    <div className="page-content" style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
      { typeof dataBreadcrumb !== "undefined" && dataBreadcrumb.length > 0 ? <Container fluid>
        <Row>
          <Col className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0">{dataBreadcrumb[dataBreadcrumb.length-1]}</h4>
              <div className="page-title">
                <ol className="breadcrumb m-0">
                  {
                    dataBreadcrumb.map((name, i) => {
                      return (
                        <BreadcrumbItem key={i}>
                          <Link to="#">{name}</Link>
                        </BreadcrumbItem>
                      )
                    })
                  }
                </ol>
              </div>
            </div>
          </Col>
        </Row>
      </Container> : null }
    </div>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
