import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import IndexViewRepository from '../Repositories/IndexViewRepository'


class IndexViewBloc {

    employeeChannel = new Subject();
    unitChannel = new Subject();
    detailChannel = new Subject();
    listChannel = new Subject();
    repository = new IndexViewRepository()

    constructor() {
        this.detailChannel.next({ status: apiResponse.INITIAL })
        this.employeeChannel.next({ status: apiResponse.INITIAL })
        this.unitChannel.next({ status: apiResponse.INITIAL })
        this.listChannel.next({ status: apiResponse.INITIAL })
    }

    fetchEmployeeList = async (query) => {
        this.employeeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployeeList(query).then((result) => {
                this.employeeChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchUnitList = async () => {
        this.unitChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchUnitList().then((result) => {
                this.unitChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.unitChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.detailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.detailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchMonitoringList = async (query) => {
        this.listChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchMonitoringList(query).then((result) => {
                this.listChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.listChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }
}

export default IndexViewBloc