import {
    apiRequest,
    listIIPC,
    detailIIPC,
    historyIIPC,
    changeIIPC,
    listCutiBesar,
    detailCutiBesar,
    listTugasBelajar,
    detailTugasBelajar,
    listCLTN,
    detailCLTN,
    listDokumen,
    detailDokumen,
    checklistDokumen,
    changeCutiBesar,
    changeTugasBelajar,
    changeCLTN

} from "../../../../../../services/adapters/simpegsimkeu"

class IndexRepository {

    fetchIIPC = async (query, cancelToken) => {
        return await apiRequest("get", listIIPC, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDetailIIPC = async (query, cancelToken) => {
        return await apiRequest("get", detailIIPC, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistoryIIPC = async (query, cancelToken) => {
        return await apiRequest("get", historyIIPC, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchChangeIIPC = async (query, cancelToken) => {
        return await apiRequest("get", changeIIPC, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchCutiBesar = async (query, cancelToken) => {
        return await apiRequest("get", listCutiBesar, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDetailCutiBesar = async (query, cancelToken) => {
        return await apiRequest("get", detailCutiBesar, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchChangeCutiBesar = async (query,cancelToken) => {
        return await apiRequest("get", changeCutiBesar, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchTugasBelajar = async (query, cancelToken) => {
        return await apiRequest("get", listTugasBelajar, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDetailTugasBelajar = async (query, cancelToken) => {
        return await apiRequest("get", detailTugasBelajar, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchChangeTugasBelajar = async (query, cancelToken) => {
        return await apiRequest("get", changeTugasBelajar, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    
    fetchCLTN = async (query, cancelToken) => {
        return await apiRequest("get", listCLTN, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDetailCLTN = async (query, cancelToken) => {
        return await apiRequest("get", detailCLTN, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchChangeCLTN = async (query, cancelToken) => {
        return await apiRequest("get", changeCLTN, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDokumen = async (query, cancelToken) => {
        return await apiRequest("get", listDokumen, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDetailDokumen = async (query, cancelToken) => {
        return await apiRequest("get", detailDokumen, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchChecklistDokumen = async (query, cancelToken) => {
        return await apiRequest("get", checklistDokumen, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default IndexRepository