import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect";

//import { YearSelect, MonthSelect } from '../../../../../helpers/ui/YearSelect'
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button, Label
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { Link, Redirect } from "react-router-dom"
import apiResponse from '../../../../../services/apiResponse'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import CreateBloc from './Blocs/CreateBloc'


class PayrollCreateView extends Component {

    createBloc = new CreateBloc();
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear()-1 : this.thisDate.getFullYear()
    thisMonth = this.thisDate.getMonth() + 1

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            years: [],
            months: [],
            loadingYear: true,
            loadingMonth: false,
            accessId: localStorage.getItem('currentRoleId'),
            loadingEmployee: true,
            employees: [],
            selectedEmployee: null
        }
    }

    submitData = async () => {
        await this.createBloc.fetchCreate(this.state.dataForm)
    }

    loadPayrollReadyYear = async (uuid) => {
        await this.createBloc.fetchReadyYear({uuid: uuid})
    }

    loadPayrollReadyMonth = async (year) => {
        //alert(year)
        await this.createBloc.fetchReadyMonth({year: year, uuid: this.state.selectedEmployee})
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
              ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    /* listPengajuan = () => {
        let year = this.state.year 
        let month = this.state.month
        let jumlah = this.state.jumlah

        let list = []
        for (let i = 0; i < jumlah; i++) {
            if ((month - i) <= 0) {
                list.push({ year: year - 1, month: 12 + (month - i) })
            } else {
                list.push({ year: year, month: month - i })
            }
        }
        return list
    } */

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.readyYearChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingYear: false,
                        years: response
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.createBloc.readyMonthChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingMonth: false,
                        months: response
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.createBloc.employeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingEmployee: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingEmployee: false,
                        employees: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        
                    });
                    break
                default:
                    break
            }
        })

        if(this.state.accessId === '2') {
            this.createBloc.fetchEmployee()
            this.setState({
                loadingYear: false
            })
        } else {
            this.loadPayrollReadyYear(null)
        }
    }

    componentWillUnmount() {
        this.createBloc.createChannel.unsubscribe()
        this.createBloc.readyYearChannel.unsubscribe()
        this.createBloc.readyMonthChannel.unsubscribe()
        this.createBloc.employeeChannel?.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tambah Pengajuan Slip Gaji</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        {
                                            this.state.accessId === '2' ? 
                                                <Row className="mb-3">
                                                    <Label
                                                        htmlFor="fieldNama"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Nama Pegawai<span className="text-danger">*</span>
                                                    </Label>
                                                    <Col md="6">
                                                        {
                                                            this.state.loadingEmployee?
                                                                <><Shimmer><div className="shimmer" style={{width: '100%', height: 38}}></div></Shimmer>{" "}</>
                                                            :
                                                                <>
                                                                    <CustomSelect 
                                                                        name="uuid"
                                                                        errorMessage="Pilih Pegawai"
                                                                        options={this.state.employees} 
                                                                        placeholder="Pilih Pegawai"
                                                                        optionLabel="employee_name"
                                                                        optionValue="employee_uuid"
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={(value) => {
                                                                            this.setState({
                                                                                years: [],
                                                                                months: [],
                                                                                loadingYear: true,
                                                                                selectedEmployee: value.employee_nip
                                                                            })

                                                                            this.loadPayrollReadyYear(value.employee_nip)
                                                                        }}
                                                                    />
                                                                </>
                                                            }
                                                    </Col>
                                                </Row>
                                            : <></>
                                        }
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Tahun<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                {
                                                    this.state.loadingYear ?
                                                        <Shimmer><div className="shimmer" style={{width: '100%', height: 33}}></div></Shimmer>
                                                    :
                                                        <CustomSelect 
                                                            name="year"
                                                            errorMessage="Pilih Tahun"
                                                            options={this.state.years} 
                                                            placeholder="Pilih Tahun"
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            validate={{ required: { value: true } }}
                                                            onChange={(value) => {
                                                                this.setState({
                                                                    months: [],
                                                                    loadingMonth: true
                                                                })
                                                                this.loadPayrollReadyMonth(value.value)
                                                            }}
                                                        />
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Bulan<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                {
                                                    this.state.loadingMonth ?
                                                        <Shimmer><div className="shimmer" style={{width: '100%', height: 33}}></div></Shimmer>
                                                    :
                                                        <CustomSelect 
                                                            name="month"
                                                            errorMessage="Pilih Bulan"
                                                            options={this.state.months} 
                                                            placeholder="Pilih Bulan"
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                }
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className="mb-3">
                                            <Col md="1"></Col>
                                            <Col md="6">
                                                <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/payroll/sigap.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>


                                    </AvForm>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody style={{height: 100}}>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>


                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/payroll/sigap.html'/>
                : null }

            </Fragment>
        )
    }
}

export default PayrollCreateView