import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import DownloadRepository from '../Repositories/DownloadRepository'


class DownloadBloc {

    detailChannel = new Subject();
    repository = new DownloadRepository()

    constructor() {
        this.detailChannel.next({status: apiResponse.INITIAL})
    }

    fetchDownloadDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDownloadDetail(query).then((result) => {
                this.detailChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
}

export default DownloadBloc