import {
    apiRequest,
    tunkinRegulerDetail,
    tunkinRegulerSsp,
    tunkinRegulerBank,
    tunkinRegulerNrs
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", tunkinRegulerDetail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tunkinRegulerSsp, {
            params: query
        }, true)
    }

    fetchDownloadBank = async (query) => {
        return await apiRequest("get", tunkinRegulerBank, {
            params: query
        }, true)
    }
    
    fetchDownloadNrs = async (query) => {
        return await apiRequest("get", tunkinRegulerNrs, {
            params: query
        }, true)
    }
}

export default DownloadRepository