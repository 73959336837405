import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import Shimmer from "react-shimmer-effect"

import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, Alert, Modal } from "reactstrap"
import { Link, Redirect } from "react-router-dom"
import { ConfirmAlert } from "../../../../../helpers/ui/alert"
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { generateFormData } from "../../../../../helpers/ui/FileInput"
import ResultListComponent from "./Components/ResultListComponent"
import ApproveBloc from "./Blocs/ApproveBloc"
import apiResponse from "../../../../../services/apiResponse"
import LoadingComponent from "./Components/LoadingComponent"

class ApproveView extends Component {
    approveBloc = new ApproveBloc()

    constructor(props) {
        super(props)
        this.state = {
            tunsusStatus: null,
            tunsusStatusName: "",
            uuid: null,
            showReject: false,
            submitType: "",
        }
        this.approveBloc.fetchCheckStatus({ uuid: this.props.uuid, summary: 1 })
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm)
        if (this.state.submitType === "accept") {
            await this.approveBloc.fetchApprove(formData)
        } else if (this.state.submitType === "reject") {
            await this.approveBloc.fetchReject(formData)
        }
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
            },
            submitType: "accept",
            confirm: true,
        })
    }

    handlerOnReject = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
            },
            submitType: "reject",
            confirm: true,
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.approveBloc.approveChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.approveBloc.rejectChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.approveBloc.checkStatusChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    if (response.status === 2) {
                        this.setState({
                            tunsusStatus: response.status,
                            tunsusTotal: response.total ? response.total : "0",
                            tunsusTax: response.tax ? response.tax : "0",
                        })
                    } else {
                        this.setState({
                            tunsusStatusName: "Persetujuan tidak dapat dilakukan",
                        })
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({})
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.approveBloc.checkStatusChannel.unsubscribe()
        this.approveBloc.approveChannel.unsubscribe()
        this.approveBloc.rejectChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Persetujuan Tunjangan Khusus Reguler</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Proses Tunjangan Khusus Reguler berdasarkan tahun dan bulan tertentu sesuai
                                        dengan data integrasi (Simpeg) yang sudah di verifikasi
                                    </CardSubtitle>
                                    <hr />
                                    {this.state.tunsusStatus ? (
                                        <>
                                            <AvForm
                                                className="needs-validation"
                                                onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                            >
                                                <AvField name="uuid" type="hidden" id="uuid" value={this.props.uuid} />
                                                <Row>
                                                    <Col sm={12}>
                                                        {this.props.uuid === null ? (
                                                            <>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "10%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "20%", height: 36 }}
                                                                    ></div>
                                                                </Shimmer>

                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "10%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "35%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                                <Shimmer>
                                                                    <div
                                                                        className="shimmer"
                                                                        style={{ width: "20%", height: 22 }}
                                                                    ></div>
                                                                </Shimmer>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ResultListComponent uuid={this.props.uuid} />
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <label className="col-md-3">Total Tunjangan</label>
                                                    <Col md="9">
                                                        <div className="text-end h5" style={{ width: 180 }}>
                                                            {this.state.tunsusTotal
                                                                .replace(".00", "")
                                                                .toString()
                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label className="col-md-3">Total Pajak</label>
                                                    <Col md="9">
                                                        <div className="text-end h5" style={{ width: 180 }}>
                                                            {this.state.tunsusTax
                                                                .replace(".00", "")
                                                                .toString()
                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <div className="form-check form-switch mb-3">
                                                            <AvCheckboxGroup
                                                                name="checkboxExample"
                                                                required
                                                                errorMessage="Agreement harus di centang"
                                                            >
                                                                <AvCheckbox
                                                                    label="Saya yakin dan bertanggung jawab atas data ini"
                                                                    value="1"
                                                                />
                                                            </AvCheckboxGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md="10">
                                                        <Link to="/payroll/tunsus-reguler.html">
                                                            <Button color="danger" type="button">
                                                                <i className="uil-arrow-left"></i> Kembali
                                                            </Button>
                                                        </Link>{" "}
                                                        <Button color="success" type="submit">
                                                            <i className="uil-check"></i> Setujui Data Tunjangan Khusus
                                                        </Button>{" "}
                                                        <Button
                                                            color="warning"
                                                            type="button"
                                                            onClick={() => {
                                                                this.setState({
                                                                    showReject: true,
                                                                })
                                                            }}
                                                        >
                                                            <i className="uil-times"></i> Tolak Data Tunjangan Khusus
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </>
                                    ) : (
                                        <>
                                            {this.state.tunsusStatusName === "" ? (
                                                <LoadingComponent message="Memeriksa status Tunjangan Khusus..." />
                                            ) : (
                                                <>
                                                    <Row>
                                                        <Col md="12">
                                                            <Alert color="danger">
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="30px"
                                                                            style={{ verticalAlign: "top" }}
                                                                        >
                                                                            <i className="uil uil-times"></i>
                                                                        </td>
                                                                        <td style={{ verticalAlign: "top" }}>
                                                                            {this.state.tunsusStatusName}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            <hr style={{ marginTop: 0 }} />
                                            <Row>
                                                <Col md="10">
                                                    <Link to="/payroll/tunsus-reguler.html">
                                                        <Button color="danger" type="button">
                                                            <i className="uil-arrow-left"></i> Kembali
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"
                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"
                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"
                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
                <Modal
                    size="sm"
                    isOpen={this.state.showReject}
                    toggle={() => {
                        this.setState({
                            showReject: !this.state.showReject,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Alasan Penolakan Data Tunjangan Khusus
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showReject: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <AvForm className="needs-validation" onValidSubmit={(e, values) => this.handlerOnReject(e, values)}>
                        <AvField name="uuid" type="hidden" id="uuid" value={this.props.uuid} />
                        <div className="modal-body">
                            <Col md="12">
                                <AvField
                                    name="reason"
                                    placeholder="Alasan Penolakan"
                                    errorMessage="Masukkan Alasan Penolakan"
                                    type="textarea"
                                    className="form-control"
                                    id="fieldReason"
                                    rows="4"
                                    validate={{ required: { value: true } }}
                                />
                            </Col>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    this.setState({
                                        showReject: false,
                                    })
                                }}
                                className="btn btn-danger waves-effect"
                                data-dismiss="modal"
                            >
                                Batal
                            </button>
                            <button
                                type="submit"
                                onClick={() => {
                                    //submit
                                }}
                                className="btn btn-primary waves-effect waves-light"
                            >
                                Kirim Alasan Menolak
                            </button>
                        </div>
                    </AvForm>
                </Modal>
                {this.state.redirect ? <Redirect to="/payroll/tunsus-reguler.html" /> : null}
            </Fragment>
        )
    }
}

export default ApproveView
