import { accessRole } from "../../../helpers/access_role"
import ErrorAccess from "../../../components/Error/Content/Access"

import PpkIndexView from "./Ppk/Index/View"
import PpkDetailView from "./Ppk/Detail/View"
import PpkCreateView from "./Ppk/Create/View"
import PpkProcessView from "./Ppk/Process/View"

import OtherIndexView from "./Other/Index/View"
import OtherDetailView from "./Other/Detail/View"
import OtherVerifyView from "./Other/Verify/View"
import OtherApproveView from "./Other/Approve/View"

const RouteView = (props) => {
    const queryParams = new URLSearchParams(props.location.search)
    const _create = queryParams.get("create")
    const _process = queryParams.get("process")
    const _detail = queryParams.get("detail")
    const _update = queryParams.get("update")
    const _delete = queryParams.get("detail")
    const _approve = queryParams.get("approve")
    const _print = queryParams.get("print")

    //var ppkUuid = localStorage.getItem('ppkUuid');

    if (localStorage.getItem("ppkUuid") !== "") {
        if (_create != null) {
            const uuid = queryParams.get("uuid")
            return accessRole("create") ? <PpkCreateView uuid={uuid} /> : <ErrorAccess />
        } else if (_process != null) {
            const uuid = queryParams.get("uuid")
            return accessRole("create") ? <PpkProcessView uuid={uuid} /> : <ErrorAccess />
        } else if (_detail != null) {
            const uuid = queryParams.get("uuid")
            return accessRole("read") ? <PpkDetailView uuid={uuid} /> : <ErrorAccess />
        } else if (_print != null) {
            return null
        }

        const tab = queryParams.get("tab")
        return accessRole("read") ? <PpkIndexView tab={tab === "2" ? "2" : "1"} /> : <ErrorAccess />
    } else {
        if (_create != null) {
            const uuid = queryParams.get("uuid")
            return accessRole("create") ? <OtherVerifyView uuid={uuid} /> : <ErrorAccess />
        } else if (_approve != null) {
            const uuid = queryParams.get("uuid")
            return accessRole("approve") ? <OtherApproveView uuid={uuid} /> : <ErrorAccess />
        } else if (_detail != null) {
            const uuid = queryParams.get("uuid")
            return accessRole("read") ? <OtherDetailView uuid={uuid} /> : <ErrorAccess />
        } else if (_print != null) {
            return null
        }

        const tab = queryParams.get("tab")
        return accessRole("read") ? <OtherIndexView tab={tab === "2" ? "2" : "1"} /> : <ErrorAccess />
    }
}
export default RouteView
