import {
    apiRequest,
    tplnT14ProcessSubmit,
    tplnT14ProcessEmployees,
    tplnT14ProcessEmployeeDetail,
    tplnT14GetSigner,
    tplnT14CheckStatus,
    tplnT14CheckKurs,
    tplnT14CheckGpp,
    tplnT14CheckPtkp,
    tplnT14CheckPkp,
    tplnT14CheckCountry,
    tplnT14CheckRank,
    tplnT14CheckEmployeeNew,
    tplnT14CheckEmployeeChange
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", tplnT14ProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", tplnT14ProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tplnT14ProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", tplnT14GetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tplnT14CheckStatus, {
            params: query
        }, true)
    }
    
    fetchCheckKurs = async (query) => {
        return await apiRequest("get", tplnT14CheckKurs, {
            params: query
        }, true)
    }

    fetchCheckGpp = async (query) => {
        return await apiRequest("get", tplnT14CheckGpp, {
            params: query
        }, true)
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest("get", tplnT14CheckPtkp, {
            params: query
        }, true)
    }
    
    fetchCheckPkp = async (query) => {
        return await apiRequest("get", tplnT14CheckPkp, {
            params: query
        }, true)
    }

    fetchCheckCountry = async (query) => {
        return await apiRequest("get", tplnT14CheckCountry, {
            params: query
        }, true)
    }

    fetchCheckRank = async (query) => {
        return await apiRequest("get", tplnT14CheckRank, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", tplnT14CheckEmployeeNew, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", tplnT14CheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository