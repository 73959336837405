import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Link, Redirect } from "react-router-dom"

import { Modal } from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../../services/apiResponse'
import DataTableGradeComponent from './DataTableGradeComponent'

class DataTableValueComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyMasterTunkinClassGrade'
    defaultOrder = 'position_name'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.historyTable = this.historyTable + this.props.type + this.props.grade
        this.state = {
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            type: this.props.type,
            grade: this.props.grade
        }
        await this.dataTableBloc.fetchRowGrade(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.inactiveData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowGradeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.dataTableBloc.rowGradeChannel.unsubscribe()
    }

    _suffixTotal = "posisi jabatan"
    columns = [
        {
            key: "position_name",
            text: "Grade",
            className: "",
            sortable: true,
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
            </>
        )
    }

}

export default DataTableValueComponent