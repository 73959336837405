import {
    apiRequest,
    tplnRegulerDetail,
    tplnRegulerSsp
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchDownloadDetail = async (query) => {
        return await apiRequest("get", tplnRegulerDetail, {
            params: query
        }, true)
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest("get", tplnRegulerSsp, {
            params: query
        }, true)
    }
}

export default DownloadRepository