import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect"
import Select from "react-select"

import { Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Button, Modal } from "reactstrap"

import TunsusIndexListComponent from "./Components/TunsusIndexListComponent"

import IndexViewBloc from "./Blocs/IndexViewBloc"
import apiResponse from "../../../../../services/apiResponse"

class IndexView extends Component {
    indexViewBloc = new IndexViewBloc()
    thisDate = new Date()
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear() - 1 : this.thisDate.getFullYear()

    constructor(props) {
        super(props)
        this.state = {
            showTunsusHistory: false,
            loadingYear: true,
            loadingNrs: true,
            yearExist: true,
            nrsList: [],
            selectedYear: { label: this.thisYear.toString(), value: this.thisYear },
        }
    }

    loadInit = async () => {
        await this.indexViewBloc.fetchYears()
        await this.indexViewBloc.fetchNrss()
    }

    componentDidMount() {
        this.indexViewBloc.yearsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        loadingYear: false,
                        yearExist: response.length > 0,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })

        this.indexViewBloc.nrssChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        nrsList: response,
                        loadingNrs: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.loadInit()
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState({
            selectedYear: itemYear.length > 0 ? itemYear[0] : { label: this.thisYear.toString(), value: this.thisYear },
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear,
                },
            ],
        })
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState(
            {
                selectedYear: selectedYear,
                loadingYear: true,
            },
            () => {
                this.setState({
                    loadingYear: false,
                })
            }
        )
    }

    componentWillUnmount() {
        this.indexViewBloc.yearsChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tunjangan Khusus Reguler</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Proses Tunjangan Khusus Reguler berdasarkan tahun dan bulan tertentu sesuai
                                        dengan data integrasi (Simpeg) yang sudah di verifikasi
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <div className="d-flex flex-row bd-highlight float-start">
                                                <div className="pt-2 pe-2 pb-2 bd-highlight">
                                                    <div style={{ paddingTop: 8 }}>Tahun</div>
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    {this.state.loadingYear ? (
                                                        <>
                                                            <Shimmer>
                                                                <div
                                                                    className="shimmer"
                                                                    style={{ width: 116, height: 36 }}
                                                                ></div>
                                                            </Shimmer>{" "}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div style={{ width: 116 }}>
                                                                <Select
                                                                    value={this.state.selectedYear}
                                                                    onChange={(selected) => {
                                                                        this.handleOnChangeYear(selected)
                                                                    }}
                                                                    options={this.state.optionYear}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                {!this.state.yearExist && (
                                                    <div className="p-2 bd-highlight">
                                                        <Button
                                                            color="warning"
                                                            style={{ padding: "6px 10px" }}
                                                            onClick={() => {
                                                                this.setState(
                                                                    {
                                                                        yearExist: true,
                                                                        loadingNrs: true,
                                                                        loadingYear: true,
                                                                    },
                                                                    async () => {
                                                                        await this.indexViewBloc.fetchYears()
                                                                        await this.indexViewBloc.fetchNrss()
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <i className="uil-refresh"></i>
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{ paddingTop: 8 }}>
                                                    <div
                                                        className="bg-success rounded-circle d-inline-block"
                                                        style={{ height: 10, width: 10 }}
                                                    ></div>{" "}
                                                    Sudah Integrasi
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{ paddingTop: 8 }}>
                                                    <div
                                                        className="bg-danger rounded-circle d-inline-block"
                                                        style={{ height: 10, width: 10 }}
                                                    ></div>{" "}
                                                    Belum Integrasi
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={12}>
                                            {this.state.loadingYear || this.state.loadingNrs ? (
                                                <>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "10%", height: 36 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "35%", height: 36 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "35%", height: 36 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "20%", height: 36 }}
                                                        ></div>
                                                    </Shimmer>

                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "10%", height: 22 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "35%", height: 22 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "35%", height: 22 }}
                                                        ></div>
                                                    </Shimmer>
                                                    <Shimmer>
                                                        <div
                                                            className="shimmer"
                                                            style={{ width: "20%", height: 22 }}
                                                        ></div>
                                                    </Shimmer>
                                                </>
                                            ) : (
                                                <>
                                                    <TunsusIndexListComponent
                                                        year={this.state.selectedYear.value}
                                                        nrsList={this.state.nrsList}
                                                    />
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView
