import {
    apiRequest,
    masterTunkinTplnRegulationCreate,
    masterTunkinTplnMasterRank
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterTunkinTplnRegulationCreate, {
            body: query
        }, true)
    }

    fetchRankList = async () => {
        return await apiRequest("get", masterTunkinTplnMasterRank, {}, true)
    }

}

export default CreateRepository