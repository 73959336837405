import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip, Modal } from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../../services/adapters/base"

import ApproveBloc from '../Blocs/ApproveBloc'
import apiResponse from '../../../../../../services/apiResponse'

import DetailEmployeeComponent from "./DetailEmployeeComponent"


import moment from 'moment'

class ResultListComponent extends Component {
    approveBloc = new ApproveBloc();

    source
    historyTable = 'historyPayrollTplnRapelResultRegulerList'
    defaultOrder = 'tunkin_result_employee_name'
    defaultSize = 10
    defaultSort = 'asc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.historyTable = this.historyTable + this.props.uuid
        this.state = {
            showDetail: false,
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            uuid: this.props.uuid
        }
        await this.approveBloc.fetchResultList(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.approveBloc.listResultChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

    }

    componentWillUnmount() {
        this.approveBloc.listResultChannel.unsubscribe()
        this.approveBloc.checkStatusChannel.unsubscribe()
    }

    columns = [
        {
            key: "tunkin_result_region_name",
            text: "Negara",
            className: "",
            sortable: true,
            width: '180'
        },
        {
            key: "tunkin_result_employee_nip",
            text: "NIP",
            className: "",
            align: 'center',
            sortable: true,
            width: '100'
        },
        {
            key: "tunkin_result_employee_name",
            text: "Nama Pegawai",
            className: "",
            sortable: true,
        },
        {
            key: "tunkin_result_last_total",
            text: "Sebelumnya",
            className: "",
            sortable: true,
            align: 'center',
            width: '160',
            cell: record => {
                var textColor = record.tunkin_result_last_total > 0 ? (record.tunkin_result_total > record.tunkin_result_last_total ? "text-danger" : (record.tunkin_result_total !== record.tunkin_result_last_total ? "text-primary" : "text-normal")) : ""
                return (
                    <>
                        <div className={"text-end " + textColor + (textColor !== "" ? " fw-bold" : "")}>
                            { record.tunkin_result_last_total > 0 ?
                                <>
                                { record.tunkin_result_last_total.toString().replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                                </>
                                : '-'
                            }
                        </div>
                    </>
                )
            }
        },
        {
            key: "tunkin_result_total",
            text: "Jumlah Bersih",
            className: "",
            sortable: true,
            align: 'center',
            width: '160',
            cell: record => {
                var textColor = record.tunkin_result_last_total > 0 ? (record.tunkin_result_total > record.tunkin_result_last_total ? "text-danger" : (record.tunkin_result_total !== record.tunkin_result_last_total ? "text-primary" : "text-normal")) : ""
                return (
                    <>
                        <div className={"text-end " + textColor + (textColor !== "" ? " fw-bold" : "")}>
                            { record.tunkin_result_total.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                        </div>
                    </>
                )
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 70,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisId = record.tunkin_result_tunkin_employee_uuid
                return (
                    <Fragment>
                        <span>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                id={"detail-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        showDetail: true,
                                        detailUuid: record.tunkin_result_tunkin_employee_uuid
                                    })
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-file-search-alt"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["detail" + thisId] : !this.state["detail" + thisId]
                                    })
                                }}
                            >Lihat Detail</Tooltip>
                        </span>
                    </Fragment>
                );
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={900}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Detail Pegawai
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <DetailEmployeeComponent uuid={this.state.detailUuid} />
                    </div>
                </Modal>
            </>
        )
    }

}

export default ResultListComponent