import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Modal
} from "reactstrap"
import * as XLSX from 'xlsx';
import Dropzone from 'react-dropzone'
import Shimmer from "react-shimmer-effect";
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import { FileInput, generateFormData } from '../../../../../helpers/ui/FileInput'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import { Link, Redirect } from "react-router-dom"
import apiResponse from '../../../../../services/apiResponse'
import CreateBloc from './Blocs/CreateBloc'


class CreateView extends Component {

    createBloc = new CreateBloc()
    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            loadingCountry: true,
            agreementChecked: false,
            kategory: [
                { label: "Daily", value: "1" },
                { label: "Hotel", value: "2" },
                { label: "Transport", value: "3" },
                { label: "Representative", value: "4" },
                { label: "Other", value: "5" }
            ],
            type: [
                { label: "Biasa", value: "1" },
                { label: "Fullday", value: "2" },
                { label: "Fullboard", value: "3" },
                { label: "Rill", value: "4" }
            ],
            rentangOptions: [
                {
                    "value":"A",
                    "label":"A"
                },
                {
                    "value":"B",
                    "label":"B"
                },
                {
                    "value":"C",
                    "label":"C"
                }
            ],
            rentangSelected: "A",
            fileData: [],
            status: 0, //0.Initial, 1.Loading file, 2.Data
            statusData: 0, //0.Empty, 1.Data OK, 2.Data Problem
            fileName: '',
        }
    }
    // submitData = async () => {
    //     await this.createBloc.fetchCreate(this.state.dataForm)
    // }

    resetAllForm = () => {
        this.setState({
            status: 0,
            statusData: 0,
            fileName: '',
            fileData: []
        })
        this.loadEmployee()
    }

    handlerOnSubmit = (e, values) => {
        var fileDataExist = this.state.fileData
        if (fileDataExist.length > 0) {
            var _diatas = []
            var _sampai = []
            var _tarik = []
            for (var i = 0; i < fileDataExist.length; ++i) {
                _diatas[i] = fileDataExist[i].diatas
                _sampai[i] = fileDataExist[i].sampai
                _tarik[i] = fileDataExist[i].tarik
            }
            this.setState({
                dataForm: {
                    selected_group: this.state.rentangSelected,
                    range_start: _diatas,
                    range_end: _sampai,
                    range_percent_value: _tarik
                },
                confirm: true
            })
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, { field: 'document', name: 'Trash/Unknown' })
        await this.createBloc.fetchCreate(formData)
    }

    handleOnSelectFile = (file) => {
        var f = file[0]
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 2 });

            var statusData = 0
            var _fileDatas = []
            if (data.length > 0) {
                var _fileData = {}
                statusData = 1
                for (var i = 0; i < data.length; ++i) {
                    _fileData = {
                        diatas: data[i].Diatas,
                        sampai: data[i].Sampai, 
                        tarik: data[i]['Tarik Efektif Rata'],
                    }
                    _fileDatas.push(_fileData)
                }
            }
            this.setState({
                status: 2,
                fileData: _fileDatas,
                statusData: statusData //0.Empty, 1.Data OK, 2.Data Problem
            })
        };
        reader.readAsBinaryString(f);

        this.setState({
            status: 1,
            fileName: file[0].name
        })
    }

    deleteRow = (key) => {
        var fileDataExist = this.state.fileData
        var statusData = 0
        var _fileDatas = []
        if (fileDataExist.length > 0) {
            var _fileData = {}
            statusData = 1
            for (var i = 0; i < fileDataExist.length; ++i) {
                if (i !== key) {
                    _fileData = fileDataExist[i]
                    _fileDatas.push(_fileData)
                }
            }
        }
        this.setState({
            fileData: _fileDatas,
            statusData: statusData
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    confirmResponse2 = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm2: false
                }
                break;
            case 'confirm':
                forState = {
                    confirm2: false
                }
                break;
            case 'success':
                forState = {
                    success2: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed2: false
                }
                break;
            default:
        }
        this.setState(forState)
    }


    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
    }

    handleKeyPress = (event) => {
        // Hapus batasan panjang teks
        if (event.which !== 44) {
            if (event.which !== 8 && isNaN(String.fromCharCode(event.which))) {
                event.preventDefault();
            }
        }
    }
    
    handleKeyUp = (event, id) => {
        const _this = document.querySelector('#' + id)
        var value = event.target.value
        value = value.charAt(0) === '0' ? value.substring(1) : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    }


    handleAgreementChange = (event) => {
        this.setState({ agreementChecked: event.target.checked });
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Tambah Informasi Data Referensi PTKP</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-2 col-form-label"
                                            >
                                                Grup<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                {
                                                    <CustomSelect
                                                        name="selected_group"
                                                        errorMessage="Pilih Grup"
                                                        options={this.state.rentangOptions}
                                                        // defaultValue={this.state.rentangSelected}
                                                        placeholder="Pilih Grup"
                                                        validate={{ required: { value: true } }}
                                                        onChange={(v) => {
                                                            this.setState({
                                                                rentangSelected: v.value,
                                                            })
                                                        }}
                                                    />
                                                }
                                            </Col>
                                        </Row>

                                        {/* <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Diatas<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Rp.</span>
                                                    </div>
                                                    <AvInput
                                                        name="range_start"
                                                        placeholder="0"
                                                        align="right"
                                                        type="text"
                                                        errorMessage="Masukkan Nilai"
                                                        className="form-control text-end"
                                                        id="fieldValue1"
                                                        validate={{ required: { value: true } }}
                                                        onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue1") }}
                                                        onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue1") }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Sampai<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Rp.</span>
                                                    </div>
                                                    <AvInput
                                                        name="range_end"
                                                        placeholder="0"
                                                        align="right"
                                                        type="text"
                                                        errorMessage="Masukkan Nilai"
                                                        className="form-control text-end"
                                                        id="fieldValue2"
                                                        validate={{ required: { value: true } }}
                                                        onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue2") }}
                                                        onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue2") }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Tarik Efektif Rata<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <div class="input-group">
                                                    <AvInput
                                                        name="range_percent_value"
                                                        placeholder="0"
                                                        align="right"
                                                        type="number"
                                                        step="0.01"
                                                        errorMessage="Masukkan Nilai"
                                                        className="form-control text-end"
                                                        id="fieldValue3"
                                                        validate={{ required: { value: true } }}
                                                        // onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue3") }}
                                                        // onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue3") }}
                                                    />
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row> */}

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-2 col-form-label"
                                            >
                                                Unggah File<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="10">
                                                {
                                                    this.state.loadingEmployee ?
                                                        <><Shimmer><div className="shimmer" style={{ width: 316, height: 36 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <>
                                                            <div className="bd-highlight float-start">
                                                                <Dropzone onDrop={acceptedFiles => this.handleOnSelectFile(acceptedFiles)}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <section>
                                                                            <div {...getRootProps()}>
                                                                                <input {...getInputProps()} multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                                                                <span
                                                                                    className={"btn " + (this.state.fileName ? "btn-danger" : "btn-secondary") + " btn-sm"}
                                                                                    style={{
                                                                                        padding: '4px 7px'
                                                                                    }}
                                                                                >
                                                                                    <i className="uil-file"></i>
                                                                                </span> {this.state.fileName !== '' ? this.state.fileName : 'Pilih File Excel'}
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                            <div className="bd-highlight float-end">
                                                                <span className={"btn btn-link btn-sm"} onClick={() => {
                                                                    window.open(process.env.REACT_APP_SERVICE_PROCESS_URL + "/templates/Template-PTKP2024.xlsx", "_blank")
                                                                }} style={{ padding: '2px 0px' }}>Download Template</span>
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label
                                                className="col-md-2 col-form-label"
                                            >
                                                Data Pegawai <span className="text-danger">*</span>
                                            </Label>
                                            <Col md="10">
                                                <Table
                                                    className="table table-striped table-bordered table-hover"
                                                >
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="40px" data-priority="1" className="text-end">No.</Th>
                                                            <Th width="180px" data-priority="1" className="text-end">Diatas</Th>
                                                            <Th width="180px" data-priority="1" className="text-end">Sampai</Th>
                                                            <Th width="180px" data-priority="1" className="text-end">Tarik Efektif Rata</Th>
                                                            <Th width="90px" data-priority="1" className="text-center">Aksi</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.status === 0 ?
                                                            <tr>
                                                                <Td colspan="5" className="text-center">
                                                                    Silahkan pilih file terlebih dahulu
                                                                </Td>
                                                            </tr>
                                                            :
                                                            <>
                                                                {this.state.status === 1 ?
                                                                    <tr>
                                                                        <Td style={{ paddingTop: 14, paddingBottom: 0 }}>
                                                                            <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                                                        </Td>
                                                                        <Td style={{ paddingTop: 14, paddingBottom: 0 }}>
                                                                            <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                                                        </Td>
                                                                        <Td style={{ paddingTop: 14, paddingBottom: 0 }}>
                                                                            <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                                                        </Td>
                                                                        <Td style={{ paddingTop: 14, paddingBottom: 0 }}>
                                                                            <Shimmer><div className="shimmer" style={{ width: '100%', height: 15 }}></div></Shimmer>
                                                                        </Td>
                                                                        <Td className="text-center">
                                                                            <Shimmer><div className="shimmer" style={{ width: 22, height: 22 }}></div></Shimmer>
                                                                        </Td>
                                                                    </tr>
                                                                    :
                                                                    <>
                                                                        {this.state.statusData === 0 ?
                                                                            <tr>
                                                                                <Td colspan="5" className="text-center">
                                                                                    Data pegawai PTSP tidak ditemukan
                                                                                </Td>
                                                                            </tr>
                                                                            :
                                                                            <>
                                                                                {Object.entries(this.state.fileData).map((item, key) => {
                                                                                    var color = item[1].problem ? '#dd0000' : '#495057'
                                                                                    var weight = item[1].problem ? 'bold' : 'normal'
                                                                                    return (
                                                                                        <tr key={key}>
                                                                                            <td style={{ color: color, fontWeight: weight }} className="text-end">{key + 1}</td>
                                                                                            <td style={{ color: color, fontWeight: weight }} className="text-end">{item[1].diatas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                                                            <td style={{ color: color, fontWeight: weight }} className="text-end">{item[1].sampai.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                                                            <td style={{ color: color, fontWeight: weight }} className="text-end">{item[1].tarik.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                                                            <td style={{ color: color, fontWeight: weight }} className="text-center">
                                                                                                {item[1].problem ?
                                                                                                    <span
                                                                                                        className="btn btn-danger btn-sm"
                                                                                                        style={{
                                                                                                            padding: '2px 5px'
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            this.deleteRow(key)
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="uil-trash-alt"></i>
                                                                                                    </span>
                                                                                                    :
                                                                                                    <></>
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }

                                                    </Tbody>
                                                </Table>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" onChange={this.handleAgreementChange} />
                                                    </AvCheckboxGroup>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <Button color="success" disabled={!this.state.agreementChecked} id="btnsubmit" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/master/pajak-ptkp2024.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />

                <ConfirmAlert
                    failedTitle="Gagal!"
                    failedInfo="Data sudah tersedia"

                    showFailed={this.state.failed2}
                    showConfirm={this.state.confirm2}
                    response={this.confirmResponse2}
                />
                {this.state.redirect ?
                    <Redirect to='/master/pajak-ptkp2024.html' />
                    : null}
            </Fragment>
        )
    }

}

export default CreateView