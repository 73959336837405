import { Subject } from "rxjs"
import apiResponse from "../../../../../../services/apiResponse"
import IndexViewRepository from "../Repositories/IndexViewRepository"

class IndexViewBloc {
    rowDataManualChannel = new Subject()
    rowDataEmployeeChannel = new Subject()

    repository = new IndexViewRepository()

    constructor() {
        this.rowDataManualChannel.next({ status: apiResponse.INITIAL })
        this.rowDataEmployeeChannel.next({ status: apiResponse.INITIAL })
    }

    fetchRowTableManual = async (query, cancelToken) => {
        this.rowDataManualChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchRowTableManual(query, cancelToken)
                .then((result) => {
                    this.rowDataManualChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.rowDataManualChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchRowTableEmployee = async (query, cancelToken) => {
        this.rowDataEmployeeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchRowTableEmployee(query, cancelToken)
                .then((result) => {
                    this.rowDataEmployeeChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.rowDataEmployeeChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }
}

export default IndexViewBloc
