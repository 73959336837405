import React, { Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    Button
} from "reactstrap"
import { Preview } from '../../../../../../helpers/ui/FileInput'

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../../services/apiResponse'
import moment from 'moment'
import CustomSelect from '../../../../../../helpers/ui/CustomSelect';
import {AvField} from "availity-reactstrap-validation"


class TableUpdateComponent extends Component {

    dataTableBloc = new DataTableBloc()

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.dataTableBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.dataTableBloc.fetchDetail({uuid: this.props.uuid })
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Kode
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detail ?
                                            <>{this.state.detail.region_code !== "" ? this.state.detail.region_code : '-'}</>
                                        : <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <label className="col-md-3">
                                    Negara
                                </label>
                                <Col md="9">
                                {
                                    this.state.loadingCountry?
                                        <><Shimmer><div className="shimmer" style={{width: '100%', height: 38}}></div></Shimmer>{" "}</>
                                    :
                                        <>
                                            <CustomSelect 
                                                name="region_uuid"
                                                errorMessage="Pilih Negara"
                                                options={this.state.countries} 
                                                placeholder="Pilih Negara"
                                                optionLabel="region_name"
                                                optionValue="region_uuid"
                                                validate={{ required: { value: true } }}
                                            />
                                        </>
                                    }
                                </Col>
                            </Row>
                            {/* <Row className="mb-3">
                                <label className="col-md-3">
                                    Nilai
                                </label>
                                <Col md="9">
                                <AvField
                                        name="official_travel_oversea_value_amount"
                                        placeholder="0"
                                        align="right"
                                        type="text"
                                        errorMessage="Masukkan Nilai"
                                        className="form-control text-end"
                                        id="fieldValue"
                                        validate={{ required: { value: true } }}
                                        onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue") }}
                                        onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue") }}
                                        value={this.state.detail.official_travel_oversea_value_amount}
                                    />
                                </Col>
                            </Row>
                           */}
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default TableUpdateComponent;