import {
    apiRequest,
    officialTravelSubmissionDetail,
    officialTravelSubmissionParticipant
} from "../../../../../../services/adapters/process"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", officialTravelSubmissionDetail, {
            params: query
        }, true)
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelSubmissionParticipant, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DetailRepository
