import { Subject } from "rxjs"
import apiResponse from "../../../../../../services/apiResponse"
import DownloadRepository from "../Repositories/DownloadRepository"

class DownloadBloc {
    gajiChannel = new Subject()
    repository = new DownloadRepository()

    constructor() {
        this.gajiChannel.next({ status: apiResponse.INITIAL })
    }
    fetchGaji = async (query) => {
        this.gajiChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchGaji(query)
                .then((result) => {
                    this.gajiChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.gajiChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }
}

export default DownloadBloc
