import React, { Component, Fragment } from "react"
import { config } from "../../../../../helpers/config_global"
import * as XLSX from 'xlsx';
import Dropzone from "react-dropzone"
import loadingIcon from "../../../../../assets/images/loading.gif"
import {
    UncontrolledAlert,
    Button,
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Label
} from "reactstrap"
import Shimmer from 'react-shimmer-effect'
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table"
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { decode, encode } from 'base-64'

import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'

import CreateViewBloc from './Blocs/CreateViewBloc'
import apiResponse from '../../../../../services/apiResponse'
import { Link, Redirect } from "react-router-dom"

class CreateView extends Component {
    createViewBloc = new CreateViewBloc();

    historyTable = 'xxxxxx'
    defaultOrder = 'mapping_nip_employee_name'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    columns = [
        {key: 'nip', text: 'nip', sortable: true, required: true, align: 'center', width: 220},
        {key: 'nmpeg', text: 'nmpeg', sortable: true, required: false, align: 'left', width: 260},
        {key: 'nogaji', text: 'nogaji', sortable: true, required: true, align: 'center', width: 130},
        {key: 'kdjns', text: 'kdjns', sortable: true, required: true, align: 'center', width: 130},
        {key: 'kdsatker', text: 'kdsatker', sortable: true, required: true, align: 'center', width: 130},
        {key: 'kdanak', text: 'kdanak', sortable: true, required: true, align: 'center', width: 130},
        {key: 'kdsubanak', text: 'kdsubanak', sortable: true, required: false, align: 'center', width: 150},
        {key: 'bulan', text: 'bulan', sortable: true, required: true, align: 'center', width: 130},
        {key: 'tahun', text: 'tahun', sortable: true, required: true, align: 'center', width: 130},
        {key: 'kdduduk', text: 'kdduduk', sortable: true, required: true, align: 'center', width: 120},
        {key: 'kdgapok', text: 'kdgapok', sortable: true, required: true, align: 'center', width: 120,},
        {key: 'kdjab', text: 'kdjab', sortable: true, required: true, align: 'center', width: 120},
        {key: 'gjpokok', text: 'gjpokok', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.gjpokok).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'tjistri', text: 'tjistri', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.tjistri).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'tjanak', text: 'tjanak', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.tjanak).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'tjupns', text: 'tjupns', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.tjupns).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'tjstruk', text: 'tjstruk', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.tjstruk).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'tjfungs', text: 'tjfungs', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.tjfungs).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'pembul', text: 'pembul', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.pembul).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'tjberas', text: 'tjberas', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.tjberas).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'tjpph', text: 'tjpph', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.tjpph).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'tjdaerah', text: 'tjdaerah', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.tjdaerah).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'tjpencil', text: 'tjpencil', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.tjpencil).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'tjlain', text: 'tjlain', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.tjlain).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'potpfk10', text: 'potpfk10', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.potpfk10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'potpph', text: 'potpph', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.potpph).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'potswrum', text: 'potswrum', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.potswrum).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'potkelbtj', text: 'potkelbtj', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.potkelbtj).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'potlain', text: 'potlain', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.potlain).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'pottabrum', text: 'pottabrum', sortable: true, required: true, align: 'center', width: 120, cell: record => <div className="text-end">{ (record.pottabrum).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'kdgol', text: 'kdgol', sortable: true, required: true, align: 'center', width: 100},
        {key: 'bpjs', text: 'bpjs', sortable: true, required: true, align: 'center', width: 100, cell: record => <div className="text-end">{ (record.bpjs).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'bpjs2', text: 'bpjs2', sortable: true, required: true, align: 'center', width: 100, cell: record => <div className="text-end">{ (record.bpjs2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</div> },
        {key: 'npwp', text: 'npwp', sortable: true, required: true, align: 'center', width: 220},
        {key: 'kdbankspan', text: 'kdbankspan', sortable: true, required: true, align: 'center', width: 180},
        {key: 'nmbankspan', text: 'nmbankspan', sortable: true, required: true, align: 'left', width: 220},
        {key: 'nm_bank', text: 'nm_bank', sortable: true, required: true, align: 'left', width: '*'},
        {key: 'rekening', text: 'rekening', sortable: true, required: true, align: 'center', width: 220},
        {key: 'nmrek', text: 'nmrek', sortable: true, required: true, align: 'left', width: 220},
    ]

    monthName = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']


    constructor(props) {
        super(props)
        this.state = {
            page: 'form',
            errorText: '',
            dataYear: null,
            dataMonth: null,

            dataGroup: [],
            dataGroupTotal: 0,
            dataGroupUploaded: 0,

            // datatable
            rows: [],
            totalRow: 0,
            loadingTable: false,
            filter: {
                filter_value: "",
                page_number: 1,
                page_size: this.defaultSize,
                sort_order: {
                    column: this.defaultOrder,
                    order: this.defaultSort
                }
            }
        }
    }

    handleAcceptedFiles = (files) => {
        this.setState({
            page: 'loading',
            errorText: ''
        })
        var f = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary', cellDates: true, cellNF: false, cellText: false });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'yyyy-mm-dd' })
            this.validatingData(dataParse)
        }
        reader.readAsBinaryString(f)
    }

    validatingData = (data) => {
        if (data.length > 0) {
            const headers = data.shift()
            let dataValid = true
            let textInvalid = ''
            let columnIndex = []

            for(var e = 0; e < this.columns.length; ++e) {
                const item = this.columns[e]
                const index = headers.indexOf(item.key)
                if(index < 0 && item.required) {
                    dataValid = false
                    textInvalid = textInvalid + '<b>header:</b> ' + item.key + ' tidak ditemukan<br/>'
                }
                columnIndex.push(index)
            }
            if(dataValid) {
                let datas = []
                for(var r = 0; r < data.length; ++r) {
                    const item = data[r]
                    let rows = {}
                    for(var c = 0; c < columnIndex.length; ++c) {
                        const col = columnIndex[c]
                        if(col >= 0) {
                            let colValue = item[col]
                            if (typeof colValue === 'string') {
                                colValue = colValue.trim();
                            }
                            colValue = colValue === '' ? null : colValue
                            if(this.columns[c].required && colValue === null) {
                                dataValid = false;
                                textInvalid = textInvalid + '<b>data:</b> ' + this.columns[c].key + ' pada row ke-' + (r+2) + ' kosong<br/>'
                            }
                            rows[this.columns[c].key] = colValue === '' ? null : colValue
                        } else {
                            rows[this.columns[c].key] = null
                        }
                    }
                    datas.push(rows)
                }
                if(dataValid && datas.length > 0) {

                    let dataGroupItem = []
                    const perGroup = 50
                    var dataUploadStep = Math.ceil(datas.length / perGroup)
                    
                    for (var s = 0; s < dataUploadStep; ++s) {
                        var dataItems = []

                        var start = (perGroup * s)
                        var end = (start + perGroup) - 1;
                        end = end < datas.length ? end : (datas.length - 1);
                        for (var i = start; i <= end; ++i) {
                            dataItems.push(datas[i])
                        }

                        dataGroupItem.push(dataItems)
                    }

                    this.setState({
                        page: 'table',
                        rows: datas,
                        totalRow: datas.length,
                        dataYear: parseInt(datas[0].tahun),
                        dataMonth: parseInt(datas[0].bulan),
                        dataGroup: dataGroupItem,
                        dataGroupTotal: dataUploadStep + 2,
                        dataGroupUploaded: 0
                    })
                } else {
                    this.setState({
                        page: 'form',
                        errorText: textInvalid
                    })
                }

            } else {
                this.setState({
                    page: 'form',
                    errorText: textInvalid
                })
            }
        } else {
            this.setState({
                page: 'form',
                errorText: 'File tidak diizinkan'
            })
        }
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false,
                    uploadText: "Mengunggah... (" + (this.state.dataGroupUploaded + 1) + "/" + this.state.dataGroupTotal + ") tahap",
                }
                this.doUpload()
                break;
            case 'success':
                forState = {
                    success: false,
                    //redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    doStartUpload = () => {
        this.setState({
            confirm: true
        })
    }

    doUpload = () => {
        if (this.state.dataGroupUploaded === 0) { //initial
            this.createViewBloc.fetchUpload({ year: this.state.dataYear, month: this.state.dataMonth, type: 'initial' })
        } else if (this.state.dataGroupUploaded === this.state.dataGroupTotal - 1) { //finishing
            this.createViewBloc.fetchUpload({ year: this.state.dataYear, month: this.state.dataMonth, type: 'finishing' })
        } else { //upload
            var _data = encode(JSON.stringify(this.state.dataGroup[this.state.dataGroupUploaded - 1]))
            this.createViewBloc.fetchUpload({ year: this.state.dataYear, month: this.state.dataMonth, type: 'upload', data: _data })
        }
    }

    componentDidMount() {
        this.createViewBloc.uploadChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        dataGroupUploaded: this.state.dataGroupUploaded + 1,
                    }, function () {
                        if (this.state.dataGroupUploaded === this.state.dataGroupTotal) {
                            this.setState({
                                loading: false,
                                success: true,
                            });
                            setTimeout(() => {
                                this.setState({
                                    redirect: true
                                });
                            }, 2000)
                        } else {
                            this.setState({
                                uploadText: "Mengunggah... (" + (this.state.dataGroupUploaded + 1) + "/" + this.state.dataGroupTotal + ") tahap",
                            });
                            this.doUpload()
                        }
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                    });
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.createViewBloc.uploadChannel.unsubscribe()
    }

    render() {
        return (
                <Fragment>
                    <Container fluid>
                        <Row>
                            <Col className="col-12">
                                <Card className="pegawai-rightbar">
                                    <CardBody>
                                        <CardTitle>Upload data Slip Gaji</CardTitle>
                                        <CardSubtitle className="mb-3">
                                            Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                        </CardSubtitle>
                                        <hr/>
                                        { this.state.page === 'form' ?
                                            <>
                                                <Row className="mb-3">
                                                    <Label
                                                        htmlFor="fieldNama"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Pilih File Gaji Web<span className="text-danger">*</span>
                                                    </Label>
                                                    <Col md="6">
                                                        <Dropzone
                                                            maxFiles={1}
                                                            onDrop={acceptedFiles => {
                                                                this.handleAcceptedFiles(acceptedFiles)
                                                            }}
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div className="dropzone">
                                                                    <div
                                                                        className="dz-message needsclick"
                                                                        {...getRootProps()}
                                                                    >
                                                                        <input {...getInputProps()} multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                                                        <div className="mb-3">
                                                                            <i className="display-4 text-muted uil uil-cloud-upload" />
                                                                        </div>
                                                                        <h4>Drop files here or click to upload.</h4>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                        {this.state.errorText &&
                                                            <div className="invalid-feedback" style={{display: 'block'}} dangerouslySetInnerHTML={{__html: this.state.errorText}}></div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <hr/>
                                                <Row className="mb-3">
                                                    <Col md="10" className="offset-md-2">
                                                        {
                                                            (this.state.page !== 'loading' && this.state.page !== 'form') && 
                                                            <>
                                                                <Button color="success" type="submit">
                                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                                </Button>
                                                                {' '}
                                                                <Button color="primary" onClick={() => { this.setState({page: 'form'}) }}>
                                                                    <i className="uil-refresh"></i> Gunakan File Lain
                                                                </Button>
                                                                {' '}
                                                            </>
                                                        }
                                                        <Link to="/integration/sigap-gaji.html">
                                                            <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                { this.state.page === 'loading' ?
                                                    <>                                                                
                                                        <Row className="mb-3">
                                                            <Label
                                                                htmlFor="fieldNama"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Pilih File Gaji Web<span className="text-danger">*</span>
                                                            </Label>
                                                            <Col md="6">
                                                                <Shimmer><div className="shimmer" style={{ width: '100%', height: 230 }}></div></Shimmer>
                                                                Memeriksa file...
                                                            </Col>
                                                        </Row>
                                                        <hr/>
                                                        <Row className="mb-3">
                                                            <Col md="10" className="offset-md-2">
                                                                {
                                                                    (this.state.page !== 'loading' && this.state.page !== 'form') && 
                                                                    <>
                                                                        <Button color="success" type="submit">
                                                                            <i className="uil-arrow-up-right"></i> Simpan Data
                                                                        </Button>
                                                                        {' '}
                                                                        <Button color="primary" onClick={() => { this.setState({page: 'form'}) }}>
                                                                            <i className="uil-refresh"></i> Gunakan File Lain
                                                                        </Button>
                                                                        {' '}
                                                                    </>
                                                                }
                                                                <Link to="/integration/sigap-gaji.html">
                                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    :
                                                    <>
                                                        <Row className="mb-3">
                                                            <Col sm={12}>
                                                                <MKIDatatable
                                                                    withNumber={false}
                                                                    className={this.configDatatable.tableClass}
                                                                    config={this.configDatatable}
                                                                    records={this.state.rows}
                                                                    columns={this.columns}
                                                                    dynamic={false}
                                                                    minWidth={5500}
                                                                    total_record={this.state.totalRow}
                                                                    initial={this.state.filter}
                                                                    onChange={(filter) => {
                                                                        this.setState({
                                                                            //loadingTable: true,
                                                                            filter: filter
                                                                        }, function () {
                                                                            //this.setTokenAPI()
                                                                        });
                                                                    }}
                                                                    loading={this.state.loadingTable}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }
                                            </>
                                        }
                                    </CardBody>
                                </Card>
                                {
                                    (this.state.page !== 'loading' && this.state.page !== 'form') && 
                                    <>
                                        <Card className="pegawai-rightbar">
                                            <CardBody className="mt-3">
                                                <AvForm
                                                    className="needs-validation"
                                                    onValidSubmit={(e, values) => this.doStartUpload()}
                                                >
                                                    <Row className="mb-3">
                                                        <Label
                                                            htmlFor="fieldNama"
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Total Data
                                                        </Label>
                                                        <Col md="10" className="col-form-label">
                                                            {this.state.totalRow}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Label
                                                            htmlFor="fieldNama"
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Tahun
                                                        </Label>
                                                        <Col md="10" className="col-form-label">
                                                            {this.state.dataYear}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-5">
                                                        <Label
                                                            htmlFor="fieldNama"
                                                            className="col-md-2 col-form-label"
                                                        >
                                                            Bulan
                                                        </Label>
                                                        <Col md="10" className="col-form-label">
                                                            {this.monthName[this.state.dataMonth-1]}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col md="2"></Col>
                                                        <Col md="10">
                                                            <div className="form-check form-switch mb-3">
                                                            <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                                <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                            </AvCheckboxGroup>
                                                            
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col md="10" className="offset-md-2">
                                                            <Button color="success" type="submit">
                                                                <i className="uil-arrow-up-right"></i> Simpan Data
                                                            </Button>
                                                            {' '}
                                                            <Button color="primary" onClick={() => { this.setState({page: 'form'}) }}>
                                                                <i className="uil-refresh"></i> Gunakan File Lain
                                                            </Button>
                                                            {' '}
                                                            <Link to="/integration/sigap-gaji.html">
                                                                <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </CardBody>
                                        </Card>
                                    </>
                                }
                            </Col>
                        </Row>
                    </Container>
                    <ConfirmAlert
                        confirmTitle="Konfirmasi!"
                        confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                        loadingTitle="Mengirim data..."
                        loadingInfo={this.state.uploadText}

                        successTitle="Berhasil!"
                        successInfo="Data berhasil disimpan"

                        failedTitle="Gagal!"
                        failedInfo="Data gagal disimpan"

                        showConfirm={this.state.confirm}
                        showLoading={this.state.loading}
                        showSuccess={this.state.success}
                        showFailed={this.state.failed}

                        response={this.confirmResponse}
                    />
                    {this.state.redirect && <Redirect to='/integration/sigap-gaji.html' />}
                </Fragment>
        )
    }

}

export default CreateView