import React, { useState, Component, Fragment } from "react"
import SimpleBar from "simplebar-react"
import Shimmer from "react-shimmer-effect"
import moment from "moment"
import { Row, Col, Table } from "reactstrap"

import CreateBloc from "../Blocs/CreateBloc"
import apiResponse from "../../../../../../services/apiResponse"

class DetailEmployeeComponent extends Component {
    createBloc = new CreateBloc()

    constructor(props) {
        super(props)
        this.state = {
            loadingData: true,
        }
    }

    componentDidMount() {
        this.createBloc.employeeDetailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingData: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState(
                        {
                            detailData: response,
                        },
                        () => {
                            this.setState({
                                loadingData: false,
                            })
                        }
                    )
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchEmployeeDetail({ uuid: this.props.uuid })
    }

    componentWillUnmount() {
        this.createBloc.employeeDetailChannel.unsubscribe()
    }

    render() {
        return (
            <>
                <Col>
                    <Row className="mb-3">
                        <label className="col-md-3">NIP</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_nip !== ""
                                        ? this.state.detailData.current.ptsp_employee_nip
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 140, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Nama</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_name !== ""
                                        ? this.state.detailData.current.ptsp_employee_name
                                        : "-"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 200, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Unit Kerja</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_work_unit_name !== "" ? (
                                        <>
                                            {this.state.detailData.current.ptsp_employee_work_unit_uuid !==
                                                this.state.detailData.last.ptsp_employee_work_unit_uuid &&
                                            this.state.detailData.last.ptsp_employee_work_unit_uuid !== null ? (
                                                <>
                                                    <label className="bg-soft-success badge rounded-pill font-size-12">
                                                        {this.state.detailData.current.ptsp_employee_work_unit_name}
                                                    </label>
                                                    <br />
                                                    <label className="bg-soft-secondary badge rounded-pill font-size-12">
                                                        {this.state.detailData.last.ptsp_employee_work_unit_name}
                                                    </label>
                                                </>
                                            ) : (
                                                <>{this.state.detailData.current.ptsp_employee_work_unit_name}</>
                                            )}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "80%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Jabatan</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_position_name !== "" ? (
                                        <>
                                            {this.state.detailData.current.ptsp_employee_position_uuid !==
                                                this.state.detailData.last.ptsp_employee_position_uuid &&
                                            this.state.detailData.last.ptsp_employee_position_uuid !== null ? (
                                                <>
                                                    <label className="bg-soft-success badge rounded-pill font-size-12">
                                                        {this.state.detailData.current.ptsp_employee_position_name}
                                                    </label>
                                                    <br />
                                                    <label className="bg-soft-secondary badge rounded-pill font-size-12">
                                                        {this.state.detailData.last.ptsp_employee_position_name}
                                                    </label>
                                                </>
                                            ) : (
                                                <>{this.state.detailData.current.ptsp_employee_position_name}</>
                                            )}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: "50%", height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Golongan Ruang</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_rank_name !== "" ? (
                                        <>
                                            {this.state.detailData.current.ptsp_employee_rank_uuid !==
                                                this.state.detailData.last.ptsp_employee_rank_uuid &&
                                            this.state.detailData.last.ptsp_employee_rank_uuid !== null ? (
                                                <>
                                                    <label className="bg-soft-success badge rounded-pill font-size-12">
                                                        {this.state.detailData.current.ptsp_employee_rank_name}
                                                    </label>
                                                    <br />
                                                    <label className="bg-soft-secondary badge rounded-pill font-size-12">
                                                        {this.state.detailData.last.ptsp_employee_rank_name}
                                                    </label>
                                                </>
                                            ) : (
                                                <>{this.state.detailData.current.ptsp_employee_rank_name}</>
                                            )}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 200, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Status Pernikahan</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_family_wife > 0
                                        ? "Menikah"
                                        : "Tidak Menikah"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 200, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3">Jumlah Tanggungan</label>
                        <Col md="9">
                            {!this.state.loadingData ? (
                                <>
                                    {this.state.detailData.current.ptsp_employee_family_child > 0
                                        ? this.state.detailData.current.ptsp_employee_family_child + " anak"
                                        : "Tidak Ada"}
                                </>
                            ) : (
                                <Shimmer>
                                    <div className="shimmer" style={{ width: 100, height: 15 }}></div>
                                </Shimmer>
                            )}
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}

export default DetailEmployeeComponent
