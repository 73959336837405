import {
    apiRequest,
    simpegPresenceYears,
    simpegPresenceMonth,
} from "../../../../../../services/adapters/sync"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", simpegPresenceYears, {}, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", simpegPresenceMonth, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default IndexViewRepository