import { accessRole } from "../../../../helpers/access_role"
import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import ErrorComingSoon from '../../../../components/Error/Content/ComengSoon'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import apiResponse from '../../../../services/apiResponse'
import IndexBloc from './Blocs/IndexBloc'
import Shimmer from "react-shimmer-effect"
import moment from 'moment'
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import Slider from "react-slick";
import logo from '../../../../assets/images/logo-sm.png';

import {
    Container,
    Row, Col,
    CardHeader, CardBody, Card, CardTitle, CardSubtitle,
    Button,
    CardFooter,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Input, Collapse, Badge
} from "reactstrap"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

class IndexView extends Component {

    indexBloc = new IndexBloc();

    constructor(props) {
        super(props)
        this.state = {
            messageError: "",
            modalShow: false,
            dashboardFilterLoading: true,
            dashboardReportLoading: true,
            jenisPengajuan: "",
            jenisPengajuanDueDate: "",
            isOpen: false,
            showAmount: false,
            activeService: 'Permintaan Dokumen',
            currentPage: 1,
            itemsPerPage: 10,
            dashboardMonitoringOPLoading: true,
            dataMonitoring: [],
            currentItems: [],
            dashboardLayananOPLoading: true,
            dataLayanan: [],
            currentLayananItems: [],
            currentPageLayanan: 1,
            dashboardPerhitunganOPLoading: true,
            dataPerhitungan: [],
            currentPerhitunganItems: [],
            currentPagePerhitungan: 1,
            dataYear: "",
            dataMonth: "",
            dataPDokumen: "",
            searchTerm: '',
            filteredMonitoring: [],
            filteredLayanan: [],
            filteredPerhitungan: [],
        }
    }

    componentDidMount() {

        this.indexBloc.dashboardReportChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        dashboardReportLoading: false,
                        dashboardReport: result.data.response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        dashboardReportLoading: false
                    });
                    break
                default:
                    break
            }
        })

        this.indexBloc.dashboardMonitoringOPChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({ dashboardMonitoringOPLoading: true, dashboardLayananOPLoading: true, dashboardPerhitunganOPLoading: true });
                    break;
                case apiResponse.COMPLETED:
                    const response = result.data.response;
                    console.log('mencari', response);
                    const dataMonitoring = response.monitoring.map(item => ({
                        description: item.financial_recap_type_name,
                        descriptionModul: item.grouping,
                        employeeName: item.employee_fullname,
                        date: this.formatDate(item.financial_recap_create_date),
                        button: {
                            text: item.process_document_is_process ? 'Sudah diproses' : 'Menunggu diproses',
                            statusProcess: item.process_document_is_process ? 'sudah diproses oleh operator keuangan' : 'menunggu proses operator keuangan',
                            proses: item.process_document_is_process.toString()
                        }
                    }));

                    const dataLayanan = response.layanan.map(item => {
                        let buttonText = 'Menunggu diproses';
                        let statusProcess = 'menunggu proses operator keuangan';
                        let descriptionModul = 'SIGAP';
                        
                        // Logika untuk paycheck_status
                        switch (item.paycheck_status) {
                            case 1:
                                buttonText = 'Diajukan';
                                statusProcess = 'menunggu proses oleh operator keuangan';
                                break;
                            case 2:
                                buttonText = 'Disetujui Operator';
                                statusProcess = 'telah disetujui oleh operator keuangan';
                                break;
                            case 3:
                                buttonText = 'Disetujui & Ditandatangani';
                                statusProcess = 'telah disetujui dan ditandatangani oleh keuangan';
                                break;
                            default:
                                break;
                        }

                        switch (item.paycheck_type) {
                            case 1:
                                descriptionModul = 'SIGAP';
                                break;
                            case 2:
                                descriptionModul = 'Slip Gaji';
                                break;
                            default:
                                break;
                        }
        
                        return {
                            description: descriptionModul,
                            employeeName: item.employee_name,
                            date: this.formatDate(item.paycheck_create_date),
                            button: {
                                text: buttonText,
                                statusProcess: statusProcess,
                                proses: item.paycheck_status.toString()
                            }
                        };
                    });

                    const dataPerhitungan = response.perhitungan.map(item => {
                        let buttonText = 'Menunggu diproses';
                        let statusProcess = 'menunggu proses operator keuangan';
                        let descriptionModul = 'TPLN';
                        
                        // Logika untuk paycheck_status
                        switch (item.tunkin_status) {
                            case 1:
                                buttonText = 'Diaktifkan';
                                statusProcess = 'menunggu proses oleh operator keuangan';
                                break;
                            case 2:
                                buttonText = 'Digenerate';
                                statusProcess = 'data telah di generate oleh system';
                                break;
                            case 3:
                                buttonText = 'Direvisi';
                                statusProcess = 'data direvisi oleh keuangan';
                                break;
                            case 4:
                                buttonText = 'Disetujui';
                                statusProcess = 'telah disetujui dan ditandatangani oleh ';
                                break;
                            default:
                                break;
                        }

                        switch (item.tunkin_category) {
                            case 1:
                                descriptionModul = 'Tunkin';
                                break;
                            case 2:
                                descriptionModul = 'TPLN';
                                break;
                            default:
                                break;
                        }
        
                        return {
                            descriptionModul: descriptionModul,
                            employeeName: item.employee_name,
                            date: this.formatDate(item.tunkin_create_date),
                            button: {
                                text: buttonText,
                                statusProcess: statusProcess,
                                proses: item.tunkin_status.toString()
                            }
                        };
                    });

                    const monthNames = [
                        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
                        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
                    ];
                    this.setState({
                        dashboardMonitoringOPLoading: false,
                        dashboardLayananOPLoading: false,
                        dashboardPerhitunganOPLoading: false,
                        dataMonitoring: dataMonitoring,
                        dataLayanan: dataLayanan,
                        dataPerhitungan: dataPerhitungan,
                        dataMonth: monthNames[response.month - 1],
                        dataPDokumen: response.sum_total,
                        dataYear: response.year,
                        currentItems: dataMonitoring.slice(0, this.state.itemsPerPage),
                        currentLayananItems: dataLayanan.slice(0, this.state.itemsPerPage),
                        currentPerhitunganItems: dataPerhitungan.slice(0, this.state.itemsPerPage)
                    });
                    break;
                case apiResponse.ERROR:
                    this.setState({ dashboardMonitoringOPLoading: false, dashboardLayananOPLoading: false, dashboardPerhitunganOPLoading: false });
                    break;
                default:
                    break;
            }
        });
        
        this.indexBloc.fetchGetMonitoringOP();
        // this.indexBloc.fetchGetLayananOP();
        this.loadData()
    }

    componentWillUnmount() {
        this.indexBloc.dashboardReportChannel?.unsubscribe()
        this.indexBloc.dashboardMonitoringOPChannel?.unsubscribe()
        // this.indexBloc.dashboardLayananOPChannel?.unsubscribe()
    }

    loadData = () => {
        this.indexBloc.fetchDashboardReport()
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        let formPencarian = {
            ...values
        }
        this.setState({ dashboardPengajuanLoading: true })
        this.indexBloc.fetchDashboarPengajuan(formPencarian)
    }

    editBatasWaktu = (e, item) => {
        this.setState({
            modalShow: true,
            jenisPengajuan: item.name,
            jenisPengajuanDueDate: item.target
        })
    }

    simpanBatasWaktu = (e) => {
        this.setState({
            modalShow: false
        })
    }

    toggleDetail = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    };

    toggleAmountVisibility = () => {
        this.setState(prevState => ({
            showAmount: !prevState.showAmount
        }));
    }

    SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", left: '10px', zIndex: 2 }} // tambahkan z-index
            onClick={onClick}
          >
            <i className="uil uil-angle-left"></i>
          </div>
        );
    }
    
    SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", right: '10px', zIndex: 2 }} // tambahkan z-index
            onClick={onClick}
          >
            <i className="uil uil-angle-right"></i>
          </div>
        );
    }

    setActiveService = (service) => {
        this.setState({ activeService: service });
    }

    formatDate(dateString) {
        const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    }

    // Metode untuk menangani perubahan halaman
    handlePageChange(pageNumber) {
        const { itemsPerPage, dataMonitoring } = this.state;
        const currentItems = dataMonitoring.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage);
        this.setState({ currentPage: pageNumber, currentItems });
    }

    handlePageLayananChange(pageNumber) {
        const { itemsPerPage, dataLayanan } = this.state;
        const currentLayananItems = dataLayanan.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage);
        this.setState({ currentPageLayanan: pageNumber, currentLayananItems });
    }

    handlePagePerhitunganChange(pageNumber) {
        const { itemsPerPage, dataPerhitungan } = this.state;
        const currentPerhitunganItems = dataPerhitungan.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage);
        this.setState({ currentPagePerhitungan: pageNumber, currentPerhitunganItems });
    }

    handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        this.setState({ searchTerm }, () => {
            this.filterData();
        });
    }

    filterData = () => {
        const { searchTerm, dataMonitoring, dataLayanan, dataPerhitungan } = this.state;

        const filteredMonitoring = dataMonitoring.filter(item =>
            item.employeeName.toLowerCase().includes(searchTerm) ||
            item.descriptionModul.toLowerCase().includes(searchTerm) ||
            item.description.toLowerCase().includes(searchTerm)
        );

        const filteredLayanan = dataLayanan.filter(item =>
            item.employeeName.toLowerCase().includes(searchTerm) ||
            item.description.toLowerCase().includes(searchTerm)
        );

        const filteredPerhitungan = dataPerhitungan.filter(item =>
            item.employeeName.toLowerCase().includes(searchTerm) ||
            item.descriptionModul.toLowerCase().includes(searchTerm)
        );

        this.setState({
            filteredMonitoring,
            filteredLayanan,
            filteredPerhitungan,
            currentItems: filteredMonitoring.slice(0, this.state.itemsPerPage),
            currentLayananItems: filteredLayanan.slice(0, this.state.itemsPerPage),
            currentPerhitunganItems: filteredPerhitungan.slice(0, this.state.itemsPerPage),
        });
    }

    render() {

        const settings = {
            dots: false,
            infinite: true,
            width: '200px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const settingsb = {
            dots: false,
            infinite: true,
            width: '250px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const settingsc = {
            dots: false,
            infinite: true,
            width: '250px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const settingsd = {
            dots: false,
            infinite: true,
            width: '280px',  // Contoh ukuran. Sesuaikan sesuai kebutuhan Anda
            minHeight: '200px',
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <this.SampleNextArrow />,
            prevArrow: <this.SamplePrevArrow />
        };

        const containerStyle = {
            display: 'flex',
            justifyContent: 'center', // opsional, untuk memposisikan card di tengah kontainer
        };

        const fixedCardStyle = {
            width: '265px',
            height: '250px', // contoh, sesuaikan dengan kebutuhan Anda
            overflow: 'auto' // agar konten yang melebihi tinggi card bisa discroll
        };

        const fixedCardStyleInfo = {
            width: '375px',
            height: '250px', // contoh, sesuaikan dengan kebutuhan Anda
            overflow: 'auto' // agar konten yang melebihi tinggi card bisa discroll
        };
    
        const { currentItems, currentLayananItems, currentPerhitunganItems, currentPage, currentPageLayanan, currentPagePerhitungan, itemsPerPage, dataMonitoring, dataLayanan, dataPerhitungan, dataMonth, dataYear, dataPDokumen, dashboardMonitoringOPLoading, dashboardLayananOPLoading, dashboardPerhitunganOPLoading, filteredMonitoring, filteredLayanan, filteredPerhitungan } = this.state;
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(dataMonitoring.length / itemsPerPage); i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button onClick={() => this.handlePageChange(i)} className="page-link">
                        {i}
                    </button>
                </li>
            );
        }

        const pageLayananNumbers = [];
        for (let i = 1; i <= Math.ceil(dataLayanan.length / itemsPerPage); i++) {
            pageLayananNumbers.push(
                <li key={i} className={`page-item ${currentPageLayanan === i ? 'active' : ''}`}>
                    <button onClick={() => this.handlePageLayananChange(i)} className="page-link">
                        {i}
                    </button>
                </li>
            );
        }

        const pagePerhitunganNumbers = [];
        for (let i = 1; i <= Math.ceil(dataPerhitungan.length / itemsPerPage); i++) {
            pagePerhitunganNumbers.push(
                <li key={i} className={`page-item ${currentPagePerhitungan === i ? 'active' : ''}`}>
                    <button onClick={() => this.handlePagePerhitunganChange(i)} className="page-link">
                        {i}
                    </button>
                </li>
            );
        }

        return (
            <Fragment>
                <Container fluid>
                    <div className="clearfix mb-4">
                        <Card className="shadow-sm mb-4">
                            <CardBody className="p-4">
                                <h3 className="mb-3">Dashboard Operator</h3>
                                <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
                                    <div>
                                        <h5 className="mb-1">Terdapat <span className="text-primary">{dataPDokumen}</span> tugas baru untuk Operator</h5>
                                        <p className="text-muted">Cek berdasarkan masing-masing kategori tugas.</p>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        {['Permintaan Dokumen', 'Perubahan Data Gaji', 'Pengembalian'].map((service, index) => (
                                            <Button 
                                                key={index}
                                                color="primary"
                                                className="me-2 mb-2 position-relative" 
                                                style={{ borderRadius: '20px' }} 
                                                onClick={() => this.setActiveService(service)}
                                            >
                                                {service}
                                                {service === 'Permintaan Dokumen' && dataPDokumen > 0 && (
                                                    <Badge 
                                                        color="danger" 
                                                        className="position-absolute top-0 end-0 translate-middle rounded-circle"
                                                        pill
                                                        style={{
                                                            backgroundColor: '#dc3545',
                                                            color: '#fff',
                                                            padding: '0.25em 0.6em',
                                                            fontSize: '0.75rem',
                                                            fontWeight: 'bold',
                                                            borderRadius: '50%',
                                                            width: '20px',
                                                            height: '20px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        {dataPDokumen}
                                                    </Badge>
                                                )}
                                            </Button>
                                        ))}
                                    </div>
                                </div>
                                <Input 
                                    type="text" 
                                    placeholder="Cari..." 
                                    style={{ borderRadius: '20px' }} 
                                    value={this.state.searchTerm}
                                    onChange={this.handleSearch}
                                />
                            </CardBody>
                        </Card>

                        <Row>
                            {['Monitoring', 'Perhitungan', 'Layanan'].map((section, index) => (
                                <Col md={4} key={index}>
                                    <Card className="shadow-sm h-100">
                                        <CardBody>
                                            <h5 className="card-title mb-3">
                                                <i className={`uil uil-${section.toLowerCase() === 'monitoring' ? 'monitor' : section.toLowerCase() === 'perhitungan' ? 'calculator-alt' : 'server-network'} me-2`}></i>
                                                {section}
                                            </h5>
                                            <h6 className="text-muted mb-4">Per {dataMonth} {dataYear}</h6>
                                            {this[`render${section}Content`]()}
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            </Fragment>
        )
    }

    // Add these methods to render content for each section
    renderMonitoringContent() {
        const { dashboardMonitoringOPLoading, currentItems, filteredMonitoring } = this.state;
        if (dashboardMonitoringOPLoading) {
            return <p className="text-center">Sedang Memuat Data Monitoring...</p>;
        }
        const itemsToRender = this.state.searchTerm ? filteredMonitoring : currentItems;
        return itemsToRender.length > 0 ? this.renderTable(itemsToRender, this.state.pageNumbers) : <p className="text-center">Tidak Ada Data Monitoring</p>;
    }

    renderPerhitunganContent() {
        const { dashboardPerhitunganOPLoading, currentPerhitunganItems, filteredPerhitungan } = this.state;
        if (dashboardPerhitunganOPLoading) {
            return <p className="text-center">Sedang Memuat Data Perhitungan...</p>;
        }
        const itemsToRender = this.state.searchTerm ? filteredPerhitungan : currentPerhitunganItems;
        return itemsToRender.length > 0 ? this.renderTable(itemsToRender, this.state.pagePerhitunganNumbers) : <p className="text-center">Tidak Ada Data Perhitungan</p>;
    }

    renderLayananContent() {
        const { dashboardLayananOPLoading, currentLayananItems, filteredLayanan } = this.state;
        if (dashboardLayananOPLoading) {
            return <p className="text-center">Sedang Memuat Data Layanan...</p>;
        }
        const itemsToRender = this.state.searchTerm ? filteredLayanan : currentLayananItems;
        return itemsToRender.length > 0 ? this.renderTable(itemsToRender, this.state.pageLayananNumbers) : <p className="text-center">Tidak Ada Data Layanan</p>;
    }

    renderTable(items, pageNumbers) {
        return (
            <>
                <div className="table-responsive" style={{maxHeight: '300px', overflowY: 'auto'}}>
                    <table className="table table-hover">
                        <tbody>
                            {items.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <Badge 
                                            color="secondary"
                                            pill 
                                            className="mb-2"
                                            style={{ color: '#fff', backgroundColor: '#6c757d' }}
                                        >
                                            {item.descriptionModul || item.description}
                                        </Badge>
                                        <p className="mb-0 font-weight-bold">{item.employeeName}</p>
                                        <small className="text-muted">{item.button.statusProcess}</small>
                                    </td>
                                    <td className="text-end">
                                        <p className="mb-1 text-muted">{item.date}</p>
                                        <Badge 
                                            pill
                                            className="px-2 py-1"
                                            style={this.getBadgeStyle(item.button.text)}
                                        >
                                            {item.button.text}
                                        </Badge>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <nav className="mt-3">
                    <ul className="pagination justify-content-center">
                        {pageNumbers}
                    </ul>
                </nav>
            </>
        );
    }

    getBadgeStyle(status) {
        let backgroundColor, color;
        switch (status) {
            case 'Menunggu diproses':
            case 'Diajukan':
            case 'Diaktifkan':
                backgroundColor = '#6c757d';
                color = '#ffffff';
                break;
            case 'Disetujui Operator':
            case 'Digenerate':
                backgroundColor = '#007bff';
                color = '#ffffff';
                break;
            case 'Disetujui & Ditandatangani':
            case 'Disetujui':
                backgroundColor = '#28a745';
                color = '#ffffff';
                break;
            default:
                backgroundColor = '#007bff';
                color = '#ffffff';
        }
        return { backgroundColor, color };
    }
}

export default IndexView