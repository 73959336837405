import {
    apiRequest,
    tunsusRegulerProcessSubmit,
    tunsusRegulerProcessEmployees,
    tunsusRegulerProcessEmployeeDetail,
    tunsusRegulerCheckStatus,
    tunsusRegulerGetSigner,
    tunsusRegulerCheckLevel,
    tunsusRegulerCheckPtkp,
    tunsusRegulerCheckOtherMonth,
    tunsusRegulerReset,
} from "../../../../../../services/adapters/process"

class CreateRepository {
    fetchCreate = async (query) => {
        return await apiRequest(
            "post",
            tunsusRegulerProcessSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunsusRegulerProcessEmployees,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerProcessEmployeeDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchGetSigner = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerGetSigner,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerCheckStatus,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckLevel = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerCheckLevel,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerCheckPtkp,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckOtherMonth = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerCheckOtherMonth,
            {
                params: query,
            },
            true
        )
    }

    fetchReset = async (query) => {
        return await apiRequest(
            "post",
            tunsusRegulerReset,
            {
                body: query,
            },
            true
        )
    }
}

export default CreateRepository
