import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect";

import {
    Container,
    Row, Col,
    Button,
    Modal,
    Alert
} from "reactstrap"

import moment from 'moment'
import { cancelToken } from "../../../../../services/adapters/master"


import DataTableValueComponent from './Components/DataTableValueComponent'
import RegulationListComponent from './Components/RegulationListComponent'

import IndexBloc from './Blocs/IndexBloc'
import apiResponse from '../../../../../services/apiResponse'

class RootView extends Component {
    indexBloc = new IndexBloc()

    constructor(props) {
        super(props)
        this.state = {
            showRegulationList: false,
            loadingCurrent: true,
            noCurrent: false,
            currentRegulation: {
                regulation_date: null,
                regulation_date_end: null,
                regulation_date_start: null,
                regulation_description: null,
                regulation_id: 0,
                regulation_number: null,
                regulation_title: null,
                regulation_uuid: null,
                regulation_active: '1'
            }
        }
    }

    loadData = async () => {
        await this.indexBloc.fetchCurrent({uuid: this.props.country.uuid}, )
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()
        this.indexBloc.currentChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        noCurrent: response === null ? true : false,
                        currentRegulation: response,
                        loadingCurrent: false,
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
    }

    handleRegulationList = (response) => {
        this.setState({
            loadingCurrent: true
        }, () => {
            this.setState({
                showRegulationList: false,
                currentRegulation: response,
                loadingCurrent: false
            })
        });
    }
    componentWillUnmount() {
        this.indexBloc.currentChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                { this.state.noCurrent ?
                    <Fragment>
                        <Alert color="secondary">
                            <table width="100%">
                                <tr>
                                    <td width="30px" style={{verticalAlign: 'top'}}>
                                        <i className="uil-times"></i>
                                    </td>
                                    <td style={{verticalAlign: 'top'}}>
                                        Belum memiliki data Tunkin
                                    </td>
                                </tr>
                            </table>
                        </Alert>
                    </Fragment>
                    :
                    <Fragment>
                        <Row>
                            <Col className="col-12">
                                <Row>
                                    <Col md="12">
                                        <div className="d-flex flex-row bd-highlight">
                                            <div className="pe-2 pb-2 bd-highlight" style={{paddingTop: 7}}>
                                                {
                                                this.state.loadingCurrent?
                                                    <><Shimmer><div className="shimmer" style={{width: 116, height: 36}}></div></Shimmer>{" "}</>
                                                :
                                                    <>
                                                        <span
                                                            onClick={() => {
                                                                this.setState({
                                                                    showRegulationList: true
                                                                });
                                                            }}
                                                        >
                                                            <Button color="success"><i className="uil-align-left"></i> Lihat Semua</Button>
                                                        </span>
                                                    </>
                                                }
                                            </div> 
                                            <div className="ps-2 bd-highlight" style={{paddingTop: 5}}>
                                                <div>
                                                    Surat Keputusan :{" "}
                                                    {
                                                        this.state.loadingCurrent?
                                                            <><Shimmer><div className="shimmer" style={{width: 160, height: 14, marginBottom: -3}}></div></Shimmer>{" "}</>
                                                        :
                                                            <><b>{ this.state.currentRegulation.regulation_number }</b>{" "}</>
                                                    }
                                                    {
                                                        this.state.loadingCurrent?
                                                            <><Shimmer><div className="shimmer" style={{width: 50, height: 14, marginBottom: -3}}></div></Shimmer>{" "}</>
                                                        :
                                                            <>{ (String(this.state.currentRegulation.regulation_active) === '0' ? <><div className="bg-danger rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>{" "}<span className="text-danger">Tidak Aktif</span></> : (String(this.state.currentRegulation.regulation_active) === '1' ? <><div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>{" "}<span className="text-success">Aktif</span></> : <><div className="bg-primary rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>{" "}<span className="text-primary">Belum Aktif</span></>)) }{" "}</>
                                                    }
                                                </div>
                                                <div className="text-muted">
                                                    {
                                                        this.state.loadingCurrent?
                                                            <><Shimmer><div className="shimmer" style={{minWidth: 360, height: 12, marginBottom: -2}}></div></Shimmer></>
                                                        :
                                                            <><small>Berlaku mulai tanggal { moment(this.state.currentRegulation.regulation_date_start).format('LL') }{ this.state.currentRegulation.regulation_date_end != null ? " s/d " + moment(this.state.currentRegulation.regulation_date_end).format('LL') : "" }</small></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col sm={12}>
                                        {
                                            this.state.loadingCurrent?
                                                <>
                                                    <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '35%', height: 36}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '35%', height: 36}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '20%', height: 36}}></div></Shimmer>

                                                    <Shimmer><div className="shimmer" style={{width: '10%', height: 22}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '35%', height: 22}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '35%', height: 22}}></div></Shimmer>
                                                    <Shimmer><div className="shimmer" style={{width: '20%', height: 22}}></div></Shimmer>
                                                </>
                                            :
                                                <>
                                                    <DataTableValueComponent uuid={ this.state.currentRegulation.regulation_uuid }/>
                                                </>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Modal
                            size="lg"
                            isOpen={this.state.showRegulationList}
                            toggle={() => {
                                this.setState({
                                    showRegulationList: this.state.showRegulationList
                                })
                            }}
                        >
                            <div className="modal-header">
                                <h5
                                    className="modal-title mt-0"
                                    id="myLargeModalLabel"
                                >
                                    Daftar Surat Keputusan TPNL { this.props.country.name }
                                </h5>
                                <button
                                    onClick={() => {
                                        this.setState({
                                            showRegulationList: false
                                        })
                                    }}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <RegulationListComponent uuid={this.props.country.uuid} changeUUID={this.handleRegulationList} />
                            </div>
                        </Modal>
                    </Fragment>
                }
            </Fragment>
        )
    }

}

export default RootView