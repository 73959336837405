import {
    apiRequest,
    masterReferenceCountrylist
} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceCountrylist, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository