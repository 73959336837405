import {
    apiRequest,
    tunkinSusulanMonths,
    tunkinSusulanHistory,
    tunkinSusulanPushSPM,
    tunkinSusulanUpdateSPM,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunkinSusulanMonths,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchHistory = async (query) => {
        return await apiRequest(
            "get",
            tunkinSusulanHistory,
            {
                params: query,
            },
            true
        )
    }

    fetchPushSPM = async (query) => {
        return await apiRequest(
            "post",
            tunkinSusulanPushSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest(
            "post",
            tunkinSusulanUpdateSPM,
            {
                body: query,
            },
            true
        )
    }
}

export default IndexViewRepository
