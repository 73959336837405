import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"

import {
    Tooltip,
    Modal
} from "reactstrap"

import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import MKIDatatable from '../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../services/adapters/base"

import IndexViewBloc from '../Blocs/IndexViewBloc'
import apiResponse from '../../../../../services/apiResponse'

import RefundUploadComponent from "./RefundUploadComponent"

class RefundProcessListComponent extends Component {

    indexViewBloc = new IndexViewBloc();

    source
    historyTable = 'historyRefundProcessListComponent'
    defaultOrder = 'official_travel_refund_create_date'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            refundUuid: null,
            showRefund: false,
            tooltip: false,
            defaultSize: 10,
            calcelUuid: null,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order
        }
        await this.indexViewBloc.fetchRowRefundListProcess(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.refundCancelData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    refundCancelData = async () => {
        await this.indexViewBloc.fetchRefundCancel({ uuid: this.state.calcelUuid })
    }

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.rowRefundListProcessChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.rowRefundCancelChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    }, function () {
                        this.setTokenAPI()
                        this.props.handleDoneSuccess()
                        this.props.handleCancelSuccess()
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
    }

    closeRefundUpload = () => {
        this.setState({
            showRefund: false
        })
    }
    closeRefundUploadSolved = () => {
        this.closeRefundUpload()
        this.setTokenAPI()
        this.props.handleDoneSuccess()
        this.props.handleCancelSuccess()
    }

    componentWillUnmount() {
        this.indexViewBloc.rowRefundListProcessChannel.unsubscribe()
        this.indexViewBloc.rowRefundCancelChannel.unsubscribe()
    }

    columns = [
        {
            key: "official_travel_process_number",
            text: "Nomor SPD",
            sortable: true,
            width: '220',
            align: 'center'
        },
        {
            key: "official_travel_number",
            text: "Nomor ST",
            sortable: true,
            width: '220',
            align: 'left'
        },
        {
            key: "employee_name",
            text: "Nama Pegawai",
            sortable: true,
            align: 'left'
        },
        {
            key: "official_travel_refund_value",
            text: "Nilai Pengembalian",
            width: '200',
            sortable: true,
            align: 'center',
            cell: record => {
                return (
                    <>
                        <div className="text-end">
                            {record.official_travel_refund_value.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </div>
                    </>
                )
            }
        },
        {
            key: "action",
            text: "Aksi",
            width: '120',
            align: 'center',
            cell: record => {
                const thisId = record.official_travel_refund_id
                var allowUpdate = accessRole('update') && record.official_travel_refund_status === 1
                var allowDelete = (accessRole('update') || accessRole('delete')) && record.official_travel_refund_status === 1
                return (
                    <>
                        <span>
                            <button
                                className="btn btn-primary btn-sm"
                                id={"detail-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        //refundUuid: record.official_travel_refund_uuid,
                                        //showDetail: true
                                    })
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                <i className="uil-align-left"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["detail" + thisId]: !this.state["detail" + thisId]
                                    })
                                }}
                            >Detail</Tooltip>
                            {allowUpdate ?
                                <>
                                    <button
                                        className="btn btn-warning btn-sm"
                                        id={"update-" + thisId}
                                        onClick={() => {
                                            this.setState({
                                                refundUuid: record.official_travel_refund_uuid,
                                                showRefund: true
                                            })
                                        }}
                                        style={{
                                            marginRight: '2px',
                                            padding: '2px 5px'
                                        }}>
                                        <i className="uil-pen"></i>
                                    </button>
                                    <Tooltip
                                        style={{
                                            marginBottom: 2
                                        }}
                                        placement="top" isOpen={this.state["update" + thisId]} target={"update-" + thisId}
                                        toggle={() => {
                                            this.setState({
                                                ["update" + thisId]: !this.state["update" + thisId]
                                            })
                                        }}
                                    >Upload Bukti</Tooltip>
                                </>
                                :
                                <button
                                    className="btn btn-soft-secondary btn-sm" disabled={true}
                                    style={{
                                        marginRight: '2px',
                                        padding: '2px 5px'
                                    }}>
                                    <i className="uil-pen"></i>
                                </button>
                            }
                            {allowDelete ?
                                <>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        id={"delete-" + thisId}
                                        onClick={() => {
                                            this.setState({
                                                calcelUuid: record.official_travel_refund_uuid,
                                                confirm: true
                                            })
                                        }}
                                        style={{
                                            marginRight: '2px',
                                            padding: '2px 5px'
                                        }}>
                                        <i className="uil-trash"></i>
                                    </button>
                                    <Tooltip
                                        style={{
                                            marginBottom: 2
                                        }}
                                        placement="top" isOpen={this.state["delete" + thisId]} target={"delete-" + thisId}
                                        toggle={() => {
                                            this.setState({
                                                ["delete" + thisId]: !this.state["delete" + thisId]
                                            })
                                        }}
                                    >Batalkan</Tooltip>
                                </>
                                :
                                <button
                                    className="btn btn-soft-secondary btn-sm" disabled={true}
                                    style={{
                                        padding: '2px 5px'
                                    }}>
                                    <i className="uil-trash"></i>
                                </button>
                            }
                        </span>
                    </>
                );
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={900}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan membatalkan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dibatalkan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dibatalkan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />

                <Modal
                    isOpen={this.state.showRefund}
                    toggle={() => {
                        this.setState({
                            showRefund: !this.state.showRefund
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Form Pengembalian
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showRefund: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {this.state.showRefund ?
                        <RefundUploadComponent refundUuid={this.state.refundUuid} cancelUpload={this.closeRefundUpload} cancelUploadSolved={this.closeRefundUploadSolved} />
                        : <></>
                    }
                </Modal>
            </>
        )
    }
}

export default RefundProcessListComponent