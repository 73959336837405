import {
    apiRequest,
    tunkinSusulanProcessSubmit,
    tunkinSusulanProcessEmployees,
    tunkinSusulanProcessEmployeeDetail,
    tunkinSusulanCheckStatus,
    tunkinSusulanGetSigner,
    tunkinSusulanCheckPresence,
    tunkinSusulanCheckLevel,
    tunkinSusulanCheckGpp,
    tunkinSusulanCheckPtkp,
    tunkinSusulanCheckPkp,
    tunkinSusulanCheckEmployeeNew,
    tunkinSusulanCheckEmployeeChange
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", tunkinSusulanProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", tunkinSusulanProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tunkinSusulanProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", tunkinSusulanGetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tunkinSusulanCheckStatus, {
            params: query
        }, true)
    }
    
    fetchCheckPresence = async (query) => {
        return await apiRequest("get", tunkinSusulanCheckPresence, {
            params: query
        }, true)
    }
    
    fetchCheckLevel = async (query) => {
        return await apiRequest("get", tunkinSusulanCheckLevel, {
            params: query
        }, true)
    }

    fetchCheckGpp = async (query) => {
        return await apiRequest("get", tunkinSusulanCheckGpp, {
            params: query
        }, true)
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest("get", tunkinSusulanCheckPtkp, {
            params: query
        }, true)
    }
    
    fetchCheckPkp = async (query) => {
        return await apiRequest("get", tunkinSusulanCheckPkp, {
            params: query
        }, true)
    }

    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", tunkinSusulanCheckEmployeeNew, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", tunkinSusulanCheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository