import { accessRole } from "../../../../../helpers/access_role"
import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import Shimmer from "react-shimmer-effect";

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    NavLink,
    NavItem,
    Nav,
    TabContent,
    TabPane,
    Alert
} from "reactstrap"
import apiResponse from '../../../../../services/apiResponse'

import RootView from './RootView'

import IndexBloc from './Blocs/IndexBloc'

class IndexView extends Component {

    indexBloc = new IndexBloc()
    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            loadingMinistry: true,
            ministryList: []
        }
    }

    componentDidMount() {
        this.indexBloc.ministryChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    break
                case apiResponse.LOADING:
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        activeMinistry: response.length === 0 ? {} : {uuid: response[0].ministry_uuid, name: response[0].ministry_name},
                        loadingMinistry: false,
                        ministryList: response
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.indexBloc.fetchMinistry()
    }

    componentWillUnmount() {
        this.indexBloc.ministryChannel.unsubscribe()
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="float-end">
                                        {
                                            (accessRole('create') && this.state.activeMinistry && this.state.ministryList.length > 0) ?
                                                <Link to={"/master/ptsp-tunkin.html?create&uuid=" + this.state.activeMinistry.uuid}>
                                                    <Button color="primary"><i className="uil-folder-medical"></i> Tambah Data Baru</Button>
                                                </Link>
                                            : null
                                        }
                                    </div>
                                    <CardTitle>Tunjangan Kinerja PTSP</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Informasi nilai tunjangan kinerja PTSP untuk golongan dan periode tertentu.
                                    </CardSubtitle>
                                    <hr />
                                    { 
                                        !this.state.loadingMinistry && this.state.ministryList.length === 0 ?
                                            <Alert color="secondary">
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" style={{verticalAlign: 'top'}}>
                                                            <i className="uil-times"></i>
                                                        </td>
                                                        <td style={{verticalAlign: 'top'}}>
                                                            Belum memiliki data Tunkin
                                                        </td>
                                                        <td className="text-end" style={{verticalAlign: 'top'}}>
                                                            {/* <Link to="/master/negara.html?create"> */}
                                                                Tambah Data Tunkin
                                                            {/* </Link> */}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </Alert>
                                        : 
                                            <Fragment>
                                                <Row>
                                                    <Col md={3} style={{minHeight: 200}}>
                                                        { 
                                                            this.state.loadingMinistry ?
                                                                <Fragment>
                                                                    <Shimmer><div className="shimmer" style={{width: '100%', height: 35}}></div></Shimmer>
                                                                    <Shimmer><div className="shimmer" style={{width: '100%', height: 35}}></div></Shimmer>
                                                                    <Shimmer><div className="shimmer" style={{width: '100%', height: 35}}></div></Shimmer>
                                                                </Fragment>
                                                            : 
                                                                <Nav pills className="flex-column">
                                                                    {
                                                                        this.state.ministryList?.map((item, key) => {
                                                                            return (
                                                                                <Fragment>
                                                                                    <NavLink
                                                                                        key={key}
                                                                                        style={{ cursor: "pointer" }}
                                                                                        className={classnames('mb-2', {
                                                                                            active: this.state.activeMinistry.uuid === item.ministry_uuid,
                                                                                        })}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                activeMinistry: {uuid: item.ministry_uuid, name: item.ministry_name },
                                                                                                loadingIndex: true
                                                                                            }, () => {
                                                                                                this.setState({
                                                                                                    loadingIndex: false
                                                                                                })
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                                                        <span className="d-none d-sm-block">
                                                                                            { item.ministry_name }
                                                                                        </span>
                                                                                    </NavLink>
                                                                                </Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                </Nav>
                                                        }
                                                    </Col>
                                                    <Col md={9}>
                                                        {
                                                            this.state.loadingMinistry ?
                                                                <Fragment>
                                                                    <Row>
                                                                        <Col md="12">
                                                                            <div className="d-flex flex-row bd-highlight">
                                                                                <div className="pe-2 pb-2 bd-highlight" style={{paddingTop: 7}}>
                                                                                    <Shimmer><div className="shimmer" style={{width: 116, height: 36}}></div></Shimmer>{" "}
                                                                                </div> 
                                                                                <div className="ps-2 bd-highlight" style={{paddingTop: 5}}>
                                                                                    <div>
                                                                                        Surat Keputusan :{" "}
                                                                                        <Shimmer><div className="shimmer" style={{width: 160, height: 14, marginBottom: -3}}></div></Shimmer>{" "}
                                                                                        <Shimmer><div className="shimmer" style={{width: 50, height: 14, marginBottom: -3}}></div></Shimmer>{" "}
                                                                                    </div>
                                                                                    <div className="text-muted">
                                                                                        <Shimmer><div className="shimmer" style={{minWidth: 360, height: 12, marginBottom: -2}}></div></Shimmer>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col sm={12}>
                                                                            <Shimmer><div className="shimmer" style={{width: '10%', height: 36}}></div></Shimmer>
                                                                            <Shimmer><div className="shimmer" style={{width: '35%', height: 36}}></div></Shimmer>
                                                                            <Shimmer><div className="shimmer" style={{width: '35%', height: 36}}></div></Shimmer>
                                                                            <Shimmer><div className="shimmer" style={{width: '20%', height: 36}}></div></Shimmer>

                                                                            <Shimmer><div className="shimmer" style={{width: '10%', height: 22}}></div></Shimmer>
                                                                            <Shimmer><div className="shimmer" style={{width: '35%', height: 22}}></div></Shimmer>
                                                                            <Shimmer><div className="shimmer" style={{width: '35%', height: 22}}></div></Shimmer>
                                                                            <Shimmer><div className="shimmer" style={{width: '20%', height: 22}}></div></Shimmer>
                                                                        </Col>
                                                                    </Row>
                                                                </Fragment>
                                                            :
                                                                <Fragment>
                                                                    {
                                                                        this.state.loadingIndex ?
                                                                            <></>
                                                                        : <RootView country={this.state.activeMinistry}/>
                                                                    }
                                                                </Fragment>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Fragment>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView;