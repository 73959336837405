import {
    apiRequest,
    tplnRegulerYears,
    tplnRegulerMonths,
    tplnRegulerHistory,
    tplnRegulerPushSPM,
    tplnRegulerUpdateSPM,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", tplnRegulerYears, {}, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", tplnRegulerMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", tplnRegulerHistory, {
            params: query
        }, true)
    }

    fetchPushSPM = async (query) => {
        return await apiRequest(
            "post",
            tplnRegulerPushSPM,
            {
                body: query,
            },
            true
        )
    }

    fetchUpdateSPM = async (query) => {
        return await apiRequest(
            "post",
            tplnRegulerUpdateSPM,
            {
                body: query,
            },
            true
        )
    }
}

export default IndexViewRepository