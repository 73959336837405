import {
    apiRequest,
    masterReferenceKursCreate,
    masterReferenceKursCurrencies
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterReferenceKursCreate, {
            body: query
        }, true)
    }

    fetchCurrencyList = async () => {
        return await apiRequest("get", masterReferenceKursCurrencies, {}, true)
    }

}

export default CreateRepository