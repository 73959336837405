import {
    apiRequest,
    simapanTokenLists,
    simapanTokenYearAvailable,
    simapanTokenCreate,
    simapanTokenDelete
} from "../../../../../../services/adapters/sync"

class OmspanRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", simapanTokenLists, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchYearAvailable = async () => {
        return await apiRequest("get", simapanTokenYearAvailable, {}, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", simapanTokenCreate, {
            body: query
        }, true)
    }

    fetchDelete = async (query) => {
        return await apiRequest("post", simapanTokenDelete, {
            body: query
        }, true)
    }
}

export default OmspanRepository