import {
    apiRequest,
    officialTravelSt,
    officialTravelDetail,
    officialTravelNominative,
    officialTravelRiil,
    officialTravelCost,
    officialTravelSptb
} from "../../../../../../services/adapters/output"

class DownloadRepository {

    fetchSt = async (query) => {
        return await apiRequest("get", officialTravelSt, {
            params: query
        }, true)
    }

    fetchDetail = async (query) => {
        return await apiRequest("get", officialTravelDetail, {
            params: query
        }, true)
    }

    fetchNominative = async (query) => {
        return await apiRequest("get", officialTravelNominative, {
            params: query
        }, true)
    }

    fetchRiil = async (query) => {
        return await apiRequest("get", officialTravelRiil, {
            params: query
        }, true)
    }

    fetchCost = async (query) => {
        return await apiRequest("get", officialTravelCost, {
            params: query
        }, true)
    }

    fetchSptb = async (query) => {
        return await apiRequest("get", officialTravelSptb, {
            params: query
        }, true)
    }
}

export default DownloadRepository