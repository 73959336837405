import { apiRequest, officialTravelRapelSubmissionSource } from "../../../../../../services/adapters/process"

class DetailRepository {
    fetchDetail = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelSubmissionSource,
            {
                params: query,
            },
            true
        )
    }
}

export default DetailRepository
