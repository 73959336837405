import React, { useState, Component, Fragment } from "react"
import SimpleBar from "simplebar-react"
import Shimmer from "react-shimmer-effect"
import moment from 'moment'
import {Row, Col, Table} from 'reactstrap'

import CreateBloc from '../Blocs/CreateBloc'
import apiResponse from '../../../../../services/apiResponse'

class DetailEmployeeComponent extends Component {

    CreateBloc = new CreateBloc();

    constructor(props) {
        super(props)
        this.state = {
            loadingData: true,
            detailData: []
        }
    }

    rupiah = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    componentDidMount() {
        this.CreateBloc.employeeDetailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingData: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        detailData: response,
                        loadingData: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.CreateBloc.fetchEmployeeDetail({uuid: this.props.uuid})
    }

    componentWillUnmount() {
        this.CreateBloc.employeeDetailChannel.unsubscribe()
    }

    render () {
        return (
            <>
                { this.state.loadingData ?
                    <SimpleBar className="activity-feed mb-0 ps-2" style={{ maxHeight: '500px' }}>
                        <li className="feed-item">
                            <p className="text-muted mb-1 font-size-13">
                                <span className="text-success">
                                    <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>{" "}
                                </span>
                                <Shimmer><div className="shimmer" style={{width: '40%', height: 12}}></div></Shimmer>
                            </p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '90%', height: 14}}></div></Shimmer></p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '40%', height: 14}}></div></Shimmer></p>
                        </li>
                        <li className="feed-item">
                            <p className="text-muted mb-1 font-size-13">
                                <span className="text-success">
                                    <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>{" "}
                                </span>
                                <Shimmer><div className="shimmer" style={{width: '40%', height: 12}}></div></Shimmer>
                            </p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '90%', height: 14}}></div></Shimmer></p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '40%', height: 14}}></div></Shimmer></p>
                        </li>
                        <li className="feed-item">
                            <p className="text-muted mb-1 font-size-13">
                                <span className="text-success">
                                    <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>{" "}
                                </span>
                                <Shimmer><div className="shimmer" style={{width: '40%', height: 12}}></div></Shimmer>
                            </p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '90%', height: 14}}></div></Shimmer></p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '40%', height: 14}}></div></Shimmer></p>
                        </li>
                    </SimpleBar>
                :
                    <>
                    { this.state.detailData != null ?
                        <SimpleBar className="activity-feed mb-0 ps-2" style={{ maxHeight: '500px' }}>
                            <Row className="mb-1">
                                <label className="col-md-3">
                                    NIP
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detailData ?
                                            <>{this.state.detailData.ptsp_employee_nip !== "" ? this.state.detailData.ptsp_employee_nip : '-'}</>
                                        : <Shimmer><div style={{width: '50%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <label className="col-md-3">
                                    Nama
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detailData ?
                                            <>{this.state.detailData.ptsp_employee_name !== "" ? this.state.detailData.ptsp_employee_name : '-'}</>
                                        : <Shimmer><div style={{width: '50%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <label className="col-md-3">
                                    Unit Kerja
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detailData ?
                                            <>{this.state.detailData.ptsp_employee_work_unit_name !== "" ? this.state.detailData.ptsp_employee_work_unit_name : '-'}</>
                                        : <Shimmer><div style={{width: '50%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <label className="col-md-3">
                                    Jabatan
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detailData ?
                                            <>{this.state.detailData.ptsp_employee_position_name !== "" ? this.state.detailData.ptsp_employee_position_name : '-'}</>
                                        : <Shimmer><div style={{width: '50%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <label className="col-md-3">
                                    Pangkat
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detailData ?
                                            <>{this.state.detailData.ptsp_employee_rank_name !== "" ? this.state.detailData.ptsp_employee_rank_name : '-'}</>
                                        : <Shimmer><div style={{width: '50%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <label className="col-md-3">
                                    Gaji Pokok
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detailData ?
                                            <>{this.state.detailData.ptsp_employee_salary_value !== "" ? `Rp ${this.rupiah(this.state.detailData.ptsp_employee_salary_value)}` : '-'}</>
                                        : <Shimmer><div style={{width: '50%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <label className="col-md-3">
                                    Absensi
                                </label>
                                <Col md="9">
                                    {
                                        this.state.detailData ?
                                            <>
                                                <Table className="table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Jenis</th>
                                                            <th>Jumlah</th>
                                                            <th>Persentase</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Tidak Hadir</td>
                                                            <td>{this.state.detailData.ptsp_presence_not_present_total !== null ? this.state.detailData.ptsp_presence_not_present_total : '0'}</td>
                                                            <td>{this.state.detailData.ptsp_presence_not_present_percentage !== null ? this.state.detailData.ptsp_presence_not_present_percentage : '0'}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Absen Hanya 1x</td>
                                                            <td>{this.state.detailData.ptsp_presence_single_finger_total !== null ? this.state.detailData.ptsp_presence_single_finger_total : '0'}</td>
                                                            <td>{this.state.detailData.ptsp_presence_single_finger_percentage !== null ? this.state.detailData.ptsp_presence_single_finger_percentage : '0'}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pulang Lebih Awal</td>
                                                            <td>{this.state.detailData.ptsp_presence_early_total !== null ? this.state.detailData.ptsp_presence_early_total : '0'}</td>
                                                            <td>{this.state.detailData.ptsp_presence_early_percentage !== null ? this.state.detailData.ptsp_presence_early_percentage : '0'}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Terlambat</td>
                                                            <td>{this.state.detailData.ptsp_presence_late_total !== null ? this.state.detailData.ptsp_presence_late_total : '0'}</td>
                                                            <td>{this.state.detailData.ptsp_presence_late_percentage !== null ? this.state.detailData.ptsp_presence_late_percentage : '0'}%</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </>
                                        : <Shimmer><div style={{width: '50%', height: 15}}></div></Shimmer>
                                    }
                                </Col>
                            </Row>   
                        </SimpleBar>
                        :
                        <p className="text-muted mb-1 font-size-13">Tidak ada data riwayat</p>
                    }
                    </>
                }
            </>
        )
    }
}

export default DetailEmployeeComponent