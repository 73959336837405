import { apiRequest, ptspRegulerExport } from "../../../../../services/adapters/output"

class DownloadRepository {
    fetchDownloadDetail = async (query) => {
        return await apiRequest(
            "get",
            ptspRegulerExport,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
