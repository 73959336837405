import { apiRequest, sigapManualCreate } from "../../../../../../services/adapters/sync"

class CreateRepository {
    fetchCreate = async (query) => {
        return await apiRequest(
            "post",
            sigapManualCreate,
            {
                body: query,
            },
            true
        )
    }
}

export default CreateRepository
