import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Modal
} from "reactstrap"
import Shimmer from "react-shimmer-effect";
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import CustomSelect from '../../../../../helpers/ui/CustomSelect'
import { FileInput, generateFormData } from '../../../../../helpers/ui/FileInput'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect } from "react-router-dom"
import apiResponse from '../../../../../services/apiResponse'
import CreateBloc from './Blocs/CreateBloc'


class CreateView extends Component {


    createBloc = new CreateBloc()
    constructor(props) {
        super(props)

        this.state = {
            dataForm: {},
            loadingCountry: true,
            keputusanvalue: '',
            kategory: [
                { label: "Daily", value: "1" },
                { label: "Hotel", value: "2" },
                { label: "Transport", value: "3" },
                { label: "Representative", value: "4" },
                { label: "Other", value: "5" }
            ],
            type: [
                { label: "Biasa", value: "1" },
                { label: "Fullday", value: "2" },
                { label: "Fullboard", value: "3" },
                { label: "Rill", value: "4" }
            ]
        }
    }
    submitData = async () => {
        await this.createBloc.fetchCreate(this.state.dataForm)
    }



    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                region_uuid: this.state.dataForm.region_uuid,
                official_travel_province_value_amount_1: this.state.dataForm.official_travel_province_value_amount_1,
                official_travel_province_value_amount_2: this.state.dataForm.official_travel_province_value_amount_2,
                official_travel_province_value_amount_3: this.state.dataForm.official_travel_province_value_amount_3,
                // official_travel_province_value_category : this.state.dataForm.official_travel_province_value_category,
                // official_travel_province_value_type : this.state.dataForm.official_travel_province_value_type,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }


    confirmResponse2 = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm2: false
                }
                break;
            case 'confirm':
                forState = {
                    confirm2: false
                }
                break;
            case 'success':
                forState = {
                    success2: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed2: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.checkChannnel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.status
                    if (response == true) {
                        this.setState({
                            failed2: true,
                            status_button: false,
                        });
                    }
                    else {
                        this.setState({
                            status_button: true,
                        });
                    }
                    break
                case apiResponse.ERROR:
                    this.setState({
                        failed2: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.countryChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingCountry: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingCountry: false,
                        countries: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.fetchCountryList()
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
        this.createBloc.countryChannel?.unsubscribe()
    }

    handleKeyPress = (event) => {
        var last = event.target.value
        if (last.length >= 10) { event.preventDefault(); }
        if (event.which !== 44) {
            if (event.which !== 8 && isNaN(String.fromCharCode(event.which))) {
                event.preventDefault();
            }
        }
    }
    handleKeyUp = (event, id) => {
        const _this = document.querySelector('#' + id)
        var value = event.target.value
        value = value.charAt(0) === '0' ? value.substring(1) : value
        _this.value = value.replace(/\D/g, "").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
    }

    handleChange = async (v) => {
        this.setState({
            datav: {
                ...this.state.datav,
                ["negara_uuid"]: v.value,
            },
        });
        this.createBloc.fetchCheck({ uuid: v.region_uuid });
    }

    render() {

        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Tambah Perjalanan Dinas Provinsi</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldNama"
                                                className="col-md-2 col-form-label"
                                            >
                                                Provinsi<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                {
                                                    this.state.loadingCountry ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 38 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <>
                                                            <CustomSelect
                                                                onChange={this.handleChange}
                                                                name="region_uuid"
                                                                id="negara_uuid"
                                                                errorMessage="Pilih Provinsi"
                                                                options={this.state.countries}
                                                                placeholder="Pilih Provinsi"
                                                                optionLabel="region_name"
                                                                optionValue="region_uuid"
                                                                validate={{ required: { value: true } }}
                                                            />
                                                        </>
                                                }
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Regular<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Rp</span>
                                                    </div>
                                                    <AvInput
                                                        name="official_travel_province_value_amount_1"
                                                        placeholder="0"
                                                        align="right"
                                                        type="text"
                                                        errorMessage="Masukkan Nilai"
                                                        className="form-control text-end"
                                                        id="fieldValue1"
                                                        validate={{ required: { value: true } }}
                                                        onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue1") }}
                                                        onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue1") }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Fullday<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Rp</span>
                                                    </div>
                                                    <AvInput
                                                        name="official_travel_province_value_amount_2"
                                                        placeholder="0"
                                                        align="right"
                                                        type="text"
                                                        errorMessage="Masukkan Nilai"
                                                        className="form-control text-end"
                                                        id="fieldValue2"
                                                        validate={{ required: { value: true } }}
                                                        onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue2") }}
                                                        onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue2") }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Fullboard<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="2">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Rp</span>
                                                    </div>
                                                    <AvInput
                                                        name="official_travel_province_value_amount_3"
                                                        placeholder="0"
                                                        align="right"
                                                        type="text"
                                                        errorMessage="Masukkan Nilai"
                                                        className="form-control text-end"
                                                        id="fieldValue3"
                                                        validate={{ required: { value: true } }}
                                                        onKeyPress={(e) => { this.handleKeyPress(e, "fieldValue3") }}
                                                        onKeyUp={(e) => { this.handleKeyUp(e, "fieldValue3") }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="10">
                                                <Button color="success" disabled={!this.state.status_button} type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                                {' '}
                                                <Link to="/master/spd-province.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />

                <ConfirmAlert
                    failedTitle="Gagal!"
                    failedInfo="Data sudah tersedia"

                    showFailed={this.state.failed2}
                    showConfirm={this.state.confirm2}
                    response={this.confirmResponse2}
                />
                {this.state.redirect ?
                    <Redirect to='/master/spd-province.html' />
                    : null}
            </Fragment>
        )
    }

}

export default CreateView