import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Row, Col,
    Button,
    ModalBody,
    Label,
    ModalFooter,
} from "reactstrap"
import { withRouter } from "react-router";
import apiResponse from '../../../../../../services/apiResponse'
import { ConfirmAlert } from '../../../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../../../helpers/ui/FileInput'
import CustomSelect from '../../../../../../helpers/ui/CustomSelect'
import moment from 'moment'

import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import CreateBloc from '../Blocs/CreateBloc'
import { noConflict } from 'lodash';


class OfficialTravelTransportComponent extends Component {

    createBloc = new CreateBloc()

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},

            participantUuid: null,
            overseaType: null,

            loadingLevelOption: true,
            selectedLevel: null,
            optionLevel: {},

            selectedTransport: null,
            optionTransport: [{ label: 'Angkutan Darat', value: 1 }, { label: 'Angkutan Laut', value: 2 }, { label: 'Angkutan Udara', value: 3 }],

        }
    }

    updateLevel = async () => {
        const formData = generateFormData(this.state.dataForm, { field: 'document', name: 'Keuangan/PerjalananDinas/Refund/Refund' })
        await this.createBloc.fetchLevelUpdate(formData)
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.updateLevel()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {

        this.createBloc.levelListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingLevelOption: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionLevel(response)
                    this.setState({
                        loadingLevelOption: false,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.levelUpdateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    this.props.onSolved()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.setState({
            participantUuid: this.props.participantUuid,
            overseaType: this.props.overseaType,
        }, () => {
            this.createBloc.fetchLevelList({ type: this.state.overseaType })
        })
    }

    setOptionLevel = (list) => {
        const itemLevel = []
        list.map((level) => {
            itemLevel.push(level)
            return null
        })
        this.setState({
            selectedLevel: null,
            optionLevel: itemLevel
        });
    }

    componentWillUnmount() {
        this.createBloc.levelListChannel.unsubscribe()
        this.createBloc.levelUpdateChannel.unsubscribe()
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                ...values,
                uuid: this.state.participantUuid
            },
            confirm: true
        })
    }


    render() {
        return (
            <Fragment>
                <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                >
                    <ModalBody>
                        <>
                            <AvField
                                name="uuid"
                                type="hidden"
                                value={this.state.selectedUuid}
                            />
                            <Row className="mb-3">
                                <Label
                                    htmlFor="ppk_name"
                                    className="col-md-12 col-form-label"
                                >
                                    Alat Angkutan<span className="text-danger">*</span>
                                </Label>
                                <Col md="12">
                                    <CustomSelect
                                        name="transport"
                                        defaultValue={this.state.selectedTransport}
                                        errorMessage="Pilih Alat Angkutan"
                                        options={this.state.optionTransport}
                                        placeholder="Pilih Alat Angkutan"
                                        optionLabel="label"
                                        optionValue="value"
                                        validate={{ required: { value: true } }}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Label
                                    htmlFor="ppk_name"
                                    className="col-md-12 col-form-label"
                                >
                                    Tingkat Biaya Perjalanan Dinas<span className="text-danger">*</span>
                                </Label>
                                <Col md="12">
                                    {this.state.loadingLevelOption ?
                                        <Shimmer><div className="shimmer" style={{ width: '100%', height: 36 }}></div></Shimmer>
                                        :
                                        <CustomSelect
                                            name="level"
                                            errorMessage="Pilih Tingkat Biaya"
                                            defaultValue={this.state.selectedLevel}
                                            options={this.state.optionLevel}
                                            placeholder="Pilih Tingkat Biaya"
                                            optionLabel="label"
                                            optionValue="value"
                                            validate={{ required: { value: true } }}
                                            onChange={(selected) => {
                                                //this.handleOnChangeUuid(selected)
                                            }}
                                        />
                                    }
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="12">
                                    <div className="form-check form-switch mb-3">
                                        <AvCheckboxGroup name="transportAgreement" required errorMessage="Agreement harus di centang">
                                            <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                        </AvCheckboxGroup>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="success"
                            type="submit"
                        >
                            Simpan
                        </Button>
                        {' '}
                        <Button
                            color="danger"
                            onClick={() => {
                                this.props.closeTransport()
                            }}
                        >
                            Tutup
                        </Button>
                    </ModalFooter>
                </AvForm>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
            </Fragment >
        )
    }
}

export default withRouter(OfficialTravelTransportComponent);
