import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, useState, useRef } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect"
import Select from "react-select"

import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Modal,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"

import TunsusIndexListComponent from "./Components/TunsusIndexListComponent"

const IndexView = (props) => {
    const [activeTab, setactiveTab] = useState("1")
    const activeRef = useRef()
    const inactiveRef = useRef()

    const reloadActiveRef = () => activeRef.current.setTokenAPI()
    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Tunjangan Khusus Rapel</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Proses Tunjangan Khusus Rapel berdasarkan tahun dan bulan tertentu sesuai dengan
                                    data integrasi (Simpeg) yang sudah di verifikasi
                                </CardSubtitle>
                                <hr />
                                <Row>
                                    <Col md="12">
                                        <div className="p-2 bd-highlight float-end">
                                            <div style={{ paddingTop: 8 }}>
                                                <div
                                                    className="bg-success rounded-circle d-inline-block"
                                                    style={{ height: 10, width: 10 }}
                                                ></div>{" "}
                                                Sudah Integrasi
                                            </div>
                                        </div>
                                        <div className="p-2 bd-highlight float-end">
                                            <div style={{ paddingTop: 8 }}>
                                                <div
                                                    className="bg-danger rounded-circle d-inline-block"
                                                    style={{ height: 10, width: 10 }}
                                                ></div>{" "}
                                                Belum Integrasi
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                            </span>
                                            <span className="d-none d-sm-block">Proses</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="far fa-envelope"></i>
                                            </span>
                                            <span className="d-none d-sm-block">Selesai</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <TunsusIndexListComponent
                                            type="1"
                                            ref={activeRef}
                                            handleInactiveSuccess={reloadInactiveRef}
                                        />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <TunsusIndexListComponent
                                            type="2"
                                            ref={activeRef}
                                            handleInactiveSuccess={reloadActiveRef}
                                        />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView
