import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'

class CreateBloc {

    createChannel = new Subject()
    employeeChannel = new Subject()
    employeeDetailChannel = new Subject()
    getSignerLeft = new Subject()
    getSignerRight = new Subject()
    getSignerPpabp = new Subject()
    checkStatusChannel = new Subject()
    checkPresenceChannel = new Subject()
    checkRankChannel = new Subject()
    checkTaxFinalChannel = new Subject()
    checkEmployeeNewChannel = new Subject()
    checkEmployeeChangeChannel = new Subject()

    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.employeeChannel.next({ status: apiResponse.INITIAL })
        this.employeeDetailChannel.next({ status: apiResponse.INITIAL })
        this.getSignerLeft.next({ status: apiResponse.INITIAL })
        this.getSignerRight.next({ status: apiResponse.INITIAL })
        this.getSignerPpabp.next({ status: apiResponse.INITIAL })
        this.checkStatusChannel.next({ status: apiResponse.INITIAL })
        this.checkPresenceChannel.next({ status: apiResponse.INITIAL })
        this.checkRankChannel.next({ status: apiResponse.INITIAL })
        this.checkTaxFinalChannel.next({ status: apiResponse.INITIAL })
        this.checkEmployeeNewChannel.next({ status: apiResponse.INITIAL })
        this.checkEmployeeChangeChannel.next({ status: apiResponse.INITIAL })

    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchEmployeeList = async (query, cancelToken) => {
        this.employeeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployeeList(query, cancelToken).then((result) => {
                this.employeeChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchEmployeeDetail = async (query) => {
        this.employeeDetailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployeeDetail(query).then((result) => {
                this.employeeDetailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeDetailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchGetSignerLeft = async (query) => {
        this.getSignerLeft.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetSigner(query).then((result) => {
                this.getSignerLeft.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getSignerLeft.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }
    fetchGetSignerRight = async (query) => {
        this.getSignerRight.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetSigner(query).then((result) => {
                this.getSignerRight.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getSignerRight.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }
    fetchGetSignerPpabp = async (query) => {
        this.getSignerPpabp.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchGetSigner(query).then((result) => {
                this.getSignerPpabp.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.getSignerPpabp.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchCheckStatus = async (query) => {
        this.checkStatusChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckStatus(query).then((result) => {
                this.checkStatusChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkStatusChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchCheckPresence = async (query) => {
        this.checkPresenceChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckPresence(query).then((result) => {
                this.checkPresenceChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkPresenceChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchCheckRank = async (query) => {
        this.checkRankChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckRank(query).then((result) => {
                this.checkRankChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkRankChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchCheckTaxFinal = async (query) => {
        this.checkTaxFinalChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckTaxFinal(query).then((result) => {
                this.checkTaxFinalChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkTaxFinalChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchCheckEmployeeNew = async (query) => {
        this.checkEmployeeNewChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckEmployeeNew(query).then((result) => {
                this.checkEmployeeNewChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkEmployeeNewChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchCheckEmployeeChange = async (query) => {
        this.checkEmployeeChangeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCheckEmployeeChange(query).then((result) => {
                this.checkEmployeeChangeChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.checkEmployeeChangeChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

}

export default CreateBloc