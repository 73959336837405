import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import IndexViewRepository from '../Repositories/IndexViewRepository'


class IndexViewBloc {

    rowPpkListActiveChannel = new Subject();
    rowPpkListInactiveChannel = new Subject();
    inactiveChannel = new Subject();
    activeChannel = new Subject();
    ppkDetailChannel = new Subject();

    repository = new IndexViewRepository()

    constructor() {
        this.rowPpkListActiveChannel.next({ status: apiResponse.INITIAL })
        this.rowPpkListInactiveChannel.next({ status: apiResponse.INITIAL })
        this.ppkDetailChannel.next({ status: apiResponse.INITIAL })
        this.inactiveChannel.next({status: apiResponse.INITIAL})
        this.activeChannel.next({status: apiResponse.INITIAL})
    }

    fetchRowPpkListActive = async (query, cancelToken) => {
        this.rowPpkListActiveChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRowPpkList({ ...query, status: 1 }, cancelToken).then((result) => {
                this.rowPpkListActiveChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowPpkListActiveChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchRowPpkListInactive = async (query, cancelToken) => {
        this.rowPpkListInactiveChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRowPpkList({ ...query, status: 0 }, cancelToken).then((result) => {
                this.rowPpkListInactiveChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowPpkListInactiveChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }

    fetchRowPpkDetail = async (query) => {
        this.ppkDetailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchPpkDetail(query).then((result) => {
                this.ppkDetailChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.ppkDetailChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }
    
    fetchInactive = async (query) => {
        this.inactiveChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchInactive(query).then((result) => {
                this.inactiveChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.inactiveChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchActive = async (query) => {
        this.activeChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchActive(query).then((result) => {
                this.activeChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.activeChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
}

export default IndexViewBloc