import { runWithAdal } from 'react-adal'
import { authContext } from './services/adalConfig'

import TimeAgo from 'javascript-time-ago'
import id from 'javascript-time-ago/locale/id'
TimeAgo.addDefaultLocale(id)

const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {

  require('./preload.jsx');

}, DO_NOT_LOGIN);