import {
    apiRequest,
    masterOvertimeValue
} from "../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterOvertimeValue, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository