import {
    apiRequest,
    officialTravelRegisterList
} from "../../../../../services/adapters/process"

class IndexViewRepository {

    fetchRowPpkListActive = async (query, cancelToken) => {
        return await apiRequest("get", officialTravelRegisterList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default IndexViewRepository