import { apiRequest, tunsusRegulerVerify, tunsusRegulerCompare } from "../../../../../../services/adapters/output"

class DownloadRepository {
    fetchDownloadVerify = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerVerify,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadCompare = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerCompare,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
