import { Subject } from "rxjs"
import apiResponse from "../../../../../../services/apiResponse"
import ProcessRepository from "../Repositories/ProcessRepository"

class ProcessBloc {
    processUpdateChannel = new Subject()
    processDetailChannel = new Subject()
    processParticipantChannel = new Subject()
    processSourceChannel = new Subject()
    repository = new ProcessRepository()

    constructor() {
        this.processUpdateChannel.next({ status: apiResponse.INITIAL })
        this.processDetailChannel.next({ status: apiResponse.INITIAL })
        this.processParticipantChannel.next({ status: apiResponse.INITIAL })
        this.processSourceChannel.next({ status: apiResponse.INITIAL })
    }

    fetchUpdate = async (query) => {
        this.processUpdateChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchUpdate(query)
                .then((result) => {
                    this.processUpdateChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.processUpdateChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchDetail = async (query) => {
        this.processDetailChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchDetail(query)
                .then((result) => {
                    this.processDetailChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.processDetailChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchParticipant = async (query) => {
        this.processParticipantChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchParticipant(query)
                .then((result) => {
                    this.processParticipantChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.processParticipantChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchSource = async (query) => {
        this.processSourceChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchSource(query)
                .then((result) => {
                    this.processSourceChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.processSourceChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }
}

export default ProcessBloc
