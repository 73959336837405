import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Link, Redirect } from "react-router-dom"

import { Tooltip, Modal } from "reactstrap"

import MKIDatatable from "../../../../../../modules/mki-datatable/lib"

import { cancelToken } from "../../../../../../services/adapters/base"

import IndexViewBloc from "../Blocs/IndexViewBloc"
import apiResponse from "../../../../../../services/apiResponse"
import PtspHistoryComponent from "./PtspHistoryComponent"
import PtspDownloadComponent from "./PtspDownloadComponent"

class PtspBkpmIndexListComponent extends Component {
    indexViewBloc = new IndexViewBloc()

    source
    historyTable = "historyPayrollPtspBkpmIndexListComponent"
    defaultOrder = "ptsp_month"
    defaultSize = 20
    defaultSort = "desc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            showPtspHistory: false,
            tplnHistoryTitle: null,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            year: this.props.year,
            type: 1,
        }
        await this.indexViewBloc.fetchMonths(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {}

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.monthsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.monthsChannel.unsubscribe()
    }

    columns = [
        {
            key: "ptsp_id",
            text: "ID",
            className: "",
            align: "center",
            width: "100",
            sortable: true,
            cell: (record) => {
                return <>{record.ptsp_id.toString().padStart(6, "0")}</>
            },
        },
        {
            key: "ptsp_status",
            text: "Proses",
            className: "",
            sortable: true,
            width: "140",
            align: "center",
            cell: (record) => {
                return (
                    <>
                        {record.ptsp_status === 2 || record.ptsp_status === 4 ? (
                            <button
                                className="btn btn-soft-primary btn-sm"
                                onClick={() => {}}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                done
                            </button>
                        ) : (
                            <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                done
                            </button>
                        )}{" "}
                        {record.ptsp_status === 4 ? (
                            <button
                                className="btn btn-soft-success btn-sm"
                                onClick={() => {}}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                approved
                            </button>
                        ) : (
                            <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                approved
                            </button>
                        )}
                    </>
                )
            },
        },
        {
            key: "ptsp_year",
            text: "Tahun",
            className: "",
            width: "140",
            align: "center",
            sortable: true,
        },
        {
            key: "ptsp_month",
            text: "Bulan",
            className: "",
            sortable: true,
            cell: (record) => {
                return <>{record.ptsp_month_name}</>
            },
        },
        {
            key: "ptsp_employee_total",
            text: "Total Pegawai",
            className: "",
            align: "center",
            width: "140",
            sortable: true,
        },
        {
            key: "ptsp_status_employee",
            text: "Simpeg",
            className: "",
            sortable: true,
            width: "100",
            align: "center",
            cell: (record) => {
                var className =
                    (record.ptsp_status_employee === 1 ? "bg-success" : "bg-danger") + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "ptsp_status_presence",
            text: "Absensi",
            className: "",
            sortable: true,
            width: "100",
            align: "center",
            cell: (record) => {
                var className =
                    (record.ptsp_status_presence === 1 ? "bg-success" : "bg-danger") + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "ptsp_uuid",
            text: "Aksi",
            className: "",
            width: "140",
            align: "center",
            cell: (record) => {
                var dataReady =
                    record.ptsp_status_employee === 1 && record.ptsp_status_presence === 1
                        ? record.ptsp_status === 1 || record.ptsp_status === 3
                            ? true
                            : false
                        : false
                var dataGenerate = record.ptsp_status === 2 ? true : false
                var dataReject = record.ptsp_status === 3 ? true : false
                var dataDone = record.ptsp_status === 4 ? true : false
                const thisId = record.ptsp_id
                return (
                    <>
                        <span>
                            {accessRole("create") ? (
                                <>
                                    {dataReady ? (
                                        <Link
                                            className={"btn btn-" + (dataReject ? "danger" : "primary") + " btn-sm"}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                            to={"/payroll/ptsp-rapel.html?create&uuid=" + record.ptsp_uuid}
                                        >
                                            <i className="uil-cog"></i>
                                        </Link>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-cog"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            <button
                                className="btn btn-warning btn-sm"
                                onClick={() => {
                                    this.setState({
                                        showPtspHistory: true,
                                        historyUuid: record.ptsp_uuid,
                                        tplnHistoryTitle: record.ptsp_month_name + " " + this.props.year,
                                    })
                                }}
                                style={{
                                    marginRight: "2px",
                                    padding: "2px 5px",
                                }}
                            >
                                <i className="uil-clock-eight"></i>
                            </button>
                            {accessRole("approve") ? (
                                <>
                                    {dataGenerate ? (
                                        <Link
                                            className={"btn btn-success btn-sm"}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                            to={"/payroll/ptsp-rapel.html?approve&uuid=" + record.ptsp_uuid}
                                        >
                                            <i className="uil-check"></i>
                                        </Link>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-check"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            {accessRole("print") ? (
                                <>
                                    {dataDone ? (
                                        <>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                id={"print-" + thisId}
                                                onClick={() => {
                                                    this.setState({
                                                        showPtspDownload: true,
                                                        downloadUuid: record.ptsp_uuid,
                                                    })
                                                }}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                            >
                                                <i className="uil-arrow-to-bottom"></i>
                                            </button>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["print" + thisId]}
                                                target={"print-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["print" + thisId]: !this.state["print" + thisId],
                                                    })
                                                }}
                                            >
                                                Download
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-arrow-to-bottom"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                        </span>
                    </>
                )
            },
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1000}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="md"
                    isOpen={this.state.showPtspHistory}
                    toggle={() => {
                        this.setState({
                            showPtspHistory: this.state.showPtspHistory,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Riwayat Proses PTSP {this.state.tplnHistoryTitle}
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showPtspHistory: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <PtspHistoryComponent uuid={this.state.historyUuid} />
                    </div>
                </Modal>
                <Modal
                    size="sm"
                    isOpen={this.state.showPtspDownload}
                    toggle={() => {
                        this.setState({
                            showPtspDownload: !this.state.showPtspDownload,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Unduh Berkas PTSP BKPM
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showPtspDownload: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <PtspDownloadComponent uuid={this.state.downloadUuid} />
                    </div>
                </Modal>
            </>
        )
    }
}

export default PtspBkpmIndexListComponent
