import {
    apiRequest,
    tplnSusulanProcessSubmit,
    tplnSusulanProcessEmployees,
    tplnSusulanProcessEmployeeDetail,
    tplnSusulanGetSigner,
    tplnSusulanCheckStatus,
    tplnSusulanCheckKurs,
    tplnSusulanCheckGpp,
    tplnSusulanCheckPtkp,
    tplnSusulanCheckPkp,
    tplnSusulanCheckCountry,
    tplnSusulanCheckRank,
    tplnSusulanCheckEmployeeNew,
    tplnSusulanCheckEmployeeChange
} from "../../../../../../services/adapters/process"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", tplnSusulanProcessSubmit, {
            body: query
        }, true)
    }

    fetchEmployeeList = async (query, cancelToken) => {
        return await apiRequest("get", tplnSusulanProcessEmployees, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tplnSusulanProcessEmployeeDetail, {
            params: query
        }, true)
    }

    fetchGetSigner = async (query) => {
        return await apiRequest("get", tplnSusulanGetSigner, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tplnSusulanCheckStatus, {
            params: query
        }, true)
    }
    
    fetchCheckKurs = async (query) => {
        return await apiRequest("get", tplnSusulanCheckKurs, {
            params: query
        }, true)
    }

    fetchCheckGpp = async (query) => {
        return await apiRequest("get", tplnSusulanCheckGpp, {
            params: query
        }, true)
    }

    fetchCheckPtkp = async (query) => {
        return await apiRequest("get", tplnSusulanCheckPtkp, {
            params: query
        }, true)
    }
    
    fetchCheckPkp = async (query) => {
        return await apiRequest("get", tplnSusulanCheckPkp, {
            params: query
        }, true)
    }

    fetchCheckCountry = async (query) => {
        return await apiRequest("get", tplnSusulanCheckCountry, {
            params: query
        }, true)
    }

    fetchCheckRank = async (query) => {
        return await apiRequest("get", tplnSusulanCheckRank, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeNew = async (query) => {
        return await apiRequest("get", tplnSusulanCheckEmployeeNew, {
            params: query
        }, true)
    }
    
    fetchCheckEmployeeChange = async (query) => {
        return await apiRequest("get", tplnSusulanCheckEmployeeChange, {
            params: query
        }, true)
    }

}

export default CreateRepository