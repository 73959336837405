import {
    apiRequest,
    masterReferenceNrsList,
    masterReferenceNrsActivated,
    masterCmsListPengumuman,
    masterCmsListPeraturan,
    masterCmsListfaq,
    masterCmsInformasiDelete
} from "../../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    
    fetchInactive = async (query, cancelToken) => {
        return await apiRequest("get", masterCmsInformasiDelete, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }


    fetchActive = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceNrsActivated, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTablePengumuman = async (query, cancelToken) => {
        return await apiRequest("get", masterCmsListPengumuman, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTablePeraturan = async (query, cancelToken) => {
        return await apiRequest("get", masterCmsListPeraturan, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowTableFaq = async (query, cancelToken) => {
        return await apiRequest("get", masterCmsListfaq, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default DataTableRepository