import { apiRequest, officialTravelRapelSubmissionList } from "../../../../../../services/adapters/process"

class DataTableRepository {
    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            officialTravelRapelSubmissionList,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }
}

export default DataTableRepository
