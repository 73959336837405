import { apiRequest, tunkinSusulanEmployeeMonths } from "../../../../../../services/adapters/process"

class EmployeeViewRepository {
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunkinSusulanEmployeeMonths,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }
}

export default EmployeeViewRepository
