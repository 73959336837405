import {
    apiRequest,
    masterPtspTunkinValue,
    masterPtspTunkinGrade
} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterPtspTunkinValue, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchRowGrade = async (query, cancelToken) => {
        return await apiRequest("get", masterPtspTunkinGrade, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }


}

export default DataTableRepository