import { config } from "../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link, Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../services/apiResponse'

import ReactTimeAgo from 'react-time-ago'

class DataTableComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyNotificationDataTable'
    defaultOrder = 'notification_date'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            gotoLink: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order
        }
        //await this.dataTableBloc.fetchRowTable(query, this.source.token)
        setTimeout(() => {
            this.setState({
                loadingTable: false
            })
        }, 1000)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        {
            key: "menu_name",
            text: "Nama Menu",
            sortable: true,
            width: '20%'
        },
        {
            key: "activity_action",
            text: "Aksi",
            width: '120px',
            sortable: true,
        },
        {
            key: "activity_description",
            text: "Keterangan",
            sortable: true,
        },
        {
            key: "notification_date",
            text: "Tanggal",
            align: 'center',
            sortable: true,
            width: '160',
            cell: record => {
                const thisId = record.sample_id
                return (
                    <center>
                        <ReactTimeAgo id={"create-" + thisId} date={record.notification_date} locale="id" timeStyle="round-minute" />
                        <Tooltip
                            style={{
                                marginBottom: 2
                            }}
                            placement="top" isOpen={this.state["create" + thisId]} target={"create-" + thisId}
                            toggle={() => {
                                this.setState({
                                    ["create" + thisId] : !this.state["create" + thisId]
                                })
                            }}
                        >{record.notification_date}</Tooltip>
                    </center>
                )
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                { this.state.gotoLink !== null ?
                    <Redirect to={this.state.gotoLink}/>
                : null }
            </>
        )
    }

}

export default DataTableComponent