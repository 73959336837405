import {
    apiRequest,
    ptspRapelYears,
    ptspRapelBkpmMonths,
    ptspRapelPpklMonths,
    ptspRapelHistory,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {
    fetchYears = async () => {
        return await apiRequest("get", ptspRapelYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            ptspRapelBkpmMonths,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            ptspRapelPpklMonths,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchHistory = async (query) => {
        return await apiRequest(
            "get",
            ptspRapelHistory,
            {
                params: query,
            },
            true
        )
    }
}

export default IndexViewRepository
