import {
    apiRequest,
    tunsusRegulerApproveSubmit,
    tunsusRegulerRejectSubmit,
    tunsusRegulerResultEmployee,
    tunsusRegulerCheckStatus,
    tunsusRegulerResultEmployeeDetail,
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {
    fetchApprove = async (query) => {
        return await apiRequest(
            "post",
            tunsusRegulerApproveSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchReject = async (query) => {
        return await apiRequest(
            "post",
            tunsusRegulerRejectSubmit,
            {
                body: query,
            },
            true
        )
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            tunsusRegulerResultEmployee,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerResultEmployeeDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerCheckStatus,
            {
                params: query,
            },
            true
        )
    }
}

export default ApproveViewRepository
