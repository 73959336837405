import {
    apiRequest,
    /* sampleDetail,
    sampleUpdate */
} from "../../../../../../services/adapters/base"

class UpdateRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", ''/* sampleDetail */, {
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", ''/* sampleUpdate */, {
            body: query
        }, true)
    }
}

export default UpdateRepository