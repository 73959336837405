import {
    apiRequest,
    masterCmsInformasiDetail,
    masterCmsInformasiEdit,
    masterCmsGetInformasi
} from "../../../../../../../services/adapters/master"

class UpdateRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", masterCmsInformasiDetail, {
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", masterCmsInformasiEdit, {
            body: query
        }, true)
    }

    fetchGetLayanan = async () => {
        return await apiRequest("get", masterCmsGetInformasi, {}, true)
    }
}

export default UpdateRepository