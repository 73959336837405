import {
    apiRequest,
    tunkinSusulanApproveSubmit,
    tunkinSusulanRejectSubmit,
    tunkinSusulanResultEmployee,
    tunkinSusulanCheckStatus,
    tunkinSusulanResultEmployeeDetail
} from "../../../../../../services/adapters/process"

class ApproveViewRepository {

    fetchApprove = async (query) => {
        return await apiRequest("post", tunkinSusulanApproveSubmit, {
            body: query
        }, true)
    }

    fetchReject = async (query) => {
        return await apiRequest("post", tunkinSusulanRejectSubmit, {
            body: query
        }, true)
    }

    fetchResultList = async (query, cancelToken) => {
        return await apiRequest("get", tunkinSusulanResultEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployeeDetail = async (query) => {
        return await apiRequest("get", tunkinSusulanResultEmployeeDetail, {
            params: query
        }, true)
    }

    fetchCheckStatus = async (query) => {
        return await apiRequest("get", tunkinSusulanCheckStatus, {
            params: query
        }, true)
    }
}

export default ApproveViewRepository