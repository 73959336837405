import {
    apiRequest,
    tunkinRegulerEmployeeYears,
    tunkinRegulerEmployeeMonths,
} from "../../../../../../services/adapters/process"

class EmployeeViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", tunkinRegulerEmployeeYears, {}, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", tunkinRegulerEmployeeMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default EmployeeViewRepository