import {
    apiRequest,
    masterReferenceBankAccountList,
    masterReferenceBankAccountDelete,
    masterReferenceBankAccountUpload,
} from "../../../../../../services/adapters/master"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterReferenceBankAccountList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchDelete = async (query) => {
        return await apiRequest("post", masterReferenceBankAccountDelete, {
            body: query
        }, true)
    }

    fetchUpload = async (query) => {
        return await apiRequest("post", masterReferenceBankAccountUpload, {
            body: query
        }, true)
    }
}

export default DataTableRepository