import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";
import Shimmer from "react-shimmer-effect"
import apiResponse from '../../../../../services/apiResponse'

import DetailBloc from './Blocs/DetailBloc'


class IndexView extends Component {

    detailBloc = new DetailBloc();

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            messageError: ""
        }
    }

    
    componentDidMount() {
       

        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            dataForm: response
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.detailBloc.fetchDetail({ uuid: queryParams.get('uuid') })
    }

    componentWillUnmount() {
        this.detailBloc.detailChannel?.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            
                            { this.state.messageError ? <>
                                <div className="alert alert-danger">
                                    <strong>{ this.state.messageError }</strong>
                                </div>
                            </> : <></> }

                            <Card>
                                <CardBody>
                                    <CardTitle>Detail Mapping NRS</CardTitle>
                                    <hr />

                                    { this.state.dataForm ? <>
                                    
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr key={Math.random()}>
                                                    <td width={200}>Kode NRS</td>
                                                    <td width={2}>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_code}</td>
                                                </tr>
                                                <tr>
                                                    <td>Kode KPPN</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_kppn_code}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tipe Supplier</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_supplier_type_code}</td>
                                                </tr>
                                                <tr>
                                                    <td>Kode Pos</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_supplier_type_code}</td>
                                                </tr>
                                                <tr>
                                                    <td>NPWP Supplier</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_supplier_npwp}</td>
                                                </tr>
                                                <tr>
                                                    <td>Nama Supplier</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_supplier_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Bank Supplier</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_supplier_bank}</td>
                                                </tr>
                                                <tr>
                                                    <td>Kode NAB</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_nab_code}</td>
                                                </tr>
                                                <tr>
                                                    <td>Kode Valas</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_currency_code}</td>
                                                </tr>
                                                <tr>
                                                    <td>Kode Swift/IBAN</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_swift_code}</td>
                                                </tr>
                                                <tr>
                                                    <td>NIP Penerima</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_recipient_nip}</td>
                                                </tr>
                                                <tr>
                                                    <td>Nama Penerima</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_recipient_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>NPWP Penerima</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_recipient_npwp}</td>
                                                </tr>
                                                <tr>
                                                    <td>No Rekening Penerima</td>
                                                    <td>:</td>
                                                    <td>{this.state.dataForm.nrs_employee_nrs_account_number}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    
                                    </> : <>
                                    
                                        <Shimmer>
                                            <div
                                            className="w-100"
                                            style={{
                                                height: "2.415rem",
                                                borderRadius: "0.25rem",
                                            }}
                                            >
                                            &nbsp;
                                            </div>
                                    </Shimmer>

                                    </> }

                                  
                                    <Link to="/master/mapping-nrs.html">
                                        <Button color="success"><i className="uil-arrow-left"></i> Kembali</Button>
                                    </Link>
                                </CardBody>
                                
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);