import {
    apiRequest,
    masterCmsDPCreate,
    masterCmsGetLayanan
} from "../../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterCmsDPCreate, {
            body: query
        }, true)
    }

    fetchGetLayanan = async () => {
        return await apiRequest("get", masterCmsGetLayanan, {}, true)
    }
}

export default CreateRepository