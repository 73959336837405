import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../../services/apiResponse'

import UpdateBloc from './Blocs/UpdateBloc'


class IndexView extends Component {

    updateBloc = new UpdateBloc();

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            messageError: ""
        }
    }

    submitData = async () => {
        await this.updateBloc.fetchUpdate(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.nrs_uuid,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: result.data.status,
                        messageError: result.data.status === false ? result.data.message : ""
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        this.setState({
                            dataForm: response
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.updateBloc.fetchDetail({ uuid: queryParams.get('uuid') })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            
                            { this.state.messageError ? <>
                                <div className="alert alert-danger">
                                    <strong>{ this.state.messageError }</strong>
                                </div>
                            </> : <></> }

                            <Card>
                                <CardBody>
                                    <CardTitle>Edit NRS</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >

                                      
                                    <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Kode<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_code"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Kode"
                                                    className="form-control"
                                                    id="nrs_code"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.dataForm.nrs_code}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Nama<span className="text-danger">*</span>
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_name"
                                                    align="right"
                                                    type="text"
                                                    errorMessage="Masukkan Nama"
                                                    className="form-control"
                                                    id="nrs_name"
                                                    validate={{ required: { value: true } }}
                                                    value={this.state.dataForm.nrs_name}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label
                                                htmlFor="fieldValue"
                                                className="col-md-2 col-form-label"
                                            >
                                                Keterangan
                                            </Label>
                                            <Col md="4">
                                                <AvField
                                                    name="nrs_description"
                                                    align="right"
                                                    type="textarea"
                                                    rows="6"
                                                    errorMessage="Masukkan Keterangan"
                                                    className="form-control"
                                                    id="nrs_description"
                                                    validate={{ required: { value: false } }}
                                                    value={this.state.dataForm.nrs_description}
                                                />
                                            </Col>
                                        </Row>
                                      
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <div className="form-check form-switch mb-3">
                                                    <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                        <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                    </AvCheckboxGroup>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="2"></Col>
                                            <Col md="9">
                                                <Link to="/master/nrs.html">
                                                    <Button color="danger"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                {this.state.redirect ?
                    <Redirect to='/master/nrs.html' />
                    : null}
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);