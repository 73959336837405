import React, { Component } from "react"
import Shimmer from "react-shimmer-effect"
import {
    Row,
    Col,
    Alert
} from 'reactstrap'
import { Link } from "react-router-dom"
import MinioClient from '../../../../../../services/minioClient'

import IndexViewBloc from '../../../Monitoring/Index/Blocs/IndexBloc'
import apiResponse from '../../../../../../services/apiResponse'

import LoadingComponent from './LoadingComponent'

import moment from 'moment'

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'

class EmployeeHistoryComponentIIPC extends Component {

    indexViewBloc = new IndexViewBloc()
    minioClient = new MinioClient()

    constructor(props) {
        super(props)
        this.state = {
            detailData: {},
            loadingDetail: true,
            changeData: [],
            loadingChange: true,
        }
    }

    convertUrl = (response) => {
        var newResponse = []
        var totalRow = response.length
        if (totalRow > 0) {
            response.map(async (f, i) => {
                f.documents_path = await this.minioClient.get(f.documents_path)
                newResponse[newResponse.length] = f
                if (i === (totalRow - 1)) {
                    this.setState({
                        changeData: newResponse,
                        loadingChange: false
                    })
                }
            })
        } else {
            this.setState({
                loadingChange: false
            })
        }

    }

    componentDidMount() {
        this.indexViewBloc.fetchDetailIIPCChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingDetail: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingDetail: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        detailData: response,
                        loadingDetail: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.indexViewBloc.fetchHistoryIIPCChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingChange: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.convertUrl(response)
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.indexViewBloc.fetchDetailIIPC({ uuid: this.props.uuid })
        this.indexViewBloc.fetchHistoryIIPC({ uuid: this.props.uuid })
    }

    componentWillUnmount() {
        this.indexViewBloc.fetchDetailIIPCChannel.unsubscribe()
        this.indexViewBloc.fetchHistoryIIPCChannel.unsubscribe()
    }

    columns = [
        {
            key: "employee_transfer_detail_decree_number",
            text: "Nomor SK",
            sortable: true,
            cell: record => {
                return <>{record.employee_transfer_detail_decree_number ? record.employee_transfer_detail_decree_number : "-"}</>
            }
        },
        {
            key: "employee_transfer_detail_decree_date",
            text: "Tanggal SK",
            sortable: true,
            cell: record => {
                return <>{record.employee_transfer_detail_decree_date ? moment(record.employee_transfer_detail_decree_date).format('LL') : "-"}</>
            }
        },
        {
            key: "employee_transfer_detail_tmt_date",
            text: "TMT Mulai",
            sortable: true,
            cell: record => {
                return <>{record.employee_transfer_detail_tmt_date ? moment(record.employee_transfer_detail_tmt_date).format('LL') : "-"}</>
            }
        },
        {
            key: "employee_transfer_detail_tmt_date_end",
            text: "TMT Berakhir",
            sortable: true,
            cell: record => {
                return <>{record.employee_transfer_detail_tmt_date_end ? 
                    moment(record.employee_transfer_detail_tmt_date_end).format('LL') : "-"}</>
            }
        },
        {
            key: "documents_path",
            text: "Dokumen SK",
            sortable: false,
            cell: record => {
                return (
                    <div className="d-flex">
                        <span>
                            <button
                                className="btn btn-success btn-sm"
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}
                                onClick={() => window.open(record.documents_path, '_blank')}
                            >
                                <i className="uil-file-download"></i>
                            </button>
                        </span>
                    </div>
                )
            }
        },
        {
            key: "created_at",
            text: "Tanggal dibuat",
            sortable: true,
            cell: record => {
                return <>{moment(record.employee_transfer_detail_create_date).format('LL')}</>
            }
        },
        {
            key: "status",
            text: "Status",
            sortable: true,
            width: '140',
            align: 'center',
            cell: record => {
                const isActive = record.employee_transfer_detail_log_name === 'Aktif';
                return (
                    <button
                        className={`btn ${isActive ? 'btn-soft-primary' : 'btn-soft-danger'} btn-sm`}
                        style={{ padding: '2px 5px', borderRadius: 16 }}
                    >
                        {record.employee_transfer_detail_log_name ? record.employee_transfer_detail_log_name : 'Tidak Aktif'}
                    </button>
                )
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable?.tableClass}
                    config={{
                        ...this.configDatatable,
                        pagination: {
                            showPaginationInfo: false,
                            showPreviousNextOnly: true,
                            previousText: "Sebelumnya",
                            nextText: "Selanjutnya",
                            pageButtonLimit: 0
                        }
                    }}
                    records={this.state.changeData}
                    columns={this.columns}
                    loading={this.state.loadingChange}
                />
            </>
        )
    }
}
export default EmployeeHistoryComponentIIPC