import { Subject } from "rxjs"
import apiResponse from "../../../../../../services/apiResponse"
import DownloadRepository from "../Repositories/DownloadRepository"

class DownloadBloc {
    detailBeforeChannel = new Subject()
    detailAfterChannel = new Subject()
    detailRapelChannel = new Subject()
    sspChannel = new Subject()
    repository = new DownloadRepository()

    constructor() {
        this.detailBeforeChannel.next({ status: apiResponse.INITIAL })
        this.detailAfterChannel.next({ status: apiResponse.INITIAL })
        this.detailRapelChannel.next({ status: apiResponse.INITIAL })
        this.sspChannel.next({ status: apiResponse.INITIAL })
    }

    fetchDownloadDetailBefore = async (query) => {
        this.detailBeforeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchDownloadDetailBefore(query)
                .then((result) => {
                    this.detailBeforeChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.detailBeforeChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchDownloadDetailAfter = async (query) => {
        this.detailAfterChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchDownloadDetailAfter(query)
                .then((result) => {
                    this.detailAfterChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.detailAfterChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchDownloadDetailRapel = async (query) => {
        this.detailRapelChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchDownloadDetailRapel(query)
                .then((result) => {
                    this.detailRapelChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.detailRapelChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }

    fetchDownloadSsp = async (query) => {
        this.sspChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchDownloadSsp(query)
                .then((result) => {
                    this.sspChannel.next({ status: apiResponse.COMPLETED, data: result })
                })
                .catch((error) => {
                    this.sspChannel.next({ status: apiResponse.ERROR, data: error })
                })
        } catch (error) {}
    }
}

export default DownloadBloc
