import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import DownloadRepository from '../Repositories/DownloadRepository'


class DownloadBloc {

    monitoringChannel = new Subject();
    repository = new DownloadRepository()

    constructor() {
        this.monitoringChannel.next({ status: apiResponse.INITIAL })
    }

    fetchMonitoring = async (query) => {
        this.monitoringChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchMonitoring(query).then((result) => {
                this.monitoringChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.monitoringChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }
}

export default DownloadBloc