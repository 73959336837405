import {
    apiRequest,
    masterSpdProvinceCreate,
    masterSpdProvinceprovince,
    masterSpdProvinceprovinceCheck
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterSpdProvinceCreate, {
            body: query
        }, true)
    }

    fetchCountryList = async () => {
        return await apiRequest("get", masterSpdProvinceprovince, {}, true)
    }

    fetchCheck = async (query) => {
        return await apiRequest("post", masterSpdProvinceprovinceCheck, {
            body: query
        }, true)
    }
    



}

export default CreateRepository