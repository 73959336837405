import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Shimmer from "react-shimmer-effect";
import Select from "react-select"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Modal
} from "reactstrap"

import EmployeeIndexListComponent from './Components/EmployeeIndexListComponent'

import IndexViewBloc from './Blocs/IndexViewBloc'
import apiResponse from '../../../../../services/apiResponse'


class IndexView extends Component {

    indexViewBloc = new IndexViewBloc()
    thisDate = new Date()
    /* thisYear = this.thisDate.getFullYear()
    thisMonth = "00".substring(0, "00".length - (this.thisDate.getMonth() + 1).toString().length) + (this.thisDate.getMonth() + 1).toString() */
    thisYear = this.thisDate.getMonth() === 0 ? this.thisDate.getFullYear() - 1 : this.thisDate.getFullYear()
    thisMonths = this.thisDate.getMonth() === 0 ? 12 : this.thisDate.getMonth() + 1
    thisMonth = "00".substring(0, "00".length - (this.thisMonths).toString().length) + (this.thisMonths).toString()

    monthName = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ]

    constructor(props) {
        super(props)
        this.state = {
            showTunkinHistory: false,
            loadingCurrent: true,
            selectedYear: { label: this.thisYear.toString(), value: this.thisYear },
            selectedMonth: { value: this.thisMonth, label: this.monthName[parseInt(this.thisMonth * 1) - 1] },
            optionMonth: [
                { value: '01', label: this.monthName[0] },
                { value: '02', label: this.monthName[1] },
                { value: '03', label: this.monthName[2] },
                { value: '04', label: this.monthName[3] },
                { value: '05', label: this.monthName[4] },
                { value: '06', label: this.monthName[5] },
                { value: '07', label: this.monthName[6] },
                { value: '08', label: this.monthName[7] },
                { value: '09', label: this.monthName[8] },
                { value: '10', label: this.monthName[9] },
                { value: '11', label: this.monthName[10] },
                { value: '12', label: this.monthName[11] },
            ],
            selectedStatus: { label: 'Semua', value: '0' },
            optionStatus: [
                { value: '0', label: "Semua" },
                { value: '1', label: "Ada perubahan" },
                { value: '2', label: "Tidak ada perubahan" }
            ],
        }

    }

    loadCurrent = async () => {
        await this.indexViewBloc.fetchYears()
    }

    componentDidMount() {
        this.indexViewBloc.yearsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionYear(response)
                    this.setState({
                        currentRegulation: response,
                        loadingCurrent: false,
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.loadCurrent()
    }

    setOptionYear = (list) => {
        const itemYear = []
        list.map((year) => {
            itemYear.push({ label: year.toString(), value: year })
            return null
        })
        this.setState({
            selectedYear: itemYear.length > 0 ? (itemYear[0].value === this.thisYear ? itemYear[0] : { label: this.thisYear.toString(), value: this.thisYear }) : { label: this.thisYear.toString(), value: this.thisYear },
            optionYear: [
                {
                    label: "Tahun",
                    options: itemYear
                }
            ]
        })
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState({
            selectedYear: selectedYear,
            loadingCurrent: true
        }, () => {
            this.setState({
                loadingCurrent: false
            })
        });
    }

    handleOnChangeMonth = (selectedMonth) => {
        this.setState({
            selectedMonth: selectedMonth,
            loadingCurrent: true
        }, () => {
            this.setState({
                loadingCurrent: false
            })
        });
    }

    handleOnChangeStatus = (selectedStatus) => {
        this.setState({
            selectedStatus: selectedStatus,
            loadingCurrent: true
        }, () => {
            this.setState({
                loadingCurrent: false
            })
        });
    }

    componentWillUnmount() {
        this.indexViewBloc.yearsChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Data Kepegawaian</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Riwayat Data Kepegawaian hasil sinkronisasi dengan Simpeg untuk bulan berkenaan.
                                    </CardSubtitle>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <div className="d-flex flex-row bd-highlight float-start">
                                                <div className="pt-2 pe-2 pb-2 bd-highlight">
                                                    <div style={{ paddingTop: 8 }}>Tahun</div>
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    {
                                                        this.state.loadingCurrent ?
                                                            <><Shimmer><div className="shimmer" style={{ width: 116, height: 36 }}></div></Shimmer>{" "}</>
                                                            :
                                                            <>
                                                                <div style={{ width: 116 }}>
                                                                    <Select
                                                                        value={this.state.selectedYear}
                                                                        onChange={(selected) => {
                                                                            this.handleOnChangeYear(selected)
                                                                        }}
                                                                        options={this.state.optionYear}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    {
                                                        this.state.loadingCurrent ?
                                                            <><Shimmer><div className="shimmer" style={{ width: 160, height: 36 }}></div></Shimmer>{" "}</>
                                                            :
                                                            <>
                                                                <div style={{ width: 160 }}>
                                                                    <Select
                                                                        value={this.state.selectedMonth}
                                                                        onChange={(selected) => {
                                                                            this.handleOnChangeMonth(selected)
                                                                        }}
                                                                        options={this.state.optionMonth}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    {
                                                        this.state.loadingCurrent ?
                                                            <><Shimmer><div className="shimmer" style={{ width: 160, height: 36 }}></div></Shimmer>{" "}</>
                                                            :
                                                            <>
                                                                <div style={{ width: 160 }}>
                                                                    <Select
                                                                        value={this.state.selectedStatus}
                                                                        onChange={(selected) => {
                                                                            this.handleOnChangeStatus(selected)
                                                                        }}
                                                                        options={this.state.optionStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={12}>
                                            {
                                                this.state.loadingCurrent ?
                                                    <>
                                                        <Shimmer><div className="shimmer" style={{ width: '10%', height: 36 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '35%', height: 36 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '20%', height: 36 }}></div></Shimmer>

                                                        <Shimmer><div className="shimmer" style={{ width: '10%', height: 22 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '35%', height: 22 }}></div></Shimmer>
                                                        <Shimmer><div className="shimmer" style={{ width: '20%', height: 22 }}></div></Shimmer>
                                                    </>
                                                    :
                                                    <>
                                                        <EmployeeIndexListComponent year={this.state.selectedYear.value} month={this.state.selectedMonth.value} status={this.state.selectedStatus.value} />
                                                    </>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView