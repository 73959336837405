import { accessRole } from "../../../../../helpers/access_role"
import classnames from "classnames"
import React, { Fragment, Component } from "react"
import { Link } from "react-router-dom"
import Select from "react-select"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Label,
    Modal,
} from "reactstrap"

import Shimmer from "react-shimmer-effect";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import apiResponse from '../../../../../services/apiResponse'
import IndexViewBloc from './Blocs/IndexViewBloc'
import DownloadBloc from './Blocs/DownloadBloc'

import MonitoringDetailComponent from './Components/MonitoringDetailComponent'

class IndexView extends Component {

    indexViewBloc = new IndexViewBloc();
    downloadBloc = new DownloadBloc();

    firstYear = 2021
    thisDate = new Date()
    totalYear = (this.thisDate.getFullYear() - this.firstYear) + 1


    monthName = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ]

    constructor(props) {
        super(props)
        this.state = {
            loadingData: true,
            loadingEmployee: true,
            loadingUnit: true,
            days: Array.from({ length: 31 }, () => ({})),
            listData: [],
            selectedEmployee: { value: '', label: 'Semua Pegawai' },
            selectedUnit: { value: '', label: 'Semua Unit Kerja' },
            optionEmployee: [],
            optionUnit: [],
            selectedYear: { value: this.thisDate.getFullYear(), label: this.thisDate.getFullYear() },
            optionYear: Array.from({ length: this.totalYear }, (y, i) => ({ value: (this.firstYear + i), label: (this.firstYear + i) })).reverse(),
            selectedMonth: { value: this.thisDate.getMonth(), label: this.monthName[this.thisDate.getMonth() - 1] },
            optionMonth: [
                { value: 1, label: this.monthName[0] },
                { value: 2, label: this.monthName[1] },
                { value: 3, label: this.monthName[2] },
                { value: 4, label: this.monthName[3] },
                { value: 5, label: this.monthName[4] },
                { value: 6, label: this.monthName[5] },
                { value: 7, label: this.monthName[6] },
                { value: 8, label: this.monthName[7] },
                { value: 9, label: this.monthName[8] },
                { value: 10, label: this.monthName[9] },
                { value: 11, label: this.monthName[10] },
                { value: 12, label: this.monthName[11] },
            ],//.reverse(),
            showDetail: false,
            paramDetail: { uuid: '', date: '' },
        }
    }

    handleOnChangeYear = (selectedYear) => {
        this.setState({
            selectedYear: selectedYear,
            //loadingCurrent: true
        }, () => {
            /* this.setState({
                loadingCurrent: false
            }) */
        });
    }

    handleOnChangeMonth = (selectedMonth) => {
        this.setState({
            selectedMonth: selectedMonth,
            //loadingCurrent: true
        }, () => {
            /* this.setState({
                loadingCurrent: false
            }) */
        });
    }

    handleOnChangeEmployee = (selectedEmployee) => {
        this.setState({
            selectedEmployee: selectedEmployee,
            //loadingCurrent: true
        }, () => {
            /* this.setState({
                loadingCurrent: false
            }) */
        });
    }

    handleOnChangeUnit = (selectedUnit) => {
        this.setState({
            selectedUnit: selectedUnit,
            selectedEmployee: { value: '', label: 'Semua Pegawai' }
        }, () => {
            this.indexViewBloc.fetchEmployeeList({ unit_code: this.state.selectedUnit.value })
            //this.fetchData()
        });
    }

    fetchData = () => {
        this.indexViewBloc.fetchMonitoringList({ year: this.state.selectedYear.value, month: this.state.selectedMonth.value, employee: this.state.selectedEmployee.value, unit: this.state.selectedUnit.value })
    }

    componentDidMount() {
        this.downloadBloc.monitoringChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    var url = process.env.REACT_APP_SERVICE_OUTPUT_URL + link
                    window.open(url, "_blank")
                    this.setState({
                        loading: false,
                        success: true
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000);
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })
        this.indexViewBloc.listChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingData: true
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingData: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        days: Array.from({ length: response.days }, () => ({})),
                        listData: response.employees,
                        loadingData: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.indexViewBloc.employeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingEmployee: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionEmployee(response)
                    this.setState({
                        loadingEmployee: false,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
        })
        this.indexViewBloc.unitChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingUnit: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setOptionUnit(response)
                    this.setState({
                        loadingUnit: false,
                    })
                    this.indexViewBloc.fetchEmployeeList({ unit_code: '' })
                    break
                case apiResponse.ERROR:
                    this.setState({

                    });
                    break
                default:
                    break
            }
        })
        this.indexViewBloc.fetchUnitList()
        this.fetchData()
    }

    setOptionEmployee = (list) => {
        const itemEmployee = []
        itemEmployee.push({ value: '', label: 'Semua Pegawai' })
        list.map((employee) => {
            itemEmployee.push(employee)
            return null
        })
        this.setState({
            optionEmployee: itemEmployee
        }, () => {

        });
    }

    setOptionUnit = (list) => {
        const itemUnit = []
        itemUnit.push({ value: '', label: 'Semua Unit Kerja' })
        list.map((unit) => {
            itemUnit.push(unit)
            return null
        })
        this.setState({
            optionUnit: itemUnit
        }, () => {

        });
    }

    componentWillUnmount() {
        this.indexViewBloc.employeeChannel.unsubscribe()
        this.indexViewBloc.unitChannel.unsubscribe()
        this.indexViewBloc.listChannel.unsubscribe()
        this.downloadBloc.monitoringChannel.unsubscribe()
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Monitoring Perjalanan Dinas</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Form monitoring perjalanan dinas
                                    </CardSubtitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <Label
                                            htmlFor="fieldNama"
                                            className="col-md-2 col-form-label"
                                        >
                                            Tahun
                                        </Label>
                                        <Col md="6">
                                            <div className="bd-highlight">
                                                <div style={{ width: 160 }}>
                                                    <Select
                                                        value={this.state.selectedYear}
                                                        onChange={(selected) => {
                                                            this.handleOnChangeYear(selected)
                                                        }}
                                                        options={this.state.optionYear}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label
                                            htmlFor="fieldNama"
                                            className="col-md-2 col-form-label"
                                        >
                                            Bulan
                                        </Label>
                                        <Col md="6">
                                            <div className="bd-highlight">
                                                <div style={{ width: 160 }}>
                                                    <Select
                                                        value={this.state.selectedMonth}
                                                        onChange={(selected) => {
                                                            this.handleOnChangeMonth(selected)
                                                        }}
                                                        options={this.state.optionMonth}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label
                                            htmlFor="fieldNama"
                                            className="col-md-2 col-form-label"
                                        >
                                            Unit Kerja
                                        </Label>
                                        <Col md="6">
                                            <div className="bd-highlight">
                                                {
                                                    this.state.loadingUnit ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 35 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <>
                                                            <div style={{ width: '100%' }}>
                                                                <Select
                                                                    isDisabled={this.state.loadingEmployee}
                                                                    value={this.state.selectedUnit}
                                                                    onChange={(selected) => {
                                                                        this.handleOnChangeUnit(selected)
                                                                    }}
                                                                    options={this.state.optionUnit}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </>
                                                }

                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Label
                                            htmlFor="fieldNama"
                                            className="col-md-2 col-form-label"
                                        >
                                            Pegawai
                                        </Label>
                                        <Col md="6">
                                            <div className="bd-highlight">
                                                {
                                                    this.state.loadingEmployee ?
                                                        <><Shimmer><div className="shimmer" style={{ width: '100%', height: 35 }}></div></Shimmer>{" "}</>
                                                        :
                                                        <>
                                                            <div style={{ width: '100%' }}>
                                                                <Select
                                                                    value={this.state.selectedEmployee}
                                                                    onChange={(selected) => {
                                                                        this.handleOnChangeEmployee(selected)
                                                                    }}
                                                                    options={this.state.optionEmployee}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </>
                                                }

                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md="2"></Col>
                                        <Col md="10">
                                            <Button
                                                color="success"
                                                type="submit"
                                                disabled={this.state.loadingEmployee}
                                                onClick={() => {
                                                    this.fetchData()
                                                }}
                                            >
                                                <i className="uil-refresh"></i> Lihat Data
                                            </Button>
                                            {" "}
                                            <div className="bd-highlight float-end">
                                                <Button
                                                    color="success"
                                                    type="submit"
                                                    disabled={this.state.loadingEmployee}
                                                    onClick={() => {
                                                        this.downloadBloc.fetchMonitoring({ year: this.state.selectedYear.value, month: this.state.selectedMonth.value, employee: this.state.selectedEmployee.value, unit: this.state.selectedUnit.value })
                                                    }}
                                                >
                                                    <i className="fas fa-file-excel"></i>&nbsp; Export
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <div style={{ 'overflow-x': 'auto' }}>
                                                <Table className="table table-striped table-bordered table-hover" style={{ minWidth: 1600 }}>
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="200px" data-priority="1">Nama Pegawai</Th>
                                                            {
                                                                Object.entries(this.state.days).map((item, key) => {
                                                                    return <Th width="26px" data-priority="1" className="text-center">{key + 1}</Th>
                                                                })
                                                            }

                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            this.state.loadingData ?
                                                                <>
                                                                    <tr>
                                                                        <td><Shimmer className="shimmer"><div style={{ width: '100%', height: 16 }}></div></Shimmer></td>
                                                                        {
                                                                            Object.entries(this.state.days).map((item, key) => {
                                                                                return <td><Shimmer className="shimmer"><div style={{ width: '100%', height: 16 }}></div></Shimmer></td>
                                                                            })
                                                                        }

                                                                    </tr>
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        this.state.listData.length === 0 ?
                                                                            <>
                                                                                <tr key={0}>
                                                                                    <td className="text-center" colSpan={this.state.days.length + 1}>Belum ada data</td>
                                                                                </tr>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    Object.entries(this.state.listData).map((item, key) => {
                                                                                        return <tr key={key}>
                                                                                            <td>{item[1].name}</td>
                                                                                            {
                                                                                                Object.entries(this.state.days).map((d, i) => {
                                                                                                    var isAssigned = item[1].days[i] > 0
                                                                                                    var isMultiple = item[1].days[i] > 1
                                                                                                    return <td style={{
                                                                                                        textAlign: 'center',
                                                                                                        verticalAlign: 'middle',
                                                                                                        fontSize: isMultiple ? 16 : 14,
                                                                                                        fontWeight: isMultiple ? 'bold' : 'normal',
                                                                                                        color: isMultiple ? '#3300DC' : '#495057'
                                                                                                    }}>
                                                                                                        {isAssigned ?
                                                                                                            <span
                                                                                                                style={{ cursor: 'pointer' }}
                                                                                                                onClick={() => {
                                                                                                                    this.setState({
                                                                                                                        showDetail: true,
                                                                                                                        paramDetail: {
                                                                                                                            uuid: item[1].uuid,
                                                                                                                            date: this.state.selectedYear.value.toString() + '-' + this.state.selectedMonth.value.toString().padStart(2, '0') + '-' + (i + 1).toString().padStart(2, '0')
                                                                                                                        }
                                                                                                                    })
                                                                                                                }}
                                                                                                            >
                                                                                                                x
                                                                                                            </span>
                                                                                                            : '-'}
                                                                                                    </td>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </>

                                                                    }
                                                                </>
                                                        }
                                                    </Tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Detail Surat Tugas
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <MonitoringDetailComponent paramDetail={this.state.paramDetail} />
                    </div>
                </Modal>
                <ConfirmAlert

                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"

                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"

                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
            </Fragment >
        )
    }
}

export default IndexView