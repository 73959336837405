import {
    apiRequest,
    masterTunjanganKhususRegulationList,
    masterTunjanganKhususRegulationDetail
} from "../../../../../services/adapters/master"

class RegulationRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", masterTunjanganKhususRegulationList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    
    fetchDetail = async (query) => {
        return await apiRequest("get", masterTunjanganKhususRegulationDetail, {
            params: query
        }, true)
    }
}

export default RegulationRepository