import {
    apiRequest,
    tunsusRegulerDetail,
    tunsusRegulerSsp,
    tunsusRegulerBank,
    tunsusRegulerNrs,
    tunsusRegulerRecap,
} from "../../../../../../services/adapters/output"

class DownloadRepository {
    fetchDownloadDetail = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadSsp = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerSsp,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadBank = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerBank,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadNrs = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerNrs,
            {
                params: query,
            },
            true
        )
    }

    fetchDownloadRecap = async (query) => {
        return await apiRequest(
            "get",
            tunsusRegulerRecap,
            {
                params: query,
            },
            true
        )
    }
}

export default DownloadRepository
