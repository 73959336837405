import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment, useState, useRef } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"

import TableActiveComponent from './Components/TableActiveComponent'


const IndexView = (props) => {


    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                            <div className="float-end">
                                {
                                    (accessRole('create')) ?
                                        <Link to="/master/spd-province.html?create">
                                            <Button color="primary"><i className="uil-folder-medical"></i> Tambah Data Baru</Button>
                                        </Link>
                                    : null
                                }
                            </div>
                                <CardTitle>Referensi Perjalanan Dinas Provinsi</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Informasi data referensi Perjalanan dinas provinsi berasal dari aplikasi kepegawaian.
                                </CardSubtitle>
                                <hr />
                                <TableActiveComponent />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView