import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component, Fragment } from "react"
import Shimmer from "react-shimmer-effect"
import { Redirect } from "react-router-dom"
import Select from "react-select/creatable"

import MinioClient from "../../../../../../services/minioClient"

import { ConfirmAlert } from "../../../../../../helpers/ui/alert"

import { Tooltip, Modal, Row, Col, Label } from "reactstrap"
import MKIDatatable from "../../../../../../modules/mki-datatable/lib"

import { cancelToken } from "../../../../../../services/adapters/base"

import CreateBloc from "../Blocs/CreateBloc"
import apiResponse from "../../../../../../services/apiResponse"

import DetailEmployeeComponent from "./DetailEmployeeComponent"

import moment from "moment"

class EmployeeListComponent extends Component {
    createBloc = new CreateBloc()
    minioClient = new MinioClient()

    source
    historyTable = "historyPayrollTplnRegularEmployeeList"
    defaultOrder = "tunkin_employee_name"
    defaultSize = 10
    defaultSort = "asc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.historyTable = this.historyTable + this.props.uuid
        this.state = {
            showUpdate: false,
            updateUuid: null,
            updateFamilyDocument: null,
            updateFamilyDocumentMinio: null,
            updateWife: 0,
            updateChild: 0,
            showDetail: false,
            tooltip: false,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case "cancel":
                forState = {
                    confirm: false,
                }
                break
            case "confirm":
                forState = {
                    loading: true,
                    showUpdate: false,
                    confirm: false,
                }
                this.createBloc.fetchUpdate({ uuid: this.state.updateUuid, wife: this.state.updateWife, child: this.state.updateChild })
                break
            case "success":
                forState = {
                    success: false,
                    redirect: true,
                }
                break
            case "failed":
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            uuid: this.props.uuid,
        }
        await this.createBloc.fetchEmployeeList(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.createBloc.employeeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })

        this.createBloc.fetchUpdateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        updateUuid: null,
                        updateWife: 0,
                        updateChild: 0,
                    })
                    this.setTokenAPI()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                        updateUuid: null,
                        updateWife: 0,
                        updateChild: 0,
                    })
                    break
                default:
                    break
            }
        })
    }

    convertPath = async (path) => {
        this.setState({
            updateFamilyDocumentMinio: null,
        })
        const pathMinio = await this.minioClient.get(path)
        this.setState({
            updateFamilyDocumentMinio: pathMinio,
        })
    }

    componentWillUnmount() {
        this.createBloc.employeeChannel.unsubscribe()
    }

    columns = [
        {
            key: "tunkin_employee_region_name",
            text: "Negara",
            className: "",
            sortable: true,
            width: "180",
        },
        {
            key: "tunkin_employee_nip",
            text: "NIP",
            className: "",
            align: "center",
            sortable: true,
            width: "100",
        },
        {
            key: "tunkin_employee_name",
            text: "Nama Pegawai",
            className: "",
            sortable: true,
        },
        {
            key: "tunkin_employee_rank_name",
            text: "Golongan",
            className: "",
            sortable: true,
            width: "160",
        },
        {
            key: "tunkin_employee_family_wife_simpeg",
            text: "Kawin",
            className: "",
            sortable: false,
            align: "center",
            width: "80",
            cell: (record) => {
                var text = (record.tunkin_employee_family_wife_simpeg === 1 ? "K" : "TK") + record.tunkin_employee_family_child_simpeg
                return (
                    <div className="text-center">
                        <div
                            class="link-primary"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                this.setState({
                                    showUpdate: true,
                                    updateUuid: record.tunkin_employee_uuid,
                                    updateFamilyDocument: record.tunkin_employee_employee_family_document,
                                    updateFamilyDocumentMinio: null,
                                    updateWife: record.tunkin_employee_family_wife_simpeg,
                                    updateChild: record.tunkin_employee_family_child_simpeg,
                                })
                                if (record.tunkin_employee_employee_family_document !== null) {
                                    this.convertPath(record.tunkin_employee_employee_family_document)
                                }
                            }}
                        >
                            <div className="text-center">{text}</div>
                        </div>
                    </div>
                )
            },
        },
        {
            key: "tunkin_employee_last",
            text: "Status",
            className: "",
            align: "center",
            sortable: true,
            width: "120",
            cell: (record) => {
                var className = (record.tunkin_employee_last === 1 ? "bg-soft-danger" : record.tunkin_employee_last === 2 ? "bg-soft-warning" : "bg-success") + " badge rounded-pill font-size-12"
                var labelName = record.tunkin_employee_last === 1 ? "Baru" : record.tunkin_employee_last === 2 ? "Perubahan" : ""
                return (
                    <>
                        {record.tunkin_employee_family_child_simpeg !== 2 ? <div className={className} style={{ height: 10, width: 10 }}></div> : null}
                        <label className={className}>{labelName}</label>{" "}
                    </>
                )
            },
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 70,
            sortable: false,
            align: "center",
            cell: (record) => {
                const thisId = record.tunkin_employee_uuid
                return (
                    <Fragment>
                        <span>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                id={"detail-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        showDetail: true,
                                        detailUuid: record.tunkin_employee_uuid,
                                    })
                                }}
                                style={{
                                    marginRight: "2px",
                                    padding: "2px 5px",
                                }}
                            >
                                <i className="uil-file-search-alt"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2,
                                }}
                                placement="top"
                                isOpen={this.state["detail" + thisId]}
                                target={"detail-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["detail" + thisId]: !this.state["detail" + thisId],
                                    })
                                }}
                            >
                                Lihat Detail
                            </Tooltip>
                        </span>
                    </Fragment>
                )
            },
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={900}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="lg"
                    isOpen={this.state.showDetail}
                    toggle={() => {
                        this.setState({
                            showDetail: !this.state.showDetail,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Detail Pegawai
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showDetail: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <DetailEmployeeComponent uuid={this.state.detailUuid} />
                    </div>
                </Modal>

                <Modal
                    size="sm"
                    isOpen={this.state.showUpdate}
                    toggle={() => {
                        this.setState({
                            showUpdate: !this.state.showUpdate,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Ganti Status Kawin
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showUpdate: false,
                                })
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.updateFamilyDocument !== null && (
                            <>
                                {this.state.updateFamilyDocumentMinio !== null ? (
                                    <>
                                        <a href={this.state.updateFamilyDocumentMinio} rel="noreferrer" target="_blank">
                                            Download Dokumen Keluarga
                                        </a>
                                        <hr />
                                    </>
                                ) : (
                                    <Shimmer>
                                        <div className="shimmer" style={{ width: "100%", height: 15 }}></div>
                                    </Shimmer>
                                )}
                            </>
                        )}
                        <Row className="mb-3">
                            <Label className="col-md-5 mt-2 col-form-label">Status Kawin</Label>
                            <Label className="col-md-7 col-form-label">
                                <Select
                                    options={[
                                        { value: 0, label: "Tidak Kawin" },
                                        { value: 1, label: "Kawin" },
                                    ]}
                                    value={this.state.updateWife === 0 ? { value: 0, label: "Tidak Kawin" } : { value: 1, label: "Kawin" }}
                                    onChange={(value) => {
                                        this.setState({
                                            updateWife: value.value,
                                        })
                                    }}
                                />
                            </Label>
                        </Row>
                        <Row>
                            <Label className="col-md-5 mt-2 col-form-label">Jumlah Anak</Label>
                            <Label className="col-md-4 col-form-label">
                                <Select
                                    options={[
                                        { value: 0, label: "0" },
                                        { value: 1, label: "1" },
                                        { value: 2, label: "2" },
                                    ]}
                                    value={{ value: this.state.updateChild, label: this.state.updateChild.toString() }}
                                    onChange={(value) => {
                                        this.setState({
                                            updateChild: value.value,
                                        })
                                    }}
                                />
                            </Label>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showUpdate: false,
                                })
                            }}
                            className="btn btn-danger waves-effect"
                            data-dismiss="modal"
                        >
                            Batal
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    confirm: true,
                                })
                            }}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            Simpan
                        </button>
                    </div>
                </Modal>

                <ConfirmAlert confirmTitle="Konfirmasi!" confirmInfo="Apakah anda yakin akan menyimpan data ini?" loadingTitle="Mengirim data..." loadingInfo="Tunggu beberapa saat" successTitle="Berhasil!" successInfo="Data berhasil disimpan" failedTitle="Gagal!" failedInfo="Data gagal disimpan" showConfirm={this.state.confirm} showLoading={this.state.loading} showSuccess={this.state.success} showFailed={this.state.failed} response={this.confirmResponse} />
            </>
        )
    }
}

export default EmployeeListComponent
