import {
    apiRequest,
    masterMappingNrsDetail,
    masterMappingNrsEdit,
    masterMappingNrsListNrs
} from "../../../../../../services/adapters/master"

class UpdateRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", masterMappingNrsDetail, {
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", masterMappingNrsEdit, {
            body: query
        }, true)
    }

    fetchNrs = async (query, cancelToken) => {
        return await apiRequest("get", masterMappingNrsListNrs, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default UpdateRepository