import { accessRole } from "../../../../../helpers/access_role"
import React, { Fragment } from "react"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button
} from "reactstrap"

import TableListComponent from './Components/TableListComponent'


const IndexView = (props) => {

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                    {
                                        (accessRole('create')) ?
                                            <Link to="/master/referensi-kurs.html?create">
                                                <Button color="primary"><i className="uil-folder-medical"></i> Tambah Data Baru</Button>
                                            </Link>
                                        : null
                                    }
                                </div>
                                <CardTitle>Referensi Kurs</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Informasi data tukar rupiah dengan mata uang asing, untuk menentukan besaran nilai tpada periode tertentu.
                                </CardSubtitle>
                                <hr />
                                <TableListComponent />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView