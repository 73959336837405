import { apiWrapper, apiCancel } from '../wrapper'

export const initial = 'simpeg'
const baseUrl = process.env.REACT_APP_SERVICE_SIMKEU_SIMPEG_URL
const appCode = process.env.REACT_APP_SERVICE_SIMKEU_SIMPEG_APPCODE
const outputType = 'json'

export const authConnect = 'auth/connect/'
// IIPC
export const listIIPC = 'iipc/list/'
export const detailIIPC = 'iipc/detail/'
export const changeIIPC = 'iipc/change/'
export const historyIIPC = 'iipc/history/'
// Cuti Besar
export const listCutiBesar = 'cutibesar/list/'
export const detailCutiBesar = 'cutibesar/detail/'
export const changeCutiBesar = 'cutibesar/change/'
// Tugas Belajar
export const listTugasBelajar = 'tugasbelajar/list/'
export const detailTugasBelajar = 'tugasbelajar/detail/'
export const changeTugasBelajar = 'tugasbelajar/change/'
// CLTN
export const listCLTN = 'cltn/list/'
export const detailCLTN = 'cltn/detail/'
export const changeCLTN = 'cltn/change/'
// Dokumen
export const listDokumen = 'dokumen/list/'
export const detailDokumen = 'dokumen/detail/'
export const checklistDokumen = 'dokumen/checklist/'

// txt
export const txtUangmakan = 'output/txt/uangmakan/'

//export const cancelToken = () => apiCancel
export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (method, route, data = { params: null, body: null, cancelToken: null }, needToken=true) => {    
    if(needToken) {
        const thisTime = (Math.floor(Date.now()/1000))
        const expire = localStorage.getItem(initial + 'accessExpired')
        if(expire < thisTime) {
           await apiWrapper(baseUrl, initial, appCode, outputType, 'post', 'auth/getAccessToken/', data)
           .then(result => {
                localStorage.setItem(initial + 'accessToken', result.response.access_token)
                return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
            })
            .catch(result => {
                return Promise.reject('Tidak dapat mengambil accessToken');
            })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)        
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}