import { apiWrapper, apiCancel } from '../wrapper'

export const initial = 'base'
const baseUrl = process.env.REACT_APP_SERVICE_BASE_URL
const appCode = process.env.REACT_APP_SERVICE_BASE_APPCODE
const outputType = 'json'

export const authConnect = 'auth/connect/'
export const notificationCheck = 'notification/check/'
export const notificationRead = 'notification/markAsRead/'
export const notificationReadDokumen = 'notification/markAsReadDokumen/'
export const processDokumen = 'notification/processDokumen/'
export const authGetAccessRole = 'auth/getAccessRole/'
export const authGetAccessMenus = 'auth/getAccessMenus/'

export const accessCreate = 'access/create/'
export const menuList = 'menu/list/'

//dashboard->notification
export const notificationList = 'pages/notification/list/'
export const notificationFlagRead = 'pages/notification/flag/'
export const notificationListIIPC = 'pages/notification/iipc/'
export const notificationListCutiBesar = 'pages/notification/cutibesar/'
export const notificationListTugasBelajar = 'pages/notification/tugasbelajar/'
export const notificationListCTN = 'pages/notification/tanggungannegara/'
export const notificationCheckList = 'pages/notification/checklist/'

//dashboard->activity
export const activityList = 'pages/activity/list/'

export const userGetData = 'user/getData/'

export const dinasGetList = ''

//config
export const settingRoleList = 'setting/role/lists/'
export const settingRoleDetail = 'setting/role/detail/'
export const settingRoleInactive = 'setting/role/doinactive/'
export const settingRoleActive = 'setting/role/doactive/'

//export const cancelToken = () => apiCancel
export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (method, route, data = { params: null, body: null, cancelToken: null }, needToken=true) => {
    if(needToken) {
        const thisTime = (Math.floor(Date.now()/1000))
        const expire = localStorage.getItem(initial + 'accessExpired')
        if(expire < thisTime) {
           await apiWrapper(baseUrl, initial, appCode, outputType, 'post', 'auth/getAccessToken/', data)
           .then(result => {
                localStorage.setItem(initial + 'accessToken', result.response.access_token)
                return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
            })
            .catch(result => {
                return Promise.reject('Tidak dapat mengambil accessToken');
            })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)        
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}