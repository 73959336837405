import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import {
    Tooltip,
    Modal
} from "reactstrap"

import MKIDatatable from '../../../../../../modules/mki-datatable/lib'

import { cancelToken } from "../../../../../../services/adapters/base"

import EmployeeViewBloc from '../Blocs/EmployeeViewBloc'
import apiResponse from '../../../../../../services/apiResponse'

class TunkinEmployeeListComponent extends Component {
    indexViewBloc = new EmployeeViewBloc();

    source
    historyTable = 'historyPayrollTunkinRegularEmployeeListComponent'
    defaultOrder = 'tunkin_month'
    defaultSize = 20
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            showTunkinData: false,
            modalrecord: null,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            year: this.props.year
        }
        await this.indexViewBloc.fetchMonths(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {}

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.monthsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.monthsChannel.unsubscribe()
    }

    columns = [
        {
            key: "tunkin_spm_status",
            text: "Proses",
            className: "",
            sortable: true,
            width: '100',
            align: 'center',
            cell: record => {
                return (
                    <>
                        { record.tunkin_spm_status === 2 ?
                        <div className="bg-success rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>
                        :
                        <div className="bg-secondary rounded-circle d-inline-block" style={{height: 10, width: 10}}></div>
                        }
                    </>
                )
            }
        },
        {
            key: "tunkin_month",
            text: "Bulan",
            className: "",
            sortable: true,
            cell: record => {
                var done = record.tunkin_spm_status === 2 ? true : false;
                return (
                    <>
                        { done ? <b> { record.tunkin_month_name } </b> : record.tunkin_month_name }
                    </>
                )
            }
        },
        {
            key: "tunkin_result_value_total_usd",
            text: "Tunjangan ($)",
            className: "",
            align: 'center',
            width: '140',
            sortable: true,
            cell: record => {
                var done = record.tunkin_spm_status === 2 ? true : false;
                return (
                    <div className="text-end">{ done ? (record.tunkin_result_value_total_usd*1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</div>
                )
            }
        },
        {
            key: "kurs_currency_value",
            text: "Kurs",
            className: "",
            sortable: true,
            width: '140',
            align: 'center',
            cell: record => {
                var done = record.tunkin_spm_status === 2 ? true : false;
                return (
                    <div className="text-end">{ done ? (record.kurs_currency_value*1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</div>
                )
            }
        },
        {
            key: "tunkin_result_value_total",
            text: "Tunjangan (Rp)",
            className: "",
            sortable: true,
            width: '140',
            align: 'center',
            cell: record => {
                var done = record.tunkin_spm_status === 2 ? true : false;
                return (
                    <div className="text-end">{ done ? (record.tunkin_result_value_total*1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</div>
                )
            }
        },
        {
            key: "tunkin_result_value_tax",
            text: "Pajak",
            className: "",
            sortable: true,
            width: '140',
            align: 'center',
            cell: record => {
                var done = record.tunkin_spm_status === 2 ? true : false;
                return (
                    <div className="text-end">{ done ? (record.tunkin_result_value_tax*1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</div>
                )
            }
        },
        {
            key: "tunkin_uuid",
            text: "Aksi",
            className: "",
            width: '70',
            align: 'center',
            cell: record => {
                var done = record.tunkin_spm_status === 2 ? true : false;
                const thisId = record.tunkin_result_id
                return (
                    <>
                        { done ?
                            <>
                                <button
                                    className="btn btn-success btn-sm"
                                    id={"detail-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            showTunkinData: true,
                                            modalrecord: record,
                                        });
                                    }}
                                    style={{
                                        marginRight: '2px',
                                        padding: '2px 5px'
                                    }}>
                                        <i className="uil-align-left"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["detail" + thisId] : !this.state["detail" + thisId]
                                        })
                                    }}
                                >Lihat Tunjangan</Tooltip>
                            </>
                            :
                            <button
                                className="btn btn-soft-secondary btn-sm" disabled={true}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-align-left"></i>
                            </button>
                        }
                    </>
                )
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1000}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="md"
                    isOpen={this.state.showTunkinData}
                    toggle={() => {
                        this.setState({
                            showTunkinData: !this.state.showTunkinData
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Rincian Tunjangan TPLN Reguler
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showTunkinData: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        { this.state.modalrecord !== null ?
                        <Table
                            className="table table-striped table-bordered table-hover"
                        >
                            <Thead>
                                <Tr>
                                    <Th width="*" data-priority="1">Nama</Th>
                                    <Th width="160px" data-priority="3" className="text-center">Jumlah</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <tr>
                                    <td className="ps-4">Tunjangan LN ($)</td>
                                    <td className="text-end">{ this.state.modalrecord.tunkin_result_value_primary !== null ? this.state.modalrecord.tunkin_result_value_primary.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</td>
                                </tr>
                                <tr>
                                    <td className="ps-4">Tunjangan Istri ($)</td>
                                    <td className="text-end">{ this.state.modalrecord.tunkin_result_value_wife !== null ? this.state.modalrecord.tunkin_result_value_wife.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</td>
                                </tr>
                                <tr>
                                    <td className="ps-4">Tunjangan Anak ($)</td>
                                    <td className="text-end">{ this.state.modalrecord.tunkin_result_value_child !== null ? this.state.modalrecord.tunkin_result_value_child.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Total Tunjangan ($)</td>
                                    <td className="fw-bold text-end">{ this.state.modalrecord.tunkin_result_value_total_usd !== null ? this.state.modalrecord.tunkin_result_value_total_usd.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</td>
                                </tr>
                                <tr>
                                    <td>Kurs ($1 = Rp)</td>
                                    <td className="text-end">{ this.state.modalrecord.kurs_currency_value !== null ? this.state.modalrecord.kurs_currency_value.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Total Tunjangan (Rp)</td>
                                    <td className="fw-bold text-end">{ this.state.modalrecord.tunkin_result_value_total !== null ? this.state.modalrecord.tunkin_result_value_total.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</td>
                                </tr>
                                <tr>
                                    <td className="ps-4">Tunjangan Khusus Pajak (Rp)</td>
                                    <td className="text-end">{ this.state.modalrecord.tunkin_result_value_tax !== null ? this.state.modalrecord.tunkin_result_value_tax.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Penghasilan Kotor (Rp)</td>
                                    <td className="fw-bold text-end">{ this.state.modalrecord.tunkin_result_value_total !== null ? ((this.state.modalrecord.tunkin_result_value_total*1)+(this.state.modalrecord.tunkin_result_value_tax*1)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</td>
                                </tr>
                                <tr>
                                    <td className="ps-4">Pajak Penghasilan (Rp)</td>
                                    <td className="text-end">{ this.state.modalrecord.tunkin_result_value_tax !== null ? this.state.modalrecord.tunkin_result_value_tax.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</td>
                                </tr>
                                <tr>
                                    <td className="h5 fw-bold">Jumlah Bersih (Rp)</td>
                                    <td className="h5 fw-bold text-end">{ this.state.modalrecord.tunkin_result_value_total !== null ? this.state.modalrecord.tunkin_result_value_total.replace('.00', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-' }</td>
                                </tr>
                            </Tbody>
                        </Table> : <></>
                        }
                    </div>
                </Modal>
            </>
        )
    }

}

export default TunkinEmployeeListComponent