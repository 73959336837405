import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'


class CreateBloc {

    checkTunkinChannel = new Subject();
    readyYearChannel = new Subject();
    readyMonthChannel = new Subject();
    createChannel = new Subject();
    employeeChannel = new Subject();
    repository = new CreateRepository()

    constructor() {
        this.checkTunkinChannel.next({status: apiResponse.INITIAL})
        this.readyYearChannel.next({status: apiResponse.INITIAL})
        this.readyMonthChannel.next({status: apiResponse.INITIAL})
        this.createChannel.next({status: apiResponse.INITIAL})
        this.employeeChannel.next({status: apiResponse.INITIAL})
    }

    
    fetchCheckTunkin = async (query) => {
        this.checkTunkinChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCheckTunkin(query).then((result) => {
                this.checkTunkinChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.checkTunkinChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchReadyYear = async (query) => {
        this.readyYearChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchReady(query).then((result) => {
                this.readyYearChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.readyYearChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchReadyMonth = async (query) => {
        this.readyMonthChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchReady(query).then((result) => {
                this.readyMonthChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.readyMonthChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchCreate = async (query) => {
        this.createChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.createChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchEmployee = async (query) => {
        this.employeeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchEmployee(query).then((result) => {
                this.employeeChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.employeeChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    
}

export default CreateBloc