import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import DataTableRepository from '../Repositories/DataTableRepository'


class DataTableBloc {

    rowtableChannel = new Subject();
    flagReadChannel = new Subject();
    IIPCChannel = new Subject();
    TugasBelajarChannel = new Subject();
    CutiBesarChannel = new Subject();
    CTNChannel = new Subject();
    CheckListChannel = new Subject();
    repository = new DataTableRepository()

    constructor() {
        this.rowtableChannel.next({status: apiResponse.INITIAL})
        this.flagReadChannel.next({status: apiResponse.INITIAL})
        this.IIPCChannel.next({status: apiResponse.INITIAL})
        this.TugasBelajarChannel.next({status: apiResponse.INITIAL})
        this.CTNChannel.next({status: apiResponse.INITIAL})
        this.CutiBesarChannel.next({status: apiResponse.INITIAL})
        this.CheckListChannel.next({status: apiResponse.INITIAL})
    }

    fetchChecklist = async (query, cancelToken) => {
        this.CheckListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchChecklist(query, cancelToken).then((result) => {
                this.CheckListChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.CheckListChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchRowTable = async (query, cancelToken) => {
        this.rowtableChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTable(query, cancelToken).then((result) => {
                this.rowtableChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchFlagRead = async (query) => {
        this.flagReadChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchFlagRead(query).then((result) => {
                this.flagReadChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.flagReadChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchRowListIIPC = async (query) => {
        this.IIPCChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowListIIPC(query).then((result) => {
                this.IIPCChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.IIPCChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
        
    }

    fetchRowListCutiBesar = async (query) => {
        this.CutiBesarChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowListCutiBesar(query).then((result) => {
                this.CutiBesarChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.CutiBesarChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
        
    }

    fetchRowListTugasBelajar = async (query) => {
        this.TugasBelajarChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowListTugasBelajar(query).then((result) => {
                this.TugasBelajarChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.TugasBelajarChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
        
    }

    fetchRowListCTN = async (query) => {
        this.CTNChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowListCTN(query).then((result) => {
                this.CTNChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.CTNChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
        
    }
}

export default DataTableBloc