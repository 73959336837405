import React, { useState, Component, Fragment } from "react"
import SimpleBar from "simplebar-react"
import Shimmer from "react-shimmer-effect"
import moment from 'moment'

import IndexViewBloc from '../Blocs/IndexViewBloc'
import apiResponse from '../../../../../../services/apiResponse'

class TplnHistoryComponent extends Component {

    indexViewBloc = new IndexViewBloc();

    constructor(props) {
        super(props)
        this.state = {
            loadingData: true,
            histroyData: []
        }
    }

    componentDidMount() {
        this.indexViewBloc.historyChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingData: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        histroyData: response,
                        loadingData: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.indexViewBloc.fetchHistory({uuid: this.props.uuid})
    }

    componentWillUnmount() {
        this.indexViewBloc.historyChannel.unsubscribe()
    }

    render () {
        return (
            <>
                { this.state.loadingData ?
                    <SimpleBar className="activity-feed mb-0 ps-2" style={{ maxHeight: '500px' }}>
                        <li className="feed-item">
                            <p className="text-muted mb-1 font-size-13">
                                <span className="text-success">
                                    <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>{" "}
                                </span>
                                <Shimmer><div className="shimmer" style={{width: '40%', height: 12}}></div></Shimmer>
                            </p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '90%', height: 14}}></div></Shimmer></p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '40%', height: 14}}></div></Shimmer></p>
                        </li>
                        <li className="feed-item">
                            <p className="text-muted mb-1 font-size-13">
                                <span className="text-success">
                                    <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>{" "}
                                </span>
                                <Shimmer><div className="shimmer" style={{width: '40%', height: 12}}></div></Shimmer>
                            </p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '90%', height: 14}}></div></Shimmer></p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '40%', height: 14}}></div></Shimmer></p>
                        </li>
                        <li className="feed-item">
                            <p className="text-muted mb-1 font-size-13">
                                <span className="text-success">
                                    <Shimmer><div className="shimmer" style={{width: '20%', height: 15}}></div></Shimmer>{" "}
                                </span>
                                <Shimmer><div className="shimmer" style={{width: '40%', height: 12}}></div></Shimmer>
                            </p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '90%', height: 14}}></div></Shimmer></p>
                            <p className="mt-0 mb-0"><Shimmer><div className="shimmer" style={{width: '40%', height: 14}}></div></Shimmer></p>
                        </li>
                    </SimpleBar>
                :
                    <>
                    { this.state.histroyData.length > 0 ?
                        <SimpleBar className="activity-feed mb-0 ps-2" style={{ maxHeight: '500px' }}>
                            {
                                this.state.histroyData.map((item, key) => {
                                    return (
                                        <li className="feed-item">
                                            <p className="text-muted mb-1 font-size-13"><span className="text-success"><b>{ item.tunkin_history_title }</b></span> <small>{ moment(item.tunkin_history_create_date).format('LLLL') } WIB</small></p>
                                            <p className="mt-0 mb-0"><span className="fw-bolder"><small>{ item.tunkin_history_create_name }</small></span>: <span dangerouslySetInnerHTML={{__html: item.tunkin_history_description }} /></p>
                                        </li>
                                    )
                                })
                            }
                        </SimpleBar>
                        :
                        <p className="text-muted mb-1 font-size-13">Tidak ada data riwayat</p>
                    }
                    </>
                }
            </>
        )
    }
}

export default TplnHistoryComponent