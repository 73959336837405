import React, { Component } from "react"

import { Row, Col } from "reactstrap"

import { ConfirmAlert } from "../../../../../../helpers/ui/alert"
import apiResponse from "../../../../../../services/apiResponse"
import DownloadBloc from "../Blocs/DownloadBloc"

class TunsusDownloadComponent extends Component {
    downloadBloc = new DownloadBloc()

    constructor(props) {
        super(props)
        this.state = {
            uuid: this.props.uuid,
        }
    }

    componentDidMount() {
        this.downloadBloc.nrsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    //window.location.href = process.env.REACT_APP_SERVICE_OUTPUT_URL + link
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })

        this.downloadBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    //window.location.href = process.env.REACT_APP_SERVICE_OUTPUT_URL + link
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })

        this.downloadBloc.recapChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })

        this.downloadBloc.sspChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    //window.location.href = process.env.REACT_APP_SERVICE_OUTPUT_URL + link
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })

        this.downloadBloc.bankChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    window.open(process.env.REACT_APP_SERVICE_OUTPUT_URL + link, "_blank")
                    this.setState({
                        loading: false,
                        success: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 1000)
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    setTimeout(() => {
                        this.setState({
                            failed: false,
                        })
                    }, 1000)
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.downloadBloc.recapChannel.unsubscribe()
        this.downloadBloc.recapChannel.unsubscribe()
        this.downloadBloc.sspChannel.unsubscribe()
        this.downloadBloc.bankChannel.unsubscribe()
        this.downloadBloc.nrsChannel.unsubscribe()
    }

    render() {
        return (
            <>
                <Row>
                    {/* <Col xs="12" className="text-center">
                        <button
                            disabled={true}
                            className="mb-3 w-100 btn btn-secondary text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadDetail({ uuid: this.state.uuid })
                            }}>
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Detail Halaman Muka
                        </button>
                    </Col>
                    <Col xs="12" className="text-center">
                        <button
                            disabled={true}
                            className="mb-3 w-100 btn btn-secondary text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadSsp({ uuid: this.state.uuid })
                            }}>
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i> Rekapitulasi KPPN
                        </button>
                    </Col>
                    <Col xs="12" className="text-center">
                        <button
                            disabled={true}
                            className="mb-3 w-100 btn btn-secondary text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadDetail({ uuid: this.state.uuid })
                            }}>
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Daftar Nominatif
                        </button>
                    </Col> */}
                    <Col xs="12" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadDetail({ uuid: this.state.uuid })
                            }}
                        >
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Detail Isi
                        </button>
                    </Col>
                    {/* <Col xs="12" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadDetail({ uuid: this.state.uuid, csv: true })
                            }}
                        >
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Detail Isi (CSV)
                        </button>
                    </Col> */}
                    <Col xs="12" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadRecap({ uuid: this.state.uuid })
                            }}
                        >
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Rekapitulasi Kelas Jabatan
                        </button>
                    </Col>
                    <Col xs="12" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadSsp({ uuid: this.state.uuid })
                            }}
                        >
                            <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i>Surat Setoran Pajak
                        </button>
                    </Col>
                    <Col xs="6" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadBank({ uuid: this.state.uuid, code: "03" })
                            }}
                        >
                            <i className="fas fa-file-csv" style={{ paddingRight: 12 }}></i>Bank 03
                        </button>
                    </Col>
                    <Col xs="6" className="text-center">
                        <button
                            className="mb-3 w-100 btn btn-success text-start"
                            style={{ width: 100 }}
                            onClick={() => {
                                this.downloadBloc.fetchDownloadBank({ uuid: this.state.uuid, code: "06" })
                            }}
                        >
                            <i className="fas fa-file-csv" style={{ paddingRight: 12 }}></i>Bank 06
                        </button>
                    </Col>
                    {this.props.nrsList.map((data, index) => {
                        return (
                            <Col xs="12" className="text-center" key={index}>
                                <button
                                    className="mb-3 w-100 btn btn-success text-start"
                                    style={{ width: 100 }}
                                    onClick={() => {
                                        this.downloadBloc.fetchDownloadNrs({
                                            uuid: this.state.uuid,
                                            nrs: data.nrs_code,
                                        })
                                    }}
                                >
                                    <i className="fas fa-file-excel" style={{ paddingRight: 12 }}></i> NRS:{" "}
                                    {data.nrs_code}
                                </button>
                            </Col>
                        )
                    })}
                </Row>

                <ConfirmAlert
                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"
                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"
                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
            </>
        )
    }
}

export default TunsusDownloadComponent
