import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import IndexViewRepository from '../Repositories/IndexViewRepository'


class IndexViewBloc {

    rowRegisterListChannel = new Subject();
    repository = new IndexViewRepository()

    constructor() {
        this.rowRegisterListChannel.next({ status: apiResponse.INITIAL })
    }

    fetchRowPpkListActive = async (query, cancelToken) => {
        this.rowRegisterListChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchRowPpkListActive({ ...query, status: 1 }, cancelToken).then((result) => {
                this.rowRegisterListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.rowRegisterListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) { }
    }
}

export default IndexViewBloc