import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Modal,
    Alert
} from "reactstrap"
import Shimmer from "react-shimmer-effect";
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import { generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, Redirect, withRouter } from "react-router-dom"
import apiResponse from '../../../../services/apiResponse'
import EmployeeListComponent from './Components/EmployeeListComponent'
import CreateBloc from './Blocs/CreateBloc'


class CreateView extends Component {

    createBloc = new CreateBloc()

    source
    constructor(props) {
        super(props)
        this.state = {
            loadedUuid: false,
            uuid: null,
            dataForm: {},
            loadingCurrent: true,
            verify : {
                ptsp_status : 0
            }
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm)
        await this.createBloc.fetchCreate(formData)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
              ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    _suffixTotal = "posisi jabatan"
    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        this.setState({
            uuid: queryParams.get('uuid'),
            loadedUuid: true
        }, () => {
            this.createBloc.fetchVerify({uuid: this.state.uuid});
        });
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.verifyChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    this.setState({
                        loadingCurrent: true
                    });
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        loadingCurrent: false,
                        verify: response
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        
                    });
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.createBloc.createChannel?.unsubscribe()
        this.createBloc.verifyChannel?.unsubscribe()
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="pegawai-rightbar">
                                <CardBody>
                                    <CardTitle>Proses Honor Tetap</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Pengecekan data Honor Tetap perpegawain dan pemrosesan data
                                    </CardSubtitle>
                                    <hr/>
                                    <Row>
                                        <Col md="12">
                                            <div className="p-2 bd-highlight float-start">
                                                <div style={{paddingTop: 8}}>
                                                    Keterangan Absen
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{paddingTop: 8}}>
                                                    <label className="badge rounded-pill bg-soft-warning font-size-12" >n</label> Terlambat
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{paddingTop: 8}}>
                                                    <label className="badge rounded-pill bg-soft-success font-size-12" >n</label> Pulang Lebih Awal
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">    
                                                <div style={{paddingTop: 8}}>
                                                <label className="badge rounded-pill bg-soft-primary font-size-12" >n</label> Absen Hanya 1x
                                                </div>
                                            </div>
                                            <div className="p-2 bd-highlight float-end">
                                                <div style={{paddingTop: 8}}>
                                                    <label className="badge rounded-pill bg-soft-danger font-size-12" >n</label> Tidak Hadir
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <AvField
                                                    name="uuid"
                                                    type="hidden"
                                                    id="uuid"
                                                    value={this.state.uuid}
                                                />
                                        {/* <Row className="mb-3">
                                            <Col md="12">
                                                {
                                                    this.state.loadingCurrent ?
                                                        <Shimmer><div className="shimmer"style={{ verticalAlign: 'middle'}}/></Shimmer>
                                                    :
                                                        <>
                                                            {
                                                                this.state.verify ?
                                                                this.state.verify.map((item, key) => {
                                                                    return (
                                                                        <>
                                                                            <Alert color="danger">
                                                                                { item }
                                                                            </Alert>
                                                                        </>
                                                                    )
                                                                }) : null
                                                            }
                                                        </>
                                                }
                                                
                                            </Col>
                                        </Row> */}
                                        <Row className="mb-3">
                                            <Col md="12">
                                                { this.state.loadedUuid ? <EmployeeListComponent uuid={this.state.uuid} /> : null }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="10">
                                                <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="10">
                                                {
                                                    this.state.verify.ptsp_status != 2 ?
                                                        <Button color="success" type="submit">
                                                            <i className="uil-arrow-up-right"></i> Proses Data PTSP
                                                        </Button>
                                                    : 
                                                        <Button color="secondary" type="button" disabled="true">
                                                            <i className="uil-arrow-up-right"></i> Proses Data PTSP
                                                        </Button>
                                                }

                                                {' '}
                                                <Link to="/payroll/honor.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>

                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/payroll/honor.html'/>
                : null }
            </Fragment>
        )
    }

}

//export default CreateView
export default withRouter(CreateView);