import {
    apiRequest,
    masterOvertimeCurrent
} from "../../../../../services/adapters/master"

class IndexViewRepository {

    fetchCurrent = async () => {
        return await apiRequest("get", masterOvertimeCurrent, {}, true)
    }
}

export default IndexViewRepository