import { accessRole } from "../../../helpers/access_role"
import ErrorAccess from '../../../components/Error/Content/Access'

import IndexView from "./Index/View"
import SkpCreateView from "./Create/SKP/View"
import PayrollCreateView from "./Create/Payroll/View"
import DetailView from "./Detail/View"
import SkpUpdateView from "./Update/SKP/View"
import PayrollUpdateView from "./Update/Payroll/View"

const RouteView = (props) => {

  const queryParams = new URLSearchParams(props.location.search);
  const _skpcreate = queryParams.get('skpcreate')
  const _payrollcreate = queryParams.get('payrollcreate')
  const _detail = queryParams.get('detail')
  const _skpupdate = queryParams.get('skpupdate')
  const _payrollupdate = queryParams.get('payrollupdate')
  const _delete = queryParams.get('detail')
  const _approve = queryParams.get('approve')
  const _print = queryParams.get('print')

  if (_skpcreate != null) {
    return accessRole('create') ? <SkpCreateView /> : <ErrorAccess />
  } else if (_payrollcreate != null) {
    return accessRole('create') ? <PayrollCreateView /> : <ErrorAccess />
  } else if (_detail != null) {
    return accessRole('read') ? <DetailView /> : <ErrorAccess />
  } else if (_skpupdate != null) {
    const _uuid = queryParams.get('uuid')
    return accessRole('update') ? <SkpUpdateView uuid={_uuid} /> : <ErrorAccess />
  } else if (_payrollupdate != null) {
    const _uuid = queryParams.get('uuid')
    return accessRole('update') ? <PayrollUpdateView uuid={_uuid} /> : <ErrorAccess />
  } else if (_delete != null) {
    return null
  } else if (_approve != null) {
    return null
  } else if (_print != null) {
    return null
  }

  return accessRole('read') ? <IndexView /> : <ErrorAccess />
}
export default RouteView