import {
    apiRequest,
    masterMappingNrsCreate,
    masterMappingNrsListNrs,
    masterMappingNrsEmployee
} from "../../../../../../services/adapters/master"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", masterMappingNrsCreate, {
            body: query
        }, true)
    }

    fetchNrs = async (query, cancelToken) => {
        return await apiRequest("get", masterMappingNrsListNrs, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchEmployee = async (query, cancelToken) => {
        return await apiRequest("get", masterMappingNrsEmployee, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
}

export default CreateRepository