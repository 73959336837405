import { apiRequest, officialTravelRapelVerificationProcessDetail, officialTravelRapelVerificationProcessParticipant, officialTravelRapelVerificationProcessVerify, officialTravelRapelVerificationProcessReject, officialTravelRapelVerificationSource } from "../../../../../../services/adapters/process"

class VerifyRepository {
    fetchVerify = async (query) => {
        return await apiRequest(
            "post",
            officialTravelRapelVerificationProcessVerify,
            {
                body: query,
            },
            true
        )
    }

    fetchReject = async (query) => {
        return await apiRequest(
            "post",
            officialTravelRapelVerificationProcessReject,
            {
                body: query,
            },
            true
        )
    }

    fetchDetail = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelVerificationProcessDetail,
            {
                params: query,
            },
            true
        )
    }

    fetchParticipant = async (query, cancelToken) => {
        return await apiRequest(
            "get",
            officialTravelRapelVerificationProcessParticipant,
            {
                cancelToken: cancelToken,
                params: query,
            },
            true
        )
    }

    fetchSource = async (query) => {
        return await apiRequest(
            "get",
            officialTravelRapelVerificationSource,
            {
                params: query,
            },
            true
        )
    }
}

export default VerifyRepository
