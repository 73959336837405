import React from "react"
import { Redirect } from "react-router-dom"

import Logout from "../pages/Authentication/Logout"
import Launcher from "../pages/Launcher/RouteView"

//Home
import HomeDashboard from "../pages/Home/Dashboard/RouteView"
import HomePegawai from "../pages/Home/DashboardPegawai/RouteView"
import HomeOperator from "../pages/Home/DashboardOperator/RouteView"
import HomeProfile from "../pages/Home/Profile/RouteView"
import HomeNotification from "../pages/Home/Notification/RouteView"
import InformasiPengumuman from "../pages/Information/Pengumuman/RouteView" 
import InformasiPeraturan from "../pages/Information/Peraturan/RouteView" 
import InformasiFaq from "../pages/Information/Faq/RouteView" 

//Integration
import SakipRealisasi from "../pages/Integration/Sakip/Realisasi/RouteView"
import SakipTarget from "../pages/Integration/Sakip/Target/RouteView"
import SimpegAbsensi from "../pages/Integration/Simpeg/Absensi/RouteView"
import SimpegKepegawaian from "../pages/Integration/Simpeg/Kepegawaian/RouteView"
import SimapanTracking from "../pages/Integration/Simapan/Tracking/RouteView"
import SimapanRealiasi from "../pages/Integration/Simapan/Realisasi/RouteView"
import SigapGajiPokok from "../pages/Integration/Sigap/Gajipokok/RouteView"
import SigapGajiref from "../pages/Integration/Sigap/Gajiref/RouteView"
import SigapKeluarga from "../pages/Integration/Sigap/Keluarga/RouteView"
import SigapManual from "../pages/Integration/Sigap/Manual/RouteView"
import SimpegMonitoring from "../pages/Integration/Simpeg/Monitoring/RouteView"
import SimpegDokumen from "../pages/Integration/Simpeg/Dokumen/RouteView"

//Payroll TPLN Reguler
import PayrollTunkinReguler from "../pages/Payroll/Tunkin/Reguler/RouteView"
import PayrollTunkin13 from "../pages/Payroll/Tunkin/T13/RouteView"
import PayrollTunkin14 from "../pages/Payroll/Tunkin/T14/RouteView"
import PayrollTunkinSusulan from "../pages/Payroll/Tunkin/Susulan/RouteView"
import PayrollTunkinRapel from "../pages/Payroll/Tunkin/Rapel/RouteView"

import PayrollTPLNReguler from "../pages/Payroll/TPLN/Reguler/RouteView"
import PayrollTPLN13 from "../pages/Payroll/TPLN/T13/RouteView"
import PayrollTPLN14 from "../pages/Payroll/TPLN/T14/RouteView"
import PayrollTPLNSusulan from "../pages/Payroll/TPLN/Susulan/RouteView"
import PayrollTPLNRapel from "../pages/Payroll/TPLN/Rapel/RouteView"

import PayrollTunsusReguler from "../pages/Payroll/Tunsus/Reguler/RouteView"
import PayrollTunsusRapel from "../pages/Payroll/Tunsus/Rapel/RouteView"

import PayrollHonor from "../pages/Payroll/Honor/RouteView"
import PayrollSKP from "../pages/Payroll/SKP/RouteView"
import PayrollSigap from "../pages/Payroll/Sigap/RouteView"
import PayrollSlip from "../pages/Payroll/Slip/RouteView"
import PayrollSPT from "../pages/Payroll/SPT/RouteView"
import PayrollPPH from "../pages/Payroll/PPH/RouteView"
import PayrollUangMakanRegular from "../pages/Payroll/UangMakan/Regular/RouteView"
import PayrollUangMakanRapel from "../pages/Payroll/UangMakan/Rapel/RouteView"
import PayrollPtspReguler from "../pages/Payroll/Ptsp/Reguler/RouteView"
import PayrollPtspRapel from "../pages/Payroll/Ptsp/Rapel/RouteView"
import PayrollOvertime from "../pages/Payroll/Overtime/RouteView"
import PayrollTunjab from "../pages/Payroll/Tunjab/RouteView"
import PayrollKrko from "../pages/Payroll/Krko/RouteView"

//Official Travel
import OfficialTravelMonitoringEmployee from "../pages/OfficialTravel/Monitoring/Employee/RouteView"
import OfficialTravelMonitoringSt from "../pages/OfficialTravel/Monitoring/St/RouteView"
import OfficialTravelPpk from "../pages/OfficialTravel/Ppk/RouteView"
import OfficialTravelRegister from "../pages/OfficialTravel/Register/RouteView"
import OfficialTravelSubmission from "../pages/OfficialTravel/Submission/RouteView"
import officialTravelRapelSubmission from "../pages/OfficialTravel/Rapel/RouteView"
import OfficialTravelRefund from "../pages/OfficialTravel/Refund/RouteView"

//Master
import MasterTunkinClassification from "../pages/Master/Tunkin/Classification/RouteView"

import MasterUangMakan from "../pages/Master/UangMakan/RouteView"
import MasterTunanganKhusus from "../pages/Master/TunjanganKhusus/RouteView"
import MasterOvertime from "../pages/Master/Overtime/RouteView"
import MasterTplnKurs from "../pages/Master/Tpln/Kurs/RouteView"
import MasterTunkinTPLN from "../pages/Master/Tpln/TPLN/RouteView"
import MasterTunkinCountry from "../pages/Master/Tpln/Country/RouteView"

import MasterReferenceCountry from "../pages/Master/Reference/Country/RouteView"
import MasterReferenceKurs from "../pages/Master/Reference/Kurs/RouteView"
import MasterReferenceAccount from "../pages/Master/Reference/Account/RouteView"

import MasterReferenceMappingNip from "../pages/Master/Reference/MappingNip/RouteView"

import MasterReferenceSigner from "../pages/Master/Reference/Signer/RouteView"

import MasterPtspHonor from "../pages/Master/Ptsp/Honorarium/RouteView"
import MasterPtspTunkin from "../pages/Master/Ptsp/TunKin/RouteView"

import MasterSpdCountry from "../pages/Master/Reference/SpdCountry/RouteView"
import MasterSpdProvince from "../pages/Master/Reference/SpdProvince/RouteView"

//Pajak
import MasterTaxPtkp from "../pages/Master/Tax/Ptkp/RouteView"
import MasterTaxPtkp2024 from "../pages/Master/Tax/Ptkp2024/RouteView"
import MasterTaxPkp from "../pages/Master/Tax/Pkp/RouteView"
import MasterTaxFinal from "../pages/Master/Tax/Final/RouteView"

//Setting
import SettingConfig from "../pages/Setting/Config/RouteView"
import SettingUser from "../pages/Setting/User/RouteView"
import SettingAccessRole from "../pages/Setting/AccessRole/RouteView"
import SettingActivity from "../pages/Setting/Activity/RouteView"

// NRS
import MasterNRS from "../pages/Master/Reference/Nrs/RouteView"

// CMS
import MasterDPegawai from "../pages/Master/Reference/Cms/DashboadPegawai/RouteView"
import MasterInformasi from "../pages/Master/Reference/Cms/Informasi/RouteView"

import MasterMappingIIPC from "../pages/Master/Reference/MappingIIPC/RouteView"

// Mappin BRS
import MasterMappingNRS from "../pages/Master/Reference/MappingNrs/RouteView"

const userRoutes = [
    //base
    { path: "/", exact: true, component: () => <Redirect to="/home/profile.html" /> },
    { path: "/home/", exact: true, component: () => <Redirect to="/home/dashboard.html" /> },
    { path: "/home/dashboard.html", component: HomeDashboard },
    { path: "/home/dashboard-pegawai.html", component: HomePegawai },
    { path: "/home/dashboard-operator.html", component: HomeOperator },
    { path: "/home/profile.html", component: HomeProfile },
    { path: "/home/notification.html", component: HomeNotification },
    { path: "/information/pengumuman.html", component: InformasiPengumuman },
    { path: "/information/peraturan.html", component: InformasiPeraturan },
    { path: "/information/faq.html", component: InformasiFaq },

    //integrasi
    { path: "/integration/", exact: true, component: () => <Redirect to="/integration/sakip-realization.html" /> },
    { path: "/integration/sakip-realization.html", component: SakipRealisasi },
    { path: "/integration/sakip-target.html", component: SakipTarget },
    { path: "/integration/simpeg-presention.html", component: SimpegAbsensi },
    { path: "/integration/simpeg-kepegawaian.html", component: SimpegKepegawaian },
    { path: "/integration/simapan-tracking.html", component: SimapanTracking },
    { path: "/integration/simapan-realization.html", component: SimapanRealiasi },
    { path: "/integration/sigap-gaji.html", component: SigapGajiPokok },
    { path: "/integration/sigap-refgaji.html", component: SigapGajiref },
    { path: "/integration/sigap-keluarga.html", component: SigapKeluarga },
    { path: "/integration/sigap-manual.html", component: SigapManual },
    { path: "/integration/monitoring.html", component: SimpegMonitoring },
    { path: "/integration/dokumen.html", component: SimpegDokumen },

    //Tunkin
    { path: "/payroll/", exact: true, component: () => <Redirect to="/payroll/tunkin-reguler.html" /> },
    { path: "/payroll/tunkin-reguler.html", component: PayrollTunkinReguler },
    { path: "/payroll/tunkin-13.html", component: PayrollTunkin13 },
    { path: "/payroll/tunkin-14.html", component: PayrollTunkin14 },
    { path: "/payroll/tunkin-susulan.html", component: PayrollTunkinSusulan },
    { path: "/payroll/tunkin-rapel.html", component: PayrollTunkinRapel },

    //Tunsus
    { path: "/payroll/tunsus-reguler.html", component: PayrollTunsusReguler },
    { path: "/payroll/tunsus-rapel.html", component: PayrollTunsusRapel },

    // TPLN
    { path: "/payroll/tpln-reguler.html", component: PayrollTPLNReguler },
    { path: "/payroll/tpln-13.html", component: PayrollTPLN13 },
    { path: "/payroll/tpln-14.html", component: PayrollTPLN14 },
    { path: "/payroll/tpln-susulan.html", component: PayrollTPLNSusulan },
    { path: "/payroll/tpln-rapel.html", component: PayrollTPLNRapel },

    { path: "/payroll/honor.html", component: PayrollHonor },
    { path: "/payroll/skp.html", component: PayrollSKP },
    { path: "/payroll/sigap.html", component: PayrollSigap },
    { path: "/payroll/slip.html", component: PayrollSlip },
    { path: "/payroll/spt.html", component: PayrollSPT },
    { path: "/payroll/pph.html", component: PayrollPPH },
    { path: "/payroll/uang-makan-regular.html", component: PayrollUangMakanRegular },
    { path: "/payroll/uang-makan-rapel.html", component: PayrollUangMakanRapel },
    { path: "/payroll/ptsp-reguler.html", component: PayrollPtspReguler },
    { path: "/payroll/ptsp-rapel.html", component: PayrollPtspRapel },
    { path: "/payroll/overtime.html", component: PayrollOvertime },
    { path: "/payroll/tunjab.html", component: PayrollTunjab },
    { path: "/payroll/krko.html", component: PayrollKrko },

    //Perjalanan Dinas
    { path: "/official-travel/", exact: true, component: () => <Redirect to="/official-travel/submission.html" /> },
    { path: "/official-travel/monitoring-employee.html", component: OfficialTravelMonitoringEmployee },
    { path: "/official-travel/monitoring-st.html", component: OfficialTravelMonitoringSt },
    { path: "/official-travel/ppk.html", component: OfficialTravelPpk },
    { path: "/official-travel/register.html", component: OfficialTravelRegister },
    { path: "/official-travel/submission.html", component: OfficialTravelSubmission },
    { path: "/official-travel/finalization.html", component: officialTravelRapelSubmission },
    { path: "/official-travel/refund.html", component: OfficialTravelRefund },

    //master
    { path: "/master/tunkin.html", component: MasterTunkinClassification },
    { path: "/master/tpln.html", component: MasterTunkinTPLN },
    { path: "/master/negara.html", component: MasterTunkinCountry },
    { path: "/master/tunjangan-makan.html", component: MasterUangMakan },
    { path: "/master/tunjangan-khusus.html", component: MasterTunanganKhusus },
    { path: "/master/overtime.html", component: MasterOvertime },

    { path: "/master/referensi-negara.html", component: MasterReferenceCountry },
    { path: "/master/referensi-kurs.html", component: MasterReferenceKurs },
    { path: "/master/daftar-rekening.html", component: MasterReferenceAccount },

    { path: "/master/mapping-nip.html", component: MasterReferenceMappingNip },
    { path: "/master/penanda-tangan.html", component: MasterReferenceSigner },

    { path: "/master/spd-country.html", component: MasterSpdCountry },
    { path: "/master/spd-province.html", component: MasterSpdProvince },

    //tpln
    { path: "/master/tpln-kurs.html", component: MasterTplnKurs },

    //pajak
    { path: "/master/pajak-ptkp.html", component: MasterTaxPtkp },
    { path: "/master/pajak-ptkp2024.html", component: MasterTaxPtkp2024 },
    { path: "/master/pajak-pkp.html", component: MasterTaxPkp },
    { path: "/master/pajak-final.html", component: MasterTaxFinal },

    //ptsp
    { path: "/master/ptsp-honor.html", component: MasterPtspHonor },
    { path: "/master/ptsp-tunkin.html", component: MasterPtspTunkin },

    //pengaturan
    { path: "/setting/config.html", component: SettingConfig },
    { path: "/setting/user.html", component: SettingUser },
    { path: "/setting/access.html", component: SettingAccessRole },
    { path: "/setting/activity.html", component: SettingActivity },

    // NRS
    { path: "/master/nrs.html", component: MasterNRS },

    // CMS
    { path: "/master/dashboard_pegawai.html", component: MasterDPegawai },
    { path: "/master/informasi.html", component: MasterInformasi },

    // Mapping NRS
    { path: "/master/mapping-nrs.html", component: MasterMappingNRS },

    // mapping IIPC year 
    { path: "/master/mapping-iipc.html", component: MasterMappingIIPC },
]

const authRoutes = [
    { path: "/launcher.html", component: Launcher },
    { path: "/logout.html", component: Logout },
]

export { userRoutes, authRoutes }
